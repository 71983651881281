import { Box } from '@material-ui/core';
import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { PaymentMethod } from '@breathelife/types';
import { Loader } from '@breathelife/ui-components';

import { Button } from '../../../../../../Components/Button/Button';
import { Icon } from '../../../../../../Components/Icons';
import Typography from '../../../../../../Components/Typography';
import { useSelector } from '../../../../../../Hooks';
import { StyledActionGhostIconButton } from '../../../Styles';
import { CreditCardPreview } from './PaymentMethodPreviews/CreditCardPreview';

type PaymentSubsectionViewProps = {
  isLoading: boolean;
  onOpenForm: (isEditing: boolean) => void;
  isEditable: boolean;
};

export function PaymentSubsectionView(props: PaymentSubsectionViewProps): ReactElement {
  const { isLoadingEditClientSecret, setupIntentStatus, paymentMethodInfo } = useSelector(
    (store) => store.leadPlatform.payment
  );

  const { t } = useTranslation();
  const { isLoading, onOpenForm: onOpenFormProp, isEditable } = props;

  const onOpenAddForm = useCallback(() => {
    onOpenFormProp(false);
  }, [onOpenFormProp]);

  const onOpenEditForm = useCallback(() => {
    onOpenFormProp(true);
  }, [onOpenFormProp]);

  let previewComponent = null;
  switch (paymentMethodInfo?.type) {
    case PaymentMethod.card:
      previewComponent = (
        <Box mr={5}>
          <CreditCardPreview last4={paymentMethodInfo.last4} />
        </Box>
      );
  }

  const creditCardEditButton = isEditable && (
    <Box>
      <Button variant='outlined' isLoading={isLoadingEditClientSecret} onClick={onOpenEditForm}>
        {t('cta.edit')}
      </Button>
    </Box>
  );

  return (
    <React.Fragment>
      <Box mb={4}>
        <Typography variant='h3'>{t('assistedApplication.paymentSubsection.title')}</Typography>
      </Box>
      <Box mb={3}>
        <Typography variant='body1'>{t('assistedApplication.paymentSubsection.paymentMethods.creditCard')}</Typography>
      </Box>
      <Box mb={3}>
        {isLoading ? (
          <Loader />
        ) : (
          <React.Fragment>
            {setupIntentStatus === 'requires_payment_method' && (
              <StyledActionGhostIconButton
                removeIconBorder
                size='small'
                startIcon={<Icon name='addAssured' variant='primary' />}
                typographyVariant='button'
                onClick={onOpenAddForm}
              >
                {t('assistedApplication.paymentSubsection.addPaymentMethod.creditCard')}
              </StyledActionGhostIconButton>
            )}
            {setupIntentStatus === 'succeeded' && previewComponent && (
              <React.Fragment>
                <Box display='flex' alignItems='center'>
                  {previewComponent}
                  {creditCardEditButton}
                </Box>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  );
}
