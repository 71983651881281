import Box from '@material-ui/core/Box';
import React, { useState } from 'react';

import { DebugToolbar } from '@breathelife/ui-components';

import { useNavigation } from '../../Hooks';
import { useGetApplicationQuery } from '../../ReactQuery/Application/application.queries';
import { useGetLeadQuery } from '../../ReactQuery/Lead/lead.queries';
import { ExportApplicationAnswersButton } from './Buttons/ExportApplicationAnswersButton';
import { ExportLeadButton } from './Buttons/ExportLeadButton';
import { ImportApplicationAnswersButton } from './Buttons/ImportApplicationAnswersButton';
import { ImportLeadButton } from './Buttons/ImportLeadButton';
import { DebugToolbarModalViewContainer } from './DebugToolbarModalViewContainer';

export function DebugToolbarContainer(): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { leadId, applicationId } = useNavigation();

  const { data: selectedLead } = useGetLeadQuery(leadId);
  const { data: selectedApplication } = useGetApplicationQuery(applicationId || selectedLead?.applicationId);

  return (
    <React.Fragment>
      <DebugToolbar onLoadPrefillSteps={() => setIsOpen(true)}>
        <Box mt={2} m={1}>
          <ImportApplicationAnswersButton lead={selectedLead} application={selectedApplication} />
        </Box>
        <Box m={1}>
          <ExportApplicationAnswersButton application={selectedApplication} />
        </Box>
        <Box mt={2} m={1}>
          <ImportLeadButton />
        </Box>
        <Box m={1}>
          <ExportLeadButton lead={selectedLead} application={selectedApplication} />
        </Box>
      </DebugToolbar>

      {isOpen && selectedApplication && (
        <DebugToolbarModalViewContainer selectedApplication={selectedApplication} onClose={() => setIsOpen(false)} />
      )}
    </React.Fragment>
  );
}
