import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

import Typography from '../../Components/Typography';
import { convertToReadableTime, getDayInText, getMethodDisplayText, TimeFormat } from '../../Helpers/communication';
import { BaseLeadCommunication, BaseLeadCommunicationSchedule } from '../../Models/Lead';

export type Props = {
  communication?: BaseLeadCommunication;
  format?: TimeFormat;
};

type DisplayableScheduleInfo = {
  dayOfTheWeekName: string;
  time: string;
};

const Preview = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
`;

const DayOfTheWeekName = styled.span`
  font-weight: 500;

  &:not(:first-child)::before {
    content: ' - ';
    font-weight: 300;
  }
`;

const getDisplayableScheduleInfo = (
  schedule: BaseLeadCommunicationSchedule,
  format: TimeFormat
): DisplayableScheduleInfo => {
  const dayOfTheWeekName = getDayInText(schedule.day, 'short');
  const time = schedule.time.map((time) => convertToReadableTime(time, format)).join(' - ');
  return {
    dayOfTheWeekName,
    time,
  };
};

const renderSchedules = (days: DisplayableScheduleInfo[]) => {
  return days.map((day) => (
    <div key={`${day.dayOfTheWeekName} - ${day.time}`}>
      <DayOfTheWeekName>{day.dayOfTheWeekName}</DayOfTheWeekName>
      <span>: {day.time}</span>
    </div>
  ));
};

const renderPreview = (days: DisplayableScheduleInfo[]) => {
  return days.map((day) => {
    return (
      <React.Fragment key={`${day.dayOfTheWeekName} - ${day.time}`}>
        <DayOfTheWeekName>{day.dayOfTheWeekName}</DayOfTheWeekName>
        <span>: {day.time}</span>
      </React.Fragment>
    );
  });
};

const CommunicationToolTip = withStyles(() => ({
  tooltip: {
    padding: '15px',
  },
}))(Tooltip);

export const CommunicationView = ({ communication, format = '24' }: Props) => {
  if (!communication) return <span />;

  const preferredMethodText = getMethodDisplayText(communication?.preferredMethods);
  const schedules = communication?.['leads-communication-schedules'] || [];
  const days = schedules.map((schedule) => getDisplayableScheduleInfo(schedule, format));

  return _.includes(communication.preferredMethods, 'phone') ? (
    <CommunicationToolTip
      title={
        <React.Fragment>
          <Typography variant='small1'>
            {preferredMethodText && <p>{preferredMethodText}</p>}
            {renderSchedules(days)}
          </Typography>
        </React.Fragment>
      }
    >
      <Preview>{renderPreview(days)}</Preview>
    </CommunicationToolTip>
  ) : (
    <span>{preferredMethodText}</span>
  );
};
