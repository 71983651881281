import React from 'react';
import { useTranslation } from 'react-i18next';

import { BlueprintConditionsValue, BooleanOperator } from '@breathelife/types';

export function BooleanOperatorView({
  conditions,
  index,
}: {
  conditions: BlueprintConditionsValue;
  index: number;
}): React.ReactElement | null {
  const { t } = useTranslation();

  if (index === conditions.conditions.length - 1 || !conditions.booleanOperator) {
    return null;
  }

  return (
    <React.Fragment>
      {conditions.booleanOperator === BooleanOperator.not
        ? ` ${t(BooleanOperator.and).toUpperCase()} `
        : ` ${t(conditions.booleanOperator).toUpperCase()} `}
    </React.Fragment>
  );
}
