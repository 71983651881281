import Box from '@material-ui/core/Box';
import _ from 'lodash';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  AdminToolActionType,
  AdminToolResourceName,
  AuditLogsRowData,
  PageQueryOptions,
  SortParams,
} from '@breathelife/types';

import { Pagination } from '../../../Components/Pagination/Pagination';
import { Select } from '../../../Components/Select/Select';
import { getOptionsFromEnum } from '../../../Helpers/options';
import { useSelector } from '../../../Hooks';
import { fetchLogsAdminTools } from '../../../Redux/Logs/LogsAdminToolsOperations';
import { AuditLogDetailsModal } from './AuditLogDetailsModal';
import { AuditLogsTable } from './AuditLogsTable';

const PER_PAGE_OPTIONS = [
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

type AuditLogQueryOptions = Partial<PageQueryOptions<AuditLogsRowData>>;

export function AuditLogsViewContainer(): React.ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { logs, total } = useSelector((store) => store.leadPlatform.logsAdminTools);

  const [isOpen, setIsOpen] = useState(false);
  const [actionDetail, setActionDetail] = useState<Record<string, any> | null>({});
  const [resourceName, setResourceName] = useState<'' | AdminToolResourceName>('');
  const [actionType, setActionType] = useState<'' | AdminToolActionType>('');
  const [auditLogQueryOptions, setAuditLogQueryOptions] = useState<AuditLogQueryOptions>({
    $page: 1,
    $limit: PER_PAGE_OPTIONS[1].value,
    $sort: { field: 'createdAt', direction: 'desc' },
  });

  useEffect(() => {
    const query = Object.assign({}, extractQueryParams(), { resourceName, actionType });
    dispatch(fetchLogsAdminTools(_.pickBy(query, _.identity), auditLogQueryOptions));
  }, [dispatch, auditLogQueryOptions, resourceName, actionType]);

  const onPageChange = useCallback(($page: number): void => {
    setAuditLogQueryOptions((prevValue: AuditLogQueryOptions) => ({ ...prevValue, $page }));
  }, []);

  const onPageLimitChange = useCallback(($limit: number): void => {
    setAuditLogQueryOptions((prevValue: AuditLogQueryOptions) => ({ ...prevValue, $limit, $page: 1 }));
  }, []);

  const onSortingChange = useCallback(($sort: SortParams<AuditLogsRowData>) => {
    setAuditLogQueryOptions((prevValue: AuditLogQueryOptions) => ({ ...prevValue, $sort }));
  }, []);

  const resourceOptions = useMemo(
    () => [
      { value: '', label: t('admin.auditLogs.options.any') },
      ...getOptionsFromEnum<AdminToolResourceName>(AdminToolResourceName, 'admin.auditLogs.options.resourceName'),
    ],
    [t]
  );

  const actionOptions = useMemo(
    () => [
      { value: '', label: t('admin.auditLogs.options.any') },
      ...getOptionsFromEnum<AdminToolActionType>(AdminToolActionType, 'admin.auditLogs.options.actionType'),
    ],
    [t]
  );

  return (
    <React.Fragment>
      <Box m={2} display='flex' align-items='center'>
        <Box mr={2}>
          <Select
            id='resourceName-select'
            label={t('admin.auditLogs.resourceName')}
            value={resourceName}
            options={resourceOptions}
            onChange={(value) => {
              setResourceName(value as AdminToolResourceName);
            }}
            displayEmpty
          />
        </Box>

        <Box>
          <Select
            id='actionType-select'
            label={t('admin.auditLogs.actionType')}
            value={actionType}
            options={actionOptions}
            onChange={(value) => {
              setActionType(value as AdminToolActionType);
            }}
            displayEmpty
          />
        </Box>
      </Box>

      <Box m={2}>
        <AuditLogsTable
          logs={logs}
          setActionDetail={setActionDetail}
          setIsOpen={setIsOpen}
          onSortingChange={onSortingChange}
        />
      </Box>

      <Box pl={2.5} pr={2.5}>
        <Pagination
          total={total}
          page={auditLogQueryOptions.$page ?? 1}
          perPage={auditLogQueryOptions.$limit ?? PER_PAGE_OPTIONS[1].value}
          perPageOptions={PER_PAGE_OPTIONS}
          onPageChange={onPageChange}
          onPerPageChange={onPageLimitChange}
        />
      </Box>

      <AuditLogDetailsModal actionDetail={actionDetail} isOpen={isOpen} setIsOpen={setIsOpen} />
    </React.Fragment>
  );
}

function extractQueryParams(): { resourceName: AdminToolResourceName; resourceId: string } | undefined {
  const parsedParams = queryString.parse(window.location.search);

  if (!(parsedParams.resourceName && parsedParams.resourceId)) return undefined;

  return {
    resourceName: parsedParams.resourceName as AdminToolResourceName,
    resourceId: parsedParams.resourceId as string,
  };
}
