import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import searchIcon from '../../Assets/search.svg';

const styles = () => ({
  input: {
    height: '2.5em',
    padding: '0',
    '&::placeholder': {
      fontSize: '14px',
    },
  },
});

const SearchInput = styled(TextField)`
  & {
    width: 190px;
  }
`;

export type SearchTextFieldProps = {
  classes: { [key: string]: string };
  onSearchChange: (searchString: string) => void;
  fullWidth?: boolean;
  debounceDurationMs?: number;
  defaultValue?: string;
  placeholder?: string;
};

function SearchField(props: SearchTextFieldProps): React.ReactElement | null {
  const { t } = useTranslation();
  const debounceDuration = _.isUndefined(props.debounceDurationMs) ? 400 : props.debounceDurationMs;

  const debouncedSearch = _.debounce((search: string) => {
    props.onSearchChange(search);
  }, debounceDuration);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchString = event.target.value;
    debouncedSearch(searchString);
  };

  return (
    <SearchInput
      defaultValue={props.defaultValue}
      fullWidth={props.fullWidth}
      placeholder={props.placeholder ?? t('leadsListTable.search')}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <img src={searchIcon} alt='' />
          </InputAdornment>
        ),
        classes: {
          notchedOutline: props.classes.notchedOutline,
          root: props.classes.root,
          input: props.classes.input,
        },
      }}
      variant='outlined'
      onChange={onChange}
      role='search'
    />
  );
}

export const SearchTextField = withStyles(styles)(SearchField);
