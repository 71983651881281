import {
  AdminToolActionType,
  AdminToolResourceName,
  ApplicationMode,
  BlueprintCollectionOperator,
  ConditionBlueprintType,
  DateUnit,
  PdfDocumentType,
  DocumentVariant,
  FieldSizes,
  FileTemplateOrigin,
  HoldingForm,
  IconName,
  InsuranceModule,
  InsuranceScopes,
  Language,
  LineOfBusiness,
  MatchesConditionPropertyQuantifier,
  MathConditionOperator,
  MeasurementSystem,
  NavigationSidebarVariants,
  NotificationTypes,
  NumberComparisonConditionOperator,
  NumericalDataType,
  OptionSize,
  OutcomeCode,
  ParticipantRoles,
  PaymentFrequency,
  PlatformType,
  ProductType,
  QuestionnaireBlueprintRenderOn,
  QuestionnairePreview,
  RuleReasonValue,
  StoredFileDocType,
  SubsectionVariant,
  SupportedBuyer,
  ThirdPartyIntegrationStatus,
  UserStatus,
  YesNoValue,
} from '@breathelife/types';

import { Translation } from '../../../Localization';
import { LeadStatusesKeys, LeadTableColumnKeys } from '../../../Localization/types';
import { UserCategory, UserRole, UserType } from '../../../Services/Auth0';
import eSignatureStatus from './eSignatureStatus';
import engineValidation from './engineValidation';

// The '$$' indicates that the value is in need of translation

const translation: Translation = {
  personalInformation: 'Information personnelle',
  needsAnalysis: 'Analyse des besoins',
  transactionFlow: 'Flux transactionnel',
  assistedApplication: {
    // @TODO DEV-12639 Translation to be added
    actionBanner: {
      readyForReview: {
        body: '$$This application is complete and ready for review.$$',
        cta: '$$Lock the application for review$$',
      },
      inReview: {
        body: '$$The application is in review. In order to modify the proposal, set it back to edit mode. Once the document has been reviewed and validated, you may submit it for signature.$$',
        cta: '$$Edit application$$',
      },
      sent: {
        body: '$$This proposal has been sent for signature, and therefore cannot be edited. If you wish to change the content of the proposal, you must first cancel the signature process.$$',
        cta: '$$Cancel the signature process$$',
      },
    },
    application: 'Proposition',
    title: 'Proposition',
    submitApplication: 'Soumettre la proposition',
    downloadApplicationPdf: 'Télécharger la proposition en format PDF',
    seeSubmissionDetails: 'Voir les détails de la soumission',
    sendToSignature: 'Envoyer en signature',
    submit: 'Soumettre',
    previousSection: 'Section précédente',
    nextSection: 'Section suivante',
    identityVerification: 'InstantID®',
    cancelESignature: "Confirmez de l'annulation",
    signaturesDetails: 'Détails des signatures',
    licenseVerification: 'Licences',
    eSignatureDetails: {
      status: eSignatureStatus,
      header: 'Détails des signatures',
      email: 'Adresse courriel',
      phoneNumber: 'Numéro de téléphone cellulaire',
      emailInfo:
        'Ce changement est pris en compte pour la signature uniquement et ne sera pas appliqué à la proposition.',
      // @TODO DEV-12639 Translation to be added
      pdfGenerationInfo: '$$Application documents are generating, please wait before sending.$$',
    },
    identityVerificationReport: 'Rapport InstantID®',
    identityVerificationReportInfo:
      "Indicateur(s) de risque renvoyé(s) par InstantID® après avoir vérifié les informations l'assuré proposé",
    displayFiles: 'Afficher les fichiers',
    files: 'Fichiers',
    documentsDrawer: {
      requiredFiles: 'Fichiers Requis',
      otherFiles: 'Autres Fichiers',
      uploadFile: 'Télécharger un fichier',
      noFilesUploaded: "Vous n'avez pas encore téléchargé de fichiers",
      noRequiredFiles: "Il n'y a pas de fichiers requis pour le moment",
      documentRequired: 'Document requis',
    },
    recipientsDrawer: {
      title: 'Destinataires des documents',
    },
    signatureDrawer: {
      signatureRequired: 'Signature requise',
      signersInformation: 'Informations sur les Signataires',
      documentsToSign: 'Documents à Signer',
      infoBoxMessage: 'Tous les documents seront envoyés aux signataires appropriés pour signatures',
    },
    multiInsuredTabs: {
      insuredPersons: 'Personnes Assurées',
      policyContract: 'Contrat de Police',
      newProposedInsured: 'Nouvelle personne à assurer',
      addNewProposedInsured: 'Ajouter une nouvelle personne à assurer',
      deleteProposedInsured: 'Supprimer cet assuré {{name}}',
    },
    // @deprecated
    attachmentUrl: 'lien URL de la pièce jointe',
    paymentSubsection: {
      title: 'Paiement de prime',
      addPaymentMethod: {
        creditCard: 'Ajouter une carte de crédit',
      },
      paymentMethods: {
        creditCard: 'Carte de crédit',
      },
      previews: {
        creditCardNumber: 'Numero de carte de crédit',
      },
    },
    fieldGroupCard: {
      error: 'Informations requises manquantes',
      expand: 'Détails',
    },
    productWidget: {
      title: 'Votre sélection',
      empty: 'Votre sélection de produits est vide',
      products: 'Produits',
      totalPremium: 'Montant total de la prime',
      paymentFrequency: {
        [PaymentFrequency.monthly]: ' /mois',
        [PaymentFrequency.quarterly]: ' /trimestre',
        [PaymentFrequency.annually]: ' /an',
        [PaymentFrequency.semiAnnually]: ' /semi-annuel',
      },
    },
    totalPremiumsWidget: {
      title: 'Prix',
      totalPremium: 'Montant total de la prime',
      paymentFrequency: {
        [PaymentFrequency.monthly]: ' /mois',
        [PaymentFrequency.annually]: ' /an',
      },
    },
    instantId: 'InstantID®',
    jetDecisionWidget: {
      title: 'Décisions',
      ruleReason: {
        [RuleReasonValue.fail]: 'Échouer',
        [RuleReasonValue.pass]: 'Passer',
      },
    },
  },
  fileUrl: 'lien URL du fichier',
  view: 'Afficher',
  applications: 'Propositions',
  products: 'Produits',
  notes: 'Notes',
  history: 'Historique',
  new: 'Nouveau',
  edit: 'Modifier',
  bestTimeToReach: 'Meilleur moment pour contacter',
  phone: 'Téléphone',
  email: 'Courriel',
  by: 'Par',
  and: 'et',
  to: 'à',
  or: 'ou',
  not: 'non',
  true: 'Vrai',
  false: 'Faux',
  lead: 'Contact',
  lastUpdated: 'Dernière mise à jour',
  lastUpdateOn: 'Mis à jour le',
  createdOn: 'Créé le',
  characters: 'caractères',
  coverageAmount: 'Montant de la couverture',
  recommendedCoverageAmount: 'Montant de couverture recommandé',
  perMonth: '/ mois',
  pending: 'En attente',
  percentCompleted: '{{percent}}% complété',
  completed: 'complété',
  financialNeeds: 'Besoins Financiers',
  notApplicable: 'N/A',
  unassigned: 'non assigné',
  me: 'Moi',
  softwareByBreatheLife: 'Plateforme logicielle par Breathe Life',
  viewLogs: 'Voir les entrées',
  pageTitles: {
    prefix: 'Plateforme Pro',
    home: 'Accueil',
    admin: "Outils d'admin",
    userManagement: 'Gestion des utilisateurs',
    documents: 'Documents',
    questionnaireManagement: 'Gestion de questionnaire',
    productManagement: 'Gestion de produit',
    salesDecisionRulesManagement: 'Gestion des règles de décision de vente',
    themeManagement: 'Gestion du thème',
    settings: 'Paramètres',
    featuresManagement: 'Gestion des fonctionnalités',
    logout: 'Déconnection',
    logs: '$$Logs$$',
    questionnaireBuilder: 'Constructeur de Questionnaires',
    thirdPartyIntegrations: 'Intégrations',
  },
  adminTabs: {
    platform: 'Plateforme',
    users: 'Utilisateurs',
    questionnaire: 'Questionnaire',
    salesDecisionRules: 'Règles de décision de vente',
    firm: 'Firme',
    product: 'Produit',
    theme: 'Thème',
    settings: 'Paramètres',
    features: 'Fonctionnalités',
    auditLogs: 'Journal de bord',
    audit: 'Audit',
    questionnaireBuilder: 'Constructeur de Questionnaires',
    thirdPartyIntegrations: 'Intégrations',
    backOffice: 'Back-Office',
    entityMappingManagement: "Mapping d'entités",
  },
  authentication: {
    loggingIn: 'Connexion en cours...',
    userBlockedTitle: 'Ce compte semble être désactivé',
    userBlockedText: "Si vous croyez qu'il y a erreur, veuillez contacter votre administrateur.",
    generalErrorTitle: "Quelque chose ne s'est pas déroulé comme prévu.",
    generalErrorText:
      'Il y a eu un problème lors de la connexion. Si le problème persiste, veuillez contacter votre administrateur.',
    softwareBy: 'Plateforme logicielle',
  },
  user: {
    category: {
      [UserCategory.nonRegistered]: 'Non-enregistré',
      [UserCategory.registered]: 'Enregistré',
    },
    role: {
      [UserRole.admin]: 'Admin',
      [UserRole.viewer]: 'Visiteur',
      [UserRole.manager]: 'Gestionnaire',
      [UserRole.user]: 'Utilisateur',
      [UserRole.superAdmin]: 'Super Admin',
    },
    type: {
      [UserType.captive]: 'Captif',
      [UserType.nonCaptive]: 'Non-Captif',
    },
    status: {
      [UserStatus.active]: 'Actif',
      [UserStatus.blocked]: 'Bloqué',
      [UserStatus.invitationPending]: 'Invitation en cours',
    },
  },
  insuranceScope: {
    [InsuranceScopes.life]: 'Vie',
    [InsuranceScopes.retirement]: 'Retraite',
  },
  language: {
    [Language.en]: 'Anglais',
    [Language.fr]: 'Français',
  },
  documentOrigin: {
    // TODO: DEV-1179 - Translate into French
    [FileTemplateOrigin.BLGenerated]: '$$BL Generated$$',
    [FileTemplateOrigin.CarrierGenerated]: '$$API-Provided$$',
    [FileTemplateOrigin.UserProvided]: '$$User-Provided$$',
    [FileTemplateOrigin.ExternalStatic]: '$$Hosted Externally$$',
    [FileTemplateOrigin.GCPBucket]: '$$Stored in Cloud Storage$$',
  },
  documentType: {
    [StoredFileDocType.Attachment]: 'Attachment',
    [StoredFileDocType.Illustration]: 'Illustration',
  },
  documentVariant: {
    [DocumentVariant.ModifiedEndowmentContract]: 'Modified Endowment Contract',
    none: 'Aucun',
  },
  participantRole: {
    [ParticipantRoles.AGENT]: 'Conseiller',
    [ParticipantRoles.INSURED]: 'Assuré',
    [ParticipantRoles.OWNER_PERSON]: 'Propriétaire',
    [ParticipantRoles.PAYER]: 'Payeur',
    [ParticipantRoles.LEGAL_GUARDIAN]: 'Tuteur Légal',
    [ParticipantRoles.TRUSTEE]: 'Fiduciaire',
    [ParticipantRoles.SOLE_PROPRIETOR]: 'Seul Propriétaire',
    [ParticipantRoles.SIGNING_AUTHORITY]: 'Signataire Autorisé',
    [ParticipantRoles.OFFICER]: 'Agent',
    [ParticipantRoles.TRANSLATOR]: 'Traducteur',
    [ParticipantRoles.OWNER_ORGANIZATION]: 'Organisme Propriétaire',
    [ParticipantRoles.PRIMARY_BENEFICIARY]: 'Bénéficiaire principal',
    [ParticipantRoles.CONTINGENT_BENEFICIARY]: 'Bénéficiaire représentatif',
  },

  usersListTable: {
    fullName: "Nom d'utilisateur",
    email: 'Adresse courriel',
    role: 'Rôle',
    lastConnection: 'Dernière connexion',
    status: 'Statut',
    isSso: 'est un utilisateur SSO',
    leadsAssigned: 'Contacts assignés',
    statusFilter: 'Tous les statuts',
    emptySearchResult: {
      title: 'Aucun résultat!',
      body: 'Veillez ajuster vos paramètres de recherche pour trouver ce que vous recherchez.',
    },
  },
  backOffice: {
    applications: {
      emptySearchResult: {
        title: 'Aucun résultat!',
        body: 'Veillez ajuster vos paramètres de recherche pour trouver ce que vous recherchez.',
      },
      moreActions: {
        openUnderwritingReports: 'Ouvrir les rapports de souscription',
      },
    },
    underwritingReports: {
      copy: 'Copier',
    },
  },
  leadsListTable: {
    active: 'Actif',
    archived: 'Archivé',
    filters: 'filtres',
    status: 'Tous les statuts',
    search: 'Recherche',
    addNew: 'Créer un contact',
  },
  applicationTable: {
    search: "Entrez un ID d'application ou un # de police",
    id: "ID d'application",
    refNo: 'Numéro de police',
    createdAt: 'Date de création',
    updatedAt: 'Date de la mise à jour',
    insuredCount: 'Assuré',
    leadStatus: 'Statut',
  },
  leadTableColumns: {
    [LeadTableColumnKeys.clientName]: 'Nom du client',
    [LeadTableColumnKeys.proposedInsured]: 'Personne à assurer',
    [LeadTableColumnKeys.email]: 'Courriel',
    [LeadTableColumnKeys.creationDate]: 'Date de création',
    [LeadTableColumnKeys.updatedAt]: 'Date de la mise à jour',
    [LeadTableColumnKeys.assignedTo]: 'Assigné à',
    [LeadTableColumnKeys.bestTimeToReach]: 'Meilleur moment pour contacter',
    [LeadTableColumnKeys.progress]: 'Progrès',
    [LeadTableColumnKeys.connected]: 'Contacté(e)',
    [LeadTableColumnKeys.phoneNumber]: 'Numéro de téléphone',
    [LeadTableColumnKeys.status]: 'Statut',
    [LeadTableColumnKeys.contacted]: 'Contacté',
    [LeadTableColumnKeys.applicationRefNo]: 'Numéro de référence',
    [LeadTableColumnKeys.referralCode]: 'Code affilié',
    [LeadTableColumnKeys.ownerDetails]: 'Propriétaire principal',
    [LeadTableColumnKeys.refNoIncrement]: "Numéro d'application",
    [LeadTableColumnKeys.actions]: 'Actions',
    [LeadTableColumnKeys.applicationMode]: 'Type de proposition',
  },
  leadStatuses: {
    [LeadStatusesKeys.new]: 'Nouveau',
    [LeadStatusesKeys.invited]: 'Invité(e)',
    [LeadStatusesKeys.started]: 'Commencée',
    [LeadStatusesKeys.questionnaireStarted]: 'Questionnaire commencé',
    [LeadStatusesKeys.analysisStarted]: 'Analyse commencée',
    [LeadStatusesKeys.analysisComplete]: 'Analyse complétée',
    [LeadStatusesKeys.toContact]: 'À contacter',
    [LeadStatusesKeys.progressing]: 'En cours',
    [LeadStatusesKeys.finalized]: 'Finalisé',
    [LeadStatusesKeys.applicationInProgress]: 'Proposition en cours',
    [LeadStatusesKeys.applicationStarted]: 'Proposition commencée',
    [LeadStatusesKeys.applicationCompleted]: 'Proposition complétée',
    [LeadStatusesKeys.applicationSubmitted]: 'Proposition soumise',
    [LeadStatusesKeys.submissionInProgress]: 'Soumission en cours',
  },
  applicationModes: {
    [ApplicationMode.digital]: 'Numérique',
    [ApplicationMode.paper]: 'Papier',
    unknown: 'Inconnue',
  },
  pagination: {
    results: 'Résultats',
    perPage: 'Rangées par page',
  },
  mgaGroup: {
    default: 'Aucun',
  },
  userMenu: {
    shareMyPublicLink: 'Partager mon lien public',
    myProfile: 'Mon profil',
    notifications: 'Notifications',
    logout: 'Déconnexion',
    filesAndDocuments: 'Fichiers et documents',
    agentLicenses: 'Licences',
  },
  cta: {
    send: 'Envoyer',
    save: 'Sauvegarder',
    edit: 'Modifier',
    block: 'Bloquer',
    cancel: 'Annuler',
    close: 'Fermer',
    delete: 'Effacer',
    assign: 'Assigner',
    extractAllLeadData: 'Exporter toutes les données des contacts',
    clear: 'Effacer',
    moreOptions: "Plus d'options",
    copy: 'Copier',
    copyApplication: 'Copier la proposition',
    assignTo: 'Assigner à',
    archive: 'Archiver',
    unarchive: 'Désarchiver',
    archiveLead: 'Archiver le contact',
    archiveApplication: 'Archiver la proposition',
    unarchiveLead: 'Désarchiver le contact',
    unarchiveApplication: 'Désarchiver la proposition',
    disabled: 'Désactiver',
    deleteUser: "Effacer l'utilisateur",
    sendAReminder: 'Envoyer un rappel',
    sendTheReminder: 'Envoyer le rappel',
    resendNeedsAnalysis: "Renvoyer l'analyse des besoins",
    launchApplication: 'Lancer la proposition',
    digitalApplication: 'Proposition électronique',
    paperApplication: 'Proposition papier',
    continue: 'Continuer',
    sendNeedsAnalysis: "Envoyer l'analyse des besoins",
    needAnalysisDetails: 'Analyse des besoins détaillées',
    addNew: 'Ajouter',
    createUser: 'Créer un utilisateur',
    downloadFile: {
      singular: 'Télécharger le fichier',
      plural: 'Télécharger les fichiers',
    },
    import: 'Importer',
    downloadDocument: 'Télécharger le document',
    uploadFilledDocument: 'Télécharger un document rempli',
    cancelESignatureProcess: 'Annuler la demande de signature',
    signaturesComplete: 'Toutes les signatures requises pour la proposition ont été obtenues',
    view: 'View',
  },
  dragAndDrop: {
    dropFile: 'Glisser-déplacer des fichiers ou double-cliquer pour sélectionner un fichier',
    acceptedFiles: 'Fichiers acceptés',
    bytes: 'octets',
  },
  notifications: {
    unableToSendToSignature: "La proposition n'a pu être envoyée en signature.",
    documentArchived: 'Document Archivé',
    linkCopied: 'Lien copié!',
    imageUploadSuccess: 'Image téléchargée avec succès',
    profileUpdated: 'Profil mis à jour!',
    invitationSent: 'Invitation envoyée!',
    userSuccessfullyCreated: 'Une invitation à joindre la plateforme a été envoyée avec succès à {{user}}!',
    userSuccessfullyDeleted: "L'utilisateur a été effacé",
    userInvitationEmailSuccessfullySent: {
      title: "Courriel d'invitation envoyé avec succès!",
      message: 'Une invitation à joindre la plateforme a été envoyée avec succès à {{name}}!',
    },
    failedToValidateRequestTitle: 'Validation échouée',
    failedToValidateRequest:
      'Nous avons identifié que les informations personnelles ont été modifiées depuis la dernière vérification InstantID. Veuillez effectuer une nouvelle vérification InstantID.',
    updatedUserNotificationPreferences: 'Les préférences de notification ont été mises à jour avec succès!',
    deleteLeadSuccess: 'Le contact a été supprimé avec succès!',
    deleteApplicationSuccess: 'La proposition a été supprimée avec succès!',
    updateUser: "L'utilisateur a été mis à jour avec succès!",
    themeUpdateSuccess: 'Le thème a été mis à jour avec succès',
    badRequestGeneral: "L'information fournie est invalide",
    createUserConflict: 'Cet adresse courriel est déjà utilisée.',
    forbiddenGeneral: "Vous n'avez pas les permissions requises pour compléter cette action.",
    forbiddenInvitationEmail: "Il y a eu une erreur dans l'envoie de l'invitation.",
    forbiddenGetLead: "Vous n'avez pas les permissions requises pour consulter ce contact.",
    submittedAndSignedApplication: 'La proposition a été soumise et signée avec succès!',
    signedApplication: 'La proposition a été signée avec succès!',
    failedToSendInvitaitonMissingInsuranceScope:
      "Échec de l'envoi de l'invitation au contact (catégorie d'assurance manquante)",
    failedToGetNotes: "Une erreur s'est produite lors de l'affichage des notes.",
    failedToUpdateNotes:
      "Une erreur s'est produite lors de mise à jour des notes.  Veuillez vous assurer que l'information y est valide.",
    failedToUpdateisDeclarationSent: "Une erreur s'est produite lors de mise à jour de la déclaration du représentant.",
    failedToCopyAgentLink: "Une erreur s'est produite en copiant le lien de l'agent!",
    failedToCopyPrivateLink: "Une erreur s'est produite en copiant le lien privé!",
    failedToCopyPrivateLinkMissingInsuranceScope:
      "Une erreur s'est produite en copiant le lien privé! (catégorie d'assurance manquante)",
    failedToLaunchCoBrowsing: 'Échec du lancement de la proposition!',
    failedToSubmitApplicationAndRedirect: 'Échec du lancement de la proposition!',
    failedToAddInsured: "Échec de l'ajout de l'assuré proposé.",
    failedToRemoveInsured: "Échec du retrait de l'assuré proposé.",
    failedToAssignLeads: "Une erreur s'est produite en assignant le contact.  Veuillez essayer à nouveau",
    failedToAssignApplications: "Une erreur s'est produite en assignant la proposition.  Veuillez essayer à nouveau",
    failedToCreateLead:
      "Une erreur s'est produite lors de la création du contact. Veuillez vous assurer que les informations soient valides.",
    failedToUpdateLead:
      "Une erreur s'est produite lors de la mise à jour du contact. Veuillez vous assurer que les informations soient valides.",
    failedToGetLead: "Une erreur s'est produite lors de la récupération de l'information du contact.",
    failedToSendInvitationEmail:
      "Une erreur s'est produite lors de l'envoi de l'invitation. Veuillez essayer à nouveau.",
    failedToUpdateUser:
      "Une erreur s'est produite lors de la mise à jour de l'utilisateur. Veuillez vous assurer que les informations soient valides.",
    failedToDeleteUser:
      "Une erreur s'est produite lors de la suppression de l'utilisateur. Veuillez essayer à nouveau.",
    failedToLoadProducts: 'There was an error retrieving the products',
    failedToLoadJetDecisionOutcomes:
      'Une erreur s’est produite lors de la récupération des données pour le "jet decision widget"',
    failedToFetchADOAndQuotes:
      "Une erreur s'est produite lors de la récupération de l'information sur les prix et du ADO min/max.",
    failedToFetchQuotes: "Une erreur s'est produite lors de la récupération de la soumission.",
    failedToFetchQuestionnaire: "Une erreur s'est produite lors de la récupération du questionnaire",
    failedToSubmitApplication: "Une erreur s'est produite lors de la tentative de soumission de la proposition.",
    failedToSubmitApplicationRefreshPage: 'La proposition n’est plus à jour. Veuillez rafraichir la page.',
    failedToSignApplication: "Une erreur s'est produite lors de la tentative de signature de la proposition.",
    submittedButFailedToSignApplication: 'Proposition soumise, mais échec de la signature',
    failedToUpdateApplicationAnswers: "Une erreur s'est produite lors de la sauvegarde du formulaire de proposition.",
    updatedADOMinMax: 'ADO min/max a été mis à jour',
    fetchingADOMinMax: 'Récupération du ADO min/max',
    failedToUpdateApplicationAnswersWithADOMinMax: "Une erreur s'est produite lors de la récupération du ADO min/max.",
    failedADOMinMaxDueToMissingNodeIds: 'Informations requises manquantes pour récupérer ADO.',
    failedToFetchPdfUrl: "Une erreur s'est produite lors de la récupération du lien PDF",
    failedToUpdateCoverageAmount: "Une erreur s'est produite lors de la sauvegarde du montant de la couverture",
    failedToCreateApplication: "Une erreur s'est produite lors de la création de la proposition",
    failedToLoadTheme: "Une erreur s'est produite lors de la récupération du thème.",
    failedToUpdateTheme: "Une erreur s'est produite lors de la mise à jour du thème.",
    settingsUpdateSuccess: 'Les paramètres ont été sauvegardés avec succès.',
    failedToLoadSettings: "Une erreur s'est produite lors de la récupération des paramètres",
    failedToCreateAPreview: "Une erreur s'est produite pendant la configuration de l'aperçu du questionnaire",
    failedToUpdateSettings: "Une erreur s'est produite lors de la mise à jour des paramètres",
    failedToUpdateNotificationPreferences:
      "Une erreur s'est produite lors de la mise à jour de vos préférences de notifications.",
    failedToSaveQuestionnaire: "Une erreur s'est produite lors de la sauvegarde du questionnaire.",
    saveQuestionnaireSuccess: 'Le questionnaire a été mis à jour avec succès.',
    fieldCopiedToClipboard: 'Champ copié dans le presse-papier.',
    fieldGroupCopiedToClipboard: 'Groupe de champs copié dans le presse-papier.',
    subsectionCopiedToClipboard: 'Sous-section copiée dans le presse-papier.',
    sectionCopiedToClipboard: 'Section copiée dans le presse-papier.',
    failedToPublishQuestionnaire: "Une erreur s'est produite lors de la publication du questionnaire.",
    publishQuestionnaireSuccess: 'Le questionnaire a été publié avec succès.',
    newDraftQuestionnaireSuccess: 'Un brouillon a été créée avec succès.',
    failedToCreateNewDraftQuestionnaire: "Une erreur s'est produite lors du brouillon.",
    failedToPublishQuestionnaireMissingEntityMappings:
      "Le questionnaire n'a pas été publié car il manque certains des mappings d'entités requis",
    failedToPublishQuestionnaireEntityMappingsParsingError:
      "Le questionnaire n'a pas été publié car il y a une erreur dans l'un des mappings d'entité associés",
    addFieldSuccess: 'Champ ajouté',
    addQuestionSuccess: 'Groupe de champs ajouté',
    addSubsectionSuccess: 'Sous-section ajouté',
    addSectionSuccess: 'Section ajouté',
    failedToFetchEntity: "Une erreur s'est produite lors de la récupération du {{entity}}",
    failedToCreateEntity: "Une erreur s'est produite lors de la création du {{entity}}",
    entityCreationSuccess: 'Création du {{entity}} avec succès.',
    cancelESignCeremonyFail: "Échec de la cérémonie d'annulation de signature électronique",
    cannotCancelCompletedESignCeremony: "Impossible d'annuler une cérémonie de signature électronique terminée",
    eSignatureRequestSent: 'Demande de signature envoyée avec succès.',
    eSignatureCancelled: 'Demande de signature annulée.',
    failedToUpdateEntity: "Une erreur s'est produite lors de la mise à jour du {{entity}}",
    failedToDeleteEntity: "Une erreur s'est produite lors de la suppression du {{entity}}",
    entityUpdateSuccess: '{{entity}} ont été mises à jour avec succès',
    entityDeleteSuccess: '{{entity}} ont bien été supprimées',
    premiumWasUpdated: 'La prime a été mise à jour',
    fetchingPremium: 'Récupération de la prime',
    debugToolbar: {
      failedAutoFillApplicationAnswers:
        "Une erreur s'est produite lors de la tentative de remplissage des informations jusqu'à la sous-section {{subsectionId}}",
    },
    // @deprecated
    failedToUploadAttachment: "Une erreur s'est produite lors du téléversement de la pièce jointe",
    failedToFetchAttachments: "Une erreur s'est produite lors du récupération des pièce jointe",
    failedToDeleteAttachment: "Une erreur s'est produite lors de la suppression de la pièce jointe",
    // end - @deprecated
    failedToFetchUploadedFiles: "Une erreur s'est produite lors du récupération des fichiers",
    failedToFetchUploadedFile: "Une erreur s'est produite lors du récupération du fichier",
    fileUploadSuccess: 'Fichier téléchargé avec succès',
    fileUploadError: {
      general: "Une erreur s'est produite lors du téléversement du fichier",
      UNACCEPTABLE_MIME_TYPE: 'Le fichier donné est dans un format inacceptable',
    },
    fileDeleteSuccess: 'Fichier supprimé avec succès',
    fileDeleteError: "Une erreur s'est produite lors de la suppression du fichier",
    failedToFetchFileTemplates:
      "Une erreur s'est produite lors du récupération des règles d'évaluation pour les fichiers",
    payment: {
      failedToLoadPaymentInformation: "Une erreur s'est produite lors du chargement des informations de paiement.",
      failedToSubmitPaymentMethod: "Une erreur s'est produite lors de la soumission des informations de paiement.",
      failedToUpdatePaymentInformation: "Une erreur s'est produite lors de la mise à jour des informations de paiement",
    },
    failedToExportData: "Erreur lors de l'exportation de données",
    exportDataSuccess: 'Export de données vers un fichier réussi',
    importedDataSuccess: 'Importation réussie de données depuis un fichier',
    failedToImportData: "Erreur lors de l'importation de données à partir d'un fichier",
    identityVerificationToast: {
      success: {
        title: 'InstantID® a été vérifié',
        description: 'InstantID® a vérifié et validé les informations de l’assuré proposé',
      },
      warning: {
        title: 'Problème(s) détecté(s)',
        description: "L'appel InstantID® a réussi mais requiert votre attention",
      },
      error: {
        title: "Un problème avec InstantID® s'est produit",
        description: "Un problème est survenu et la vérification InstantID® n'a pas pu être effectuée",
      },
      validationErrors: {
        title: "Une erreur de validation InstantID s'est produite",
        firstName: {
          pattern: "Veuillez vous assurer que le champ du prénom ne contient pas de caractères spéciaux ni d'espaces.",
          max: 'Veuillez vous assurer que le champ du prénom ne contient pas plus que 20 caractères.',
          empty: 'Le champ du prénom est requis.',
        },
        middleName: {
          pattern:
            "Veuillez vous assurer que le champ du deuxième nom ne contient pas de caractères spéciaux ni d'espaces.",
          max: 'Veuillez vous assurer que le champ du deuxième nom ne contient pas plus que 15 caractères.',
        },
        lastName: {
          pattern:
            "Veuillez vous assurer que le champ du nom de famille ne contient pas de caractères spéciaux ni d'espaces.",
          max: 'Veuillez vous assurer que le champ du nom de famille ne contient pas plus que 28 caractères.',
          empty: 'Le champ du nom de famille est requis.',
        },
        DOB: {
          year: {
            min: "Le champ de l'année de naissance ne peut pas être avant l'année 1900.",
            empty: "Le champ de l'année de naissance est requis.",
          },
          month: {
            min: "Le champ du mois de naissance ne peut pas être moins qu'un.",
            max: 'Le champ du mois de naissance ne peut pas être plus que 12.',
            empty: 'Le champ du mois de naissance field est requis.',
          },
          day: {
            min: "Le champ du jour de naissance ne peut pas être moins qu'un.",
            max: 'Le champ du jour de naissance ne peut pas être plus que 31.',
            empty: 'Le champ du jour de naissance est requis.',
          },
        },
        telephone: {
          classification: "Le champ du type de téléphone n'est pas valide",
          areaCode: {
            pattern: "Veuillez vous assurer que l'indicatif téléphonique ne contient que des chiffres.",
            max: "Veuillez vous assurer que l'indicatif téléphonique ne contient pas plus que trois chiffres.",
            empty: "L'indicatif téléphonique est requis.",
          },
          exchange: {
            pattern: 'Veuillez vous assurer que the telephone exchange number ne contient que des chiffres.',
            max: 'Veuillez vous assurer que the telephone exchange number ne contient pas plus que trois chiffres.',
            empty: 'The telephone exchange number est requis.',
          },
          number: {
            pattern: 'Veuillez vous assurer que the telephone line number ne contient que des chiffres.',
            max: 'Veuillez vous assurer que the telephone line number ne contient pas plus que quatre chiffres.',
            empty: 'The telephone line number est requis.',
          },
          extension: {
            pattern: 'Veuillez vous assurer que the telephone extension code ne contient que des chiffres.',
            max: 'Veuillez vous assurer que the telephone extension code ne contient pas plus que quatre chiffres.',
          },
        },
        currentAddress: {
          idPattern: "Veuillez vous assurer que l'ID de l'addresse actuelle correspond au modèle correctement.",
          street: {
            number: {
              pattern:
                "Veuillez vous assurer que le numéro civique de l'addresse actuelle ne contient que des chiffres.",
              max: 'Veuillez vous assurer que the current addresse street number ne contient pas plus que quatre chiffres.',
            },
            name: {
              pattern:
                "Veuillez vous assurer que le nom de la rue de l'addresse actuelle ne contient pas de caractères spéciaux.",
              max: "Veuillez vous assurer que le nom de la rue de l'addresse actuelle ne contient pas plus que 20 caractères.",
            },
            address1: {
              max: "Veuillez vous assurer que le champ de l'addresse courante 1 ne contient pas plus que 20 caractères.",
              empty: "Le champ de l'addresse courante 1 est requis.",
            },
            address2: {
              max: "Veuillez vous assurer que le champ de l'addresse courante 2 ne contient pas plus que 20 caractères.",
            },
          },
          city: {
            max: "Veuillez vous assurer que la ville de l'addresse actuelle ne contient pas plus que 20 caractères.",
            empty: "Le champ de la ville de l'addresse actuelle est requis.",
          },
          state: {
            max: "Veuillez vous assurer que l'état de l'addresse actuelle ne contient pas plus que deux caractères.",
            empty: "Le champ de l'état de l'addresse actuelle est requis.",
          },
          zip5: {
            max: "Veuillez vous assurer que le code postal de l'addresse actuelle ne contient pas plus que cinq caractères.",
            empty: "Le champ du code postal de l'addresse actuelle est requis.",
          },
          county: {
            pattern:
              "Veuillez vous assurer que le compté de l'addresse actuelle ne contient pas de caractères spéciaux.",
            max: "Veuillez vous assurer que le compté de l'addresse actuelle ne contient pas plus que 15 caractères.",
          },
          country: {
            max: "Veuillez vous assurer que le payé de l'addresse actuelle ne contient pas plus que 15 caractères.",
          },
          unitNumber: {
            pattern:
              "Veuillez vous assurer que le numéro de l'appartement de l'addresse actuelle ne conteint que des chiffres.",
            max: "Veuillez vous assurer que le numéro de l'appartement de l'addresse actuelle ne contient pas plus que cinq chiffres.",
          },
          postalCode: {
            max: "Veuillez vous assurer que le code postal de l'addresse actuelle ne contient pas plus que cinq caractères.",
          },
        },
      },
      serverErrors: {
        title: "Une problème du serveur InstantID s'est produite",
        timeoutError: "Un délai d'expiration s'est produit pendant la vérification InstantID.",
        addressError: "Une erreur d'addresse s'est produite pendant la vérification InstantID.",
        processError: "Une erreur de traitement s'est produite pendant la vérification InstantID.",
        applicationNotFound: "L'application ne pouvait pas être trouver pendant la vérification InstantID.",
      },
      timeoutError: {
        title: "Délai d'expiration du serveur InstantID",
        description: "Impossible de terminer la vérification de l'identité en raison du délai d'attente du serveur.",
      },
      unexpectedError: "Une erreur inattendue s'est produite",
      failedToCheckConsent: "Le consentement InstantID n'a pas été accepté",
    },
    deleteProposedInsuredSuccess: 'Personne à assurer supprimée avec succès',
    questionnaireBuilderPdfUploaded: 'PDF importé',
    failedToGetAgentLicenses: "Une erreur s'est produite lors de la récupération des licences d'agent",
    copyApplicationSuccess:
      'La proposition #{{refNoIncrement}} a été copiée avec succès. La proposition #{{copyRefNoIncrement}} a été créée.',
    failedToCopyApplication:
      "La proposition #{{refNoIncrement}} n'a pas pu être copiée en raison d'erreurs techniques. Veuillez essayer à nouveau.",
    saveApplicationSuccess: 'La proposition a été sauvegardée avec succès',
    dataExtractSuccess: "Demande d'extraction de données de contacts enregistrée.",
    dataExtractFailure: 'Demande non enregistrée. Veuillez réessayer plus tard.',
    getProductsWidgetTotalPremiumsFailure: "Une erreur s'est produite lors de la mise à jour du montant de la prime",
  },
  leadDetailDrawer: {
    newProfile: 'Nouveau Profil',
    connectedWithClient: 'Client contacté',
    checkSubscriptionStatus: "Vérifier le statut de l'inscription",
    isDeclarationSent: "J'ai envoyé la déclaration de représentation.",
    history: {
      showSystemStatus: 'Afficher les status système',
      header: {
        date: 'Date',
        user: 'Agent',
        event: 'Évènement',
      },
      statuses: {
        'statusUpdated/Unready': 'Brouillon',
        'statusUpdated/New': 'Nouveau',
        'statusUpdated/Invited': 'Invité',
        'statusUpdated/PlanFinderStarted': 'Analyse des besoins débuté',
        'statusUpdated/PlanFinderCompleted': 'Analyse des besoins complété',
        'statusUpdated/Engaged': 'Engagé',
        'statusUpdated/TransactionFlowStarted': 'Flux transactionnel débuté',
        'statusUpdated/TransactionFlowCompleted': 'Flux transactionnel complété',
        'statusUpdated/Qualified': 'Qualifié',
        archived: 'Archivé',
        unarchived: 'Désarchivé',
        deleted: 'Supprimé',
        emailSent: 'Courriel envoyé',
        'assist/ApplicationAccessed': 'Proposition consultée',
        assigned: 'Assigné',
        unassign: 'Désassigné',
      },
      statusTypes: {
        statusUpdate: 'Mise à jour du statut',
        archive: 'Archive',
        communication: 'Communication',
        assisted: 'Assisté',
        dispatch: 'Délégation',
      },
    },
  },
  userDetailDrawer: {
    newProfile: 'Nouveau profil',
    emailAddress: 'Adresse Courriel',
  },
  week: {
    '0': {
      full: 'Lundi',
      short: 'Lun',
    },
    '1': {
      full: 'Mardi',
      short: 'Mar',
    },
    '2': {
      full: 'Mercredi',
      short: 'Mer',
    },
    '3': {
      full: 'Jeudi',
      short: 'Jeu',
    },
    '4': {
      full: 'Vendredi',
      short: 'Ven',
    },
    '5': {
      full: 'Samedi',
      short: 'Sam',
    },
    '6': {
      full: 'Dimanche',
      short: 'Dim',
    },
  },
  inputs: {
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    documentName: 'Nom de Document',
    enDocumentName: 'Nom du document en Anglais',
    frDocumentName: 'Nom du document en Français',
    documentOrigin: 'Document Origin',
    documentType: 'Document Type',
    documentIdentifier: 'Document Identifier',
    externalDocumentUrl: 'External URL',
    documentVariant: 'Variant',
    preferredName: 'Prénom choisi',
    email: 'Courriel',
    phoneNumber: 'Téléphone',
    role: 'Rôle',
    category: 'Catégorie',
    type: 'Type',
    group: 'Groupe',
    userId: "Numéro d'identification",
    zipCode: 'Code zip',
    postalCode: 'Code postal',
    state: 'État',
    province: 'Province',
    securityDisclosure: 'Divulgation de sécurité',
    coverageAmount: 'Montant de la couverture révisé',
    language: 'Langue de correspondance',
  },
  validation: {
    ...engineValidation,
    error: {
      fname: '$t(inputs.firstName) invalide',
      lname: '$t(inputs.lastName) invalide',
      pname: '$t(inputs.preferredName) invalide',
      email: '$t(inputs.email) invalide',
      required: 'Ce champ est requis',
      unique: 'La valeur entrée doit être unique',
      phoneNumber: '$t(inputs.phoneNumber) invalide',
      zipCode: 'Le code zip doit avoir le format suivant: 99999 ou 99999-9999',
      postalCode: 'Le code postal doit avoir le format suivant: A1A 1A1',
      phoneNumberAreaCode: 'Code régional invalide',
      coverageLimitMax: 'Le montant de couverture maximal est {{amount}}. Veuillez ajuster le montant en conséquence.',
      coverageLimitMin: 'Le montant de couverture minimal est {{amount}}. Veuillez ajuster le montant en conséquence.',
      nodeIdInList: "L'option sélectionnée est valide",
      nodeIdNotInList: "L'option sélectionnée n'est pas dans la liste",
      mismatchedCollectionNodeIds: 'Les options sélectionnées appartiennent à différentes collections',
      nodeIdIsNumberField: "L'option sélectionnée est un nombre",
      nodeIdIsNotNumberField: "L'option sélectionnée n'est pas un nombre",
      minValue: 'La valeur minimale doit être inférieure à la valeur maximale',
      maxValue: 'La valeur maximale doit être supérieure à la valeur minimale',
      positive: 'La valeur entrée doit être positive',
      fileType: "Le type de fichier n'est pas valide",
      fileName: "Le nom de fichier n'est pas valide",
      modalFactor: 'Le facteur modal est invalide',
      duplicateSignersEmail: "Le numéro de téléphone et l'email doivent être uniques pour chaque signataire",
      duplicateAdvisorsEmail: "Le numéro de téléphone et l'email doivent être uniques pour chaque agent",
      productIdAlreadyExists: 'Il existe déjà un produit avec cet identifiant',
      percentMax: 'Le pourcentage ne peut pas dépasser 100%',
      ruleIdentifier: 'L’identifiant peut être un seul descriptif ou une série de descriptifs séparés par . ou -',
    },
  },
  alerts: {
    errorDetails: "Détails de l'erreur",
  },
  popper: {
    identityVerification: {
      responses: {
        identityVerificationCheck: {
          body: `Vérifier les informations de l'assuré proposé via InstantID® avant de soumettre la candidature`,
          checkWithInstantIdButton: 'Vérifier avec InstantID',
        },
        identityVerificationTimeoutRetry: {
          body: `Vérifier les informations de l'assuré proposé via InstantID® avant de soumettre la candidature`,
          checkWithInstantIdButton: 'Réessayer InstantID®',
        },
        identityVerificationFailedRetry: {
          body: `InstantID® a identifié au moins un problème lors de la vérification des information de l'assuré proposé`,
          reportLink: 'Voir le rapport InstantID®',
          checkWithInstantIdButton: 'Réessayer InstantID®',
        },
        identityVerificationSuccess: {
          body: "InstantID® a été vérifié et a validé les informations de l'assuré proposé",
        },
      },
    },
    licenseVerification: {
      header: 'La licence a été vérifiée',
      status: {
        neutral:
          "Votre permis ne peut pas être vérifié tant que la province de résidence du titulaire du contrat n'a pas été choisie",
        valid: 'La licence a été vérifiée et validée afin de continuer avec cette application.',
        invalidJurisdiction:
          'La licence a été vérifié, mais vous devez être autorisé à vendre dans la province de résidence du propriétaire. Cependant, vous pouvez continuer et soumettre cette politique',
        expired:
          'La licence a été vérifiée mais doit être renouvelée. Cependant, vous pouvez continuer et soumettre cette candidature',
      },
    },
  },
  modals: {
    assistedApplication: {
      submissionDetails: {
        modalTitle: 'Détail de la soumission',
        confirmationNumber: 'Numéro de confirmation à conserver dans vos dossiers:',
        digitalSignatureKey: 'Clé de siganture digitale',
      },
      submitApplicationView: {
        submissionView: {
          responses: {
            accepted: {
              title: 'La proposition a été soumise avec succès!',
              body: `Le consommateur recevra la confirmation de la décision par courriel. La police d’assurance entrera en vigueur seulement après réception de cette confirmation.`,
            },
            referred: {
              title: 'La proposition a été soumise avec succès!',
              body: `<p>Certaines validations sont requises pour confirmer l'acceptation de la proposition.</p>
              <p>Le consommateur recevra la confirmation de la décision par la poste d'ici 5 à 10 jours ouvrables. La police d’assurance entrera en vigueur seulement lorsque celui-ci recevra cette confirmation.</p>`,
            },
            denied: {
              title: 'La proposition a été refusée',
              body: 'Certaines réponses que vous avez fournies dans le questionnaire ne nous permettent pas de donner suite à votre demande.',
              status: 'Statut: ',
              outcomes: {
                [OutcomeCode.denied]: 'Refusé',
                [OutcomeCode.blocked]: 'Bloqué',
                [OutcomeCode.blockedAtQuoter]: 'Bloqué au devis',
              },
            },
          },
          downloadPdf: 'Téléchargez votre proposition',
        },
        errorView: {
          title: 'Une erreur est survenue!',
          somethingWentWrong: `<p>Une erreur s'est produite lors de la soumission de la proposition.</p><p> Veuillez essayer à nouveau.</p>`,
        },
      },
      sendESignatureRequest: {
        body1:
          'En cliquant sur “Envoyé à la signature” tous les signataires recevront un courriel d’invitation pour signer et un code de validation par SMS.',
        body2: 'Avez-vous validé toutes les informations de contact?',
      },
      cancelESignature: {
        body1: 'Vous vous apprêtez à annuler la demande de signature. Cette action est irréversible.',
        body2: 'Si vous continuez, les parties prenantes ne pourront pas accéder aux documents pour signature.',
      },
      trackESignature: {
        title: 'Suivre la progression de la signature',
        body: 'Obtenez le statut des signatures et de la souscription à tout moment en cliquant sur le bouton Signature en haut à droite.',
        checkboxLabel: 'Ne plus afficher ce message',
        confirmButton: "J'ai compris",
      },
      fileAttachment: {
        modalTitle: 'Fichiers et documents',
        uploadButton: 'Téléverser un fichier à cette proposition',
        uploadedOn: 'Téléversé le {{date}}',
        errorUploadLimitExceeded:
          '{{filename}} dépasse la taille maximale de téléversement ({{size}}) sur la plateforme.',
        errorUploadInvalidName: 'Nom du fichier ne peut contenir des caractères spéciaux ({{specialChars}})',
      },
      addPaymentMethod: {
        creditCard: {
          modalTitle: 'Informations de carte de crédit',
          informationText:
            'Cette carte de crédit sera débitée de la première prime après avoir soumis avec succès la proposition, puis les paiements mensuels récurrents des primes suivantes.',
        },
      },
      deleteProposedInsured: {
        title: "Supprimer l'assuré",
        body1: 'Êtes-vous sûr de vouloir supprimer <i>{{name}}</i> ?',
        body2: 'Toutes les informations associées à cet assuré seront également supprimées.',
      },
    },
    promptCompleteProfile: {
      welcome: 'Bienvenue!',
      fullAccess: "Afin d'utiliser la plateforme à son plein potentiel, veuillez compléter votre profil.",
      submit: 'Compléter mon profil',
    },
    sendEmail: {
      topics: 'Sujets',
      selectNeedsAnalysis: "Sélectionner l'analyse des besoins que vous désirez envoyer:",
      willBeSentTo: "Une invitation à remplir l'Analyse des besoins sera envoyée à:",
      insuranceScopesSendingMessage: 'Vous vous apprêtez à envoyer un questionnaire au sujet de:',
      copyLink: "Copier le lien de l'invitation",
      invalidEmail:
        'Est-ce vraiment le bon courriel? Cet adresse courriel a déjà été invalidée. Si vous en êtes, envoyez à nouveau.',
      confirmSend: "Est-ce vraiment le bon courriel? Si vous êtes certain, envoyez l'invitation à nouveau.",
    },
    sharePublicLink: {
      title: 'Partager votre lien public',
      copyLinkLabel: 'Copier votre lien public et le partager dans votre réseau',
      copyPublicLink: 'Copier mon lien public {{scope}}',
      infoMessage: 'Vous pouvez changer vos paramètres de copie du lien public par défaut dans votre',
      profilePage: 'page de profil',
      insuranceScopesSharingMessage: 'Votre lien public inclut des questions au sujet de:',
    },
    notificationPreferences: {
      title: 'Préférences de Notification',
      body: 'Sélectionner le type de notification courriel que vous désiré recevoir.',
      emails: {
        [NotificationTypes.leadScheduledACallEmail]: 'Contactez-moi quand un contact fait une demande de rencontre.',
        [NotificationTypes.leadCompletedNeedsAnalysisEmail]:
          "Contactez-moi quand un contact complète l'analyse des besoins.",
      },
    },
    editUser: {
      title: 'Mon profil',
      contactInfo: 'Information de contact',
      uploadProfilePicture: 'Télécharger une photo',
      mySharingPreferences: 'Mes Préférences de partage',
      selectDefaultPreference:
        'Veuillez sélectionner un sujet par défaut pour le partage de mes liens et les invitations:',
      clickToCopy: 'Cliquer sur le bouton pour copier le lien:',
      copyLinkToClipboard: 'Copier le lien',
      openInNewTab: 'Ouvrir le lien dans un nouvel onglet',
      platformLanguage: 'Langue',
      selectAPlatformLanguage: "Veuillez sélectionner la langue d'affichage de préférence",
      selectASharingPreference:
        "Sélectionner un sujet ci-haut et sauvegarder votre profil pour accéder à votre bouton 'Copier le lien'",
      saveSharingPreferences: 'Sauvegarder votre profil pour conserver les préférences.',
      insuranceScopeDisabled:
        "Basé sur vos droits et permissions, vous ne pouvez pas modifier le type d'Analyse des besoins que vous pouvez partager. Pour plus d'information, contactez votre administrateur.",
      agencyName: "Nom de l'Agence",
      revertAgencyName: 'Réinitialiser au nom par défaut',
      securityDisclosureToolTip:
        'Veuillez inscrire votre Divulgation de titres (si applicable) et la soumettre au processus de révision standard.',
    },
    deleteLead: {
      deleteLeadTitle: 'Effacer Contact',
      deleteApplicationTitle: 'Effacer Proposition',
      deleteLeadConfirmation: 'Êtes-vous certain de vouloir supprimer ce contact?',
      deleteApplicationConfirmation: 'Êtes-vous certain de vouloir supprimer cette proposition?',
      irreversible: 'Ceci est irréversible.',
    },
    copyApplication: {
      copyApplicationChooseContent:
        'Veuillez choisir les informations de la proposition #{{refNoIncrement}} qui seront copiées dans une nouvelle proposition.',
      insured: "Information de l'assuré",
      owner: 'Information du propriétaire',
      copyApplicationMissingSectionsError: 'Vous devez sélectionner au moins une section',
    },
    userDetails: {
      title: 'Créer un utilisateur',
      selectASharingPreferenceLabel:
        'Sélectionner le choix par défaut pour le partage des liens et les invitations par courriel (optionel):',
    },
    confirmDeleteUser: {
      title: 'Supprimer un utilisateurDelete User',
      confirmDelete: "Êtes-vous certain de vouloir supprimer l'utilisateur suivant?",
    },
    confirmDeleteFile: {
      title: 'Supprimer le fichier',
      optionalFile: {
        body: 'Êtes-vous certain de vouloir supprimer ce fichier?',
      },
      requiredFile: {
        body1:
          "Si vous supprimez un fichier requis, vous devez le téléverser d'encore pour compléter cette proposition.",
        body2: 'Êtes-vous certain de vouloir supprimer ce fichier?',
      },
    },
    sendUserInvitationModal: {
      title: 'Envoyer un rappel',
      willBeSentTo: "Un rappel à l'utilisateur de se connecter à la plateforme sera envoyé à:",
    },
    leadsAssign: {
      assignSingleLeadTitle: 'Assigner ce contact à',
      assignMultipleLeadsTitle: 'Assigner ces {{numberOfLeads}} contacts à',
      assignSingleApplicationTitle: 'Assigner cette proposition à',
      assignMultipleApplicationsTitle: 'Assigner ces {{numberOfLeads}} propositions à',
      unassignedTitle: 'Désassigner',
      unassignedLeadCardSubtitle: 'Ce contact ne sera assigné à aucun utilisateur',
      unassignedApplicationCardSubtitle: 'Cette proposition ne sera assignée à aucun utilisateur',
      assignToMe: 'Assigner à moi',
    },
    leadsListFilters: {
      filterLeadsTitle: 'Filtrer les contacts par Planificateur',
      filterApplicationsTitle: 'Filtrer les propositions par Planificateur',
      submit: 'Soumettre',
      me: 'Moi',
      all: 'Tous les utilisateurs',
      unassigned: 'Désassigné',
      showAllLeads: 'Afficher tous les contacts',
      showAllApplications: 'Afficher toutes les propositions',
      showUnassignedLeads: 'Afficher tous les contacts désassignés',
      showUnassignedApplications: 'Afficher toutes les propositions désassignés',
      showMyLeads: 'Afficher les contacts assignés à moi',
      showMyApplications: 'Afficher les propositions assignées à moi',
    },
    leadsArchive: {
      leadNotAssignedToYou: "Ce contact n'est pas assigné à vous.",
      applicationNotAssignedToYou: "Cette proposition n'est pas assignée à vous.",
      archiveConfirmationQuestion: 'Êtes-vous certain de vouloir archiver ce contact',
      unarchiveConfirmationQuestion: 'Êtes-vous certain de vouloir désarchiver ce contact',
      archiveLeadTitle: 'Contact archivé',
      archiveApplicationTitle: 'Proposition archivée',
      unarchiveLeadTitle: 'Contact désarchivé',
      unarchiveApplicationTitle: 'Proposition désarchivée',
    },
    filesDownload: {
      title: 'Fichiers et documents',
    },
    importUsers: {
      importUsers: 'Importer des utilisateurs',
      import: 'Importer',
      success: "L'importation des utilisateurs s'est déroulée avec succès. Voici le sommaire: ",
      invalidFileLength: "Le fichier d'importation doit contenir au moins un utilisateur.",
      invalidFileHeader: "En-tête manquant ou invalide dans le fichier d'importation.",
      maxUsersExceeded: 'Une maximum de {{maxNumberOfUsers}} utilisateurs peut être importé à la fois.',
      invalidNumberOfColumn:
        "Impossible d'extraire les utilisateurs. {{nbOfColumnRequired}} colonnes devraient être spécifiées par rangée et seulement {{nbOfColumnSpecified}} sont spécifiées. Rangée: [{{data}}].",
      invalidFileFormat: "Le format de fichier n'est pas supporté. Les formats supportés sont: {{extensions}}",
      fileParsingError:
        "Une erreur s'est produite lors de l'importation du fichier. Veuillez vous assurer que le format est compatible",
      generalError: "Une erreur s'est produite lors de l'importation des utilisateurs.",
    },
    agentLicenses: {
      title: 'Licences',
      provinceOrState: 'Province / État',
      status: 'Satut du Licence',
      expirationDate: "Date d'Éxpiration",
      type: 'Type',
      active: 'Active',
      expired: 'Expirée',
    },
    extractDataConsent: {
      title: 'Extract all lead data',
      body: 'Please note that the Zinnia Hybrid Origination platform is not storing the data extract file that you are requesting. Once the file has been transferred successfully to your assigned sFTP server, the file will be deleted from our database. By clicking the Acknowledge button, you are accepting that Zinnia is not responsible to retain a file each time this data extract feature is executed.',
      submitButton: 'Acknowledge',
    },
    submitPaperApp: {
      title: 'Soumission finale',
      body: 'Soumission de la proposition',
      submitButton: 'Soumettre',
      submitSuccessMessage: 'La proposition a été soumise avec succès',
      submitErrorMessage: 'Une erreur s’est produite',
    },
  },
  tabs: {
    active: {
      empty: {
        hello: 'Bonjour {{name}}',
        noApplication: 'Vous n’avez aucune proposition en ce moment.',
        startBy: "Commencez la création d'un nouveau contact.",
      },
      searchEmpty: "Il n'y a aucun contact actif qui concorde avec la recherche",
    },
    archived: {
      empty: {
        hello: 'Bonjour {{name}}',
        thereAreNoLeads: "Vous n'avez aucun contact archivé",
      },
      searchEmpty: "Il n'y a aucun contact archivé qui concorde avec la recherche",
    },
    needsAnalysis: {
      sent: 'Analyse des besoins envoyée',
      details: 'Détails',
      back: 'Précédent',
      notSent: {
        notSentYet: "Aucune Analyse des besoins n'a encore été envoyé à ce contact.",
        wantToSendItNow: "Voulez-vous l'envoyer maintenant?",
      },
      pending: {
        pendingInfo: "En attente d'information",
        hasBeenSent: 'Une invitation a déjà été envoyée',
      },
    },
  },
  logout: {
    text: 'Vous êtes maintenant déconnecté de la plateforme.',
  },
  product: {
    roundedAmount: 'Le montant doit être arrondi à {{amount}}',
    missingNeedsAnalysisInformation:
      "Il manque des informations pour confirmer l'éligibilité et calculer le prix pour ce produit. Veuillez inviter le contact à remplir l'Analyse des besoins.",
  },
  sidebar: {
    customers: 'Contacts',
    applications: 'Applications',
    admin: 'Admin',
    logo: 'Logo',
  },
  admin: {
    importData: {
      tooltip: 'Importer',
      blueprintsAndSalesDecisionRules: 'Importer le plan du Questionnaire et Règles de décision de vente',
      insuranceProductsAndFirms: "Importer des listes de produits et d'entreprises",
      theme: 'Importer le fichier de Thème',
      settings: 'Importer un fichier de paramètres',
    },
    exportData: {
      tooltip: 'Exporter',
    },
    themeManagement: {
      title: 'Thème',
      subtitle: 'Personalisez la plateforme Breathe Life pour que le thème concorde avec vos couleurs.',
      colors: {
        title: 'Couleurs',
        primary: 'Primaire',
        secondary: 'Secondaire',
        tertiary: 'Tertiaire',
      },
      logos: {
        title: 'Logos',
        compactButtonText: 'Modifier le logo compact',
        regularButtonText: 'Modifier le logo régulier',
      },
      navigationSidebarVariant: {
        title: 'Modification de la barre latérale',
        options: {
          [NavigationSidebarVariants.primary]: 'Primaire',
          [NavigationSidebarVariants.secondary]: 'Secondaire',
          [NavigationSidebarVariants.light]: 'Claire',
        },
      },
    },
    settingsManagement: {
      title: 'Paramètres',
      subtitle: 'Définition des paramètres de la plateforme Breathe Life',
      languages: {
        title: 'Langue de préférence',
        enabledLanguagesLabel: 'Sélectionnez les langues supportées',
        defaultLanguageLabel: 'Sélectionnez la langue par défaut',
        enabledLanguagesError: 'Vous devez sélectionner au moins une langue',
        defaultLanguageError: 'La langue par défaut doit être parmi les langues supportées',
        options: {
          [Language.en]: 'Anglais',
          [Language.fr]: 'Français',
        },
      },
      insuranceScopes: {
        title: "Paramètres de Catégorie d'Assurance",
        insuranceScopesLabel: "Sélectionner les catégories d'assurance à activer",
        defaultInsuranceScopeLabel: "Sélectionner la catégorie d'assurance par défaut",
        enabledInsuranceScopesError: "Vous devez sélectionner au moins une catégorie d'assurance",
        defaultInsuranceScopesError: "Une catégorie d'assurance par défaut doit être sélectionnée",
        options: {
          [InsuranceScopes.life]: 'Vie',
          [InsuranceScopes.retirement]: 'Retraite',
        },
      },
      carrierNames: {
        title: 'Paramètres de nom de la compagnie',
        carrierNameEmpty: 'Au moins un nom de compagnie doit être inscrit',
        label: 'Entrer le nom de la compagnie - {{language}}',
        placeholder: 'Nom de compagnie - {{language}}',
      },
      publicLink: {
        title: 'Paramètres du lien public',
        enablePublicLink: 'Activer le lien public',
        titleLabel: 'Titre - {{language}}',
        descriptionLabel: 'Description - {{language}}',
        imageLabel: 'Image - {{language}}',
        imageButtonText: "Modifier l'image du lien public - {{language}}",
      },
      consumerFooter: {
        title: 'Paramètres de bas de page',
        subtitle: "L'année en cours peut être affichée en ajoutant {year} dans le texte",
        label: 'Entrer texte pour le bas de page - {{language}}',
      },
    },
    questionnaireManagement: {
      title: 'Questionnaire',
      version: 'Version {{majorVersion}}.{{minorVersion}} - {{description}}',
      emptyDescription: 'Aucun description',
      nodeIds: 'Identifiants de réponse de champ',
      questionnaireEditorData: "Donnée de l'éditeur de questionnaire",
      questionnaireListData: 'Liste des données de questionnaire',
      draftQuestionnaireVersion: 'Brouillon',
      autoSaving: 'Sauvegarde...',
      publish: 'Publier',
      createDraft: 'Créer un brouillon',
      newDraft: 'Nouveau brouillon',
      open: 'Ouvrir',
      preview: 'Aperçu',
      previewRequirements: {
        [QuestionnairePreview.consumerFlow]:
          "Vous devez configurer au moins un produit et de sélectionner un type d'aperçu",
        [QuestionnairePreview.assistedApplication]:
          "Vous devez configurer au moins un produit et de sélectionner un type d'aperçu",
        [QuestionnairePreview.pdf]:
          "Besoin de soumettre un application dans l'aperçu de flow consommateurs OU dans l'aperçu d'application assistée",
      },
      previewDescription:
        "Veuillez sélectionner le type d'affichage pour l'aperçu. L'aperçu apparaîtra dans votre navigateur.",
      previewOptions: {
        consumerFlow: 'Flow consommateurs',
        assistedApplication: 'Application assistée',
        pdf: 'PDF',
      },
      return: 'Retour à la liste de questionnaire',
      advancedOptions: {
        section: 'Options de section avancées',
        subsection: 'Options de sous-section avancées',
        question: 'Options de groupe de champs avancées',
        field: 'Options de champ avancées',
      },
      dynamicOptions: {
        removeModal: {
          title: 'Enlever les options dynamiques',
          content:
            'Voulez-vous enlever les options dynamiques ? Les node ids sélectionnés et les conditions, que vous avez configurés, seront perdus.',
          confirmButton: 'Supprimer les options dynamiques',
        },
        title: 'Options dynamiques',
        pickCollection: "Choisissez le node id d'une collection.",
        pickSelect: 'Former un libellé avec les node ids de cette collection.',
        note: '*Note: Les options dynamiques sont toujours ajoutées après les options statiques.',
      },
      jsonEditor: {
        title: 'Modifier le questionnaire via JSON',
        tooltip: 'Modifier via JSON',
        blueprintEditorTitle: 'Plan du questionnaire',
        partsViewerTitle: 'Sections du questionnaire',
      },
      description: 'Description',
      publishQuestionnaire: {
        modalTitle: 'Publier le questionnaire',
        modalDetails:
          'La publication d’une mise à jour du questionnaire la rendra applicable à toutes les <strong>nouvelles</strong> propositions.  Les propositions qui auront préalablement été commencées demeureront sur la version initiale.',
      },
      createDraftQuestionnaire: {
        modalTitle: 'Créer un brouillon de questionnaire',
        modalDetails: `Copie le questionnaire actuel dans une nouvelle version brouillon. La version publiée ne sera pas modifiée.`,
      },
      missingTitle: {
        section: 'Section sans titre',
        subsection: 'Sous-section sans titre',
        question: 'Groupe de champs sans titre',
        field: 'Champ sans titre',
      },
      confirmDeleteModal: {
        title: 'Confirmer la supression',
        defaultContentWithoutResourceName: 'Êtes-vous certain de vouloir supprimer ceci?',
        defaultContent: 'Êtes-vous certain de vouloir supprimer {{resourceName}}?',
        section: 'cette section',
        subsection: 'cette sous-section',
        selectOption: 'cette option',
        fieldGroup: 'ce groupe de champs',
        field: 'ce champ',
      },
      input: {
        sectionGroupTitle: 'Titre du groupe de section ({{language}})',
        sectionTitle: 'Titre de section',
        sectionIcon: 'Icon de section',
        subsectionTitle: 'Titre de sous-section',
        subsectionText: 'Texte de sous-section',
        subsectionIcon: 'Icon de la sous-section',
        subsectionNextStepButtonText: "Texte du bouton de l'étape suivante de la sous-section",
        questionTitle: 'Titre de groupe de champs',
        questionText: 'Texte de groupe de champs',
        fieldTitle: 'Titre de champ',
        fieldText: 'Texte de champ',
        fieldPlaceholder: 'Texte fantôme',
        fieldLabel: 'Étiquette de champ',
        fieldType: 'Type de champ',
        infoFieldStyling: "Style de champ d'information",
        dataLabel: 'Étiquette de données',
        subsectionVariant: 'Variante de sous-section',
        requiredField: 'Champ requis',
        disabledField: 'Champ désactivé',
        validationType: 'Validation',
        answerStorageNodeId: 'Réponse de champ',
        repeatableQuestionNodeId: 'Réponse de collecte répétable',
        options: 'Options',
        consentModalHeader: 'En-tête modal de consentement',
        consentText: 'Texte de consentement',
        agreeFieldTextTooltip: "Texte du bouton qui ouvre la fenêtre de consentement dans l'application assistée",
        agreeFieldTitleTooltip:
          "Texte du bouton de confirmation dans la fenêtre de consentement de l'application assistée, et texte à côté de la case à cocher pour accepter dans le flow consommateurs",
        buttonText: 'Texte du bouton',
        buttonTextAndLanguage: 'Texte du bouton ({{language}})',
        consentTextTooltip:
          "Texte de consentement qui figure dans la fenêtre de consentement de l'application assistée; texte qui figure au dessus de la case à cocher dans le flow consommateurs",
        consentModalHeaderTooltip: "En-tête de la fenêtre de consentement dans l'application assistée",
        consentModalHeaderAndLanguage: 'En-tête Modal ({{language}})',
        consentTextAndLanguage: 'Texte Modal ({{language}})',
        autocompleteCountryCode: "Code pays de l'adresse autocomplete",
        autocompleteCountryCodes: {
          US: 'États-Unis',
          CA: 'Canada',
        },
        autocompleteFieldIsTheStreetAddressField: "Ce champ est l'adresse",
        autocompleteNodeId: "Node Id saisie automatique de l'adresse",
        autocompleteStreetAddressField: "Champ d'adresse",
        autocompleteCityField: 'Champ de la ville',
        autocompleteStateOrProvinceField: 'Champ état ou province',
        autocompletePostalCodeOrZipField: 'Champ de code postal ou zip',
        addOption: 'Ajouter une option',
        addField: 'Ajouter un champ',
        addQuestion: 'Ajouter une groupe de champs',
        addSubsection: 'Ajouter une sous-section',
        addSection: 'Ajouter une section',
        textAndLanguage: 'Texte ({{language}})',
        titleAndLanguage: 'Titre ({{language}})',
        placeholderAndLanguage: 'Texte fantôme ({{language}})',
        addButtonTextAndLanguage: 'Bouton ajouter texte ({{language}})',
        removeButtonTextAndLanguage: 'Bouton supprimer texte ({{language}})',
        minRepeatable: 'Répétitions minimales',
        maxRepeatable: 'Répétitions maximales',
        iconName: "Nom de l'icône",
        fieldSize: 'Dimension du champ',
        forceNewLine: 'Forcer une nouvelle ligne',
        validationMessageAndLanguage: "Message d'erreur de validation ({{language}})",
        createOption: {
          title: 'Créer une option',
          optionId: "Identifiant de l'option",
        },
        createField: {
          title: 'Créer une champ',
        },
        createQuestion: {
          title: 'Créer une groupe de champs',
        },
        createSubsection: {
          title: 'Créer une sous-section',
        },
        createSection: {
          title: 'Créer une {{sectionGroupTitle}} section',
        },
        renameOption: {
          title: "Renommer l'identifiant de l'option",
          optionId: "Identifiant de l'option",
        },
        noneSet: 'Aucun défini',
        requiredIfAnySet: 'Obligatoire si une autre valeur est définie',
        productFieldAnswers: 'Réponses sur les champs de produits',
        coverageAmountFieldAnswer: 'Réponse du champ montant de couverture',
        numericalDataType: 'Type de données numériques',
        premiumFieldAnswer: 'Réponse de champ premium',
        displayAsCard: `Afficher comme une carte dans l'application assistée`,
        displayInCardPreview: `Afficher dans l'aperçu de la carte dans l'application assistée`,
        showInNavigation: `Afficher dans le menu de navigation de l'application assistée`,
        pageBreakSubSectionInPdf: 'Placer cette sous-section sur une nouvelle page du PDF',
        checked: 'Cochée',
        unchecked: 'Non cochée',
        noDefault: 'Aucune valeur',
        defaultValue: 'Valeur par défaut',
        numberOfInsured: "Nombre d'Assurés",
        participantRole: 'Rôle de participant',
      },
      editSectionGroup: 'Éditer la groupe de section',
      editSection: 'Éditer la section',
      editSubsection: 'Éditer la sous-section',
      editQuestion: 'Éditer la question',
      editField: 'Éditer le champ',
      editFieldHeading: 'Champ de {{fieldType}}',
      editInfoSupplement: 'Éditer le tooltip',
      hideSection: 'Fermer',
      remove: 'Supprimer',
      show: 'Afficher',
      hide: 'Cacher',
      hiddenFields: 'Champs cachés:',
      hiddenFieldGroups: 'Groupe de champs cachés:',
      infoSupplement: 'Tooltip',
      repeatable: 'Répétable',
      importFromCopyDeck: 'Importer du Copy Deck',
      importFromCopyDeckSuccess: 'Importation du Copy Deck réussie',
      importFromCopyDeckListErrors: "Liste des erreurs d'importation",
      clipboard: {
        copySection: 'Copier la section',
        copySubsection: 'Copier la sous-section',
        copyFieldGroup: 'Copier le groupe de champs',
        copyField: 'Copier le champ',
        pasteSection: 'Coller la section',
        pasteSubsection: 'Coller la sous-section',
        pasteFieldGroup: 'Coller le groupe de champs',
        pasteField: 'Coller le champ',
      },
      search: {
        results: {
          singular: 'Résultat',
          plural: 'Résultats',
        },
      },
      rules: {
        visibility: {
          edit: 'Éditer les conditions de visibilité',
          conditions: 'Conditions de visibilité',
        },
        validation: {
          addCondition: 'Ajouter une condition de validation',
          edit: 'Éditer les conditions de validation',
          conditions: 'Conditions de validation',
          messagePrompt: "Message d'échec: {{message}}",
        },
        addCondition: 'Ajouter une condition',
        removeCondition: 'Supprimer la condition',
      },
      fieldTypes: {
        input: 'Texte',
        radio: 'Bouton radio',
        dropdown: 'Menu déroulant',
        checkboxGroup: 'Sélection Multiple',
        number: 'Numérique',
        money: 'Devise',
        date: 'Date',
        phone: 'Téléphone',
        checkbox: 'Case à cocher',
        textarea: 'Zone de texte',
        information: 'Information',
        button: 'Bouton',
        currencyCard: 'Carte de devise',
        autocomplete: "Saisie automatique de l'adresse",
        yearMonth: 'Date (YYYY-MM)',
        agree: 'Consentement',
        signature: 'Signature electronique',
      },
      infoFieldStyles: {
        info: 'Info',
        paragraph: 'Paragraphe',
        success: 'Succès',
        warning: 'Avertissement',
        error: 'Erreur',
      },
      subsectionVariants: {
        [SubsectionVariant.summary]: 'Sommaire',
        [SubsectionVariant.quoter]: 'Soumission',
        simpleQuoter: 'Soumission simple',
        [SubsectionVariant.payment]: 'Paiement',
        [SubsectionVariant.submission]: 'Soumission finale',
        hideHeader: 'Cacher le Header',
        displayInline: 'Afficher sur une ligne',
        variantWarning:
          "Cette fonctionalité n'est pas disponible pour toues les compagnies. Au besoin, consultez l'équipe d'ingénieurie avant de l'utiliser.",
        [SubsectionVariant.addon]: 'Complément',
        [SubsectionVariant.productSummary]: 'Résumé du produit',
        [SubsectionVariant.productSelection]: 'Sélection de produit',
      },
      dataLabels: {
        inherit: 'Hériter du parent',
        Unknown: 'Inconnu',
        KnownSafe: 'Connu et sûr',
        PII: 'IIP',
        PHI: 'ISP',
        IdentifiablePHI: 'ISP identifiable',
        ReferenceNumber: 'Numéro de référence',
        PostalCode: 'Code postal',
        DateOfBirth: 'Date de naissance',
        IdentifiableDate: 'Date identifiable',
        NonIdentifiableDate: 'Date non identifiable',
        FreeFormText: 'Texte entrée libre',
        AgeAtServiceTime: 'Âge au moment du service',
        Language: 'Langue',
        AdvisorEmail: 'Courriel du conseiller',
        LoginEmail: 'Courriel du connexion',
        InsuredPersonEmail: "Courriel de l'assuré proposé",
        LeadEmail: 'Courriel prospect',
        Email: 'Courriel',
      },
      validateAs: {
        string: 'Aucun',
        date: 'Aucun',
        boolean: 'Aucun',
        booleanTrue: 'Doit accepter les conditions',
        integer: 'Integer',
        decimal: 'Décimal',
        percentage: 'Pourcentage',
        yearMonth: 'YYYY-MM',
        yearMonthPastDate: 'YYYY-MM (Date antérieure)',
        yearMonthPastOrCurrentDate: "YYYY-MM (Date antérieure ou aujourd'hui)",
        yearMonthFutureDate: 'YYYY-MM (Date ultérieure)',
        yearMonthFutureOrCurrentDate: "YYYY-MM (Date ultérieure ou aujourd'hui)",
        futureDate: 'Date ultérieure',
        futureOrCurrentDate: "Date ultérieure ou aujourd'hui",
        pastDate: 'Date antérieure',
        pastOrCurrentDate: "Date antérieure ou aujourd'hui",
        currentDate: 'La date du jour',
        email: 'Courriel',
        sin: 'NAS',
        ssn: 'SSN',
        canadianPostalCode: 'Code postal',
        zipCode: 'Code zip',
        phone: 'Phone',
        branchNumber: 'Numéro de la succursale',
        institutionNumber: "Numéro d'établissement",
        accountNumber: 'Numéro de compte',
        firstName: 'Prénom',
        lastName: 'Nom de famille',
        preferredName: 'Prénom choisi',
        signature: 'Signature electronique',
      },
      triggerStepNavigation: {
        label: 'Navigue à la prochaine étape',
        description:
          "Lorsqu'il est activé, indique qu'un clic sur un bouton tentera de passer à l'étape suivante si le formulaire actuel est valide.",
      },
      fieldWidthOptions: {
        [OptionSize.half]: 'Demi',
        [OptionSize.full]: 'Entier',
        [OptionSize.oneThird]: 'Un Tiers',
      },
      fieldSizes: {
        [FieldSizes.full]: 'Entier',
        [FieldSizes.threeQuarters]: 'Trois quarts',
        [FieldSizes.twoThirds]: 'Deux tiers',
        [FieldSizes.half]: 'Demi',
        [FieldSizes.third]: 'Un Tiers',
        [FieldSizes.quarter]: 'Un Quart',
      },
      pdfDocuments: {
        title: 'Document(s) PDF',
        [PdfDocumentType.application]: 'PDF de proposition ',
        [PdfDocumentType.advisorsReport]: `PDF d'agent`,
        [PdfDocumentType.carrierReport]: 'PDF de compagnie',
        [PdfDocumentType.external]: 'Externe',
        [PdfDocumentType.oneSpanEvidenceSummary]: 'OneSpan Résumé des preuves',
      },
      rendering: {
        title: 'Rendu :',
        [QuestionnaireBlueprintRenderOn.web]: 'Web',
        [QuestionnaireBlueprintRenderOn.pdf]: 'PDF',
        [QuestionnaireBlueprintRenderOn.summary]: 'Sommaire',
      },
      platforms: {
        title: 'Flux :',
        [PlatformType.producer]: 'Agent',
        [PlatformType.consumer]: 'Consommateur',
      },
      scope: {
        title: 'Vie / Retraite :',
        [InsuranceScopes.retirement]: 'Retraite',
        [InsuranceScopes.life]: 'Vie',
      },
      module: {
        title: 'Appartient à: ',
        [InsuranceModule.insuranceApplication]: 'Proposition',
        [InsuranceModule.needsAnalysis]: 'Analyse des besoins',
      },
      applicationMode: {
        title: "Type d'application:",
        paper: 'Papier',
        digital: 'Numérique',
      },
      copyable: {
        title: 'Copiable dans:',
        none: 'Aucun',
        insured: 'Assuré',
        owner: 'Propriétaire',
      },
      table: {
        description: 'Description',
        createdAt: 'Créé le',
        updatedAt: 'Modifié le',
        version: 'Version',
        applicationsCount: 'Propositions associées',
        status: 'Statut',
        draft: 'Brouillon',
        published: 'Publié',
        noQuestionnairesFoundTitle: 'Aucun questionnaire',
        noQuestionnairesFoundDetails: "La liste des questionnaires sera affichée lorsqu'un questionnaire sera créé",
        applicationSchema: "Schéma de l'application",
      },
      questionnaireIconLabels: {
        [IconName.error404]: 'Erreur 404',
        [IconName.advisor]: 'Conseiller',
        [IconName.afterDeath]: 'Après le décès',
        [IconName.averageFinancialHealth]: 'Santé financière moyenne',
        [IconName.bullet]: 'Point',
        [IconName.calculatorCoverage]: 'Calcul de couverture',
        [IconName.coverage]: 'Couverture',
        [IconName.close]: 'Fermer',
        [IconName.confirmation]: 'Confirmation',
        [IconName.confirmationOutlined]: 'Confirmation contour',
        [IconName.contact]: 'Contacter',
        [IconName.debtsSavings]: 'Dettes et épargnes',
        [IconName.alcohol]: 'Alcool',
        [IconName.car]: 'Voiture',
        [IconName.confirmation]: 'Confirmation',
        [IconName.check]: 'Check',
        [IconName.checklist]: 'Liste',
        [IconName.checkmark]: 'Check',
        [IconName.day]: 'Jour',
        [IconName.death]: 'Décès',
        [IconName.drugs]: 'Drogues',
        [IconName.edit]: 'Modifier',
        [IconName.education]: 'Éducation',
        [IconName.educationCoverageCalculator]: "Calculateur de couverture d'éducation",
        [IconName.excellentHealth]: 'Santé excellente',
        [IconName.excellentFinancialHealth]: 'Santé financière excellente',
        [IconName.extremeSports]: 'Sports extrêmes',
        [IconName.family]: 'Famille',
        [IconName.familyIncome]: 'Revenu familial',
        [IconName.file]: 'Ficher',
        [IconName.fileDownload]: 'Téléchargement',
        [IconName.future]: 'Futur',
        [IconName.goodHealth]: 'Bonne santé',
        [IconName.goodFinancialHealth]: 'Bonne santé financière',
        [IconName.greatHealth]: 'Très bonne santé',
        [IconName.handshake]: 'Poignée de mains',
        [IconName.health]: 'Santé',
        [IconName.hospital]: 'Hôpital',
        [IconName.loading]: 'Chargement',
        [IconName.loadingCalculator]: 'Calcul de chargement',
        [IconName.marijuana]: 'Marijuana',
        [IconName.mail]: 'Courrier',
        [IconName.mib]: 'Medical Information Bureau (MIB)',
        [IconName.morning]: 'Matin',
        [IconName.night]: 'Nuit',
        [IconName.payment]: 'Paiement',
        [IconName.personalInformation]: 'Information personnelle',
        [IconName.phone]: 'Téléphone',
        [IconName.poorFinancialHealth]: 'Mauvaise santé financière',
        [IconName.poorHealth]: 'Mauvaise santé',
        [IconName.police]: 'Police',
        [IconName.plane]: 'Avion',
        [IconName.preference]: 'Préférence',
        [IconName.smoking]: 'Fumeur',
        [IconName.summary]: 'Résumé',
        [IconName.unfortunately]: 'Malheureusement',
        [IconName.update]: 'Mise à jour',
        [IconName.watch]: 'Montre',
        [IconName.weight]: 'Poids',
        [IconName.work]: 'Travail',
        [IconName.wait]: 'Attendre',
        [IconName.close2]: 'Fermer 2',
        [IconName.success]: 'Succès',
        [IconName.successOutlined]: 'Succès contour',
        [IconName.warning]: 'Mise en garde',
        [IconName.warningOutlined]: 'Mise en garde contour',
        [IconName.information]: 'Information',
        [IconName.informationOutlined]: 'Information contour',
        [IconName.error]: 'Erreur',
        [IconName.errorOutlined]: 'Erreur contour',
        [IconName.triangle]: 'Triangle',
        [IconName.dragHandle]: 'Poignée de déplacement',
        [IconName.folder]: 'Dossier',
        [IconName.fileMenu]: 'Menu du fichier',
        [IconName.list]: 'Liste',
        [IconName.question]: 'Question',
        [IconName.threeDots]: 'Ellipse',
        [IconName.search]: 'Recherche',
      },
      numericalDataTypeOptions: {
        [NumericalDataType.float]: 'Float',
        [NumericalDataType.integer]: 'Integer',
      },
    },
    salesDecisionRulesManagement: {
      salesDecisionRule: {
        singular: 'règle de décision de vente',
        plural: 'règles de décision de vente',
      },
      questionnaire: { singular: 'questionnaire', plural: 'questionnaires' },
      table: {
        conditions: 'Conditions',
        createdAt: 'Date de création',
        outcomeCode: 'Résultat',
        reason: 'Raison',
        identifier: 'L’identifiant',
        noRulesFoundTitle: 'Aucune règle de décision de vente trouvée',
        noRulesFoundText: 'Les règles de décision de vente seront affichées une fois créées',
      },
      createNewSalesDecisionRule: 'Créer une nouvelle règle de décision de vente',
      editSalesDecisionRule: 'Modifier la règle de décision de vente',
      labels: {
        outcome: 'Résultat de la proposition',
        reason: 'Raison',
        identifier: 'L’identifiant',
      },
      options: {
        outcomes: {
          [OutcomeCode.referred]: 'Référé',
          [OutcomeCode.denied]: 'Refusé',
          [OutcomeCode.blocked]: 'Bloqué',
          [OutcomeCode.blockedAtQuoter]: 'Bloqué à la soumission',
          [OutcomeCode.accepted]: 'Accepté',
          [OutcomeCode.conditional]: 'Accepté conditionnellement',
        },
      },
    },
    firmManagement: {
      noFirmsFoundTitle: 'Aucun firme disponible',
      noFirmsFoundText: "La liste des firmes sera affichée lorsqu'au moins un produit sera créé",
      labels: {
        name: 'Nom',
        createdAt: 'Créé le',
        productsCount: 'Produits associées',
        nameAndLanguage: 'Nom ({{language}})',
        addressAndLanguage: 'Adresse ({{language}})',
        uploadLogoButtonText: "Ajouter une firme d'assurance - {{language}}",
        changeLogoButtonText: "Changer le logo de la firme d'assurance - {{language}}",
        primaryPhoneNumber: 'Numéro de téléphone principal',
        tollFreePhoneNumber: 'Numéro de téléphone sans frais',
        faxNumber: 'Numéro de fax',
        websiteUrl: 'Adresse du site web',
        email: 'courriel',
        firmAmfNumber: 'Numéro AMF de la firme',
        insurerAmfNumber: "Numéro AMF de l'assureur",
        privacyPolicyUrl: 'Lien vers les politiques de vie privée',
        firmRegistrationUrl: "Lien vers l'enregistrement de la firme",
        insurerRegistrationUrl: "Lien vers l'enregistrement de l'assureur",
      },
      insuranceFirm: {
        singular: "firme d'assurance",
        plural: "firmes d'assurance",
      },
      createNewFirm: "Créer une nouvelle firme d'assurance",
    },
    featuresManagement: {
      warning: {
        title: 'Non disponible',
        subtitle: 'Les outils de gestion des fonctionnalités sont en cours de développement',
      },
    },
    entityMappings: {
      namespaceDropdownLabel: 'Namespace',
      questionnaireVersionDropdownLabel: 'Version du questionnaire',
      mappingDropdownLabel: 'Mapping',
      saveButtonLabel: 'Sauvegarder',
      selectEntityToEditInfo: 'Selectionnez une configuration pour commencer à éditer le code.',
      unsavedChangedModalTitle: 'Changements non sauvegardés',
      discardChangesModalButton: 'Oublier les changements',
      changesUnsavedModalWarning:
        "Certains changements n'ont pas été sauvegardés. Êtes-vous certain de vouloir continuer?",
    },
    documentDetailManagement: {
      title: 'Document',
      documentRule: {
        singular: 'règle du document',
        plural: 'règles du document',
        currentlyEditingVersion: 'Pour la version du questionnaire: {{version}}',
      },
      documentFieldEditorDialog: {
        newTitle: 'New Field',
        editTitle: 'Edit Field',
        fields: {
          placementType: "Type d'emplacement",
          fieldBinding: 'Liaison de champ (Node Id)',
          type: 'Type',
          anchorText: 'Anchor Text',
          anchorPoint: 'Anchor Point',
          anchorIndex: 'Anchor Index',
          anchorCharacterIndex: 'Character Anchor Index',
          top: 'Top',
          left: 'Left',
          topOffset: 'Top Offset',
          leftOffset: 'Left Offset',
          width: 'Length of Signature Box',
          height: 'Height of Signature Box',
          page: 'Page',
        },
      },

      addSigner: 'Add Signer',
      addRecipient: 'Ajouter un destinataire',
      recipient: 'Destinataire',
    },

    documentManagement: {
      noDocumentsFoundTitle: 'Aucun document disponible',
      noDocumentsFoundText: "La liste des documents sera affichée lorsqu'au moins un document sera créé",
      newDocumentDialog: {
        title: 'Nouveau Document',
      },

      archiveDocumentDialog: {
        title: 'Archiver Document',
        message: 'Êtes-vous certain de vouloir archiver ce document?',
      },

      archiveStatusSelect: {
        active: 'Actif',
        archived: 'Archivé',
      },
    },
    productManagement: {
      insuranceProduct: {
        singular: "produit d'assurance",
        plural: "produits d'assurance",
      },
      insuranceProductRates: "tarifs des produits d'assurance",
      noProductsFoundTitle: 'Aucun produit disponible',
      noProductsFoundText: "La liste des produits sera affichée lorsqu'au moins un produit sera créé",
      createNewProduct: "Créer un nouveau produit d'assurance",
      modifyPricing: 'Modifier les prix',
      productPricing: 'Modifier le prix du {{name}} produit',
      selectAFile: 'Sélectionner un fichier',
      selectedFile: 'Fichier sélectionné: {{fileName}}',
      noFileSelected: 'Aucun fichier choisi',
      addedAt: 'Ajouté à {{date}}',
      labels: {
        name: 'Nom',
        nameAndLanguage: 'Nom ({{language}})',
        type: 'Type',
        lineOfBusiness: "Secteur d'activité",
        minimumAge: 'Âge minimal',
        maximumAge: 'Âge maximal',
        productId: 'Identifiant du produit',
        insuranceFirmId: "Firme d'assurance",
        holdingForm: "Formulaire d'actifs",
        supportedBuyers: 'Acheteurs supportés',
        policyDetailsUrl: 'Lien vers les détails de la police',
        temporaryInsuranceUrl: "Lien vers le certificat d'assurance temporaire",
        renewable: 'Renouvelable',
        maxRenewalAge: 'Âge maximal de renouvellement',
        convertible: 'Convertible',
        addonOrRider: 'Complément / Avenant',
        linkToTheseProducts: 'Attaché à ces produits',
        noProductAvailable: 'Aucun produit disponible',
        linkToTheseAddons: 'Attaché à ces compléments/avenants',
        noAddonAvailable: 'Aucun complément/avenant disponible',
        missingProductName: '[Le nom du produit est absent]',
        maxConversionAge: 'Âge maximal de conversion',
        requiresMedicalExam: 'Examen médical requis',
        policyFee: 'Frais de police',
        additionalFeatures: 'Fonctionalités supplémentaires {{language}}',
        additionalFeature: 'Fonctionalités additionnelle #{{index}}',
        coverageAmountRanges: 'Fourchettes de montant de couverture',
        coverageAmountRange: 'Fourchette de montant de couverture #{{index}}',
        minimumCoverageAmount: 'Montant de couverture minimal',
        maximumCoverageAmount: 'Montant de couverture maximal',
        minimumSalary: 'Salaire minimum',
        pricing: 'Prix',
        modalFactor: 'Facteur modal',
        paymentFrequency: 'Fréquence de paiement',
        selectors: 'Sélecteurs de produits',
        index: 'Index',
        productFieldAnswers: 'Réponses du champ de produit',
        coverageAmountFieldAnswer: 'Réponse du champ montant de couverture',
        premiumFieldAnswer: 'Réponse de champ premium',
      },
      archiving: {
        archive: 'Archiver',
        archived: 'Archivé',
        yes: 'Oui',
        no: 'Non',
        unarchive: 'Unarchive',
        modal: {
          title: 'Confirmation',
          descriptionForArchive:
            'Confirmez-vous que vous souhaitez archiver ce produit ? Les produits archivés ne sont plus visibles dans les pages de sélection de produits et ne peuvent pas être sélectionnés dans les applications.',
          descriptionForUnarchive:
            'Confirmez-vous que vous souhaitez désarchiver ce produit ? Les produits non archivés sont visibles dans les pages de sélection de produits et peuvent être sélectionnés dans les applications.',
        },
      },
      options: {
        lineOfBusiness: {
          [LineOfBusiness.annuity]: 'Rente',
          [LineOfBusiness.criticalIllness]: 'Maladie Grave',
          [LineOfBusiness.disability]: 'Invalidité',
          [LineOfBusiness.health]: 'Santé',
          [LineOfBusiness.longTermCare]: 'Soins de longue durée',
          [LineOfBusiness.medicalSupplement]: 'Supplément médical',
          [LineOfBusiness.life]: 'Vie',
        },
        holdingForm: {
          [HoldingForm.individual]: 'Individuel',
          [HoldingForm.jointIndividual]: 'Conjoint',
        },
        type: {
          [ProductType.criticalIllness]: 'Maladie Grave',
          [ProductType.termLife]: 'Vie temporaire',
          [ProductType.wholeLife]: 'Vie entière',
        },
        supportedBuyers: {
          [SupportedBuyer.consumer]: 'Consommateur',
          [SupportedBuyer.pro]: 'Agent',
        },
        paymentFrequency: {
          [PaymentFrequency.monthly]: 'Mensuel',
          [PaymentFrequency.quarterly]: 'Trimestriel',
          [PaymentFrequency.annually]: 'Annuellement',
        },
      },
    },
    conditions: {
      labels: {
        conditionBlueprintType: 'Condition',
        targetNodeId: 'Cible du champ du questionnaire',
        targetNodeIdByNumber: 'Cible du champ du questionnaire #{{number}}',
        selectValue: 'Sélectionner une valeur',
        selectedValues: {
          singular: 'Valeur sélectionnée',
          pluralish: 'Valeur(s) sélectionnée(s)',
          noOptions: 'Aucune option disponible',
        },
        outcome: 'Résultat de la proposition',
        reason: 'Raison',
        ageUnit: 'Unité',
        minAge: 'Âge minimal',
        maxAge: 'Âge maximal',
        months: 'Mois',
        years: 'Années',
        days: ' Jours',
        roundingType: 'Arrondi',
        roundingTypeNone: 'Aucun',
        roundingTypeClosestBirthday: 'Anniversaire le plus proche',
        roundingTypeNextBirthday: 'Prochain anniversaire',
        roundingTypeLastBirthday: 'Dernier anniversaire',
        minLength: 'Caractères minimum',
        maxLength: 'Caractères maximum',
        height: 'Grandeur',
        weight: 'Poid',
        minBmi: 'IMC minimal',
        maxBmi: 'IMC maximal',
        controlValue: 'Valeur de comparaison',
        equality: "Condition d'égualité",
        emptiness: 'Condition de champ vide',
        numberComparison: 'Condition de comparaison numérique',
        matches: 'Condition de correspondance',
        collectionOperator: 'Opérateur collection',
        measurementSystem: 'Système de mesure',
        mathConditionOperator: 'Opérateur mathématique',
        dateUnit: 'Unité de date',
        startDate: 'Date de début cible du champ du questionnaire',
        endDate: 'Date cible de fin du champ du questionnaire',
        addCondition: 'Ajouter une condition',
        addNestedCondition: 'Ajouter une condition imbriquée',
        removeCondition: 'Supprimer la condition',
        removeNestedCondition: 'Supprimer la condition imbriquée',
        logicalOperator: 'Opérateur logique',
        noUpperLimit: 'infini',
        answer: 'Réponse',
        comparisonValueType: 'Type de la valeur de comparaison',
        nodeId: 'Champ',
        number: 'Valeur',
        string: 'Valeur(s) à comparer',
        percent: 'Pour cent (%)',
        selectedCollection: 'Collection: {{collectionNodeId}}',
        collectionCompareToCurrentItem: "Comparer à l'élément actuel",
        conditionSummary: {
          [ConditionBlueprintType.mathOperator]: {
            prefix: {
              default: {
                [MathConditionOperator.sum]: 'Si la somme des valeurs des champs <strong>{{fieldNames}}</strong> ',
                [MathConditionOperator.multiply]:
                  'Si le produit des valeurs dans les champs <strong>{{fieldNames}}</strong> ',
                [MathConditionOperator.subtract]:
                  'Si la différence de valeurs dans les champs <strong>{{fieldNames}}</strong> ',
              },
              [BlueprintCollectionOperator.every]: {
                [MathConditionOperator.sum]:
                  'Si <strong>chaque</strong> somme des valeurs dans les champs <strong>{{fieldNames}}</strong> ',
                [MathConditionOperator.multiply]:
                  'Si <strong>chaque</strong> produit des valeurs dans les champs <strong>{{fieldNames}}</strong> ',
                [MathConditionOperator.subtract]:
                  'Si <strong>chaque</strong> différence de valeurs dans les champs <strong>{{fieldNames}}</strong> ',
              },
              [BlueprintCollectionOperator.some]: {
                [MathConditionOperator.sum]:
                  'Si <strong>certains</strong> somme des valeurs dans les champs <strong>{{fieldNames}}</strong> ',
                [MathConditionOperator.multiply]:
                  'Si <strong>certains</strong> produit des valeurs dans les champs <strong>{{fieldNames}}</strong> ',
                [MathConditionOperator.subtract]:
                  'Si <strong>certains</strong> différence de valeurs dans les champs <strong>{{fieldNames}}</strong> ',
              },
              [BlueprintCollectionOperator.none]: {
                [MathConditionOperator.sum]:
                  'Si <strong>aucun</strong> somme des valeurs dans les champs <strong>{{fieldNames}}</strong> ',
                [MathConditionOperator.multiply]:
                  'Si <strong>aucun</strong> produit des valeurs dans les champs <strong>{{fieldNames}}</strong> ',
                [MathConditionOperator.subtract]:
                  'Si <strong>aucun</strong> différence de valeurs dans les champs <strong>{{fieldNames}}</strong> ',
              },
            },
            suffix: {
              [NumberComparisonConditionOperator.greaterThan]: 'plus grand que <strong>{{value}}</strong>',
              [NumberComparisonConditionOperator.lessThan]: 'est moins que <strong>{{value}}</strong>',
              [NumberComparisonConditionOperator.lessThanOrEqual]: 'moins que ou égal à <strong>{{value}}</strong>',
              [NumberComparisonConditionOperator.greaterThanOrEqual]: 'plus grand ou égal à <strong>{{value}}</strong>',
              [NumberComparisonConditionOperator.equal]: 'est égal à <strong>{{value}}</strong>',
              [NumberComparisonConditionOperator.multipleOf]: 'est multiple de <strong>{{value}}</strong>',
            },
          },
          [ConditionBlueprintType.ageRange]: {
            default:
              "Si l'âge en {{unit}} est entre <strong>{{minAge}}</strong> et <strong>{{maxAge}}</strong>, arrondi par {{roundingType}}",
            [BlueprintCollectionOperator.every]:
              'Si <strong>chaque</strong> champ de <strong>{{fieldName}}</strong> a un âge compris entre <strong>{{minAge}}</strong> et <strong>{{maxAge}}</strong> <strong>{{unit}}</strong>, arrondi par {{roundingType}}',
            [BlueprintCollectionOperator.some]:
              'Si <strong>certains</strong> champs de <strong>{{fieldName}}</strong> ont entre <strong>{{minAge}}</strong> et <strong>{{maxAge}}</strong> <strong>{{unit}}</strong>, arrondi par {{roundingType}}',
            [BlueprintCollectionOperator.none]:
              'Si <strong>aucun</strong> champs de <strong>{{fieldName}}</strong> ne date de <strong>{{minAge}}</strong> à <strong>{{maxAge}}</strong> <strong>{{unit}}</strong>, arrondi par {{roundingType}}',
          },
          [ConditionBlueprintType.characterCountInBetween]: {
            default:
              'Si le nombre de caractères est entre <strong>{{minLength}}</strong> et <strong>{{maxLength}}</strong>',
            [BlueprintCollectionOperator.every]:
              'Si <strong>chaque</strong> champ <strong>{{fieldName}}</strong> a un nombre de caractères compris entre <strong>{{minLength}}</strong> et <strong>{{maxLength}}</strong>',
            [BlueprintCollectionOperator.some]:
              'Si <strong>certains</strong> champs du <strong>{{fieldName}}</strong> ont un nombre de caractères compris entre <strong>{{minLength}}</strong> et <strong>{{maxLength}}</strong>',
            [BlueprintCollectionOperator.none]:
              "Si <strong>aucun</strong> champ du <strong>{{fieldName}}</strong> n'a un nombre de caractères compris entre <strong>{{minLength}}</strong> et <strong>{{maxLength}}</strong>",
          },
          [ConditionBlueprintType.bmiRange]: {
            default: "Si l'IMC est entre <strong>{{minBmi}}</strong> et <strong>{{maxBmi}}</strong>",
            [BlueprintCollectionOperator.every]:
              "Si <strong>chaque</strong> valeur d'IMC calculée à partir de <strong>{{heightField}}</strong> (taille) et <strong>{{weightField}}</strong> (poids) est comprise entre <strong>{ {minBmi}}</strong> et <strong>{{maxBmi}}</strong>",
            [BlueprintCollectionOperator.some]:
              "Si <strong>certaines</strong> des valeurs d'IMC calculées à partir de <strong>{{heightField}}</strong> (taille) et <strong>{{weightField}}</strong> (poids) se situent entre <strong >{{minBmi}}</strong> et <strong>{{maxBmi}}</strong>",
            [BlueprintCollectionOperator.none]:
              "Si <strong>aucune</strong> des valeurs d'IMC calculées à partir de <strong>{{heightField}}</strong> (taille) et <strong>{{weightField}}</strong> (poids) est comprise entre <strong >{{minBmi}}</strong> et <strong>{{maxBmi}}</strong>",
          },
          [ConditionBlueprintType.emptiness]: {
            default: {
              true: 'Si le champ <strong>{{fieldName}}</strong> est vide',
              false: "Si le champ <strong>{{fieldName}}</strong> n'est pas vide",
            },
            [BlueprintCollectionOperator.every]: {
              true: 'Si <strong>chaque</strong> champ de <strong>{{fieldName}}</strong> est <strong>vide</strong>',
              false:
                "Si <strong>chaque</strong> champ de <strong>{{fieldName}}</strong> n'est <strong>pas vide</strong>",
            },
            [BlueprintCollectionOperator.some]: {
              true: 'Si <strong>certains</strong> champs de <strong>{{fieldName}}</strong> sont <strong>vides</strong>',
              false:
                'Si <strong>certains</strong> champs du <strong>{{fieldName}}</strong> ne sont <strong>pas vides</strong>',
            },
            [BlueprintCollectionOperator.none]: {
              true: "Si <strong>aucun</strong> champ du <strong>{{fieldName}}</strong> <strong>n'est vide</strong>",
              false: "Si <strong>aucun</strong> champ du <strong>{{fieldName}}</strong> <strong>n'est vide</strong>",
            },
          },
          [ConditionBlueprintType.equality]: {
            default: {
              true: 'Si la valeur du champ <strong>{{fieldName}}</strong> est égale à <strong>{{value}}</strong>',
              false:
                "Si la valeur du champ <strong>{{fieldName}}</strong> n'est pas égale à <strong>{{value}}</strong>",
            },
            [BlueprintCollectionOperator.every]: {
              true: 'Si <strong>chaque</strong> champ de la valeur <strong>{{fieldName}}</strong> est égal à <strong>{{value}}</strong>',
              false:
                "Si <strong>chaque</strong> champ de la valeur <strong>{{fieldName}}</strong> n'est pas égal à <strong>{{value}}</strong>",
            },
            [BlueprintCollectionOperator.some]: {
              true: 'Si <strong>certains</strong> champs de la valeur <strong>{{fieldName}}</strong> sont égaux à <strong>{{value}}</strong>',
              false:
                'Si <strong>certains</strong> champs de la valeur <strong>{{fieldName}}</strong> ne sont pas égaux à <strong>{{value}}</strong>',
            },
            [BlueprintCollectionOperator.none]: {
              true: "Si <strong>aucun</strong> champ de la valeur <strong>{{fieldName}}</strong> n'est égal à <strong>{{value}}</strong>",
              false:
                "Si <strong>aucun</strong> champ de la valeur <strong>{{fieldName}}</strong> n'est égal à <strong>{{value}}</strong>",
            },
          },
          [ConditionBlueprintType.numberComparison]: {
            default:
              'Si la valeur du champ numérique <strong>{{fieldName}}</strong> est <strong>{{operator}}</strong> {{conjunction}} <strong>{{value}}</strong>',
            [BlueprintCollectionOperator.every]:
              'Si <strong>chaque</strong> champ numérique <strong>{{fieldName}}</strong> est <strong>{{operator}}</strong> {{conjunction}} <strong>{{value}}</strong>',
            [BlueprintCollectionOperator.some]:
              'Si la valeur de <strong>certains</strong> champs numériques est <strong>{{operator}}</strong> {{conjunction}} <strong>{{value}}</strong>',
            [BlueprintCollectionOperator.none]:
              "Si <strong>aucun</strong> champ numérique <strong>{{fieldName}}</strong> n'est pas <strong>{{operator}}</strong> {{conjunction}} <strong>{{value}}</strong>",
          },
          [ConditionBlueprintType.reflexive]: {
            default: 'Si la valeur du champ <strong>{{fieldName}}</strong> est <strong>{{value}}</strong>',
            [BlueprintCollectionOperator.every]:
              'Si <strong>chaque</strong> champ de la valeur <strong>{{fieldName}}</strong> est <strong>{{value}}</strong>',
            [BlueprintCollectionOperator.some]:
              'Si <strong>un</strong> champ de la valeur <strong>{{fieldName}}</strong> est <strong>{{value}}</strong>',
            [BlueprintCollectionOperator.none]:
              "Si <strong>aucun</strong> champ de la valeur <strong>{{fieldName}}</strong> n'est <strong>{{value}}</strong>",
          },
          [ConditionBlueprintType.matches]: {
            default: {
              [MatchesConditionPropertyQuantifier.any]:
                'Si les valeurs du champ <strong>{{fieldName}}</strong> contiennent un ou plus de: <strong>{{formattedValues}}</strong>',
              [MatchesConditionPropertyQuantifier.none]:
                'Si les valeurs du champ <strong>{{fieldName}}</strong> ne contiennent aucun des: <strong>{{formattedValues}}</strong>',
            },
            [BlueprintCollectionOperator.every]: {
              [MatchesConditionPropertyQuantifier.any]:
                'Si <strong>chaque</strong> champ de valeurs <strong>{{fieldName}}</strong> contient un ou plusieurs éléments de : <strong>{{value}}</strong>',
              [MatchesConditionPropertyQuantifier.none]:
                'Si <strong>chaque</strong> champ de valeurs <strong>{{fieldName}}</strong> ne contient aucun des éléments suivants : <strong>{{value}}</strong>',
            },
            [BlueprintCollectionOperator.some]: {
              [MatchesConditionPropertyQuantifier.any]:
                'Si <strong>certains</strong> champs des valeurs <strong>{{fieldName}}</strong> contiennent un ou plusieurs éléments de : <strong>{{value}}</strong>',
              [MatchesConditionPropertyQuantifier.none]:
                'Si <strong>certains</strong> champs des valeurs <strong>{{fieldName}}</strong> ne contiennent aucun des éléments suivants : <strong>{{value}}</strong>',
            },
            [BlueprintCollectionOperator.none]: {
              [MatchesConditionPropertyQuantifier.any]:
                'Si <strong>aucun</strong> champ des valeurs <strong>{{fieldName}}</strong> ne contient un ou plusieurs de : <strong>{{value}}</strong>',
              [MatchesConditionPropertyQuantifier.none]:
                'Si <strong>aucun</strong> champ de la valeur <strong>{{fieldName}}</strong> ne contient aucun des éléments suivants : <strong>{{value}}</strong>',
            },
          },
          [ConditionBlueprintType.lastIncidentDate]: {
            prefix: {
              default: 'Si la date du dernier incident visé au <strong>{{fieldName}}</strong> ',
              [BlueprintCollectionOperator.every]:
                'Si <strong>chaque</strong> date du dernier incident visé <strong>{{fieldName}}</strong> ',
              [BlueprintCollectionOperator.some]:
                'Si <strong>certaines</strong> dates du dernier incident visé <strong>{{fieldName}}</strong> ',
              [BlueprintCollectionOperator.none]:
                'Si <strong>aucune</strong> date du dernier incident visé <strong>{{fieldName}}</strong> ',
            },
            suffix: {
              [NumberComparisonConditionOperator.greaterThan]: `c'était il y a plus de <strong>{{value}} {{dateUnit}}</strong>`,
              [NumberComparisonConditionOperator.lessThan]: `c'était il y a moins de <strong>{{value}} {{dateUnit}}</strong>`,
              [NumberComparisonConditionOperator.lessThanOrEqual]: `c'était il y a moins de ou exactement <strong>{{value}} {{dateUnit}}</strong>`,
              [NumberComparisonConditionOperator.greaterThanOrEqual]: `c'était il y a plus ou exactement <strong>{{value}} {{dateUnit}}</strong>`,
            },
          },
          [ConditionBlueprintType.dateComparison]: {
            prefix: {
              default:
                'Si la différence entre le <strong>{{endDateFieldName}}</strong> (date de fin) et le <strong>{{startDateFieldName}}</strong> (date de début) est ',
              [BlueprintCollectionOperator.every]:
                'Si <strong>chaque</strong> différence entre le <strong>{{endDateFieldName}}</strong> (date de fin) et le <strong>{{startDateFieldName}}</strong> (date de début) est ',
              [BlueprintCollectionOperator.some]:
                'Si <strong>certaines</strong> différence entre le <strong>{{endDateFieldName}}</strong> (date de fin) et le <strong>{{startDateFieldName}}</strong> (date de début) est ',
              [BlueprintCollectionOperator.none]:
                'Si <strong>aucune</strong> différence entre le <strong>{{endDateFieldName}}</strong> (date de fin) et le <strong>{{startDateFieldName}}</strong> (date de début) est ',
            },

            suffix: {
              [NumberComparisonConditionOperator.equal]:
                '<strong>égale</strong> à <strong>{{value}} {{dateUnit}}</strong>',
              [NumberComparisonConditionOperator.lessThan]: '<strong>moins que {{value}} {{dateUnit}}</strong>',
              [NumberComparisonConditionOperator.lessThanOrEqual]:
                '<strong>moins que ou égal</strong> à <strong>{{value}} {{dateUnit}}</strong>',
              [NumberComparisonConditionOperator.greaterThan]:
                '<strong>plus grand</strong> que <strong>{{value}} {{dateUnit}}</strong>',
              [NumberComparisonConditionOperator.greaterThanOrEqual]:
                '<strong>plus grand ou égal</strong> à <strong>{{value}} {{dateUnit}}</strong>',
            },
          },
          [ConditionBlueprintType.countEqual]: {
            prefix: {
              default:
                'Si le nombre de réponses avec la valeur <strong>{{controlValue}}</strong> au champ <strong>{{targetNodeId}}</strong> est ',
              [BlueprintCollectionOperator.every]:
                'Si le nombre de réponses <strong>avec</strong> la valeur <strong>{{controlValue}}</strong> au champ <strong>{{targetNodeId}}</strong> est ',
              [BlueprintCollectionOperator.some]:
                'Si le nombre de réponses <strong>ayant</strong> la valeur <strong>{{controlValue}}</strong> dans le champ <strong>{{targetNodeId}}</strong> est ',
              [BlueprintCollectionOperator.none]:
                "Si le nombre de réponses <strong>n'ayant pas</strong> de valeur <strong>{{controlValue}}</strong> dans le champ <strong>{{targetNodeId}}</strong> est ",
            },
            suffix: {
              [NumberComparisonConditionOperator.greaterThan]: 'plus grand que {{value}}</strong>',
              [NumberComparisonConditionOperator.lessThan]: 'moins que <strong>{{value}}</strong>',
              [NumberComparisonConditionOperator.lessThanOrEqual]: 'moins que ou égal à <strong>{{value}}</strong>',
              [NumberComparisonConditionOperator.greaterThanOrEqual]: 'Plus grand ou égal à <strong>{{value}}</strong>',
              [NumberComparisonConditionOperator.equal]: 'égal à <strong>{{value}}</strong>',
            },
          },
          [ConditionBlueprintType.percentOf]: {
            prefix: {
              default:
                'Si <strong>{{percent}} %</strong> de la valeur du champ numérique <strong>{{targetNodeId}}</strong> est ',
            },
            suffix: {
              [NumberComparisonConditionOperator.greaterThan]: 'plus grand que {{value}}</strong>',
              [NumberComparisonConditionOperator.lessThan]: 'moins que <strong>{{value}}</strong>',
              [NumberComparisonConditionOperator.lessThanOrEqual]: 'moins que ou égal à <strong>{{value}}</strong>',
              [NumberComparisonConditionOperator.greaterThanOrEqual]: 'plus grand ou égal à <strong>{{value}}</strong>',
              [NumberComparisonConditionOperator.equal]: 'égal à <strong>{{value}}</strong>',
            },
          },
        },
      },
      options: {
        collectionOperator: {
          [BlueprintCollectionOperator.every]: 'Chaque',
          [BlueprintCollectionOperator.some]: 'Quelques',
          [BlueprintCollectionOperator.none]: 'Aucun',
          [BlueprintCollectionOperator.thisItem]: 'Collection contenante',
        },
        conditionProperty: {
          [ConditionBlueprintType.ageRange]: "L'âge est dans la braquette",
          [ConditionBlueprintType.characterCountInBetween]: 'Le texte contient le bon nombre de caractères',
          [ConditionBlueprintType.bmiRange]: "L'IMC est dans la braquette",
          [ConditionBlueprintType.emptiness]: "La valeur du champ est/n'est pas vide",
          [ConditionBlueprintType.equality]: "La valeur du champ est/n'est pas égale à",
          [ConditionBlueprintType.numberComparison]: 'La valeur du champ numérique est',
          [ConditionBlueprintType.percentOf]: 'La valeur du champ numérique est un pourcentage de',
          [ConditionBlueprintType.reflexive]: 'Le champ est Oui/Non',
          [ConditionBlueprintType.matches]: "La valeur du champ est/n'est pas correspondante",
          [ConditionBlueprintType.countEqual]: 'Le nombre de réponses est',
          [ConditionBlueprintType.mathOperator]: 'La valeur calculée est',
          [ConditionBlueprintType.lastIncidentDate]: 'État de la date du dernier incident',
          [ConditionBlueprintType.dateComparison]: 'Comparaison des dates',
        },
        yesNo: {
          [YesNoValue.no]: 'Non',
          [YesNoValue.yes]: 'Oui',
        },
        emptiness: {
          empty: 'Est vide',
          notEmpty: "N'est pas vide",
        },
        equality: {
          equal: 'Est égal',
          notEqual: "N'est pas égal",
        },
        numberComparison: {
          [NumberComparisonConditionOperator.equal]: 'Égal',
          [NumberComparisonConditionOperator.greaterThan]: 'Plus grand que',
          [NumberComparisonConditionOperator.greaterThanOrEqual]: 'Plus grand ou égal',
          [NumberComparisonConditionOperator.lessThan]: 'Plus petit que',
          [NumberComparisonConditionOperator.lessThanOrEqual]: 'Plus petit ou égal',
          [NumberComparisonConditionOperator.multipleOf]: 'Multiple de',
        },
        measurementSystem: {
          [MeasurementSystem.imperial]: 'Impérial',
          [MeasurementSystem.metric]: 'Métrique',
        },
        matches: {
          [MatchesConditionPropertyQuantifier.any]: "N'importe quel",
          [MatchesConditionPropertyQuantifier.none]: 'Aucun',
        },
        mathConditionOperator: {
          [MathConditionOperator.multiply]: 'Multiplier',
          [MathConditionOperator.subtract]: 'Soustraction',
          [MathConditionOperator.sum]: 'Somme',
        },
        dateUnit: {
          [DateUnit.day]: 'Jours',
          [DateUnit.month]: 'Mois',
          [DateUnit.year]: 'Année',
        },
      },
    },
    auditLogs: {
      logEntity: {
        plural: 'audit logs',
        singular: 'audit log',
      },
      resourceName: 'Nom de la ressource',
      resourceId: 'ID de la ressource',
      actionType: 'Type d’action',
      userName: 'Nom d’utilisateur',
      userRole: 'Rôle de l’utilisateur',
      timestamp: 'Horodatage',
      details: 'Détails',
      modalTitle: 'Détails de l’action',
      modalLabel: 'Propriétés mises à jour',
      options: {
        any: 'Tout',
        resourceName: {
          [AdminToolResourceName.product]: 'Produit',
          [AdminToolResourceName.salesDecisionRule]: 'Règle de décision de vente',
          [AdminToolResourceName.productPricing]: 'Prix de Produits',
          [AdminToolResourceName.firm]: 'Firme',
          [AdminToolResourceName.theme]: 'Thème',
          [AdminToolResourceName.settings]: 'Paramètres',
          [AdminToolResourceName.questionnaire]: 'Questionnaire',
          [AdminToolResourceName.submittedApplicationStatusReset]: `Support d'application - Réinitialisation de la soumission`,
        },
        actionType: {
          [AdminToolActionType.create]: 'Créer',
          [AdminToolActionType.read]: 'Lire',
          [AdminToolActionType.update]: 'Mise à jour',
          [AdminToolActionType.delete]: 'Supprimer',
          [AdminToolActionType.import]: 'Importer',
        },
      },
    },
    questionnaireBuilder: {
      uploadButton: 'Télécharger un questionnaire',
      uploadModal: 'Selectionner un questionnaire (PDF)',
    },
    thirdPartyIntegrations: {
      status: {
        [ThirdPartyIntegrationStatus.active]: 'Actif',
        [ThirdPartyIntegrationStatus.inactive]: 'Inactif',
      },
      buttonLabels: {
        manage: 'Gérer',
      },
    },
  },
  canadianProvinces: {
    AB: 'Alberta',
    BC: 'Colombie-Britannique',
    MB: 'Manitoba',
    NB: 'Nouveau Brunswick',
    NL: 'Terre-Neuve et Labrador',
    NT: 'Territoires du Nord-Ouest',
    NS: 'Nouvelle-Écosse',
    NU: 'Nunavut',
    ON: 'Ontario',
    PE: 'Île-du-Prince-Édouard',
    QC: 'Québec',
    SK: 'Saskatchewan',
    YT: 'Territoire du Yukon',
  },
  usStates: {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'Samoa américaines',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'Washington D.C.',
    FM: 'États fédérés de Micronésie',
    FL: 'Floride',
    GA: 'Géorgie',
    GU: 'Guam',
    HI: 'Hawaï',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MH: 'Iles Marshall',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'Nouveau-Mexique',
    NY: 'New York',
    NC: 'Caroline du Nord',
    ND: 'Dakota du Nord',
    MP: 'Iles Mariannes du Nord',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palaos',
    PA: 'Pennsylvania',
    PR: 'Porto Rico',
    RI: 'Rhode Island',
    SC: 'Caroline du Sud',
    SD: 'Dakota du Sud',
    TN: 'Tennessee',
    TX: 'Texas',
    UM: 'Iles mineures éloignées des États-Unis',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Iles Vierges des États-Unis',
    VA: 'Virginie',
    WA: 'Washington',
    WV: 'Virginie occidentale',
    WI: 'Wisconsin',
    WY: 'Wyoming',
  },
  dataSizeLabels: {
    B: 'o',
    KB: 'Ko',
    MB: 'Mo',
    GB: 'Go',
    TB: 'To',
  },
  outcomes: {
    [OutcomeCode.referred]: {
      label: 'Référé',
      tooltip: '',
    },
    [OutcomeCode.denied]: {
      label: 'Refusé',
      tooltip: '',
    },
    [OutcomeCode.blocked]: {
      label: 'Bloqué',
      tooltip: '',
    },
    [OutcomeCode.blockedAtQuoter]: {
      label: 'Bloqué à la soumission',
      tooltip: '',
    },
    [OutcomeCode.accepted]: {
      label: 'Accepté',
      tooltip: '',
    },
    [OutcomeCode.conditional]: {
      label: 'Accepté conditionnellement',
      tooltip: '',
    },
    underReview: {
      // this outcome is only used by Beneva
      label: 'Proposition en cours d’étude',
      tooltip: 'Votre proposition est en cours d’étude par l’Assureur.',
    },
    conditionalApproval: {
      // this outcome is only used by Beneva
      label: 'Approuvé conditionnellement',
      tooltip:
        'Votre proposition a fait l’objet d’une approbation conditionnelle et provisoire. Cette approbation conditionnelle peut être révoquée à tout moment jusqu’à ce que la proposition ait été complétée et signée par tous les preneurs et personnes à assurer. Votre proposition d’assurance n’a donc pas été acceptée par l’Assureur et votre assurance n’a pas pris effet.',
    },
  },
};

export default translation;
