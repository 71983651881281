import Box from '@material-ui/core/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ChevronLeft } from '../../../../../../Assets/chevron-left-icon.svg';
import { Application } from '../../../../../../Models/Application';
import { LeadStatus } from '../../../../../../Models/Lead';
import { ContentHeading, CtaButton } from '../../Styles';
import { ApplicationHeaderInfo } from './ApplicationHeaderInfo';
import { ApplicationHeaderProgress } from './ApplicationHeaderProgress';
import { ApplicationView } from './Engine/ApplicationView';

type ApplicationDetailProps = {
  customView?: (props: any) => React.ReactElement | null;
  application: Application;
  leadStatus: LeadStatus;
  onClose: () => void;
};

export function ApplicationDetail({
  customView,
  application,
  leadStatus,
  onClose,
}: ApplicationDetailProps): React.ReactElement | null {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <ContentHeading py={2} px={3}>
        <Box display='flex' alignItems='flex-start'>
          <CtaButton startIcon={<ChevronLeft />} variant='outlined' onClick={onClose}>
            {t('tabs.needsAnalysis.back')}
          </CtaButton>

          <Box ml={2}>
            <ApplicationHeaderInfo application={application} />
          </Box>
        </Box>

        <Box textAlign='right'>
          <ApplicationHeaderProgress application={application} status={leadStatus} />
        </Box>
      </ContentHeading>

      <Box p={3}>
        <ApplicationView customView={customView} application={application} leadStatus={leadStatus} />
      </Box>
    </React.Fragment>
  );
}
