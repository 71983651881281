import { getDefaultMiddleware } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import _ from 'lodash';
import { combineReducers, Middleware } from 'redux';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, Transform } from 'redux-persist';
import { createWhitelistFilter } from 'redux-persist-transform-filter';

import { createTrackerMiddleware } from '@breathelife/redux';
import { Language } from '@breathelife/types';

import Urls from '../Navigation/Urls';
import { LeadPlatformStore } from '../Redux/types';
import { productManagementSlice } from './Admin/ProductManagement/ProductManagementSlice';
import { salesDecisionRuleManagementSlice } from './Admin/SalesDecisionRulesManagement/SalesDecisionRulesManagementSlice';
import { settingsSlice } from './Admin/SettingsManagement/SettingsSlice';
import { themeSlice } from './Admin/ThemeManagement/ThemeSlice';
import { applicationSlice } from './Application/ApplicationSlice';
import { assistedApplicationSlice } from './AssistedApplication/AssistedApplicationSlice';
import { authenticationSlice } from './Authentication/AuthenticationSlice';
import { identityVerificationSlice } from './IdentityVerification/IdentityVerificationSlice';
import { layoutSlice } from './Layout/LayoutSlice';
import { logsAdminToolsSlice } from './Logs/LogsAdminToolsSlice';
import { notificationSlice } from './Notification/NotificationSlice';
import { paymentSlice } from './Payment/PaymentSlice';
import { productsSlice } from './Products/ProductsSlice';
import { submissionSlice } from './Submission/SubmissionSlice';
import { userManagementSlice } from './UserManagement/UserManagementSlice';

type CreateMiddlewaresOptions = {
  thunk?: boolean;
  serializableCheck?: boolean;
};

const RootReducer = {
  leadPlatform: combineReducers({
    authentication: authenticationSlice.reducer,
    layout: layoutSlice.reducer,
    application: applicationSlice.reducer,
    notification: notificationSlice.reducer,
    products: productsSlice.reducer,
    userManagement: userManagementSlice.reducer,
    theme: themeSlice.reducer,
    productManagement: productManagementSlice.reducer,
    salesDecisionRulesManagement: salesDecisionRuleManagementSlice.reducer,
    settings: settingsSlice.reducer,
    submission: submissionSlice.reducer,
    logsAdminTools: logsAdminToolsSlice.reducer,
    assistedApplication: assistedApplicationSlice.reducer,
    payment: paymentSlice.reducer,
    identityVerification: identityVerificationSlice.reducer,
  }),
};

// TODO: Can be removed once NLG has updated their login redirect to point on /pro/login. They are currently doing
// /leads/login?sso=1 from their platform.
const isNotViewableScreen = [Urls.authCallback, Urls.login, Urls.legacy.login];

const isTrackedPage = (path: string): boolean => {
  const paths = _.values(Urls);
  const pathsToTrack = _.difference(paths, isNotViewableScreen);
  return pathsToTrack.includes(path);
};

const trackerMiddleware = createTrackerMiddleware<LeadPlatformStore>(isTrackedPage, () => null, Language.en);

const createMiddlewares = (history: History, options: CreateMiddlewaresOptions = {}): Middleware[] => {
  const reduxToolkitMiddleware = getDefaultMiddleware({
    serializableCheck: !!options.serializableCheck && {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
    thunk: options.thunk,
    // CreateSlice is using Immerjs by default. We don't need the immutable check since all of are reducer are
    // using CreateSlice
    immutableCheck: false,
  });
  return [routerMiddleware(history), ...reduxToolkitMiddleware, trackerMiddleware];
};

const persistenceTransforms: Transform<unknown, unknown>[] = [
  createWhitelistFilter('leadPlatform', [
    'authentication',
    'theme',
    'settings',
    'assistedApplication.disableTrackESignInfoModal',
    'assistedApplication.isDocumentSignInfoBoxOpen',
  ]),
];

export const leadPlatformStore = {
  RootReducer,
  persistenceTransforms,
  middlewares: (history: History, options?: CreateMiddlewaresOptions): Middleware[] =>
    createMiddlewares(history, options),
};
