import { Language } from '@breathelife/types';

import { useCarrierContext } from '../Hooks';

export function usePlatformLanguage(): Language | undefined {
  const { localizedHostnames } = useCarrierContext();
  const params = new URLSearchParams(location.search);

  if (!localizedHostnames) {
    return params.get('lang') as Language;
  }

  const localizedHostname = localizedHostnames.find((host) => host.hostname === location.hostname);

  return localizedHostname?.lang;
}
