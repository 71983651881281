import { Language } from '@breathelife/types';

import { FirmFormFields, LocalizableInputState } from './types';

function getDefaultLocalizableState(enabledLanguages: Language[]): LocalizableInputState {
  return enabledLanguages.reduce((acc, language) => ({ ...acc, [language]: '' }), {});
}

export function getDefaultInsuranceFirmFormState(
  enabledLanguages: Language[],
  formField?: FirmFormFields
): FirmFormFields {
  if (formField) {
    return formField;
  }
  const localizableDefaultState = getDefaultLocalizableState(enabledLanguages);

  const insuranceFirmFields = {
    name: localizableDefaultState,
    primaryPhoneNumber: undefined,
    tollFreePhoneNumber: undefined,
    faxNumber: undefined,
    websiteUrl: undefined,
    email: undefined,
    insurerAmfNumber: undefined,
    privacyPolicyUrl: undefined,
    firmRegistrationUrl: undefined,
    insurerRegistrationUrl: undefined,
    address: localizableDefaultState,
    logoUrl: localizableDefaultState,
  };

  return insuranceFirmFields;
}
