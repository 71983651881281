import React from 'react';
import { useTheme } from 'styled-components';

type LogoBreatheLifeSmallProps = {
  fill?: string;
  height?: string;
  id?: string;
  title: string;
  width?: string;
};

export function LogoBreatheLifeSmall(props: LogoBreatheLifeSmallProps): React.ReactElement {
  const { fill: fillProp, width: widthProp, height: heightProp, id, title } = props;
  const theme = useTheme();
  const width = widthProp ?? '16';
  const height = heightProp ?? '19';
  const fill = fillProp ?? theme.colors.grey[50];
  const titleId = `title-${id}`;

  return (
    <svg
      id={id}
      role='img'
      aria-label='Breathe Life'
      fill={fill}
      height={height}
      viewBox='0 0 16 19'
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={id ? titleId : undefined}
    >
      {id && title && <title id={titleId}>{title}</title>}
      <path
        clipRule='evenodd'
        d='m5.04392 6.48296h5.61218c2.4514 0 4.439 1.92982 4.439 4.31014v3.8971c0 2.3802-1.9876 4.3098-4.439 4.3098h-6.21743c-2.45143 0-4.4386635-1.9296-4.4386635-4.3098v-3.6786c0-1.36963.6321535-2.59671 1.6249535-3.4277-1.62524775-1.57807-1.62511925-2.68458-1.62495676-4.08375.00000163-.01402.00000326-.02807.00000326-.04215v-3.072722c0-.212695.1774705-.385278.3967945-.385278h4.681089c2.04893 0 3.70976 1.61263 3.70976 3.60182v.81726c0 .2127-.17774.38502-.3968.38502h-1.68665c-.21905 0-.39652-.17232-.39652-.38502v-.81726c0-.65919-.55062-1.19383-1.22979-1.19383h-2.59792v1.05001c0 1.37275.21009 3.02496 2.56395 3.02496zm5.24858 10.10904c1.2825 0 2.3226-1.0096 2.3226-2.2552v-3.1904c0-1.24555-1.0401-2.25545-2.3226-2.25545h-1.50485-3.74373-.24134c-1.28278 0-2.3226 1.0099-2.3226 2.25545v3.1904c0 1.2456 1.03982 2.2552 2.3226 2.2552z'
        fillRule='evenodd'
        opacity='.4'
      />
    </svg>
  );
}
