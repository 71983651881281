import { EntityMappingDefinition, GetEntityMappingDefinitionParams } from '@breathelife/types';

import ApiService from './ApiService';

export async function getEntityMapping(options: GetEntityMappingDefinitionParams): Promise<EntityMappingDefinition> {
  const response = await ApiService.admin.retrieveEntityMapping(options);
  return response.data;
}

export async function updateEntityMapping(data: EntityMappingDefinition): Promise<EntityMappingDefinition> {
  const response = await ApiService.admin.saveEntityMapping(data);
  return response.data;
}
