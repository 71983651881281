import { push as routerPush } from 'connected-react-router';

import { LeadTab } from '../../Models/Layout';
import {
  generateApplicationUnderwritingReportsUrl,
  generateApplicationUrl,
  generateLeadDetailUrl,
  generateLeadsListUrl,
} from '../../Navigation/Urls';
import { Dispatch } from '../../Redux/types';

export const navigateToLeadsList =
  (leadTab: LeadTab = LeadTab.active) =>
  (dispatch: Dispatch): void => {
    dispatch(routerPush(generateLeadsListUrl(leadTab)));
  };

export const navigateToLeadDetail =
  (leadId: number, leadTab: LeadTab = LeadTab.active) =>
  (dispatch: Dispatch): void => {
    dispatch(routerPush(generateLeadDetailUrl(leadId, leadTab)));
  };

export const navigateToAssistedApplication =
  (applicationId: string, leadId: number, leadTab: LeadTab = LeadTab.active) =>
  (dispatch: Dispatch): void => {
    dispatch(routerPush(generateApplicationUrl(applicationId, leadId, leadTab)));
  };

export const navigateToApplicationUnderwritingReports =
  (applicationId: string) =>
  (dispatch: Dispatch): void => {
    dispatch(routerPush(generateApplicationUnderwritingReportsUrl(applicationId)));
  };
