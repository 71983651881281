import React, { useCallback } from 'react';

import { ApplicationType, InsuranceScopes } from '@breathelife/types';

import { useDispatch, useNavigation } from '../../../../../Hooks';
import { Lead } from '../../../../../Models/Lead';
import {
  useLaunchAssistedApplicationMutation,
  useLaunchNewAssistedApplicationMutation,
} from '../../../../../ReactQuery/AssistedApplication/assistedApplication.mutations';
import { navigateToAssistedApplication } from '../../../../../Redux/Navigation/NavigationOperations';
import { ExistingApplicationsView } from './ExistingApplicationsView';
import { NewApplicationView } from './NewApplicationView';
import { ApplicationsContainer } from './Styles';

type ApplicationsViewContainerProps = {
  lead?: Lead;
};

export function ApplicationsViewContainer(props: ApplicationsViewContainerProps): React.ReactElement | null {
  const { lead } = props;
  const { leadTab } = useNavigation();
  const dispatch = useDispatch();

  const hasExistingApplication = !!lead?.applications?.length;

  const launchApplicationSuccess = useCallback(
    (application) => {
      dispatch(navigateToAssistedApplication(application.id, application.leadId, leadTab));
    },
    [dispatch, leadTab]
  );

  const launchNewAssistedApplicationMutation = useLaunchNewAssistedApplicationMutation({
    onSuccess: (data) => {
      launchApplicationSuccess(data);
    },
  });

  const launchExistingAssistedApplicationMutation = useLaunchAssistedApplicationMutation({
    onSuccess: (data) => {
      launchApplicationSuccess(data);
    },
  });

  const launchNewApplication = useCallback(
    (mode) => {
      if (lead?.id) {
        launchNewAssistedApplicationMutation.mutate({
          leadId: lead.id,
          type: ApplicationType.transactionFlow,
          insuranceScopes: [InsuranceScopes.life],
          mode,
        });
      }
    },
    [lead, launchNewAssistedApplicationMutation]
  );

  const launchExistingApplication = useCallback(
    (application) => {
      if (lead?.id) {
        launchExistingAssistedApplicationMutation.mutate({
          applicationId: application.id,
        });
      }
    },
    [lead, launchExistingAssistedApplicationMutation]
  );

  if (!lead) return null;

  return (
    <ApplicationsContainer>
      {!hasExistingApplication && (
        <NewApplicationView
          isCreatingApplication={launchNewAssistedApplicationMutation.isLoading}
          onLaunchAssistedApplication={launchNewApplication}
        />
      )}

      {hasExistingApplication && (
        <ExistingApplicationsView lead={lead} onLaunchAssistedApplication={launchExistingApplication} />
      )}
    </ApplicationsContainer>
  );
}
