import React from 'react';

import { RenderingQuestion, RenderingSubsection } from '@breathelife/questionnaire-engine';
import { Box } from '@breathelife/ui-components';

import { FieldGroupContainer } from './FieldGroupContainer';
import { SubsectionHeader } from './SubsectionHeader';

type SubsectionViewProps = {
  subsection: RenderingSubsection;
};

export function SubsectionView(props: SubsectionViewProps): React.ReactElement | null {
  const { subsection } = props;

  if (!subsection.visible) return null;

  return (
    <React.Fragment>
      <SubsectionHeader title={subsection.title} text={subsection.text} />
      {subsection.questions
        .filter((question) => question.visible)
        .map((question: RenderingQuestion) => (
          <Box mb={3} key={question.id}>
            <FieldGroupContainer question={question} />
          </Box>
        ))}
    </React.Fragment>
  );
}
