import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: ${(props) => `1px solid ${props.theme.colors.grey[40]}`};
  border-radius: 8px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.07);
  overflow: hidden;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  padding: 0 16px 0 24px;
  background-color: ${(props) => props.theme.colors.grey[20]};
`;

export const CardEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px;
`;

export const CardEmptyImg = styled.img`
  margin-bottom: 24px;
`;

export const CardBody = styled.div`
  padding: 24px;
  max-height: 400px;
  overflow-y: auto;
`;

export const CardProducts = styled.div`
  margin: 16px -8px 8px;
  padding: 12px 8px;
  background-color: ${(props) => props.theme.colors.grey[20]};
  border-radius: 8px;
`;

export const CardProduct = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 10px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .productPremium {
    padding-top: 5px;
  }
`;

export const CardRiders = styled.div`
  padding: 0 12px;
`;

export const Dot = styled.div`
  height: 16px;
  width: 8px;
  display: flex;
  align-items: flex-start;
  background-color: #fff;
  border-radius: 50%;
  margin-top: 5px;

  &::before {
    content: '';
    height: 8px;
    width: 8px;
    background-color: ${(props) => props.theme.colors.grey[40]};
    border-radius: 50%;
  }
`;

export const CardRider = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const CardRiderContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
  margin-top: 16px;
`;

export const CardFooter = styled.div`
  margin: 0 20px 8px 20px;
  padding: 24px 8px;
  border-top: 1px solid ${(props) => props.theme.colors.grey[40]};
  display: flex;
  justify-content: space-between;
`;
