import { Permission } from '@breathelife/types';

import { LeadTableColumnKeys } from '../Localization/types';

export enum LeadsTableColumnName {
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  assignedToName = 'assignedToName',
  status = 'status',
  communication = 'leads-communication',
  contacted = 'contacted',
  email = 'email',
  fullName = 'fullName',
  phoneNumber = 'phoneNumber',
  progress = 'progress',
  refNo = 'refNo',
  referralCode = 'referralCode',
  ownerDetails = 'ownerDetails',
  refNoIncrement = 'refNoIncrement',
  applicationMode = 'applicationMode',
}

export type LeadTableColumnInfo = {
  name: LeadsTableColumnName;
  title?: LeadTableColumnKeys;
  permission?: Permission;
  defaultSort?: 'asc' | 'desc';
};
