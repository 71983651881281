import React, { PropsWithChildren } from 'react';

import { DisplayQuestionnaireBuilder } from '@breathelife/types';

import { useFetchQuestionnaireBuilderQuery } from '../../../ReactQuery/Admin/QuestionnaireBuilder/questionnaireBuilder.queries';

export type ContextProps = {
  questionnaireBuilder?: DisplayQuestionnaireBuilder;
  questionnaireBuilderLoadingStatus: 'error' | 'idle' | 'loading' | 'success';
  questionnaireBuilderError?: unknown;
};

export const QuestionnaireBuilderContext = React.createContext<ContextProps>({} as ContextProps);

export function QuestionnaireBuilderProvider({
  children,
}: PropsWithChildren<Record<string, unknown>>): React.ReactElement {
  const {
    data: questionnaireBuilder,
    error: questionnaireBuilderError,
    status: questionnaireBuilderLoadingStatus,
  } = useFetchQuestionnaireBuilderQuery();

  return (
    <QuestionnaireBuilderContext.Provider
      value={{
        questionnaireBuilder,
        questionnaireBuilderError,
        questionnaireBuilderLoadingStatus,
      }}
    >
      {children}
    </QuestionnaireBuilderContext.Provider>
  );
}
