import MaterialUIDrawer, { DrawerProps } from '@material-ui/core/Drawer';
import styled from 'styled-components';

export const Drawer = styled(MaterialUIDrawer)<DrawerProps & { width?: string }>`
  .paper {
    width: ${(props) => props.width ?? '620px'};
  }
`;

export const Content = styled.div`
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 0;
  }
`;
