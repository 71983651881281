import { captureException } from '@sentry/browser';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MutateOptions, useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { ApiErrorIds, customAxiosErrorWithUnknownDataSchema } from '@breathelife/errors';
import { BlueprintModifier } from '@breathelife/insurance-form-builder';
import { SerializedNodeIdToAnswerPathMap } from '@breathelife/questionnaire-engine';
import {
  BlueprintCreation,
  BlueprintModification,
  BlueprintReorder,
  BlueprintUpdate,
  FieldBlueprint,
  FieldPartIdentifier,
  isBlueprintCreation,
  isBlueprintRemoval,
  isBlueprintReorder,
  isBlueprintUpdate,
  isFieldBlueprintCreation,
  isFieldBlueprintUpdate,
  isFieldPartIdentifier,
  isQuestionBlueprintCreation,
  isQuestionPartIdentifier,
  isSelectOptionBlueprintCreation,
  isSelectOptionPartIdentifier,
  PartIdentifier,
  QuestionBlueprint,
  QuestionnaireBlueprint,
  QuestionPartIdentifier,
  SectionBlueprint,
  SectionPartIdentifier,
  SelectOptionBlueprint,
  SelectOptionPartIdentifier,
  SubsectionBlueprint,
  SubsectionPartIdentifier,
} from '@breathelife/types';

import {
  generateQuestionnaireNodeIdsFromBlueprint,
  QuestionnaireNodeIds,
} from '../../../Helpers/questionnaireEditor/questionnaireNodeIds';
import { QuestionnaireEditorContext } from '../../../Pages/Admin/Questionnaire/QuestionnaireEditorContextProvider';
import { QuestionnaireVersionDataContext } from '../../../Pages/Admin/Questionnaire/QuestionnaireVersionDataContextProvider';
import { QueryId } from '../../../ReactQuery/common/common.types';
import { notificationSlice } from '../../../Redux/Notification/NotificationSlice';
import {
  createNewDraftQuestionnaireVersion,
  patchQuestionnaireVersionBlueprint,
  publishQuestionnaire,
  QuestionnaireVersionDetailWithNodeIdInfo,
} from '../../../Services/QuestionnaireVersionService';

type PatchQuestionnaireMutationVariables = BlueprintModification;

type PatchQuestionnaireMutationContext = {
  previousQuestionnaireBlueprint?: QuestionnaireBlueprint;
};

function makeQueryId(questionnaireVersionId: string): [string, string] {
  return [QueryId.questionnaireVersion, questionnaireVersionId];
}

function isFieldBlueprintUpdateWithNewNodeIds(modification: BlueprintModification): boolean {
  return (
    isBlueprintUpdate(modification) &&
    isFieldBlueprintUpdate(modification) &&
    (modification.update.property === 'answerNodeId' ||
      modification.update.property === 'fieldType' ||
      modification.update.property === 'addressAutocompleteNodeId')
  );
}

function isAddFieldModification(modification: BlueprintModification): boolean {
  return isBlueprintCreation(modification) && isFieldBlueprintCreation(modification);
}

function isAddQuestionModification(modification: BlueprintModification): boolean {
  return isBlueprintCreation(modification) && isQuestionBlueprintCreation(modification);
}

function isAddSelectOptionModification(modification: BlueprintModification): boolean {
  return isBlueprintCreation(modification) && isSelectOptionBlueprintCreation(modification);
}

function isRemoveFieldModification(modification: BlueprintModification): boolean {
  return isBlueprintRemoval(modification) && isFieldPartIdentifier(modification.partIdentifier);
}

function isRemoveQuestionModification(modification: BlueprintModification): boolean {
  return isBlueprintRemoval(modification) && isQuestionPartIdentifier(modification.partIdentifier);
}

function isRemoveSelectOptionModification(modification: BlueprintModification): boolean {
  return isBlueprintRemoval(modification) && isSelectOptionPartIdentifier(modification.partIdentifier);
}

function getRefreshedQuestionnaireNodeIds(
  modification: BlueprintModification,
  questionnaireBlueprint?: QuestionnaireBlueprint,
  nodeIdToAnswerPath?: SerializedNodeIdToAnswerPathMap
): QuestionnaireNodeIds | void {
  if (
    questionnaireBlueprint &&
    nodeIdToAnswerPath &&
    (isFieldBlueprintUpdateWithNewNodeIds(modification) ||
      isAddFieldModification(modification) ||
      isAddQuestionModification(modification) ||
      isAddSelectOptionModification(modification) ||
      isRemoveQuestionModification(modification) ||
      isRemoveFieldModification(modification) ||
      isRemoveSelectOptionModification(modification))
  ) {
    return generateQuestionnaireNodeIdsFromBlueprint(questionnaireBlueprint, nodeIdToAnswerPath);
  }
}

export function usePatchQuestionnaireMutation(
  questionnaireVersionId?: string,
  options?: UseMutationOptions<QuestionnaireBlueprint, unknown, PatchQuestionnaireMutationVariables>
): UseMutationResult<QuestionnaireBlueprint, unknown, PatchQuestionnaireMutationVariables> {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setIsMutating } = useContext(QuestionnaireEditorContext);

  function getOptimisticallyUpdatedBlueprint(
    currentQuestionnaireBlueprint: QuestionnaireBlueprint,
    modification: BlueprintModification
  ): QuestionnaireBlueprint {
    const newBlueprint = { ...currentQuestionnaireBlueprint };
    if (isBlueprintCreation(modification)) {
      BlueprintModifier.createBlueprint(modification, newBlueprint);
    } else if (isBlueprintUpdate(modification)) {
      BlueprintModifier.updateBlueprint(modification, newBlueprint);
    } else if (isBlueprintRemoval(modification)) {
      BlueprintModifier.removeBlueprint(modification.partIdentifier, newBlueprint);
    } else if (isBlueprintReorder(modification)) {
      BlueprintModifier.reorderBlueprint(modification, newBlueprint);
    }
    return newBlueprint;
  }

  return useMutation<
    QuestionnaireBlueprint,
    unknown,
    PatchQuestionnaireMutationVariables,
    PatchQuestionnaireMutationContext
  >(
    async (modification) => {
      if (!questionnaireVersionId) {
        throw new Error('useMutation: Missing questionnaireVersionId');
      }
      return patchQuestionnaireVersionBlueprint(questionnaireVersionId, modification);
    },
    {
      onMutate: async (modification) => {
        options?.onMutate?.(modification);
        setIsMutating?.(true);
        if (!questionnaireVersionId) {
          return;
        }
        const queryId = makeQueryId(questionnaireVersionId);
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(queryId);

        const questionnaireBlueprintQueryData = queryClient.getQueryData<
          QuestionnaireVersionDetailWithNodeIdInfo | undefined
        >(queryId);
        const previousQuestionnaireBlueprint = questionnaireBlueprintQueryData?.blueprint;
        // Optimistically update to the new value
        queryClient.setQueryData<QuestionnaireVersionDetailWithNodeIdInfo | undefined>(queryId, (queryData) => {
          if (queryData?.blueprint) {
            const updatedBlueprint = getOptimisticallyUpdatedBlueprint(queryData?.blueprint, modification);
            return { ...queryData, blueprint: updatedBlueprint };
          }
          return queryData;
        });
        return { previousQuestionnaireBlueprint };
      },
      onError: async (error, variables, context) => {
        captureException(error);
        if (context?.previousQuestionnaireBlueprint && questionnaireVersionId) {
          queryClient.setQueryData(makeQueryId(questionnaireVersionId), context.previousQuestionnaireBlueprint);
        }
        dispatch(notificationSlice.actions.setError({ message: t('notifications.failedToSaveQuestionnaire') }));

        if (options?.onError) {
          await options.onError(error, variables, context);
        }
      },
      onSuccess: async (data, variables, context) => {
        if (!questionnaireVersionId) {
          return;
        }

        queryClient.setQueryData<QuestionnaireVersionDetailWithNodeIdInfo | undefined>(
          makeQueryId(questionnaireVersionId),
          (queryData) => {
            const { blueprint, nodeIdToAnswerPath } = queryData || {};
            const updatedNodeIds = getRefreshedQuestionnaireNodeIds(variables, blueprint, nodeIdToAnswerPath);
            dispatch(notificationSlice.actions.setSuccess({ message: t('notifications.saveQuestionnaireSuccess') }));
            return queryData
              ? {
                  ...queryData,
                  blueprint: data,
                  questionnaireNodeIds: updatedNodeIds || queryData.questionnaireNodeIds,
                }
              : queryData;
          }
        );

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
      onSettled: () => {
        setIsMutating?.(false);
      },
    }
  );
}

export function useUpdateQuestionnaireElementBlueprint<T extends BlueprintUpdate>(
  questionnaireVersionId?: string,
  options?: MutateOptions<QuestionnaireBlueprint, unknown, PatchQuestionnaireMutationVariables>
): (modification: T) => Promise<void> {
  const patchMutation = usePatchQuestionnaireMutation(questionnaireVersionId);
  return async (modification: T) => {
    return patchMutation.mutate(modification, {
      ...options,
      onError: async (error, variables, context) => {
        captureException(error);

        if (options?.onError) {
          await options.onError(error, variables, context);
        }
      },
    });
  };
}

interface CreateBlueprintFunc {
  (newBlueprint: SectionBlueprint, parentPartIdentifier: null): Promise<void>;
  (newBlueprint: SubsectionBlueprint, parentPartIdentifier?: SectionPartIdentifier): Promise<void>;
  (newBlueprint: QuestionBlueprint, parentPartIdentifier?: SubsectionPartIdentifier): Promise<void>;
  (newBlueprint: FieldBlueprint, parentPartIdentifier?: QuestionPartIdentifier): Promise<void>;
  (newBlueprint: SelectOptionBlueprint, parentPartIdentifier?: FieldPartIdentifier): Promise<void>;
}

export function useAddQuestionnaireElementBlueprint(
  questionnaireVersionId?: string,
  options?: MutateOptions<QuestionnaireBlueprint, unknown, PatchQuestionnaireMutationVariables>
): CreateBlueprintFunc {
  const patchMutation = usePatchQuestionnaireMutation(questionnaireVersionId);

  const createBlueprint: CreateBlueprintFunc = async (newBlueprint: any, parentPartIdentifier: any) => {
    const blueprintCreation: BlueprintCreation = {
      partIdentifier: parentPartIdentifier || null,
      create: { blueprint: newBlueprint },
    };

    return patchMutation.mutate(blueprintCreation, {
      ...options,
      onError: async (error, variables, context) => {
        captureException(error);

        if (options?.onError) {
          await options.onError(error, variables, context);
        }
      },
    });
  };

  return createBlueprint;
}

export function useRemoveQuestionnaireElementBlueprint(
  questionnaireVersionId?: string,
  options?: MutateOptions<QuestionnaireBlueprint, unknown, PatchQuestionnaireMutationVariables>
): (partIdentifier: PartIdentifier) => Promise<void> {
  const patchMutation = usePatchQuestionnaireMutation(questionnaireVersionId);

  return async (partIdentifier: PartIdentifier) => {
    await patchMutation.mutate(
      {
        partIdentifier,
        remove: true,
      },
      {
        ...options,
        async onError(error, variables, context) {
          captureException(error);

          if (options?.onError) {
            await options.onError(error, variables, context);
          }
        },
      }
    );
  };
}

interface UpdateOrderFunc {
  (options: {
    sourcePartIdentifier: SectionPartIdentifier;
    targetSiblingPartIdentifier?: SectionPartIdentifier;
  }): Promise<void>;
  (options: {
    sourcePartIdentifier: SubsectionPartIdentifier;
    targetSiblingPartIdentifier?: SubsectionPartIdentifier;
    targetParentPartIdentifier?: SectionPartIdentifier;
  }): Promise<void>;
  (options: {
    sourcePartIdentifier: QuestionPartIdentifier;
    targetSiblingPartIdentifier?: QuestionPartIdentifier;
    targetParentPartIdentifier?: SubsectionPartIdentifier;
  }): Promise<void>;
  (options: {
    sourcePartIdentifier: FieldPartIdentifier;
    targetParentPartIdentifier?: QuestionPartIdentifier;
    targetSiblingPartIdentifier?: FieldPartIdentifier;
  }): Promise<void>;
  (options: {
    sourcePartIdentifier: SelectOptionPartIdentifier;
    targetSiblingPartIdentifier?: SelectOptionPartIdentifier;
    targetParentPartIdentifier?: FieldPartIdentifier;
  }): Promise<void>;
}

export function useUpdateQuestionnaireElementBlueprintOrder(
  questionnaireVersionId?: string,
  options?: MutateOptions<QuestionnaireBlueprint, unknown, PatchQuestionnaireMutationVariables>
): UpdateOrderFunc {
  const patchMutation = usePatchQuestionnaireMutation(questionnaireVersionId);

  const updateOrder: UpdateOrderFunc = async ({
    sourcePartIdentifier,
    targetParentPartIdentifier,
    targetSiblingPartIdentifier,
  }: {
    sourcePartIdentifier: any;
    targetSiblingPartIdentifier?: any;
    targetParentPartIdentifier?: any;
  }) => {
    const blueprintReorder: BlueprintReorder = {
      partIdentifier: sourcePartIdentifier,
      reorder: {
        newPreviousSiblingIdentifier: targetSiblingPartIdentifier,
        parentIdentifier: targetParentPartIdentifier,
      },
    };
    await patchMutation.mutate(blueprintReorder, {
      ...options,
      onError: async (error, variables, context) => {
        captureException(error);

        if (options?.onError) {
          await options.onError(error, variables, context);
        }
      },
    });
  };
  return updateOrder;
}

type PublishQuestionnaireVersionVariables = {
  description: string;
};

export function usePublishQuestionnaireVersionMutation(
  options?: UseMutationOptions<void, unknown, PublishQuestionnaireVersionVariables>
): UseMutationResult<void, unknown, PublishQuestionnaireVersionVariables> {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { questionnaireVersionId } = useContext(QuestionnaireVersionDataContext);
  return useMutation<void, unknown, PublishQuestionnaireVersionVariables>(
    async ({ description }) => {
      if (!questionnaireVersionId) {
        throw new Error('No questionnaire Id');
      }
      await publishQuestionnaire(questionnaireVersionId, description);

      dispatch(notificationSlice.actions.setSuccess({ message: t('notifications.publishQuestionnaireSuccess') }));

      void queryClient.invalidateQueries([QueryId.questionnaireVersions]);
      void queryClient.invalidateQueries([QueryId.allQuestionnaireVersions]);
      void queryClient.invalidateQueries([QueryId.questionnaireVersion, questionnaireVersionId]);
    },
    {
      ...options,
      onError: async (err, variables, context) => {
        const parsedError = customAxiosErrorWithUnknownDataSchema.safeParse(err);

        if (!parsedError.success) {
          dispatch(notificationSlice.actions.setError({ message: t('notifications.failedToPublishQuestionnaire') }));
          return;
        }

        const errorId = parsedError.data.response?.data?.errors?.errorId;

        if (errorId === ApiErrorIds.QuestionnaireVersionMissingEntityMappingsError) {
          dispatch(
            notificationSlice.actions.setError({
              message: t('notifications.failedToPublishQuestionnaireMissingEntityMappings'),
            })
          );
        } else if (errorId === ApiErrorIds.QuestionnaireVersionEntityMappingsParsingError) {
          dispatch(
            notificationSlice.actions.setError({
              message: t('notifications.failedToPublishQuestionnaireEntityMappingsParsingError'),
            })
          );
        } else {
          dispatch(notificationSlice.actions.setError({ message: t('notifications.failedToPublishQuestionnaire') }));
        }
        captureException(err);

        if (options?.onError) {
          await options.onError(err, variables, context);
        }
      },
    }
  );
}

type NewDraftQuestionnaireVariables = {
  description: string;
};

type NewDraftQuestionnaireResult = {
  questionnaireVersionId: string;
};

export function useNewDraftQuestionnaireVersionMutation(
  options?: UseMutationOptions<NewDraftQuestionnaireResult, unknown, NewDraftQuestionnaireVariables>
): UseMutationResult<NewDraftQuestionnaireResult, unknown, NewDraftQuestionnaireVariables> {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { questionnaireVersionData } = useContext(QuestionnaireVersionDataContext);
  return useMutation<NewDraftQuestionnaireResult, unknown, NewDraftQuestionnaireVariables>(
    async ({ description }) => {
      if (!questionnaireVersionData) {
        throw new Error('No questionnaire Id');
      }
      const { questionnaireId, blueprint } = questionnaireVersionData;
      const data = await createNewDraftQuestionnaireVersion(questionnaireId, description, blueprint);

      dispatch(notificationSlice.actions.setSuccess({ message: t('notifications.newDraftQuestionnaireSuccess') }));

      void queryClient.invalidateQueries([QueryId.questionnaireVersions]);

      return { questionnaireVersionId: data.id };
    },
    {
      ...options,
      onError: async (err, variables, context) => {
        notificationSlice.actions.setError({
          message: t('notifications.failedToCreateNewDraftQuestionnaire'),
        });
        captureException(err);

        if (options?.onError) {
          await options.onError(err, variables, context);
        }
      },
    }
  );
}
