import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { GhostButton } from '../../../../../Components/Button/GhostButton';
import { Icon } from '../../../../../Components/Icons';

export type Props = {
  url: string;
};

export const Container = styled.div`
  display: flex;
  height: 100%;
`;

export const Link = styled.a`
  &&& {
    margin: auto 0;
    display: flex;
    color: ${(props) => props.theme.colors.blue[50]};
    > svg {
      margin-top: 4px;
      margin-right: 5px;
    }
  }
`;

export const SubscriptionToolLink: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  return (
    <Container>
      <GhostButton
        color='primary'
        startIcon={<Icon name='emailSubscriptionStatus' variant='primary' />}
        onClick={() => window.open(props.url)}
      >
        {t('leadDetailDrawer.checkSubscriptionStatus')}
      </GhostButton>
    </Container>
  );
};
