import Box from '@material-ui/core/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Dot } from '../../Components/Icons/Dot';
import Typography from '../../Components/Typography';
import { LeadStatus } from '../../Models/Lead';
import { PlatformColorList } from '../../Styles/Types';

type ProgressProps = {
  progress: number;
  className?: string;
  status: LeadStatus;
};

export function Progress(props: ProgressProps): React.ReactElement {
  const { t } = useTranslation();
  const { className, progress, status } = props;
  // TODO: use application status instead of lead status
  const isStarted = status !== 'new' && status !== 'invited' && progress > 0;
  const isCompleted = progress === 100;

  let color: PlatformColorList = 'purple';
  let text = t('pending');

  if (isCompleted) {
    color = 'green';
    text = t('completed');
  } else if (isStarted) {
    color = 'yellow';
    text = t('percentCompleted', { percent: progress });
  }

  return (
    <div className={className}>
      <Box mr={1} component='span'>
        <Dot color={color} />
      </Box>
      <Typography variant='body3' grey={90}>
        {text}
      </Typography>
    </div>
  );
}
