import React from 'react';
import { useTranslation } from 'react-i18next';

import PendingNeedsAnalysis from '../../../../../../Assets/NA-pending.svg';
import { Container, TopText, BottomText, Image } from './Styles';

export function PendingInformation(): React.ReactElement {
  const { t } = useTranslation();
  return (
    <Container>
      <Image src={PendingNeedsAnalysis} alt='' />
      <TopText>{t('tabs.needsAnalysis.pending.pendingInfo')}</TopText>
      <BottomText>{t('tabs.needsAnalysis.pending.hasBeenSent')}</BottomText>
    </Container>
  );
}
