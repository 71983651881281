import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ScreenName, TypewriterTracking } from '@breathelife/react-tracking';

import { Helmet } from '../../Components/Helmet';
import { useDispatch } from '../../Hooks';
import { authenticationSlice } from '../../Redux/Authentication/AuthenticationSlice';
import { userManagementSlice } from '../../Redux/UserManagement/UserManagementSlice';
import LogoutView from './LogoutView';

const { actions: authenticationActions } = authenticationSlice;
const { actions: userManagementActions } = userManagementSlice;

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.grey[20]};
`;

const Content = styled.div`
  width: 100%;
  max-width: 360px;
`;

function LogoutContainer(): React.ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    TypewriterTracking.viewedScreen({ screenName: ScreenName.logout, hashedId: null });
  }, []);

  useEffect(() => {
    dispatch(userManagementActions.reset());
    dispatch(authenticationActions.reset());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Helmet text={t('pageTitles.logout')} />

      <Container>
        <Content>
          <LogoutView />
        </Content>
      </Container>
    </React.Fragment>
  );
}

export default LogoutContainer;
