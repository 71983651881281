import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { ExportedData, ExportQueryParams } from '@breathelife/types';

import { QueryId } from '../../../ReactQuery/common/common.types';
import { salesDecisionRuleManagementSlice } from '../../../Redux/Admin/SalesDecisionRulesManagement/SalesDecisionRulesManagementSlice';
import { settingsSlice } from '../../../Redux/Admin/SettingsManagement/SettingsSlice';
import { themeSlice } from '../../../Redux/Admin/ThemeManagement/ThemeSlice';
import { notificationSlice } from '../../../Redux/Notification/NotificationSlice';
import { createDataExtract, DataExtractRequest, DataExtractResponse } from '../../../Services/DataExtractService';
import { fetchDataForExport } from '../../../Services/ExportDataService';
import { QuestionnaireVersionDetailWithNodeIdInfo } from '../../../Services/QuestionnaireVersionService';

export function useFetchDataForExportMutation(
  options?: UseMutationOptions<ExportedData, unknown, ExportQueryParams>
): UseMutationResult<ExportedData, unknown, ExportQueryParams> {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  async function onSuccess(data: ExportedData, variables: ExportQueryParams, context: unknown): Promise<void> {
    if (data.blueprint) {
      queryClient.setQueryData<QuestionnaireVersionDetailWithNodeIdInfo | undefined>(
        [QueryId.questionnaireVersion, variables.questionnaireVersionId],
        (queryData) => {
          if (queryData && data.blueprint) {
            return { ...queryData, blueprint: data.blueprint };
          }
          return queryData;
        }
      );
    }

    if (data.salesDecisionRules) {
      dispatch(salesDecisionRuleManagementSlice.actions.setRules({ rules: data.salesDecisionRules }));
    }

    if (data.theme) {
      dispatch(themeSlice.actions.setTheme({ theme: data.theme }));
    }

    if (data.settings) {
      dispatch(settingsSlice.actions.setSettings({ settings: data.settings }));
    }

    dispatch(
      notificationSlice.actions.setSuccess({
        message: t('notifications.exportDataSuccess'),
        autoHideDuration: 3000,
      })
    );

    if (options?.onSuccess) {
      await options.onSuccess(data, variables, context);
    }
  }

  async function onError(e: unknown, variables: ExportQueryParams, context: unknown): Promise<void> {
    dispatch(
      notificationSlice.actions.setError({
        message: t('notifications.failedToExportData'),
      })
    );

    if (options?.onError) {
      await options.onError(e, variables, context);
    }
  }

  return useMutation<ExportedData, unknown, ExportQueryParams, unknown>((query) => fetchDataForExport(query), {
    onSuccess,
    onError,
  });
}

export function useCreateDataExtractMutation(
  options?: UseMutationOptions<DataExtractResponse, unknown, DataExtractRequest>
): UseMutationResult<DataExtractResponse, unknown, DataExtractRequest> {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useMutation(createDataExtract, {
    ...options,
    onSuccess: async (data, variables, context) => {
      dispatch(
        notificationSlice.actions.setSuccess({
          message: t('notifications.dataExtractSuccess'),
          autoHideDuration: 5000,
        })
      );

      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },

    onError: async (error, variables, context) => {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.dataExtractFailure'),
          autoHideDuration: 5000,
        })
      );

      if (options?.onError) {
        await options.onError(error, variables, context);
      }
    },
  });
}
