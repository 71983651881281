import { SortDirection } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import _ from 'lodash';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import LoadingView from '../../../Components/LoadingView/LoadingView';
import { Pagination } from '../../../Components/Pagination/Pagination';
import { Table } from '../../../Components/Table/Table';
import { getFileIcon } from '../../../Helpers/getFileIcon';
import { useCarrierContext } from '../../../Hooks';
import { Application } from '../../../Models/Application';
import { LeadTab, ModalState, ModalType } from '../../../Models/Layout';
import { Lead } from '../../../Models/Lead';
import { DeleteLeadModal } from '../../../Pages/Home/Modals/DeleteLeadModal';
import { DownloadFilesModal } from '../../../Pages/Home/Modals/DownloadFilesModal';
import { LeadArchiveConfirmationModal } from '../../../Pages/Home/Modals/LeadArchiveConfirmationModal';
import { SendEmailModal } from '../../../Pages/Home/Modals/SendEmailModal';
import { LeadsAssignModalContainer } from '../../../Pages/Home/Modals/UserListModal/LeadsAssignModalContainer';
import { LeadsListFilterModalContainer } from '../../../Pages/Home/Modals/UserListModal/LeadsListFilterModalContainer';
import { LeadsPageDataContext, PER_PAGE_OPTIONS } from '../../../Pages/Leads/LeadsPageDataContextProvider';
import { defaultState as defaultLayoutState, ModalPayload } from '../../../Redux/Layout/LayoutSlice';
import LeadDetailContainer from '../LeadDetailView/LeadDetailContainer';
import { CopyApplicationConfirmationModal } from '../Modals/CopyApplicationConfirmationModal';
import emptyListViewLookup from './EmptyListView';
import { LeadMoreActionButton } from './LeadMoreActionButton';
import { ListHeader } from './ListHeader';
import { MaterialTableContainer } from './Styles';

export type LeadsListViewProps = {
  isCreatingLeadAndLaunchingApplication: boolean;
  modalState: ModalState;
  onCreateLeadClick: () => void;
  onClickMarketingDataExtractButton: () => void;
  onCopyClick: (application: Application) => void;
  onDeleteClick: (lead: Lead) => void;
  onDownloadFilesClick: () => void;
  onSearchChange: (search: string) => void;
  onSendEmailClick: (lead: Lead) => void;
  onTableRowClick: (lead?: Lead) => void;
  onToggleArchiveClick: (lead: Lead, userId: string) => void;
  openAssignModal: (lead: Lead) => void;
  selectedTab: LeadTab;
  setModalState: (state: ModalPayload) => void;
  updateModalState: (payload: Partial<ModalState>) => void;
};

export function LeadsListView(props: LeadsListViewProps): React.ReactElement {
  const {
    isCreatingLeadAndLaunchingApplication,
    modalState,
    onCreateLeadClick,
    onClickMarketingDataExtractButton,
    onCopyClick,
    onDeleteClick,
    onDownloadFilesClick,
    onSearchChange,
    onSendEmailClick,
    onTableRowClick: onTableRowClickProp,
    onToggleArchiveClick,
    openAssignModal: openAssignModalOperation,
    selectedTab,
    setModalState,
    updateModalState,
  } = props;

  const { externalResources, features } = useCarrierContext();
  const { t } = useTranslation();

  const {
    renderedTableColumnsInfo,
    tableOptions,
    leadsQueryIsLoading,
    leadsQueryIsSuccess,
    leads,
    totalNumberOfLeads,
    setFilters,
    setPage,
    setPerPage,
    setSorting,
  } = useContext(LeadsPageDataContext);

  const { page: currentPage, filters: filterParams, perPage } = tableOptions;

  //eslint-disable-next-line react-hooks/exhaustive-deps
  const EmptyListView = useMemo(() => emptyListViewLookup(selectedTab), [leads]);

  const onTableRowClick = useCallback(
    (id: string) => {
      const selectedLead = leads.find((lead) => {
        return lead.id.toString() === id;
      });
      onTableRowClickProp(selectedLead);
    },
    [onTableRowClickProp, leads]
  );

  const onCloseModal = useCallback(() => {
    // We don't reset `type` here so that the exit transitions can be rendered properly
    updateModalState(_.omit(defaultLayoutState.modalState, 'type'));
  }, [updateModalState]);

  const hasLeads = leads.length > 0;

  const downloadableFiles = externalResources?.downloadableFiles || [];
  const filesToDownload = downloadableFiles.map((file) => ({
    ...file,
    icon: getFileIcon(file.type),
  }));

  const onOrderChange = useCallback(
    (columnId, sortDirection: SortDirection) => {
      if (!sortDirection) {
        return;
      }
      setSorting(columnId, sortDirection);
    },
    [setSorting]
  );

  return (
    <React.Fragment>
      <ListHeader
        onSearchChange={onSearchChange}
        onFiltersChanged={setFilters}
        onCreateLeadClick={onCreateLeadClick}
        onClickMarketingDataExtractButton={onClickMarketingDataExtractButton}
        setModalState={setModalState}
        selectedUserId={filterParams.selectedUserId}
        allowApplicationMarketingDataExtract={features.applicationMarketingDataExtract?.enabled}
        searchTerm={filterParams.$search}
        allowLeadCreation={features.leadCreation.enabled}
        isCreatingLeadAndLaunchingApplication={isCreatingLeadAndLaunchingApplication}
      />

      {leadsQueryIsLoading && <LoadingView />}

      {hasLeads && renderedTableColumnsInfo && (
        <MaterialTableContainer data-testid={`lead-table-${LeadTab[selectedTab]}`} role='region'>
          <Table<Lead>
            data={leads}
            onRowClick={onTableRowClick}
            onOrderChange={onOrderChange}
            initialState={{
              sortBy: [
                ...(tableOptions.sort
                  ? [{ id: tableOptions.sort.columnName, desc: tableOptions.sort.direction === 'desc' }]
                  : []),
              ],
            }}
            columns={[
              ...renderedTableColumnsInfo,
              {
                id: 'actions',
                Header: 'Actions',
                defaultCanSort: false,
                disableSortBy: true,
                accessor: (lead: Lead) => lead,
                Cell: ({ value }: { value: Lead }) => (
                  <LeadMoreActionButton
                    lead={value}
                    onCopyClick={onCopyClick}
                    onDeleteClick={onDeleteClick}
                    onSendEmailClick={onSendEmailClick}
                    onAssignClick={openAssignModalOperation}
                    onToggleArchiveClick={onToggleArchiveClick}
                  />
                ),
              },
            ]}
          />

          <Box position='relative' px={2.5}>
            <Pagination
              total={totalNumberOfLeads}
              page={currentPage}
              perPage={perPage}
              perPageOptions={PER_PAGE_OPTIONS}
              onPageChange={setPage}
              onPerPageChange={setPerPage}
            />
          </Box>
        </MaterialTableContainer>
      )}

      {!hasLeads && leadsQueryIsSuccess && (
        <EmptyListView
          hasFilters={_.values(filterParams).filter((value) => value !== undefined).length > 0}
          onCreateLeadClick={onCreateLeadClick}
          isCreatingLeadAndLaunchingApplication={isCreatingLeadAndLaunchingApplication}
          allowLeadCreation={features.leadCreation.enabled}
        />
      )}

      <LeadDetailContainer
        onSendEmailClick={(lead: Lead) => onSendEmailClick(lead)}
        onToggleArchiveClick={onToggleArchiveClick}
        onCopyClick={onCopyClick}
        onDeleteClick={onDeleteClick}
        onDownloadFilesClick={onDownloadFilesClick}
      />
      {modalState.type === ModalType.email && modalState.lead && (
        <SendEmailModal isOpen={modalState.isOpen} lead={modalState.lead} closeModal={onCloseModal} />
      )}
      {modalState.type === ModalType.copyApplication && modalState.application && (
        <CopyApplicationConfirmationModal
          isOpen={modalState.isOpen}
          application={modalState.application}
          closeModal={onCloseModal}
        />
      )}
      {modalState.type === ModalType.deleteLead && modalState.lead && (
        <DeleteLeadModal isOpen={modalState.isOpen} lead={modalState.lead} closeModal={onCloseModal} />
      )}
      {modalState.type === ModalType.assign && modalState.lead && (
        <LeadsAssignModalContainer
          isOpen={modalState.isOpen}
          lead={modalState.lead}
          closeModal={onCloseModal}
          submitLabel={t('cta.assign')}
        />
      )}
      {modalState.type === ModalType.leadsListFilter && (
        <LeadsListFilterModalContainer
          isOpen={modalState.isOpen}
          onModalSubmit={(selectedUserId) => {
            setModalState({ modalState: defaultLayoutState.modalState });
            setFilters({ selectedUserId });
          }}
          closeModal={onCloseModal}
          submitLabel={t('modals.leadsListFilters.submit')}
          defaultSelectedUserId={filterParams.selectedUserId}
        />
      )}
      {modalState.type === ModalType.archiveConfirmation && modalState.lead && (
        <LeadArchiveConfirmationModal isOpen={modalState.isOpen} lead={modalState.lead} closeModal={onCloseModal} />
      )}
      {modalState.type === ModalType.downloadFiles && (
        <DownloadFilesModal isOpen={modalState.isOpen} files={filesToDownload || []} closeModal={onCloseModal} />
      )}
    </React.Fragment>
  );
}
