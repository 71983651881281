import { Box } from '@material-ui/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Permission } from '@breathelife/types';

import { SubmitButton } from '../../../Components/Button/SubmitButton';
import { RestrictedToPermission } from '../../../Components/Restricted/RestrictedToPermission';
import Typography from '../../../Components/Typography';
import { CarrierContext } from '../../../Context/CarrierContext';
import { useDispatch, useNavigation } from '../../../Hooks';
import { ModalLayout } from '../../../Layouts/Modal/ModalLayout';
import { Lead } from '../../../Models/Lead';
import { useUpdateLeadArchiveMutation } from '../../../ReactQuery/Lead/lead.mutations';
import { navigateToLeadsList } from '../../../Redux/Navigation/NavigationOperations';

export type Props = {
  isOpen: boolean;
  closeModal: () => void;
  lead: Lead;
};

export function LeadArchiveConfirmationModal(props: Props): React.ReactElement {
  const { t } = useTranslation();
  const { features } = useContext(CarrierContext);
  const dispatch = useDispatch();
  const { leadTab } = useNavigation();
  const { isOpen, closeModal, lead } = props;

  const updateLeadArchiveMutation = useUpdateLeadArchiveMutation({
    onSuccess: () => {
      dispatch(navigateToLeadsList(leadTab));
      closeModal();
    },
  });

  function updateLeadArchive(): void {
    updateLeadArchiveMutation.mutate({ leadId: lead.id, archive: !lead.archived });
  }

  const title = lead.archived
    ? t(
        features.hiddenLeads?.enabled
          ? 'modals.leadsArchive.unarchiveApplicationTitle'
          : 'modals.leadsArchive.unarchiveLeadTitle'
      )
    : t(
        features.hiddenLeads?.enabled
          ? 'modals.leadsArchive.archiveApplicationTitle'
          : 'modals.leadsArchive.archiveLeadTitle'
      );

  return (
    <ModalLayout
      maxWidth='sm'
      isOpen={isOpen}
      closeModal={closeModal}
      title={title}
      submitButton={
        <RestrictedToPermission permission={Permission.LeadArchive}>
          <SubmitButton
            isLoading={updateLeadArchiveMutation.isLoading}
            disabled={updateLeadArchiveMutation.isLoading}
            onClick={updateLeadArchive}
            data-testid='lead-archive-confirm'
          >
            {lead.archived ? t('cta.unarchive') : t('cta.archive')}
          </SubmitButton>
        </RestrictedToPermission>
      }
    >
      <Box mt={2} mb={2.5}>
        <Typography display='block' variant='body1' grey={90}>
          {t(
            features.hiddenLeads?.enabled
              ? 'modals.leadsArchive.applicationNotAssignedToYou'
              : 'modals.leadsArchive.leadNotAssignedToYou'
          )}
        </Typography>
        <Typography display='block' variant='body1' grey={90}>
          {lead.archived
            ? t('modals.leadsArchive.unarchiveConfirmationQuestion')
            : t('modals.leadsArchive.archiveConfirmationQuestion')}
        </Typography>
      </Box>
    </ModalLayout>
  );
}
