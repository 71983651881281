import { CircularProgressProps } from '@material-ui/core';
import MuiButton, { ButtonProps } from '@material-ui/core/Button';
import React from 'react';

import Typography from '../Typography';
import { StyledLoader } from './Styles';

// The `component` typing has been fixed in Material-ui Version 5. Unfortunately, in version 4, component
// is not typed properly in `ButtonProps` but is supported by the Button API.
export function Button(
  props: ButtonProps & {
    component?: unknown;
    isLoading?: boolean;
    loaderProps?: CircularProgressProps;
    innerRef?: React.Ref<HTMLButtonElement>;
  }
): React.ReactElement {
  const { children, innerRef, disabled, isLoading, loaderProps, ...restProps } = props;
  return (
    <MuiButton {...restProps} ref={innerRef} disabled={disabled ?? isLoading}>
      {isLoading && <StyledLoader size='20px' {...loaderProps} />}
      {!isLoading && <Typography variant='button'>{children}</Typography>}
    </MuiButton>
  );
}
