import React from 'react';

import errorSvg from '../../Assets/error.svg';
import { ErrorContainer } from './Styles';

export type Props = {
  message?: string;
  help?: string;
};

export const GenericError = (props: Props): React.ReactElement => {
  return (
    <ErrorContainer>
      <img src={errorSvg} alt='' />
      <p>{props.message ?? 'Something unexpected has happened.'}</p>
      <p>{props.help ?? 'Try reloading the page!'}</p>
    </ErrorContainer>
  );
};
