import React from 'react';
import styled from 'styled-components';

import { SelectMui, SelectMuiProps } from '@breathelife/ui-components';

export const Select = styled(SelectMui)`
  &&& {
    width: 160px;
    .MuiSelect-icon {
      color: ${(props) => props.theme.colors.grey[90]};
    }
  }
` as <T extends string | number = string>(props: SelectMuiProps<T>) => React.ReactElement;
