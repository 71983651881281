import React from 'react';

import { RenderingSectionGroup } from '@breathelife/questionnaire-engine';

import { SectionGroup } from './SectionGroup';

type SectionGroupsProps = {
  sectionGroups: RenderingSectionGroup[];
};

export function SectionGroups(props: SectionGroupsProps): React.ReactElement | null {
  const { sectionGroups } = props;
  return (
    <React.Fragment>
      {sectionGroups.map((sectionGroup: RenderingSectionGroup) => (
        <SectionGroup key={sectionGroup.id} sectionGroup={sectionGroup} />
      ))}
    </React.Fragment>
  );
}
