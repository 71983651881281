import React from 'react';

import { SelectMui, SelectMuiProps } from '@breathelife/ui-components';

import { US_STATES } from '../../Helpers/locations';

type UsStateSelectProps = Omit<SelectMuiProps, 'options'>;

export function UsStateSelect(props: UsStateSelectProps): React.ReactElement {
  const options = Object.entries(US_STATES).map(([value, label]) => ({
    value,
    label,
  }));
  return <SelectMui {...props} options={options} />;
}
