import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { Language, LocalizedThirdPartyIntegration } from '@breathelife/types';

import { QueryId } from '../../../ReactQuery/common/common.types';
import ApiService from '../../../Services/ApiService';

async function fetchThirdPartyIntegrations(): Promise<LocalizedThirdPartyIntegration[]> {
  const { data } = await ApiService.admin.fetchThirdPartyIntegrations(Language.en);
  return data;
}

export function useFetchThirdPartyIntegrationsQuery(
  options?: UseQueryOptions<LocalizedThirdPartyIntegration[], unknown>
): UseQueryResult<LocalizedThirdPartyIntegration[], unknown> {
  return useQuery<LocalizedThirdPartyIntegration[], unknown>(
    QueryId.thirdPartyIntegrations,
    fetchThirdPartyIntegrations,
    {
      ...options,
      onError: (e) => {
        options?.onError?.(e);
      },
    }
  );
}
