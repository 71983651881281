import Box from '@material-ui/core/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';

import emptyListImage from '../../../../Assets/empty-application-list.svg';
import { getUserDisplayName } from '../../../../Helpers/user';
import { useSelector } from '../../../../Hooks';
import { EmptyListContainer, Img, ListText, ListTitle } from './Styles';

export const TabArchivedEmptyListView: React.FC = () => {
  const { t } = useTranslation();
  const authentication = useSelector((store) => store.leadPlatform.authentication);
  const displayName = authentication.user ? ` ${getUserDisplayName(authentication.user)}` : '';
  const listTitleText = `${t('tabs.archived.empty.hello', { name: displayName })}!`;
  return (
    <EmptyListContainer textAlign='center' pt={12} minHeight={400}>
      <Box mb={3}>
        <Img src={emptyListImage} alt='' />
      </Box>
      <Box mb={1.5}>
        <ListTitle variant='h1' grey={70}>
          {listTitleText}
        </ListTitle>
      </Box>
      <ListText variant='body1' grey={60}>
        {t('tabs.archived.empty.thereAreNoLeads')}
      </ListText>
    </EmptyListContainer>
  );
};
