import { Box } from '@material-ui/core';
import { AlertTitle } from '@material-ui/lab';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Conditions, CreateFileTemplateRuleData } from '@breathelife/types';

import { Alert } from '../../../../../Components/Alert/Alert';
import { SubmitButton } from '../../../../../Components/Button/SubmitButton';
import { useCreateFileTemplateRuleMutation } from '../../../../../ReactQuery/Document/DocumentRule/documentRule.mutations';
import { notificationSlice } from '../../../../../Redux/Notification/NotificationSlice';

type NewDocumentRuleProps = {
  fileTemplateId: string;
  questionnaireVersionId: string;
};

export function NewDocumentRule(props: NewDocumentRuleProps): React.ReactElement {
  const { t } = useTranslation();

  const createFileTemplateRuleMutation = useCreateFileTemplateRuleMutation({
    onError: () => {
      notificationSlice.actions.setError({
        message: t('notifications.failedToCreateEntity', {
          entity: t('admin.documentDetailManagement.documentRule.singular'),
        }),
      });
    },
  });

  const [conditionState, setConditionsState] = useState<string>('');
  const [validationState, setValidationState] = useState<null | string>(null);

  const createDocumentRule = async (): Promise<void> => {
    if (conditionState.trim().length < 1) {
      setValidationState('Invalid condition');
    }

    // TODO: DEV-10157 - construct JSON using GUI
    let condition;
    try {
      condition = JSON.parse(conditionState.trim()) as Conditions;
      setValidationState(null);
    } catch (err) {
      setValidationState('Invalid condition');
      return;
    }

    const data: CreateFileTemplateRuleData = {
      templateId: props.fileTemplateId,
      questionnaireVersionId: props.questionnaireVersionId,
      condition,
    };
    await createFileTemplateRuleMutation.mutateAsync(data);
  };

  return (
    <Box p={2}>
      <Box>
        {validationState && (
          <Alert severity={'error'} onClose={() => setValidationState(null)}>
            <AlertTitle>{t('alerts.errorDetails')}</AlertTitle>
            {validationState}
          </Alert>
        )}
      </Box>

      <Box>
        <textarea
          disabled={createFileTemplateRuleMutation.isLoading}
          rows={10}
          cols={60}
          value={conditionState}
          onChange={(event) => setConditionsState(event.target.value)}
        />
      </Box>

      <Box display='flex' justifyContent='flex-end'>
        <SubmitButton
          data-testid='create-document-rule-button'
          isLoading={createFileTemplateRuleMutation.isLoading}
          onClick={createDocumentRule}
        >
          {t('cta.save')}
        </SubmitButton>
      </Box>
    </Box>
  );
}
