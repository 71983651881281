import { useCallback, useContext } from 'react';

import {
  isFieldPartIdentifier,
  isQuestionPartIdentifier,
  isSectionGroupPartIdentifier,
  isSectionPartIdentifier,
  isSubsectionPartIdentifier,
} from '@breathelife/types';
import { TreeViewRepositionOptions } from '@breathelife/ui-components';

import { useUpdateQuestionnaireElementBlueprintOrder } from '../../../../../ReactQuery/Admin/Questionnaire/questionnaireVersion.mutations';
import { QuestionnaireVersionDataContext } from '../../QuestionnaireVersionDataContextProvider';
import { makePartIdentifierFromTreePath } from '../Helpers/makePartIdentifierFromTreePath';

export function useQuestionnaireReordering(): {
  handleReposition: (pathIdentifier: string[], options: TreeViewRepositionOptions) => void;
  getNewParentPathFromPreviousTreeItemPath: (movedItemPath: string[], previousTreeItemPath: string[]) => string[];
} {
  const { questionnaireVersionId } = useContext(QuestionnaireVersionDataContext);

  const reorderBlueprint = useUpdateQuestionnaireElementBlueprintOrder(questionnaireVersionId);

  /**
   * Tree View needs a way to determine the path to the new parent of a moved item
   * based on the item after which it is dragged
   *
   * If the moved item is a section:
   *    - The new parent is the tree root
   *
   * If the moved item is a subsection
   *    - The new parent is the parent section of the item after which it was dragged
   *
   * If the moved item is a question
   *    - If the item before it is a subsection, a question, or a field,
   *      then the new parent is the parent subsection of the item after which it was dragged
   *
   * If the moved item is a field
   *     - If the item before it is a question or a field,
   *      then the new parent is the parent question of the item after which it was dragged
   *
   */
  const getNewParentPathFromPreviousTreeItemPath = (
    movedItemPath: string[],
    previousTreeItemPath: string[]
  ): string[] => {
    const movedItemPartIdentifier = makePartIdentifierFromTreePath(movedItemPath);
    const previousTreeItemPartIdentifier = makePartIdentifierFromTreePath(previousTreeItemPath);

    if (isSectionGroupPartIdentifier(movedItemPartIdentifier)) {
      return [];
    }

    if (
      isSectionPartIdentifier(movedItemPartIdentifier) &&
      (isSectionGroupPartIdentifier(previousTreeItemPartIdentifier) ||
        isSectionPartIdentifier(previousTreeItemPartIdentifier) ||
        isSubsectionPartIdentifier(previousTreeItemPartIdentifier) ||
        isQuestionPartIdentifier(previousTreeItemPartIdentifier) ||
        isFieldPartIdentifier(previousTreeItemPartIdentifier))
    ) {
      return [previousTreeItemPartIdentifier.sectionGroupPartName];
    }

    if (
      isSubsectionPartIdentifier(movedItemPartIdentifier) &&
      (isSectionPartIdentifier(previousTreeItemPartIdentifier) ||
        isSubsectionPartIdentifier(previousTreeItemPartIdentifier) ||
        isQuestionPartIdentifier(previousTreeItemPartIdentifier) ||
        isFieldPartIdentifier(previousTreeItemPartIdentifier))
    ) {
      return [previousTreeItemPartIdentifier.sectionGroupPartName, previousTreeItemPartIdentifier.sectionPartName];
    }

    if (
      isQuestionPartIdentifier(movedItemPartIdentifier) &&
      (isSubsectionPartIdentifier(previousTreeItemPartIdentifier) ||
        isQuestionPartIdentifier(previousTreeItemPartIdentifier) ||
        isFieldPartIdentifier(previousTreeItemPartIdentifier))
    ) {
      return [
        previousTreeItemPartIdentifier.sectionGroupPartName,
        previousTreeItemPartIdentifier.sectionPartName,
        previousTreeItemPartIdentifier.subsectionPartName,
      ];
    }

    if (
      isFieldPartIdentifier(movedItemPartIdentifier) &&
      (isFieldPartIdentifier(previousTreeItemPartIdentifier) ||
        isQuestionPartIdentifier(previousTreeItemPartIdentifier))
    ) {
      return [
        previousTreeItemPartIdentifier.sectionGroupPartName,
        previousTreeItemPartIdentifier.sectionPartName,
        previousTreeItemPartIdentifier.subsectionPartName,
        previousTreeItemPartIdentifier.questionPartName,
      ];
    }

    throw new Error('Invalid drag');
  };

  /**
   * On reposition, TreeView will send a request for reposition that contains:
   *    - The path of the new parent
   *    - Sometimes the path of the new sibling
   *
   * This function transforms these paths into part identifiers,
   * checks the the parent and siblings are one level apart,
   * and sends a reorder request to the blueprint modifier.
   */
  const handleReposition = useCallback(
    (pathIdentifier: string[], options: TreeViewRepositionOptions) => {
      const repositionedItemPartIdentifier = makePartIdentifierFromTreePath(pathIdentifier);

      const { newSiblingPath, newParentPath } = options;

      const newSiblingPartIdentifier = newSiblingPath ? makePartIdentifierFromTreePath(newSiblingPath) : undefined;
      const newParentPartIdentifier = newParentPath ? makePartIdentifierFromTreePath(newParentPath) : undefined;

      if (
        isSectionPartIdentifier(repositionedItemPartIdentifier) &&
        (!newSiblingPartIdentifier || isSectionPartIdentifier(newSiblingPartIdentifier))
      ) {
        void reorderBlueprint({
          sourcePartIdentifier: repositionedItemPartIdentifier,
          targetSiblingPartIdentifier: newSiblingPartIdentifier || undefined,
        });

        return;
      }

      if (
        isSubsectionPartIdentifier(repositionedItemPartIdentifier) &&
        (!newSiblingPartIdentifier || isSubsectionPartIdentifier(newSiblingPartIdentifier)) &&
        (!newParentPartIdentifier || isSectionPartIdentifier(newParentPartIdentifier))
      ) {
        void reorderBlueprint({
          sourcePartIdentifier: repositionedItemPartIdentifier,
          targetSiblingPartIdentifier: newSiblingPartIdentifier || undefined,
          targetParentPartIdentifier: newParentPartIdentifier || undefined,
        });

        return;
      }

      if (
        isQuestionPartIdentifier(repositionedItemPartIdentifier) &&
        (!newSiblingPartIdentifier || isQuestionPartIdentifier(newSiblingPartIdentifier)) &&
        (!newParentPartIdentifier || isSubsectionPartIdentifier(newParentPartIdentifier))
      ) {
        void reorderBlueprint({
          sourcePartIdentifier: repositionedItemPartIdentifier,
          targetSiblingPartIdentifier: newSiblingPartIdentifier || undefined,
          targetParentPartIdentifier: newParentPartIdentifier || undefined,
        });

        return;
      }

      if (
        isFieldPartIdentifier(repositionedItemPartIdentifier) &&
        (!newSiblingPartIdentifier || isFieldPartIdentifier(newSiblingPartIdentifier)) &&
        (!newParentPartIdentifier || isQuestionPartIdentifier(newParentPartIdentifier))
      ) {
        void reorderBlueprint({
          sourcePartIdentifier: repositionedItemPartIdentifier,
          targetSiblingPartIdentifier: newSiblingPartIdentifier || undefined,
          targetParentPartIdentifier: newParentPartIdentifier || undefined,
        });

        return;
      }
    },
    [reorderBlueprint]
  );
  return {
    handleReposition,
    getNewParentPathFromPreviousTreeItemPath,
  };
}
