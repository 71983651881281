import Typography from '@material-ui/core/Typography/Typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CopyDeckUnhandledRow } from '@breathelife/types';
import { Box, List, ListItem } from '@breathelife/ui-components';

type ErrorListProps = {
  items: CopyDeckUnhandledRow[];
};

type Opened = boolean;
type State = Record<number, Opened>;

const ListItemWrapper = styled.a`
  cursor: pointer;
`;

export function CopyDeckUnhandledRows(props: ErrorListProps): React.ReactElement {
  const { items } = props;
  const [state, setState] = useState<State>(() => {
    return items.reduce((acc, _item, index) => {
      acc[index] = false;
      return acc;
    }, {});
  });
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Box mb={1}>
        <Typography variant='h2'>{t('admin.questionnaireManagement.importFromCopyDeckListErrors')}</Typography>
      </Box>

      <List>
        {items.map((item, index) => (
          <ListItemWrapper
            key={index}
            onClick={() => setState((prevState) => ({ ...prevState, [index]: !prevState[index] }))}
          >
            <ListItem iconType={'bullet'}>
              <Box mb={1}>
                <Typography variant='body1'>{item.value.fieldName}</Typography>
              </Box>
              {state[index] && (
                <Box>
                  {item.errors.map((error, i) => (
                    <Typography key={`${error.message}-${i}`} variant='body2' color='error'>
                      <Box display='flex' flexDirection='column' mb={1} mt={-1}>
                        <div>&emsp;{error.type}</div>
                        {error.message && <div>&emsp;{error.message}</div>}
                      </Box>
                    </Typography>
                  ))}
                </Box>
              )}
            </ListItem>
          </ListItemWrapper>
        ))}
      </List>
    </React.Fragment>
  );
}
