import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { User } from '@breathelife/types';

import { ActionsContainer } from '../../../../Components/ActionsContainer/ActionsContainer';
import { IconButton } from '../../../../Components/Controls';
import { Icon } from '../../../../Components/Icons';

type Props = {
  disableResendInvitationEmail?: boolean;
  disableToggleBlockUser?: boolean;
  user?: User;
  isLoading: boolean;
  onOpenResendInvitationModal: (user: User) => void;
  toggleBlockUser: (user: User) => void;
};

export function ActionButtons(props: Props): React.ReactElement {
  const { t } = useTranslation();
  const {
    user,
    onOpenResendInvitationModal: onOpenResendInvitationModalProp,
    toggleBlockUser: toggleBlockUserProp,
  } = props;

  const onOpenResendInvitationModal = useCallback(() => {
    if (!user || !onOpenResendInvitationModalProp) return;
    onOpenResendInvitationModalProp(user);
  }, [user, onOpenResendInvitationModalProp]);

  const toggleBlockUser = useCallback(() => {
    if (!user || !toggleBlockUserProp) return;
    toggleBlockUserProp(user);
  }, [user, toggleBlockUserProp]);

  // TODO: Use correct icons for resend invitation and block
  return (
    <ActionsContainer>
      <IconButton
        data-testid='actionButton-notify'
        icon={<Icon name='notification' />}
        title={t('cta.sendAReminder')}
        disabled={!!props.disableResendInvitationEmail}
        showSkeleton={props.isLoading}
        onClick={onOpenResendInvitationModal}
      />
      <IconButton
        data-testid='actionButton-block'
        icon={<Icon name='blockUser' />}
        title={t('cta.block')}
        onClick={toggleBlockUser}
        disabled={!!props.disableToggleBlockUser}
        showSkeleton={props.isLoading}
      />
    </ActionsContainer>
  );
}
