import Box from '@material-ui/core/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';

import filteredListEmptySvg from '../../../../Assets/filtered-list-empty.svg';
import Typography from '../../../../Components/Typography';
import { EmptySearchResultContainer } from './Styles';

export function EmptySearchResultView(): React.ReactElement {
  const { t } = useTranslation();

  return (
    <EmptySearchResultContainer>
      <Box mb={3}>
        <img src={filteredListEmptySvg} alt='' />
      </Box>
      <Box mb={1.5}>
        <Typography variant='h2' grey={70}>
          {t('usersListTable.emptySearchResult.title')}
        </Typography>
      </Box>
      <Box maxWidth={300}>
        <Typography variant='body1' grey={60}>
          {t('usersListTable.emptySearchResult.body')}
        </Typography>
      </Box>
    </EmptySearchResultContainer>
  );
}
