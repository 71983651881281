import MaterialIconButton from '@material-ui/core/IconButton';
import styled, { css } from 'styled-components';

export type Size = 'x-small' | 'small' | 'medium' | 'large';

export type IconButtonStyleProps = { $customSize?: Size; $disableCssEffect?: boolean; $removeBorder?: boolean };

export const imageSize: { [key in Size]: string } = {
  'x-small': '16px',
  small: '24px',
  medium: '36px',
  large: '44px',
};

const imagePadding: { [key in Size]: string } = {
  'x-small': '0',
  small: '5px',
  medium: '10px',
  large: '13px',
};

export const buttonSize: { [key in Size]: string } = {
  'x-small': '16px',
  small: '36px',
  medium: '40px',
  large: '44px',
};

export const IconButton = styled(MaterialIconButton)<IconButtonStyleProps>`
  &&& {
    width: ${(props) => (props.$customSize ? buttonSize[props.$customSize] : buttonSize['medium'])};
    height: ${(props) => (props.$customSize ? buttonSize[props.$customSize] : buttonSize['medium'])};
    background-color: transparent;
    border-radius: 50%;
    padding: 0;
    border: ${(props) => (props.$removeBorder ? undefined : `1px solid ${props.theme.colors.grey[50]}`)};

    > span {
      width: unset;
      height: unset;
      > svg {
        padding: ${(props) => (props.$customSize ? imagePadding[props.$customSize] : imagePadding['medium'])};
        width: ${(props) => (props.$customSize ? imageSize[props.$customSize] : imageSize['medium'])};
        height: ${(props) => (props.$customSize ? imageSize[props.$customSize] : imageSize['medium'])};
      }
    }

    &:disabled {
      opacity: 0.3;
    }
    ${(props) =>
      !props.$disableCssEffect &&
      css`
        &:not(:disabled) {
          &:hover,
          &:active,
          &:focus {
            svg {
              stroke: ${(props) => props.theme.colors.primary.default};
              fill: ${(props) => props.theme.colors.primary[20]};
            }
          }

          &:hover {
            border-color: ${(props) => props.theme.colors.primary.default};
          }

          &:active {
            background-color: ${(props) => props.theme.colors.grey[30]};
            border-color: ${(props) => props.theme.colors.primary.default};
          }

          &:focus:not(:active) {
            outline: 0;
            border-color: ${(props) => props.theme.colors.tertiary.default};
          }
        }
      `}
  }
`;
