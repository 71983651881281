import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { ESignSigner2FAInfo } from '@breathelife/types';

import { participantProcessor } from '../../Helpers/participantProcessor';
import { QueryId } from '../../ReactQuery/common/common.types';
import { processParticipants } from '../../Services/ParticipantsProcessorService';

export function useProcessParticipantsQuery(
  applicationId: string | undefined,
  options?: UseQueryOptions<ESignSigner2FAInfo[]>
): UseQueryResult<ESignSigner2FAInfo[]> {
  return useQuery<ESignSigner2FAInfo[]>(
    [QueryId.participantProcessor, applicationId],
    async () => {
      if (!applicationId) {
        return Promise.reject(new Error('application id is required.'));
      }

      const response = await processParticipants(applicationId);
      return participantProcessor(response);
    },
    {
      ...options,
      staleTime: 1000 * 60 * 10, // 10 minutes
    }
  );
}
