import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { QuestionnairePreviewData } from '@breathelife/types';

import { notificationSlice } from '../../../../../Redux/Notification/NotificationSlice';
import ApiService from '../../../../../Services/ApiService';

export function useOpenQuestionnairePreviewLink(): (
  questionnairePreviewData: QuestionnairePreviewData
) => Promise<void> {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return async function openQuestionnairePreviewLink(questionnairePreviewData: QuestionnairePreviewData) {
    try {
      const { data } = await ApiService.admin.createQuestionnairePreviewLink(questionnairePreviewData);

      window.open(data.link, '_blank');
    } catch (e) {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToCreateAPreview'),
        })
      );
    }
  };
}
