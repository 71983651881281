import React, { createContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionButton } from '../../../Components/Button/ActionButton';
import { useModalState } from '../../../Hooks';
import { useUploadPdfQuestionnaireMutation } from '../../../ReactQuery/Admin/QuestionnaireBuilder/questionnaireBuilder.mutations';
import { DragAndDropUpload } from '../Questionnaire/QuestionnaireEditor/Components/DragAndDropUpload';

interface UploadPdfCtxValue {
  isUploadModalOpen: boolean;
  toggleUploadModal: () => void;
  handlePdfUpload: (file: File[]) => void;
}

type Props = {
  children: React.ReactNode;
};

export const UploadPdfCtx = createContext<UploadPdfCtxValue>({
  isUploadModalOpen: false,
  toggleUploadModal: () => {},
  handlePdfUpload: () => {},
});

export function UploadPdfProvider(props: Props): React.ReactElement {
  const [isUploadModalOpen, onOpenImportModal, onCloseImportModal] = useModalState();

  const uploadPdfQuestionnaireMutation = useUploadPdfQuestionnaireMutation({ onSuccess: onCloseImportModal });

  const toggleUploadModal = (): void => {
    isUploadModalOpen ? onCloseImportModal() : onOpenImportModal();
  };

  const handlePdfData = (fileName: string, reader: FileReader) => (): void => {
    if (reader.result) {
      uploadPdfQuestionnaireMutation.mutate({ pdfUriData: reader.result as string, fileName });
    }
  };

  const handlePdfUpload = (file: File[]): void => {
    const fileData = file[0];
    const reader = new FileReader();
    reader.onload = handlePdfData(fileData.name, reader);
    reader.readAsDataURL(fileData);
  };

  return (
    <UploadPdfCtx.Provider
      value={{
        isUploadModalOpen,
        toggleUploadModal,
        handlePdfUpload,
      }}
    >
      {props.children}
    </UploadPdfCtx.Provider>
  );
}

export function UploadButton(): React.ReactElement {
  const { t } = useTranslation();
  const { toggleUploadModal } = React.useContext(UploadPdfCtx);

  const uploadButtonText = t('admin.questionnaireBuilder.uploadButton');

  return (
    <ActionButton color='primary' variant='contained' onClick={toggleUploadModal}>
      {uploadButtonText}
    </ActionButton>
  );
}

export function UploadModal(): React.ReactElement {
  const { t } = useTranslation();
  const { isUploadModalOpen, toggleUploadModal, handlePdfUpload } = React.useContext(UploadPdfCtx);

  const title = t('admin.questionnaireBuilder.uploadModal');

  return (
    <DragAndDropUpload
      title={title}
      accept={'.pdf'}
      isOpen={isUploadModalOpen}
      handleFileUpload={handlePdfUpload}
      onClose={toggleUploadModal}
    />
  );
}
