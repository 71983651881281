import { DetailViewType, LeadDetailTab } from '../../Models/Layout';
import { defaultState, layoutSlice } from '../../Redux/Layout/LayoutSlice';
import { Dispatch } from '../../Redux/types';

const layoutActions = layoutSlice.actions;

export const openRightPanel =
  (type: DetailViewType, tab: LeadDetailTab) =>
  (dispatch: Dispatch): void => {
    dispatch(
      layoutActions.setRightPanelState({
        rightPanelState: {
          isOpen: true,
          type,
          tab,
        },
      })
    );
  };

export const closeRightPanel =
  () =>
  (dispatch: Dispatch): void => {
    dispatch(layoutActions.setRightPanelState({ rightPanelState: defaultState.rightPanelState }));
  };
