import {
  ExpandableFileTemplateRule,
  FileTemplateRule,
  CreateFileTemplateRuleData,
  PatchFileTemplateRuleData,
} from '@breathelife/types';

import ApiService from './ApiService';

export async function findFileTemplateRules(
  questionnaireVersionId: string,
  options?: Parameters<typeof ApiService.findFileTemplateRules>[1]
): Promise<ExpandableFileTemplateRule[]> {
  const response = await ApiService.findFileTemplateRules(questionnaireVersionId, options);
  return response.data;
}

export async function createFileTemplateRule(data: CreateFileTemplateRuleData): Promise<FileTemplateRule> {
  const response = await ApiService.createFileTemplateRule(data);
  return response.data;
}

export async function patchFileTemplateRule(id: string, data: PatchFileTemplateRuleData): Promise<FileTemplateRule> {
  const response = await ApiService.patchFileTemplateRule(id, { condition: data.condition });
  return response.data;
}

export async function deleteFileTemplateRule(id: string): Promise<void> {
  await ApiService.deleteFileTemplateRule(id);
}
