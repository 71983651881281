import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon } from '../../../Components/Icons';
import Typography from '../../../Components/Typography';

type Direction = 'previous' | 'next';

type Props = {
  onClick: () => void;
  children?: React.ReactNode;
  className?: string;
  direction: Direction;
};

const StyledIconButton = styled(IconButton)<{ direction: Direction }>`
  &&& {
    background-color: ${(props) => props.theme.colors.primary.default};
    margin-right: ${(props) => props.direction === 'previous' && '12px'};
    margin-left: ${(props) => props.direction === 'next' && '12px'};

    svg {
      stroke: ${(props) => props.theme.colors.grey[0]};
      fill: ${(props) => props.theme.colors.primary.default};
      transition: fill 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    &:hover,
    &:focus {
      background-color: ${(props) => props.theme.colors.primary[70]};

      svg {
        fill: ${(props) => props.theme.colors.primary[70]};
      }
    }

    &:active {
      background-color: ${(props) => props.theme.colors.primary[80]};

      svg {
        fill: ${(props) => props.theme.colors.primary[80]};
      }
    }
  }
`;

export function NavigationButton(props: Props): React.ReactElement {
  const { direction, onClick, children, className } = props;
  const { t } = useTranslation();
  return (
    <Box display='flex' alignItems='center' className={className}>
      {direction === 'previous' && (
        <StyledIconButton onClick={onClick} direction={direction} aria-label={t('assistedApplication.previousSection')}>
          <Icon name='leftArrow' />
        </StyledIconButton>
      )}

      <Typography variant='body2'>{children}</Typography>

      {direction === 'next' && (
        <StyledIconButton onClick={onClick} direction={direction} aria-label={t('assistedApplication.nextSection')}>
          <Icon name='rightArrow' />
        </StyledIconButton>
      )}
    </Box>
  );
}
