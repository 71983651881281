import i18next from 'i18next';
import * as yup from 'yup';

import { Language } from '@breathelife/types';

export function localizableFieldSchema(enabledLanguages: Language[], options: { required: boolean }): yup.ObjectSchema {
  if (options.required) {
    return yup
      .object()
      .shape(
        enabledLanguages.reduce(
          (acc, language) => ({ ...acc, [language]: yup.string().required(i18next.t('validation.error.required')) }),
          {}
        )
      );
  }

  return yup.object().shape(enabledLanguages.reduce((acc, language) => ({ ...acc, [language]: yup.string() }), {}));
}
