import * as _ from 'lodash';

import {
  BlueprintCollectionOperator,
  BlueprintConditionsValue,
  BlueprintConditionValue,
  BlueprintSingleConditionValue,
  BooleanOperator,
  ConditionBlueprintType,
  ConsiderationBlueprintRowData,
  SalesDecisionRule,
} from '@breathelife/types';

import { defaultSingleConditionBlueprintValue } from '../../Components/Conditions';
import {
  nodeWithDateValues,
  nodeWithNumberValues,
  nodeWithOptions,
  nodeWithTextValues,
  nodeWithYesNoOptions,
} from '../../Components/Conditions/Helpers/nodeIdFilters';
import { CriteriaMetadata } from '../../Helpers/questionnaireEditor/criteriaMetadata';
import { NodeDetail, QuestionnaireNodeIds } from '../../Helpers/questionnaireEditor/questionnaireNodeIds';

export const BASE_CONDITION_PATH = '../../conditions';
export const DEFAULT_COLLECTION_OPERATOR = BlueprintCollectionOperator.some;

export function generateRowData(rules?: SalesDecisionRule[]): ConsiderationBlueprintRowData[] {
  if (typeof rules === 'undefined' || rules.length === 0) return [];

  return rules.map((rule) => {
    const { id, blueprint, createdAt, identifier } = rule;
    const { outcomeCode, conditions, reason } = blueprint;

    const rowData: ConsiderationBlueprintRowData = {
      id,
      outcomeCode,
      conditions,
      reason,
      identifier,
      createdAt,
    };

    return rowData;
  });
}

export function updateConditionBooleanOperator(
  condition: BlueprintConditionValue | undefined,
  path: string,
  booleanOperator: BooleanOperator
): BlueprintConditionValue | undefined {
  if (!condition) return;
  const clonedPreviousState = _.cloneDeep(condition) as BlueprintConditionsValue;

  const isRootCondition = path === '';

  if (isRootCondition) {
    clonedPreviousState.booleanOperator = booleanOperator;

    return clonedPreviousState;
  } else {
    const newConditionBlueprint = _.set(clonedPreviousState, `${path}.booleanOperator`, booleanOperator);
    return newConditionBlueprint;
  }
}

export function updateConditionBlueprint(
  condition: BlueprintConditionValue | undefined,
  path: string,
  data: BlueprintConditionValue
): BlueprintConditionValue | undefined {
  if (!condition) return;

  const clonedPreviousState = _.cloneDeep(condition);

  const newConditionBlueprint = _.set(clonedPreviousState, path, data);
  return newConditionBlueprint;
}

export function addConditionToBlueprint(
  condition: BlueprintConditionValue | undefined,
  path: string
): BlueprintConditionValue | undefined {
  if (!condition) return;

  const clonedPreviousState = _.cloneDeep(condition) as BlueprintConditionsValue;
  const isRootCondition = path === '';

  if (isRootCondition) {
    clonedPreviousState.conditions = [...clonedPreviousState.conditions, defaultSingleConditionBlueprintValue()];

    return clonedPreviousState;
  } else {
    const targetCondition = _.get(clonedPreviousState, path);

    const newConditionBlueprint = _.set(clonedPreviousState, path, {
      ...targetCondition,
      conditions: [...targetCondition.conditions, defaultSingleConditionBlueprintValue()],
    });
    return newConditionBlueprint;
  }
}

export function addNestedConditionToBlueprint(
  condition: BlueprintConditionValue | undefined,
  path: string
): BlueprintConditionValue | undefined {
  if (!condition) return;

  const clonedPreviousState = _.cloneDeep(condition);

  const { conditionPath, index } = extractIndexFromConditionPath(path);

  if (typeof index === 'number' && conditionPath) {
    // inserts a default single condition after the target condition
    _.invoke(clonedPreviousState, `${conditionPath}.splice`, index + 1, 0, {
      booleanOperator: BooleanOperator.and,
      conditions: [defaultSingleConditionBlueprintValue()],
    });

    return clonedPreviousState;
  }

  return clonedPreviousState;
}

export function removeConditionFromBlueprint(
  condition: BlueprintConditionValue | undefined,
  path: string
): BlueprintConditionValue | undefined {
  if (!condition) return;

  const clonedPreviousState = _.cloneDeep(condition);
  const { conditionPath, index } = extractIndexFromConditionPath(path);

  if (typeof index === 'number' && conditionPath) {
    // Remove the condition at the target index from the conditions array
    _.invoke(clonedPreviousState, `${conditionPath}.splice`, index, 1);

    return clonedPreviousState;
  }

  return clonedPreviousState;
}

function extractIndexFromConditionPath(path: string): Record<string, string | number | undefined> {
  // Gets the position of the condition altered in the conditions array
  // Returns the value still wrapped in brackets (e.g: [1])
  const targetConditionIndex = path.match(/\[[0-9]+\]$/g)?.[0];

  // Gets the path for the condition array
  const conditionPath = targetConditionIndex ? path.slice(0, path.length - targetConditionIndex.length) : undefined;

  // Removes the brackets from the targetConditionIndex
  const index = targetConditionIndex ? Number(targetConditionIndex.replace(/\[|\]/g, '')) : undefined;

  return { conditionPath, index };
}

export function buildNodeIdList(
  conditionType: ConditionBlueprintType,
  questionnaireNodeIds: QuestionnaireNodeIds,
  criteriaMetadata: CriteriaMetadata
): NodeDetail[] {
  const { isNumberCriteria, isTextCriteria, isDateCriteria, isReflexiveCriteria, isSelectOptionCriteria } =
    criteriaMetadata[conditionType];

  const nodeDetails = [
    ...questionnaireNodeIds.inQuestionnaire.allLeafs,
    ...questionnaireNodeIds.notInQuestionnaire.allLeafs,
  ];

  const detailsFilteredByType = nodeDetails.filter((nodeDetail) => {
    const hasNoTypeFilter = !isNumberCriteria && !isReflexiveCriteria && !isSelectOptionCriteria && !isDateCriteria;

    if (hasNoTypeFilter) {
      return true;
    }

    return (
      (isNumberCriteria && nodeWithNumberValues(nodeDetail)) ||
      (isTextCriteria && nodeWithTextValues(nodeDetail)) ||
      (isSelectOptionCriteria && nodeWithOptions(nodeDetail, questionnaireNodeIds.selectOptionsByNodeId)) ||
      (isReflexiveCriteria && nodeWithYesNoOptions(nodeDetail, questionnaireNodeIds.selectOptionsByNodeId)) ||
      (isDateCriteria && nodeWithDateValues(nodeDetail))
    );
  });

  return detailsFilteredByType;
}

export function getTargetNodeIdsFromCondition(conditionValues: BlueprintSingleConditionValue): string[] {
  switch (conditionValues.type) {
    case ConditionBlueprintType.reflexive:
    case ConditionBlueprintType.characterCountInBetween:
    case ConditionBlueprintType.equality:
    case ConditionBlueprintType.emptiness:
    case ConditionBlueprintType.matches:
    case ConditionBlueprintType.numberComparison:
    case ConditionBlueprintType.lastIncidentDate:
    case ConditionBlueprintType.countEqual:
    case ConditionBlueprintType.percentOf:
      return [conditionValues.targetNodeId];

    case ConditionBlueprintType.ageRange:
      return [conditionValues.targetBirthdateNodeId];

    case ConditionBlueprintType.dateComparison:
      return [conditionValues.startDateNodeId, conditionValues.endDateNodeId];

    case ConditionBlueprintType.mathOperator:
      return conditionValues.nodeIds;

    case ConditionBlueprintType.bmiRange:
      return [conditionValues.heightNodeId, conditionValues.weightNodeId];

    case ConditionBlueprintType.instancesCount:
    case ConditionBlueprintType.jointProductAgeRange:
    case ConditionBlueprintType.engineCondition:
      return [];
  }
}
