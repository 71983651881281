import Box from '@material-ui/core/Box';
import React, { useState } from 'react';

import { StyledActionGhostIconButton } from '../../../../Components/AssistedApplication/AssistedApplicationView/Styles';
import { Icon, IconMap } from '../../../../Components/Icons';
import { EmptyPdfDownloadHandler } from './EmptyPdfDownloadHandler/EmptyPdfDownloadHandler';

type DownloadPdfButtonProps = {
  mb?: number;
  iconName: keyof typeof IconMap;
  children: React.ReactNode;
  fullWidth?: boolean;
};

export function DownloadPdfButton(props: DownloadPdfButtonProps): React.ReactElement {
  const { mb, iconName, children, fullWidth } = props;

  const [pdfUrl, setPdfUrl] = useState('');
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);

  const onSuccessDownloadPdf = (url: string): void => {
    setPdfUrl(url);
    setIsLoadingPdf(false);
  };

  const onClickDownloadPdf = (): void => {
    if (pdfUrl) {
      window.open(pdfUrl);
    } else {
      setIsLoadingPdf(true);
    }
  };

  return (
    <Box mb={mb}>
      {isLoadingPdf && (
        <EmptyPdfDownloadHandler onSuccess={onSuccessDownloadPdf} onFailure={() => setIsLoadingPdf(false)} />
      )}
      <StyledActionGhostIconButton
        onClick={onClickDownloadPdf}
        startIcon={<Icon name={iconName} variant='primary' fill='none' />}
        isLoading={isLoadingPdf}
        $fullWidth={fullWidth}
        size='x-small'
        removeIconBorder
      >
        {children}
      </StyledActionGhostIconButton>
    </Box>
  );
}
