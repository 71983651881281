import React, { SVGProps } from 'react';

export function EyeIcon(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M8 3.87998C9.82102 3.87998 11.5177 4.67629 12.8784 5.6122C14.1996 6.52098 15.1699 7.53799 15.5825 8.00226C15.1699 8.46654 14.1996 9.48355 12.8784 10.3923C11.5177 11.3282 9.82102 12.1245 8 12.1245C6.17898 12.1245 4.48228 11.3282 3.12164 10.3923C1.80043 9.48355 0.830087 8.46654 0.417499 8.00226C0.830087 7.53799 1.80043 6.52098 3.12164 5.6122C4.48228 4.67629 6.17898 3.87998 8 3.87998Z'
        stroke='#757575'
        strokeWidth='0.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.11985 8.781C6.01758 8.5341 5.96494 8.26946 5.96494 8.00221C5.96494 7.73496 6.01758 7.47033 6.11985 7.22342C6.22212 6.97652 6.37203 6.75217 6.561 6.5632C6.74998 6.37422 6.97432 6.22432 7.22123 6.12205C7.46813 6.01978 7.73277 5.96714 8.00002 5.96714C8.26727 5.96714 8.5319 6.01978 8.77881 6.12205C9.02571 6.22432 9.25006 6.37422 9.43903 6.5632C9.62801 6.75217 9.77791 6.97652 9.88018 7.22342C9.98245 7.47033 10.0351 7.73496 10.0351 8.00221C10.0351 8.26946 9.98245 8.5341 9.88018 8.781C9.77791 9.02791 9.62801 9.25225 9.43903 9.44123C9.25006 9.6302 9.02571 9.78011 8.77881 9.88238C8.5319 9.98465 8.26727 10.0373 8.00002 10.0373C7.73277 10.0373 7.46813 9.98465 7.22123 9.88238C6.97432 9.78011 6.74998 9.6302 6.561 9.44123C6.37203 9.25225 6.22212 9.02791 6.11985 8.781Z'
        stroke='#757575'
        strokeWidth='0.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
