import Box from '@material-ui/core/Box';
import MuiButton, { ButtonProps } from '@material-ui/core/Button';
import React from 'react';
import styled, { css } from 'styled-components';

import { Icon } from '../../Components/Icons';
import Typography from '../../Components/Typography';
import { FullColorShade } from '../../Styles/Types';

type StyledMuiButtonProps = { maxWidth?: number };

const StyledMuiButton = styled(MuiButton).withConfig({
  shouldForwardProp: (prop) => !['maxWidth'].includes(prop),
})<StyledMuiButtonProps>`
  && {
    padding: 8px 12px;
    border-radius: 3px;
    background-color: ${(props) => props.theme.colors.grey[0]};
    border: 1px solid ${(props) => props.theme.colors.grey[50]};
    ${(props) =>
      props.maxWidth &&
      css`
        max-width: ${props.maxWidth}px;
      `}

    :hover {
      background-color: ${(props) => props.theme.colors.grey[0]};
      border-color: ${(props) => props.theme.colors.grey[90]};
    }
    :active {
      border: 2px solid ${(props) => props.theme.colors.primary.default};
      background-color: ${(props) => props.theme.colors.grey[20]};
    }
    :focus:not(:active) {
      outline: 0;
      border: 2px solid ${(props) => props.theme.colors.primary.default} !important;
    }
  }
`;

type DropdownButtonProps = Omit<ButtonProps, 'variant'> &
  StyledMuiButtonProps & {
    grey?: keyof FullColorShade;
  };

export function DropdownButton({ grey, ...props }: DropdownButtonProps): React.ReactElement | null {
  return (
    <StyledMuiButton {...props} endIcon={<Icon name='filledCaret' variant='dark' />}>
      <Box textOverflow='ellipsis' overflow='hidden' width='100%' textAlign='left' whiteSpace='nowrap' mr={1}>
        <Typography grey={grey || 70} variant='button'>
          {props.children}
        </Typography>
      </Box>
    </StyledMuiButton>
  );
}
