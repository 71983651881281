import Box from '@material-ui/core/Box';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ApplicationMode, Permission, SignatureType } from '@breathelife/types';

import { ApplicationModeBadge } from '../../../Components/Badge/ApplicationModeBadge';
import { Button } from '../../../Components/Button/Button';
import { CloseButton } from '../../../Components/Button/CloseButton';
import { SubmitButton } from '../../../Components/Button/SubmitButton';
import { Icon } from '../../../Components/Icons';
import { RestrictedToPermission } from '../../../Components/Restricted/RestrictedToPermission';
import { useCarrierContext, useSelector } from '../../../Hooks';
import { IdentityVerificationPopper } from '../Popper/IdentityVerification/IdentityVerificationPopper';
import { LicenseVerificationPopper } from '../Popper/LicenseVerificationPopper/LicenseVerificationPopper';
import { DocumentsDrawerButton } from './DocumentsDrawerButton';
import { HeaderTabBar } from './HeaderTabBar';
import { HeaderButtonContainer, HeaderTitle, HeaderTitleContainer, HeaderWrapper } from './Styles';

type AssistedApplicationHeaderProps = {
  applicantName: string;
  applicationSignatureType?: SignatureType;
  close: () => void;
  isMissingRequiredFiles: boolean;
  isPreview: boolean;
  isSubmitButtonDisabled: boolean;
  isSubmitButtonVisible: boolean;
  applicationMode: ApplicationMode;
  onSubmitApplication: () => void;
  onOpenESignatureDetails: () => void;
  onOpenFileAttachmentModal: () => void;
  onOpenDocumentsDrawer: () => void;
  triggerIdentityVerification: () => void;
  onOpenInstantIdReportDrawer: () => void;
  onOpenSubmitPaperAppModal: () => void;
};

enum SubmissionButtonFlow {
  default = 'default',
  esignature = 'esignature',
  paperApp = 'paperApp',
  esignatureSubmitted = 'esignatureSubmitted',
}

function AssistedApplicationHeader(props: AssistedApplicationHeaderProps): React.ReactElement {
  const { features } = useCarrierContext();
  const { t } = useTranslation();

  const {
    applicantName,
    applicationSignatureType,
    close,
    isMissingRequiredFiles,
    isPreview,
    isSubmitButtonDisabled,
    isSubmitButtonVisible,
    applicationMode,
    onSubmitApplication,
    onOpenESignatureDetails,
    onOpenFileAttachmentModal,
    onOpenDocumentsDrawer,
    triggerIdentityVerification,
    onOpenInstantIdReportDrawer,
    onOpenSubmitPaperAppModal,
  } = props;

  const submissionButtonStatus = useMemo<SubmissionButtonFlow | undefined>(() => {
    if (!isSubmitButtonVisible) {
      return applicationSignatureType === SignatureType.eSignature
        ? SubmissionButtonFlow.esignatureSubmitted
        : undefined;
    }
    if (applicationMode === ApplicationMode.paper) {
      return !isPreview ? SubmissionButtonFlow.paperApp : undefined;
    }
    if (
      applicationSignatureType === SignatureType.cryptoSignature ||
      applicationSignatureType === SignatureType.externalSignature ||
      isPreview
    ) {
      return SubmissionButtonFlow.default;
    }
    if (applicationSignatureType === SignatureType.eSignature) {
      return SubmissionButtonFlow.esignature;
    }
    return undefined;
  }, [applicationMode, applicationSignatureType, isPreview, isSubmitButtonVisible]);

  const isApplicationSubmitting = useSelector((state) => state.leadPlatform.submission.isSubmittingApplication);

  const DOCUMENTS_DRAWER_ENABLED = features.assistedApplication?.documentsDrawer?.enabled === true;
  const MULTI_INSURED_ENABLED = features.assistedApplication?.multiInsured?.enabled ?? false;
  const AGENT_LICENSE_ENABLED = features.agentLicenseValidation?.enabled ?? false;

  const title = `${t('assistedApplication.title')}${applicantName ? ` - ${applicantName}` : ''}`;

  return (
    <HeaderWrapper>
      <HeaderTitleContainer>
        <HeaderTitle variant='h2'>{title}</HeaderTitle>
        {applicationMode === ApplicationMode.paper && (
          <Box display='inline-block' ml='10px'>
            <ApplicationModeBadge mode={applicationMode} />
          </Box>
        )}
      </HeaderTitleContainer>
      {MULTI_INSURED_ENABLED && <HeaderTabBar />}
      <HeaderButtonContainer>
        <IdentityVerificationPopper
          triggerIdentityVerification={triggerIdentityVerification}
          onOpenInstantIdReportDrawer={onOpenInstantIdReportDrawer}
        />
        {AGENT_LICENSE_ENABLED && <LicenseVerificationPopper />}
        {applicationMode === ApplicationMode.digital &&
          (DOCUMENTS_DRAWER_ENABLED ? (
            <DocumentsDrawerButton
              isMissingRequiredFiles={isMissingRequiredFiles}
              onOpenDocumentsDrawer={onOpenDocumentsDrawer}
            />
          ) : (
            <RestrictedToPermission
              permission={[
                Permission.ApplicationFileCreate,
                Permission.ApplicationFileRead,
                Permission.ApplicationFileRemove,
              ]}
            >
              <Box>
                <Button
                  color='secondary'
                  variant='outlined'
                  onClick={onOpenFileAttachmentModal}
                  startIcon={<Icon name='files' />}
                >
                  {t('assistedApplication.files')}
                </Button>
              </Box>
            </RestrictedToPermission>
          ))}
        {submissionButtonStatus && (
          <Box ml={2}>
            {submissionButtonStatus === SubmissionButtonFlow.default && (
              <SubmitButton disabled={isSubmitButtonDisabled} onClick={onSubmitApplication}>
                {t('assistedApplication.submitApplication')}
              </SubmitButton>
            )}
            {submissionButtonStatus === SubmissionButtonFlow.esignature && (
              <RestrictedToPermission permission={[Permission.ApplicationFileRead]}>
                <SubmitButton
                  data-testid='send-to-signature-button'
                  disabled={isSubmitButtonDisabled}
                  onClick={onOpenESignatureDetails}
                >
                  {t('assistedApplication.sendToSignature')}
                </SubmitButton>
              </RestrictedToPermission>
            )}
            {submissionButtonStatus === SubmissionButtonFlow.paperApp && (
              <SubmitButton
                isLoading={isApplicationSubmitting}
                disabled={isSubmitButtonDisabled}
                onClick={onOpenSubmitPaperAppModal}
              >
                {t('assistedApplication.submit')}
              </SubmitButton>
            )}
            {submissionButtonStatus === SubmissionButtonFlow.esignatureSubmitted && (
              <Button
                color='secondary'
                variant='outlined'
                disabled={isSubmitButtonDisabled}
                onClick={onOpenESignatureDetails}
              >
                {t('assistedApplication.signaturesDetails')}
              </Button>
            )}
          </Box>
        )}
        <Box ml={5}>
          <CloseButton onClick={close} />
        </Box>
      </HeaderButtonContainer>
    </HeaderWrapper>
  );
}

export const MemoizedAssistedApplicationHeader = React.memo(AssistedApplicationHeader);
