import Box from '@material-ui/core/Box';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Permission, User } from '@breathelife/types';

import { DropdownButton } from '../../../Components/Button/DropdownButton';
import { Select } from '../../../Components/Select/Select';
import { SearchTextField } from '../../../Components/TextInputs';
import Typography from '../../../Components/Typography';
import { userHasPermission } from '../../../Helpers/user';
import { useCarrierContext, useSelector } from '../../../Hooks';
import { ModalType } from '../../../Models/Layout';
import { DefaultLeadsListFilterIds } from '../../../Pages/Home/Modals/UserListModal/UserListModal';
import { ModalPayload } from '../../../Redux/Layout/LayoutSlice';

const FilterContainer = styled.div`
  display: flex;
`;

const StyledDropdownButton = styled(DropdownButton)`
  &&&& {
    padding: 0px 12px !important;
    width: 160px;
    margin-left: 16px;
    border-radius: 4px;
  }
`;

export type Filters = {
  status?: string;
  selectedUserId?: string;
};

type Props = {
  onFiltersChanged: (filters?: Filters) => void;
  onSearchChange: (searchString: string) => void;
  setModalState: (state: ModalPayload) => void;
  selectedUserId: string;
  searchTerm?: string;
};

export function ListFilter(props: Props): React.ReactElement {
  const { onFiltersChanged, onSearchChange, setModalState, selectedUserId, searchTerm } = props;
  const { t } = useTranslation();
  const { leadStatuses } = useCarrierContext();
  const [filterValue, setFilterValue] = useState('');
  const currentUser = useSelector((store) => store.leadPlatform.authentication.user) as User;
  const selectedUser = useSelector((store) =>
    store.leadPlatform.userManagement.users.find((user) => user.auth0Id === selectedUserId)
  );

  const leadsListFilterByUser = useMemo(() => {
    let title;
    if (!selectedUser) {
      title =
        selectedUserId === DefaultLeadsListFilterIds.All
          ? t('modals.leadsListFilters.all')
          : t('modals.leadsListFilters.unassigned');
    } else if (selectedUser.auth0Id === currentUser.auth0Id) {
      title = t('modals.leadsListFilters.me');
    } else {
      title = `${selectedUser.firstName} ${selectedUser.lastName}`;
    }
    return title;
  }, [selectedUser, selectedUserId, currentUser, t]);

  const statusOptions = Object.entries(leadStatuses).map((status) => {
    const [key, value] = status;
    return {
      value: key,
      // They are provided by a carrier with a value, then saved to the store
      // Typescript thinks that the specific status could be undefined
      label: t(`leadStatuses.${value!.label}`),
    };
  });

  const emptyOption = [{ value: '', label: t('leadsListTable.status') }];
  const options = emptyOption.concat(statusOptions);

  const onStatusFilterChange = useCallback(
    (value: string) => {
      setFilterValue(value);
      const filterValue = { status: !_.isEmpty(value) ? value : undefined };
      onFiltersChanged(filterValue);
    },
    [onFiltersChanged]
  );

  const onLeadsListFilterClick = useCallback(() => {
    setModalState({ modalState: { isOpen: true, type: ModalType.leadsListFilter } });
  }, [setModalState]);

  const canSeeOtherLeads = userHasPermission(currentUser?.permissions, [
    Permission.LeadReadGroup,
    Permission.LeadReadAll,
  ]);

  return (
    <FilterContainer>
      <SearchTextField onSearchChange={onSearchChange} defaultValue={searchTerm} />
      <Box ml={2}>
        <Select id='status-select' value={filterValue} options={options} onChange={onStatusFilterChange} displayEmpty />
      </Box>
      {canSeeOtherLeads && (
        <StyledDropdownButton id='assigned-to-select' onClick={onLeadsListFilterClick}>
          <Typography variant='body1'>{leadsListFilterByUser}</Typography>
        </StyledDropdownButton>
      )}
    </FilterContainer>
  );
}
