import _ from 'lodash';

import { User } from '@breathelife/types';

import { LeadPlatformStore } from '../../Redux/types';

export const getFirstSelectedUser = (state: LeadPlatformStore): User | undefined => {
  return _.find(
    state.leadPlatform.userManagement.users,
    (user) => user.auth0Id === state.leadPlatform.userManagement.selectedUserIds[0]
  );
};
