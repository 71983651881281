const _ = require('lodash');

if (!global.breatheLifeConfig && process.env.ENV_ID !== 'test') {
  console.error('Configuration error: configuration script is not attached or loaded');
}

const config = {
  has: (path) => typeof _.get(global.breatheLifeConfig, path) !== 'undefined',
  get: (path) => _.get(global.breatheLifeConfig, path),
};

module.exports = { config };
