import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';

import { FieldGenerator, RenderContext, StyleVariant } from '@breathelife/field-generator';
import {
  getRepeatedQuestionIndex,
  isRenderingRepeatedQuestion,
  RenderingQuestion,
} from '@breathelife/questionnaire-engine';

import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import { useCarrierContext, useLocale } from '../../../../Hooks';
import { useAssistedApplicationContext } from '../../../../Hooks/useAssistedApplicationContext';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';

type FieldGroupCardModalProps = {
  isOpen: boolean;
  onCloseModal: () => void;
  secondaryAction?: React.ReactNode;
  question: RenderingQuestion;
};

const modalLayoutBodyStyling = css`
  overflow-y: auto;
  max-height: 500px;
`;

export function FieldGroupCardModal(props: FieldGroupCardModalProps): React.ReactElement {
  const { isOpen, onCloseModal, secondaryAction, question } = props;
  const { iconMap } = useCarrierContext();
  const locale = useLocale();
  const { t } = useTranslation();
  const { onAnswerChange, onAnswerComplete } = useAssistedApplicationContext();

  /**
   * We already display the question's title as the modal's title, hence we don't want to
   * display it again in the modal's content and can remove it
   */
  const fieldGeneratorProps = {
    context: RenderContext.web,
    styleVariant: StyleVariant.pro,
    iconMap,
    locale,
    question: _.omit(question, 'title'),
    onAnswerChange,
    onAnswerComplete,
  };

  /**
   * /!\ IMPORTANT /!\
   * In order to get the repeatedInstanceIdentifierContext to account for repeated questions and multi-insured,
   * we want to handle the UI logic here instead of letting FieldGenerator do it.
   * The only issue with that is that we lose the repeatedQuestionIndex in the answer
   * complete callback added by FieldGenerator, hence the repeated question flag to manually add it.
   */

  fieldGeneratorProps.onAnswerChange = (nodeId, answer, effects, _, triggerStepNavigation) =>
    onAnswerChange(
      nodeId,
      answer,
      effects,
      question.metadata?.repeatedInstanceIdentifierContext,
      triggerStepNavigation
    );
  if (isRenderingRepeatedQuestion(question)) {
    const repeatedQuestionIndex = getRepeatedQuestionIndex(question);

    fieldGeneratorProps.question = _.omit(fieldGeneratorProps.question, 'options.repeatable');
    fieldGeneratorProps.onAnswerComplete = (fieldId, answer, previousAnswer) =>
      onAnswerComplete(fieldId, answer, previousAnswer, repeatedQuestionIndex);
  }

  return (
    <ModalLayout
      maxWidth='sm'
      title={question.title}
      isOpen={isOpen}
      closeModal={onCloseModal}
      secondaryAction={secondaryAction}
      submitButton={
        <SubmitButton onClick={onCloseModal} data-testid={`${question.id}-save`}>
          {t('cta.save')}
        </SubmitButton>
      }
      bodyStyling={modalLayoutBodyStyling}
    >
      <FieldGenerator {...fieldGeneratorProps} />
    </ModalLayout>
  );
}
