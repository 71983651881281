import React from 'react';

import { ESignSigner2FAInfo } from '@breathelife/types';

import { Application } from '../Models/Application';

export type ESignatureContextType = {
  application: Application | undefined;
  signers: ESignSigner2FAInfo[];
  setSigners: React.Dispatch<React.SetStateAction<ESignSigner2FAInfo[]>>;
};

export const ESignatureContext = React.createContext<ESignatureContextType>({
  signers: [],
  setSigners: () => {},
  application: undefined,
});
