import Box, { BoxProps } from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';

export function ApplicationDetailsWidgetsStickyWrapper(props: BoxProps): React.ReactElement {
  const theme = useTheme();

  const topPadding = (props.pt || props.p) ?? 0;
  const topMargin = (props.mt || props.m) ?? 0;
  const topStickyPosition = theme.spacing(topPadding + topMargin);

  return (
    <Box {...props}>
      <Box position='sticky' top={topStickyPosition}>
        {props.children}
      </Box>
    </Box>
  );
}
