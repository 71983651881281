import { useTranslation } from 'react-i18next';

import { IconName, Permission } from '@breathelife/types';

import { PageLayoutTab } from '../Layouts/PageLayout/PageLayout';
import Urls from '../Navigation/Urls';
import { useCarrierContext } from './useCarrierContext';

export function useAdminTabs(): PageLayoutTab[] {
  const { features } = useCarrierContext();
  const { t } = useTranslation();

  const tabs: PageLayoutTab[] = [
    {
      label: t('adminTabs.platform'),
      ['data-testid']: 'admin-tab-platform',
      iconName: IconName.producerCog,
      children: [
        {
          to: Urls.settingsManagement,
          label: t('adminTabs.settings'),
          ['data-testid']: 'admin-tab-settings',
          permissions: [Permission.SettingsUpdate],
        },
        {
          to: Urls.themeManagement,
          label: t('adminTabs.theme'),
          ['data-testid']: 'admin-tab-theme',
          permissions: [Permission.ThemeUpdate],
        },
        {
          to: Urls.userManagement,
          label: t('adminTabs.users'),
          ['data-testid']: 'admin-tab-users',
          permissions: [Permission.UserCreate],
        },
        {
          to: Urls.firmManagement,
          label: t('adminTabs.firm'),
          ['data-testid']: 'admin-tab-firm',
          permissions: [Permission.InsuranceFirmUpdate],
        },
      ],
    },
    {
      label: t('adminTabs.product'),
      ['data-testid']: 'admin-tab-products',
      iconName: IconName.producerUmbrella,
      children: [
        {
          to: Urls.productManagement,
          label: t('adminTabs.product'),
          ['data-testid']: 'admin-tab-product',
          permissions: [Permission.InsuranceProductUpdate],
        },
      ],
    },
    {
      label: 'Audit',
      ['data-testid']: 'admin-tab-audit',
      iconName: IconName.producerCogAndSearch,
      children: [
        {
          to: Urls.auditLogs,
          label: t('adminTabs.auditLogs'),
          ['data-testid']: 'admin-tab-audit-logs',
          permissions: [Permission.SettingsUpdate],
        },
      ],
    },
  ];

  if (features.questionnaireEditor.enabled) {
    // insert into tabs array at index 1 without removing any other items
    tabs[1]?.children?.splice(1, 0, {
      to: Urls.questionnaireManagement,
      label: t('adminTabs.questionnaire'),
      ['data-testid']: 'admin-tab-questionnaire',
      permissions: [Permission.QuestionnaireUpdate],
    });
  }

  if (features.salesDecisionRules?.enabled) {
    // insert into tabs array at index 2 without removing any other items
    tabs[1]?.children?.splice(2, 0, {
      to: Urls.salesDecisionRulesManagement,
      label: t('adminTabs.salesDecisionRules'),
      ['data-testid']: 'admin-tab-sales-decision-rules',
      permissions: [Permission.SalesDecisionRuleCreate],
    });
  }

  if (features.documentTemplate?.enabled) {
    // insert into tabs array at index 3 without removing any other items
    tabs[1]?.children?.splice(3, 0, {
      to: Urls.documentsManagement,
      label: t('pageTitles.documents'),
      ['data-testid']: 'admin-tab-document-templates',
      permissions: [
        Permission.FileTemplateCreate,
        Permission.FileTemplateRead,
        Permission.FileTemplateRemove,
        Permission.FileTemplateUpdate,
      ],
    });
  }

  if (features.questionnaireBuilder?.enabled) {
    tabs[1]?.children?.splice(4, 0, {
      to: Urls.questionnaireBuilder,
      label: t('adminTabs.questionnaireBuilder'),
      ['data-testid']: 'admin-tab-questionnaire-builder',
      permissions: [Permission.QuestionnaireUpdate],
    });
  }

  if (features.thirdPartyIntegrations?.enabled) {
    tabs[0]?.children?.splice(3, 0, {
      to: Urls.thirdPartyIntegrations,
      label: t('adminTabs.thirdPartyIntegrations'),
      ['data-testid']: 'admin-tab-third-party-integrations',
      permissions: [
        Permission.ThirdPartyIntegrationConfigurationEdit,
        Permission.ThirdPartyIntegrationConfigurationRead,
      ],
    });
  }

  if (features.backOfficeTools?.enabled) {
    tabs[0]?.children?.splice(3, 0, {
      to: Urls.backOfficeApplications,
      label: t('adminTabs.backOffice'),
      ['data-testid']: 'admin-tab-back-office',
      permissions: [Permission.BackOfficeApplicationsRead],
    });
  }

  if (features.entityMappings?.enabled) {
    tabs[1]?.children?.push({
      to: Urls.entityMappingManagement,
      label: t('adminTabs.entityMappingManagement'),
      ['data-testid']: 'admin-tab-entity-mapping-management',
      permissions: [Permission.EntityMappingUpdate],
    });
  }

  return tabs;
}
