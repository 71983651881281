import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import config from '@breathelife/config/frontend';
import { InsuranceEntities } from '@breathelife/insurance-entities';
import { deserializeNodeIdToAnswerPathMap } from '@breathelife/questionnaire-engine';
import { ESignSigner2FAInfo, User, ProposedInsuredEntity, PayerEntity, SignatureType } from '@breathelife/types';

import { CarrierContext } from '../../Context/CarrierContext';
import { ESignatureContext } from '../../Context/ESignatureContext';
import { insuredEntitiesToESignSigner } from '../../Helpers/eSignSigners/insuredEntitiesToESignSigner';
import { useDispatch, useSelector } from '../../Hooks';
import { useGetESignCeremonyQuery } from '../../ReactQuery/ESignCeremony/eSignCeremony.queries';
import { useFetchQuestionnaireQuery } from '../../ReactQuery/Questionnaire/questionnaire.queries';
import { notificationSlice } from '../../Redux/Notification/NotificationSlice';

export function useSigners(): [ESignSigner2FAInfo[], React.Dispatch<React.SetStateAction<ESignSigner2FAInfo[]>>] {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { signers, setSigners, application } = useContext(ESignatureContext);
  const eSignCeremony = useGetESignCeremonyQuery(
    application?.id,
    config.get<SignatureType | undefined>('features.signatureType.leadPlatform')
  ).data;

  const { features } = useContext(CarrierContext);

  const user = useSelector<User | undefined>((store) => store.leadPlatform.authentication.user);
  const { data: questionnaireData } = useFetchQuestionnaireQuery(application?.id, {
    onError: () =>
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToFetchQuestionnaire'),
        })
      ),
  });
  const entitySelectors = questionnaireData?.entitySelectors;
  const serializedNodeIdToAnswerPathMap = questionnaireData?.nodeIdToAnswerPath;

  useEffect(() => {
    if (!entitySelectors || !serializedNodeIdToAnswerPathMap || !application || !user || !eSignCeremony) return;

    const { eSignSigners } = eSignCeremony;

    // TODO: Refactor - currently typescript throws typemismatch error.
    const signersNew = eSignSigners?.map((signer) => ({ ...signer, documentTypes: [] } as ESignSigner2FAInfo));

    // Set the signers to the persisted values. If they don't exist, fetch from the insurance-entities lib.
    if (signersNew) {
      setSigners(signersNew);
      return;
    }

    const nodeIdToAnswerPathMap = deserializeNodeIdToAnswerPathMap(serializedNodeIdToAnswerPathMap);

    // Fetch insured, owner, payer, advisor using insurance-entities lib
    const insuranceEntities = new InsuranceEntities(nodeIdToAnswerPathMap, entitySelectors);

    // @featureFlag multiInsured start
    // TODO we need to pass the correct collection instance identifiers here
    let proposedInsured: ProposedInsuredEntity | null = null;
    let payer: PayerEntity | null = null;
    if (!features.assistedApplication?.multiInsured?.enabled) {
      proposedInsured = insuranceEntities.getProposedInsured(application.answers, 0);
      payer = insuranceEntities.getPayer(application.answers, 0);
    }
    // @featureFlag multiInsured end

    const owner = insuranceEntities.getOwner(application.answers);
    const advisor = insuranceEntities.getAdvisor(user);

    if (!proposedInsured || !advisor) {
      throw new Error('Fetched proposed insured and advisor entities cannot be null');
    }

    const signers: ESignSigner2FAInfo[] = insuredEntitiesToESignSigner(proposedInsured, owner, payer, advisor);

    setSigners(signers);
  }, [application, entitySelectors, serializedNodeIdToAnswerPathMap, user]);

  return [signers, setSigners];
}
