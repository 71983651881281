import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Popper } from '@breathelife/ui-components';

import { StyledLoader } from '../../../../Components/Button/Styles';
import { Icon } from '../../../../Components/Icons';
import Typography from '../../../../Components/Typography';
import { useSelector, useCarrierContext } from '../../../../Hooks';
import {
  IconContainer,
  IdentityVerificationButton,
  PopperBox,
  TextContainer,
  IdentityVerificationRetryButton,
  StyledLink,
} from './Styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 1px 4px rgb(0 0 0 / 25%)',
      borderRadius: '8px',
      marginTop: '15px',
    },
  })
);

const PopperWithZIndex = styled(Popper)`
  z-index: 1101;
`;

type PopperContainerProps = {
  triggerIdentityVerification: () => void;
  onOpenInstantIdReportDrawer: () => void;
};

export function IdentityVerificationPopper(props: PopperContainerProps): React.ReactElement {
  const { identityVerificationStatus, isLoading } = useSelector((store) => store.leadPlatform.identityVerification);
  const { features } = useCarrierContext();
  const { triggerIdentityVerification, onOpenInstantIdReportDrawer } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const classes = useStyles();

  const onOpenIdentityVerificationPopper = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const isOpen = !!anchorEl;
  const popperId = isOpen ? 'identityverification-popper' : undefined;

  const handleClickAway = () => {
    setAnchorEl(null);
  };
  if (!features.enableInstantIdRequest?.enabled) return <></>;
  return (
    <React.Fragment>
      <Box mr={2}>
        <Button
          color='secondary'
          variant='outlined'
          onClick={onOpenIdentityVerificationPopper}
          startIcon={<Icon name='idCheck' />}
        >
          {t('assistedApplication.identityVerification')}
        </Button>
      </Box>
      <Box>
        <IconContainer>
          {identityVerificationStatus === 'success' && (
            <Icon name='circledCheckMark' variant='green' inverted stroke='none' />
          )}
          {identityVerificationStatus === 'riskDetected' && (
            <Icon name='circleFilledWarning' fill='#D9A810' variant='orange' inverted stroke='none' />
          )}
          {identityVerificationStatus === 'retry' && (
            <Icon name='circleFilledWarning' fill='#FF0000' variant='red' inverted stroke='none' />
          )}
        </IconContainer>
      </Box>

      {anchorEl && (
        <PopperWithZIndex id={popperId} isOpen={isOpen} anchorEl={anchorEl} className={classes.paper} placement='auto'>
          <ClickAwayListener onClickAway={handleClickAway}>
            <PopperBox>
              <TextContainer>
                {identityVerificationStatus === '' && (
                  <Typography variant='body3' grey={90}>
                    {t('popper.identityVerification.responses.identityVerificationCheck.body')}
                  </Typography>
                )}
                {identityVerificationStatus === 'success' && (
                  <Typography variant='body3' grey={90}>
                    {t('popper.identityVerification.responses.identityVerificationSuccess.body')}
                  </Typography>
                )}
                {identityVerificationStatus === 'retry' && (
                  <Typography variant='body3' grey={90}>
                    {t('popper.identityVerification.responses.identityVerificationTimeoutRetry.body')}
                  </Typography>
                )}
                {identityVerificationStatus === 'riskDetected' && (
                  <Box>
                    <Box>
                      <Typography variant='body3' grey={90}>
                        {t('popper.identityVerification.responses.identityVerificationFailedRetry.body')}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant='body3' grey={90}>
                        <StyledLink onClick={onOpenInstantIdReportDrawer}>
                          {t('popper.identityVerification.responses.identityVerificationFailedRetry.reportLink')}
                        </StyledLink>
                      </Typography>
                    </Box>
                  </Box>
                )}
              </TextContainer>

              {identityVerificationStatus === '' && (
                <IdentityVerificationButton color='primary' variant='contained' onClick={triggerIdentityVerification}>
                  {isLoading && <StyledLoader size='20px' color='inherit' />}
                  {!isLoading &&
                    t('popper.identityVerification.responses.identityVerificationCheck.checkWithInstantIdButton')}
                </IdentityVerificationButton>
              )}
              {identityVerificationStatus === 'retry' && (
                <IdentityVerificationRetryButton
                  color='primary'
                  variant='outlined'
                  onClick={triggerIdentityVerification}
                >
                  {t('popper.identityVerification.responses.identityVerificationTimeoutRetry.checkWithInstantIdButton')}
                </IdentityVerificationRetryButton>
              )}
              {identityVerificationStatus === 'riskDetected' && (
                <IdentityVerificationRetryButton
                  color='primary'
                  variant='outlined'
                  onClick={triggerIdentityVerification}
                >
                  {t('popper.identityVerification.responses.identityVerificationFailedRetry.checkWithInstantIdButton')}
                </IdentityVerificationRetryButton>
              )}
            </PopperBox>
          </ClickAwayListener>
        </PopperWithZIndex>
      )}
    </React.Fragment>
  );
}
