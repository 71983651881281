import { push } from 'connected-react-router';
import React, { useCallback } from 'react';

import { User } from '@breathelife/types';

import { useDispatch } from '../../../Hooks';
import Urls from '../../../Navigation/Urls';
import {
  regularAuthenticate as reduxRegularAuthenticate,
  ssoAuthenticate as reduxSsoAuthenticate,
} from '../../../Redux/Authentication/AuthenticationOperations';
import AuthCallbackView from './AuthCallbackView';

function AuthCallbackContainer(): React.ReactElement | null {
  const dispatch = useDispatch();

  const handleRegularCallback = useCallback(
    (token: string, auth0User: Pick<User, 'auth0Id'>, lang?: string) => {
      dispatch(reduxRegularAuthenticate(token, auth0User, lang));
    },
    [dispatch]
  );

  const handleSsoCallback = useCallback(
    (token: string, auth0User: Pick<User, 'auth0Id'>, lang?: string) => {
      dispatch(reduxSsoAuthenticate(token, auth0User, lang));
    },
    [dispatch]
  );

  const redirectToPlatform = useCallback(
    (route?: string) => {
      dispatch(push(route || Urls.home));
    },
    [dispatch]
  );

  const props = { handleRegularCallback, redirectToPlatform, handleSsoCallback };

  return <AuthCallbackView {...props} />;
}

export default AuthCallbackContainer;
