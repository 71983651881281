import { TextFieldProps } from '@material-ui/core/TextField';
import React, { useCallback } from 'react';
import InputMask from 'react-input-mask';

import { Input, ValidationProps } from '@breathelife/ui-components';

type Props = Omit<TextFieldProps, 'onChange'> &
  ValidationProps & {
    onChange: (value: string) => void;
  };

export function PostalCodeInputMui(props: Props): React.ReactElement {
  const { onChange: onChangeProps } = props;
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!onChangeProps) return;
      onChangeProps(event.target.value.toUpperCase());
    },
    [onChangeProps]
  );

  return (
    <InputMask
      onChange={onChange}
      alwaysShowMask={false}
      mask='a9a 9a9'
      maskChar='_'
      value={props.value as string}
      onBlur={props.onBlur}
      placeholder={props.placeholder}
      type='tel'
    >
      {(inputProps: TextFieldProps) => (
        <Input
          {...inputProps}
          required={props.required}
          id={props.id}
          label={props.label}
          error={!!props.validationError}
          validationError={props.validationError}
          inputVariant='outlined'
        />
      )}
    </InputMask>
  );
}
