import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { ParticipantRole } from '@breathelife/types';

import { QueryId } from '../../ReactQuery/common/common.types';
import { fetchParticipantRoles } from '../../Services/ParticipantRolesService';

export function useFindParticipantRolesQuery(
  options?: UseQueryOptions<ParticipantRole[]>
): UseQueryResult<ParticipantRole[]> {
  return useQuery<ParticipantRole[]>([QueryId.participantRoles], async () => await fetchParticipantRoles(), {
    ...options,
    staleTime: 1000 * 60 * 60, // 1 hour
  });
}
