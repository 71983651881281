import React, { SVGProps } from 'react';

import { PdfIcon } from '../../Assets/Attachments/PdfIcon';

type Size = 'small' | 'medium' | 'large';

const iconSize: { [key in Size]: string } = {
  small: '24px',
  medium: '36px',
  large: '44px',
};

const iconMap = {
  pdf: PdfIcon,
};

type AttachmentIconProps = SVGProps<SVGSVGElement> & {
  name: keyof typeof iconMap;
  alt?: string;
  size?: Size;
};

export function AttachmentIcon(props: AttachmentIconProps): React.ReactElement {
  const { name, alt, size, ...restOfProps } = props;

  const IconToRender = iconMap[name];

  const renderSize = {
    height: iconSize[size ?? 'medium'],
    width: iconSize[size ?? 'medium'],
  };

  return <IconToRender role='img' aria-label={alt} {...renderSize} {...restOfProps} />;
}
