import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Language } from '@breathelife/types';

import { Image } from '../../../../../Components/Images/Image';
import { CloudinaryUpload } from '../../../../../Components/Upload/Cloudinary/CloudinaryUpload';
import { ValueChangeFunction } from '../types';

const StyledGrid = styled(Grid)`
  &&& {
    padding: 0;
  }
`;

type Props = {
  logoUrl?: string;
  language: Language;
  onInputValueChange: ValueChangeFunction;
};

export const FirmLogo = React.memo(function FirmLogo(props: Props) {
  const { t } = useTranslation();
  const imageUrl =
    typeof props.logoUrl === 'undefined' || !/cloudinary/.test(props.logoUrl) ? undefined : props.logoUrl;
  return (
    <StyledGrid item xs={6}>
      <CloudinaryUpload
        uploadPreset='px_admin_firm_logo'
        buttonText={
          imageUrl
            ? t('admin.firmManagement.labels.changeLogoButtonText', {
                language: t(`language.${props.language}`),
              })
            : t('admin.firmManagement.labels.uploadLogoButtonText', {
                language: t(`language.${props.language}`),
              })
        }
        setPicture={(logo) => {
          props.onInputValueChange({
            path: `logoUrl.${props.language}`,
            value: logo,
          });
        }}
      />
      {imageUrl && (
        <Box my={2}>
          <Image src={imageUrl} radius={0} isProfile={false} width={150} />
        </Box>
      )}
    </StyledGrid>
  );
});
