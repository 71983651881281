import React, { SVGProps } from 'react';

export function AddIcon(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg height='22' viewBox='0 0 22 22' width='22' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path
          id='add-border'
          d='m11.0084229 21.4986572c5.7989898 0 10.5-4.7010101 10.5-10.5 0-5.79898985-4.7010102-10.49999997-10.5-10.49999997-5.79898992 0-10.50000005 4.70101012-10.50000005 10.49999997 0 5.7989899 4.70101013 10.5 10.50000005 10.5z'
          stroke={props.stroke}
        />
        <g fill={props.stroke} id='add-plus'>
          <rect height='12' rx='1' width='2' x='10' y='5' />
          <path
            d='m11 5c.5522847 0 1 .44771525 1 1v10c0 .5522847-.4477153 1-1 1-.5522847 0-1-.4477153-1-1v-10c0-.55228475.4477153-1 1-1z'
            transform='matrix(0 1 -1 0 22 0)'
          />
        </g>
      </g>
    </svg>
  );
}
