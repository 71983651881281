import styled from 'styled-components';

import { Box } from '@breathelife/ui-components';

import { Button } from '../../../../../Components/Button/Button';

export const CardContainer = styled(Box)<{ showError: boolean }>`
  && {
    border: ${(props) => `1px solid ${props.showError ? props.theme.colors.red[50] : props.theme.colors.grey[50]}`};
    border-radius: 4px;
    overflow: hidden;
  }
`;

export const CardHeader = styled(Box)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: ${(props) => props.theme.colors.grey[20]};
  }
`;

export const CardBody = styled(Box)`
  && {
    padding: 16px;
  }
`;

export const CardGrid = styled(Box)`
  && {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 24px;
  }
`;

export const CardField = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
  }
`;

export const CardExpand = styled(Button)`
  && {
    margin: 16px 0 -8px -8px;

    svg {
      margin-left: 8px;
    }
  }
`;

export const AddButton = styled(Button)`
  && {
    margin-top: 24px;
    color: ${(props) => props.theme.colors.grey[70]};

    &:hover {
      color: ${(props) => props.theme.colors.primary.default};
    }
  }
`;

export const DeleteButton = styled(Button)`
  && {
    color: ${(props) => props.theme.colors.red[50]};
  }
`;
