import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Switch, useLocation } from 'react-router-dom';

import { DebugToolbarContainer } from '../../Components/DebugToolbar/DebugToolbarContainer';
import { useCarrierContext } from '../../Hooks';
import { LeadsPageLayout } from '../../Layouts/LeadsPageLayout/LeadsPageLayout';
import { LeadTab } from '../../Models/Layout';
import Urls, { generateLeadsListUrl } from '../../Navigation/Urls';
import { LeadsPageContainer } from '../../Pages/Leads/LeadsPageContainer';
import PrivateRoute from '../../Root/PrivateRoute';
import { LeadsPageDataContextProvider } from './LeadsPageDataContextProvider';

type LeadPageProps = {
  displayContentFullScreen: boolean;
};

export function LeadsPage(props: LeadPageProps): React.ReactElement {
  const { displayContentFullScreen } = props;
  const { features } = useCarrierContext();
  const { t } = useTranslation();
  const location = useLocation();

  const tabs = [
    {
      to: generateLeadsListUrl(LeadTab.active),
      label: t('leadsListTable.active'),
      ['data-testid']: 'leads-list-tab-active',
    },
    {
      to: generateLeadsListUrl(LeadTab.archived),
      label: t('leadsListTable.archived'),
      ['data-testid']: 'leads-list-tab-archive',
    },
  ];

  return (
    <LeadsPageLayout
      displayContentFullScreen={displayContentFullScreen}
      pageTitle={t('pageTitles.home')}
      content={
        <Switch>
          <PrivateRoute
            exact
            path={[Urls.leadsList, Urls.leadDetail, Urls.application]}
            render={() => (
              <React.Fragment>
                {features.questionnaireDebugTools.enabled && <DebugToolbarContainer />}
                <LeadsPageDataContextProvider>
                  <LeadsPageContainer />
                </LeadsPageDataContextProvider>
              </React.Fragment>
            )}
          />
          <Redirect to={{ pathname: tabs[0].to, search: location.search }} />
        </Switch>
      }
      defaultTab={tabs[0].to}
      tabs={tabs}
    />
  );
}
