import { Grid, IconButton } from '@material-ui/core';
import { DragHandle } from '@material-ui/icons';
import React, { useMemo, useState } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../../Components/Button/Button';
import { SubmitButton } from '../../../../../Components/Button/SubmitButton';
import { Icon } from '../../../../../Components/Icons';
import { MoreActionButton } from '../../../../../Components/MoreActionButton/MoreActionButton';
import { MenuAction, MenuElement } from '../../../../../Components/PopupMenu/PopupMenu';
import { ModalLayout } from '../../../../../Layouts/Modal/ModalLayout';

export enum RemovableResources {
  section = 'section',
  subsection = 'subsection',
  selectOption = 'selectOption',
  fieldGroup = 'fieldGroup',
  field = 'field',
}

export function DeleteResourceConfirmationModal(props: {
  onConfirm: () => void;
  onCancel: () => void;
  customMessage?: string;
  resourceName?: RemovableResources;
}): React.ReactElement {
  const { t } = useTranslation();
  return (
    <ModalLayout
      maxWidth='md'
      isOpen={true}
      closeModal={props.onCancel}
      title={t('admin.questionnaireManagement.confirmDeleteModal.title')}
      submitButton={
        <SubmitButton
          onClick={(event) => {
            event.stopPropagation();
            props.onConfirm();
          }}
        >
          {t('cta.delete')}
        </SubmitButton>
      }
    >
      {props.customMessage ||
        (props.resourceName
          ? t('admin.questionnaireManagement.confirmDeleteModal.defaultContent', {
              resourceName: t(`admin.questionnaireManagement.confirmDeleteModal.${props.resourceName}`),
            })
          : t('admin.questionnaireManagement.confirmDeleteModal.defaultContentWithoutResourceName'))}
    </ModalLayout>
  );
}

type EditButtonProps = {
  onEditClick: () => void;
};

type HideItemButtonProps = {
  isItemHidden: boolean;
  onHideItemButtonClick: (newIsItemHiddenValue: boolean) => void;
  disabled?: boolean;
};

type RemoveItemButtonProps = {
  onRemoveItemClick: () => void;
  customConfirmModalMessage?: string;
  resourceName?: RemovableResources;
  disabled?: boolean;
};

type HandleButtonProps = {
  dragHandleProps?: DraggableProvidedDragHandleProps;
};

type Props = {
  editButtonProps?: EditButtonProps;
  hideItemButtonProps?: HideItemButtonProps;
  removeItemButtonProps?: RemoveItemButtonProps;
  handleButtonProps?: HandleButtonProps;
  disabled?: boolean;
  dragDisabled?: boolean;
  additionalActions?: MenuAction[];
};

export function ActionButtons(props: Props): React.ReactElement | null {
  const {
    editButtonProps,
    hideItemButtonProps,
    handleButtonProps,
    removeItemButtonProps,
    dragDisabled,
    disabled,
    additionalActions,
  } = props;
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const items = useMemo(() => {
    let items: MenuElement[] = [];

    if (removeItemButtonProps) {
      items.push({
        tag: 'MenuAction',
        icon: <Icon name='delete' />,
        onClick: () => {
          setIsModalOpen(true);
        },
        label: t('admin.questionnaireManagement.remove'),
        disabled: disabled || removeItemButtonProps.disabled,
      });
    }

    if (hideItemButtonProps) {
      items.push({
        tag: 'MenuAction',
        icon: <Icon name={hideItemButtonProps.isItemHidden ? 'visibility' : 'visibilityOff'} />,
        onClick: () => {
          hideItemButtonProps.onHideItemButtonClick(!hideItemButtonProps.isItemHidden);
        },
        label: hideItemButtonProps.isItemHidden
          ? t('admin.questionnaireManagement.show')
          : t('admin.questionnaireManagement.hide'),
        disabled: disabled || hideItemButtonProps.disabled,
      });
    }

    if (additionalActions && additionalActions.length > 0) {
      items = items.concat([{ tag: 'MenuDivider' }, ...additionalActions]);
    }

    return items;
  }, [additionalActions, disabled, hideItemButtonProps, removeItemButtonProps, t]);

  return (
    <Grid item>
      {editButtonProps && <ViewOrEditButton {...editButtonProps} disabled={disabled} />}
      {!disabled && items.length > 0 && <MoreActionButton actions={items} />}

      {handleButtonProps && (
        <IconButton disabled={disabled || dragDisabled} {...handleButtonProps?.dragHandleProps}>
          <DragHandle fontSize='small' />
        </IconButton>
      )}

      {isModalOpen && removeItemButtonProps && (
        <DeleteResourceConfirmationModal
          resourceName={removeItemButtonProps.resourceName}
          customMessage={removeItemButtonProps.customConfirmModalMessage}
          onCancel={() => setIsModalOpen(false)}
          onConfirm={() => {
            removeItemButtonProps.onRemoveItemClick();
            setIsModalOpen(false);
          }}
        />
      )}
    </Grid>
  );
}

function ViewOrEditButton(props: EditButtonProps & { disabled?: boolean }): React.ReactElement {
  const { onEditClick, disabled } = props;
  const { t } = useTranslation();

  return (
    <Button size='small' color='primary' variant='text' onClick={onEditClick}>
      {t(disabled ? 'view' : 'edit')}
    </Button>
  );
}
