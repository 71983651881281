import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/AddRounded';
import React, { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApplicationMode } from '@breathelife/types';

import { ActionButton } from '../../Components/Button/ActionButton';

type LaunchApplicationMenuButtonProps = {
  onCreateApplication: (mode: ApplicationMode) => void;
  isCreatingApplication: boolean;
};

export function LaunchApplicationMenuButton(props: LaunchApplicationMenuButtonProps): React.ReactElement {
  const { t } = useTranslation();
  const { onCreateApplication, isCreatingApplication } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openMenu = (event: MouseEvent) => {
    const { target } = event;

    if (target instanceof HTMLElement) {
      setAnchorEl(target);
    }
  };

  const closeMenu = (event: MouseEvent) => {
    const { target } = event;

    if (target instanceof HTMLElement) {
      const mode = target.dataset.id ?? '';

      if (ApplicationMode[mode]) {
        onCreateApplication(ApplicationMode[mode]);
      }

      setAnchorEl(null);
    }
  };

  return (
    <Box textAlign='center'>
      <ActionButton
        data-testid='addNewPaperAppBtn'
        color='primary'
        variant='contained'
        onClick={openMenu}
        startIcon={<AddIcon htmlColor='white' />}
        disabled={isCreatingApplication}
        aria-controls='switch-application-mode-menu'
      >
        {t('cta.launchApplication')}
      </ActionButton>
      <Menu
        id='switch-application-mode-menu'
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={closeMenu}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem data-id={ApplicationMode.digital} onClick={closeMenu}>
          {t('cta.digitalApplication')}
        </MenuItem>
        <MenuItem data-id={ApplicationMode.paper} onClick={closeMenu}>
          {t('cta.paperApplication')}
        </MenuItem>
      </Menu>
    </Box>
  );
}
