import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { Notification } from '../Components/Notification/Notification';
import Urls from '../Navigation/Urls';
import AuthCallbackPage from '../Pages/Login/AuthCallback/AuthCallbackContainer';
import LoginPage from '../Pages/Login/LoginContainer';
import LogoutPage from '../Pages/Login/LogoutContainer';
import { PrivateRootApplication } from '../Root/PrivateRootApplication';
import PrivateRoute from '../Root/PrivateRoute';

export function Root(): React.ReactElement {
  const location = useLocation();
  return (
    <React.Fragment>
      <Notification />
      <Switch>
        <Route exact path={Urls.login} component={LoginPage} />
        {/* Support old login route to redirect to the proper route. Can be removed once
            NLG has updated their login redirect to point on /pro/login. They are currently doing
            /leads/login?sso=1 from their platform.
        */}
        <Redirect exact from={Urls.legacy.login} to={{ pathname: Urls.login, search: location.search }} />

        <Route exact path={Urls.logout} component={LogoutPage} />
        {/* Support old logout route to redirect to the proper route. Can be removed once NLG update
            their portal login/logout.
        */}
        <Redirect exact from={Urls.legacy.logout} to={{ pathname: Urls.logout, search: location.search }} />

        <Route exact path={[Urls.authCallback]} component={AuthCallbackPage} />

        <PrivateRoute path={Urls.home} component={PrivateRootApplication} />

        <Redirect to={{ pathname: Urls.home, search: location.search }} />
      </Switch>
    </React.Fragment>
  );
}
