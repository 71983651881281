import { Box, Grid } from '@material-ui/core';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ValidationError } from 'yup';

import { InsuranceScopes, Permission, UserCategory, UserType } from '@breathelife/types';
import { Input as TextInput, PhoneInput, SelectMui, SelectMuiProps } from '@breathelife/ui-components';

import RadioGroup, { Radio } from '../../../../Components/RadioGroup/RadioGroup';
import { getFieldValidationError } from '../../../../Helpers/inputValidation/form/user';
import { getOptionsFromEnum } from '../../../../Helpers/options';
import { userHasPermission } from '../../../../Helpers/user';
import { useCarrierContext, useSelector } from '../../../../Hooks';
// Not using UserRole from @breathelife/types because it is also including service role..
import { UserRole } from '../../../../Services/Auth0';

export type UserFormState = {
  firstName: InputState;
  lastName: InputState;
  phoneNumber: InputState;
  userId: InputState;
  email: InputState;
  insuranceScope?: InsuranceScopes;
  role: UserRole;
  groupId: string;
  category: UserCategory;
  type: UserType;
  picture?: string;
  isSso?: boolean;
};

export type InputState = {
  value: string;
  error?: ValidationError;
};

type UserFormProps = {
  userFormState: UserFormState;

  onFirstNameChange: (formData: InputState) => void;
  onLastNameChange: (formData: InputState) => void;
  onEmailChange: (formData: InputState) => void;
  onPhoneNumberChange: (formData: InputState) => void;
  onUserIdChange: (formData: InputState) => void;
  onGroupIdChange: (value: string) => void;
  onRoleChange: (value: UserRole) => void;
  onCategoryChange: (value: UserCategory) => void;
  onTypeChange: (value: UserType) => void;
  onInsuranceScopeChange: (value: InsuranceScopes) => void;
};

// styled HoC does not preserve Generic types
const Select = styled(SelectMui)`
  width: 100%;
` as <T extends string | number = string>(props: SelectMuiProps<T>) => React.ReactElement;

export function UserForm(props: UserFormProps): React.ReactElement | null {
  const { t } = useTranslation();
  const currentUserPermissions = useSelector((store) => store.leadPlatform.authentication?.user?.permissions);
  const {
    userFormState,
    onFirstNameChange,
    onLastNameChange,
    onEmailChange,
    onPhoneNumberChange,
    onGroupIdChange,
    onUserIdChange,
    onRoleChange,
    onCategoryChange,
    onTypeChange,
    onInsuranceScopeChange,
  } = props;
  const { firstName, lastName, email, phoneNumber, userId, role, groupId, category, type, insuranceScope, isSso } =
    userFormState;
  const { insuranceScopes: carrierInsuranceScopes, mgas, ssoProfileFields } = useCarrierContext();
  let rolesOptions = getOptionsFromEnum<UserRole>(UserRole, 'user.role');
  if (!userHasPermission(currentUserPermissions, Permission.UserCreateSuperAdmin)) {
    rolesOptions = _.filter(rolesOptions, (option) => option.value !== UserRole.superAdmin);
  }
  const userCategoryOptions = getOptionsFromEnum<UserCategory>(UserCategory, 'user.category');
  const userTypeOptions = getOptionsFromEnum<UserType>(UserType, 'user.type');
  const groupOptions = useMemo(
    () =>
      [{ label: t('mgaGroup.default'), value: '' }].concat(
        _.map(mgas, (group) => ({
          label: group.name,
          value: group.id,
        }))
      ),
    [t, mgas]
  );
  const insuranceScopesRadioChoices = _.map(carrierInsuranceScopes, (scope) => ({
    value: scope,
    label: t(`insuranceScope.${scope}`),
  }));

  const hasGroupOptions = groupOptions.length > 0;

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box mt={-0.5}>
            <TextInput
              fullWidth
              required
              disabled={isSso && ssoProfileFields.includes('firstName')}
              value={firstName.value}
              error={!!firstName.error}
              validationError={firstName.error}
              id='userManagement-fname'
              inputVariant='outlined'
              label={t('inputs.firstName')}
              onChange={(event) =>
                onFirstNameChange({
                  value: event.target.value,
                  error: getFieldValidationError('firstName', event.target.value),
                })
              }
              onBlur={() =>
                onFirstNameChange({
                  ...firstName,
                  error: getFieldValidationError('firstName', firstName.value),
                })
              }
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box mt={-0.5}>
            <TextInput
              fullWidth
              required
              disabled={isSso && ssoProfileFields.includes('lastName')}
              value={lastName.value}
              error={!!lastName.error}
              validationError={lastName.error}
              id='userManagement-lname'
              inputVariant='outlined'
              label={t('inputs.lastName')}
              onChange={(event) =>
                onLastNameChange({
                  value: event.target.value,
                  error: getFieldValidationError('lastName', event.target.value),
                })
              }
              onBlur={() =>
                onLastNameChange({
                  ...lastName,
                  error: getFieldValidationError('lastName', lastName.value),
                })
              }
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box mt={-0.5}>
            <TextInput
              fullWidth
              required
              disabled={isSso && ssoProfileFields.includes('emailLogin')}
              value={email.value}
              error={!!email.error}
              validationError={email.error}
              id='userManagement-email'
              inputVariant='outlined'
              label={t('inputs.email')}
              onChange={(event) =>
                onEmailChange({
                  value: event.target.value.trim(),
                  error: getFieldValidationError('emailLogin', event.target.value),
                })
              }
              onBlur={() =>
                onEmailChange({
                  ...email,
                  error: getFieldValidationError('emailLogin', email.value),
                })
              }
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box mt={-0.5}>
            <PhoneInput
              name='userManagement-phoneNumber'
              disabled={isSso && ssoProfileFields.includes('phoneNumber')}
              fullWidth
              inputVariant='outlined'
              label={t('inputs.phoneNumber')}
              validationError={phoneNumber.error}
              value={phoneNumber.value}
              onAnswerChange={(answer: string) =>
                onPhoneNumberChange({
                  value: answer,
                  error: getFieldValidationError('phoneNumber', answer),
                })
              }
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box mt={-0.5}>
            <TextInput
              fullWidth
              disabled={isSso && ssoProfileFields.includes('customId')}
              value={userId.value}
              error={!!userId.error}
              validationError={userId.error}
              id='userManagement-customId'
              inputVariant='outlined'
              label={t('inputs.userId')}
              onChange={(event) =>
                onUserIdChange({
                  value: event.target.value,
                  error: getFieldValidationError('userId', event.target.value),
                })
              }
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box mt={-0.5}>
            <Select
              id='roles-select'
              disabled={isSso && ssoProfileFields.includes('roles')}
              label={t('inputs.role')}
              value={role}
              options={rolesOptions}
              onChange={onRoleChange}
            />
          </Box>
        </Grid>
        {hasGroupOptions && (
          <Grid item xs={6}>
            <Box mt={-0.5}>
              <Select
                displayEmpty
                id='group-select'
                disabled={isSso && ssoProfileFields.includes('groupId')}
                value={groupId}
                label={t('inputs.group')}
                options={groupOptions}
                onChange={onGroupIdChange}
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={6}>
          <Box mt={-0.5}>
            <Select
              id='category-select'
              disabled={isSso && ssoProfileFields.includes('category')}
              label={t('inputs.category')}
              value={category}
              options={userCategoryOptions}
              onChange={onCategoryChange}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box mt={-0.5}>
            <Select
              id='type-select'
              disabled={isSso && ssoProfileFields.includes('type')}
              label={t('inputs.type')}
              value={type}
              options={userTypeOptions}
              onChange={onTypeChange}
            />
          </Box>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RadioGroup<InsuranceScopes>
              label={t('modals.userDetails.selectASharingPreferenceLabel')}
              name='UserForm-insuranceScopes'
              onChange={onInsuranceScopeChange}
              value={insuranceScope}
            >
              <Grid container spacing={1}>
                {_.map(insuranceScopesRadioChoices, (choice) => {
                  return (
                    <Grid item xs={3} sm={3} key={choice.value}>
                      <Radio<InsuranceScopes>
                        value={choice.value}
                        label={choice.label}
                        disabled={isSso && ssoProfileFields.includes('defaultInsuranceScopes')}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </RadioGroup>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
