import ReactHtmlParser from 'html-react-parser';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@breathelife/ui-components';

import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import { Icon } from '../../../../Components/Icons';
import Typography from '../../../../Components/Typography';
import { useAssistedApplicationContext } from '../../../../Hooks/useAssistedApplicationContext';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';

type DeleteProposedInsuredModalProps = {
  isOpen: boolean;
  onClose: () => void;
  proposedInsuredIndexToDelete: number;
};

export function DeleteProposedInsuredModal(props: DeleteProposedInsuredModalProps): React.ReactElement {
  const { isOpen, onClose, proposedInsuredIndexToDelete } = props;
  const { t } = useTranslation();
  const { proposedInsuredTabs, onRemoveProposedInsured, isLoadingProposedInsured } = useAssistedApplicationContext();

  const proposedInsured = proposedInsuredTabs[proposedInsuredIndexToDelete];
  const name = proposedInsured?.name ?? t('assistedApplication.multiInsuredTabs.newProposedInsured');

  function onSubmit(): void {
    if (!proposedInsured?.surrogateId) return;
    onRemoveProposedInsured(proposedInsured.surrogateId);
  }

  return (
    <ModalLayout
      maxWidth='sm'
      isOpen={isOpen}
      closeModal={onClose}
      titleIcon={<Icon name='warning' variant='yellow' fill='none' />}
      title={t('modals.assistedApplication.deleteProposedInsured.title')}
      submitButton={
        <SubmitButton onClick={onSubmit} isLoading={isLoadingProposedInsured}>
          {t('cta.delete')}
        </SubmitButton>
      }
    >
      <Box mb={2}>
        <Typography variant='body1'>
          {ReactHtmlParser(t('modals.assistedApplication.deleteProposedInsured.body1', { name }))}
        </Typography>
      </Box>
      <Box mb={4}>
        <Typography variant='body1'>{t('modals.assistedApplication.deleteProposedInsured.body2')}</Typography>
      </Box>
    </ModalLayout>
  );
}
