import { Tooltip } from '@material-ui/core';
import { captureMessage, Severity } from '@sentry/browser';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { OutcomeCode } from '@breathelife/types';

import { LeadPlatformTheme } from '../../Styles/Types';
import { StyledBadge, StyledBadgeContainer } from './Badge';

export type Props = {
  outcome: OutcomeCode;
  overrideOutcome?: string;
};

export function OutcomeBadge({ outcome, overrideOutcome }: Props): React.ReactElement | null {
  const theme = useTheme() as LeadPlatformTheme;
  const { t } = useTranslation();

  const outcomeCodeTextColors = useMemo<Partial<Record<OutcomeCode, { textColor: string; backgroundColor: string }>>>(
    () => ({
      [OutcomeCode.referred]: { textColor: theme.colors.orange[60], backgroundColor: theme.colors.orange[20] },
      [OutcomeCode.denied]: { textColor: theme.colors.red[60], backgroundColor: theme.colors.red[20] },
      [OutcomeCode.blocked]: { textColor: theme.colors.blue[60], backgroundColor: theme.colors.blue[20] },
      [OutcomeCode.blockedAtQuoter]: { textColor: theme.colors.purple[60], backgroundColor: theme.colors.purple[20] },
      [OutcomeCode.accepted]: { textColor: theme.colors.green[60], backgroundColor: theme.colors.green[20] },
      [OutcomeCode.conditional]: { textColor: theme.colors.yellow[60], backgroundColor: theme.colors.yellow[20] },
    }),
    [theme]
  );

  const colors = outcomeCodeTextColors[outcome];
  if (!colors) {
    captureMessage(`Unknown outcome code: ${outcome}`, Severity.Error);
    return null;
  }

  const label = overrideOutcome ? t(`outcomes.${overrideOutcome}.label`) : t(`outcomes.${outcome}.label`);
  const tooltip = overrideOutcome ? t(`outcomes.${overrideOutcome}.tooltip`) : t(`outcomes.${outcome}.tooltip`);

  return (
    <Tooltip arrow placement='right' title={tooltip}>
      <StyledBadgeContainer>
        <StyledBadge backgroundColor={colors.backgroundColor} textColor={colors.textColor} label={label} />
      </StyledBadgeContainer>
    </Tooltip>
  );
}
