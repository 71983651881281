import React, { SVGProps } from 'react';

export function LoginKeyIcon(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      stroke='#29614B'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0)'>
        <path
          d='M8.54553 9.67758L10.2422 7.98091L11.4102 8.29424C11.5877 8.34159 11.7746 8.34143 11.9521 8.29377C12.1296 8.2461 12.2914 8.15262 12.4213 8.02268C12.5512 7.89275 12.6447 7.73093 12.6924 7.55347C12.7401 7.376 12.7402 7.18912 12.6929 7.01158L12.3795 5.84358L12.9022 5.32091L14.0702 5.63424C14.2478 5.68181 14.4347 5.6818 14.6123 5.63422C14.7899 5.58663 14.9518 5.49314 15.0818 5.36315C15.2118 5.23316 15.3053 5.07125 15.3528 4.89368C15.4004 4.71611 15.4004 4.52915 15.3529 4.35158L15.0402 3.18358C15.3348 2.88892 15.5002 2.48932 15.5002 2.07267C15.5001 1.65603 15.3345 1.25648 15.0399 0.961909C14.894 0.816054 14.7208 0.700364 14.5302 0.621445C14.3395 0.542526 14.1353 0.501922 13.929 0.501953C13.5123 0.502016 13.1128 0.667587 12.8182 0.962242L6.3242 7.45624C5.42926 7.0768 4.4302 7.02044 3.49826 7.29681C2.56632 7.57318 1.75951 8.16508 1.21611 8.97106C0.672711 9.77705 0.426552 10.7469 0.51982 11.7145C0.613089 12.6821 1.03998 13.5871 1.72733 14.2744C2.41468 14.9618 3.31969 15.3887 4.28726 15.482C5.25484 15.5752 6.22472 15.3291 7.03071 14.7857C7.8367 14.2423 8.4286 13.4355 8.70497 12.5035C8.98134 11.5716 8.92497 10.5725 8.54553 9.67758V9.67758Z'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.90088 12.0532C2.90088 12.1907 2.92797 12.3269 2.9806 12.454C3.03324 12.5811 3.11038 12.6965 3.20764 12.7938C3.30489 12.891 3.42035 12.9682 3.54742 13.0208C3.67448 13.0734 3.81067 13.1005 3.94821 13.1005C4.08575 13.1005 4.22194 13.0734 4.34901 13.0208C4.47608 12.9682 4.59153 12.891 4.68879 12.7938C4.78604 12.6965 4.86319 12.5811 4.91582 12.454C4.96846 12.3269 4.99555 12.1907 4.99555 12.0532C4.99555 11.9157 4.96846 11.7795 4.91582 11.6524C4.86319 11.5253 4.78604 11.4099 4.68879 11.3126C4.59153 11.2154 4.47608 11.1382 4.34901 11.0856C4.22194 11.0329 4.08575 11.0059 3.94821 11.0059C3.81067 11.0059 3.67448 11.0329 3.54742 11.0856C3.42035 11.1382 3.30489 11.2154 3.20764 11.3126C3.11038 11.4099 3.03324 11.5253 2.9806 11.6524C2.92797 11.7795 2.90088 11.9157 2.90088 12.0532V12.0532Z'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
}
