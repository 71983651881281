import React, { useCallback } from 'react';

import { FileTemplate, FileTemplateOrigin } from '@breathelife/types';

import { Drawer } from '../../../Components/Drawer/Drawer';
import { useCreateFileTemplateMutation } from '../../../ReactQuery/Document/document.mutations';
import { CreateFileTemplateData } from '../../../Services/FileTemplateService';
import { DocumentEditor } from './Editor/DocumentEditor';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export function NewDocumentDrawer(props: Props): React.ReactElement {
  const { isOpen, onClose } = props;

  const createFileTemplateMutation = useCreateFileTemplateMutation({
    onSuccess: useCallback(() => {
      onClose();
    }, [onClose]),
  });

  const onCreateDocument = useCallback(
    async (data: Partial<FileTemplate>) => {
      if (!data.origin || !data.docType || !data.localizedName || !data.localizedName.en) {
        return;
      }

      const createFileTemplateData: CreateFileTemplateData = {
        name: data.name || '',
        origin: data.origin,
        docType: data.docType,
        externalUrl: data.origin === FileTemplateOrigin.ExternalStatic ? data.externalUrl : undefined,
        identifier: data.identifier,
        isRequired: !!data.isRequired,
        variant: data.variant === 'none' ? null : data.variant,
        localizedName: data.localizedName,
      };
      await createFileTemplateMutation.mutateAsync(createFileTemplateData);
    },
    [createFileTemplateMutation]
  );

  return (
    <Drawer open={isOpen} onClose={onClose}>
      <DocumentEditor
        onClose={onClose}
        onSubmit={onCreateDocument}
        isSavingTemplate={createFileTemplateMutation.isLoading}
      />
    </Drawer>
  );
}
