import Box from '@material-ui/core/Box';
import MuiTab from '@material-ui/core/Tab';
import MuiTabs from '@material-ui/core/Tabs';
import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

const StyledTab = styled(MuiTab)`
  &&& {
    padding: 0;
    min-width: unset;
    margin-left: 20px;

    &:first-child {
      margin-left: unset;
    }
  }
`;

export type TabType = {
  label: string;
  hidden?: boolean;
  disabled?: boolean;
};

export type Props = {
  tabs: TabType[];
  value: number | false;
  onChange: (event: ChangeEvent<{}>, value: number) => void;
};

type TabPanelProps = {
  selectedIndex: number | undefined;
  tabIndex: number;
  children: React.ReactComponentElement<any> | null;
};

export function TabPanel(props: TabPanelProps): React.ReactElement | null {
  return props.selectedIndex === props.tabIndex ? props.children : null;
}
export function Tabs(props: Props): React.ReactElement {
  return (
    <MuiTabs variant='scrollable' indicatorColor='primary' textColor='primary' {...props}>
      {props.tabs.map((tab, index) => (
        <StyledTab
          key={`rightPanelTab-${index}`}
          label={tab.label}
          data-testid={`tab-${tab.label.toLowerCase().replace(' ', '-')}`}
          hidden={tab.hidden}
          disabled={tab.disabled}
        />
      ))}
    </MuiTabs>
  );
}

export const TabsContainer = styled(Box)`
  && {
    border-color: ${(props) => props.theme.colors.grey[40]};
    box-shadow: 0 1px 5px rgba(9, 30, 66, 0.05);
  }
`;
