import { TFunction } from 'i18next';
import * as yup from 'yup';

type ValidationResults = {
  streetAddressOrAutocompleteNodeId?: yup.ValidationError;
  city?: yup.ValidationError;
  stateOrProvince?: yup.ValidationError;
  postalCodeOrZip?: yup.ValidationError;
};

export function validate(
  t: TFunction,
  data: {
    autocompleteNodeId: string;
    streetAddress: string;
    city: string;
    stateOrProvince: string;
    postalCodeOrZip: string;
    autocompleteIsAddressStreet: boolean;
  }
): ValidationResults {
  const requiredErrorMessage = t('validation.error.required');

  const fieldSchemas = {
    streetAddressOrAutocompleteNodeId: yup.mixed().test({
      test: (addressObject: unknown) => {
        if (
          typeof addressObject !== 'object' ||
          addressObject === null ||
          !['streetAddress', 'autocompleteNodeId', 'autocompleteIsAddressStreet'].every((key) => key in addressObject)
        ) {
          return false;
        }

        const { streetAddress, autocompleteNodeId, autocompleteIsAddressStreet } = addressObject as any;

        if (
          typeof streetAddress !== 'string' ||
          typeof autocompleteNodeId !== 'string' ||
          typeof autocompleteIsAddressStreet !== 'boolean'
        ) {
          return false;
        }

        return (autocompleteIsAddressStreet && !!autocompleteNodeId) || !!streetAddress;
      },
      message: requiredErrorMessage,
    }),
    city: yup.string().required(requiredErrorMessage),
    stateOrProvince: yup.string().required(requiredErrorMessage),
    postalCodeOrZip: yup.string().required(requiredErrorMessage),
  };

  const validationResult: ValidationResults = {};

  try {
    fieldSchemas.city.validateSync(data.city);
  } catch (err) {
    if (yup.ValidationError.isError(err)) {
      validationResult.city = err;
    } else {
      throw err;
    }
  }

  try {
    fieldSchemas.stateOrProvince.validateSync(data.stateOrProvince);
  } catch (err) {
    if (yup.ValidationError.isError(err)) {
      validationResult.stateOrProvince = err;
    } else {
      throw err;
    }
  }

  try {
    fieldSchemas.postalCodeOrZip.validateSync(data.postalCodeOrZip);
  } catch (err) {
    if (yup.ValidationError.isError(err)) {
      validationResult.postalCodeOrZip = err;
    } else {
      throw err;
    }
  }

  try {
    fieldSchemas.streetAddressOrAutocompleteNodeId.validateSync({
      streetAddress: data.streetAddress,
      autocompleteIsAddressStreet: data.autocompleteIsAddressStreet,
      autocompleteNodeId: data.autocompleteNodeId,
    });
  } catch (err) {
    if (yup.ValidationError.isError(err)) {
      validationResult.streetAddressOrAutocompleteNodeId = err;
    } else {
      throw err;
    }
  }

  return validationResult;
}
