import React from 'react';
import { useTranslation } from 'react-i18next';

import { SubmitButton } from '../../../../../Components/Button/SubmitButton';
import { ModalLayout } from '../../../../../Layouts/Modal/ModalLayout';

interface Props {
  isOpen: boolean;
  onCloseModal: () => void;
  onSubmit: () => void;
  isProductArchived: boolean;
}

export function ArchiveModal(props: Props): React.ReactElement {
  const { isOpen, onCloseModal, onSubmit, isProductArchived } = props;
  const { t } = useTranslation();

  return (
    <ModalLayout
      maxWidth='sm'
      title={t('admin.productManagement.archiving.modal.title')}
      isOpen={isOpen}
      closeModal={onCloseModal}
      hideCancel={false}
      submitButton={
        <SubmitButton
          data-testid={isProductArchived ? 'ArchiveModal-unarchive' : 'ArchiveModal-archive'}
          onClick={() => {
            onSubmit();
            onCloseModal();
          }}
        >
          {isProductArchived
            ? t('admin.productManagement.archiving.unarchive')
            : t('admin.productManagement.archiving.archive')}
        </SubmitButton>
      }
    >
      {isProductArchived
        ? t('admin.productManagement.archiving.modal.descriptionForUnarchive')
        : t('admin.productManagement.archiving.modal.descriptionForArchive')}
    </ModalLayout>
  );
}
