import _ from 'lodash';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Box } from '@breathelife/ui-components';

import { Helmet } from '../../../../Components/Helmet';
import LoadingView from '../../../../Components/LoadingView/LoadingView';
import Urls from '../../../../Navigation/Urls';
import { QuestionnaireVersionsContext } from '../QuestionnaireVersionsContextProvider';
import { QuestionnaireVersionsTable } from './QuestionnaireVersionsTable';

export function QuestionnaireVersionsTablePage(): React.ReactElement {
  const { t } = useTranslation();
  const history = useHistory();

  const { questionnaireVersions, isFetching } = useContext(QuestionnaireVersionsContext);

  const selectQuestionnaire = useCallback(
    (questionnaireVersionId?: string) => {
      if (!questionnaireVersionId) return;

      history.push(`${Urls.questionnaireManagement}/${questionnaireVersionId}`);
    },
    [history]
  );
  if (isFetching && _.isEmpty(questionnaireVersions)) {
    return <LoadingView />;
  }
  return (
    <Box m={2}>
      <Helmet text={t('pageTitles.questionnaireManagement')} />
      <QuestionnaireVersionsTable onTableRowClick={selectQuestionnaire} />
    </Box>
  );
}
