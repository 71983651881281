import Box from '@material-ui/core/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Permission } from '@breathelife/types';

import NeedsAnalysisEmptyImage from '../../../../../../Assets/NA-empty.svg';
import { Button } from '../../../../../../Components/Button/Button';
import { RestrictedToPermission } from '../../../../../../Components/Restricted/RestrictedToPermission';
import { useCarrierContext } from '../../../../../../Hooks';
import { Lead } from '../../../../../../Models/Lead';
import { Container, TopText, BottomText, Image } from './Styles';

type Props = {
  lead?: Lead;
  onSendToNeedsAnalysisClick: () => void;
};

const StyledButton = styled(Button)`
  margin-top: 35px;
`;

export function NotSent(props: Props): React.ReactElement {
  const { t } = useTranslation();
  const { isEmailActionEnabled } = useCarrierContext();
  const { lead } = props;
  const isSendNeedsAnalysisButtonEnabled = !!lead?.assignedToId && isEmailActionEnabled(lead.status);
  return (
    <Container>
      <Box mt={3}>
        <Image src={NeedsAnalysisEmptyImage} alt='' />
        <TopText>{t('tabs.needsAnalysis.notSent.notSentYet')}</TopText>
      </Box>

      <RestrictedToPermission permission={[Permission.LeadInvitePrivate, Permission.LeadInviteEmail]}>
        <React.Fragment>
          <BottomText>{t('tabs.needsAnalysis.notSent.wantToSendItNow')}</BottomText>
          <StyledButton
            color='primary'
            variant='contained'
            onClick={props.onSendToNeedsAnalysisClick}
            data-testid={'send-needs-analysis-button'}
            disabled={!isSendNeedsAnalysisButtonEnabled}
          >
            {t('cta.sendNeedsAnalysis')}
          </StyledButton>
        </React.Fragment>
      </RestrictedToPermission>
    </Container>
  );
}
