import { Grid, IconButton } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import React, { forwardRef, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DragStart,
  Droppable,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { NumericalDataType, Validations } from '@breathelife/questionnaire-engine';
import {
  FieldPartIdentifier,
  InformationFieldBlueprintVariant,
  isAgreeFieldBlueprint,
  isInformationFieldBlueprint,
  isRadioFieldBlueprint,
  isSelectOptionFieldBlueprint,
  isNumberFieldBlueprint,
  Language,
  Localizable,
  OptionSize,
  NumericalDataType as NumericalDataTypeEnum,
  QuestionnaireBlueprintFieldTypes,
  SelectOptionFieldBlueprint,
  SelectOptionPartIdentifier,
  isButtonFieldBlueprint,
  IconName,
  FieldSizes,
  getFieldLayoutOptions,
  FieldLayout,
  isAddressAutocompleteFieldBlueprint,
  AddressAutocompleteFieldBlueprint,
  isTextFieldBlueprint,
  DynamicOptionsBlueprint,
  PartIdentifierTag,
  FieldBlueprint,
  isAcceptingDefaultValueField,
  ParticipantRoles,
  isSignatureFieldBlueprint,
  BlueprintConditionValue,
  ApplicationModesDefault,
  ApplicationMode,
  QuestionnaireBlueprintCopyableOption,
} from '@breathelife/types';
import { AutocompleteOption, SimpleCheckbox, Box } from '@breathelife/ui-components';

import { NodeIdSelector } from '../../../../../Components/NodeIds/NodeIdSelector';
import Typography from '../../../../../Components/Typography';
import { getOptionsFromEnum } from '../../../../../Helpers/options';
import { NodeDetail, QuestionnaireNodeIds } from '../../../../../Helpers/questionnaireEditor/questionnaireNodeIds';
import {
  getInfoFieldStylingOptions,
  ValidationOptionsByFieldType,
} from '../../../../../Helpers/questionnaireEditor/selectOptions';
import { useCarrierContext } from '../../../../../Hooks/useCarrierContext';
import { SummaryText } from '../../../../../Pages/Admin/Questionnaire/QuestionnaireEditor/Components/SummaryText';
import { TextInput } from '../../../../../Pages/Admin/Questionnaire/QuestionnaireEditor/Components/TextInput';
import {
  useAddQuestionnaireElementBlueprint,
  useUpdateQuestionnaireElementBlueprint,
  useUpdateQuestionnaireElementBlueprintOrder,
} from '../../../../../ReactQuery/Admin/Questionnaire/questionnaireVersion.mutations';
import { QuestionnaireVersionDataContext } from '../../QuestionnaireVersionDataContextProvider';
import { AdvancedBlueprintOptions } from '../Components/AdvancedBlueprintOptions';
import { DynamicOptionsEditor } from '../Components/DynamicOptionsEditor';
import { EditorDropdown } from '../Components/EditorDropdown';
import { IconNameSelector } from '../Components/IconNameSelector';
import NavigatesToNextStepCheckbox from '../Components/NavigatesToNextStepCheckbox';
import { CreateSelectOption } from '../Creators/CreateSelectOption';
import { useAdvancedBlueprintOptions } from '../Hooks/useAdvancedBlueprintOptions';
import { useHighlightedTextInBlueprintElement } from '../Hooks/useHighlightedTextInBlueprintElement';
import { AddressAutocompleteFieldEditor } from './AddressAutocompleteFieldEditor';
import { AgreeFieldEditor } from './AgreeFieldEditor';
import { ConditionsEditor } from './ConditionsEditor';
import { InfoSupplementEditor } from './InfoSupplementEditor';
import { SelectOptionBlueprintEditor } from './SelectOptionBlueprintEditor';
import { ValidationConditionsEditor } from './ValidationConditionsEditor';
import { BooleanFieldDefaultValueEditor } from './defaultValueEditors/BooleanFieldDefaultValueEditor';
import { NumberFieldDefaultValueEditor } from './defaultValueEditors/NumberFieldDefaultValueEditor';

type Props = {
  isEditingEnabled: boolean;
  blueprint: FieldBlueprint;
  partIdentifier: FieldPartIdentifier;
  selectedLanguage: Language;
  parentHidden: boolean;
  sectionGroupCollectionNodeId: string | undefined;
  questionCollectionNodeId: string | undefined;
  questionnaireNodeIds: QuestionnaireNodeIds;
  addressAutocompleteFields?: AddressAutocompleteFieldBlueprint[];
  fieldTypeOptions: AutocompleteOption[];
  validationOptionsByFieldType: ValidationOptionsByFieldType;
  dataLabelOptions: AutocompleteOption[];
  addressAutocompleteFieldTargetOptions?: AutocompleteOption[]; // `AutocompleteOption` in the mui sense.
  displayAsCard?: boolean;
  disableCopyable: boolean;
};

const NO_DEFAULT = '----no-default-value';

export const FieldBlueprintEditor = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    blueprint,
    partIdentifier,
    fieldTypeOptions,
    validationOptionsByFieldType,
    selectedLanguage,
    parentHidden,
    sectionGroupCollectionNodeId,
    questionCollectionNodeId,
    questionnaireNodeIds,
    dataLabelOptions,
    addressAutocompleteFieldTargetOptions,
    displayAsCard,
    isEditingEnabled,
    disableCopyable,
  } = props;

  const { t } = useTranslation();
  const { showBlueprintIdInEditor } = useCarrierContext();

  const [title, setTitle] = useState<Partial<Localizable>>({});
  const [text, setText] = useState<Partial<Localizable>>({});
  const [fieldType, setFieldType] = useState('');
  const [validationType, setValidationType] = useState('');
  const [nodeId, setNodeId] = useState<string>('');
  const [isRequiredField, setIsRequiredField] = useState(false);
  const [isDisabledField, setIsDisabledField] = useState<boolean>();
  const [triggerStepNavigation, setTriggerStepNavigation] = useState(false);
  const [fieldSize, setFieldSize] = useState<FieldSizes | null>(null);
  const [forceNewLine, setForceNewLine] = useState<boolean | null>(null);
  const [editingNodeId, setEditingNodeId] = useState<boolean>(false);
  const [fieldOptionWidth, setFieldOptionsWidth] = useState<keyof typeof OptionSize | undefined>(undefined);
  const [fieldNumericalDataType, setFieldNumericalDataType] = useState<NumericalDataType | undefined>(undefined);
  const [dynamicOptions, setDynamicOptions] = useState<DynamicOptionsBlueprint | undefined>(undefined);

  const [consentModalHeader, setConsentModalHeader] = useState<Partial<Localizable>>({});
  const [consentText, setConsentText] = useState<Partial<Localizable>>({});
  const [buttonText, setButtonText] = useState<Partial<Localizable>>({});
  const [iconName, setIconName] = useState<IconName | undefined>(undefined);
  const [placeholder, setPlaceholder] = useState<Partial<Localizable>>({});
  const [defaultValue, setDefaultValue] = useState<string | number | boolean | string[] | undefined>(undefined);
  const [displayInCardPreview, setDisplayInCardPreview] = useState(false);
  const [infoFieldStylingOption, setInfoFieldStylingOption] = useState<string>(InformationFieldBlueprintVariant.info);
  const [participantRole, setParticipantRole] = useState<ParticipantRoles>(ParticipantRoles.INSURED);
  const [applicationModes, setApplicationModes] = useState<ApplicationMode[]>(ApplicationModesDefault);

  const infoFieldStylingOptions = useMemo(() => getInfoFieldStylingOptions(t), [t]);

  const highlightedTexts = useHighlightedTextInBlueprintElement({ partIdentifier, blueprint });

  const { questionnaireVersionId } = useContext(QuestionnaireVersionDataContext);

  const blueprintUpdate = useUpdateQuestionnaireElementBlueprint(questionnaireVersionId);

  const {
    actions: { setPlatforms, setScopes, setRenderOn, setDataLabel, setCopyable },
    selectors: { platforms, scopes, renderOn, dataLabel, copyable },
  } = useAdvancedBlueprintOptions<FieldPartIdentifier>({
    partIdentifier,
    blueprintUpdate,
  });

  useEffect(() => {
    if (!blueprint) return;

    const newTitle = blueprint.title;
    if (newTitle) {
      setTitle(newTitle);
    }

    const newText = blueprint.text;
    if (newText) {
      setText(newText);
    }

    const newPlaceholder = blueprint.placeholder;
    if (newPlaceholder) {
      setPlaceholder(newPlaceholder);
    }

    const newDisplayInCardPreview = blueprint.displayInCardPreview;
    if (newDisplayInCardPreview) {
      setDisplayInCardPreview(newDisplayInCardPreview);
    }

    const newDataLabel = blueprint.dataLabel;
    if (newDataLabel) {
      setDataLabel(newDataLabel, false);
    } else if (dataLabelOptions?.length) {
      setDataLabel(dataLabelOptions[0].value, false);
    }

    if (isRadioFieldBlueprint(blueprint)) {
      setFieldOptionsWidth(blueprint.optionSize ?? OptionSize.half);
    }

    if (blueprint.layout?.size) {
      setFieldSize(blueprint.layout.size);
    }

    if (blueprint.layout?.forceNewLine) {
      setForceNewLine(blueprint.layout.forceNewLine);
    }

    if (isAgreeFieldBlueprint(blueprint)) {
      if (blueprint.modalHeader) {
        setConsentModalHeader(blueprint.modalHeader);
      }
      if (blueprint.modalText) {
        setConsentText(blueprint.modalText);
      }
    } else if (isInformationFieldBlueprint(blueprint)) {
      setInfoFieldStylingOption(blueprint.variant);
    } else if (isNumberFieldBlueprint(blueprint)) {
      setFieldNumericalDataType(blueprint.numericalDataType ?? 'integer');
    } else if (isButtonFieldBlueprint(blueprint)) {
      setTriggerStepNavigation(blueprint.triggerStepNavigation ?? false);
      setButtonText(blueprint.buttonText);
      setIconName(blueprint.iconName);
    } else if (isSignatureFieldBlueprint(blueprint)) {
      setParticipantRole(blueprint.participantRole);
    }

    if (blueprint.dynamicOptions) {
      setDynamicOptions(blueprint.dynamicOptions);
    }

    if (isAcceptingDefaultValueField(blueprint)) {
      setDefaultValue(blueprint.defaultValue);
    }

    setFieldType(blueprint.fieldType);
    setValidationType(blueprint.validateAs);
    setNodeId(blueprint.answerNodeId);
    setIsRequiredField(!blueprint.optional);
    setIsDisabledField(blueprint.disabled);

    setPlatforms(blueprint.platforms ?? [], false);
    setRenderOn(blueprint.renderOn ?? [], false);
    setScopes(blueprint.scopes ?? [], false);
    setCopyable(blueprint.copyable ?? QuestionnaireBlueprintCopyableOption.none, false);
    setApplicationModes(blueprint.applicationModes ?? ApplicationModesDefault);
  }, [
    blueprint,
    setPlatforms,
    setRenderOn,
    setScopes,
    setCopyable,
    setDataLabel,
    setDefaultValue,
    dataLabelOptions,
    setApplicationModes,
  ]);

  const disabled = parentHidden || !!blueprint.hidden || !isEditingEnabled;

  const collectionContext: string[] = [];
  if (sectionGroupCollectionNodeId) collectionContext.push(sectionGroupCollectionNodeId);
  if (questionCollectionNodeId) collectionContext.push(questionCollectionNodeId);

  // The last item in the `collectionContext` array will be the parent collection node id.
  const parentCollectionNodeId = collectionContext.length ? collectionContext[collectionContext.length - 1] : undefined;
  const nodeIds: NodeDetail[] = parentCollectionNodeId
    ? [
        ...(questionnaireNodeIds.inQuestionnaire.withRepeatableAncestor[parentCollectionNodeId] ?? []),
        ...(questionnaireNodeIds.notInQuestionnaire.withRepeatableAncestor[parentCollectionNodeId] ?? []),
      ]
    : [
        ...questionnaireNodeIds.inQuestionnaire.noRepeatability,
        ...questionnaireNodeIds.notInQuestionnaire.noRepeatability,
      ];

  const validationTypeOptions = validationOptionsByFieldType[fieldType];

  const onUpdateDefaultValue = useCallback(
    (value: any) => {
      setDefaultValue(value);
      void blueprintUpdate({
        partIdentifier,
        update: { property: 'defaultValue', value },
      });
    },
    [setDefaultValue, blueprintUpdate, partIdentifier]
  );

  let selectOptionEditors: React.ReactElement | null = null;
  if (isSelectOptionFieldBlueprint(blueprint)) {
    selectOptionEditors = (
      <SelectOptionEditors
        defaultValue={blueprint.defaultValue}
        blueprint={blueprint}
        partIdentifier={partIdentifier}
        selectedLanguage={selectedLanguage}
        disabled={disabled}
        onUpdateDefaultValue={onUpdateDefaultValue}
        questionnaireNodeIds={questionnaireNodeIds}
        collectionContext={collectionContext}
      />
    );
  }

  const handleNodeIdChange = useCallback(
    (option: { value: string } | null) => {
      if (!option?.value) return;

      setNodeId(option?.value || '');
      void blueprintUpdate({
        partIdentifier,
        update: { property: 'answerNodeId', value: option?.value },
      });
    },
    [partIdentifier, blueprintUpdate]
  );

  const fieldWidthOptions = getOptionsFromEnum<OptionSize>(
    OptionSize,
    'admin.questionnaireManagement.fieldWidthOptions'
  );

  const fieldWidthNumericalDataType = getOptionsFromEnum<NumericalDataTypeEnum>(
    NumericalDataTypeEnum,
    'admin.questionnaireManagement.numericalDataTypeOptions'
  );

  const participantRoleOptions = getOptionsFromEnum<ParticipantRoles>(ParticipantRoles, 'participantRole');

  const onChangeFieldType = useCallback(
    (newValue) => {
      setFieldType(newValue);

      const newValidationOptions = validationOptionsByFieldType[newValue];
      if (newValidationOptions.length && !newValidationOptions.includes(validationType)) {
        // When field type changes update the validation type.
        setValidationType(newValidationOptions[0]);
      }

      if (newValue === QuestionnaireBlueprintFieldTypes.information) {
        setInfoFieldStylingOption(InformationFieldBlueprintVariant.info);
      }

      if (newValue === QuestionnaireBlueprintFieldTypes.signature) {
        setParticipantRole(ParticipantRoles.INSURED);
      }

      void blueprintUpdate({
        partIdentifier,
        update: { property: 'fieldType', value: newValue },
      });
    },
    [blueprintUpdate, partIdentifier, validationOptionsByFieldType, validationType]
  );

  const onTitleBlur = useCallback(() => {
    void blueprintUpdate({
      partIdentifier,
      update: { property: 'title', value: title },
    });
  }, [blueprintUpdate, partIdentifier, title]);

  const onPlaceholderBlur = useCallback(() => {
    void blueprintUpdate({
      partIdentifier,
      update: { property: 'placeholder', value: placeholder },
    });
  }, [blueprintUpdate, partIdentifier, placeholder]);

  if (!blueprint) {
    return null;
  }

  return (
    <Box
      // @ts-ignore ref is missing in Material UI Box typing definitions https://github.com/mui-org/material-ui/issues/17010
      ref={ref}
    >
      {showBlueprintIdInEditor && (
        <Box pb={1}>
          <TextInput label={'Id'} value={blueprint?.id} disabled />
        </Box>
      )}

      <Box pb={1} pr={1}>
        <Grid container alignItems='center' justify='space-between'>
          <Grid item xs={9}>
            <Box pr={1}>
              <SummaryText
                text={t('admin.questionnaireManagement.editFieldHeading', {
                  fieldType: t(`admin.questionnaireManagement.fieldTypes.${blueprint.fieldType}`),
                })}
                hidden={blueprint.hidden}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box pt={1} pb={1} pr={2}>
        <EditorDropdown
          label={t('admin.questionnaireManagement.input.fieldType')}
          selectedOptionValue={fieldType}
          onChange={onChangeFieldType}
          disabled={disabled}
          options={fieldTypeOptions}
        />
      </Box>
      {fieldType === QuestionnaireBlueprintFieldTypes.button && (
        <React.Fragment>
          <Box pt={1} pb={1} pr={2}>
            <NavigatesToNextStepCheckbox
              prefix='edit-field'
              checked={triggerStepNavigation}
              disabled={disabled}
              onChange={(checked: boolean) => {
                setTriggerStepNavigation(checked);
                void blueprintUpdate({
                  partIdentifier,
                  update: { property: 'triggerStepNavigation', value: checked },
                });
              }}
            />
          </Box>
          <Box pt={1} pb={1} pr={2}>
            <TextInput
              label={t('admin.questionnaireManagement.input.buttonText')}
              value={buttonText?.[selectedLanguage] ?? ''}
              disabled={disabled}
              onChange={(event) => {
                const value = event.target.value;
                setButtonText((previousValue) => ({ ...previousValue, [selectedLanguage]: value }));
              }}
              onBlur={() => {
                void blueprintUpdate({
                  partIdentifier,
                  update: { property: 'buttonText', value: buttonText },
                });
              }}
            />
          </Box>
          <Box pt={1} pb={1} pr={2}>
            <IconNameSelector
              value={iconName}
              disabled={disabled}
              onChange={(newIconName) => {
                setIconName(newIconName);
                void blueprintUpdate({
                  partIdentifier,
                  update: { property: 'iconName', value: newIconName },
                });
              }}
              label={t('admin.questionnaireManagement.input.iconName')}
            />
          </Box>
        </React.Fragment>
      )}

      <Box pt={1} pb={1} pr={2}>
        <EditorDropdown
          label={t('admin.questionnaireManagement.input.fieldSize')}
          selectedOptionValue={fieldSize?.toString()}
          disabled={disabled}
          onChange={(value) => {
            const newValue = value ? parseInt(value) : null;
            setFieldSize(newValue);

            const newFieldLayout: FieldLayout = { ...blueprint.layout, size: newValue ?? FieldSizes.full };

            void blueprintUpdate({
              partIdentifier,
              update: { property: 'layout', value: newFieldLayout },
            });
          }}
          options={getFieldLayoutOptions((key: FieldSizes) => t(`admin.questionnaireManagement.fieldSizes.${key}`)).map(
            (e) => ({ ...e, value: e.value.toString() })
          )}
        />
      </Box>
      <Box pt={2} pb={2}>
        <SimpleCheckbox
          id={`forceNewLine-${blueprint.partName}`}
          label={t('admin.questionnaireManagement.input.forceNewLine')}
          checked={forceNewLine ?? false}
          disabled={disabled}
          onChange={(event) => {
            const newValue = event.target.checked;
            setForceNewLine(event.target.checked);

            const newFieldLayout: FieldLayout = { ...blueprint.layout, forceNewLine: newValue };

            void blueprintUpdate({
              partIdentifier,
              update: { property: 'layout', value: newFieldLayout },
            });
          }}
        />
      </Box>

      {fieldType === QuestionnaireBlueprintFieldTypes.number && (
        <Box pt={1} pb={1} pr={2}>
          <EditorDropdown
            label={t('admin.questionnaireManagement.input.numericalDataType')}
            selectedOptionValue={fieldNumericalDataType}
            disabled={disabled}
            onChange={(newValue) => {
              const newNumericalDataType = newValue as NumericalDataTypeEnum;
              setFieldNumericalDataType(newNumericalDataType);
              void blueprintUpdate({
                partIdentifier,
                update: { property: 'numericalDataType', value: newNumericalDataType },
              });
            }}
            options={fieldWidthNumericalDataType}
          />
        </Box>
      )}
      {validationTypeOptions?.length > 1 && (
        <Box pt={1} pb={1} pr={2}>
          <EditorDropdown
            label={t('admin.questionnaireManagement.input.validationType')}
            selectedOptionValue={validationType}
            disabled={disabled}
            onChange={(newValue) => {
              if (newValue !== null) {
                setValidationType(newValue);
                void blueprintUpdate({
                  partIdentifier,
                  update: { property: 'validateAs', value: newValue },
                });
              }
            }}
            options={validationTypeOptions}
          />
        </Box>
      )}

      {fieldType === QuestionnaireBlueprintFieldTypes.signature && (
        <Box pt={1} pb={1} pr={2}>
          <EditorDropdown
            label={t('admin.questionnaireManagement.input.participantRole')}
            selectedOptionValue={participantRole}
            disabled={disabled}
            onChange={(newValue) => {
              const newParticipantRole = newValue as ParticipantRoles;
              setParticipantRole(newParticipantRole);
              void blueprintUpdate({
                partIdentifier,
                update: { property: 'participantRole', value: newParticipantRole },
              });
            }}
            options={participantRoleOptions}
          />
        </Box>
      )}

      <Box pb={2} pr={1} pt={1}>
        <Grid container alignItems='flex-end' justify='space-between'>
          <Grid item xs={9}>
            <NodeIdSelector
              label={t('admin.questionnaireManagement.input.answerStorageNodeId')}
              highlightedSelectedNodeId={highlightedTexts.answerNodeId}
              nodeIds={nodeIds}
              readOnly={!editingNodeId || disabled}
              selectedNodeId={nodeId}
              onChange={handleNodeIdChange}
              selectedLanguage={selectedLanguage}
            />
          </Grid>
          <Grid item>
            {!disabled && (
              <IconButton onClick={() => setEditingNodeId((previousValue) => !previousValue)}>
                {editingNodeId ? <DoneIcon /> : <EditIcon />}
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box pb={1}>
        <SimpleCheckbox
          id={`fieldRequired-${blueprint.partName}`}
          label={t('admin.questionnaireManagement.input.requiredField')}
          checked={isRequiredField}
          disabled={disabled}
          onChange={(event) => {
            setIsRequiredField(event.target.checked);
            void blueprintUpdate({
              partIdentifier,
              update: { property: 'optional', value: !event.target.checked },
            });
          }}
        />
      </Box>
      <Box pt={1} pb={1}>
        <SimpleCheckbox
          id={`fieldDisabled-${blueprint.partName}`}
          label={t('admin.questionnaireManagement.input.disabledField')}
          checked={!!isDisabledField}
          disabled={disabled}
          onChange={(event) => {
            setIsDisabledField(event.target.checked);
            void blueprintUpdate({
              partIdentifier,
              update: { property: 'disabled', value: event.target.checked },
            });
          }}
        />
      </Box>
      <Box pt={1} pr={1}>
        <Grid container>
          <Grid item xs={9}>
            <TextInput
              highlighted={!!highlightedTexts.title}
              label={t('admin.questionnaireManagement.input.fieldTitle')}
              value={title[selectedLanguage] ?? ''}
              disabled={disabled}
              onChange={(event) => {
                const value = event.target.value;
                setTitle((previousValue) => ({ ...previousValue, [selectedLanguage]: value }));
              }}
              onBlur={onTitleBlur}
              helperTooltipText={
                fieldType === QuestionnaireBlueprintFieldTypes.agree
                  ? t(`admin.questionnaireManagement.input.agreeFieldTitleTooltip`)
                  : undefined
              }
            />
          </Grid>
        </Grid>
      </Box>
      <Box pt={1} pb={1} pr={1}>
        <Grid container>
          <Grid item xs={9}>
            <TextInput
              highlighted={!!highlightedTexts.text}
              label={t('admin.questionnaireManagement.input.fieldText')}
              multiline={true}
              value={text[selectedLanguage] ?? ''}
              disabled={disabled}
              onChange={(event) => {
                const value = event.target.value;
                setText((previousValue) => ({ ...previousValue, [selectedLanguage]: value }));
              }}
              onBlur={() => {
                void blueprintUpdate({
                  partIdentifier,
                  update: { property: 'text', value: text },
                });
              }}
              helperTooltipText={
                fieldType === QuestionnaireBlueprintFieldTypes.agree
                  ? t(`admin.questionnaireManagement.input.agreeFieldTextTooltip`)
                  : undefined
              }
            />
          </Grid>
        </Grid>
      </Box>
      {isTextFieldBlueprint(fieldType as QuestionnaireBlueprintFieldTypes.agree) && (
        <Box pt={1} pr={1}>
          <Grid container>
            <Grid item xs={9}>
              <TextInput
                label={t('admin.questionnaireManagement.input.fieldPlaceholder')}
                value={placeholder[selectedLanguage] ?? ''}
                disabled={disabled}
                onChange={(event) => {
                  const value = event.target.value;
                  setPlaceholder((previousValue) => ({ ...previousValue, [selectedLanguage]: value }));
                }}
                onBlur={onPlaceholderBlur}
              />
            </Grid>
          </Grid>
        </Box>
      )}

      {fieldType === QuestionnaireBlueprintFieldTypes.information && (
        <Box pt={1} pb={1} pr={1}>
          <Grid container>
            <Grid item xs={9}>
              <EditorDropdown
                label={t('admin.questionnaireManagement.input.infoFieldStyling')}
                selectedOptionValue={infoFieldStylingOption}
                disabled={disabled}
                onChange={(value) => {
                  if (value !== null) {
                    setInfoFieldStylingOption(value);

                    void blueprintUpdate({
                      partIdentifier,
                      update: { property: 'variant', value: value as InformationFieldBlueprintVariant },
                    });
                  }
                }}
                options={infoFieldStylingOptions}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      {fieldType === QuestionnaireBlueprintFieldTypes.agree && (
        <Box pb={1} pr={1}>
          <AgreeFieldEditor
            disabled={disabled}
            spacing={1}
            languages={[selectedLanguage]}
            consentModalHeader={consentModalHeader}
            consentText={consentText}
            onConsentModalHeaderChange={(value) =>
              setConsentModalHeader((previousValue) => ({ ...previousValue, [selectedLanguage]: value }))
            }
            onConsentTextChange={(value) =>
              setConsentText((previousValue) => ({ ...previousValue, [selectedLanguage]: value }))
            }
            onConsentModalHeaderBlur={() => {
              void blueprintUpdate({
                partIdentifier,
                update: { property: 'modalHeader', value: consentModalHeader },
              });
            }}
            onConsentTextBlur={() => {
              void blueprintUpdate({
                partIdentifier,
                update: { property: 'modalText', value: consentText },
              });
            }}
          />
        </Box>
      )}
      {isAddressAutocompleteFieldBlueprint(blueprint) && (
        <Box pr={2} pt={1} pb={1}>
          <AddressAutocompleteFieldEditor
            blueprint={blueprint}
            partIdentifier={partIdentifier}
            addressAutocompleteFieldTargetOptions={addressAutocompleteFieldTargetOptions}
            disabled={disabled}
            selectedLanguage={selectedLanguage}
            nodeIds={nodeIds}
          />
        </Box>
      )}

      {(fieldType === QuestionnaireBlueprintFieldTypes.number ||
        fieldType === QuestionnaireBlueprintFieldTypes.money) && (
        <Box pr={2} pb={1} pt={1}>
          <NumberFieldDefaultValueEditor
            fieldType={fieldType}
            value={typeof defaultValue === 'number' ? defaultValue : undefined}
            validationType={validationType as Validations}
            disabled={disabled}
            onChange={(value: any) => {
              setDefaultValue(value);
              void blueprintUpdate({
                partIdentifier,
                update: { property: 'defaultValue', value: value },
              });
            }}
            numericalDataType={fieldNumericalDataType}
          />
        </Box>
      )}

      {(fieldType === QuestionnaireBlueprintFieldTypes.dropdown ||
        fieldType === QuestionnaireBlueprintFieldTypes.radio) && (
        <Box pr={2} pb={1} pt={1}>
          <EditorDropdown
            label={t('admin.questionnaireManagement.input.defaultValue')}
            selectedOptionValue={(defaultValue as string) || NO_DEFAULT}
            disabled={disabled}
            onChange={(value?: string | null) => {
              if (value === NO_DEFAULT) {
                value = undefined;
              }
              onUpdateDefaultValue(value);
            }}
            options={
              isSelectOptionFieldBlueprint(blueprint)
                ? [
                    { label: t('admin.questionnaireManagement.input.noDefault'), value: NO_DEFAULT },
                    ...(blueprint.selectOptions ?? []).map((selectOption) => ({
                      label: selectOption.text[selectedLanguage] || '',
                      value: selectOption.partName,
                    })),
                  ]
                : undefined
            }
          />
        </Box>
      )}

      {fieldType === QuestionnaireBlueprintFieldTypes.checkbox && (
        <Box pr={2} pb={1} pt={1}>
          <BooleanFieldDefaultValueEditor
            onChange={(value) => {
              onUpdateDefaultValue(value);
            }}
            value={typeof defaultValue === 'boolean' && defaultValue}
            disabled={disabled}
          />
        </Box>
      )}

      {displayAsCard && (
        <Box pt={2} pb={1}>
          <SimpleCheckbox
            id={`displayInCardPreview-${blueprint.partName}`}
            label={t('admin.questionnaireManagement.input.displayInCardPreview')}
            checked={displayInCardPreview}
            disabled={disabled}
            onChange={(event) => {
              setDisplayInCardPreview(event.target.checked);
              void blueprintUpdate({
                partIdentifier,
                update: { property: 'displayInCardPreview', value: event.target.checked },
              });
            }}
          />
        </Box>
      )}

      <ConditionsEditor
        questionnaireNodeIds={questionnaireNodeIds}
        collectionContext={collectionContext}
        condition={blueprint.visible}
        isReadOnly={disabled}
        label={t('admin.questionnaireManagement.rules.visibility.conditions')}
        editHeadingText={t('admin.questionnaireManagement.rules.visibility.edit')}
        selectedLanguage={selectedLanguage}
        saveCondition={(condition) => {
          void blueprintUpdate({
            partIdentifier,
            update: { property: 'visible', value: condition },
          });
        }}
      />

      <ValidationConditionsEditor
        validationConditions={blueprint.valid}
        questionnaireNodeIds={questionnaireNodeIds}
        collectionContext={collectionContext}
        isReadOnly={disabled}
        selectedLanguage={selectedLanguage}
        save={(validationConditions) => {
          void blueprintUpdate({
            partIdentifier,
            update: { property: 'valid', value: validationConditions },
          });
        }}
      />

      <InfoSupplementEditor
        selectedLanguage={selectedLanguage}
        isReadOnly={disabled}
        save={(infoSupplement) => {
          void blueprintUpdate({
            partIdentifier,
            update: { property: 'infoSupplement', value: infoSupplement },
          });
        }}
        infoSupplement={blueprint.infoSupplement}
      />

      <Box pr={2}>
        <AdvancedBlueprintOptions
          tag='field'
          platform={{ selected: platforms, onChange: setPlatforms }}
          renderOn={{ selected: renderOn, onChange: setRenderOn }}
          scope={{ selected: scopes, onChange: setScopes }}
          applicationModes={{
            selected: applicationModes,
            onChange: (updatedModes) => {
              void blueprintUpdate({
                partIdentifier,
                update: { property: 'applicationModes', value: updatedModes },
              });
              setApplicationModes(updatedModes);
            },
          }}
          dataLabel={{
            selected: dataLabel,
            options: dataLabelOptions,
            onChange: (value) => {
              if (value !== null) {
                setDataLabel(value);
              }
            },
          }}
          copyable={{
            selected: copyable,
            disabled: disableCopyable,
            onChange: (value) => {
              if (value !== null) {
                setCopyable(value as QuestionnaireBlueprintCopyableOption);
              }
            },
          }}
          disabled={disabled}
        />
      </Box>

      {fieldType === QuestionnaireBlueprintFieldTypes.radio && (
        <Box pt={2} pb={1} pr={2}>
          <EditorDropdown
            label={'Option Size'}
            selectedOptionValue={fieldOptionWidth}
            disabled={disabled}
            onChange={(newValue) => {
              const newOptionSize = newValue as OptionSize;
              setFieldOptionsWidth(newOptionSize);

              void blueprintUpdate({
                partIdentifier,
                update: { property: 'optionSize', value: newOptionSize },
              });
            }}
            options={fieldWidthOptions}
          />
        </Box>
      )}

      <Box pr={2} pt={1}>
        {selectOptionEditors}
      </Box>

      {isSelectOptionFieldBlueprint(blueprint) && (!disabled || !!dynamicOptions) && (
        <Box pr={2} pt={1}>
          <DynamicOptionsEditor
            isReadOnly={disabled}
            partName={blueprint.partName}
            collectionContext={collectionContext}
            questionnaireNodeIds={questionnaireNodeIds}
            disabled={disabled}
            selectedLanguage={selectedLanguage}
            dynamicOptions={dynamicOptions}
            onChange={(dynamicOptionsBlueprint: DynamicOptionsBlueprint | undefined) => {
              setDynamicOptions(dynamicOptionsBlueprint);
              void blueprintUpdate({
                partIdentifier,
                update: { property: 'dynamicOptions', value: dynamicOptionsBlueprint },
              });
            }}
            onSaveCondition={(condition?: BlueprintConditionValue) => {
              if (blueprint.dynamicOptions) {
                setDynamicOptions((prevState) => {
                  if (prevState)
                    return {
                      ...prevState,
                      visible: condition,
                    };
                  return prevState;
                });
                void blueprintUpdate({
                  partIdentifier,
                  update: { property: 'dynamicOptions', value: { ...blueprint.dynamicOptions, visible: condition } },
                });
              }
            }}
          />
        </Box>
      )}
    </Box>
  );
});

function SelectOptionEditors(props: {
  blueprint: SelectOptionFieldBlueprint;
  partIdentifier: FieldPartIdentifier;
  selectedLanguage: Language;
  disabled: boolean;
  defaultValue?: string | string[];
  onUpdateDefaultValue: (newDefaultValue: any) => void;
  questionnaireNodeIds: QuestionnaireNodeIds;
  collectionContext: string[];
}): React.ReactElement {
  const {
    blueprint,
    partIdentifier,
    selectedLanguage,
    disabled,
    defaultValue,
    onUpdateDefaultValue,
    questionnaireNodeIds,
    collectionContext,
  } = props;
  const { t } = useTranslation();
  const { questionnaireVersionId } = useContext(QuestionnaireVersionDataContext);

  const addBlueprint = useAddQuestionnaireElementBlueprint(questionnaireVersionId);
  const reorderBlueprint = useUpdateQuestionnaireElementBlueprintOrder(questionnaireVersionId);

  const optionIds = blueprint.selectOptions.map((optionBlueprint) => optionBlueprint.partName);

  function toggleOptionDefaultValueState(partName: string): void {
    let newDefaultValue: string[] = [];
    if (defaultValue) {
      newDefaultValue = [...defaultValue];
    }
    if (newDefaultValue.includes(partName)) {
      newDefaultValue = newDefaultValue.filter((item) => item !== partName);
    } else {
      newDefaultValue.push(partName);
    }

    onUpdateDefaultValue(newDefaultValue);
  }

  const selectOptionEditors = blueprint.selectOptions.map((optionBlueprint, idx) => {
    const selectOptionPartIdentifier: SelectOptionPartIdentifier = {
      selectOptionPartName: optionBlueprint.partName,
      fieldPartName: partIdentifier.fieldPartName,
      questionPartName: partIdentifier.questionPartName,
      subsectionPartName: partIdentifier.subsectionPartName,
      sectionPartName: partIdentifier.sectionPartName,
      sectionGroupPartName: partIdentifier.sectionGroupPartName,
      tag: PartIdentifierTag.selectOption,
    };

    const isDefaultValue =
      defaultValue === optionBlueprint.partName ||
      (Array.isArray(defaultValue) && defaultValue.includes(optionBlueprint.partName));

    return (
      <Draggable
        draggableId={`${optionBlueprint.partName}`}
        index={idx}
        key={optionBlueprint.partName}
        isDragDisabled={disabled || blueprint.selectOptions.length <= 1}
      >
        {(provided: DraggableProvided) => (
          <SelectOptionBlueprintEditor
            ref={provided.innerRef}
            key={optionBlueprint.partName}
            blueprint={optionBlueprint}
            partIdentifier={selectOptionPartIdentifier}
            selectedLanguage={selectedLanguage}
            parentHidden={disabled}
            draggableProps={provided.draggableProps}
            dragHandleProps={provided.dragHandleProps}
            isDraggable={blueprint.selectOptions.length > 1}
            isDefaultValue={isDefaultValue}
            defaultValueButtonProps={
              blueprint.fieldType === QuestionnaireBlueprintFieldTypes.checkboxGroup
                ? { onClick: () => toggleOptionDefaultValueState(optionBlueprint.partName) }
                : undefined
            }
            questionnaireNodeIds={questionnaireNodeIds}
            collectionContext={collectionContext}
            condition={optionBlueprint.visible}
            existingOptionIds={optionIds}
          />
        )}
      </Draggable>
    );
  });

  const [draggedField, setDraggedField] = useState<string | undefined>(undefined);

  return (
    <React.Fragment>
      <Typography variant='body1'>{t('admin.questionnaireManagement.input.options')}</Typography>
      <DragDropContext
        onDragStart={(dragStart: DragStart) => setDraggedField(dragStart.draggableId)}
        onDragEnd={(dropResult: DropResult) => {
          if (
            !draggedField ||
            typeof dropResult?.destination?.index === 'undefined' ||
            blueprint.selectOptions[dropResult.destination.index].partName === draggedField
          ) {
            return;
          }

          const destinationIndex = dropResult.destination.index;
          const previousSiblingIndex = destinationIndex - 1;
          const previousSibling = previousSiblingIndex >= 0 ? blueprint.selectOptions[previousSiblingIndex] : undefined;

          void reorderBlueprint({
            sourcePartIdentifier: {
              ...partIdentifier,
              selectOptionPartName: draggedField,
              tag: PartIdentifierTag.selectOption,
            },
            targetParentPartIdentifier: partIdentifier,
            targetSiblingPartIdentifier: previousSibling
              ? {
                  ...partIdentifier,
                  selectOptionPartName: previousSibling.partName,
                  tag: PartIdentifierTag.selectOption,
                }
              : undefined,
          });
        }}
      >
        <Droppable droppableId={`${blueprint.partName}-droppable`}>
          {(provided: DroppableProvided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {selectOptionEditors}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Box pt={1}>
        <CreateSelectOption
          onCreate={(selectOptionBlueprint) => addBlueprint(selectOptionBlueprint, partIdentifier)}
          existingOptionIds={optionIds}
          disabled={disabled}
        />
      </Box>
    </React.Fragment>
  );
}
