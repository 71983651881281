import {
  isFieldPartIdentifier,
  isQuestionPartIdentifier,
  isSectionPartIdentifier,
  isSelectOptionPartIdentifier,
  isSubsectionPartIdentifier,
  PartIdentifier,
  PartIdentifierTag,
} from '@breathelife/types';

import { useQuestionnaireVersionDetailWithNodeIdInfo } from '../../QuestionnaireVersionDataContextProvider';

type RepeatableNodeIdInformation = {
  repeatableNodeId: string;
  partIdentifierTag: PartIdentifierTag;
};

export function useElementRepeatableNodeIdInParentsAndSelf(
  partIdentifier: PartIdentifier,
  order: 'closestFirst' | 'closestLast' = 'closestLast'
): RepeatableNodeIdInformation[] {
  const { blueprint: questionnaire } = useQuestionnaireVersionDetailWithNodeIdInfo() || {};
  const repeatableNodeIds: RepeatableNodeIdInformation[] = [];

  if (!questionnaire) {
    return repeatableNodeIds;
  }
  if (
    isSectionPartIdentifier(partIdentifier) ||
    isSubsectionPartIdentifier(partIdentifier) ||
    isQuestionPartIdentifier(partIdentifier) ||
    isFieldPartIdentifier(partIdentifier) ||
    isSelectOptionPartIdentifier(partIdentifier)
  ) {
    const elementSection = questionnaire.sectionBlueprints.find(
      (section) => section.partName === partIdentifier.sectionPartName
    );
    const elementSectionGroupRepeatableNodeId = elementSection
      ? questionnaire.sectionGroupBlueprints[elementSection.sectionGroupKey]?.repeatable?.repeatableAnswerNodeId
      : undefined;
    if (elementSectionGroupRepeatableNodeId) {
      repeatableNodeIds.push({
        repeatableNodeId: elementSectionGroupRepeatableNodeId,
        partIdentifierTag: PartIdentifierTag.sectionGroup,
      });
    }

    if (
      isFieldPartIdentifier(partIdentifier) ||
      isSelectOptionPartIdentifier(partIdentifier) ||
      isQuestionPartIdentifier(partIdentifier)
    ) {
      const subsectionBlueprint = elementSection?.subsections.find(
        (subsection) => subsection.partName === partIdentifier.subsectionPartName
      );
      const questionBlueprint = subsectionBlueprint
        ? subsectionBlueprint.questions.find((question) => question.partName === partIdentifier.questionPartName)
        : undefined;

      if (questionBlueprint?.repeatable?.repeatableAnswerNodeId) {
        const nodeIdInformation = {
          repeatableNodeId: questionBlueprint.repeatable.repeatableAnswerNodeId,
          partIdentifierTag: PartIdentifierTag.question,
        };
        if (order === 'closestFirst') {
          repeatableNodeIds.unshift(nodeIdInformation);
        } else {
          repeatableNodeIds.push(nodeIdInformation);
        }
      }
    }
  }

  return repeatableNodeIds;
}
