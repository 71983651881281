import _ from 'lodash';

import { PlatformFeatures } from '../Models/PlatformFeatures';

export function buildPlatformFeaturesConfig(
  featuresConfig: PlatformFeatures,
  featuresConfigFromFeatureFlags: Partial<PlatformFeatures> = {}
): PlatformFeatures {
  return _.merge({}, featuresConfig, featuresConfigFromFeatureFlags);
}
