import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExistingLeadAndApplicationPreview, QuestionnairePreview } from '@breathelife/types';

import { useDispatch } from '../../../../../Hooks';
import { notificationSlice } from '../../../../../Redux/Notification/NotificationSlice';
import ApiService from '../../../../../Services/ApiService';

export function useQuestionnairePreviewApplication({
  isNeedsAnalysisEnabled,
  isExternalNeedsAnalysisEnabled,
  questionnaireVersionId,
  selectedOption,
}: {
  isNeedsAnalysisEnabled: boolean;
  isExternalNeedsAnalysisEnabled?: boolean;
  selectedOption: QuestionnairePreview | null;
  questionnaireVersionId?: string;
}): ExistingLeadAndApplicationPreview | null {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [existingApplication, setExistingApplication] = useState<ExistingLeadAndApplicationPreview | null>(null);

  useEffect(() => {
    if ((!isNeedsAnalysisEnabled && !isExternalNeedsAnalysisEnabled) || !questionnaireVersionId) return;

    if (selectedOption !== QuestionnairePreview.assistedApplication && selectedOption !== QuestionnairePreview.pdf) {
      setExistingApplication(null);
      return;
    }

    const fetchExistingPreviewLeads = async (): Promise<void> => {
      try {
        const { data } = await ApiService.admin.getQuestionnairePreviewApplicationData({
          questionnaireVersionId,
          needsAnalysis: true,
          previewType: selectedOption,
        });

        if (data) {
          setExistingApplication(data);
        }
      } catch (error) {
        dispatch(
          notificationSlice.actions.setError({
            message: 'Failed',
          })
        );
      }
    };

    void fetchExistingPreviewLeads();
  }, [t, dispatch, isNeedsAnalysisEnabled, questionnaireVersionId, selectedOption, isExternalNeedsAnalysisEnabled]);

  return existingApplication;
}
