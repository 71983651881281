import Box from '@material-ui/core/Box';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

import { Permission } from '@breathelife/types';
import { Loader } from '@breathelife/ui-components';

import Typography from '../Components/Typography';
import { userHasPermission } from '../Helpers/user';
import { useDispatch, useSelector } from '../Hooks';
import Urls from '../Navigation/Urls';
import { getUser } from '../Redux/Authentication/UserOperations';
import { isTokenExpired } from '../Services/Auth0';
import { PrivateRouteErrorContainer } from './Styles';

type Props = RouteProps;

function PrivateRoute(props: Props & { permissions?: Permission | Permission[] }): React.ReactElement | null {
  const authentication = useSelector((store) => store.leadPlatform.authentication);
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const [fetchingNewUserFormat, setFetchingNewUserFormat] = useState(false);

  const userPermissions = authentication.user?.permissions;
  const isAuthorized = authentication.isAuthenticated && authentication.token && !isTokenExpired(authentication.token);
  const isOldUserFormat = isAuthorized && !authentication.user?.auth0Id;
  const isLoading = useSelector((store) => store.leadPlatform.authentication.isLoading);

  useEffect(() => {
    // If ever `authentication.user.id` is invalid, it will cause an infinite loop, spamming our API
    // since `getUser` will fail and modifies the authentication.isLoading which causes a re-render.
    // We need to protect ourself against that.
    if (isOldUserFormat && authentication.user && !isLoading && !fetchingNewUserFormat) {
      setFetchingNewUserFormat(true);
      dispatch(getUser(authentication.user.id));
    }
  }, [dispatch, isOldUserFormat, authentication.user, isLoading, fetchingNewUserFormat]);

  if (!isAuthorized) {
    const { pathname, search, hash } = location;
    const pathQueryHash = `${pathname}${search}${hash}`;

    return (
      <Redirect
        to={{
          pathname: Urls.login,
          search, // Keep any incoming query strings
          state: { targetRoute: pathQueryHash },
        }}
      />
    );
  }

  // The user is currently logged in and has the old
  // user type in their store. We should fetch the
  // user data in the new format and save it in the store.
  if (isOldUserFormat && authentication.user) {
    if (isLoading) {
      return (
        <Box height='100vh' display='flex' justifyContent='center' alignItems='center'>
          <Loader />
        </Box>
      );
    }

    return (
      <Box height='100vh' display='flex'>
        <PrivateRouteErrorContainer margin='auto' p={4}>
          <Typography component='p' variant='body1'>
            {t('authentication.generalErrorText')}
          </Typography>
        </PrivateRouteErrorContainer>
      </Box>
    );
  }

  if (props.permissions && userPermissions) {
    const allowedPermissions = !_.isArray(props.permissions) ? [props.permissions] : props.permissions;
    const hasPermissions = userHasPermission(allowedPermissions, userPermissions);
    if (!hasPermissions) {
      return <Redirect to={{ pathname: Urls.home }} />;
    }
  }

  return <Route {...props} />;
}

export default PrivateRoute;
