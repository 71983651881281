import i18next from 'i18next';

import { createCryptoMaterial, CryptoMaterial, SubmitResponse } from '@breathelife/crypto-material';
import { hash } from '@breathelife/hash';
import { getDocumentFormat, TypewriterTracking } from '@breathelife/react-tracking';
import { ApplicationMode, ESignSigner2FAInfo, SignatureType } from '@breathelife/types';

import { Application } from '../../Models/Application';
import { SendESignCeremonyMutation } from '../../ReactQuery/ESignCeremony/eSignCeremony.mutations';
import { identityVerificationSlice } from '../../Redux/IdentityVerification/IdentityVerificationSlice';
import { Dispatch, LeadPlatformStore } from '../../Redux/types';
import {
  createAttachment,
  deleteAttachment as deleteAttachmentService,
  fetchAttachments as fetchAttachmentsService,
} from '../../Services/ApplicationsAttachmentsService';
import * as SubmissionService from '../../Services/SubmissionService';
import { notificationSlice } from '../Notification/NotificationSlice';
import { submissionSlice } from '../Submission/SubmissionSlice';
import { applicationSlice } from './ApplicationSlice';

const { actions: applicationActions } = applicationSlice;
const { actions: submissionActions } = submissionSlice;

const submitInsuranceApplication =
  (leadApplication: Application, productQuote?: number | null) =>
  async (dispatch: Dispatch): Promise<SubmitResponse | undefined> => {
    dispatch(submissionActions.setIsSubmitting(true));

    const submissionResponse = await SubmissionService.submitInsuranceApplication(leadApplication.id, productQuote);

    if (!submissionResponse) {
      return;
    }

    return submissionResponse;
  };

const finishSubmissionInsuranceApplication =
  (leadApplication: Application) => async (): Promise<SubmitResponse | undefined> => {
    const submissionResponse = await SubmissionService.finishSubmissionInsuranceApplication(leadApplication.id);

    if (!submissionResponse) {
      return;
    }

    return submissionResponse;
  };

// this is probably what we need to separate
const digitallySignInsuranceApplication =
  (leadApplication: Application, digest?: string) => async (): Promise<Application> => {
    // if submissionResponse is null at this point, then the application is already
    // submitted and we can use the userReportDigest in the application directly.
    digest = digest ?? leadApplication.private?.userReportDigest;

    if (!digest) {
      throw new Error('digest is missing for signing the application');
    }

    const cryptoMaterial = generateCryptoMaterial(digest);
    const signatureResponse = await SubmissionService.signApplicationUsingCrypto(leadApplication.id, cryptoMaterial);

    return signatureResponse.application;
  };

function generateCryptoMaterial(digest: string): CryptoMaterial {
  try {
    return createCryptoMaterial({ digest });
  } catch (err) {
    throw new Error('Failed to create Crypto Material');
  }
}

const submitApplicationForExternalSignature = (application: Application) => async (): Promise<Application> => {
  const signatureResponse = await SubmissionService.submitApplicationForExternalSignature(application.id);
  return signatureResponse.application;
};

const processApplicationSignatures =
  (application: Application, submissionData: SubmissionData, submissionResponse: SubmitResponse | undefined) =>
  async (dispatch: Dispatch): Promise<Application | undefined> => {
    let updatedApplication: Application | undefined;
    // TypeScript loses the connection between initial discriminated union type and destructured constants so signatureType cannot be deconstructed
    switch (submissionData.signatureType) {
      case SignatureType.cryptoSignature:
        updatedApplication = await dispatch(digitallySignInsuranceApplication(application, submissionResponse?.digest));
        break;

      case SignatureType.eSignature:
        if (application.isPreview) {
          updatedApplication = await dispatch(
            digitallySignInsuranceApplication(application, submissionResponse?.digest)
          );
          break;
        }

        await submissionData.sendESignCeremonyMutation.mutateAsync({
          applicationId: application.id,
          signers: submissionData.signers,
        });

        break;

      case SignatureType.externalSignature:
        updatedApplication = await dispatch(submitApplicationForExternalSignature(application));
        break;

      default:
        throw new Error('Signature operation not yet implemented!');
    }

    return updatedApplication;
  };

type SubmissionData = {
  application: Application;
  productQuote?: number | null;
} & SignatureData;

type SignatureData =
  | {
      signatureType: SignatureType.cryptoSignature | SignatureType.externalSignature;
    }
  | {
      signatureType: SignatureType.eSignature;
      signers: ESignSigner2FAInfo[];
      sendESignCeremonyMutation: SendESignCeremonyMutation;
    };

/**
 * Switcher operation that submits the application and takes the appropriate next step that corresponds to
 * the signature configuration. This should be the only submission operation that is exposed to the lead-platform UI.
 */
export const processApplicationSubmission =
  (submissionData: SubmissionData) =>
  async (dispatch: Dispatch): Promise<Application | undefined> => {
    let submissionResponse: SubmitResponse | undefined;

    const { application: applicationParam, productQuote } = submissionData;

    let application: Application = applicationParam;

    const isPaperApp = application.mode === ApplicationMode.paper;

    try {
      if (!application.submitted || isPaperApp) {
        TypewriterTracking.initiatedApplicationSubmission({
          hashedId: hash(application.id),
        });

        submissionResponse = await dispatch(submitInsuranceApplication(application, productQuote));

        if (isPaperApp) {
          await dispatch(finishSubmissionInsuranceApplication(application));
        }

        application = submissionResponse?.application;
      }

      if (application.submitted && !application.signed && !isPaperApp) {
        const updatedApplication = await dispatch(
          processApplicationSignatures(application, submissionData, submissionResponse)
        );
        if (updatedApplication) {
          application = updatedApplication;
        }

        /*
        The submissionResponse state is the pointOfSaleDecisionOutcome (POS) returned from our signature & submission endpoints. For certain carriers (e.g. 1891)
        we calculate the POS outcome at a different stage (after all parties have signed) and so the POS outcome is not available at this step.
        This is handled in the PATCH operation of the submission endpoint.
        */
        const pointOfSaleDecisionOutcome = submissionResponse?.state;
        if (pointOfSaleDecisionOutcome) {
          TypewriterTracking.applicationSubmissionSuccessful({
            hashedId: hash(application.id),
            //TODO: As part of https://breathelife.atlassian.net/browse/DEV-12698 , this will need to be changed after the multi-insured support is added.
            outcome: pointOfSaleDecisionOutcome,
            // Similar to consumer-flow implementation, these are hard coded to 1 until
            // we have multi-product and multi-insured features available.
            insuredPersonCount: 1,
            productCount: 1,
            source: 'lead-platform',
          });
        }
      }

      dispatch(submissionActions.setIsFailure(false));
      dispatch(submissionActions.setIsSuccess(true));
    } catch (error) {
      // if we ever need to pass some reason of the failure, this should be set in the failure payload so that
      // the view can react to it.
      dispatch(submissionActions.setIsSuccess(false));
      dispatch(submissionActions.setIsFailure(true));

      if (error.response?.data?.code === 409) {
        dispatch(
          notificationSlice.actions.setError({
            message: i18next.t('notifications.failedToSubmitApplicationRefreshPage'),
            autoHideDuration: 5000,
          })
        );
      } else if (error.response?.data?.code === 414) {
        dispatch(
          notificationSlice.actions.setError({
            title: i18next.t('notifications.failedToValidateRequestTitle'),
            message: i18next.t('notifications.failedToValidateRequest'),
            autoHideDuration: 5000,
          })
        );
        dispatch(identityVerificationSlice.actions.reset());
      } else {
        dispatch(
          notificationSlice.actions.setError({
            message: i18next.t('notifications.failedToSubmitApplication'),
            autoHideDuration: 5000,
          })
        );
      }
    } finally {
      dispatch(submissionActions.setIsSubmitting(false));
    }

    return application;
  };

// A preview application is an application that was created with the intention
// to be used in the context of the questionnaire editor's preview feature.
export const processPreviewApplicationSubmission =
  (submissionData: Pick<SubmissionData, 'application' | 'productQuote'>) =>
  async (dispatch: Dispatch): Promise<Application | undefined> => {
    const { application, productQuote } = submissionData;

    let submissionResponse: SubmitResponse | undefined;
    let updatedApplication: Application | undefined;

    try {
      if (!application.submitted) {
        submissionResponse = await dispatch(submitInsuranceApplication(application, productQuote));
        updatedApplication = submissionResponse?.application;
      }

      if (!application.signed) {
        const dataWithSignatureType: SubmissionData = {
          ...submissionData,
          // Regardless of the signature type, we will force preview applications to go through
          // the crypto signature type. This signature type also has the simplest path to pdf generation
          // (relative to the esign signature).
          signatureType: SignatureType.cryptoSignature,
        };
        updatedApplication = await dispatch(
          processApplicationSignatures(application, dataWithSignatureType, submissionResponse)
        );
      }

      dispatch(submissionActions.setIsFailure(false));
      dispatch(submissionActions.setIsSuccess(true));
    } catch (e) {
      dispatch(submissionActions.setIsSuccess(false));
      dispatch(submissionActions.setIsFailure(true));
    } finally {
      dispatch(submissionActions.setIsSubmitting(false));
    }

    return updatedApplication;
  };

export const uploadAttachment =
  (applicationId: string, file: File) =>
  async (dispatch: Dispatch, store: () => LeadPlatformStore): Promise<void> => {
    dispatch(applicationActions.setIsUploadingAttachment(true));

    try {
      const attachment = await createAttachment({
        applicationId,
        file,
      });

      // TODO DEV-7166 - prevent merging the result if the current attachments is not associated with the provided application id
      const storedAttachments = store().leadPlatform.application.attachments;
      dispatch(applicationActions.setAttachments(storedAttachments.concat([attachment])));

      TypewriterTracking.attachmentAddedSuccessully({
        attachmentId: attachment.id,
        documentFormat: getDocumentFormat(file),
        hashedId: hash(applicationId),
      });
    } catch (err) {
      dispatch(
        notificationSlice.actions.setError({
          message: i18next.t('notifications.failedToUploadAttachment'),
          autoHideDuration: 5000,
        })
      );

      TypewriterTracking.attachmentErrorOccurred({
        hashedId: hash(applicationId),
        documentFormat: getDocumentFormat(file),
        error: 'Failed to upload attachment',
      });
    } finally {
      dispatch(applicationActions.setIsUploadingAttachment(false));
    }
  };

export const fetchAttachments =
  (applicationId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const attachments = await fetchAttachmentsService(applicationId);
      dispatch(applicationActions.setApplicationId(applicationId));
      dispatch(applicationActions.setAttachments(attachments));
    } catch (err) {
      dispatch(
        notificationSlice.actions.setError({
          message: i18next.t('notifications.failedToFetchAttachments'),
          autoHideDuration: 5000,
        })
      );
    }
  };

export const deleteAttachment =
  (attachmentId: string) =>
  async (dispatch: Dispatch, store: () => LeadPlatformStore): Promise<void> => {
    try {
      await deleteAttachmentService(attachmentId);
      const storedAttachments = store().leadPlatform.application.attachments;
      dispatch(
        applicationActions.setAttachments(storedAttachments.filter((attachment) => attachment.id !== attachmentId))
      );
    } catch (err) {
      dispatch(
        notificationSlice.actions.setError({
          message: i18next.t('notifications.failedToDeleteAttachment'),
          autoHideDuration: 5000,
        })
      );
    }
  };
