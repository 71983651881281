import i18next from 'i18next';

export type SelectOptions<T> = { value: T; label: string }[];

export function getOptionsFromEnum<T>(enumObject: Record<string, T>, translationBasePath?: string): SelectOptions<T> {
  return Object.values(enumObject).map((value) => {
    return {
      value: value,
      label: i18next.t(translationBasePath ? `${translationBasePath}.${value}` : `${value}`),
    };
  });
}
