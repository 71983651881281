import { Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as WelcomeSVG } from '../../../Assets/welcome.svg';
import { Button } from '../../../Components/Button/Button';
import Typography from '../../../Components/Typography';
import { ModalLayout } from '../../../Layouts/Modal/ModalLayout';

export type Props = {
  isOpen: boolean;
  onSubmit: () => void;
};

export const PromptCompleteProfileModal: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  return (
    <ModalLayout maxWidth='sm' confirmationRequired isOpen={props.isOpen}>
      <Box textAlign='center'>
        <WelcomeSVG />
        <Box mt={3} mb={2}>
          <Typography variant='h1' grey={90}>
            {t('modals.promptCompleteProfile.welcome')}
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant='body1' grey={90}>
            {t('modals.promptCompleteProfile.fullAccess')}
          </Typography>
        </Box>
        <Box mb={2}>
          <Button
            data-testid='promptCompleteProfile-submit'
            color='primary'
            variant='contained'
            onClick={props.onSubmit}
          >
            {t('modals.promptCompleteProfile.submit')}
          </Button>
        </Box>
      </Box>
    </ModalLayout>
  );
};
