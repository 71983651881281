import Box from '@material-ui/core/Box';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ESignSigner2FAInfo, ParticipantRoles } from '@breathelife/types';
import { Input as TextInput, PhoneInput } from '@breathelife/ui-components';

import { Icon } from '../../../../../Components/Icons';
import { getFieldValidationError, SignerFormField } from '../../../../../Helpers/inputValidation/form/signer';
import { SignerInfoHeader } from './SignerInfoHeader';

type SignerInfoFormProps = {
  disabled: boolean;
  signer: ESignSigner2FAInfo;
  onUpdateSigner: (signer: ESignSigner2FAInfo) => void;
};

export function SignerInfoForm(props: SignerInfoFormProps): React.ReactElement {
  const { t } = useTranslation();
  const { disabled, signer, onUpdateSigner } = props;

  const emailError = getFieldValidationError(SignerFormField.email, signer.email);
  const phoneNumberError = getFieldValidationError(SignerFormField.cellphone, signer.cellphone);

  const onEmailChange = useCallback(
    (email: string) => {
      onUpdateSigner({
        ...signer,
        email,
      });
    },
    [onUpdateSigner, signer]
  );

  const onPhoneNumberChange = useCallback(
    (cellphone: string) => {
      onUpdateSigner({
        ...signer,
        cellphone,
      });
    },
    [onUpdateSigner, signer]
  );

  return (
    <div>
      <SignerInfoHeader signer={signer} />
      <Box px={2}>
        <Box mb={2} maxWidth={310}>
          <TextInput
            fullWidth
            required
            inputVariant='outlined'
            label={t('inputs.email')}
            error={!!emailError}
            validationError={emailError}
            value={signer.email}
            disabled={disabled}
            onChange={(event) => onEmailChange(event.target.value)}
            inputTooltip={
              signer.roles.some((role) => role.type === ParticipantRoles.AGENT)
                ? {
                    icon: <Icon name='info' variant='primary' width='16' height='16' />,
                    text: t('assistedApplication.eSignatureDetails.emailInfo'),
                  }
                : undefined
            }
          />
        </Box>
        <Box maxWidth={150}>
          <PhoneInput
            name='signerInfo-phoneNumber'
            required
            label={t('inputs.phoneNumber')}
            inputVariant='outlined'
            validationError={phoneNumberError}
            value={signer.cellphone}
            disabled={disabled}
            onAnswerChange={(answer: string) => onPhoneNumberChange(answer)}
          />
        </Box>
      </Box>
    </div>
  );
}
