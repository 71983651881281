import Box from '@material-ui/core/Box';
import MuiCheckbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import { createStyles, StyleRules, withStyles, WithStyles } from '@material-ui/core/styles';
import React, { useContext, useMemo } from 'react';
import { ThemeContext } from 'styled-components';

import checkboxOutlineBlankIcon from '../../Assets/checkbox-outline-blank-icon.svg';
import Typography from '../../Components/Typography';
import { LeadPlatformTheme } from '../../Styles/Types';

type FormControlLabelProps = {
  label?: string;
};

type CheckboxGroupProps = {
  label?: string;
  children: React.ReactNode;
  validationError?: string;
};

const buildMuiStyles = (theme: LeadPlatformTheme): StyleRules =>
  createStyles({
    label: {
      fontSize: theme.text.body3.size,
      fontWeight: theme.text.body3.weight,
    },
  });

const UnstyledCheckbox = (
  props: CheckboxProps & FormControlLabelProps & WithStyles<typeof buildMuiStyles>
): React.ReactElement => (
  <FormControlLabel
    classes={props.classes}
    control={
      <MuiCheckbox
        icon={<img src={checkboxOutlineBlankIcon} alt='' />}
        checked={props.checked}
        onChange={props.onChange}
        disabled={props.disabled}
        color={props.color}
      />
    }
    label={props.label ?? ''}
  />
);

export const Checkbox = (props: CheckboxProps & FormControlLabelProps): React.ReactElement => {
  const theme = useContext(ThemeContext);
  const StyledCheckbox = useMemo(() => withStyles(buildMuiStyles(theme))(UnstyledCheckbox), [theme]);
  return (
    <StyledCheckbox
      label={props.label}
      checked={props.checked}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      color={props.color}
      disabled={props.disabled}
    />
  );
};

export function CheckboxGroup(props: CheckboxProps & CheckboxGroupProps): React.ReactElement {
  return (
    <FormControl component='fieldset' fullWidth>
      {props.label && (
        <Box mb={1}>
          <FormLabel component='legend'>
            <Typography variant='body1' grey={70}>
              {props.label}
            </Typography>
          </FormLabel>
        </Box>
      )}
      {props.children}
      {props.validationError && <FormHelperText error>{props.validationError}</FormHelperText>}
    </FormControl>
  );
}
