import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MathConditionOperator, MathOperatorConditionValue } from '@breathelife/types';
import { SelectMui } from '@breathelife/ui-components';

import {
  getConditionValidationError,
  ValidationValues,
} from '../../../Helpers/inputValidation/form/salesDecisionRules';
import { getOptionsFromEnum } from '../../../Helpers/options';
import { CollectionOperators } from '../Helpers/CollectionOperators';
import { ConditionTargetNodeAutocompleteMultiple } from '../Helpers/ConditionTargetNodeAutocompleteMultiple';
import { NumberComparisonConditionOperatorSelect } from '../Helpers/NumberComparisonConditionOperatorSelect';
import { NumberValueOrNodeIdInput } from '../Helpers/NumberValueOrNodeIdInput';
import { ValidationErrorText } from '../ValidationErrorText';
import { ConditionCriteriaProps } from '../types';

function MathConditionOperatorSelect(props: {
  value: MathConditionOperator;
  onChange: (value: MathConditionOperator) => void;
}): React.ReactElement {
  const { value, onChange } = props;

  const { t } = useTranslation();

  const options = getOptionsFromEnum<MathConditionOperator>(
    MathConditionOperator,
    'admin.conditions.options.mathConditionOperator'
  );

  return (
    <SelectMui
      id='condition-math-condition-operator-select'
      label={t('admin.conditions.labels.mathConditionOperator')}
      options={options}
      value={value}
      onChange={onChange}
    />
  );
}

export function MathOperatorConditionCriteria({
  condition,
  nodeIdList,
  nodeIdInCollectionMap,
  collectionContext,
  onConditionPropertiesChange,
  language,
  conditionIndex,
}: ConditionCriteriaProps<MathOperatorConditionValue>): React.ReactElement {
  const validationErrors = getConditionValidationError(condition, nodeIdList, nodeIdInCollectionMap);
  const collectionNodeIds = condition.nodeIds?.length ? nodeIdInCollectionMap[condition.nodeIds[0]] ?? [] : [];

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <ConditionTargetNodeAutocompleteMultiple
          values={condition.nodeIds}
          nodeIdList={nodeIdList}
          onSelect={(nodeIds) => {
            onConditionPropertiesChange({ nodeIds });
          }}
          validationError={validationErrors?.[ValidationValues.nodeIds]}
          selectedLanguage={language}
        />
      </Grid>
      <Grid item xs={12}>
        <MathConditionOperatorSelect
          value={condition.mathOperator}
          onChange={(mathOperator) => {
            onConditionPropertiesChange({ mathOperator });
          }}
        />
        {validationErrors?.[ValidationValues.mathOperator] && (
          <ValidationErrorText>{validationErrors[ValidationValues.mathOperator].message}</ValidationErrorText>
        )}
      </Grid>
      <Grid item xs={12}>
        <NumberComparisonConditionOperatorSelect
          value={condition.operator}
          onChange={(operator) => {
            onConditionPropertiesChange({ operator });
          }}
        />
        {validationErrors?.[ValidationValues.operator] && (
          <ValidationErrorText>{validationErrors[ValidationValues.operator].message}</ValidationErrorText>
        )}
      </Grid>
      <NumberValueOrNodeIdInput
        nodeIdOfValue={condition.nodeIdOfValue}
        numberValue={condition.value}
        nodeIdList={nodeIdList}
        onChange={({ numberValue, nodeIdOfValue }: { numberValue?: number; nodeIdOfValue?: string }) =>
          onConditionPropertiesChange({ value: numberValue, nodeIdOfValue })
        }
        language={language}
        numberValueValidationError={validationErrors && validationErrors[ValidationValues.value]}
        nodeIdOfValueValidationError={validationErrors && validationErrors[ValidationValues.nodeIdOfValue]}
      />
      <CollectionOperators
        collectionContext={collectionContext}
        collectionNodeIds={collectionNodeIds}
        collectionOperators={condition.collectionOperators}
        onConditionPropertiesChange={onConditionPropertiesChange}
        conditionIndex={conditionIndex}
      />
    </React.Fragment>
  );
}
