import { DesignSystemTheme } from './Types';

export const grey = {
  100: '#000000',
  90: '#333333',
  80: '#555555',
  70: '#757575',
  60: '#999999',
  50: '#cccccc',
  40: '#e6e6e6',
  30: '#eeeeee',
  20: '#f6f6f6',
  10: '#fbfbfb',
  0: '#ffffff',
};
export const blue = {
  90: '#020811',
  80: '#051934',
  70: '#0B3A7A',
  60: '#115BC0',
  50: '#2F80ED',
  40: '#66A2F2',
  30: '#ACCCF8',
  20: '#F1F7FE',
  10: '#F8FBFE',
};

export const green = {
  90: '#05170D',
  80: '#082313',
  70: '#12512D',
  60: '#1D8046',
  50: '#27AE60',
  40: '#4DD787',
  30: '#8EE6B3',
  20: '#DFF8E9',
  10: '#F7FDF9',
};

export const yellow = {
  90: '#281E03',
  80: '#3B2E04',
  70: '#8A6B0A',
  60: '#D9A810',
  50: '#F2C94C',
  40: '#F5D77C',
  30: '#F9E6AB',
  20: '#FCF4DB',
  10: '#FEFCF4',
};

export const orange = {
  90: '#271403',
  80: '#3B1E04',
  70: '#8A460A',
  60: '#D86E10',
  50: '#F2994A',
  40: '#F5B47A',
  30: '#F9CFAB',
  20: '#FCEBDB',
  10: '#FDF5ED',
};

export const red = {
  90: '#260505',
  80: '#3A0707',
  70: '#861010',
  60: '#D31919',
  50: '#EB5757',
  40: '#F08484',
  30: '#F6B1B1',
  20: '#FEF4F4',
  10: '#FEF9F9',
};

export const purple = {
  90: '#150623',
  80: '#1F0934',
  70: '#491679',
  60: '#7322BE',
  50: '#9B51E0',
  40: '#B67FE8',
  30: '#D0AEF1',
  20: '#EBDCF9',
  10: '#F5EDFC',
};

const REGULAR_WEIGHT = 400;
const MEDIUM_WEIGHT = 500;
const SEMI_BOLD_WEIGHT = 600;

export const text = {
  h1: { size: '21px', weight: MEDIUM_WEIGHT },
  h2: { size: '18px', weight: SEMI_BOLD_WEIGHT },
  h3: { size: '16px', weight: SEMI_BOLD_WEIGHT },
  h4: { size: '15px', weight: SEMI_BOLD_WEIGHT },
  body1: { size: '15px', weight: REGULAR_WEIGHT },
  body2: { size: '14px', weight: SEMI_BOLD_WEIGHT },
  body3: { size: '14px', weight: REGULAR_WEIGHT },
  body4: { size: '13px', weight: SEMI_BOLD_WEIGHT },
  body5: { size: '13px', weight: REGULAR_WEIGHT },
  button: { size: '14px', weight: MEDIUM_WEIGHT },
  small1: { size: '12px', weight: REGULAR_WEIGHT },
  small2: { size: '11px', weight: MEDIUM_WEIGHT },
  small3: { size: '9px', weight: SEMI_BOLD_WEIGHT },
};

export const animation = {
  transition: 'cubic-bezier(0.55, 0, 0.1, 1)',
  speed: {
    slow: '0.5s',
    medium: '0.25s',
    fast: '0.1s',
  },
};

export const breakpoints = {
  xs: '0px',
  sm: '600px',
  md: '960px',
  lg: '1280px',
  xl: '1920px',
};

const theme: DesignSystemTheme = {
  text,
  colors: {
    grey,
    blue,
    green,
    yellow,
    orange,
    red,
    purple,
  },
  animation,
  breakpoints,
};

export default theme;
