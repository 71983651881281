import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Language, Localizable } from '@breathelife/types';
import { AutoCompleteMultiple, AutocompleteOption } from '@breathelife/ui-components';

import { NodeDetail } from '../../../Helpers/questionnaireEditor/questionnaireNodeIds';

type AutocompleteOptionWithGroupName = AutocompleteOption & { groupName?: Partial<Localizable> };

type Props = {
  values: string[];
  nodeIdList: NodeDetail[];
  onSelect: (values: string[]) => any;
  label?: string;
  fieldId?: string;
  validationError?: yup.ValidationError;
  selectedLanguage: Language;
};

export function ConditionTargetNodeAutocompleteMultiple(props: Props): React.ReactElement {
  const { nodeIdList, onSelect, label, values, fieldId, selectedLanguage, validationError } = props;

  const { t } = useTranslation();

  const options: AutocompleteOptionWithGroupName[] = useMemo(
    () =>
      nodeIdList.map((nodeIdDetail) => ({
        label: nodeIdDetail.answerNodeId,
        value: nodeIdDetail.answerNodeId,
        groupName: nodeIdDetail.groupName,
      })),
    [nodeIdList]
  );

  const selectedOptions = values.map((value) => ({
    label: value,
    value,
  }));

  const handleChange = useCallback(
    (options: { value: string }[]) => {
      const selectedOptions = options.map(({ value }) => value);
      onSelect(selectedOptions);
    },
    [onSelect]
  );

  return (
    <AutoCompleteMultiple
      id={fieldId ?? 'condition-target-node-autocomplete'}
      optionInfo={{ options, selectedOptions, onChange: handleChange }}
      label={label ?? t('admin.conditions.labels.targetNodeId')}
      validationError={validationError}
      groupBy={(option: AutocompleteOptionWithGroupName) =>
        (option.groupName && option.groupName[selectedLanguage]) ?? ''
      }
    />
  );
}
