import React from 'react';
import styled, { useTheme } from 'styled-components';

import Typography, { ItalicTypography, Strikeable } from '../../../../../Components/Typography';

const Root = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: 4px;
  }
`;

type Props = {
  text?: string;
  fallbackText?: string;
  hidden?: boolean;
  indicateRequired?: boolean;
  postfix?: React.ReactElement;
};

export function SummaryText(props: Props): React.ReactElement {
  const { text, fallbackText, postfix, indicateRequired, hidden } = props;
  const theme = useTheme();

  const isFallbackText = !text && fallbackText;
  const TypographyComponent = isFallbackText || hidden ? ItalicTypography : Typography;

  return (
    <Root>
      <Strikeable strike={hidden}>
        <TypographyComponent variant='body1' color={theme.colors.grey[90]}>
          {text || fallbackText}
          {indicateRequired ? ' *' : null}
        </TypographyComponent>
      </Strikeable>

      {postfix}
    </Root>
  );
}
