import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PartIdentifierTag, SectionBlueprint, SectionGroupBlueprint, SectionGroupKey } from '@breathelife/types';
import { AutocompleteOption, FormattedTreeViewItemData } from '@breathelife/ui-components';

import { useUpdateQuestionnaireElementBlueprint } from '../../../../../ReactQuery/Admin/Questionnaire/questionnaireVersion.mutations';
import { QuestionnaireEditorContext } from '../../QuestionnaireEditorContextProvider';
import {
  QuestionnaireVersionDataContext,
  useContractSectionBlueprints,
  useInsuredPeopleSectionBlueprints,
} from '../../QuestionnaireVersionDataContextProvider';
import { CreateQuestionnaireElement } from '../Creators/CreateQuestionnaireElement';
import { SectionGroupModalEditor } from '../Editors/SectionGroupModalEditor';
import { makePartIdentifierFromTreePath } from '../Helpers/makePartIdentifierFromTreePath';
import { useQuestionnaireElementActions } from '../Hooks/useQuestionnaireElementActions';
import { useResize } from '../Hooks/useResize';
import { QuestionnaireSectionGroupTree } from './QuestionnaireSectionGroupTree';
import { QuestionnaireTreeViewSearchContext } from './QuestionnaireTreeViewSearchContextProvider';

const Container = styled.div<{ containerHeight?: number }>`
  min-height: 150px;
  height: ${(props) => (props ? `${props.containerHeight}px` : 'auto')};
  width: 100%;
  padding-top: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const ResizeHandle = styled.button<{ $isResizing: boolean }>`
  height: 2px;
  width: 100%;
  padding: 0px;
  border: 0px;
  background-color: ${(props) => props.theme.colors.grey[40]};
  cursor: ${(props) => (props.$isResizing ? 'row-resize' : 'ns-resize')};

  &:before {
    content: '';
    width: 100%;
    height: 3px;
    background-color: ${(props) => props.theme.colors.grey[40]};
    display: ${(props) => (props.$isResizing ? 'block' : 'none')};
  }

  &:hover {
    &:before {
      display: block;
    }
  }
`;

type QuestionnaireTreeViewProps = {
  isEditingEnabled: boolean;
  dataLabelOptions: AutocompleteOption[];
};

const TREEVIEW_HEIGHT = 350;

export function QuestionnaireTreeView(props: QuestionnaireTreeViewProps): React.ReactElement {
  const { isEditingEnabled, dataLabelOptions } = props;

  const { searchResults, searchTerm, previousSearchTerm } = useContext(QuestionnaireTreeViewSearchContext);
  const { size, ref, isResizing, enableResize } = useResize({ minSize: TREEVIEW_HEIGHT, isHeight: true });

  const { questionnaireVersionData, questionnaireVersionId } = useContext(QuestionnaireVersionDataContext);
  const questionnaireBlueprint = questionnaireVersionData?.blueprint;
  const insuredPeopleSectionGroupBlueprint = useInsuredPeopleSectionBlueprints();
  const contractSectionGroupBlueprint = useContractSectionBlueprints();
  const { onItemClick, addItem } = useQuestionnaireElementActions({
    isQuestionnaireEditingEnabled: isEditingEnabled,
  });

  const { t } = useTranslation();

  const { selectedPartIdentifier } = useContext(QuestionnaireEditorContext);
  const [selectedItemTreePathIdentifier, setSelectedItemTreePathIdentifier] = useState<string | undefined>();
  const [forcedScrollToItemPathIdentifier, setForcedScrollToItemPathIdentifier] = useState<string | undefined>();

  const [isAddSectionModalVisible, setIsAddSectionModalVisible] = useState<boolean>(false);
  const [addSectionSectionGroupKey, setAddSectionSectionGroupKey] = useState<SectionGroupKey>('insuredPeople');

  const [isEditSectionGroupModalOpen, setIsEditSectionGroupModalOpen] = useState<boolean>(false);
  const [editSectionGroupKey, setEditSectionGroupKey] = useState<SectionGroupKey>('insuredPeople');

  const updateQuestionnaireElement = useUpdateQuestionnaireElementBlueprint(questionnaireVersionId);

  const onEditSectionGroup = useCallback(
    (sectionGroupBlueprint: SectionGroupBlueprint): void => {
      void updateQuestionnaireElement({
        partIdentifier: { tag: PartIdentifierTag.sectionGroup, sectionGroupPartName: editSectionGroupKey },
        sectionGroupKey: editSectionGroupKey,
        update: sectionGroupBlueprint,
      });
    },
    [editSectionGroupKey, updateQuestionnaireElement]
  );

  const handleItemClick = useCallback(
    (data: FormattedTreeViewItemData) => {
      const partIdentifier = makePartIdentifierFromTreePath(data.path);
      setSelectedItemTreePathIdentifier(data.pathIdentifier);
      onItemClick(partIdentifier);
    },
    [onItemClick]
  );

  useEffect(() => {
    if (!searchTerm && previousSearchTerm && selectedItemTreePathIdentifier) {
      setForcedScrollToItemPathIdentifier(selectedItemTreePathIdentifier);
    }
  }, [searchTerm, setForcedScrollToItemPathIdentifier, selectedItemTreePathIdentifier, previousSearchTerm]);

  const handleEditSectionGroupModal = useCallback((sectionGroupKey: SectionGroupKey) => {
    setEditSectionGroupKey(sectionGroupKey);
    setIsEditSectionGroupModalOpen(true);
  }, []);

  const handleOpenNewSectionModal = useCallback((sectionGroupKey: SectionGroupKey) => {
    setAddSectionSectionGroupKey(sectionGroupKey);
    setIsAddSectionModalVisible(true);
  }, []);

  function makeSectionGroupTree(sectionGroupKey: SectionGroupKey): JSX.Element {
    let sectionBlueprints: SectionBlueprint[] = [];
    if (sectionGroupKey === 'insuredPeople') {
      sectionBlueprints = insuredPeopleSectionGroupBlueprint;
    }
    if (sectionGroupKey === 'contract') {
      sectionBlueprints = contractSectionGroupBlueprint;
    }

    return (
      <QuestionnaireSectionGroupTree
        isEditingEnabled={isEditingEnabled}
        dataLabelOptions={dataLabelOptions}
        sectionGroupKey={sectionGroupKey}
        sectionGroupBlueprint={questionnaireBlueprint?.sectionGroupBlueprints[sectionGroupKey]}
        sectionsInGroupBlueprints={sectionBlueprints}
        forcedScrollToItemPathIdentifier={forcedScrollToItemPathIdentifier}
        onForceScrollToItemEnd={() => {
          setForcedScrollToItemPathIdentifier(undefined);
        }}
        handleItemClick={handleItemClick}
        openEditSectionGroupModal={() => {
          handleEditSectionGroupModal(sectionGroupKey);
        }}
        openAddSectionModal={() => {
          handleOpenNewSectionModal(sectionGroupKey);
        }}
        selectedPartIdentifier={selectedPartIdentifier}
        searchTerm={searchTerm}
        searchResults={searchResults}
      />
    );
  }

  return (
    <React.Fragment>
      <Container ref={ref} containerHeight={size}>
        {makeSectionGroupTree('insuredPeople')}
      </Container>

      <ResizeHandle type='button' $isResizing={isResizing} onMouseDown={enableResize} />

      <Container>{makeSectionGroupTree('contract')}</Container>

      {isEditSectionGroupModalOpen && (
        <SectionGroupModalEditor
          isOpen={isEditSectionGroupModalOpen}
          isRepeatableSectionGroup={editSectionGroupKey === 'insuredPeople'}
          title={t('admin.questionnaireManagement.editSectionGroup')}
          sectionGroupBlueprint={questionnaireBlueprint?.sectionGroupBlueprints[editSectionGroupKey]}
          onSave={onEditSectionGroup}
          onClose={() => setIsEditSectionGroupModalOpen(false)}
        />
      )}

      {isAddSectionModalVisible && (
        <CreateQuestionnaireElement
          parentPartIdentifier={{
            tag: PartIdentifierTag.sectionGroup,
            sectionGroupPartName: addSectionSectionGroupKey,
          }}
          type={PartIdentifierTag.section}
          onCreate={(blueprint) => addItem(blueprint)}
          onClose={() => setIsAddSectionModalVisible(false)}
          dataLabelOptions={dataLabelOptions}
          sectionGroupKey={addSectionSectionGroupKey}
        />
      )}
    </React.Fragment>
  );
}
