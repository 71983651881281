import React, { useCallback, useEffect, useState } from 'react';

import { CreateUserData, InsuranceScopes, User, UserCategory, UserType } from '@breathelife/types';

import { getGroupProperties } from '../../../../Helpers/groups';
import { FormFields, getFormSchema } from '../../../../Helpers/inputValidation/form/user';
import { getDefaultInsuranceScope } from '../../../../Helpers/insuranceScope';
import { useCarrierContext, useDispatch, useSelector } from '../../../../Hooks';
import { DetailViewType } from '../../../../Models/Layout';
import { Mga } from '../../../../Models/Mga';
import * as UserManagementOperations from '../../../../Redux/UserManagement/UserManagementOperation';
import { getFirstSelectedUser } from '../../../../Redux/UserManagement/UserManagementSelectors';
// Not using UserRole from @breathelife/types because it is also including service role..
import { isSsoUser, UserRole } from '../../../../Services/Auth0';
import { UserDetailView } from './UserDetailView';
import { InputState, UserFormState } from './UserForm';

type UserDetailContainerProps = {
  onClose: () => void;
  onOpenResendInvitationModal: (user: User) => void;
  toggleBlockUser: (user: User) => void;
};

const defaultFormState: InputState = {
  value: '',
};

function buildUser(userFormState: UserFormState, groups: Mga[]): Partial<CreateUserData> {
  const user = {
    ...(userFormState.firstName.value && { firstName: userFormState.firstName.value }),
    ...(userFormState.lastName.value && { lastName: userFormState.lastName.value }),
    ...(userFormState.phoneNumber.value && { phoneNumber: userFormState.phoneNumber.value }),
    ...(userFormState.userId.value && { userId: userFormState.userId.value }),
    ...(userFormState.insuranceScope && { defaultInsuranceScopes: [userFormState.insuranceScope] }),
    emailLogin: userFormState.email.value,
    role: userFormState.role,
    groupId: userFormState.groupId,
    category: userFormState.category,
    type: userFormState.type,
    picture: userFormState.picture,
  };
  const { groupId, role, userId, ...rest } = user;
  const roles = role ? [role] : undefined;
  const groupData = getGroupProperties(groupId, groups);
  return { ...rest, ...groupData, roles, customId: userId };
}

function getDefaultUserFormState(carrierInsuranceScopes: InsuranceScopes[]): UserFormState {
  return {
    firstName: { ...defaultFormState },
    lastName: { ...defaultFormState },
    email: { ...defaultFormState },
    phoneNumber: { ...defaultFormState },
    userId: { ...defaultFormState },
    groupId: '',
    role: UserRole.user,
    category: UserCategory.nonRegistered,
    type: UserType.captive,
    insuranceScope: getDefaultInsuranceScope(carrierInsuranceScopes),
  };
}

function UserDetailContainer(props: UserDetailContainerProps): React.ReactElement {
  const { onClose: onCloseProp, onOpenResendInvitationModal, toggleBlockUser } = props;
  const dispatch = useDispatch();
  const { insuranceScopes: carrierInsuranceScopes, mgas } = useCarrierContext();

  const userManagementState = useSelector((store) => store.leadPlatform.userManagement);
  const { isOpen: isModalOpen, type: detailViewType } = useSelector(
    (store) => store.leadPlatform.layout.rightPanelState
  );
  const selectedUser: User | undefined = useSelector(getFirstSelectedUser);

  const [userFormState, setUserFormState] = useState<UserFormState>(getDefaultUserFormState(carrierInsuranceScopes));

  const onClose = useCallback(() => {
    setUserFormState(getDefaultUserFormState(carrierInsuranceScopes));

    onCloseProp();
  }, [onCloseProp, carrierInsuranceScopes]);

  const onUpdateUser = useCallback(() => {
    if (!selectedUser) return;
    const isSso = isSsoUser(selectedUser);
    const data = buildUser(userFormState, mgas);
    dispatch(UserManagementOperations.updateUser(selectedUser.auth0Id, data, isSso));
  }, [dispatch, mgas, selectedUser, userFormState]);

  const onCreateUser = useCallback(() => {
    const data = buildUser(userFormState, mgas) as CreateUserData;
    dispatch(UserManagementOperations.createUser(data));
  }, [dispatch, userFormState, mgas]);

  useEffect(() => {
    const defaultState = getDefaultUserFormState(carrierInsuranceScopes);
    setUserFormState({
      firstName: {
        value: selectedUser?.firstName ?? defaultState.firstName.value,
      },
      lastName: {
        value: selectedUser?.lastName ?? defaultState.lastName.value,
      },
      email: {
        value: selectedUser?.emailLogin ?? defaultState.email.value,
      },
      phoneNumber: {
        value: selectedUser?.phoneNumber ?? defaultState.phoneNumber.value,
      },
      userId: {
        value: selectedUser?.customId ?? defaultState.userId.value,
      },
      groupId: selectedUser?.groupId ?? defaultState.groupId,
      role: (selectedUser?.roles?.[0] as UserRole) ?? defaultState.role,
      category: selectedUser?.category ?? defaultState.category,
      type: selectedUser?.type ?? defaultState.type,
      insuranceScope: selectedUser?.defaultInsuranceScopes[0] ?? defaultState.insuranceScope,
      picture: selectedUser?.picture,
      isSso: selectedUser?.isSso,
    });
  }, [selectedUser, carrierInsuranceScopes]);

  const userFormValues: Partial<FormFields> = {
    ...(userFormState.firstName.value && { firstName: userFormState.firstName.value }),
    ...(userFormState.lastName.value && { lastName: userFormState.lastName.value }),
    ...(userFormState.phoneNumber.value && { phoneNumber: userFormState.phoneNumber.value }),
    ...(userFormState.userId.value && { userId: userFormState.userId.value }),
    ...(userFormState.insuranceScope && { defaultInsuranceScopes: [userFormState.insuranceScope] }),
    emailLogin: userFormState.email.value,
    role: userFormState.role,
    groupId: userFormState.groupId,
    category: userFormState.category,
    type: userFormState.type,
    picture: userFormState.picture,
  };

  const isFormValid = getFormSchema().isValidSync(userFormValues);

  const onProfilePictureChange = useCallback((picture: string) => {
    setUserFormState((prevUserFormState) => ({
      ...prevUserFormState,
      picture,
    }));
  }, []);

  const onFirstNameChange = useCallback((firstName: InputState) => {
    setUserFormState((prevUserFormState) => ({
      ...prevUserFormState,
      firstName,
    }));
  }, []);

  const onLastNameChange = useCallback((lastName: InputState) => {
    setUserFormState((prevUserFormState) => ({
      ...prevUserFormState,
      lastName,
    }));
  }, []);

  const onEmailChange = useCallback((email: InputState) => {
    setUserFormState((prevUserFormState) => ({
      ...prevUserFormState,
      email,
    }));
  }, []);

  const onPhoneNumberChange = useCallback((phoneNumber: InputState) => {
    setUserFormState((prevUserFormState) => ({
      ...prevUserFormState,
      phoneNumber,
    }));
  }, []);

  const onGroupIdChange = useCallback((groupId: string) => {
    setUserFormState((prevUserFormState) => ({
      ...prevUserFormState,
      groupId,
    }));
  }, []);

  const onUserIdChange = useCallback((userId: InputState) => {
    setUserFormState((prevUserFormState) => ({
      ...prevUserFormState,
      userId,
    }));
  }, []);

  const onRoleChange = useCallback((role: UserRole) => {
    setUserFormState((prevUserFormState) => ({
      ...prevUserFormState,
      role,
    }));
  }, []);

  const onCategoryChange = useCallback((category: UserCategory) => {
    setUserFormState((prevUserFormState) => ({
      ...prevUserFormState,
      category,
    }));
  }, []);

  const onTypeChange = useCallback((type: UserType) => {
    setUserFormState((prevUserFormState) => ({
      ...prevUserFormState,
      type,
    }));
  }, []);

  const onInsuranceScopeChange = useCallback((insuranceScope: InsuranceScopes) => {
    setUserFormState((prevUserFormState) => ({
      ...prevUserFormState,
      insuranceScope,
    }));
  }, []);

  const allProps = {
    isLoading: userManagementState.isLoading,
    isOpen: isModalOpen,
    onUpdateUser,
    onCreateUser,
    selectedUser: detailViewType === DetailViewType.edit ? selectedUser : undefined,
    userFormState,
    viewType: detailViewType ?? DetailViewType.create,
    onClose,
    onOpenResendInvitationModal,
    toggleBlockUser,
    isFormValid: !!isFormValid,
    onProfilePictureChange,
    onFirstNameChange,
    onLastNameChange,
    onEmailChange,
    onPhoneNumberChange,
    onGroupIdChange,
    onUserIdChange,
    onRoleChange,
    onCategoryChange,
    onTypeChange,
    onInsuranceScopeChange,
  };
  return <UserDetailView {...allProps} />;
}

export default UserDetailContainer;
