import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  BlueprintConditionValue,
  BlueprintSingleConditionValue,
  BooleanOperator,
  isBlueprintConditionsValue,
  Language,
} from '@breathelife/types';

import { SingleConditionValueEditor } from '../../Components/Conditions';
import { IconButton } from '../../Components/Controls';
import { BASE_CONDITION_PATH } from '../../Helpers/conditions/blueprintHelpers';
import { NodeIdInCollections, QuestionnaireNodeIds } from '../../Helpers/questionnaireEditor/questionnaireNodeIds';
import { BooleanOperatorSelector } from './Helpers/BooleanOperatorSelect';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
  align-items: center;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.grey[40]};

  &:last-child {
    border-right-color: transparent;
  }
`;

const ConditionsActionsContainer = styled(Box)`
  border-right: 1px solid ${(props) => props.theme.colors.grey[40]};
  width: 90px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
`;

const ConditionsContainer = styled(List)`
  width: 100%;
  grid-column: 2/-1;
`;

type Props = {
  path?: string;
  questionnaireNodeIds: QuestionnaireNodeIds;
  nodeIdInCollectionMap: NodeIdInCollections;
  collectionContext: string[];
  condition?: BlueprintConditionValue;
  onConditionChange: (updatedCondition: BlueprintConditionValue, path: string) => void;
  onBooleanOperatorChange: (booleanOperator: BooleanOperator, path: string) => void;
  onAddCondition: (path: string) => void;
  onAddNestedCondition: (path: string) => void;
  onRemoveCondition: (path: string) => void;
  isConditionRemovable?: boolean;
  language: Language;
  conditionIndex?: string;
};

export function ConditionsEditor({
  condition,
  questionnaireNodeIds,
  collectionContext,
  onConditionChange,
  onBooleanOperatorChange,
  onAddCondition,
  onAddNestedCondition,
  onRemoveCondition,
  path = '',
  isConditionRemovable,
  language,
  nodeIdInCollectionMap,
  conditionIndex,
}: Props): React.ReactElement | null {
  const { t } = useTranslation();

  if (!condition) {
    return null;
  }

  if (isBlueprintConditionsValue(condition)) {
    return (
      <GridContainer>
        <ConditionsActionsContainer>
          <Box display='flex' flexDirection='column' p={1}>
            <Box py={1}>
              <IconButton
                onClick={() => onAddCondition(path)}
                aria-label='add a condition'
                icon={<AddCircleOutlineIcon />}
                title={t('admin.conditions.labels.addCondition')}
              />
            </Box>
            <Box py={1}>
              <IconButton
                disabled={path === ''}
                onClick={() => onRemoveCondition(path)}
                aria-label='remove the condition'
                icon={<DeleteIcon />}
                title={t('admin.conditions.labels.removeNestedCondition')}
              />
            </Box>
          </Box>
          <BooleanOperatorSelector
            // conditions with the "NOT" operator are currently supported by the condition engine with only one nested condition
            // https://github.com/getbreathelife/bliss/blob/d6b2c658ef5d92bb1f30f752a4682f3b41990b73/shared/questionnaire-engine/src/conditionEngine/evaluateConditions.ts#L39-L44
            canSelectNotOperator={condition.conditions.length <= 1}
            onChange={onBooleanOperatorChange}
            path={path}
            value={condition.booleanOperator as BooleanOperator}
          />
        </ConditionsActionsContainer>
        <ConditionsContainer dense disablePadding>
          {condition.conditions.map((nestedCondition, index) => {
            const newConditionIndex = `${conditionIndex ? conditionIndex + '.' : ''}${index}`;
            return (
              <ListItem
                key={`condition-editor-${path}.${newConditionIndex}`}
                disableGutters
                divider={
                  index < condition.conditions.length - 1 &&
                  !isBlueprintConditionsValue(nestedCondition) &&
                  !isBlueprintConditionsValue(condition.conditions[index + 1])
                }
              >
                <ConditionsEditor
                  questionnaireNodeIds={questionnaireNodeIds}
                  collectionContext={collectionContext}
                  condition={nestedCondition}
                  onConditionChange={onConditionChange}
                  onBooleanOperatorChange={onBooleanOperatorChange}
                  onAddCondition={onAddCondition}
                  onAddNestedCondition={onAddNestedCondition}
                  path={path === '' ? `${BASE_CONDITION_PATH}[${index}]` : `${path}.${BASE_CONDITION_PATH}[${index}]`}
                  onRemoveCondition={onRemoveCondition}
                  // avoids having empty conditions if last condition in conditions array is deleted
                  isConditionRemovable={condition.conditions.length > 1}
                  language={language}
                  nodeIdInCollectionMap={nodeIdInCollectionMap}
                  conditionIndex={newConditionIndex}
                />
              </ListItem>
            );
          })}
        </ConditionsContainer>
      </GridContainer>
    );
  }

  return (
    <Box py={2} px={1} width='100%'>
      <SingleConditionValueEditor
        questionnaireNodeIds={questionnaireNodeIds}
        condition={condition as BlueprintSingleConditionValue}
        onConditionChange={(conditionProperties) => {
          onConditionChange(conditionProperties, path);
        }}
        onRemoveCondition={() => onRemoveCondition(path)}
        onAddNestedCondition={() => onAddNestedCondition(path)}
        isConditionRemovable={Boolean(isConditionRemovable)}
        language={language}
        collectionContext={collectionContext}
        nodeIdInCollectionMap={nodeIdInCollectionMap}
        conditionIndex={conditionIndex || '0'}
      />
    </Box>
  );
}
