import _ from 'lodash';
import { generatePath } from 'react-router';

import { LeadTab } from '../Models/Layout';

export enum UrlParamKeys {
  leadTab = 'leadTab',
  leadId = 'leadId',
  applicationId = 'applicationId',
}

const urls = {
  // TODO: Legacy route can be removed once NLG has updated their login redirect to point on /pro/login. They are currently doing
  // /leads/login?sso=1 from their platform.
  legacy: {
    login: '/leads/login',
    logout: '/leads/logout',
  },

  home: '/pro',

  // Lead management
  leads: '/pro/leads',
  leadsList: `/pro/leads/:${UrlParamKeys.leadTab}`,
  leadDetail: `/pro/leads/:${UrlParamKeys.leadTab}/:${UrlParamKeys.leadId}`,
  application: `/pro/leads/:${UrlParamKeys.leadTab}/:${UrlParamKeys.leadId}/applications/:${UrlParamKeys.applicationId}`,

  // Admin tools
  admin: '/pro/admin',
  userManagement: '/pro/admin/users',
  questionnaireManagement: '/pro/admin/questionnaire',
  salesDecisionRulesManagement: '/pro/admin/sales-decision-rules',
  documentsManagement: '/pro/admin/documents',
  firmManagement: '/pro/admin/firm',
  productManagement: '/pro/admin/product',
  themeManagement: '/pro/admin/theme',
  settingsManagement: '/pro/admin/settings',
  auditLogs: '/pro/admin/logs',
  questionnaireBuilder: '/pro/admin/q-builder',
  thirdPartyIntegrations: '/pro/admin/third-party-integrations',
  entityMappingManagement: '/pro/admin/entity-mapping',
  backOfficeApplications: '/pro/admin/back-office/applications',
  backOfficeApplicationUnderwritingReports: `/pro/admin/back-office/applications/:${UrlParamKeys.applicationId}/underwriting-reports`,

  login: '/pro/login',
  logout: '/pro/logout',
  authCallback: '/pro/login-callback',
};

const DEFAULT_LEAD_TAB = _.findKey(LeadTab, (value) => value === 0); // === 'active'

function getLeadTabKey(leadTab: LeadTab): string | undefined {
  return _.findKey(LeadTab, (value) => value === leadTab) || DEFAULT_LEAD_TAB;
}

export function generateLeadsListUrl(leadTab: LeadTab = LeadTab.active): string {
  return generatePath(urls.leadsList, {
    [UrlParamKeys.leadTab]: getLeadTabKey(leadTab),
  });
}

export function generateLeadDetailUrl(leadId: number, leadTab: LeadTab = LeadTab.active): string {
  return generatePath(urls.leadDetail, {
    [UrlParamKeys.leadId]: leadId.toString(),
    [UrlParamKeys.leadTab]: getLeadTabKey(leadTab),
  });
}

export function generateApplicationUrl(
  applicationId: string,
  leadId: number,
  leadTab: LeadTab = LeadTab.active
): string {
  return generatePath(urls.application, {
    [UrlParamKeys.applicationId]: applicationId,
    [UrlParamKeys.leadId]: leadId.toString(),
    [UrlParamKeys.leadTab]: getLeadTabKey(leadTab),
  });
}

export function generateApplicationUnderwritingReportsUrl(applicationId: string): string {
  return generatePath(urls.backOfficeApplicationUnderwritingReports, {
    [UrlParamKeys.applicationId]: applicationId,
  });
}

export default urls;
