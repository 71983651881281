import styled from 'styled-components';

export const ApplicationsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const ExistingApplicationsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 32px;
  padding-right: 32px;
`;

export const Image = styled.img`
  height: 170px;
  width: 170px;
  margin: 30px;
`;

export const LaunchApplicationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
