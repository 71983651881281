import React, { Dispatch, SetStateAction } from 'react';

import { OnAnswerChange, OnBulkAnswerClear } from '@breathelife/questionnaire-engine';

import { ProposedInsuredTab } from '../Helpers/assistedApplication/tabs';
import { SectionGroupId } from '../Helpers/questionnaireAnswers';

export type AssistedApplicationContextProps = {
  // Proposed insured
  currentSectionGroupId: SectionGroupId;
  onSwitchSectionGroupTab: (id: SectionGroupId) => void;
  proposedInsuredTabs: ProposedInsuredTab[];
  currentProposedInsuredIndex: number;
  onSelectProposedInsured: (index: number) => void;
  isLoadingProposedInsured: boolean;
  onAddProposedInsured: () => void;
  onRemoveProposedInsured: (surrogateId: string) => void;
  activeSectionIndex: number;
  setActiveSectionIndex: Dispatch<SetStateAction<number>>;

  // Form
  areAllFieldsDisabled: boolean;
  isSavingAnswers: boolean;
  onAnswerChange: OnAnswerChange;
  onAnswerComplete: (fieldId: string, answer: any, previousAnswer: any, repeatedQuestionIndex?: number) => void;
  onBulkAnswerClear: OnBulkAnswerClear;
};

export const AssistedApplicationContext = React.createContext<AssistedApplicationContextProps>(
  {} as AssistedApplicationContextProps
);
