import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router';
import styled from 'styled-components';

import { IconName } from '@breathelife/types';
import { Icon } from '@breathelife/ui-components';

import { IconButton } from '../../../Components/Controls';
import { CarrierContext } from '../../../Context/CarrierContext';
import Urls from '../../../Navigation/Urls';
import { EntityMappingCodeEditor } from './EntityMappingCodeEditor';
import { EntityMappingContextProvider } from './EntityMappingContextProvider';
import { EntityMappingSidebar } from './EntityMappingSidebar';

const EntityMappingTwoColumnWrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
  min-height: 100%;
  height: 100%;
  display: flex;
`;

const EntityMappingSidebarWrapper = styled.div<{ isOpen: boolean }>`
  width: ${(props) => (props.isOpen ? '350px' : '65px')};
  border-right: 1px solid ${(props) => props.theme.colors.grey[30]};
  min-height: 100%;
  left: 0;
  top: 0;
  background-color: ${(props) => props.theme.colors.grey[20]};
  padding: 10px;
`;

const CodeEditorWrapper = styled.div<{ sidebarIsOpen: boolean }>`
  min-height: 100%;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const SidebarHeader = styled.div<{ sidebarIsOpen: boolean }>`
  display: flex;
  justify-content: end;
  width: 100%;
`;

const SidebarContentWrapper = styled.div<{ sidebarIsOpen: boolean }>`
  opacity: ${({ sidebarIsOpen }) => (sidebarIsOpen ? 1 : 0)};
`;

const SidebarToggleRotationWrapper = styled.div<{ sidebarIsOpen: boolean }>`
  transform: ${({ sidebarIsOpen }) => `rotate(${sidebarIsOpen ? 0 : 180}deg)`};
`;

function EntityMappingViewContainerWithoutContext(): React.ReactElement {
  const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(true);

  return (
    <EntityMappingTwoColumnWrapper>
      <EntityMappingSidebarWrapper isOpen={sidebarIsOpen}>
        <SidebarHeader sidebarIsOpen={sidebarIsOpen}>
          <SidebarToggleRotationWrapper sidebarIsOpen={sidebarIsOpen}>
            <IconButton
              icon={<Icon name={IconName.leftArrow} size='15px' />}
              onClick={() => {
                setSidebarIsOpen((prev) => !prev);
              }}
            />
          </SidebarToggleRotationWrapper>
        </SidebarHeader>
        <SidebarContentWrapper sidebarIsOpen={sidebarIsOpen}>
          <EntityMappingSidebar />
        </SidebarContentWrapper>
      </EntityMappingSidebarWrapper>
      <CodeEditorWrapper sidebarIsOpen={sidebarIsOpen}>
        <EntityMappingCodeEditor />
      </CodeEditorWrapper>
    </EntityMappingTwoColumnWrapper>
  );
}

export function EntityMappingViewContainer(): React.ReactElement {
  const { entityMappings } = useContext(CarrierContext);
  if (!entityMappings || !entityMappings.enabled) {
    return <Redirect to={Urls.questionnaireManagement} />;
  }
  return (
    <EntityMappingContextProvider>
      <EntityMappingViewContainerWithoutContext />
    </EntityMappingContextProvider>
  );
}
