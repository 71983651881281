import React from 'react';

import { CreateUserData, User } from '@breathelife/types';

import { useSelector } from '../../../../Hooks';
import { SortParams } from '../../../../Services/Users/UsersService';
import { UsersTable } from './UsersTable';

type OwnProps = {
  currentPage: number;
  perPage: number;
  perPageOptions: { label: number; value: number }[];
  onPageChange: (page: number) => void;
  onPerPageChange: (perPage: number) => void;
  onSortingChange: (options: SortParams) => void;
  onTableRowClick: (user?: User) => void;
  onUpdateUser: (userId: string, data: Partial<CreateUserData>) => void;
  onOpenResendInvitationModal: (user: User) => void;
  onOpenDeleteUserConfirmationModal: (user: User) => void;
};

const UsersTableContainer = (ownProps: OwnProps): React.ReactElement => {
  const isLoading = useSelector((store) => store.leadPlatform.userManagement.isLoading);
  const users = useSelector((store) => store.leadPlatform.userManagement.users);
  const totalUsers = useSelector((store) => store.leadPlatform.userManagement.total);

  const props = {
    ...ownProps,
    isLoading,
    users,
    totalUsers: totalUsers,
  };
  return <UsersTable {...props} />;
};

export default UsersTableContainer;
