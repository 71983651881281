import { Box, Divider, Typography } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../Components/Button/Button';
import { Image } from '../../../Components/Images/Image';
import { CloudinaryUpload } from '../../../Components/Upload/Cloudinary/CloudinaryUpload';
import { getFieldValidationError } from '../../../Helpers/inputValidation/form/theme';

const ExpansionPanelDetails = styled(MuiExpansionPanelDetails)`
  &&& {
    align-items: center;
    justify-content: space-evenly;
  }
`;

type Props = {
  compactLogoUrl: string;
  regularLogoUrl: string;
  isUpdating: boolean;
  onSaveClick: (regularLogo: string, compactLogo: string) => void;
};

export function LogoUploadView({ compactLogoUrl, regularLogoUrl, isUpdating, onSaveClick }: Props): React.ReactElement {
  const { t } = useTranslation();

  const [compactLogo, setCompactLogo] = useState<string>(compactLogoUrl);
  const [regularLogo, setRegularLogo] = useState<string>(regularLogoUrl);

  useEffect(() => {
    setRegularLogo(regularLogoUrl);
    setCompactLogo(compactLogoUrl);
  }, [regularLogoUrl, compactLogoUrl]);

  const validationErrorRegularLogo = getFieldValidationError('logoImageUrl', regularLogo);
  const validationErrorCompactLogo = getFieldValidationError('compactLogoImgUrl', compactLogo);

  return (
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1c-content' id='panel1c-header'>
        <Box display='flex' flexDirection='row' alignItems='center'>
          <Typography variant='h2' style={{ marginRight: '8px' }}>
            {t('admin.themeManagement.logos.title')}
          </Typography>
        </Box>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Image src={regularLogo} radius={0} height={48} width={138} isProfile={false} />
          <CloudinaryUpload
            buttonText={t('admin.themeManagement.logos.regularButtonText')}
            setPicture={(logo) => {
              setRegularLogo(logo);
            }}
            minWidth={138}
            minHeight={48}
            uploadPreset='px_admin_regular_logo'
            clientAllowedFormats={['jpg, jpeg', 'png']}
          />
        </Box>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Image src={compactLogo} radius={0} height={48} width={48} isProfile={false} />
          <CloudinaryUpload
            buttonText={t('admin.themeManagement.logos.compactButtonText')}
            setPicture={(logo) => {
              setCompactLogo(logo);
            }}
            minWidth={40}
            minHeight={40}
            uploadPreset='px_admin_compact_logo'
            clientAllowedFormats={['jpg, jpeg', 'png']}
          />
        </Box>
      </ExpansionPanelDetails>
      <Divider />
      <ExpansionPanelActions>
        <Button
          disabled={Boolean(validationErrorCompactLogo) || Boolean(validationErrorRegularLogo)}
          data-testid='themeColorEdit-save'
          color='primary'
          variant='text'
          isLoading={isUpdating}
          onClick={() => onSaveClick(regularLogo, compactLogo)}
        >
          {t('cta.save').toUpperCase()}
        </Button>
      </ExpansionPanelActions>
    </ExpansionPanel>
  );
}
