import { Box } from '@material-ui/core';
import _ from 'lodash';
import React, { useCallback } from 'react';

import { useDispatch } from '../../../Hooks';
import { navigateToApplicationUnderwritingReports } from '../../../Redux/Navigation/NavigationOperations';
import { ApplicationsContextProvider } from './Applications/ApplicationsContextProvider';
import { ApplicationsTable } from './Applications/ApplicationsTable';

export function ApplicationsTablePage(): React.ReactElement {
  return (
    <ApplicationsContextProvider>
      <ApplicationsViewContainer />
    </ApplicationsContextProvider>
  );
}

export function ApplicationsViewContainer(): React.ReactElement {
  const dispatch = useDispatch();

  const onOpenUnderwritingReports = useCallback(
    (applicationId: string) => {
      if (!applicationId) return;
      dispatch(navigateToApplicationUnderwritingReports(applicationId));
    },
    [dispatch]
  );
  return (
    <React.Fragment>
      <Box mx={2}>
        <ApplicationsTable onOpenUnderwritingReports={onOpenUnderwritingReports} />
      </Box>
    </React.Fragment>
  );
}
