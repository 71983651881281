import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ExportButton } from '../../../Components/ExportData/ExportButton';
import { ExportDataProvider } from '../../../Components/ExportData/ExportDataProvider';
import { ImportButton } from '../../../Components/ExportData/ImportButton';
import { ImportDataProvider } from '../../../Components/ExportData/ImportDataProvider';
import { ImportModal } from '../../../Components/ExportData/ImportModal';
import { Helmet } from '../../../Components/Helmet';
import Typography from '../../../Components/Typography';
import { useDispatch, useSelector } from '../../../Hooks';
import * as ThemeOperations from '../../../Redux/Admin/ThemeManagement/ThemeOperations';
import { ColorRangesEditView } from './ColorRangesEditView';
import { LogoUploadView } from './LogoUploadView';
import { SidebarVariantEditView } from './SidebarVariantEditView';

export function ThemeManagementViewContainer(): React.ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { theme, isFetching, isUpdatingColors, isUpdatingLogos, isUpdatingNavigationSidebarVariant } = useSelector(
    (store) => store.leadPlatform.theme
  );

  useEffect(() => {
    void dispatch(ThemeOperations.fetchTheme());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Helmet text={t('pageTitles.themeManagement')} />
      <Box m={2} display={'flex'} justifyContent={'space-between'}>
        <div>
          <Typography variant='h1'>{t('admin.themeManagement.title')}</Typography>
          <Typography variant='body1'>{t('admin.themeManagement.subtitle')}</Typography>
        </div>
        <Box display={'flex'}>
          <Box mr={1}>
            <ExportDataProvider theme>
              <ExportButton />
            </ExportDataProvider>
          </Box>
          <ImportDataProvider theme>
            <ImportButton />
            <ImportModal title={t('admin.importData.theme')} />
          </ImportDataProvider>
        </Box>
      </Box>
      <Divider />
      <Box m={2}>
        {(isFetching || !theme) && <Skeleton variant='rect' height={50} />}
        {!isFetching && theme && (
          <React.Fragment>
            <ColorRangesEditView
              colorRanges={theme.colorRanges}
              isUpdating={isUpdatingColors}
              onSaveClick={(colorRanges) => dispatch(ThemeOperations.updateTheme(theme, { ...theme, colorRanges }))}
            />
            <LogoUploadView
              compactLogoUrl={theme.compactLogoImgUrl}
              regularLogoUrl={theme.logoImgUrl}
              isUpdating={isUpdatingLogos}
              onSaveClick={(regularLogo, compactLogo) =>
                dispatch(
                  ThemeOperations.updateTheme(theme, {
                    ...theme,
                    logoImgUrl: regularLogo,
                    compactLogoImgUrl: compactLogo,
                  })
                )
              }
            />
            <SidebarVariantEditView
              onSaveClick={(navigationSidebarVariant) =>
                dispatch(ThemeOperations.updateTheme(theme, { ...theme, navigationSidebarVariant }))
              }
              isUpdating={isUpdatingNavigationSidebarVariant}
              navigationSidebarVariant={theme.navigationSidebarVariant}
            />
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  );
}
