import { Application } from '../Models/Application';
import ApiService from './ApiService';

export async function addProposedInsured(applicationId: string): Promise<Application> {
  const response = await ApiService.addProposedInsuredToApplication<Application>(applicationId);
  return response.data;
}

export type UpdateApplicationRemoveProposedInsuredParams = {
  applicationId: string;
  surrogateId: string;
};

export async function removeProposedInsured(data: UpdateApplicationRemoveProposedInsuredParams): Promise<Application> {
  const response = await ApiService.removeProposedInsuredFromApplication<Application>(data);
  return response.data;
}
