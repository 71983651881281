import React, { useContext } from 'react';

import { LeadItem } from '../../../../../Components/LeadItem/LeadItem';
import { CarrierContext } from '../../../../../Context/CarrierContext';
import { Application } from '../../../../../Models/Application';
import { Lead } from '../../../../../Models/Lead';
import { ExistingApplicationsContainer } from './Styles';

type ExistingApplicationsViewProps = {
  lead?: Lead;
  onLaunchAssistedApplication: (application: Application) => void;
};

export function ExistingApplicationsView(props: ExistingApplicationsViewProps): React.ReactElement {
  const { lead, onLaunchAssistedApplication } = props;
  const { features } = useContext(CarrierContext);
  return (
    <ExistingApplicationsContainer>
      {lead?.applications.map((application) => {
        const title =
          features.hiddenLeads?.enabled && application.refNoIncrement && !application.submitted
            ? application.refNoIncrement.toString()
            : application.refNo;
        return (
          <LeadItem
            key={application.id}
            application={application}
            isItemSelected={lead?.applicationId === application.id || lead?.applications?.[0].id === application.id}
            lead={lead}
            onLaunchApplication={() => onLaunchAssistedApplication(application)}
            title={'#' + title}
          />
        );
      })}
    </ExistingApplicationsContainer>
  );
}
