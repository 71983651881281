import TableRow from '@material-ui/core/TableRow';
import styled from 'styled-components';

import { tableHeight } from '../../Models/Layout';

export const StyledTableContainer = styled.div`
  height: calc(100vh - ${tableHeight}px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
`;

export const StyledTableRow = styled(TableRow)`
  :hover {
    cursor: pointer;
  }
`;
