import React from 'react';
import { useTranslation } from 'react-i18next';

import { Language } from '@breathelife/types';
import { SelectMui, SelectMuiProps } from '@breathelife/ui-components';

type LanguageSelectProps = Omit<SelectMuiProps<Language>, 'options'> & {
  enabledLanguages: Language[];
};

export function LanguageSelect(props: LanguageSelectProps): React.ReactElement {
  const { t } = useTranslation();
  const { enabledLanguages } = props;

  const languageOptions = enabledLanguages.map((language) => ({
    value: language,
    label: t(`language.${language}`),
  }));

  return <SelectMui {...props} options={languageOptions} />;
}
