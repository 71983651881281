import React, { createContext, useState } from 'react';

import { Language } from '@breathelife/types';

import { useCarrierContext } from '../../../Hooks';
import { SelectedPartIdentifier } from './QuestionnaireEditor/types';

type QuestionnaireEditorContextValue = {
  selectedLanguage: Language;
  onSelectedLanguageChange: (language: Language) => void;
  selectedPartIdentifier?: SelectedPartIdentifier;
  onSelectedPartIdentifierChange: (partIdentifier: SelectedPartIdentifier) => void;
  isMutating: boolean;
  setIsMutating: React.Dispatch<React.SetStateAction<boolean>>;
};

export const QuestionnaireEditorContext = createContext<QuestionnaireEditorContextValue>({
  onSelectedPartIdentifierChange: () => {},
  onSelectedLanguageChange: () => {},
  selectedLanguage: Language.en,
  setIsMutating: () => {},
  isMutating: false,
});

export function QuestionnaireEditorContextProvider({ children }: { children: React.ReactNode }): React.ReactElement {
  const { languageSettings } = useCarrierContext();
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(languageSettings.default);
  const [selectedPartIdentifier, setSelectedPartIdentifier] = useState<SelectedPartIdentifier>(null);
  const [isMutating, setIsMutating] = useState<boolean>(false);

  function onSelectedPartIdentifierChange(partIdentifier: SelectedPartIdentifier): void {
    setSelectedPartIdentifier(partIdentifier);
  }

  function onSelectedLanguageChange(language: Language): void {
    if (languageSettings.enabledLanguages.includes(language)) {
      setSelectedLanguage(language);
    }
  }

  return (
    <QuestionnaireEditorContext.Provider
      value={{
        onSelectedLanguageChange,
        onSelectedPartIdentifierChange,
        selectedLanguage,
        selectedPartIdentifier,
        isMutating,
        setIsMutating,
      }}
    >
      {children}
    </QuestionnaireEditorContext.Provider>
  );
}
