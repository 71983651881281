import React, { createContext, PropsWithChildren } from 'react';

import {
  QuestionnaireNodeIds,
  NodeIdDetailsByRepeatability,
} from '../../../Helpers/questionnaireEditor/questionnaireNodeIds';
import { useFetchQuestionnaireNodeIdsQuery } from '../../../ReactQuery/Admin/Questionnaire/questionnaireVersion.queries';

type QuestionnaireNodeIdsContextValue = {
  questionnaireNodeIds: QuestionnaireNodeIds;
};

const emptyNodeIdDetailsByRepeatability: NodeIdDetailsByRepeatability = {
  allLeafs: [],
  noRepeatability: [],
  withRepeatableAncestor: {},
  collection: [],
};

const initialValue: QuestionnaireNodeIdsContextValue = {
  questionnaireNodeIds: {
    inQuestionnaire: emptyNodeIdDetailsByRepeatability,
    notInQuestionnaire: emptyNodeIdDetailsByRepeatability,
    selectOptionsByNodeId: {},
  },
};

export const QuestionnaireNodeIdsContext = createContext<QuestionnaireNodeIdsContextValue>(initialValue);

export function QuestionnaireNodeIdsContextProvider({
  children,
}: PropsWithChildren<Record<string, unknown>>): React.ReactElement {
  const { data } = useFetchQuestionnaireNodeIdsQuery();
  const questionnaireNodeIds = data || initialValue.questionnaireNodeIds;

  return (
    <QuestionnaireNodeIdsContext.Provider value={{ questionnaireNodeIds }}>
      {children}
    </QuestionnaireNodeIdsContext.Provider>
  );
}
