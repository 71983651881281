import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import React, { useCallback } from 'react';

type Props = {
  name: string;
  disabled: boolean;
  data?: string;
  fileName: string;
};

export function ExportEntityButton({ name, disabled, data, fileName }: Props): React.ReactElement {
  const onExport = useCallback(() => {
    if (!data) return;

    const dataBlob = new Blob([data], { type: 'application/json' });
    const dataBlobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(dataBlob)
        : window.webkitURL.createObjectURL(dataBlob);

    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = dataBlobURL;
    tempLink.setAttribute('download', `${fileName}.json`);
    document.body.appendChild(tempLink);
    tempLink.click();
    tempLink.parentNode && tempLink.parentNode.removeChild(tempLink);
  }, [data, fileName]);

  return (
    <Button disabled={disabled} variant='outlined' color='primary' onClick={onExport} startIcon={<GetAppIcon />}>
      {name}
    </Button>
  );
}
