import Box from '@material-ui/core/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Permission, StoredFile, SupportedApplicationFileMimeTypes } from '@breathelife/types';

import { UploadButton } from '../../../../../../Components/Button/UploadButton';
import { RestrictedToPermission } from '../../../../../../Components/Restricted/RestrictedToPermission';
import { useCarrierContext } from '../../../../../../Hooks';
import { Application } from '../../../../../../Models/Application';
import { NoFiles } from '../NoFiles';
import { OtherFileItem } from './OtherFileItem';

type FileProps = {
  application: Application;
  files: StoredFile[];
  isUploading: boolean;
  isUploadDisabled: boolean;
  onFileUpload: (file: File) => void;
  onFileDelete: (fileId: string, isRequired: boolean) => void;
};

export function OtherFiles(props: FileProps): React.ReactElement | null {
  const { t } = useTranslation();
  const { application, files, isUploading, isUploadDisabled, onFileUpload, onFileDelete } = props;
  const {
    carrierInfo: { validFileUploadMimeTypes },
  } = useCarrierContext();

  const mimeTypes =
    validFileUploadMimeTypes && validFileUploadMimeTypes.length > 0
      ? validFileUploadMimeTypes
      : SupportedApplicationFileMimeTypes;

  const uploadFileButton = (
    <UploadButton
      onChange={onFileUpload}
      mimeTypes={mimeTypes}
      disabled={isUploadDisabled}
      isLoading={isUploading}
      loaderProps={{
        color: 'secondary',
      }}
      variant='contained'
      color='primary'
      component='label'
    >
      {t('assistedApplication.documentsDrawer.uploadFile')}
    </UploadButton>
  );

  if (!files.length) {
    return (
      <Box>
        <NoFiles message={t('assistedApplication.documentsDrawer.noFilesUploaded')} />
        <RestrictedToPermission permission={[Permission.ApplicationFileCreate]}>
          <Box display='flex' justifyContent='center' alignItems='center'>
            {uploadFileButton}
          </Box>
        </RestrictedToPermission>
      </Box>
    );
  }

  return (
    <Box px={2}>
      <Box>
        {files.map((storedFile) => (
          <OtherFileItem
            application={application}
            storedFile={storedFile}
            key={storedFile.id}
            onFileDelete={onFileDelete}
          />
        ))}
      </Box>
      <RestrictedToPermission permission={[Permission.ApplicationFileCreate]}>
        <Box py={10} display='flex' justifyContent='center' alignItems='center'>
          {uploadFileButton}
        </Box>
      </RestrictedToPermission>
    </Box>
  );
}
