import { Box } from '@material-ui/core';
import React, { useContext } from 'react';

import { ThirdPartyIntegrationCard } from './ThirdPartyIntegrationCard';
import { ThirdPartyIntegrationsContext } from './ThirdPartyIntegrationsContextProvider';

export function ThirdPartyIntegrationsBoard(): React.ReactElement {
  const { thirdPartyIntegrations } = useContext(ThirdPartyIntegrationsContext);
  return (
    <Box display='grid' gridAutoFlow={'row'} gridGap={24} gridTemplateColumns='repeat(auto-fill, minmax(250px, 1fr))'>
      {thirdPartyIntegrations.map((integration) => (
        <Box key={integration.id} flexGrow='grow'>
          <ThirdPartyIntegrationCard data={integration} />
        </Box>
      ))}
    </Box>
  );
}
