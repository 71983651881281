import Tooltip from '@material-ui/core/Tooltip';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const WIDTH_THRESHOLD = 300;

const StyledEmail = styled.div`
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: ${WIDTH_THRESHOLD}px;
`;

type EmailProps = {
  email: string;
};

export const Email = (props: EmailProps): React.ReactElement | null => {
  const emailContainerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const element = emailContainerRef.current;
    if (element) {
      setWidth(element.clientWidth);
    }
  }, [props.email]);

  const isTooltipEnabled = width >= WIDTH_THRESHOLD;

  return (
    <Tooltip
      disableFocusListener={!isTooltipEnabled}
      disableHoverListener={!isTooltipEnabled}
      disableTouchListener={!isTooltipEnabled}
      title={props.email || ''}
    >
      <StyledEmail ref={emailContainerRef}>{props.email}</StyledEmail>
    </Tooltip>
  );
};
