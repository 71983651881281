import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateUserData, User } from '@breathelife/types';

import { Helmet } from '../../../Components/Helmet';
import { useDispatch, useSelector } from '../../../Hooks';
import { DetailViewType, ModalType } from '../../../Models/Layout';
import { defaultState as defaultLayoutState, layoutSlice } from '../../../Redux/Layout/LayoutSlice';
import * as UserManagementOperation from '../../../Redux/UserManagement/UserManagementOperation';
import { userManagementSlice } from '../../../Redux/UserManagement/UserManagementSlice';
import { GetUsersOptions, SortParams } from '../../../Services/Users/UsersService';
import { UserManagementView } from './UserManagementView';
import { Filters } from './UsersTable/UsersTableHeader';

const { actions: userManagementActions } = userManagementSlice;

const PER_PAGE_OPTIONS = [
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

export function UserManagementViewContainer(): React.ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLoading = useSelector((store) => store.leadPlatform.userManagement.isLoading);
  const users = useSelector((store) => store.leadPlatform.userManagement.users);
  const modalState = useSelector((store) => store.leadPlatform.layout.modalState);
  const rightPanelState = useSelector((store) => store.leadPlatform.layout.rightPanelState);
  const defaultLimitValue = PER_PAGE_OPTIONS[1].value;

  const [hasSearchQuery, setHasSearchQuery] = useState(false);
  const [fetchUsersOptions, setFetchUsersOptions] = useState<GetUsersOptions>({
    page: 1,
    limit: defaultLimitValue,
    fields: [
      'id',
      'firstName',
      'lastName',
      'emailLogin',
      'createdAt',
      'auth0Id',
      'lastLogin',
      'roles',
      'picture',
      'groupId',
      'phoneNumber',
      'customId',
      'category',
      'type',
      'defaultInsuranceScopes',
      'status',
      'isSso',
    ],
    includeAssociationCounts: true,
  });

  useEffect(() => {
    if (!fetchUsersOptions) return;
    dispatch(UserManagementOperation.getUsers(fetchUsersOptions));
  }, [dispatch, fetchUsersOptions]);

  const onTableRowClick = useCallback(
    (data?: User) => {
      if (!data || !data.auth0Id) return;
      dispatch(UserManagementOperation.selectUser(data?.auth0Id));
      dispatch(
        layoutSlice.actions.setRightPanelState({
          rightPanelState: {
            isOpen: true,
            type: DetailViewType.edit,
          },
        })
      );
    },
    [dispatch]
  );

  const onOpenCreateUser = useCallback(() => {
    dispatch(
      layoutSlice.actions.setRightPanelState({
        rightPanelState: { isOpen: true, type: DetailViewType.create },
      })
    );
  }, [dispatch]);

  const onCloseUserDetails = useCallback(() => {
    if (!rightPanelState.isOpen) return;
    dispatch(userManagementActions.setSelectedUserIds({ selectedUserIds: [] }));
    dispatch(layoutSlice.actions.setRightPanelState({ rightPanelState: defaultLayoutState.rightPanelState }));
  }, [dispatch, rightPanelState.isOpen]);

  const onFiltersChanged = useCallback((filters?: Filters) => {
    setFetchUsersOptions((prevValue: GetUsersOptions) => ({ ...prevValue, filters, page: 1 }));
  }, []);

  const onSearchChange = useCallback((search: string) => {
    setHasSearchQuery(!!search);
    setFetchUsersOptions((prevValue: GetUsersOptions) => ({ ...prevValue, search, page: 1 }));
  }, []);

  const onPageChange = useCallback((page: number): void => {
    setFetchUsersOptions((prevValue: GetUsersOptions) => ({ ...prevValue, page }));
  }, []);

  const onPerPageChange = useCallback((perPage: number): void => {
    setFetchUsersOptions((prevValue: GetUsersOptions) => ({ ...prevValue, limit: perPage, page: 1 }));
  }, []);

  const onSortingChanged = useCallback((sort: SortParams) => {
    setFetchUsersOptions((prevValue: GetUsersOptions) => ({ ...prevValue, sort, page: 1 }));
  }, []);

  const onDeleteUser = useCallback(
    async (userId: string) => {
      const userCountBeforeDelete = users.length;
      await dispatch(UserManagementOperation.deleteUser(userId));
      setFetchUsersOptions((prevValue: GetUsersOptions) => {
        let page = prevValue.page ?? 1;
        if (userCountBeforeDelete === 1) {
          page = page !== 1 ? page - 1 : 1;
        }
        return {
          ...prevValue,
          page,
        };
      });
    },
    [dispatch, users]
  );

  const onUpdateUser = useCallback(
    async (userId: string, data: Partial<CreateUserData>) => {
      await dispatch(UserManagementOperation.updateUser(userId, data));
    },
    [dispatch]
  );

  const onSendInvitationEmail = useCallback(
    (user?: User) => {
      if (!user) return;

      dispatch(UserManagementOperation.sendInvitationEmail(user));
    },
    [dispatch]
  );

  const onOpenResendInvitationModal = useCallback(
    (data?: User) => {
      dispatch(
        layoutSlice.actions.setModalState({
          modalState: { user: data, isOpen: true, type: ModalType.userEmail },
        })
      );
    },
    [dispatch]
  );

  const onOpenDeleteUserConfirmationModal = useCallback(
    (data?: User) => {
      dispatch(
        layoutSlice.actions.setModalState({
          modalState: { user: data, isOpen: true, type: ModalType.deleteUser },
        })
      );
    },
    [dispatch]
  );

  const onOpenImportUsers = useCallback(() => {
    dispatch(
      layoutSlice.actions.setModalState({
        modalState: { isOpen: true, type: ModalType.importUsers },
      })
    );
  }, [dispatch]);

  const resetModalState = useCallback(
    () => dispatch(layoutSlice.actions.setModalState(defaultLayoutState)),
    [dispatch]
  );

  const shouldShowEmptySearchResultView = useMemo(() => {
    return hasSearchQuery && !isLoading && users.length < 1;
  }, [hasSearchQuery, isLoading, users]);

  return (
    <React.Fragment>
      <Helmet text={t('pageTitles.userManagement')} />

      <UserManagementView
        currentPage={fetchUsersOptions.page ?? 1}
        perPage={fetchUsersOptions.limit ?? defaultLimitValue}
        perPageOptions={PER_PAGE_OPTIONS}
        onSearchChange={onSearchChange}
        onFiltersChanged={onFiltersChanged}
        onOpenCreateUser={onOpenCreateUser}
        onPageChange={onPageChange}
        onPerPageChange={onPerPageChange}
        onSortingChanged={onSortingChanged}
        onTableRowClick={onTableRowClick}
        onUpdateUser={onUpdateUser}
        onOpenResendInvitationModal={onOpenResendInvitationModal}
        onOpenDeleteUserConfirmationModal={onOpenDeleteUserConfirmationModal}
        onOpenImportUsers={onOpenImportUsers}
        onCloseUserDetails={onCloseUserDetails}
        onDeleteUser={onDeleteUser}
        onSendInvitationEmail={onSendInvitationEmail}
        resetModalState={resetModalState}
        modalState={modalState}
        shouldShowEmptySearchResultView={shouldShowEmptySearchResultView}
      />
    </React.Fragment>
  );
}
