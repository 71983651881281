import Box from '@material-ui/core/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { CloseButton } from '../../../../Components/Button/CloseButton';
import Typography from '../../../../Components/Typography';

type Props = {
  onClose: () => void;
};

export function IdentityVerificationDrawerHeader(props: Props): React.ReactElement | null {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' m={4}>
        <Box display='flex' alignItems='center'>
          <Typography variant='h1'>{t('assistedApplication.identityVerificationReport')}</Typography>
        </Box>
        <CloseButton onClick={props.onClose} />
      </Box>
      <Box borderTop={`1px solid ${theme.colors.grey[40]}`}>
        <Box display='flex' alignItems='center' ml={4} mr={4} mb={3} mt={3}>
          <Typography variant='body1' grey={80}>
            {t('assistedApplication.identityVerificationReportInfo')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
