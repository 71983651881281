import React, { useCallback, useEffect } from 'react';

import { useDispatch, useNavigation, useSelector } from '../../../Hooks';
import { Application } from '../../../Models/Application';
import { DetailViewType, LeadDetailTab } from '../../../Models/Layout';
import { Lead } from '../../../Models/Lead';
import { closeRightPanel, openRightPanel } from '../../../Redux/Layout/LayoutOperations';
import { navigateToLeadsList } from '../../../Redux/Navigation/NavigationOperations';
import { resetProducts } from '../../../Redux/Products/ProductsOperations';
import { LeadDetailView } from './LeadDetailView';

type LeadDetailContainerProps = {
  onSendEmailClick: (lead: Lead) => void;
  onToggleArchiveClick: (lead: Lead, userId: string) => void;
  onCopyClick: (application: Application) => void;
  onDeleteClick: (lead: Lead) => void;
  onDownloadFilesClick: (lead: Lead) => void;
};

function LeadDetailContainer(props: LeadDetailContainerProps): React.ReactElement | null {
  const { onToggleArchiveClick: onToggleArchiveClickProp } = props;
  const dispatch = useDispatch();
  const { leadId: navigationLeadId, leadTab: navigationLeadTab } = useNavigation();
  const { user } = useSelector((store) => store.leadPlatform.authentication);
  const { isOpen, type } = useSelector((store) => store.leadPlatform.layout.rightPanelState);

  const onClose = useCallback(() => {
    dispatch(closeRightPanel());
    dispatch(navigateToLeadsList(navigationLeadTab));
    dispatch(resetProducts());
  }, [dispatch, navigationLeadTab]);

  useEffect(() => {
    // the panel should close
    if (isOpen && !navigationLeadId && type !== DetailViewType.create) {
      onClose();
    }

    // the panel should open
    if (!isOpen && navigationLeadId) {
      dispatch(openRightPanel(DetailViewType.read, LeadDetailTab.personalInfo));
    }
  }, [dispatch, navigationLeadId, isOpen, onClose, type]);

  const onToggleArchiveClick = useCallback(
    (lead: Lead) => {
      if (!user) return;
      onToggleArchiveClickProp(lead, user.auth0Id);
    },
    [onToggleArchiveClickProp, user]
  );

  return (
    <LeadDetailView
      {...props}
      isOpen={isOpen}
      onClose={onClose}
      onToggleArchiveClick={onToggleArchiveClick}
      viewType={type ?? DetailViewType.read}
    />
  );
}

export default LeadDetailContainer;
