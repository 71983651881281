import { StoredFile, StoredFileDocType, StoredFileWithSignedUrl } from '@breathelife/types';

import ApiService from './ApiService';

export type CreateApplicationFileData = {
  applicationId: string;
  templateId?: string;
  file: File;
  docType: StoredFileDocType;
};

export async function createApplicationFile(data: CreateApplicationFileData): Promise<StoredFile> {
  const response = await ApiService.createApplicationFile(data);
  return response.data;
}

export async function getApplicationFile(
  applicationId: string,
  fileId: string,
  options?: { download: boolean; validFor: number }
): Promise<StoredFileWithSignedUrl> {
  const response = await ApiService.getApplicationFileWithSignedUrl(applicationId, fileId, options);
  return response.data;
}

export async function fetchApplicationFiles(applicationId: string): Promise<StoredFile[]> {
  const response = await ApiService.fetchFilesForApplication(applicationId);
  return response.data;
}

export async function deleteApplicationFile(applicationId: string, fileId: string): Promise<StoredFile> {
  const response = await ApiService.deleteApplicationFile(applicationId, fileId);
  return response.data;
}
