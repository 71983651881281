import { CircularProgressProps } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import React, { ChangeEvent, useCallback, useMemo } from 'react';

import { Button } from './Button';

type BaseUploadButtonProps = Omit<ButtonProps, 'onChange'> & {
  onChange: (file: File) => void;
  children: React.ReactNode;
  isLoading?: boolean;
  loaderProps?: CircularProgressProps;
  // The `component` typing has been fixed in Material-ui Version 5. Unfortunately, in version 4, component
  // is not typed properly in `ButtonProps` but is supported by the Button API.
  component?: unknown;
};

type UploadButtonProps =
  | (BaseUploadButtonProps & { extensions: string[]; mimeTypes?: never })
  | (BaseUploadButtonProps & { mimeTypes: string[]; extensions?: never });

export function UploadButton(props: UploadButtonProps): React.ReactElement {
  const { children, onChange, extensions, mimeTypes, ...buttonProps } = props;

  const acceptAttribute = useMemo(() => {
    if (mimeTypes) {
      return mimeTypes.join(',');
    }
    return extensions?.map((ext) => `.${ext}`).join(',');
  }, [mimeTypes, extensions]);

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;

      if (files) {
        // Convert from `FileList` type to `File[]`
        const fileList = Array.from(Array(files.length).keys()).map((i) => files[i]);
        onChange(fileList[0]);
      }
      // Reset input to clear the information of the selected file
      // If we need to retry an upload with the same file, 'onchange' event will not happen if we don't first clear the input
      event.target.value = '';
    },
    [onChange]
  );

  return (
    <Button {...buttonProps}>
      {children}
      <input
        accept={acceptAttribute}
        style={{ display: 'none' }}
        id='upload-button'
        type='file'
        disabled={buttonProps.disabled}
        onChange={handleOnChange}
      />
    </Button>
  );
}
