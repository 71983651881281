import MenuItemMui from '@material-ui/core/MenuItem';
import styled from 'styled-components';

export const MenuItem = styled(MenuItemMui)`
  &:not(:disabled) {
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;

      svg {
        stroke: ${(props) => props.theme.colors.primary.default};
        fill: ${(props) => props.theme.colors.primary[20]};
      }
    }
  }
`;
