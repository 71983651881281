import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import { ButtonName, TypewriterTracking } from '@breathelife/react-tracking';

import { Icon } from '../../../Components/Icons';
import { useCarrierContext, useDispatch } from '../../../Hooks';
import { notificationSlice } from '../../../Redux/Notification/NotificationSlice';
import { UploadButton } from './Styles';

declare global {
  interface Window {
    cloudinary: {
      createUploadWidget: (options: Options, callback: ResultCallback) => Widget;
    };
  }
}

// Result events https://cloudinary.com/documentation/upload_widget#api_events
enum ResultEvents {
  success = 'success',
}

type CloudinaryUploadProps = {
  buttonText: string;
  minWidth?: number;
  minHeight?: number;
  uploadPreset?: string;
  setPicture: (pictureUrl: string) => void;
  uploadButtonRef?: React.Ref<HTMLButtonElement>;
  hideUploadButton?: boolean;
  clientAllowedFormats?: string[];
};

export function CloudinaryUpload(props: CloudinaryUploadProps): React.ReactElement | null {
  const { t } = useTranslation();
  const { cloudinaryConfig } = useCarrierContext();
  const theme = useContext(ThemeContext);

  const dispatch = useDispatch();
  const [widget, setWidget] = useState<Widget>();
  const [isCloudinaryLoaded, setCloudinaryLoaded] = useState(!!window.cloudinary);
  const { setPicture, minWidth, minHeight, uploadPreset, uploadButtonRef, hideUploadButton, clientAllowedFormats } =
    props;

  useEffect(() => {
    if (!cloudinaryConfig) return;

    if (window.cloudinary) {
      setCloudinaryLoaded(true);
      return;
    }

    const cloudinaryLoadedInterval = setInterval(() => {
      if (window.cloudinary) {
        setCloudinaryLoaded(true);
        clearInterval(cloudinaryLoadedInterval);
      }
    }, 200);

    return () => {
      clearInterval(cloudinaryLoadedInterval);
    };
    // Only allow to load the config on mount even if the cloudinaryConfig change.
    // This is to avoid multiple interval.
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!cloudinaryConfig || !isCloudinaryLoaded) return;

    const cloudinaryWidget = window.cloudinary.createUploadWidget(
      {
        styles: {
          palette: {
            windowBorder: theme.colors.primary.default,
            tabIcon: theme.colors.primary.default,
            menuIcons: theme.colors.grey[60],
            textDark: theme.colors.grey[90],
            link: theme.colors.primary.default,
            action: theme.colors.primary.default,
            sourceBg: theme.colors.grey[20],
          },
        },
        cloudName: cloudinaryConfig?.cloudName,
        uploadPreset: uploadPreset ?? cloudinaryConfig?.uploadPreset,
        folder: cloudinaryConfig?.folder,
        multiple: false,
        sources: ['local'],
        singleUploadAutoClose: false,
        theme: 'minimal',
        cropping: false,
        croppingAspectRatio: 1,
        croppingShowDimensions: true,
        croppingValidateDimensions: true,
        croppingCoordinatesMode: 'custom',
        maxImageFileSize: 5000000, // 5 MB
        clientAllowedFormats: clientAllowedFormats ?? ['jpg, jpeg', 'png', 'svg'],
        minImageWidth: minWidth ?? 200,
        minImageHeight: minHeight ?? 200,
      },
      (error: CloudinaryError, result: CloudinaryResult) => {
        if (error) {
          dispatch(notificationSlice.actions.setError({ message: error.statusText }));
          return;
        }
        if (result.event !== ResultEvents.success) return;
        setPicture(result.info.secure_url);
        dispatch(
          notificationSlice.actions.setSuccess({
            message: t('notifications.imageUploadSuccess'),
            autoHideDuration: 3000,
          })
        );
      }
    );
    setWidget(cloudinaryWidget);
  }, [cloudinaryConfig, dispatch, isCloudinaryLoaded, setPicture, minWidth, minHeight, uploadPreset, t, theme]);

  const openWidget = useCallback(() => {
    widget?.open();
    TypewriterTracking.clickedButton({ buttonName: ButtonName.userProfileUploadPicture, hashedId: null });
  }, [widget]);

  if (!cloudinaryConfig) return null;

  return (
    <React.Fragment>
      {!isCloudinaryLoaded && (
        <Helmet>
          <script src='https://widget.cloudinary.com/v2.0/global/all.js' type='text/javascript' />
        </Helmet>
      )}
      <UploadButton
        innerRef={uploadButtonRef}
        data-testid='cloudinary-uploadPicture'
        color='primary'
        startIcon={<Icon name='upload' variant='primary' />}
        onClick={openWidget}
        hideUploadButton={!!hideUploadButton}
      >
        {props.buttonText}
      </UploadButton>
    </React.Fragment>
  );
}
