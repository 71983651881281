import Grid from '@material-ui/core/Grid';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CharacterCountInBetweenConditionValue } from '@breathelife/types';

import { NumberInput } from '../../../Components/NumberInput/NumberInput';
import {
  getConditionValidationError,
  ValidationValues,
} from '../../../Helpers/inputValidation/form/salesDecisionRules';
import { CollectionOperators } from '../Helpers/CollectionOperators';
import { ConditionTargetNodeAutocomplete } from '../Helpers/ConditionTargetNodeAutocomplete';
import { ValidationErrorText } from '../ValidationErrorText';
import { ConditionCriteriaProps } from '../types';

export function CharacterRangeConditionCriteria({
  condition,
  nodeIdList,
  nodeIdInCollectionMap,
  collectionContext,
  onConditionPropertiesChange,
  language,
  conditionIndex,
}: ConditionCriteriaProps<CharacterCountInBetweenConditionValue>): React.ReactElement {
  const { t } = useTranslation();
  const validationErrors = getConditionValidationError(condition, nodeIdList, nodeIdInCollectionMap);

  const [minLength, setMinLength] = useState<number | undefined>(condition.value.minLength);
  const [maxLength, setMaxLength] = useState<number | undefined>(condition.value.maxLength);

  const onBlur = useCallback(() => {
    onConditionPropertiesChange({ value: { minLength: minLength, maxLength } });
  }, [onConditionPropertiesChange, minLength, maxLength]);

  const collectionNodeIds = nodeIdInCollectionMap[condition.targetNodeId] ?? [];

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <ConditionTargetNodeAutocomplete
          value={condition.targetNodeId}
          nodeIdList={nodeIdList}
          onSelect={(targetNodeId) => {
            if (targetNodeId !== null) {
              onConditionPropertiesChange({ targetNodeId });
            }
          }}
          validationError={validationErrors?.[ValidationValues.targetNodeId]}
          selectedLanguage={language}
        />
      </Grid>
      <Grid item xs={4}>
        <NumberInput
          inputVariant='outlined'
          value={minLength}
          onNumberChange={setMinLength}
          label={t('admin.conditions.labels.minLength')}
          onBlur={onBlur}
          error={!!validationErrors && !!validationErrors[ValidationValues.charactersMinLength]}
        />
        {validationErrors?.[ValidationValues.charactersMinLength] && (
          <ValidationErrorText>{validationErrors?.[ValidationValues.charactersMinLength]?.message}</ValidationErrorText>
        )}
      </Grid>
      <Grid item xs={4}>
        <NumberInput
          inputVariant='outlined'
          value={maxLength}
          onNumberChange={setMaxLength}
          label={t('admin.conditions.labels.maxLength')}
          onBlur={onBlur}
          error={!!validationErrors && !!validationErrors[ValidationValues.charactersMaxLength]}
        />
        {validationErrors?.[ValidationValues.charactersMaxLength] && (
          <ValidationErrorText>{validationErrors?.[ValidationValues.charactersMaxLength]?.message}</ValidationErrorText>
        )}
      </Grid>
      <CollectionOperators
        collectionContext={collectionContext}
        collectionNodeIds={collectionNodeIds}
        collectionOperators={condition.collectionOperators}
        onConditionPropertiesChange={onConditionPropertiesChange}
        conditionIndex={conditionIndex}
      />
    </React.Fragment>
  );
}
