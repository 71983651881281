import { Divider, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlueprintConditionValue, DynamicOptionsBlueprint, Language } from '@breathelife/types';
import { AutoCompleteMultiple, Box, SimpleCheckbox } from '@breathelife/ui-components';

import { Button } from '../../../../../Components/Button/Button';
import { NodeIdSelector } from '../../../../../Components/NodeIds/NodeIdSelector';
import Typography from '../../../../../Components/Typography';
import { NodeDetail, QuestionnaireNodeIds } from '../../../../../Helpers/questionnaireEditor/questionnaireNodeIds';
import { ModalLayout } from '../../../../../Layouts/Modal/ModalLayout';
import { ConditionsEditor } from '../Editors/ConditionsEditor';

const getNodeIdsOfCollection = (
  collectionNodeId: string | undefined,
  questionnaireNodeIds: QuestionnaireNodeIds
): NodeDetail[] => {
  if (collectionNodeId) {
    return [
      ...(questionnaireNodeIds.inQuestionnaire.withRepeatableAncestor[collectionNodeId] ?? []),
      ...(questionnaireNodeIds.notInQuestionnaire.withRepeatableAncestor[collectionNodeId] ?? []),
    ];
  }
  return [];
};

type Props = {
  disabled: boolean;
  isReadOnly: boolean;
  partName: string;
  dynamicOptions: DynamicOptionsBlueprint | undefined;
  questionnaireNodeIds: QuestionnaireNodeIds;
  selectedLanguage: Language;
  collectionContext: string[];
  onChange: (updatedBlueprint: DynamicOptionsBlueprint | undefined) => void;
  onSaveCondition: (condition?: BlueprintConditionValue) => void;
};

export function DynamicOptionsEditor(props: Props): React.ReactElement {
  const { disabled, selectedLanguage, dynamicOptions, questionnaireNodeIds, isReadOnly, collectionContext, partName } =
    props;
  const { t } = useTranslation();

  const [askingRemoveConfirmation, setAskingRemoveConfirmation] = useState(false);

  const nodeIdsOfCollection: string[] = useMemo(
    () => getNodeIdsOfCollection(dynamicOptions?.collection, questionnaireNodeIds).map((e) => e.answerNodeId),
    [dynamicOptions?.collection, questionnaireNodeIds]
  );

  return (
    <div>
      <ModalLayout
        maxWidth='sm'
        title={t('admin.questionnaireManagement.dynamicOptions.removeModal.title')}
        isOpen={askingRemoveConfirmation}
        closeModal={() => setAskingRemoveConfirmation(false)}
        submitButton={
          <Button
            variant='contained'
            onClick={() => {
              props.onChange(undefined);
              setAskingRemoveConfirmation(false);
            }}
          >
            {t('admin.questionnaireManagement.dynamicOptions.removeModal.confirmButton')}
          </Button>
        }
      >
        <Typography variant='body1' grey={70}>
          {t('admin.questionnaireManagement.dynamicOptions.removeModal.content')}
        </Typography>
      </ModalLayout>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <SimpleCheckbox
            id={`has-dynamic-options-editor-${partName}`}
            disabled={isReadOnly || disabled}
            checked={!!dynamicOptions}
            onChange={() => {
              if (dynamicOptions) {
                setAskingRemoveConfirmation(true);
              }
            }}
          />
          {t('admin.questionnaireManagement.dynamicOptions.title')}
        </ExpansionPanelSummary>
        <Divider />
        <ExpansionPanelDetails>
          <Box display='flex' flexDirection='column' width='100%'>
            <NodeIdSelector
              label={t('admin.questionnaireManagement.dynamicOptions.pickCollection')}
              nodeIds={questionnaireNodeIds.inQuestionnaire.collection}
              readOnly={disabled || isReadOnly}
              selectedNodeId={dynamicOptions?.collection || ''}
              onChange={(nodeId) => {
                const collectionId = nodeId?.value;

                if (collectionId && collectionId !== dynamicOptions?.collection) {
                  props.onChange({ ...dynamicOptions, collection: collectionId, select: [] });
                }
              }}
              selectedLanguage={selectedLanguage}
            />

            {dynamicOptions?.collection && (
              <Box pt={1}>
                <AutoCompleteMultiple
                  label={t('admin.questionnaireManagement.dynamicOptions.pickSelect')}
                  disabled={disabled || isReadOnly}
                  optionInfo={{
                    selectedItems: dynamicOptions.select,
                    items: nodeIdsOfCollection,
                    toOption: (text: string) => ({ label: text, value: text }),
                    onChange: (nodeIds: string[]) => {
                      props.onChange({ ...dynamicOptions, select: [...nodeIds] });
                    },
                  }}
                />
              </Box>
            )}

            <ConditionsEditor
              questionnaireNodeIds={questionnaireNodeIds}
              collectionContext={collectionContext}
              condition={dynamicOptions?.visible}
              isReadOnly={isReadOnly}
              label={t('admin.questionnaireManagement.rules.visibility.conditions')}
              editHeadingText={t('admin.questionnaireManagement.rules.visibility.edit')}
              selectedLanguage={selectedLanguage}
              saveCondition={props.onSaveCondition}
            />

            <Box pb={1} pt={1}>
              <Divider />
            </Box>

            <Typography variant='small1'>{t('admin.questionnaireManagement.dynamicOptions.note')}</Typography>
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
