import EditIcon from '@material-ui/icons/Edit';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  isFieldBlueprint,
  isQuestionBlueprint,
  isSectionBlueprint,
  isSubsectionBlueprint,
  PartIdentifier,
  PartIdentifierTag,
  QuestionnaireElementBlueprint,
  SectionBlueprint,
  SectionGroupBlueprint,
  SectionGroupKey,
} from '@breathelife/types';
import {
  AutocompleteOption,
  FormattedTreeViewItemData,
  TreeView,
  TreeViewRepositionOptions,
  UnformattedTreeViewItemData,
} from '@breathelife/ui-components';

import { Icon } from '../../../../../Components/Icons';
import { QuestionnaireSearchResult } from '../../../../../Helpers/questionnaireEditor/questionnaireSearch';
import { QuestionnaireEditorContext } from '../../QuestionnaireEditorContextProvider';
import { QuestionnaireVersionDataContext } from '../../QuestionnaireVersionDataContextProvider';
import { SectionGroupHeader } from '../Components/SectionGroupHeader';
import { arePartIdentifiersTheSame } from '../Helpers/arePartIdentifiersTheSame';
import { findElementBlueprintFromPartIdentifier } from '../Helpers/findBlueprintFromPartIdentifier';
import { isPartIdentifierAChild } from '../Helpers/isPartIdentifierAChild';
import { isPartIdentifierTheSameOrAChild } from '../Helpers/isPartIdentifierTheSameOrAChild';
import {
  getElementTypeFromTreePathLength,
  makePartIdentifierFromTreePath,
} from '../Helpers/makePartIdentifierFromTreePath';
import { useQuestionnaireClipboard } from '../Hooks/useQuestionnaireClipboard';
import { useQuestionnaireElementActions } from '../Hooks/useQuestionnaireElementActions';
import { useQuestionnaireReordering } from '../Hooks/useQuestionnaireReordering';
import { SelectedPartIdentifier } from '../types';
import { QuestionnaireTreeViewDragContext } from './QuestionnaireTreeViewDragContext';
import { SearchInfo } from './QuestionnaireTreeViewItem';
import {
  QuestionnaireTreeViewItemMetadata,
  QuestionnaireTreeViewItemWrapper,
} from './QuestionnaireTreeViewItemWrapper';

const TreeViewWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

type QuestionnaireTreeViewProps = {
  isEditingEnabled: boolean;
  dataLabelOptions: AutocompleteOption[];
  sectionGroupKey: SectionGroupKey;
  sectionGroupBlueprint?: SectionGroupBlueprint;
  sectionsInGroupBlueprints: SectionBlueprint[];
  forcedScrollToItemPathIdentifier?: string;
  onForceScrollToItemEnd?: () => void;
  handleItemClick: (data: FormattedTreeViewItemData) => void;
  openEditSectionGroupModal: () => void;
  openAddSectionModal: () => void;
  selectedPartIdentifier?: SelectedPartIdentifier;
  searchTerm: string;
  searchResults: QuestionnaireSearchResult[];
};

export function QuestionnaireSectionGroupTree(props: QuestionnaireTreeViewProps): React.ReactElement | null {
  const {
    isEditingEnabled,
    dataLabelOptions,
    sectionGroupKey,
    sectionGroupBlueprint,
    sectionsInGroupBlueprints,
    forcedScrollToItemPathIdentifier,
    onForceScrollToItemEnd,
    handleItemClick,
    openEditSectionGroupModal,
    openAddSectionModal,
    searchTerm,
    searchResults,
  } = props;

  const { questionnaireVersionData } = useContext(QuestionnaireVersionDataContext);
  const { blueprint: questionnaireBlueprint } = questionnaireVersionData || {};
  const { selectedPartIdentifier, selectedLanguage } = useContext(QuestionnaireEditorContext);

  const selectedSectionBlueprint =
    questionnaireBlueprint?.sectionBlueprints.find(
      (blueprint) => blueprint.partName === selectedPartIdentifier?.sectionPartName
    ) ?? questionnaireBlueprint?.sectionBlueprints[0];

  const selectedSectionGroupBlueprint = selectedSectionBlueprint
    ? questionnaireBlueprint?.sectionGroupBlueprints?.[selectedSectionBlueprint?.sectionGroupKey]
    : undefined;

  const { handleDragEnd, handleDragStart, handleDragUpdate } = useContext(QuestionnaireTreeViewDragContext);

  const { handleReposition, getNewParentPathFromPreviousTreeItemPath } = useQuestionnaireReordering();

  const {
    actions: { pasteSection },
    selectors: { copiedSection, copiedSubsection, copiedField, copiedFieldGroup },
  } = useQuestionnaireClipboard();

  const { copyItem } = useQuestionnaireElementActions({
    isQuestionnaireEditingEnabled: isEditingEnabled,
  });

  const { t } = useTranslation();

  const getBlueprintFromItemPath = useCallback(
    (path: string[]): QuestionnaireElementBlueprint | null => {
      if (path.length < 1 || !questionnaireBlueprint) {
        return null;
      }
      const partIdentifier = makePartIdentifierFromTreePath(path);
      return (
        findElementBlueprintFromPartIdentifier(questionnaireBlueprint, partIdentifier as PartIdentifier)?.blueprint ||
        null
      );
    },
    [questionnaireBlueprint]
  );

  const handleCopyItem = useCallback(
    (itemDataPath: FormattedTreeViewItemData['path']) => {
      const blueprint = getBlueprintFromItemPath(itemDataPath);
      if (!blueprint) {
        return;
      }
      copyItem(blueprint);
    },
    [copyItem, getBlueprintFromItemPath]
  );

  const checkIsItemSelected = useCallback(
    (itemPath: FormattedTreeViewItemData['path']) => {
      const partIdentifier = makePartIdentifierFromTreePath(itemPath);
      if (!partIdentifier || !selectedPartIdentifier) {
        return false;
      }
      return arePartIdentifiersTheSame(partIdentifier, selectedPartIdentifier);
    },
    [selectedPartIdentifier]
  );

  function shouldExpandChildrenOnItemExpansion(itemPath: string[]): boolean {
    const blueprint = getBlueprintFromItemPath(itemPath);
    return Boolean(blueprint && isSectionBlueprint(blueprint));
  }

  const getTreeViewDataItemFromQuestionnaireElement = useCallback(
    (questionnaireElement: unknown, currentPath: string[]): Omit<UnformattedTreeViewItemData, 'children'> => {
      const typedQuestionnaireElement = questionnaireElement as QuestionnaireElementBlueprint;
      let label = typedQuestionnaireElement.title?.[selectedLanguage];
      if (!label) {
        if (isSectionBlueprint(typedQuestionnaireElement)) {
          label = t('admin.questionnaireManagement.missingTitle.section');
        }
        if (isSubsectionBlueprint(typedQuestionnaireElement)) {
          label = t('admin.questionnaireManagement.missingTitle.subsection');
        }
        if (isQuestionBlueprint(typedQuestionnaireElement)) {
          label = t('admin.questionnaireManagement.missingTitle.question');
        }
        if (isFieldBlueprint(typedQuestionnaireElement)) {
          label = t('admin.questionnaireManagement.missingTitle.field');
        }
      }
      let searchInfo: SearchInfo = {};
      const itemPath = [...currentPath, typedQuestionnaireElement.partName];
      const partIdentifier = makePartIdentifierFromTreePath(itemPath);
      const isParentOfSelected =
        selectedPartIdentifier && partIdentifier && isPartIdentifierAChild(selectedPartIdentifier, partIdentifier);

      if (searchTerm && partIdentifier) {
        let numberOfSearchTermsInSelfAndChildren = 0;
        let numberOfSearchTermsInSelf = 0;
        let foundMatch = false;

        searchResults.forEach((result) => {
          const propertiesContainingSearchTerm = result.propertiesContainingSearchTerm;
          if (isPartIdentifierTheSameOrAChild(result.identifier, partIdentifier)) {
            numberOfSearchTermsInSelfAndChildren += propertiesContainingSearchTerm.length;
            if (
              partIdentifier.tag === PartIdentifierTag.field &&
              result.identifier.tag === PartIdentifierTag.selectOption
            ) {
              numberOfSearchTermsInSelf += propertiesContainingSearchTerm.length;
            }
          }
          if (!foundMatch && arePartIdentifiersTheSame(result.identifier, partIdentifier)) {
            foundMatch = true;
            numberOfSearchTermsInSelf += propertiesContainingSearchTerm.length;
          }
        });

        searchInfo = {
          numberOfSearchTermsInSelfAndChildren,
          numberOfSearchTermsInSelf,
        };
      }

      const forceHide = !!(searchTerm && (searchInfo.numberOfSearchTermsInSelfAndChildren || 0) < 1);

      return {
        identifier: typedQuestionnaireElement.partName,
        label: label || '',
        forceExpand: isParentOfSelected || !!searchInfo.numberOfSearchTermsInSelfAndChildren,
        forceHide,
        metadata: {
          searchInfo,
        } as QuestionnaireTreeViewItemMetadata,
      };
    },
    [searchResults, searchTerm, selectedLanguage, t, selectedPartIdentifier]
  );

  const getTreeViewItemChildrenFromQuestionnaireElement = useCallback(
    (
      questionnaireElement: QuestionnaireElementBlueprint,
      itemData: UnformattedTreeViewItemData
    ): QuestionnaireElementBlueprint[] => {
      const searchInfo = (itemData.metadata as QuestionnaireTreeViewItemMetadata)?.searchInfo;
      if (searchTerm) {
        const numberOfSearchTermsInChildren =
          (searchInfo?.numberOfSearchTermsInSelfAndChildren || 0) - (searchInfo?.numberOfSearchTermsInSelf || 0);
        if (numberOfSearchTermsInChildren < 1) {
          return [];
        }
      }

      const typedQuestionnaireElement = questionnaireElement as QuestionnaireElementBlueprint;
      if (isSectionBlueprint(typedQuestionnaireElement)) {
        return typedQuestionnaireElement.subsections;
      }
      if (isSubsectionBlueprint(typedQuestionnaireElement)) {
        return typedQuestionnaireElement.questions;
      }
      if (isQuestionBlueprint(typedQuestionnaireElement)) {
        return typedQuestionnaireElement.fields;
      }
      return [];
    },
    [searchTerm]
  );

  const onDragEnd = useCallback(
    (pathIdentifier?: string[], options?: TreeViewRepositionOptions) => {
      handleDragEnd();
      if (pathIdentifier && options) {
        handleReposition(pathIdentifier, options);
      }
    },
    [handleReposition, handleDragEnd]
  );

  if (!questionnaireBlueprint) {
    return null;
  }
  return (
    <React.Fragment>
      <SectionGroupHeader
        sectionGroupBlueprint={sectionGroupBlueprint}
        selectedLanguage={selectedLanguage}
        menuActions={[
          {
            tag: 'MenuAction',
            onClick: openEditSectionGroupModal,
            icon: <EditIcon />,
            label: 'Edit Section Group',
          },
          { tag: 'MenuDivider' },
          {
            tag: 'MenuAction',
            onClick: () => {
              if (copiedSection) {
                void pasteSection(sectionGroupKey, copiedSection);
              }
            },
            icon: <Icon name='files' />,
            disabled: copiedSection === null,
            label: t('admin.questionnaireManagement.clipboard.pasteSection'),
          },
          {
            tag: 'MenuAction',
            onClick: openAddSectionModal,
            icon: <Icon name='addAssured' />,
            disabled: false,
            label: t('admin.questionnaireManagement.input.addSection'),
          },
        ]}
      />

      <TreeViewWrapper>
        <TreeView
          onDragEnd={isEditingEnabled && !searchTerm ? onDragEnd : undefined}
          treeName={`questionnaire-tree-view-${sectionGroupKey}`}
          data={sectionsInGroupBlueprints}
          itemStyle={{ height: 34 }}
          shouldExpandChildrenOnItemExpansion={shouldExpandChildrenOnItemExpansion}
          getDataItemFromUnknownItem={getTreeViewDataItemFromQuestionnaireElement}
          getUnknownItemChildren={getTreeViewItemChildrenFromQuestionnaireElement}
          getNewParentPathFromPreviousTreeItemPath={getNewParentPathFromPreviousTreeItemPath}
          hideDefaultDragHandle
          forceScrollToItemPathIdentifier={forcedScrollToItemPathIdentifier || undefined}
          onForceScrollToItemEnd={onForceScrollToItemEnd}
          pathPrefix={[sectionGroupKey]}
          renderChild={({ data, dragHandleProps }) => {
            const elementType = getElementTypeFromTreePathLength(data.path.length);
            return (
              <QuestionnaireTreeViewItemWrapper
                questionnaireBlueprint={questionnaireBlueprint}
                isEditDisabled={!isEditingEnabled}
                data={data}
                dragHandleProps={isEditingEnabled ? dragHandleProps : undefined}
                canPaste={
                  !!(
                    (copiedSubsection && elementType === PartIdentifierTag.section) ||
                    (copiedFieldGroup && elementType === PartIdentifierTag.subsection) ||
                    (copiedField && elementType === PartIdentifierTag.question)
                  )
                }
                selected={checkIsItemSelected(data.path)}
                copyItem={handleCopyItem}
                onClick={handleItemClick}
                dataLabelOptions={dataLabelOptions}
                sectionGroupCollectionNodeId={selectedSectionGroupBlueprint?.repeatable?.repeatableAnswerNodeId}
              />
            );
          }}
          onDragUpdate={handleDragUpdate}
          onDragStart={(draggedPath) => {
            handleDragStart(draggedPath);
          }}
        />
      </TreeViewWrapper>
    </React.Fragment>
  );
}
