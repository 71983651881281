import { Grid, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InfoSupplementBlueprint, Language, Localizable } from '@breathelife/types';
import { Input as TextInput, ReadonlyFormControlLabel, Box } from '@breathelife/ui-components';

import { SubmitButton } from '../../../../../Components/Button/SubmitButton';
import { useCarrierContext } from '../../../../../Hooks';
import { ModalLayout } from '../../../../../Layouts/Modal/ModalLayout';
import { SummaryText } from '../Components/SummaryText';

type Props = {
  save: (item?: InfoSupplementBlueprint) => void;
  infoSupplement?: InfoSupplementBlueprint;
  isReadOnly: boolean;
  selectedLanguage: Language;
};

export function InfoSupplementEditor(props: Props): React.ReactElement {
  const { save, infoSupplement, isReadOnly, selectedLanguage } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen]);

  return (
    <React.Fragment>
      {isModalOpen && (
        <InfoSupplementModal save={save} closeModal={closeModal} existingInfoSupplement={infoSupplement} />
      )}
      <Box pt={2} pb={2} pr={1}>
        <InfoSupplementSummaryWithEditButton
          infoSupplement={infoSupplement}
          setIsModalOpen={setIsModalOpen}
          isReadOnly={isReadOnly}
          selectedLanguage={selectedLanguage}
        />
      </Box>
    </React.Fragment>
  );
}

function InfoSupplementSummaryWithEditButton(props: {
  setIsModalOpen: (isOpen: boolean) => void;
  infoSupplement?: InfoSupplementBlueprint;
  isReadOnly: boolean;
  selectedLanguage: Language;
}): React.ReactElement {
  const { infoSupplement, selectedLanguage, setIsModalOpen, isReadOnly } = props;
  const { t } = useTranslation();

  const summaryControl = useMemo(() => {
    if (infoSupplement) {
      // Titles will generally be shorter, use it for the summary if it's available.
      const supplementTitle = infoSupplement.title ? infoSupplement.title[selectedLanguage] : undefined;
      return (
        <Box pr={1}>
          <SummaryText text={supplementTitle ?? infoSupplement.text[selectedLanguage]} />
        </Box>
      );
    }

    return (
      <Box pr={1}>
        <SummaryText fallbackText={t('admin.questionnaireManagement.input.noneSet')} />
      </Box>
    );
  }, [infoSupplement, selectedLanguage, t]);

  return (
    <Grid container alignItems='flex-end' justify='space-between'>
      <Grid item xs={10}>
        <ReadonlyFormControlLabel
          label={t('admin.questionnaireManagement.infoSupplement')}
          showError={false}
          labelPlacement='top'
          control={summaryControl}
        />
      </Grid>
      <Grid item>
        {!isReadOnly && (
          <IconButton onClick={() => setIsModalOpen(true)}>
            <EditIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
}

function InfoSupplementModal(props: {
  closeModal: () => void;
  save: (infoSupplement?: InfoSupplementBlueprint) => void;
  existingInfoSupplement?: InfoSupplementBlueprint;
}): React.ReactElement {
  const { existingInfoSupplement, closeModal, save } = props;
  const { languageSettings } = useCarrierContext();
  const enabledLanguages = languageSettings.enabledLanguages;

  const { t } = useTranslation();
  const [title, setTitle] = useState<Partial<Localizable>>(existingInfoSupplement?.title ?? {});
  const [text, setText] = useState<Partial<Localizable>>(existingInfoSupplement?.text ?? {});

  const textValidationError = useMemo(() => {
    const isAnyValueSet = enabledLanguages.some((language) => title[language] || text[language]);

    const textErrors = enabledLanguages.reduce((currentErrors, language) => {
      if (!text[language]) {
        currentErrors[language] = { message: t('admin.questionnaireManagement.input.requiredIfAnySet') };
      }

      return currentErrors;
    }, {});

    return isAnyValueSet ? textErrors : {};
  }, [text, title, enabledLanguages, t]);

  // Text is required when an info supplement exists (if every text/title input is empty then the info supplement will be removed, it's not required for that case)
  const isFormValid = !enabledLanguages.some((language) => textValidationError[language]);

  return (
    <ModalLayout
      maxWidth='md'
      isOpen={true}
      closeModal={closeModal}
      title={t('admin.questionnaireManagement.editInfoSupplement')}
      submitButton={
        <SubmitButton
          disabled={!isFormValid}
          onClick={() => {
            // When the text and title are both empty, remove the supplement.
            const isTextSet = enabledLanguages.every((language) => text[language]);
            const isTitleSet = enabledLanguages.every((language) => title[language]);
            const shouldRemoveSupplement = !isTextSet && !isTitleSet;

            const infoSupplement = shouldRemoveSupplement
              ? undefined
              : {
                  title,
                  text,
                };

            save(infoSupplement);
            closeModal();
          }}
          data-testid='questionnaire-editor-save-info-supplement'
        >
          {t('cta.save')}
        </SubmitButton>
      }
    >
      <Grid container spacing={2} alignItems='flex-start'>
        {enabledLanguages.map((language) => (
          <Grid item xs={6} key={`info-supplement-title-${language}`}>
            <TextInput
              inputVariant='outlined'
              label={t('admin.questionnaireManagement.input.titleAndLanguage', { language: t(`language.${language}`) })}
              value={title[language] ?? ''}
              onChange={(event) => {
                const value = event.target.value;
                setTitle((previousValue) => ({ ...previousValue, [language]: value }));
              }}
            />
          </Grid>
        ))}
        {enabledLanguages.map((language) => (
          <Grid item xs={12} key={`info-supplement-text-${language}`}>
            <TextInput
              inputVariant='outlined'
              label={t('admin.questionnaireManagement.input.textAndLanguage', { language: t(`language.${language}`) })}
              multiline={true}
              value={text[language] ?? ''}
              validationError={textValidationError[language]}
              onChange={(event) => {
                const value = event.target.value;
                setText((previousValue) => ({ ...previousValue, [language]: value }));
              }}
            />
          </Grid>
        ))}
      </Grid>
    </ModalLayout>
  );
}
