import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/AddRounded';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FileTemplateOrigin } from '@breathelife/types';

import { ActionButton } from '../../../Components/Button/ActionButton';
import { Helmet } from '../../../Components/Helmet';
import { Select } from '../../../Components/Select/Select';
import { useModalState } from '../../../Hooks';
import { DocumentsTable } from './DocumentsTable';
import { NewDocumentDrawer } from './NewDocumentDrawer';

type StringInputChangePayload = {
  value: string;
  path: string;
};

type OriginInputChangePayload = {
  value: FileTemplateOrigin;
  path: string;
};

export type ValueChangeFunctionPayload = StringInputChangePayload | OriginInputChangePayload;

export const ModalContent = styled.div`
  padding-bottom: 24px;
  color: ${(props) => props.theme.colors.grey[90]};
`;

enum TemplateStatus {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

export function DocumentManagementViewContainer(): React.ReactElement {
  const { t } = useTranslation();

  const [isNewDocumentDrawerOpen, openNewDocumentTemplateDialog, closeNewDocumentTemplateDialog] = useModalState();
  const [templateStatusFilter, setTemplateStatusFilter] = useState(TemplateStatus.ACTIVE);

  const selectOptions: { label: string; value: TemplateStatus }[] = useMemo(
    () => [
      { label: t('admin.documentManagement.archiveStatusSelect.active'), value: TemplateStatus.ACTIVE },
      { label: t('admin.documentManagement.archiveStatusSelect.archived'), value: TemplateStatus.ARCHIVED },
    ],
    [t]
  );

  return (
    <React.Fragment>
      <Helmet text={t('pageTitles.documents')} />

      <NewDocumentDrawer isOpen={isNewDocumentDrawerOpen} onClose={closeNewDocumentTemplateDialog} />

      <Box display='flex' justifyContent='space-between' alignItems='center' m={2}>
        <Box>
          <Select
            id='display-template-status'
            options={selectOptions}
            value={templateStatusFilter}
            onChange={setTemplateStatusFilter}
          />
        </Box>

        <ActionButton
          data-testid='addNewDocumentButton'
          color='primary'
          variant='contained'
          onClick={openNewDocumentTemplateDialog}
          startIcon={<AddIcon htmlColor='white' />}
        >
          {t('cta.addNew')}
        </ActionButton>
      </Box>

      <Box m={2}>
        <DocumentsTable displayArchived={templateStatusFilter === TemplateStatus.ARCHIVED} />
      </Box>
    </React.Fragment>
  );
}
