import i18next from 'i18next';
import { useMutation, useQueryClient, UseMutationResult, UseMutationOptions } from 'react-query';

import { CreateRequest, UpdateRequest, InsuranceFirm } from '@breathelife/types';

import { useDispatch } from '../../../Hooks';
import { QueryId } from '../../../ReactQuery/common/common.types';
import { notificationSlice } from '../../../Redux/Notification/NotificationSlice';
import { createInsuranceFirm, updateInsuranceFirm } from '../../../Services/InsuranceFirmService';

export function useCreateFirmMutation(
  options?: UseMutationOptions<InsuranceFirm, unknown, CreateRequest<InsuranceFirm>>
): UseMutationResult<InsuranceFirm, unknown, CreateRequest<InsuranceFirm>> {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const entity = i18next.t('admin.firmManagement.insuranceFirm.singular');

  return useMutation<InsuranceFirm, unknown, CreateRequest<InsuranceFirm>, unknown>(createInsuranceFirm, {
    ...options,
    onSuccess: async (data, variables, context): Promise<void> => {
      dispatch(
        notificationSlice.actions.setSuccess({
          message: i18next.t('notifications.entityCreationSuccess', { entity }),
          autoHideDuration: 3000,
        })
      );

      await queryClient.invalidateQueries(QueryId.firms);

      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },

    onError: async (error, variables, context) => {
      dispatch(
        notificationSlice.actions.setError({
          message: i18next.t('notifications.failedToCreateEntity', { entity }),
        })
      );

      if (options?.onError) {
        await options.onError(error, variables, context);
      }
    },
  });
}

type UpdateFirmParameters = {
  id: string;
  data: UpdateRequest<InsuranceFirm>;
};

export function useUpdateFirmMutation(
  options?: UseMutationOptions<InsuranceFirm, unknown, UpdateFirmParameters>
): UseMutationResult<InsuranceFirm, unknown, UpdateFirmParameters> {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const entity = i18next.t('admin.firmManagement.insuranceFirm.singular');
  return useMutation<InsuranceFirm, unknown, UpdateFirmParameters, unknown>(
    async ({ data, id }: UpdateFirmParameters) => await updateInsuranceFirm(id, data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        dispatch(
          notificationSlice.actions.setSuccess({
            message: i18next.t('notifications.entityUpdateSuccess', { entity }),
            autoHideDuration: 3000,
          })
        );

        await queryClient.invalidateQueries(QueryId.firms);
        await queryClient.invalidateQueries([QueryId.firm, data.id]);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },

      onError: async (error, variables, context) => {
        dispatch(
          notificationSlice.actions.setError({
            message: i18next.t('notifications.failedToUpdateEntity', { entity }),
          })
        );

        if (options?.onError) {
          await options.onError(error, variables, context);
        }
      },
    }
  );
}
