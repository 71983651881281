import { captureException } from '@sentry/browser';
import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useDispatch } from 'react-redux';

import {
  QuestionnaireVersionInfo,
  QuestionnaireVersionInfoPage,
  PageQueryOptions,
  QuestionnaireVersionRowData,
} from '@breathelife/types';

import {
  generateQuestionnaireNodeIdsFromQuestionnaire,
  QuestionnaireNodeIds,
} from '../../../Helpers/questionnaireEditor/questionnaireNodeIds';
import { QueryId } from '../../../ReactQuery/common/common.types';
import { notificationSlice } from '../../../Redux/Notification/NotificationSlice';
import { fetchQuestionnaire } from '../../../Services/QuestionnaireService';
import {
  fetchAllQuestionnaireVersions,
  fetchQuestionnaireVersionAndNodeIdData,
  fetchQuestionnaireVersions,
  QuestionnaireVersionDetailWithNodeIdInfo,
} from '../../../Services/QuestionnaireVersionService';

export function useFetchQuestionnaireVersionsQuery(
  fetchOptions: PageQueryOptions<QuestionnaireVersionRowData>,
  options?: UseQueryOptions<QuestionnaireVersionInfoPage>
): UseQueryResult<QuestionnaireVersionInfoPage> {
  const queryId = [QueryId.questionnaireVersions, fetchOptions];
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useQuery<QuestionnaireVersionInfoPage>(queryId, () => fetchQuestionnaireVersions(fetchOptions), {
    ...options,
    onError: (err) => {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToFetchEntity', {
            entity: t('admin.questionnaireManagement.questionnaireListData'),
          }),
        })
      );
      captureException(err);
      options?.onError?.(err);
    },
  });
}

export function useFetchAllQuestionnaireVersionsQuery(
  options?: UseQueryOptions<QuestionnaireVersionInfo[]>
): UseQueryResult<QuestionnaireVersionInfo[]> {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useQuery<QuestionnaireVersionInfo[]>(QueryId.allQuestionnaireVersions, fetchAllQuestionnaireVersions, {
    ...options,
    onError(err) {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToFetchEntity', {
            entity: t('admin.questionnaireManagement.questionnaireListData'),
          }),
        })
      );
      captureException(err);
      options?.onError?.(err);
    },
  });
}

export function useFetchQuestionnaireVersionQuery(
  questionnaireId: string,
  options?: UseQueryOptions<QuestionnaireVersionDetailWithNodeIdInfo>
): UseQueryResult<QuestionnaireVersionDetailWithNodeIdInfo> {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useQuery<QuestionnaireVersionDetailWithNodeIdInfo>(
    [QueryId.questionnaireVersion, questionnaireId],
    async () => await fetchQuestionnaireVersionAndNodeIdData(questionnaireId),
    {
      ...options,
      onError: (err) => {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToFetchEntity', {
              entity: t('admin.questionnaireManagement.questionnaireEditorData'),
            }),
          })
        );
        captureException(err);
        options?.onError?.(err);
      },
    }
  );
}

export function useFetchQuestionnaireNodeIdsQuery(
  options?: UseQueryOptions<QuestionnaireNodeIds>
): UseQueryResult<QuestionnaireNodeIds> {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useQuery<QuestionnaireNodeIds>(
    [QueryId.questionnaireNodeIds],
    async () => {
      const data = await fetchQuestionnaire();
      const { questionnaire } = data;
      return generateQuestionnaireNodeIdsFromQuestionnaire(questionnaire);
    },
    {
      ...options,
      onError: (err) => {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToFetchEntity', {
              entity: t('admin.questionnaireManagement.nodeIds'),
            }),
          })
        );
        captureException(err);
        options?.onError?.(err);
      },
    }
  );
}
