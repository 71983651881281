import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LoaderOverlay } from '@breathelife/ui-components';

import { CircledSendIcon } from '../../../../Assets/CircledSendIcon';
import Typography from '../../../../Components/Typography';
import { Application } from '../../../../Models/Application';
import { useGetJetDecisionOutcomesQuery } from '../../../../ReactQuery/JetDecision/jetDecision.queries';
import { JetDecisionOutcomeAccordion } from './JetDecisionOutcomeAccordion';
import { Container, WidgetHeader, WidgetBody, AccordionWrapper, ButtonWrapper } from './Styles';

type Props = {
  application?: Application;
};

export function JetDecisionWidget(props: Props) {
  const { application } = props;

  const { t } = useTranslation();
  const [isWidgetOpen, setIsWidgetOpen] = useState<boolean>(false);
  const { isFetching, data: jetDecisionOutcomes } = useGetJetDecisionOutcomesQuery(application?.id);

  return isWidgetOpen ? (
    <Container>
      <WidgetHeader>
        <Typography variant='h4'>{t('assistedApplication.jetDecisionWidget.title')}</Typography>
        <IconButton key='close' aria-label='Close' onClick={() => setIsWidgetOpen(false)}>
          <CloseIcon />
        </IconButton>
      </WidgetHeader>
      <WidgetBody>
        <LoaderOverlay isLoading={isFetching}>
          <AccordionWrapper>
            {jetDecisionOutcomes &&
              jetDecisionOutcomes.map((jetDecisionOutcome) => (
                <JetDecisionOutcomeAccordion key={jetDecisionOutcome.participantId} {...jetDecisionOutcome} />
              ))}
          </AccordionWrapper>
        </LoaderOverlay>
      </WidgetBody>
    </Container>
  ) : (
    <ButtonWrapper>
      <IconButton key='open' aria-label='Open' onClick={() => setIsWidgetOpen(true)}>
        <CircledSendIcon />
      </IconButton>
    </ButtonWrapper>
  );
}
