import Box from '@material-ui/core/Box';
import styled from 'styled-components';

import Typography from '../../../../Components/Typography';
import { emptyTableHeight } from '../../../../Models/Layout';

export const EmptySearchResultContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: ${() => `calc(100vh - ${emptyTableHeight}px)`};
  min-height: 300px;
`;

export const EmptyListContainer = styled(Box)`
  background-color: ${(props) => props.theme.colors.grey[30]};
  height: ${() => `calc(100vh - ${emptyTableHeight}px)`};
`;

export const ListTitle = styled(Typography)`
  && {
    line-height: 1.2em;
  }
`;

export const ListText = styled(Typography)`
  && {
    line-height: 1.2em;
  }
`;

export const Img = styled.img`
  display: inline-block;
`;

export const tableStyles = {
  boxShadow: 'none',
  paddingTop: 0,
};

export const StyledSearchContainer = styled(Box)`
  min-width: 24rem;
`;
