import { useCallback, useState } from 'react';

import { DataLabel } from '@breathelife/meta-cruncher';
import {
  InsuranceScopes,
  PartIdentifier,
  PlatformType,
  QuestionnaireBlueprintRenderOn,
  QuestionnaireBlueprintCopyableOption,
} from '@breathelife/types';

import { dataLabelSelectionToDataLabel } from '../../../../../Helpers/questionnaireEditor/selectOptions';

type BlueprintUpdate<T extends PartIdentifier> = (update: {
  partIdentifier: T;
  update:
    | { property: 'platforms'; value: PlatformType[] }
    | { property: 'renderOn'; value: QuestionnaireBlueprintRenderOn[] }
    | { property: 'scopes'; value: InsuranceScopes[] }
    | { property: 'dataLabel'; value: DataLabel | undefined }
    | { property: 'copyable'; value: QuestionnaireBlueprintCopyableOption | undefined };
}) => Promise<void>;

type Options<T extends PartIdentifier> = {
  partIdentifier: T;
  blueprintUpdate: BlueprintUpdate<T>;
};

type HookOutput = {
  actions: {
    setPlatforms: (updatedPlatforms: PlatformType[], withDispatch?: boolean) => void;
    setRenderOn: (updatedRenderOn: QuestionnaireBlueprintRenderOn[], withDispatch?: boolean) => void;
    setScopes: (updatedScopes: InsuranceScopes[], withDispatch?: boolean) => void;
    setDataLabel: (updatedDataLabel: string, withDispatch?: boolean) => void;
    setCopyable: (updatedCopyableOption: QuestionnaireBlueprintCopyableOption, withDispatch?: boolean) => void;
  };
  selectors: {
    platforms: PlatformType[];
    renderOn: QuestionnaireBlueprintRenderOn[];
    scopes: InsuranceScopes[];
    dataLabel: string;
    copyable: QuestionnaireBlueprintCopyableOption;
  };
};

export function useAdvancedBlueprintOptions<T extends PartIdentifier>({
  partIdentifier,
  blueprintUpdate,
}: Options<T>): HookOutput {
  // TODO: Change `string` to `DataLabel` when Autocomplete components have better type support.
  const [dataLabel, setDataLabel] = useState<string>('');

  const [renderOn, setRenderOn] = useState<QuestionnaireBlueprintRenderOn[]>([]);
  const [scopes, setScopes] = useState<InsuranceScopes[]>([]);
  const [platforms, setPlatforms] = useState<PlatformType[]>([]);
  const [copyable, setCopyable] = useState<QuestionnaireBlueprintCopyableOption>(
    QuestionnaireBlueprintCopyableOption.none
  );

  const setAndDispatchPlatforms = useCallback(
    (updatedPlatforms: PlatformType[], withDispatch: boolean = true): void => {
      setPlatforms(updatedPlatforms);

      if (withDispatch) {
        void blueprintUpdate({ partIdentifier, update: { property: 'platforms', value: updatedPlatforms } });
      }
    },
    [setPlatforms]
  );

  const setAndDispatchRenderOn = useCallback(
    (updatedRenderOn: QuestionnaireBlueprintRenderOn[], withDispatch: boolean = true): void => {
      setRenderOn(updatedRenderOn);

      if (withDispatch) {
        void blueprintUpdate({ partIdentifier, update: { property: 'renderOn', value: updatedRenderOn } });
      }
    },
    [setRenderOn]
  );

  const setAndDispatchInsuranceScopes = useCallback(
    (updatedScopes: InsuranceScopes[], withDispatch: boolean = true): void => {
      setScopes(updatedScopes);

      if (withDispatch) {
        void blueprintUpdate({ partIdentifier, update: { property: 'scopes', value: updatedScopes } });
      }
    },
    [setScopes]
  );

  const setAndDispatchDataLabel = useCallback(
    (updatedDataLabel: string, withDispatch: boolean = true): void => {
      const dataLabel = dataLabelSelectionToDataLabel(updatedDataLabel);
      setDataLabel(updatedDataLabel);

      if (withDispatch) {
        void blueprintUpdate({ partIdentifier, update: { property: 'dataLabel', value: dataLabel } });
      }
    },
    [setDataLabel]
  );

  const setAndDispatchCopyable = useCallback(
    (updatedCopyableOption: QuestionnaireBlueprintCopyableOption, withDispatch: boolean = true): void => {
      setCopyable(updatedCopyableOption);

      if (withDispatch) {
        void blueprintUpdate({ partIdentifier, update: { property: 'copyable', value: updatedCopyableOption } });
      }
    },
    [setCopyable]
  );

  return {
    actions: {
      setPlatforms: setAndDispatchPlatforms,
      setRenderOn: setAndDispatchRenderOn,
      setScopes: setAndDispatchInsuranceScopes,
      setDataLabel: setAndDispatchDataLabel,
      setCopyable: setAndDispatchCopyable,
    },
    selectors: {
      platforms,
      renderOn,
      scopes,
      dataLabel,
      copyable,
    },
  };
}
