import i18next from 'i18next';
import * as yup from 'yup';

import { isValidPhoneNumberAreaCode, phoneAreaCodeList, phoneNumberRegex } from '@breathelife/questionnaire-engine';

export const getPhoneNumberSchema = (): yup.StringSchema =>
  yup
    .string()
    .matches(phoneNumberRegex, { message: i18next.t('validation.error.phoneNumber'), excludeEmptyString: true })
    .test({
      test: (value) => isValidPhoneNumberAreaCode(value, phoneAreaCodeList),
      message: i18next.t('validation.error.phoneNumberAreaCode'),
    });
