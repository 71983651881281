import { Typography } from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  IconName,
  Language,
  Localizable,
  SubsectionBlueprint,
  SubsectionPartIdentifier,
  SubsectionVariant,
  QuestionnaireBlueprintCopyableOption,
} from '@breathelife/types';
import { AutocompleteOption, StyledFormControlLabel, Box, SimpleCheckbox } from '@breathelife/ui-components';

import { QuestionnaireNodeIds } from '../../../../../Helpers/questionnaireEditor/questionnaireNodeIds';
import { useCarrierContext } from '../../../../../Hooks';
import { TextInput } from '../../../../../Pages/Admin/Questionnaire/QuestionnaireEditor/Components/TextInput';
import { SetSelectedPartIdentifier } from '../../../../../Pages/Admin/Questionnaire/QuestionnaireEditor/types';
import { useUpdateQuestionnaireElementBlueprint } from '../../../../../ReactQuery/Admin/Questionnaire/questionnaireVersion.mutations';
import { QuestionnaireVersionDataContext } from '../../QuestionnaireVersionDataContextProvider';
import { AdvancedBlueprintOptions } from '../Components/AdvancedBlueprintOptions';
import { IconNameSelector } from '../Components/IconNameSelector';
import { getBlueprintVariantName } from '../Helpers/getBlueprintvariantName';
import { useAdvancedBlueprintOptions } from '../Hooks/useAdvancedBlueprintOptions';
import { useHighlightedTextInBlueprintElement } from '../Hooks/useHighlightedTextInBlueprintElement';
import { HighlightedContent } from '../TreeView/HighlightedContent';
import { ConditionsEditor } from './ConditionsEditor';

type Props = {
  blueprint: SubsectionBlueprint;
  sectionGroupCollectionNodeId: string | undefined;
  questionnaireNodeIds: QuestionnaireNodeIds;
  dataLabelOptions: AutocompleteOption[];
  partIdentifier: SubsectionPartIdentifier;
  setSelectedPartIdentifier: SetSelectedPartIdentifier;
  selectedLanguage: Language;
  parentHidden: boolean;
  isEditingEnabled: boolean;
  disableCopyable: boolean;
};

export function SubsectionBlueprintEditor(props: Props): React.ReactElement {
  const {
    partIdentifier,
    blueprint,
    sectionGroupCollectionNodeId,
    selectedLanguage,
    parentHidden,
    isEditingEnabled,
    questionnaireNodeIds,
    dataLabelOptions,
    disableCopyable,
  } = props;

  const { t } = useTranslation();
  const { showBlueprintIdInEditor } = useCarrierContext();

  const [title, setTitle] = useState<Partial<Localizable>>({});
  const [text, setText] = useState<Partial<Localizable>>({});
  const [iconName, setIconName] = useState<IconName | undefined>();
  const [nextStepButtonText, setNextStepButtonText] = useState<Partial<Localizable>>({});
  const [showInNavigation, setShowInNavigation] = useState(false);
  const [pageBreakSubSectionInPdf, setPageBreakSubSectionInPdf] = useState(false);
  const { questionnaireVersionId } = useContext(QuestionnaireVersionDataContext);

  const blueprintUpdate = useUpdateQuestionnaireElementBlueprint(questionnaireVersionId);

  const {
    actions: { setPlatforms, setScopes, setRenderOn, setDataLabel, setCopyable },
    selectors: { platforms, scopes, renderOn, dataLabel, copyable },
  } = useAdvancedBlueprintOptions<SubsectionPartIdentifier>({
    partIdentifier,
    blueprintUpdate,
  });

  const [isInitialized, setIsInitialized] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isInitialized) return;

    const initialTitle = blueprint.title;
    if (initialTitle) {
      setTitle(initialTitle);
    }

    const initialText = blueprint.text;
    if (initialText) {
      setText(initialText);
    }

    const initialIconName = blueprint.iconName;
    if (initialIconName) {
      setIconName(initialIconName);
    }

    const initialNextStepButtonText = blueprint.nextStepButtonText;
    if (initialNextStepButtonText) {
      setNextStepButtonText(initialNextStepButtonText);
    }

    const initialShowInNavigation = blueprint.showInNavigation;
    if (initialShowInNavigation) {
      setShowInNavigation(initialShowInNavigation);
    }

    const initialPageBreakSubSectionInPdf = blueprint.pageBreakSubSectionInPdf;
    if (initialPageBreakSubSectionInPdf) {
      setPageBreakSubSectionInPdf(initialPageBreakSubSectionInPdf);
    }

    const initialDataLabel = blueprint.dataLabel;
    if (initialDataLabel) {
      setDataLabel(initialDataLabel, false);
    } else if (dataLabelOptions?.length) {
      setDataLabel(dataLabelOptions[0].value, false);
    }

    setPlatforms(blueprint.platforms ?? [], false);
    setRenderOn(blueprint.renderOn ?? [], false);
    setScopes(blueprint.scopes ?? [], false);
    setCopyable(blueprint.copyable ?? QuestionnaireBlueprintCopyableOption.none, false);

    setIsInitialized(true);
  }, [
    blueprint.title,
    blueprint.text,
    blueprint.iconName,
    blueprint.nextStepButtonText,
    blueprint.showInNavigation,
    blueprint.dataLabel,
    blueprint.platforms,
    blueprint.renderOn,
    blueprint.scopes,
    blueprint.copyable,
    dataLabelOptions,
    setPlatforms,
    setRenderOn,
    setScopes,
    isInitialized,
    setDataLabel,
    setCopyable,
  ]);

  const disabled = parentHidden || !!blueprint.hidden || !isEditingEnabled;

  const highlightedTexts = useHighlightedTextInBlueprintElement({ partIdentifier, blueprint });

  const blueprintVariantName = useMemo(() => getBlueprintVariantName(blueprint.variant, t), [t, blueprint.variant]);

  return (
    <Box ref={containerRef}>
      {showBlueprintIdInEditor && (
        <Box pb={1}>
          <TextInput label={'Id'} value={blueprint?.id} disabled />
        </Box>
      )}

      {blueprint.variant && (
        <React.Fragment>
          <Box pb={1}>
            <StyledFormControlLabel
              label={t('admin.questionnaireManagement.input.subsectionVariant')}
              showError={false}
              labelPlacement='top'
              control={
                <Typography variant='body1'>
                  {highlightedTexts.variant ? (
                    <HighlightedContent textFragments={highlightedTexts.variant} />
                  ) : (
                    blueprintVariantName
                  )}
                </Typography>
              }
            />
          </Box>
          {blueprint.variant.type === SubsectionVariant.quoter && (
            <React.Fragment>
              <Box pb={1}>
                <StyledFormControlLabel
                  label={t('admin.questionnaireManagement.input.productFieldAnswers')}
                  showError={false}
                  labelPlacement='top'
                  control={<Typography variant='body1'>{blueprint.variant.productNodeIds.product}</Typography>}
                />
              </Box>
              <Box pb={1}>
                <StyledFormControlLabel
                  label={t('admin.questionnaireManagement.input.coverageAmountFieldAnswer')}
                  showError={false}
                  labelPlacement='top'
                  control={<Typography variant='body1'>{blueprint.variant.productNodeIds.coverageAmount}</Typography>}
                />
              </Box>
            </React.Fragment>
          )}
          {blueprint.variant.type === SubsectionVariant.submission && (
            <React.Fragment>
              <Box pb={1}>
                <StyledFormControlLabel
                  label={t('admin.questionnaireManagement.subsectionVariants.hideHeader')}
                  showError={false}
                  labelPlacement='top'
                  control={
                    <Typography variant='body1'>{blueprint.variant.hideHeader ? t('true') : t('false')}</Typography>
                  }
                />
              </Box>
              <Box pb={1}>
                <StyledFormControlLabel
                  label={t('admin.questionnaireManagement.subsectionVariants.displayInline')}
                  showError={false}
                  labelPlacement='top'
                  control={
                    <Typography variant='body1'>{blueprint.variant.displayInline ? t('true') : t('false')}</Typography>
                  }
                />
              </Box>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <TextInput
        highlighted={!!highlightedTexts.title}
        label={t('admin.questionnaireManagement.input.subsectionTitle')}
        value={title[selectedLanguage] ?? ''}
        disabled={disabled}
        onChange={(event) => {
          const value = event.target.value;
          setTitle((previousValue) => ({ ...previousValue, [selectedLanguage]: value }));
        }}
        onBlur={() => {
          void blueprintUpdate({
            partIdentifier,
            update: { property: 'title', value: title },
          });
        }}
      />
      <Box pt={1}>
        <TextInput
          highlighted={!!highlightedTexts.text}
          label={t('admin.questionnaireManagement.input.subsectionText')}
          value={text[selectedLanguage] ?? ''}
          disabled={disabled}
          onChange={(event) => {
            const value = event.target.value;
            setText((previousValue) => ({ ...previousValue, [selectedLanguage]: value }));
          }}
          multiline={true}
          onBlur={() => {
            void blueprintUpdate({
              partIdentifier,
              update: { property: 'text', value: text },
            });
          }}
        />
      </Box>
      <Box pt={1}>
        <IconNameSelector
          label={t('admin.questionnaireManagement.input.iconName')}
          disabled={disabled}
          value={iconName}
          onChange={(newIconName) => {
            setIconName(newIconName);

            void blueprintUpdate({
              partIdentifier,
              update: { property: 'iconName', value: newIconName },
            });
          }}
        />
      </Box>
      <Box pt={1}>
        <TextInput
          label={t('admin.questionnaireManagement.input.subsectionNextStepButtonText')}
          value={nextStepButtonText[selectedLanguage] ?? ''}
          disabled={disabled}
          onChange={(event) => {
            const value = event.target.value;
            setNextStepButtonText((previousValue) => ({ ...previousValue, [selectedLanguage]: value }));
          }}
          multiline={true}
          onBlur={() => {
            void blueprintUpdate({
              partIdentifier,
              update: { property: 'nextStepButtonText', value: nextStepButtonText },
            });
          }}
        />
      </Box>
      <Box pt={2}>
        <SimpleCheckbox
          id={`showInNavigation-${blueprint.partName}`}
          label={t('admin.questionnaireManagement.input.showInNavigation')}
          checked={showInNavigation}
          disabled={disabled}
          onChange={(event) => {
            setShowInNavigation(event.target.checked);
            void blueprintUpdate({
              partIdentifier,
              update: { property: 'showInNavigation', value: event.target.checked },
            });
          }}
        />
      </Box>
      <Box pt={2}>
        <SimpleCheckbox
          id={`pageBreakSubSectionInPdf`}
          label={t('admin.questionnaireManagement.input.pageBreakSubSectionInPdf')}
          checked={pageBreakSubSectionInPdf}
          onChange={(event) => {
            setPageBreakSubSectionInPdf(event.target.checked);
            void blueprintUpdate({
              partIdentifier,
              update: { property: 'pageBreakSubSectionInPdf', value: event.target.checked },
            });
          }}
          disabled={disabled}
        />
      </Box>

      <Box pt={1} pb={2} mr={-1}>
        <ConditionsEditor
          questionnaireNodeIds={questionnaireNodeIds}
          condition={blueprint?.visible}
          collectionContext={sectionGroupCollectionNodeId ? [sectionGroupCollectionNodeId] : []}
          isReadOnly={parentHidden || !!blueprint.hidden || disabled}
          label={t('admin.questionnaireManagement.rules.visibility.conditions')}
          editHeadingText={t('admin.questionnaireManagement.rules.visibility.edit')}
          selectedLanguage={selectedLanguage}
          saveCondition={(condition) => {
            void blueprintUpdate({
              partIdentifier,
              update: { property: 'visible', value: condition },
            });
          }}
        />
      </Box>

      <AdvancedBlueprintOptions
        tag='subsection'
        platform={{ selected: platforms, onChange: setPlatforms }}
        renderOn={{ selected: renderOn, onChange: setRenderOn }}
        scope={{ selected: scopes, onChange: setScopes }}
        dataLabel={{
          selected: dataLabel,
          options: dataLabelOptions,
          onChange: (value) => {
            if (value !== null) {
              setDataLabel(value);
            }
          },
        }}
        copyable={{
          selected: copyable,
          disabled: disableCopyable,
          onChange: (value) => {
            if (value !== null) {
              setCopyable(value as QuestionnaireBlueprintCopyableOption);
            }
          },
        }}
        disabled={disabled}
      />
    </Box>
  );
}
