import React from 'react';

export function SubmissionSuccess(): React.ReactElement {
  return (
    <svg width='168' height='168' viewBox='0 0 168 168' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M84 168C130.392 168 168 130.392 168 84C168 37.6081 130.392 0 84 0C37.6081 0 0 37.6081 0 84C0 130.392 37.6081 168 84 168Z'
        fill='#FBFBFB'
      />
      <path
        d='M83.8673 133.405C102.019 133.405 116.735 132.104 116.735 130.498C116.735 128.893 102.019 127.592 83.8673 127.592C65.7152 127.592 51 128.893 51 130.498C51 132.104 65.7152 133.405 83.8673 133.405Z'
        fill='#EEEEEE'
      />
      <g clipPath='url(#clip0)'>
        <path
          d='M84 167.506C130.392 167.506 168 129.898 168 83.5059C168 37.1139 130.392 -0.494141 84 -0.494141C37.6081 -0.494141 0 37.1139 0 83.5059C0 129.898 37.6081 167.506 84 167.506Z'
          fill='#FBFBFB'
        />
        <path
          d='M87.33 133.405C105.482 133.405 120.197 132.104 120.197 130.498C120.197 128.893 105.482 127.592 87.33 127.592C69.1779 127.592 54.4626 128.893 54.4626 130.498C54.4626 132.104 69.1779 133.405 87.33 133.405Z'
          fill='#EEEEEE'
        />
        <path
          d='M68.0074 43.4824H104.147L118.588 58.4669V112.758C118.588 113.256 118.397 113.734 118.058 114.086C117.718 114.438 117.258 114.635 116.777 114.635H68.0074C67.5324 114.631 67.0779 114.434 66.742 114.086C66.406 113.737 66.2156 113.266 66.2117 112.774V45.3438C66.2156 44.8514 66.406 44.3803 66.742 44.0321C67.0779 43.6839 67.5324 43.4865 68.0074 43.4824Z'
          fill='#F6F6F6'
        />
        <path
          d='M65.1089 40.8262H103.772L119.221 56.339V112.545C119.221 113.06 119.017 113.554 118.653 113.919C118.29 114.283 117.797 114.488 117.284 114.488H65.1089C64.6007 114.484 64.1144 114.279 63.7551 113.919C63.3957 113.558 63.1919 113.071 63.1877 112.561V42.7532C63.1919 42.2434 63.3957 41.7557 63.7551 41.3952C64.1144 41.0347 64.6007 40.8304 65.1089 40.8262Z'
          stroke='#757575'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.221 56.339L103.756 40.8262V54.3959C103.756 54.9085 103.957 55.4003 104.317 55.7642C104.677 56.1282 105.166 56.3348 105.677 56.339H119.221Z'
          fill='white'
          stroke='#757575'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M68.9671 121.297C81.2482 121.297 91.2041 111.31 91.2041 98.9912C91.2041 86.6721 81.2482 76.6855 68.9671 76.6855C56.6859 76.6855 46.73 86.6721 46.73 98.9912C46.73 111.31 56.6859 121.297 68.9671 121.297Z'
          fill='#8EE6B3'
          stroke='#757575'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M68.9672 116.447C78.5782 116.447 86.3694 108.632 86.3694 98.9911C86.3694 89.3504 78.5782 81.5352 68.9672 81.5352C59.3562 81.5352 51.5649 89.3504 51.5649 98.9911C51.5649 108.632 59.3562 116.447 68.9672 116.447Z'
          fill='white'
        />
        <path
          d='M68.9672 86.3847C73.1606 86.387 77.212 87.9095 80.3746 90.6717C83.5373 93.4339 85.5981 97.2497 86.1773 101.416C86.2951 100.64 86.3593 99.8562 86.3694 99.0712C86.3694 94.4416 84.536 90.0016 81.2724 86.728C78.0089 83.4543 73.5825 81.6152 68.9672 81.6152C64.3518 81.6152 59.9255 83.4543 56.6619 86.728C53.3984 90.0016 51.5649 94.4416 51.5649 99.0712C51.5713 99.883 51.6355 100.693 51.7571 101.496C52.3218 97.3167 54.3759 93.4838 57.5396 90.7059C60.7033 87.9281 64.7626 86.393 68.9672 86.3847Z'
          fill='#EEEEEE'
        />
        <path
          d='M68.9672 116.447C78.5782 116.447 86.3694 108.632 86.3694 98.9911C86.3694 89.3504 78.5782 81.5352 68.9672 81.5352C59.3562 81.5352 51.5649 89.3504 51.5649 98.9911C51.5649 108.632 59.3562 116.447 68.9672 116.447Z'
          stroke='#757575'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M73.5457 50.5098H94.8061' stroke='#757575' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M73.5457 57.3027H94.8061' stroke='#757575' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M73.5457 64.0957H109.311' stroke='#757575' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M73.5457 70.873H109.311' stroke='#757575' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M89.0107 77.6641H109.311' stroke='#757575' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M94.8062 84.457H109.311' stroke='#757575' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M97.7039 91.2344H109.311' stroke='#757575' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M98.6804 98.0273H109.311' stroke='#757575' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M97.7039 104.805H109.311' stroke='#757575' strokeLinecap='round' strokeLinejoin='round' />
        <path
          d='M65.7973 107.728C65.1184 107.727 64.4677 107.455 63.9882 106.973L58.8332 101.802C58.5632 101.571 58.3438 101.285 58.1889 100.965C58.034 100.644 57.9469 100.294 57.9331 99.9381C57.9193 99.5819 57.979 99.2267 58.1086 98.8947C58.2382 98.5628 58.4348 98.2614 58.6861 98.0093C58.9374 97.7573 59.2379 97.5601 59.5688 97.4301C59.8997 97.3001 60.2538 97.2402 60.6089 97.254C60.964 97.2679 61.3124 97.3552 61.6322 97.5106C61.952 97.666 62.2364 97.886 62.4674 98.1569L65.7973 101.497L74.2823 92.986C74.7754 92.5629 75.4095 92.3419 76.058 92.3672C76.7064 92.3925 77.3215 92.6622 77.7803 93.1225C78.2392 93.5828 78.5081 94.1997 78.5333 94.8502C78.5586 95.5006 78.3383 96.1367 77.9164 96.6313L67.6224 106.973C67.137 107.456 66.4811 107.728 65.7973 107.728Z'
          fill='#8EE6B3'
          stroke='#757575'
          strokeMiterlimit='10'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='168' height='168' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
