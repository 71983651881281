import { MuiThemeProvider } from '@material-ui/core/styles';
import { configureScope, Scope, setUser as setSentryUser } from '@sentry/browser';
import { WebAuth } from 'auth0-js';
import i18n from 'i18next';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { TypewriterTracking } from '@breathelife/react-tracking';
import { Language } from '@breathelife/types';
import { baseTheme, ErrorBoundary, Loader, StripeElementsProvider } from '@breathelife/ui-components';

import { CarrierContext, ContextProps } from './Context/CarrierContext';
import { getTraceId } from './Helpers/monitoring';
import { defaultSsoProfileFields } from './Helpers/user';
import { useDispatch, useSelector } from './Hooks';
import initI18n, { changeLanguage } from './Localization';
import { LeadStatusesKeys } from './Localization/types';
import { getCurrentLocale, getLanguageRegion } from './Localization/utils';
import { LeadStatusesColumnData } from './Models/Lead';
import { Mga } from './Models/Mga';
import { PlatformFeatures } from './Models/PlatformFeatures';
import { ErrorPage } from './Pages/Error/ErrorPage';
import { ReactQueryClientProvider } from './ReactQuery';
import { getSettingsData } from './Redux/Admin/SettingsManagement/SettingsSelectors';
import { fetchTheme } from './Redux/Admin/ThemeManagement/ThemeOperations';
import { Root } from './Root/Root';
import { setAuthorizationInterceptor } from './Services/ApiService';
import { initAuth0Instance } from './Services/Auth0';
import { GlobalStyle } from './Styles/GlobalStyle';
import { createMuiTheme } from './Styles/MuiTheme';
import leadPlatformTheme from './Styles/Theme';
import { LeadPlatformTheme, LeadPlatformCarrierTheme } from './Styles/Types';
import { LeadPlatformConfig } from './types';

export type { LeadPlatformConfig } from './types';

// Add typing to props.theme in styled components
declare module 'styled-components' {
  // Without this comment Prettier/ESLint would auto-format this and we really don't want it to be
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends LeadPlatformTheme {}
}

const defaultLeadStatusLabels = {
  new: {
    label: LeadStatusesKeys.new,
  },
  invited: {
    label: LeadStatusesKeys.invited,
  },
  planFinderStarted: {
    label: LeadStatusesKeys.started,
  },
  planFinderCompleted: {
    label: LeadStatusesKeys.progressing,
  },
  engaged: {
    label: LeadStatusesKeys.finalized,
  },
  transactionFlowStarted: {
    label: LeadStatusesKeys.applicationStarted,
  },
  qualified: {
    label: LeadStatusesKeys.applicationCompleted,
  },
  submissionStarted: {
    label: LeadStatusesKeys.submissionInProgress,
  },
  submissionCompleted: {
    label: LeadStatusesKeys.applicationSubmitted,
  },
};

type LeadPlatformProps = Omit<ContextProps, 'unsubToolUrl'> & {
  theme?: LeadPlatformCarrierTheme;
  leadStatuses?: Partial<LeadStatusesColumnData>;
  mgas?: Mga[];
  config: LeadPlatformConfig;
  features: PlatformFeatures;
  iconMap?: Record<string, string>;
};

function LeadPlatformComponent(props: LeadPlatformProps): React.ReactElement | null {
  const {
    config,
    leadStatuses,
    theme: propTheme,
    carrierInfo,
    enabledTabs,
    externalResources,
    headerVariant,
    isEmailActionEnabled,
    leadTableColumns,
    mgas,
    features,
    navigationSidebarVariant,
    iconMap,
    availablePlatformLanguages,
    defaultPlatformLanguage,
    ssoProfileFields,
    coverageAmountStepRounding,
  } = props;

  const { auth0: auth0Config, cloudinary, countryCode, unsubToolUrl, localizedHostnames } = config;

  const dispatch = useDispatch();
  const [auth0, setAuth0] = useState<WebAuth>();
  const [languageInitialized, setLanguageInitialized] = useState<boolean>(false);
  const [fetchThemeInitialized, setFetchThemeInitialized] = useState<boolean>(false);
  const authentication = useSelector((store) => store.leadPlatform.authentication);
  const { theme } = useSelector((store) => store.leadPlatform.theme);
  const settings = useSelector(getSettingsData, _.isEqual);
  const insuranceScopes = settings?.insuranceScopes;
  const carrierNames = settings?.carrierNames;
  const defaultLanguage = settings?.defaultLanguage;
  const enabledLanguages = settings?.enabledLanguages;
  const authenticatedUser = authentication.user;
  const enableLoadLanguageSettingsFromDb = features.loadLanguageSettingsFromDb?.enabled;
  const location = useLocation();

  useEffect(() => {
    if (fetchThemeInitialized) return;

    void dispatch(fetchTheme());
    setFetchThemeInitialized(true);
  }, [dispatch, fetchThemeInitialized]);

  useEffect(() => {
    configureScope((scope: Scope) => {
      const traceId = getTraceId();
      scope.setTag('traceId', traceId);
    });
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const auth0Instance = initAuth0Instance(auth0Config, searchParams);
    setAuth0(auth0Instance);
  }, [auth0Config, location.search]);

  const mergedLanguageSettings: {
    enabledLanguages: Language[];
    default: Language;
    userPlatformLanguage: Language;
  } = useMemo(() => {
    if (enableLoadLanguageSettingsFromDb && enabledLanguages && defaultLanguage) {
      const currentLocale = getCurrentLocale(defaultLanguage);
      let userPlatformLanguage = authenticatedUser?.platformLanguage;

      if (authenticatedUser?.platformLanguage && enabledLanguages.includes(authenticatedUser.platformLanguage)) {
        userPlatformLanguage = authenticatedUser.platformLanguage;
      }

      if (!authenticatedUser?.platformLanguage && enabledLanguages.includes(currentLocale)) {
        userPlatformLanguage = currentLocale;
      }

      return {
        enabledLanguages: enabledLanguages,
        default: defaultLanguage,
        userPlatformLanguage: userPlatformLanguage ?? defaultLanguage,
      };
    } else {
      const availableLanguages = availablePlatformLanguages ?? [Language.en];
      const defaultLanguage = defaultPlatformLanguage ?? Language.en;
      const currentLocale = getCurrentLocale(defaultLanguage);

      let userPlatformLanguage;

      if (authenticatedUser?.platformLanguage && availableLanguages.includes(authenticatedUser.platformLanguage)) {
        userPlatformLanguage = authenticatedUser.platformLanguage;
      }

      if (!authenticatedUser?.platformLanguage && availableLanguages.includes(currentLocale)) {
        userPlatformLanguage = currentLocale;
      }

      return {
        enabledLanguages: availableLanguages,
        default: defaultLanguage,
        userPlatformLanguage: userPlatformLanguage ?? defaultLanguage,
      };
    }
  }, [
    availablePlatformLanguages,
    defaultLanguage,
    defaultPlatformLanguage,
    enableLoadLanguageSettingsFromDb,
    enabledLanguages,
    authenticatedUser,
  ]);

  useEffect(() => {
    if (languageInitialized) return;
    setLanguageInitialized(true);
    const isOptionalNeedsAnalysisEnabled = features.needsAnalysis.enabled && features.needsAnalysis.optional;
    void initI18n(mergedLanguageSettings.userPlatformLanguage, isOptionalNeedsAnalysisEnabled);
  }, [languageInitialized, mergedLanguageSettings.userPlatformLanguage, features]);

  useEffect(() => {
    if (!languageInitialized || !authenticatedUser) return;

    const onLanguageChanged = (): void => {
      TypewriterTracking.identify({
        userId: authenticatedUser.auth0Id,
        traits: {
          userType: authenticatedUser.roles.join(','),
          locale: getLanguageRegion(),
        },
      });
    };

    i18n.on('languageChanged', onLanguageChanged);

    void changeLanguage(mergedLanguageSettings.userPlatformLanguage);

    return () => {
      i18n.off('languageChanged', onLanguageChanged);
    };
  }, [languageInitialized, authenticatedUser, mergedLanguageSettings.userPlatformLanguage]);

  useEffect(() => {
    if (authentication.token) {
      setAuthorizationInterceptor(authentication.token);
    }
    if (authentication.user) {
      const { emailLogin, roles } = authentication.user;

      setSentryUser({ email: emailLogin, roles });
    }
  }, [dispatch, authentication, auth0]);

  const codedTheme = propTheme ?? baseTheme;

  const mergedThemes = useMemo<LeadPlatformTheme>(
    () => _.merge({}, codedTheme, theme ? { colors: theme.colorRanges } : {}, leadPlatformTheme),
    [codedTheme, theme]
  );

  if (!theme) return <Loader color='#000000' />;

  return (
    <ReactQueryClientProvider>
      <ThemeProvider theme={mergedThemes}>
        <MuiThemeProvider theme={createMuiTheme(mergedThemes)}>
          <ErrorBoundary renderErrorComponent={() => <ErrorPage />}>
            <CarrierContext.Provider
              value={{
                carrierInfo: {
                  companyName:
                    features.loadCarrierNamesFromDb?.enabled && carrierNames
                      ? carrierNames[mergedLanguageSettings.userPlatformLanguage]
                      : carrierInfo.companyName,
                  logo: theme ? theme?.logoImgUrl : carrierInfo.logo,
                  logoCompact: theme ? theme.compactLogoImgUrl : carrierInfo.logoCompact,
                  validFileUploadMimeTypes: carrierInfo.validFileUploadMimeTypes,
                },
                cloudinaryConfig: cloudinary,
                countryCode: countryCode,
                enabledTabs: enabledTabs,
                externalResources: externalResources,
                headerVariant: headerVariant,
                isEmailActionEnabled: isEmailActionEnabled,
                leadStatuses: leadStatuses || defaultLeadStatusLabels,
                leadTableColumns: leadTableColumns,
                unsubToolUrl: unsubToolUrl,
                ssoConnectionName: auth0Config.ssoConnectionName,
                defaultToSsoConnection: auth0Config.defaultToSsoConnection ?? false,
                mgas: mgas ?? [],
                insuranceScopes: insuranceScopes ?? [],
                features: features,
                languageSettings: {
                  enabledLanguages: mergedLanguageSettings.enabledLanguages,
                  default: mergedLanguageSettings.default,
                },
                navigationSidebarVariant: theme.navigationSidebarVariant ?? navigationSidebarVariant,
                iconMap: iconMap ?? {},
                ssoProfileFields: ssoProfileFields || defaultSsoProfileFields,
                availablePlatformLanguages,
                coverageAmountStepRounding,
                pdfFetchDuration: config.pdfFetchDuration,
                showBlueprintIdInEditor: features.showBlueprintIdInEditor?.enabled,
                entityMappings: features.entityMappings,
                localizedHostnames,
              }}
            >
              {/*
               * @TODO: The rendering of StripeElementProvider will be edited in DEV-11626
               * to provide a parent wrapper component and better isolate the following logic
               */}
              {auth0 ? (
                <React.Fragment>
                  <StripeElementsProvider config={features.payments}>
                    <Root />
                  </StripeElementsProvider>
                </React.Fragment>
              ) : (
                <div />
              )}
            </CarrierContext.Provider>
          </ErrorBoundary>
        </MuiThemeProvider>
        <GlobalStyle />
      </ThemeProvider>
    </ReactQueryClientProvider>
  );
}

export const LeadPlatform = React.memo(LeadPlatformComponent, _.isEqual);

export * from './Redux/Store';
export * from './Redux/types';
export * from './Models/Layout';
export * from './Models/LeadTableColumns';
export * from './Models/Lead';
export * from './Models/Mga';
export * from './Models/PlatformFeatures';
export * from './Helpers/featureConfig';
export * from './Localization/types';
