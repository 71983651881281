import styled from 'styled-components';

import { Tag } from '@breathelife/ui-components';

export const Badge = styled(Tag)`
  font-size: ${(props) => props.theme.text.small2.size};
  font-weight: ${(props) => props.theme.text.small2.weight};
  color: ${(props) => (props.textColor ? props.textColor : props.theme.colors.grey[60])};
`;

export const StyledBadge = styled(Badge)`
  text-transform: uppercase;
  border-color: ${(props) => props.theme.colors.grey[0]};
`;

export const StyledBadgeContainer = styled.div`
  max-width: fit-content;
`;
