import React from 'react';

import { Drawer } from '../../../../Components/Drawer/Drawer';
import { Application } from '../../../../Models/Application';
import { IdentityVerificationDrawerBody } from './IdentityVerificationDrawerBody';
import { IdentityVerificationDrawerHeader } from './IdentityVerificationDrawerHeader';

export type Props = {
  application: Application;
  isOpen: boolean;
  onClose: () => void;
};

export function IdentityVerificationDrawer(props: Props): React.ReactElement {
  return (
    <Drawer open={props.isOpen} onClose={props.onClose}>
      <IdentityVerificationDrawerHeader onClose={props.onClose} />
      <IdentityVerificationDrawerBody application={props.application} />
    </Drawer>
  );
}
