import React, { PropsWithChildren, useCallback, useState } from 'react';

import {
  PageQueryOptions,
  QuestionnaireVersionInfo,
  QuestionnaireVersionRowData,
  SortParams,
} from '@breathelife/types';

import { useFetchQuestionnaireVersionsQuery } from '../../../ReactQuery/Admin/Questionnaire/questionnaireVersion.queries';

export const PER_PAGE_OPTIONS = [
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

type QuestionnaireVersionsContextValue = {
  questionnaireVersions: QuestionnaireVersionInfo[];
  total: number;
  isFetching: boolean;
  onPageChange: ($page: number) => void;
  onLimitPerPageChange: ($limit: number) => void;
  onSortingChange: ($sort: SortParams<QuestionnaireVersionRowData>) => void;
  currentPage: number;
  perPage: number;
};

type QuestionnaireQueryOptions = Partial<PageQueryOptions<QuestionnaireVersionRowData>>;

export const QuestionnaireVersionsContext = React.createContext<QuestionnaireVersionsContextValue>({
  questionnaireVersions: [],
  total: 0,
  isFetching: false,
  onPageChange: () => {},
  onLimitPerPageChange: () => {},
  onSortingChange: () => {},
  perPage: 0,
  currentPage: 1,
});

export function QuestionnaireVersionsContextProvider(props: PropsWithChildren<{}>): React.ReactElement {
  const [questionnaireQueryOptions, setQuestionnaireQueryOptions] = useState<QuestionnaireQueryOptions>({
    $page: 1,
    $limit: PER_PAGE_OPTIONS[1].value,
    $sort: undefined,
  });
  const { data: questionnaireVersionsPage, isFetching } = useFetchQuestionnaireVersionsQuery(questionnaireQueryOptions);

  const onPageChange = useCallback(($page: number): void => {
    setQuestionnaireQueryOptions((prevValue: QuestionnaireQueryOptions) => ({ ...prevValue, $page }));
  }, []);

  const onLimitPerPageChange = useCallback(($limit: number): void => {
    setQuestionnaireQueryOptions((prevValue: QuestionnaireQueryOptions) => ({ ...prevValue, $limit }));
  }, []);

  const onSortingChange = useCallback(($sort: SortParams<QuestionnaireVersionRowData>) => {
    setQuestionnaireQueryOptions((prevValue: QuestionnaireQueryOptions) => ({ ...prevValue, $sort }));
  }, []);

  return (
    <QuestionnaireVersionsContext.Provider
      value={{
        questionnaireVersions: questionnaireVersionsPage?.data || [],
        total: questionnaireVersionsPage?.total || 0,
        isFetching,
        onPageChange,
        onLimitPerPageChange,
        onSortingChange,
        perPage: questionnaireQueryOptions.$limit || PER_PAGE_OPTIONS[1].value,
        currentPage: questionnaireQueryOptions.$page || 1,
      }}
    >
      {props.children}
    </QuestionnaireVersionsContext.Provider>
  );
}
