import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ESignFieldSettingAbsolute,
  ESignFieldSettingAnchorPointType,
  ESignFieldSettingPlacementType,
  ESignFieldSettingRelative,
  ESignFieldSettingType,
} from '@breathelife/types';
import { Input as TextInput } from '@breathelife/ui-components';

import { eSignFieldSettingValidation } from '../../../../../../Helpers/inputValidation/form/eSignFieldSetting';
import { StyledSelect } from '../../../Style';

type ESignFieldSettingFields = Omit<ESignFieldSettingAbsolute, 'id' | 'signerSettingId' | 'placementType'> &
  Omit<ESignFieldSettingRelative, 'id' | 'signerSettingId' | 'placementType'> & {
    placementType: ESignFieldSettingPlacementType;
  };

export type ESignFieldSettingFormData = Record<keyof ESignFieldSettingFields, string | null>;

type ESignFieldSettingFormProps = {
  data: ESignFieldSettingFormData;
  onChange?: (data: ESignFieldSettingFormData) => void;
  disabled?: boolean;
};

export function ESignFieldSettingForm(props: ESignFieldSettingFormProps): React.ReactElement {
  const { data, disabled } = props;
  const { t } = useTranslation();

  const [placementType, setPlacementType] = useState<string>(data.placementType ?? '');
  const [top, setTop] = useState<string>(data.top ?? '');
  const [left, setLeft] = useState<string>(data.left ?? '');
  const [width, setWidth] = useState<string>(data.width ?? '');
  const [height, setHeight] = useState<string>(data.height ?? '');

  // Absolute-positioning-specific fields
  const [page, setPage] = useState<string>(data.page ?? '');

  // Relative-positioning-specific fields
  const [anchorText, setAnchorText] = useState<string>(data.anchorText ?? '');
  const [anchorPoint, setAnchorPoint] = useState<string>(data.anchorPoint ?? '');
  const [anchorIndex, setAnchorIndex] = useState<string>(data.anchorIndex ?? '');

  const updateForm = (data: Partial<ESignFieldSettingFormData>): void => {
    const { placementType, top, left, width, height, page, anchorText, anchorPoint, anchorIndex } = data;
    placementType !== undefined && setPlacementType(placementType ?? '');
    top !== undefined && setTop(top ?? '');
    left !== undefined && setLeft(left ?? '');
    width !== undefined && setWidth(width ?? '');
    height !== undefined && setHeight(height ?? '');
    page !== undefined && setPage(page ?? '');
    anchorText !== undefined && setAnchorText(anchorText ?? '');
    anchorPoint !== undefined && setAnchorPoint(anchorPoint ?? '');
    anchorIndex !== undefined && setAnchorIndex(anchorIndex ?? '');
  };

  useEffect(() => {
    updateForm(data);
  }, [data]);

  const propagateChange = (): void => {
    const eSignFieldSetting: ESignFieldSettingFormData = {
      type: ESignFieldSettingType.SIGNATURE,
      placementType,
      top,
      left,
      width,
      height,
      page,
      anchorText,
      anchorPoint,
      anchorIndex,
      anchorCharacterIndex: '',
      fieldBinding: '',
      defaultValue: '',
    };

    props.onChange?.(eSignFieldSetting);
  };

  const placementTypeOptions = Object.keys(ESignFieldSettingPlacementType).map((placementType) => ({
    value: placementType,
    label: placementType,
  }));

  const anchorPointOptions = Object.keys(ESignFieldSettingAnchorPointType).map((anchorPoint) => ({
    value: anchorPoint,
    label: anchorPoint,
  }));

  const topValidationError = eSignFieldSettingValidation.getFieldValidationError('top', top);
  const leftValidationError = eSignFieldSettingValidation.getFieldValidationError('left', left);
  const widthValidationError = eSignFieldSettingValidation.getFieldValidationError('width', width);
  const heightValidationError = eSignFieldSettingValidation.getFieldValidationError('height', height);
  const pageValidationError = eSignFieldSettingValidation.getFieldValidationError('page', page);
  const anchorTextValidationError = eSignFieldSettingValidation.getFieldValidationError('anchorText', anchorText);
  const anchorIndexValidationError = eSignFieldSettingValidation.getFieldValidationError('anchorIndex', anchorIndex);

  const isRelativePlacement = placementType === ESignFieldSettingPlacementType.RELATIVE;

  return (
    <React.Fragment>
      <Grid container spacing={2} onBlur={propagateChange}>
        <Grid item xs={12}>
          <StyledSelect
            options={placementTypeOptions}
            onChange={(value) => {
              updateForm({
                placementType: value as ESignFieldSettingPlacementType,
              });
            }}
            value={placementType}
            disabled={disabled}
            id='eSign-field-form-placement-type'
            label={t('admin.documentDetailManagement.documentFieldEditorDialog.fields.placementType')}
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            required
            fullWidth
            inputVariant='outlined'
            id='eSign-field-form-top'
            label={t(
              `admin.documentDetailManagement.documentFieldEditorDialog.fields.top${
                isRelativePlacement ? 'Offset' : ''
              }`
            )}
            disabled={disabled}
            error={!!topValidationError}
            validationError={topValidationError}
            value={top}
            onChange={(event) =>
              updateForm({
                top: event.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            required
            fullWidth
            inputVariant='outlined'
            id='eSign-field-form-left'
            label={t(
              `admin.documentDetailManagement.documentFieldEditorDialog.fields.left${
                isRelativePlacement ? 'Offset' : ''
              }`
            )}
            disabled={disabled}
            error={!!leftValidationError}
            validationError={leftValidationError}
            value={left}
            onChange={(event) =>
              updateForm({
                left: event.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            required
            fullWidth
            inputVariant='outlined'
            id='eSign-field-form-width'
            label={t('admin.documentDetailManagement.documentFieldEditorDialog.fields.width')}
            disabled={disabled}
            error={!!widthValidationError}
            validationError={widthValidationError}
            value={width}
            onChange={(event) =>
              updateForm({
                width: event.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            required
            fullWidth
            inputVariant='outlined'
            id='eSign-field-form-height'
            label={t('admin.documentDetailManagement.documentFieldEditorDialog.fields.height')}
            disabled={disabled}
            error={!!heightValidationError}
            validationError={heightValidationError}
            value={height}
            onChange={(event) =>
              updateForm({
                height: event.target.value,
              })
            }
          />
        </Grid>
        {isRelativePlacement ? (
          <React.Fragment>
            <Grid item xs={3}>
              <TextInput
                required
                fullWidth
                inputVariant='outlined'
                id='eSign-field-form-anchor-text'
                label={t('admin.documentDetailManagement.documentFieldEditorDialog.fields.anchorText')}
                disabled={disabled}
                error={!!anchorTextValidationError}
                validationError={anchorTextValidationError}
                value={anchorText}
                onChange={(event) =>
                  updateForm({
                    anchorText: event.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={3}>
              <StyledSelect
                options={anchorPointOptions}
                onChange={(value) =>
                  updateForm({
                    anchorPoint: value as ESignFieldSettingAnchorPointType,
                  })
                }
                value={anchorPoint}
                disabled={disabled}
                id='eSign-field-form-anchor-point'
                label={t('admin.documentDetailManagement.documentFieldEditorDialog.fields.anchorPoint')}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                required
                fullWidth
                inputVariant='outlined'
                id='eSign-field-form-anchor-index'
                label={t('admin.documentDetailManagement.documentFieldEditorDialog.fields.anchorIndex')}
                disabled={disabled}
                error={!!anchorIndexValidationError}
                validationError={anchorIndexValidationError}
                value={anchorIndex}
                onChange={(event) =>
                  updateForm({
                    anchorIndex: event.target.value,
                  })
                }
              />
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid item xs={3}>
              <TextInput
                required
                fullWidth
                inputVariant='outlined'
                id='eSign-field-form-page'
                label={t('admin.documentDetailManagement.documentFieldEditorDialog.fields.page')}
                disabled={disabled}
                error={!!pageValidationError}
                validationError={pageValidationError}
                value={page}
                onChange={(event) =>
                  updateForm({
                    page: event.target.value,
                  })
                }
              />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </React.Fragment>
  );
}
