import Box from '@material-ui/core/Box';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { InsuranceScopeBadge } from '../../../../../../Components/Badge/InsuranceScopeBadge';
import Typography from '../../../../../../Components/Typography';
import { Application } from '../../../../../../Models/Application';

type ApplicationHeaderInfoProps = {
  application: Application | null;
};

export function ApplicationHeaderInfo({ application }: ApplicationHeaderInfoProps): React.ReactElement | null {
  const { t } = useTranslation();

  if (!application) return null;

  const date = dayjs(application.createdAt || new Date()).format('MMM. D, YYYY');
  const insuranceScopes = application.insuranceScopes || [];

  return (
    <React.Fragment>
      <Typography variant='h3'>{date}</Typography>

      <Box display='flex'>
        {insuranceScopes.map((scope) => (
          <Box key={scope} mt={1} mr={1}>
            <InsuranceScopeBadge label={t(`insuranceScope.${scope}`)} />
          </Box>
        ))}
      </Box>
    </React.Fragment>
  );
}
