import Box from '@material-ui/core/Box';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonName, TypewriterTracking } from '@breathelife/react-tracking';
import { InsuranceScopes } from '@breathelife/types';

import { Alert } from '../../../Components/Alert/Alert';
import { InsuranceScopeBadge } from '../../../Components/Badge/InsuranceScopeBadge';
import { Button } from '../../../Components/Button/Button';
import { GhostButton } from '../../../Components/Button/GhostButton';
import { Icon } from '../../../Components/Icons';
import Typography from '../../../Components/Typography';
import { useCarrierContext, useDispatch, useSelector } from '../../../Hooks';
import { ModalLayout } from '../../../Layouts/Modal/ModalLayout';
import {
  copyAgentLink as copyAgentLinkOperation,
  fetchAndSetPublicLink,
} from '../../../Redux/Authentication/UserOperations';
import { isSsoUser } from '../../../Services/Auth0';
import { TextButton } from './Styles';

export type Props = {
  isOpen: boolean;
  closeModal: () => void;
  onOpenProfilePage: () => void;
};

export function SharePublicLinkModal(props: Props): React.ReactElement {
  const { t } = useTranslation();
  const user = useSelector((store) => store.leadPlatform.authentication.user);
  const publicLink = useSelector((store) => store.leadPlatform.authentication.publicLink);
  const { carrierInfo } = useCarrierContext();
  const dispatch = useDispatch();
  const insuranceScopes = user?.defaultInsuranceScopes;

  const isInsuranceScopeDisabled = useMemo((): boolean => {
    if (!user) return true;
    if (isSsoUser(user)) return true;

    // TODO DEV-3842 Remove this condition once NLG pilot users are migrated to SSO
    // Prevent NLG pilot users ( not logged in via SSO ) from changing their insurance scope once it is set. This was a
    // request from the client and can be remove once all their users are migrated to connect via SSO.
    // ------------------------------------------------------------------------------
    const isNLG = carrierInfo.companyName === 'National Life Group';
    const userHasInsuranceScope = user?.defaultInsuranceScopes.length;
    if (isNLG && userHasInsuranceScope) return true;
    // ------------------------------------------------------------------------------
    // END DEV-3842

    return false;
  }, [user, carrierInfo.companyName]);

  useEffect(() => {
    if (props.isOpen) {
      dispatch(fetchAndSetPublicLink());
    }
  }, [dispatch, props.isOpen]);

  const copyAgentLink = useCallback(async () => {
    TypewriterTracking.clickedButton({
      buttonName: ButtonName.sharePublicLinkCopyToClipBoard,
      hashedId: null,
    });
    dispatch(copyAgentLinkOperation(insuranceScopes?.[0]));
  }, [dispatch, insuranceScopes]);

  return (
    <ModalLayout
      maxWidth='sm'
      isOpen={props.isOpen}
      closeModal={props.closeModal}
      title={t('modals.sharePublicLink.title')}
      secondaryAction={
        <GhostButton onClick={() => window.open(`${publicLink}/${user?.defaultInsuranceScopes?.[0]}`)}>
          {t('modals.editUser.openInNewTab')}
        </GhostButton>
      }
    >
      <Box mb={1}>
        <Typography variant='body1' grey={70}>
          {t('modals.sharePublicLink.copyLinkLabel')}
        </Typography>
      </Box>
      <Box mb={3}>
        <Button
          startIcon={<Icon name='copy' variant='primary-inverted' />}
          variant='contained'
          color='primary'
          onClick={copyAgentLink}
          data-testid='copyPublicLink'
          data-publiclink={`${publicLink}/${insuranceScopes?.[0]}`}
        >
          {t('modals.sharePublicLink.copyPublicLink', { scope: t(`insuranceScope.${insuranceScopes?.[0]}`) })}
        </Button>
      </Box>
      {!isInsuranceScopeDisabled && (
        <Box mb={2}>
          <Alert severity='info'>
            <Typography variant='body3' grey={90}>
              {t('modals.sharePublicLink.infoMessage')}{' '}
              <TextButton onClick={props.onOpenProfilePage}>{t('modals.sharePublicLink.profilePage')}</TextButton>
            </Typography>
          </Alert>
        </Box>
      )}
      <Box mb={1}>
        <Typography variant='body1' grey={70}>
          {t('modals.sharePublicLink.insuranceScopesSharingMessage')}
        </Typography>
      </Box>
      <Box display='flex'>
        {_.map(insuranceScopes, (scope: InsuranceScopes) => (
          <Box key={scope} mb={1} mr={1}>
            <InsuranceScopeBadge label={t(`insuranceScope.${scope}`)} />
          </Box>
        ))}
      </Box>
    </ModalLayout>
  );
}
