import { CryptoMaterial, SubmitResponse } from '@breathelife/crypto-material';

import ApiService from './ApiService';

export async function submitInsuranceApplication(
  applicationId: string,
  productQuote?: number | null
): Promise<SubmitResponse> {
  const response = await ApiService.submitInsuranceApplication(applicationId, productQuote);
  return response.data;
}

export async function finishSubmissionInsuranceApplication(applicationId: string): Promise<SubmitResponse> {
  const response = await ApiService.finishSubmissionInsuranceApplication(applicationId);
  return response.data;
}

export async function signApplicationUsingCrypto(applicationId: string, cryptoMaterial: CryptoMaterial): Promise<any> {
  const response = await ApiService.signApplicationUsingCrypto(applicationId, cryptoMaterial);
  return response.data;
}

export async function submitApplicationForExternalSignature(applicationId: string): Promise<any> {
  const response = await ApiService.submitApplicationForExternalSignature(applicationId);
  return response.data;
}
