import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';

import { IconName, Permission } from '@breathelife/types';
import { ProducerNavigation, ProducerNavigationItemData } from '@breathelife/ui-components';

import { Image } from '../../Components/Images/Image';
import { UserMenu } from '../../Components/Menu/UserMenu';
import { useCarrierContext } from '../../Hooks';

const PageLayoutGridWrapper = styled('div')`
  display: flex;
  height: 100vh;
`;

const Sidebar = styled('div')`
  width: 200px;
  min-width: 200px;
  padding-top: 24px;
  background-color: ${({ theme }) => theme.colors.grey[20]};
  overflow: auto;
  border-right: 1px solid ${({ theme }) => theme.colors.grey[40]};
`;

const PageContent = styled('div')`
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  align-items: stretch;
  overflow: hidden;
  height: 100vh;
  flex-grow: 1;
`;

const PageHeader = styled('div')`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[40]};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 25px;
  min-height: 64px;
`;

const LogoWrapper = styled('div')`
  width: 100%;
  padding: 0 24px;
  margin-bottom: 24px;
  height: 40px;
`;

const Logo = styled(Image)`
  width: 100%;
  height: 100%;
  & img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const ContentWrapper = styled('div')`
  overflow: auto;
  flex-grow: 1;
`;

export type PageLayoutTab = {
  ['data-testid']: string;
  label: string;
  iconName?: IconName;
  to?: string;
  permissions?: Permission[];
  showWhenNotAllowed?: boolean;
  children?: PageLayoutTab[];
};

type PageLayoutProps = {
  content: React.ReactElement;
  tabs: PageLayoutTab[];
};

function isActiveTab(tab: PageLayoutTab, currentLocationPathname: string): boolean {
  return tab.to ? currentLocationPathname.includes(tab.to) : false;
}

export function PageLayout({ content, tabs }: PageLayoutProps): React.ReactElement {
  const location = useLocation();
  const history = useHistory();
  const { carrierInfo } = useCarrierContext();
  const { t } = useTranslation();

  const selectedItemKey: string | undefined = useMemo(() => {
    let matchingTab: PageLayoutTab | undefined;
    tabs.some((tab) => {
      if (isActiveTab(tab, location.pathname)) {
        matchingTab = tab;
        return true;
      }
      if (tab.children) {
        let inChild = false;
        tab.children.some((childTab) => {
          if (isActiveTab(childTab, location.pathname)) {
            matchingTab = childTab;
            inChild = true;
            return true;
          }
          return false;
        });
        return inChild;
      }
      return false;
    });
    return matchingTab?.['data-testid'];
  }, [location.pathname, tabs]);

  const makeDataFromTab = useCallback(
    (tab: PageLayoutTab): ProducerNavigationItemData => {
      return {
        identifier: tab['data-testid'],
        text: tab.label,
        onClick: () => {
          if (tab.to) {
            history.push(tab.to);
          }
        },
        icon: tab.iconName
          ? {
              name: tab.iconName,
            }
          : undefined,
        children: tab.children?.map(makeDataFromTab),
      };
    },
    [history]
  );

  const data = useMemo(() => {
    return tabs.map(makeDataFromTab);
  }, [tabs, makeDataFromTab]);

  return (
    <PageLayoutGridWrapper>
      <Sidebar>
        <LogoWrapper>
          <Logo
            src={carrierInfo.logo}
            radius={0}
            alt={`${carrierInfo.companyName} ${t('sidebar.logo')}`}
            isProfile={false}
          />
        </LogoWrapper>
        <ProducerNavigation automaticallyOpenParentOfSelected data={data} selectedItemKey={selectedItemKey} />
      </Sidebar>
      <PageContent>
        <PageHeader>
          <UserMenu variant='default' showCarret showUserName />
        </PageHeader>
        <ContentWrapper> {content}</ContentWrapper>
      </PageContent>
    </PageLayoutGridWrapper>
  );
}
