import { Box } from '@material-ui/core';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';

import { BackOfficeApplication } from '@breathelife/types';

import { StatusBadge } from '../../../../Components/Badge/StatusBadge';
import { ListFilterContainer } from '../../../../Components/ListFilterContainer/ListFilterContainer';
import LoadingView from '../../../../Components/LoadingView/LoadingView';
import { Pagination } from '../../../../Components/Pagination/Pagination';
import { Table } from '../../../../Components/Table/Table';
import { LeadStatus } from '../../../../Models/Lead';
import { ApplicationsContext, PER_PAGE_OPTIONS } from './ApplicationsContextProvider';
import { ApplicationsTableHeader } from './ApplicationsTableHeader';
import { EmptySearchResultView } from './EmptySearchResultView';

export type ApplicationsTableViewProps = {
  onOpenUnderwritingReports: (applicationId: string) => void;
};

export function ApplicationsTable(props: ApplicationsTableViewProps): React.ReactElement {
  const { onOpenUnderwritingReports } = props;
  const { t } = useTranslation();

  const [hasSearchQuery, setHasSearchQuery] = useState(false);

  const {
    onPageChange,
    onLimitPerPageChange,
    onSearchChange: onSearchChangedProp,
    onSortingChange: onSortingChangedProp,
    applications,
    total,
    currentPage,
    perPage,
    isLoading,
  } = useContext(ApplicationsContext);

  const onTableRowClick = useCallback(
    (applicationId: string) => {
      onOpenUnderwritingReports(applicationId);
    },
    [onOpenUnderwritingReports]
  );

  const onSortingChanged = useCallback(
    (columnKey: keyof BackOfficeApplication, direction: 'asc' | 'desc') => {
      if (!columnKey || !direction) return;
      onSortingChangedProp({ field: columnKey as keyof BackOfficeApplication, direction });
    },
    [onSortingChangedProp]
  );

  const onSearchChange = useCallback(
    (search: string) => {
      onSearchChangedProp(search);
      setHasSearchQuery(!!search);
    },
    [onSearchChangedProp]
  );

  const shouldShowEmptySearchResultView = useMemo(() => {
    return hasSearchQuery && !isLoading && applications.length < 1;
  }, [hasSearchQuery, isLoading, applications]);

  const columns = useMemo<
    {
      id?: string;
      Header: string;
      accessor: keyof BackOfficeApplication;
      Cell?: (props: CellProps<BackOfficeApplication>) => React.ReactElement;
      disableSortBy?: boolean;
    }[]
  >(
    () => [
      {
        Header: t('applicationTable.id'),
        accessor: 'id',
      },
      {
        Header: t('applicationTable.refNo'),
        accessor: 'refNo',
      },
      {
        Header: t('applicationTable.createdAt'),
        accessor: 'createdAt',
        Cell: function ({ cell: { value } }: { cell: { value: Date } }): React.ReactElement {
          return <React.Fragment>{value ? dayjs(value).format('D MMMM YYYY HH:MM A') : ''}</React.Fragment>;
        },
      },
      {
        Header: t('applicationTable.updatedAt'),
        accessor: 'updatedAt',
        Cell: function ({ cell: { value } }: { cell: { value: Date } }): React.ReactElement {
          return <React.Fragment>{value ? dayjs(value).format('D MMMM YYYY HH:MM A') : ''}</React.Fragment>;
        },
      },
      {
        Header: t('applicationTable.insuredCount'),
        accessor: 'insuredCount',
        disableSortBy: true,
      },
      {
        Header: t('applicationTable.leadStatus'),
        accessor: 'leadStatus',
        disableSortBy: true,
        Cell: function ({ cell: { value } }: { cell: { value: string } }): React.ReactElement {
          return (
            <React.Fragment>
              <StatusBadge status={value as LeadStatus} />
            </React.Fragment>
          );
        },
      },
    ],

    [t]
  );

  return (
    <React.Fragment>
      <ListFilterContainer>
        <ApplicationsTableHeader onSearchChange={onSearchChange} />
      </ListFilterContainer>

      {isLoading && <LoadingView />}
      {shouldShowEmptySearchResultView && <EmptySearchResultView />}

      <Table<BackOfficeApplication>
        columns={columns}
        data={applications}
        onRowClick={onTableRowClick}
        onOrderChange={onSortingChanged}
      />
      <Box px={2.5}>
        <Pagination
          total={total}
          page={currentPage}
          perPage={perPage}
          perPageOptions={PER_PAGE_OPTIONS}
          onPageChange={onPageChange}
          onPerPageChange={onLimitPerPageChange}
        />
      </Box>
    </React.Fragment>
  );
}
