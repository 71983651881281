import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { JetDecisionOutcomes } from '@breathelife/types';

import { useCarrierContext, useDispatch } from '../../Hooks';
import { QueryId } from '../../ReactQuery/common/common.types';
import { notificationSlice } from '../../Redux/Notification/NotificationSlice';
import { getJetDecisionOutcomes } from '../../Services/JetDecisionService';

export function useGetJetDecisionOutcomesQuery(
  applicationId?: string,
  options?: UseQueryOptions<JetDecisionOutcomes[], Error>
): UseQueryResult<JetDecisionOutcomes[], Error> {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { features } = useCarrierContext();

  return useQuery<JetDecisionOutcomes[], Error>(
    [QueryId.jetDecisionOutcomes, applicationId],
    async () => {
      if (!applicationId) {
        return Promise.reject(new Error('application id is required.'));
      }

      return await getJetDecisionOutcomes(applicationId);
    },
    {
      ...options,
      enabled: features.assistedApplication?.jetDecisionWidget?.enabled && !!applicationId,
      onError: (error) => {
        options?.onError?.(error);
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToLoadJetDecisionOutcomes'),
          })
        );
      },
    }
  );
}
