import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReflexiveConditionValue, YesNoValue } from '@breathelife/types';

import {
  getConditionValidationError,
  ValidationValues,
} from '../../../Helpers/inputValidation/form/salesDecisionRules';
import { getOptionsFromEnum } from '../../../Helpers/options';
import { CollectionOperators } from '../Helpers/CollectionOperators';
import { ConditionTargetNodeAutocomplete } from '../Helpers/ConditionTargetNodeAutocomplete';
import { QuantifierRadioGroup } from '../Helpers/QuantifierRadioGroup';
import { ValidationErrorText } from '../ValidationErrorText';
import { ConditionCriteriaProps } from '../types';

function YesNoRadioGroup(props: { value: YesNoValue; onChange: (value: YesNoValue) => void }): React.ReactElement {
  const { value, onChange } = props;

  const { t } = useTranslation();

  const yesNoOptions = getOptionsFromEnum<YesNoValue>(YesNoValue, 'admin.conditions.options.yesNo');

  return (
    <QuantifierRadioGroup
      label={t('admin.conditions.labels.selectValue')}
      name='condition-yes-no-radio-group'
      options={yesNoOptions}
      onChange={onChange}
      value={value}
    />
  );
}

export function ReflexiveConditionCriteria({
  condition,
  nodeIdList,
  nodeIdInCollectionMap,
  collectionContext,
  onConditionPropertiesChange,
  language,
  conditionIndex,
}: ConditionCriteriaProps<ReflexiveConditionValue>): React.ReactElement {
  const validationErrors = getConditionValidationError(condition, nodeIdList, nodeIdInCollectionMap);
  const collectionNodeIds = nodeIdInCollectionMap[condition.targetNodeId] ?? [];

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <ConditionTargetNodeAutocomplete
          value={condition.targetNodeId}
          nodeIdList={nodeIdList}
          onSelect={(targetNodeId) => {
            if (targetNodeId !== null) {
              onConditionPropertiesChange({ targetNodeId });
            }
          }}
          validationError={validationErrors?.[ValidationValues.targetNodeId]}
          selectedLanguage={language}
        />
      </Grid>
      <Grid item xs={12}>
        <YesNoRadioGroup
          value={condition.value}
          onChange={(value) => {
            onConditionPropertiesChange({ value });
          }}
        />
        {validationErrors?.[ValidationValues.value] && (
          <ValidationErrorText>{validationErrors[ValidationValues.value].message}</ValidationErrorText>
        )}
      </Grid>
      <CollectionOperators
        collectionContext={collectionContext}
        collectionNodeIds={collectionNodeIds}
        collectionOperators={condition.collectionOperators}
        onConditionPropertiesChange={onConditionPropertiesChange}
        conditionIndex={conditionIndex}
      />
    </React.Fragment>
  );
}
