import { captureMessage, Severity } from '@sentry/browser';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { UserStatus } from '@breathelife/types';

import { LeadPlatformTheme } from '../../Styles/Types';
import { StyledBadge } from './Badge';

export type Props = {
  status: UserStatus;
};

export const UserStatusBadge = (props: Props): React.ReactElement | null => {
  const theme = useTheme() as LeadPlatformTheme;
  const { t } = useTranslation();

  const UserStatusTextColors: {
    [status in UserStatus | 'default']: { textColor: string; backgroundColor: string };
  } = useMemo(
    () => ({
      active: { textColor: theme.colors.green[60], backgroundColor: theme.colors.green[20] },
      invitationPending: { textColor: theme.colors.orange[60], backgroundColor: theme.colors.orange[20] },
      blocked: { textColor: theme.colors.red[60], backgroundColor: theme.colors.red[20] },
      default: { textColor: theme.colors.grey[50], backgroundColor: theme.colors.grey[20] },
    }),
    [theme]
  );

  const statusLabel = t(`user.status.${props.status}`);
  const statusColors = UserStatusTextColors[props.status] || UserStatusTextColors.default;

  if (!UserStatusTextColors[props.status] || !UserStatus[props.status]) {
    captureMessage(`Unknown user status: ${props.status}`, Severity.Error);
  }

  return (
    <StyledBadge
      backgroundColor={statusColors.backgroundColor}
      textColor={statusColors.textColor}
      label={statusLabel}
    />
  );
};
