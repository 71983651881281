import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useDispatch } from 'react-redux';

import { TypewriterTracking } from '@breathelife/react-tracking';
import { BackOfficeApplication, PageQueryOptions } from '@breathelife/types';

import { QueryId } from '../../../ReactQuery/common/common.types';
import { notificationSlice } from '../../../Redux/Notification/NotificationSlice';
import { GetApplicationsResponse, getBackOfficeApplications } from '../../../Services/BackOfficeService';

export function useFindBackOfficeApplicationsQuery(
  applicationsOptions: PageQueryOptions<BackOfficeApplication>,
  options?: UseQueryOptions<GetApplicationsResponse>
): UseQueryResult<GetApplicationsResponse> {
  const dispatch = useDispatch();
  return useQuery<GetApplicationsResponse>(
    [QueryId.backOfficeApplications, applicationsOptions],
    async () => {
      if (!applicationsOptions) return Promise.reject(new Error('Application options are missing.'));
      return await getBackOfficeApplications(applicationsOptions);
    },
    {
      ...options,
      enabled: !!applicationsOptions,
      onSuccess: (data) => {
        options?.onSuccess?.(data);

        if (applicationsOptions?.$search) {
          TypewriterTracking.searchedForAnApplication({ hasSearchResults: !!data.total });
        }
      },
      onError: (error) => {
        options?.onError?.(error);

        if (error instanceof Error) {
          dispatch(notificationSlice.actions.setError({ message: error.message }));
        }
      },
    }
  );
}
