import _ from 'lodash';

import {
  ESignSigner2FAInfo,
  ParticipantRoles,
  OwnerEntity,
  AdvisorEntity,
  ProposedInsuredEntity,
  PayerEntity,
  PdfDocumentType,
  ParticipantRole,
} from '@breathelife/types';

export type InsuranceEntity = ProposedInsuredEntity | AdvisorEntity | OwnerEntity | PayerEntity;

// Converts insurance entity into a signer with a role
// If entity is missing a property, we fallback to empty string
export function buildESignSigner(
  entity: ProposedInsuredEntity | AdvisorEntity | OwnerEntity | PayerEntity,
  roles: Omit<ParticipantRole, 'id'>[],
  documentTypes: PdfDocumentType[]
): ESignSigner2FAInfo {
  const eSignSigner = {
    firstName: entity.firstName ?? '',
    lastName: entity.lastName ?? '',
    cellphone: '',
    email: '',
    roles,
    documentTypes,
  };

  // Only set the cellphone of the signer if entity has a defined phoneNumber
  if ('phoneNumber' in entity && typeof entity.phoneNumber !== 'undefined') {
    eSignSigner.cellphone = entity.phoneNumber;
  }

  // Only set the email of the signer if entity has a defined email
  if ('email' in entity && typeof entity.email !== 'undefined') {
    eSignSigner.email = entity.email;
  }

  return eSignSigner;
}

/**
 * Helper to check if 2 insurance entities are 1 person
 * As of now, we only rely on the firstName and lastName properties for 2 reasons
 * 1. Some entities (like the Payor) only have a firstName and a lastName
 * 2. Entities, like the insured, might have a "placeholder" email (or empty email), an undefined email or an empty string
 * => this is because the user filling the application might choose to put a placeholder email for the insured,
 * => then only put the real insured's email after in the ESign drawer
 */
export function isSamePerson(entity1: InsuranceEntity, entity2: InsuranceEntity): boolean {
  return entity1.firstName === entity2.firstName && entity1.lastName === entity2.lastName;
}

/**
 * Helper function to convert the info fetched from the insurance-entities lib into signers for ESign
 * As of now this function assumes that:
 * 1. We only have 1 insured and 1 advisor signers
 * 2. The insured signer is also the owner and payor
 */
export function insuredEntitiesToESignSigner(
  insuredEntity: ProposedInsuredEntity,
  ownerEntity: OwnerEntity | null,
  payerEntity: PayerEntity | null,
  advisorEntity: AdvisorEntity
): ESignSigner2FAInfo[] {
  if (!ownerEntity || !_.isEqualWith(ownerEntity, insuredEntity, isSamePerson)) {
    throw new Error('Owner must be the same as the Insured');
  }
  if (!payerEntity || !_.isEqualWith(payerEntity, insuredEntity, isSamePerson)) {
    throw new Error('Payor must be the same as the Insured');
  }

  const proposedInsured = buildESignSigner(
    insuredEntity,
    [
      { type: ParticipantRoles.INSURED, position: 1 },
      { type: ParticipantRoles.OWNER_PERSON, position: 1 },
      { type: ParticipantRoles.PAYER, position: 1 },
    ],
    [PdfDocumentType.application]
  );

  const advisor = buildESignSigner(
    advisorEntity,
    [{ type: ParticipantRoles.AGENT, position: 1 }],
    [PdfDocumentType.application]
  );

  return [proposedInsured, advisor];
}
