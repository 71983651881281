import * as yup from 'yup';

import { Language } from '@breathelife/types';

import { localizableFieldSchema } from '../field';
import { objectSchemaFieldsAreDefined } from '../typeGuards';

export function createSelectOptionFormSchema(
  existingOptionIds: string[],
  enabledLanguages: Language[]
): yup.ObjectSchema {
  return yup.object().shape({
    text: localizableFieldSchema(enabledLanguages, { required: true }),
    optionId: yup.mixed().notOneOf(existingOptionIds),
  });
}

export function getOptionIdFieldValidationError(
  value: string,
  existingOptionIds: string[],
  enabledLanguages: Language[]
): yup.ValidationError | undefined {
  const schema = createSelectOptionFormSchema(existingOptionIds, enabledLanguages);

  if (!objectSchemaFieldsAreDefined(schema)) return;

  try {
    schema.fields['optionId'].validateSync(value);
  } catch (error) {
    return error;
  }

  return;
}
