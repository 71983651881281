import Box from '@material-ui/core/Box';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Permission } from '@breathelife/types';

import { SubmitButton } from '../../../Components/Button/SubmitButton';
import { RestrictedToPermission } from '../../../Components/Restricted/RestrictedToPermission';
import Typography from '../../../Components/Typography';
import { CarrierContext } from '../../../Context/CarrierContext';
import { useDispatch, useNavigation } from '../../../Hooks';
import { ModalLayout } from '../../../Layouts/Modal/ModalLayout';
import { Lead } from '../../../Models/Lead';
import { useDeleteLeadMutation } from '../../../ReactQuery/Lead/lead.mutations';
import { navigateToLeadsList } from '../../../Redux/Navigation/NavigationOperations';

export type Props = {
  isOpen: boolean;
  closeModal: () => void;
  lead: Lead;
};

export function DeleteLeadModal(props: Props): React.ReactElement {
  const { t } = useTranslation();
  const { features } = useContext(CarrierContext);
  const dispatch = useDispatch();
  const { leadTab } = useNavigation();
  const { isOpen, closeModal, lead } = props;

  const deleteLeadMutation = useDeleteLeadMutation({
    onSuccess: () => {
      dispatch(navigateToLeadsList(leadTab));
      closeModal();
    },
  });

  function deleteLead(): void {
    deleteLeadMutation.mutate(lead.id);
  }

  return (
    <ModalLayout
      maxWidth='sm'
      isOpen={isOpen}
      closeModal={closeModal}
      title={t(
        features.hiddenLeads?.enabled ? 'modals.deleteLead.deleteApplicationTitle' : 'modals.deleteLead.deleteLeadTitle'
      )}
      submitButton={
        <RestrictedToPermission permission={Permission.LeadDelete}>
          <SubmitButton
            isLoading={deleteLeadMutation.isLoading}
            disabled={deleteLeadMutation.isLoading}
            onClick={deleteLead}
            data-testid='lead-delete-confirm'
          >
            {t('cta.delete')}
          </SubmitButton>
        </RestrictedToPermission>
      }
    >
      <Box mb={5}>
        <Typography variant='body1' grey={90}>
          {t(
            features.hiddenLeads?.enabled
              ? 'modals.deleteLead.deleteApplicationConfirmation'
              : 'modals.deleteLead.deleteLeadConfirmation'
          )}{' '}
          {t('modals.deleteLead.irreversible')}
        </Typography>
      </Box>
    </ModalLayout>
  );
}
