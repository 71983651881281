import { Box, Dialog } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { Button } from '../../Components/Button/Button';
import { CloseButton } from '../../Components/Button/CloseButton';
import { Transition, TransitionInnerProps, TransitionVariant } from '../../Components/Transition';
import Typography from '../../Components/Typography';

type ModalLayoutProps = {
  isOpen: boolean;
  closeModal?: () => void;
  onExited?: () => void;
  title?: string;
  titleIcon?: React.ReactNode;
  submitButton?: React.ReactNode;
  children: React.ReactNode;
  confirmationRequired?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  secondaryAction?: React.ReactNode;
  bodyStyling?: FlattenSimpleInterpolation;
  footerStyling?: FlattenSimpleInterpolation;
  hideCancel?: boolean;
  disableBodyPadding?: boolean;
  fullScreen?: boolean;
  closeButton?: React.ReactNode;
  transition?: TransitionVariant;
  transitionProps?: TransitionProps;
};

const Header = styled.header`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[30]};
  padding: 16px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled(Typography)`
  line-height: 21px;
  color: ${(props) => props.theme.colors.grey[90]};
  margin: 0;
`;

type BodyProps = {
  bodyStyling?: FlattenSimpleInterpolation;
  disableBodyPadding?: boolean;
  fullScreen?: boolean;
};

const Body = styled.div<BodyProps>`
  padding: ${(props) => (props.disableBodyPadding ? '0' : '16px 32px')};
  ${(props) => props.bodyStyling ?? ''}
  ${(props) =>
    props.fullScreen &&
    css`
      flex: 1;
    `}
`;

type FooterProps = {
  footerStyling?: FlattenSimpleInterpolation;
};

const Footer = styled.footer<FooterProps>`
  border-top: 1px solid ${(props) => props.theme.colors.grey[30]};
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  ${(props) => props.footerStyling ?? ''}
`;

export function ModalLayout(props: ModalLayoutProps): React.ReactElement | null {
  const { transition } = props;
  const { t } = useTranslation();

  const transitionComponent = useMemo(() => {
    if (!transition) return undefined;
    return (props: TransitionInnerProps) => <Transition variant={transition} {...props} />;
  }, [transition]);

  return (
    <Dialog
      fullWidth
      fullScreen={props.fullScreen}
      maxWidth={props.maxWidth}
      open={props.isOpen}
      onClose={props.closeModal}
      onExited={props.onExited}
      disableBackdropClick={props.confirmationRequired}
      disableEscapeKeyDown={props.confirmationRequired}
      TransitionComponent={transitionComponent}
      TransitionProps={props.transitionProps}
      closeAfterTransition
      role='dialog'
    >
      <Box position='relative' display='flex' height='100%' flexDirection='column'>
        {props.title && (
          <Header>
            {!!props.titleIcon && (
              <Box display='flex' alignItems='center' mr={1.5}>
                {props.titleIcon}
              </Box>
            )}
            <Title variant='h2'>{props.title}</Title>
          </Header>
        )}
        <Box position='absolute' top={0} right={0}>
          {props.closeButton
            ? props.closeButton
            : !props.confirmationRequired && (
                <Box mt={1.5} mr={1.5}>
                  <CloseButton onClick={props.closeModal} />
                </Box>
              )}
        </Box>

        <Body
          bodyStyling={props.bodyStyling}
          disableBodyPadding={props.disableBodyPadding}
          fullScreen={props.fullScreen}
        >
          {props.children}
        </Body>
        {(props.submitButton || props.secondaryAction || (!props.hideCancel && !props.confirmationRequired)) && (
          <Footer footerStyling={props.footerStyling}>
            {props.secondaryAction}
            <Box ml='auto' display='flex'>
              {!props.hideCancel && !props.confirmationRequired && (
                <Button variant='outlined' onClick={props.closeModal}>
                  {t('cta.cancel')}
                </Button>
              )}
              {props.submitButton && <Box ml={2}>{props.submitButton}</Box>}
            </Box>
          </Footer>
        )}
      </Box>
    </Dialog>
  );
}
