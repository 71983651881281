import i18next from 'i18next';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useDispatch } from 'react-redux';

import {
  EntityMappingDefinition,
  GetEntityMappingDefinitionParams,
  isCompleteEntityMappingDefinitionRequestOptions,
} from '@breathelife/types';

import { QueryId } from '../../../ReactQuery/common/common.types';
import { notificationSlice } from '../../../Redux/Notification/NotificationSlice';
import { getEntityMapping } from '../../../Services/EntityMappingService';

export function useGetEntityMappingQuery(
  fetchOptions: Partial<GetEntityMappingDefinitionParams>,
  options?: UseQueryOptions<EntityMappingDefinition | undefined>
): UseQueryResult<EntityMappingDefinition | undefined> {
  const dispatch = useDispatch();
  const query = [
    QueryId.entityMappings,
    fetchOptions.mapping,
    fetchOptions.namespace,
    fetchOptions.questionnaireVersionId,
  ];

  return useQuery<EntityMappingDefinition | undefined>(
    query,
    () => {
      if (isCompleteEntityMappingDefinitionRequestOptions(fetchOptions)) {
        return getEntityMapping(fetchOptions);
      }
      return;
    },
    {
      ...options,
      onError: (error: any) => {
        options?.onError?.(error);
        dispatch(
          notificationSlice.actions.setError({
            message: i18next.t('notifications.failedToFetchEntity'), // TODO proper text
          })
        );
      },
    }
  );
}
