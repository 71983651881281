import Box from '@material-ui/core/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ESignSigner2FAInfo, OutcomeCode } from '@breathelife/types';

import { OutcomeBadge } from '../../../../../Components/Badge/OutcomeBadge';
import Typography from '../../../../../Components/Typography';
import { SignerInfoHeader } from './SignerInfoHeader';

type SignerInfoProps = {
  signer: ESignSigner2FAInfo;
  outcome?: OutcomeCode;
  overrideOutcome?: string;
};

export function SignerInfo(props: SignerInfoProps): React.ReactElement {
  const { t } = useTranslation();
  const { signer, outcome, overrideOutcome } = props;

  return (
    <div>
      <SignerInfoHeader signer={signer} />
      <Box px={2}>
        {outcome && (
          <Box mb={2}>
            <OutcomeBadge outcome={outcome} overrideOutcome={overrideOutcome} />
          </Box>
        )}
        <Box mb={2}>
          <Typography variant='body5' component='div'>
            {t('assistedApplication.eSignatureDetails.email')}
          </Typography>
          <Typography variant='body1' component='div'>
            {signer.email}
          </Typography>
        </Box>
        <Box>
          <Typography variant='body5' component='div'>
            {t('assistedApplication.eSignatureDetails.phoneNumber')}
          </Typography>
          <Typography variant='body1' component='div'>
            {signer.cellphone}
          </Typography>
        </Box>
      </Box>
    </div>
  );
}
