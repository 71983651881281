import Box from '@material-ui/core/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import Typography from '../../../../Components/Typography';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';

type SubmitPaperAppModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export function SubmitPaperAppModal(props: SubmitPaperAppModalProps): React.ReactElement {
  const { t } = useTranslation();
  const { isOpen, onClose, onConfirm } = props;

  return (
    <ModalLayout
      maxWidth='sm'
      isOpen={isOpen}
      closeModal={onClose}
      title={t('modals.submitPaperApp.title')}
      submitButton={<SubmitButton onClick={onConfirm}>{t('modals.submitPaperApp.submitButton')}</SubmitButton>}
    >
      <Box>
        <Typography variant='body1'>{t('modals.submitPaperApp.body')}</Typography>
      </Box>
    </ModalLayout>
  );
}
