import _ from 'lodash';

import { Paginated, BackOfficeApplication, PageQueryOptions } from '@breathelife/types';

import ApiService from './ApiService';

export type BackOfficeSortParams = {
  field: keyof BackOfficeApplication;
  direction?: 'asc' | 'desc';
};

export type GetApplicationsResponse = {
  total: number;
  limit: number;
  skip: number;
  data: BackOfficeApplication[];
};

export async function getBackOfficeApplications(
  options: PageQueryOptions<BackOfficeApplication>
): Promise<Paginated<BackOfficeApplication>> {
  const response = await ApiService.getBackOfficeApplications(options);
  return response.data;
}
