import i18next from 'i18next';
import _ from 'lodash';

import { Theme } from '@breathelife/types';

import { themeSlice } from '../../../Redux/Admin/ThemeManagement/ThemeSlice';
import { notificationSlice } from '../../../Redux/Notification/NotificationSlice';
import { Dispatch } from '../../../Redux/types';
import ApiService from '../../../Services/ApiService';

export const fetchTheme =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(themeSlice.actions.setIsFetching(true));

    try {
      const response = await ApiService.fetchTheme();
      dispatch(themeSlice.actions.setTheme({ theme: response.data }));
    } catch (error) {
      dispatch(
        notificationSlice.actions.setError({
          message: i18next.t('notifications.failedToLoadTheme'),
        })
      );
    } finally {
      dispatch(themeSlice.actions.setIsFetching(false));
    }
  };

export const updateTheme =
  (currentTheme: Theme, newTheme: Theme) =>
  async (dispatch: Dispatch): Promise<void> => {
    const haveColorsChanged = !_.isEqual(currentTheme.colorRanges, newTheme.colorRanges);
    const haveLogosChanged =
      currentTheme.compactLogoImgUrl !== newTheme.compactLogoImgUrl || currentTheme.logoImgUrl !== newTheme.logoImgUrl;
    const hasNavigationSidebarVariantChanged =
      currentTheme.navigationSidebarVariant !== newTheme.navigationSidebarVariant;

    if (haveColorsChanged) {
      dispatch(themeSlice.actions.setIsUpdatingColors(true));
    }

    if (haveLogosChanged) {
      dispatch(themeSlice.actions.setIsUpdatingLogos(true));
    }

    if (hasNavigationSidebarVariantChanged) {
      dispatch(themeSlice.actions.setIsUpdatingNavigationSidebarVariant(true));
    }

    try {
      const updatedTheme = _.pick(
        newTheme,
        'colorRanges',
        'logoImgUrl',
        'compactLogoImgUrl',
        'navigationSidebarVariant'
      );
      const response = await ApiService.updateTheme(newTheme.id, updatedTheme);
      dispatch(themeSlice.actions.setTheme({ theme: response.data }));
      dispatch(
        notificationSlice.actions.setSuccess({
          message: i18next.t('notifications.themeUpdateSuccess'),
          autoHideDuration: 3000,
        })
      );
    } catch (error) {
      dispatch(
        notificationSlice.actions.setError({
          message: i18next.t('notifications.failedToUpdateTheme'),
        })
      );
    } finally {
      dispatch(themeSlice.actions.setIsUpdatingColors(false));
      dispatch(themeSlice.actions.setIsUpdatingLogos(false));
      dispatch(themeSlice.actions.setIsUpdatingNavigationSidebarVariant(false));
    }
  };
