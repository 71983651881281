import React from 'react';
import { useTranslation } from 'react-i18next';

import { NumberComparisonConditionOperator } from '@breathelife/types';
import { SelectMui } from '@breathelife/ui-components';

import { getOptionsFromEnum } from '../../../Helpers/options';

export function NumberComparisonConditionOperatorSelect(props: {
  value: NumberComparisonConditionOperator;
  onChange: (value: NumberComparisonConditionOperator) => void;
}): React.ReactElement {
  const { value, onChange } = props;

  const { t } = useTranslation();

  const options = getOptionsFromEnum<NumberComparisonConditionOperator>(
    NumberComparisonConditionOperator,
    'admin.conditions.options.numberComparison'
  );

  return (
    <SelectMui
      id='number-comparison-condition-operator-select'
      label={t('admin.conditions.labels.numberComparison')}
      options={options}
      value={value}
      onChange={onChange}
    />
  );
}
