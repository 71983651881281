import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { InsuranceScopes, Permission } from '@breathelife/types';
import { Input as TextInput } from '@breathelife/ui-components';

import { Alert } from '../../../../Components/Alert/Alert';
import { Icon } from '../../../../Components/Icons';
import RadioGroup, { Radio } from '../../../../Components/RadioGroup/RadioGroup';
import { RestrictedToPermission } from '../../../../Components/Restricted/RestrictedToPermission';
import Typography from '../../../../Components/Typography';
import { useCarrierContext, useSelector } from '../../../../Hooks';
import { CopyLinkSection } from './CopyLinkSection';

type SharingPreferencesProps = {
  isCompletingProfile: boolean;
  isPublicLinkLoading: boolean;
  hasSharingPreferenceChanged: boolean;
  sharingPreference?: InsuranceScopes;
  publicLink?: string;
  securityDisclosure?: string;
  onSecurityDisclosureChange: (value: string) => void;
  onSharingPreferenceChange: (value: InsuranceScopes) => void;
  onCopyAgentLink: () => void;
  isSso?: boolean;
};

export function SharingPreferences(props: SharingPreferencesProps): React.ReactElement {
  const {
    hasSharingPreferenceChanged,
    isCompletingProfile,
    isPublicLinkLoading,
    onCopyAgentLink,
    onSharingPreferenceChange,
    publicLink,
    sharingPreference,
    isSso,
    securityDisclosure,
    onSecurityDisclosureChange,
  } = props;
  const { t } = useTranslation();
  const { insuranceScopes: carrierInsuranceScopes, carrierInfo, ssoProfileFields, features } = useCarrierContext();
  const user = useSelector((store) => store.leadPlatform.authentication.user);
  const isPublicLinkEnabled = useSelector((store) => store.leadPlatform.settings.settings?.data.publicLink.enabled);

  const insuranceScopesRadioChoices = _.map(carrierInsuranceScopes, (scope) => ({
    value: scope,
    label: t(`insuranceScope.${scope}`),
  }));

  const isInsuranceScopeDisabled = useMemo((): boolean => {
    if (isSso && ssoProfileFields.includes('defaultInsuranceScopes')) return true;

    // TODO DEV-3842 Remove this condition once NLG pilot users are migrated to SSO
    // Prevent NLG pilot users ( not logged in via SSO ) from changing their insurance scope once it is set. This was a
    // request from the client and can be remove once all their users are migrated to connect via SSO.
    // ------------------------------------------------------------------------------
    const isNLG = carrierInfo.companyName === 'National Life Group';
    const userHasInsuranceScope = user?.defaultInsuranceScopes?.length;
    if (isNLG && userHasInsuranceScope) return true;
    // ------------------------------------------------------------------------------
    // END DEV-3842

    return false;
  }, [ssoProfileFields, isSso, user, carrierInfo.companyName]);

  return (
    <React.Fragment>
      <Box mb={3}>
        <Box mb={1}>
          <Typography variant='h3' grey={90}>
            {t('modals.editUser.mySharingPreferences')}
          </Typography>
        </Box>
        <RadioGroup<InsuranceScopes>
          label={t('modals.editUser.selectDefaultPreference')}
          name='userEdit-sharing-preferences'
          onChange={onSharingPreferenceChange}
          value={sharingPreference}
        >
          <Grid container spacing={1}>
            {_.map(insuranceScopesRadioChoices, (choice) => {
              return (
                <Grid item xs={12} sm={6} key={choice.value}>
                  <Radio<InsuranceScopes>
                    value={choice.value}
                    label={choice.label}
                    disabled={isInsuranceScopeDisabled}
                  />
                </Grid>
              );
            })}
          </Grid>
        </RadioGroup>

        {isInsuranceScopeDisabled && (
          <Box mt={2}>
            <Alert severity='info'>{t('modals.editUser.insuranceScopeDisabled')}</Alert>
          </Box>
        )}
      </Box>

      {isPublicLinkEnabled && (
        <RestrictedToPermission permission={Permission.LeadInvitePublic}>
          <CopyLinkSection
            isCompletingProfile={isCompletingProfile}
            isPublicLinkLoading={isPublicLinkLoading}
            onCopyAgentLink={onCopyAgentLink}
            publicLink={publicLink}
          />
        </RestrictedToPermission>
      )}

      {!isCompletingProfile && hasSharingPreferenceChanged && !isPublicLinkLoading && (
        <Box mt={2}>
          <Alert severity='info'>{t('modals.editUser.saveSharingPreferences')}</Alert>
        </Box>
      )}

      {isCompletingProfile && !sharingPreference && !hasSharingPreferenceChanged && (
        <Box mt={2}>
          <Alert severity='info'>{t('modals.editUser.selectASharingPreference')}</Alert>
        </Box>
      )}

      {features.userSecurityDisclosure.enabled && (
        <Box mt={3}>
          <TextInput
            fullWidth
            multiline
            rowsMax={6}
            id='userEditModal-security-disclosure'
            inputVariant='outlined'
            label={t('inputs.securityDisclosure')}
            labelTooltip={{
              icon: <Icon name='info' variant='default' width='15' height='15' />,
              text: t('modals.editUser.securityDisclosureToolTip'),
            }}
            value={securityDisclosure}
            disabled={isSso && ssoProfileFields.includes('securityDisclosure')}
            onChange={(event) => onSecurityDisclosureChange(event.target.value)}
          />
        </Box>
      )}
    </React.Fragment>
  );
}
