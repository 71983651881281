import styled from 'styled-components';

export const LeadItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  padding-top: 24px;
`;

export const LeadItemButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
`;
