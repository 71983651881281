import { InputProps } from '@material-ui/core';
import React from 'react';

import { Input, Box } from '@breathelife/ui-components';

import { Icon } from '../../../../../Components/Icons';

type Props = {
  value?: string;
  label?: string;
  placeholder?: string;
  onChange?: InputProps['onChange'];
  onBlur?: InputProps['onBlur'];
  multiline?: InputProps['multiline'];
  disabled?: InputProps['disabled'];
  helperTooltipText?: string;
  highlighted?: boolean;
};

export function TextInput(props: Props): React.ReactElement {
  const { value, label, placeholder, onChange, onBlur, multiline, disabled, helperTooltipText, highlighted } = props;
  return (
    <Box pt={1}>
      <Input
        highlighted={!!highlighted}
        label={label}
        value={value}
        placeholder={placeholder}
        inputVariant='outlined'
        onChange={onChange}
        onBlur={onBlur}
        multiline={multiline}
        disabled={disabled}
        labelTooltip={
          helperTooltipText ? { text: helperTooltipText, icon: <Icon name='info' width='14px' /> } : undefined
        }
      />
    </Box>
  );
}
