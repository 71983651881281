import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NavigationSidebarVariants } from '@breathelife/types';

import { Button } from '../../../Components/Button/Button';
import { Select } from '../../../Components/Select/Select';
import { getFieldValidationError } from '../../../Helpers/inputValidation/form/theme';
import { getOptionsFromEnum } from '../../../Helpers/options';

type Props = {
  navigationSidebarVariant: NavigationSidebarVariants;
  isUpdating: boolean;
  onSaveClick: (navigationSidebarVariant: NavigationSidebarVariants) => void;
};

export function SidebarVariantEditView({
  navigationSidebarVariant: navigationSidebarVariantProp,
  isUpdating,
  onSaveClick,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  const [navigationSidebarVariant, setNavigationSidebarVariant] =
    useState<NavigationSidebarVariants>(navigationSidebarVariantProp);

  useEffect(() => {
    setNavigationSidebarVariant(navigationSidebarVariantProp);
  }, [navigationSidebarVariantProp]);

  const navigationSidebarOptions = getOptionsFromEnum(
    NavigationSidebarVariants,
    'admin.themeManagement.navigationSidebarVariant.options'
  );

  const validationError = getFieldValidationError('navigationSidebarVariant', navigationSidebarVariant);

  return (
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='navigationSidebarVariant-content'
        id='navigationSidebarVariant-header'
      >
        <Box display='flex' flexDirection='row' alignItems='center' mr={2}>
          <Typography variant='h2'>{t('admin.themeManagement.navigationSidebarVariant.title')}</Typography>
        </Box>
      </ExpansionPanelSummary>
      <Divider />
      <MuiExpansionPanelDetails>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Select
            id='sidebar-theme-dropdown'
            value={navigationSidebarVariant}
            onChange={(value) => setNavigationSidebarVariant(value as NavigationSidebarVariants)}
            options={navigationSidebarOptions}
          />
        </Box>
      </MuiExpansionPanelDetails>
      <Divider />
      <ExpansionPanelActions>
        <Button
          disabled={Boolean(validationError)}
          data-testid='themeSidebarVariantEdit-save'
          color='primary'
          variant='text'
          isLoading={isUpdating}
          onClick={() => onSaveClick(navigationSidebarVariant)}
        >
          {t('cta.save').toUpperCase()}
        </Button>
      </ExpansionPanelActions>
    </ExpansionPanel>
  );
}
