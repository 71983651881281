import Box from '@material-ui/core/Box';
import { NavLinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Button } from '../../Components/Button/Button';
import { Image } from '../../Components/Images/Image';

export const StyledSidebar = styled(Box)<{ background: string; border: string }>`
  background: ${(props) => props.background};
  border: ${(props) => props.border};
  border-radius: 6px;
  box-shadow: -6px -6px 10px rgba(28, 43, 79, 0.12);
  overflow-y: auto;
`;

export const Logo = styled(Image)`
  &&& {
    img {
      max-width: 56px;
    }
  }
`;

const activeNavLinkButtonStyles = css<StyledNavLinkButtonProps>`
  background-color: ${(props) => props.activeColor ?? props.theme.colors.primary[80]};
`;

type StyledNavLinkButtonProps = Omit<NavLinkProps, 'isActive'> & {
  isActive: boolean;
  activeColor?: string;
  hoverColor?: string;
};

export const NavLinkButton = styled(Button).withConfig({
  shouldForwardProp: (prop) => !['isActive', 'activeColor', 'hoverColor'].includes(prop),
})<StyledNavLinkButtonProps>`
  && {
    background-color: transparent;
    color: ${(props) => props.theme.colors.grey[0]};
    width: 56px;
    height: 56px;
    border: none;
    border-radius: 4px;
    padding: 0;
    outline: 0;
    cursor: pointer;

    &:hover {
      background-color: ${(props) => props.hoverColor ?? props.theme.colors.primary[70]};
    }

    &:active {
      background-color: ${(props) => props.activeColor ?? props.theme.colors.primary[80]};
    }

    &:focus:not(:active) {
      outline: 0;
      border: 1px solid ${(props) => props.theme.colors.tertiary.default};
    }

    ${(props) => props.isActive && activeNavLinkButtonStyles}
  }
`;

export const SidebarFooter = styled(Box)`
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;
