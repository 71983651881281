import React from 'react';
import { useTranslation } from 'react-i18next';

import { SignatureType } from '@breathelife/types';

import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';
import { SubmissionDetails } from './SubmissionDetails';

type SubmissionDetailsModalProps = {
  signatureType: SignatureType;
  confirmationNumber?: string;
  cryptoSignature?: string;
  isOpen: boolean;
  closeModal: () => void;
};

export function SubmissionDetailsModal(props: SubmissionDetailsModalProps): React.ReactElement {
  const { signatureType, confirmationNumber, cryptoSignature, isOpen, closeModal } = props;
  const { t } = useTranslation();

  return (
    <ModalLayout
      maxWidth='sm'
      title={t('modals.assistedApplication.submissionDetails.modalTitle')}
      isOpen={isOpen}
      closeModal={closeModal}
      hideCancel
    >
      <SubmissionDetails
        signatureType={signatureType}
        confirmationNumber={confirmationNumber}
        cryptoSignature={cryptoSignature}
      />
    </ModalLayout>
  );
}
