import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FileTemplate, Permission } from '@breathelife/types';

import { RestrictedToPermission } from '../../../../../../Components/Restricted/RestrictedToPermission';
import { RequiredFile } from '../../../../../../Hooks/Application';
import { Application } from '../../../../../../Models/Application';
import { CreateApplicationFileMutateOptions } from '../../../../../../ReactQuery/Application/application.mutations';
import { NoFiles } from '../NoFiles';
import { CompletedRequiredFile } from './CompletedRequiredFile';
import { MissingRequiredFile } from './MissingRequiredFile';

type Props = {
  application: Application;
  files: RequiredFile[];
  onFileUpload: (file: File, template: FileTemplate, options?: CreateApplicationFileMutateOptions) => void;
  onFileDelete: (fileId: string, isRequired: boolean) => void;
};

export function RequiredFiles(props: Props): React.ReactElement {
  const { t } = useTranslation();
  const { application, files, onFileUpload, onFileDelete } = props;

  if (!files.length) {
    return <NoFiles message={t('assistedApplication.documentsDrawer.noRequiredFiles')} />;
  }

  return (
    <List disablePadding>
      {files.map((file) => (
        <div key={file.template.id}>
          {file.storedFile ? (
            <CompletedRequiredFile
              application={application}
              template={file.template}
              storedFile={file.storedFile}
              onFileDelete={onFileDelete}
            />
          ) : (
            <RestrictedToPermission permission={[Permission.ApplicationFileCreate]}>
              <MissingRequiredFile template={file.template} onFileUpload={onFileUpload} />
            </RestrictedToPermission>
          )}
          <Divider />
        </div>
      ))}
    </List>
  );
}
