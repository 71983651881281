import i18next from 'i18next';
import _ from 'lodash';
import * as yup from 'yup';

import {
  BlueprintConditionsValue,
  BlueprintConditionValue,
  BlueprintSingleConditionValue,
  ConditionBlueprintType,
  ConsiderationBlueprint,
  DateUnit,
  isBlueprintConditionsValue,
  MathConditionOperator,
  NumberComparisonConditionOperator,
  QuestionnaireBlueprintFieldTypes,
} from '@breathelife/types';

import { buildNodeIdList } from '../../../Helpers/conditions/blueprintHelpers';
import { criteriaMetadata } from '../../../Helpers/questionnaireEditor/criteriaMetadata';
import {
  NodeDetail,
  NodeIdInCollections,
  QuestionnaireNodeIds,
} from '../../../Helpers/questionnaireEditor/questionnaireNodeIds';
import { objectSchemaFieldsAreDefined } from '../typeGuards';

function validateNodeIdExists(nodeDetails: NodeDetail[], nodeId: string | null | undefined): boolean {
  return nodeDetails.some((nodeDetail) => nodeDetail.answerNodeId === nodeId);
}

function validateNodeIdIsNumber(nodeDetails: NodeDetail[], nodeId: string): boolean {
  const node = nodeDetails.find((nodeDetail) => nodeDetail.answerNodeId === nodeId);
  if (node) {
    return (
      node.fieldType === QuestionnaireBlueprintFieldTypes.number ||
      node.fieldType === QuestionnaireBlueprintFieldTypes.money
    );
  }
  return false;
}

export enum ValidationValues {
  minAge = 'minAge',
  maxAge = 'maxAge',
  minAgeValue = 'value.minAge',
  maxAgeValue = 'value.maxAge',
  targetBirthdateNodeId = 'targetBirthdateNodeId',
  heightUnit = 'heightUnit',
  weightUnit = 'weightUnit',
  heightNodeId = 'heightNodeId',
  weightNodeId = 'weightNodeId',
  minBMI = 'minBMI',
  maxBMI = 'maxBMI',
  minBMIValue = 'value.minBMI',
  maxBMIValue = 'value.maxBMI',
  targetNodeId = 'targetNodeId',
  charactersMinLength = 'value.minLength',
  charactersMaxLength = 'value.maxLength',
  isEmpty = 'isEmpty',
  type = 'type',
  operator = 'operator',
  value = 'value',
  quantifier = 'quantifier',
  mathOperator = 'mathOperator',
  nodeIds = 'nodeIds',
  unit = 'unit',
  isEqual = 'isEqual',
  startDateNodeId = 'startDateNodeId',
  endDateNodeId = 'endDateNodeId',
  controlValue = 'controlValue',
  nodeIdOfValue = 'nodeIdOfValue',
  percent = 'percent',
}

export function ageRangeConditionSchema(nodeDetails: NodeDetail[]): yup.ObjectSchema {
  return yup.object().shape({
    [ValidationValues.value]: yup.object().shape({
      [ValidationValues.minAge]: yup
        .number()
        .lessThan(yup.ref(ValidationValues.maxAge), i18next.t('validation.error.minValue')),
      [ValidationValues.maxAge]: yup
        .number()
        .moreThan(yup.ref(ValidationValues.minAge), i18next.t('validation.error.maxValue')),
    }),
    [ValidationValues.targetBirthdateNodeId]: yup
      .string()
      .test('require-node-id', i18next.t('validation.error.required'), (nodeId) => !_.isEmpty(nodeId))
      .test(
        i18next.t('validation.error.nodeIdInList'),
        i18next.t('validation.error.nodeIdNotInList'),
        (targetBirthdateNodeId) => !targetBirthdateNodeId || validateNodeIdExists(nodeDetails, targetBirthdateNodeId)
      ),
  });
}

export function characterCountInBetweenConditionSchema(nodeDetails: NodeDetail[]): yup.ObjectSchema {
  return yup.object().shape({
    value: yup.object().shape({
      minLength: yup.number().test('minLength-if-alone', i18next.t('validation.error.minValue'), function (minLength) {
        const maxLength = this.parent?.maxLength;
        return minLength === undefined || minLength === null || maxLength === undefined || minLength <= maxLength;
      }),
      maxLength: yup.number().test('maxLength-if-alone', i18next.t('validation.error.maxValue'), function (maxLength) {
        const minLength = this.parent?.minLength;
        return minLength === undefined || maxLength === undefined || maxLength === null || minLength <= maxLength;
      }),
    }),
    [ValidationValues.targetNodeId]: yup
      .string()
      .test('require-node-id', i18next.t('validation.error.required'), (nodeId) => !!nodeId)
      .test(
        i18next.t('validation.error.nodeIdInList'),
        i18next.t('validation.error.nodeIdNotInList'),
        (nodeId) => !nodeId || (validateNodeIdExists(nodeDetails, nodeId) && !_.isEmpty(nodeId))
      ),
  });
}

export function bmiRangeConditionSchema(
  nodeDetails: NodeDetail[],
  nodeIdInCollectionMap: NodeIdInCollections
): yup.ObjectSchema {
  return yup.object().shape({
    [ValidationValues.heightUnit]: yup.string().oneOf(['inch', 'cm']),
    [ValidationValues.weightUnit]: yup.string().oneOf(['lb', 'kg']),
    [ValidationValues.heightNodeId]: yup
      .mixed()
      .test('require-height-id', i18next.t('validation.error.required'), (nodeId) => !!nodeId)
      .test(
        i18next.t('validation.error.nodeIdInList'),
        i18next.t('validation.error.nodeIdNotInList'),
        (nodeId) => !nodeId || validateNodeIdExists(nodeDetails, nodeId)
      ),
    [ValidationValues.weightNodeId]: yup
      .mixed()
      .test('require-weight-id', i18next.t('validation.error.required'), (nodeId) => !!nodeId)
      .test(
        i18next.t('validation.error.nodeIdInList'),
        i18next.t('validation.error.nodeIdNotInList'),
        (nodeId) => !nodeId || validateNodeIdExists(nodeDetails, nodeId)
      )
      .test('are-collections-mismatched', i18next.t('validation.error.mismatchedCollectionNodeIds'), function () {
        const { heightNodeId, weightNodeId } = this.parent;
        return areNodeIdsInTheSameCollection([heightNodeId, weightNodeId], nodeIdInCollectionMap);
      }),
    [ValidationValues.value]: yup.object().shape({
      [ValidationValues.minBMI]: yup
        .number()
        .positive(i18next.t('validation.error.positive'))
        .lessThan(yup.ref(ValidationValues.maxBMI), i18next.t('validation.error.minValue')),
      [ValidationValues.maxBMI]: yup
        .number()
        .positive(i18next.t('validation.error.positive'))
        .moreThan(yup.ref(ValidationValues.minBMI), i18next.t('validation.error.maxValue')),
    }),
  });
}

export function emptinessConditionSchema(nodeDetails: NodeDetail[]): yup.ObjectSchema {
  return yup.object().shape({
    [ValidationValues.targetNodeId]: yup
      .mixed()
      .test('require-node-id', i18next.t('validation.error.required'), (nodeId) => !!nodeId)
      .test(
        i18next.t('validation.error.nodeIdInList'),
        i18next.t('validation.error.nodeIdNotInList'),
        (nodeId) => !nodeId || validateNodeIdExists(nodeDetails, nodeId)
      ),
    [ValidationValues.isEmpty]: yup.string().required(i18next.t('validation.error.required')),
    [ValidationValues.type]: yup.string().oneOf([ConditionBlueprintType.emptiness]),
  });
}

function numberOrNumberNodeIdFieldSchema(nodeDetails: NodeDetail[]): yup.ObjectSchema {
  return yup.object().shape({
    [ValidationValues.value]: yup
      .number()
      .test(i18next.t('validation.error.required'), i18next.t('validation.error.required'), function (value) {
        const { nodeIdOfValue } = this.parent;
        if (!nodeIdOfValue) return value != null;
        return true;
      }),
    [ValidationValues.nodeIdOfValue]: yup
      .string()
      .test(i18next.t('validation.error.required'), i18next.t('validation.error.required'), function (value) {
        const { value: numberValue } = this.parent;
        if (!numberValue) return value != null;
        return true;
      })
      .test(i18next.t('validation.error.nodeIdInList'), i18next.t('validation.error.nodeIdNotInList'), (nodeId) => {
        if (!nodeId) return true;
        return validateNodeIdExists(nodeDetails, nodeId);
      })
      .test(
        i18next.t('validation.error.nodeIdIsNumberField'),
        i18next.t('validation.error.nodeIdIsNotNumberField'),
        (nodeId) => {
          if (!nodeId) return true;
          return validateNodeIdIsNumber(nodeDetails, nodeId);
        }
      ),
  });
}

export function numberComparisonConditionSchema(nodeDetails: NodeDetail[]): yup.ObjectSchema {
  const schema = yup.object().shape({
    [ValidationValues.operator]: yup.string().required(i18next.t('validation.error.required')),
    [ValidationValues.targetNodeId]: yup
      .mixed()
      .test('require-node-id', i18next.t('validation.error.required'), (nodeId) => !!nodeId)
      .test(
        i18next.t('validation.error.nodeIdInList'),
        i18next.t('validation.error.nodeIdNotInList'),
        (nodeId) => !nodeId || validateNodeIdExists(nodeDetails, nodeId)
      ),
  });

  const additionalSchema = numberOrNumberNodeIdFieldSchema(nodeDetails);

  if (objectSchemaFieldsAreDefined(additionalSchema)) {
    schema.concat(additionalSchema);
  }

  return schema;
}

export function reflexiveConditionSchema(nodeDetails: NodeDetail[]): yup.ObjectSchema {
  return yup.object().shape({
    [ValidationValues.value]: yup.string().required(i18next.t('validation.error.required')),
    [ValidationValues.targetNodeId]: yup
      .mixed()
      .test('require-node-id', i18next.t('validation.error.required'), (nodeId) => !!nodeId)
      .test(
        i18next.t('validation.error.nodeIdInList'),
        i18next.t('validation.error.nodeIdNotInList'),
        (nodeId) => !nodeId || validateNodeIdExists(nodeDetails, nodeId)
      ),
    type: yup.string().required(i18next.t('validation.error.required')).oneOf([ConditionBlueprintType.reflexive]),
  });
}

export function matchesConditionSchema(nodeDetails: NodeDetail[]): yup.ObjectSchema {
  return yup.object().shape({
    [ValidationValues.value]: yup.array(yup.string().required(i18next.t('validation.error.required'))).min(0),
    [ValidationValues.targetNodeId]: yup
      .mixed()
      .test('require-node-id', i18next.t('validation.error.required'), (nodeId) => !!nodeId)
      .test(
        i18next.t('validation.error.nodeIdInList'),
        i18next.t('validation.error.nodeIdNotInList'),
        (nodeId) => !nodeId || validateNodeIdExists(nodeDetails, nodeId)
      ),
    [ValidationValues.type]: yup.string().oneOf([ConditionBlueprintType.matches]),
    [ValidationValues.quantifier]: yup.string().required(i18next.t('validation.error.required')).oneOf(['any', 'none']),
  });
}

export function mathOperatorConditionSchema(
  nodeDetails: NodeDetail[],
  nodeIdInCollectionMap: NodeIdInCollections
): yup.ObjectSchema {
  const schema = yup.object().shape({
    [ValidationValues.operator]: yup
      .string()
      .required(i18next.t('validation.error.required'))
      .oneOf(Object.values(NumberComparisonConditionOperator)),
    [ValidationValues.mathOperator]: yup
      .string()
      .required(i18next.t('validation.error.required'))
      .oneOf(Object.values(MathConditionOperator)),
    [ValidationValues.type]: yup
      .string()
      .required(i18next.t('validation.error.required'))
      .oneOf([ConditionBlueprintType.mathOperator]),
    [ValidationValues.nodeIds]: yup
      .array()
      .test(
        i18next.t('validation.error.nodeIdInList'),
        i18next.t('validation.error.nodeIdNotInList'),
        (nodeIds) => !nodeIds?.length || nodeIds.every((nodeId) => validateNodeIdExists(nodeDetails, nodeId as string))
      )
      .test(
        'node-ids-same-collection',
        i18next.t('validation.error.mismatchedCollectionNodeIds'),
        (nodeIds) => !nodeIds?.length || areNodeIdsInTheSameCollection(nodeIds as string[], nodeIdInCollectionMap)
      )
      .min(2),
  });

  const additionalSchema = numberOrNumberNodeIdFieldSchema(nodeDetails);

  if (objectSchemaFieldsAreDefined(additionalSchema)) {
    schema.concat(additionalSchema);
  }

  return schema;
}

export function lastIncidentDateConditionSchema(nodeDetails: NodeDetail[]): yup.ObjectSchema {
  return yup.object().shape({
    [ValidationValues.operator]: yup
      .string()
      .required(i18next.t('validation.error.required'))
      .oneOf(['lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual']),
    [ValidationValues.value]: yup.number().required(i18next.t('validation.error.required')),
    [ValidationValues.unit]: yup
      .string()
      .required(i18next.t('validation.error.required'))
      .oneOf([DateUnit.day, DateUnit.month, DateUnit.year]),
    [ValidationValues.targetNodeId]: yup
      .string()
      .test('require-node-id', i18next.t('validation.error.required'), (nodeId) => !!nodeId)
      .test(
        i18next.t('validation.error.nodeIdInList'),
        i18next.t('validation.error.nodeIdNotInList'),
        (nodeId) => !nodeId || validateNodeIdExists(nodeDetails, nodeId)
      ),
  });
}

export function dateComparisonConditionSchema(
  nodeDetails: NodeDetail[],
  nodeIdInCollectionMap: NodeIdInCollections
): yup.ObjectSchema {
  return yup.object().shape({
    [ValidationValues.startDateNodeId]: yup
      .string()
      .test('require-start-date-id', i18next.t('validation.error.required'), (nodeId) => !!nodeId)
      .test(
        i18next.t('validation.error.nodeIdInList'),
        i18next.t('validation.error.nodeIdNotInList'),
        (nodeId) => !nodeId || validateNodeIdExists(nodeDetails, nodeId)
      ),
    [ValidationValues.endDateNodeId]: yup
      .string()
      .test('require-end-date-id', i18next.t('validation.error.required'), (nodeId) => !!nodeId)
      .test(
        i18next.t('validation.error.nodeIdInList'),
        i18next.t('validation.error.nodeIdNotInList'),
        (nodeId) => !nodeId || validateNodeIdExists(nodeDetails, nodeId)
      )
      .test('are-collections-mismatched', i18next.t('validation.error.mismatchedCollectionNodeIds'), function () {
        const { startDateNodeId, endDateNodeId } = this.parent;
        return areNodeIdsInTheSameCollection([startDateNodeId, endDateNodeId], nodeIdInCollectionMap);
      }),
    [ValidationValues.value]: yup.number().required(i18next.t('validation.error.required')),
    [ValidationValues.unit]: yup
      .string()
      .required(i18next.t('validation.error.required'))
      .oneOf([DateUnit.day, DateUnit.month, DateUnit.year]),
  });
}

export function equalityConditionSchema(nodeDetails: NodeDetail[]): yup.ObjectSchema {
  return yup.object().shape({
    [ValidationValues.isEqual]: yup.boolean().required(i18next.t('validation.error.required')),
    [ValidationValues.value]: yup.mixed(),
    [ValidationValues.targetNodeId]: yup
      .string()
      .test('require-node-id', i18next.t('validation.error.required'), (nodeId) => !!nodeId)
      .test(
        i18next.t('validation.error.nodeIdInList'),
        i18next.t('validation.error.nodeIdNotInList'),
        (nodeId) => !nodeId || validateNodeIdExists(nodeDetails, nodeId)
      ),
    [ValidationValues.nodeIdOfValue]: yup
      .string()
      .test(i18next.t('validation.error.nodeIdInList'), i18next.t('validation.error.nodeIdNotInList'), (nodeId) => {
        if (!nodeId) return true;
        return validateNodeIdExists(nodeDetails, nodeId);
      }),
  });
}

export function countEqualConditionSchema(nodeDetails: NodeDetail[]): yup.ObjectSchema {
  return yup.object().shape({
    [ValidationValues.targetNodeId]: yup
      .string()
      .test('require-node-id', i18next.t('validation.error.required'), (nodeId) => !!nodeId)
      .test(
        i18next.t('validation.error.nodeIdInList'),
        i18next.t('validation.error.nodeIdNotInList'),
        (nodeId) => !nodeId || validateNodeIdExists(nodeDetails, nodeId)
      ),
    [ValidationValues.operator]: yup
      .string()
      .required(i18next.t('validation.error.required'))
      .oneOf(Object.values(NumberComparisonConditionOperator)),
    [ValidationValues.controlValue]: yup.string().required(i18next.t('validation.error.required')),
    [ValidationValues.value]: yup.number().required(i18next.t('validation.error.required')),
  });
}

export function percentOfConditionSchema(nodeDetails: NodeDetail[]): yup.ObjectSchema {
  return yup.object().shape({
    [ValidationValues.targetNodeId]: yup
      .string()
      .test('require-node-id', i18next.t('validation.error.required'), (nodeId) => !!nodeId)
      .test(
        i18next.t('validation.error.nodeIdInList'),
        i18next.t('validation.error.nodeIdNotInList'),
        (nodeId) => !nodeId || validateNodeIdExists(nodeDetails, nodeId)
      ),
    [ValidationValues.percent]: yup
      .number()
      .min(0)
      .max(100, i18next.t('validation.error.percentMax'))
      .required(i18next.t('validation.error.required')),
    [ValidationValues.operator]: yup
      .string()
      .required(i18next.t('validation.error.required'))
      .oneOf(Object.values(NumberComparisonConditionOperator)),
    [ValidationValues.nodeIdOfValue]: yup
      .string()
      .test(i18next.t('validation.error.nodeIdInList'), i18next.t('validation.error.nodeIdNotInList'), (nodeId) => {
        if (!nodeId) return true;
        return validateNodeIdExists(nodeDetails, nodeId);
      }),
  });
}

function blueprintSchema(
  questionnaireNodeIds: QuestionnaireNodeIds,
  nodeIdInCollectionMap: NodeIdInCollections
): yup.ObjectSchema {
  return yup.object().shape({
    conditions: yup.array().of(
      yup.mixed().test('conditions of valid type', 'wrong condition type in array of conditions', (condition) => {
        if (condition.conditions) {
          return !!blueprintSchema(questionnaireNodeIds, nodeIdInCollectionMap).validateSync(condition);
        } else {
          const nodeDetails = buildNodeIdList(condition.type, questionnaireNodeIds, criteriaMetadata);

          try {
            switch (condition.type) {
              case ConditionBlueprintType.ageRange:
                return !!ageRangeConditionSchema(nodeDetails).validateSync(condition);
              case ConditionBlueprintType.characterCountInBetween:
                return !!characterCountInBetweenConditionSchema(nodeDetails).validateSync(condition);
              case ConditionBlueprintType.bmiRange:
                return !!bmiRangeConditionSchema(nodeDetails, nodeIdInCollectionMap).validateSync(condition);
              case ConditionBlueprintType.emptiness:
                return !!emptinessConditionSchema(nodeDetails).validateSync(condition);
              case ConditionBlueprintType.equality:
                return !!equalityConditionSchema(nodeDetails).validateSync(condition);
              case ConditionBlueprintType.numberComparison:
                return !!numberComparisonConditionSchema(nodeDetails).validateSync(condition);
              case ConditionBlueprintType.reflexive:
                return !!reflexiveConditionSchema(nodeDetails).validateSync(condition);
              case ConditionBlueprintType.matches:
                return !!matchesConditionSchema(nodeDetails).validateSync(condition);
              case ConditionBlueprintType.mathOperator:
                return !!mathOperatorConditionSchema(nodeDetails, nodeIdInCollectionMap).validateSync(condition);
              case ConditionBlueprintType.lastIncidentDate:
                return !!lastIncidentDateConditionSchema(nodeDetails).validateSync(condition);
              case ConditionBlueprintType.dateComparison:
                return !!dateComparisonConditionSchema(nodeDetails, nodeIdInCollectionMap).validateSync(condition);
              case ConditionBlueprintType.countEqual:
                return !!countEqualConditionSchema(nodeDetails).validateSync(condition);
              case ConditionBlueprintType.percentOf:
                return !!percentOfConditionSchema(nodeDetails).validateSync(condition);
              default:
                return false;
            }
          } catch (error) {
            return false;
          }
        }
      })
    ),
  });
}

export function getConditionValidationError(
  condition: BlueprintSingleConditionValue,
  nodeDetails: NodeDetail[],
  nodeIdInCollectionMap: NodeIdInCollections
): _.Dictionary<yup.ValidationError> | undefined | string {
  try {
    switch (condition.type) {
      case ConditionBlueprintType.ageRange:
        ageRangeConditionSchema(nodeDetails).validateSync(condition, { abortEarly: false });
        return;
      case ConditionBlueprintType.characterCountInBetween:
        characterCountInBetweenConditionSchema(nodeDetails).validateSync(condition, {
          abortEarly: false,
        });
        return;
      case ConditionBlueprintType.bmiRange:
        bmiRangeConditionSchema(nodeDetails, nodeIdInCollectionMap).validateSync(condition, { abortEarly: false });
        return;
      case ConditionBlueprintType.emptiness:
        emptinessConditionSchema(nodeDetails).validateSync(condition, { abortEarly: false });
        return;
      case ConditionBlueprintType.numberComparison:
        numberComparisonConditionSchema(nodeDetails).validateSync(condition, {
          abortEarly: false,
        });
        return;
      case ConditionBlueprintType.reflexive:
        reflexiveConditionSchema(nodeDetails).validateSync(condition, { abortEarly: false });
        return;
      case ConditionBlueprintType.equality:
        equalityConditionSchema(nodeDetails).validateSync(condition, { abortEarly: false });
        return;
      case ConditionBlueprintType.matches:
        matchesConditionSchema(nodeDetails).validateSync(condition, { abortEarly: false });
        return;
      case ConditionBlueprintType.mathOperator:
        mathOperatorConditionSchema(nodeDetails, nodeIdInCollectionMap).validateSync(condition, {
          abortEarly: false,
        });
        return;
      case ConditionBlueprintType.lastIncidentDate:
        lastIncidentDateConditionSchema(nodeDetails).validateSync(condition, {
          abortEarly: false,
        });
        return;
      case ConditionBlueprintType.dateComparison:
        dateComparisonConditionSchema(nodeDetails, nodeIdInCollectionMap).validateSync(condition, {
          abortEarly: false,
        });
        return;
      case ConditionBlueprintType.countEqual:
        countEqualConditionSchema(nodeDetails).validateSync(condition, { abortEarly: false });
        return;
      case ConditionBlueprintType.percentOf:
        percentOfConditionSchema(nodeDetails).validateSync(condition, { abortEarly: false });
        return;
    }
    return;
  } catch (error) {
    return formatErrors(error);
  }
}

// Helper function to output the first validation error for a field in the form
// A field can have multiple validation errors, they are displayed one at a time
function formatErrors(error: yup.ValidationError): _.Dictionary<yup.ValidationError> {
  const fieldErrors = _.groupBy(error?.inner, 'path');
  const formattedFieldErrors: _.Dictionary<yup.ValidationError> = {};

  // Retain only the first error
  // deepcode ignore PrototypePollution: fieldErrors are not exploitable through end-user inputs
  Object.keys(fieldErrors).map((field) => (formattedFieldErrors[field] = fieldErrors[field][0]));

  return formattedFieldErrors;
}

function isBlueprintSchemaValid(
  partialBlueprint: Partial<ConsiderationBlueprint> | BlueprintConditionsValue,
  questionnaireNodeIds: QuestionnaireNodeIds,
  nodeIdInCollectionMap: NodeIdInCollections
): boolean {
  try {
    return !!blueprintSchema(questionnaireNodeIds, nodeIdInCollectionMap).validateSync(partialBlueprint);
  } catch (error) {
    return false;
  }
}

export function validateBlueprint(
  blueprintConditionValue: BlueprintConditionValue | undefined,
  questionnaireNodeIds: QuestionnaireNodeIds,
  nodeIdInCollectionMap: NodeIdInCollections,
  options?: { allowNoConditions?: boolean }
): boolean {
  if (!blueprintConditionValue) {
    return !!options?.allowNoConditions;
  }
  if (isBlueprintConditionsValue(blueprintConditionValue)) {
    return isBlueprintSchemaValid(blueprintConditionValue, questionnaireNodeIds, nodeIdInCollectionMap);
  } else {
    const nodeDetails = buildNodeIdList(blueprintConditionValue.type, questionnaireNodeIds, criteriaMetadata);
    return !!getConditionValidationError(blueprintConditionValue, nodeDetails, nodeIdInCollectionMap);
  }
}

const ruleIdentifierRegex = /^[a-zA-Z0-9]+(?:[-.][a-zA-Z0-9]+)*$/g;

export function validateRuleIdentifier(identifier: string, requireSalesDecisionRuleIdentifier?: boolean): boolean {
  if (requireSalesDecisionRuleIdentifier && !identifier) return false;
  if (!identifier) return true;
  return ruleIdentifierRegex.test(identifier);
}

export const ruleIdentifierSchema = (): yup.StringSchema => {
  return yup
    .string()
    .trim()
    .matches(ruleIdentifierRegex, { message: i18next.t('validation.error.ruleIdentifier') });
};

export function getRuleIdentifierValidationError(
  identifier: string,
  requireSalesDecisionRuleIdentifier?: boolean
): yup.ValidationError | undefined {
  if (requireSalesDecisionRuleIdentifier && !identifier) {
    return new yup.ValidationError(i18next.t('validation.error.required'), true, 'path');
  }

  try {
    ruleIdentifierSchema().validateSync(identifier);
  } catch (error) {
    if (yup.ValidationError.isError(error)) {
      return error;
    }
    throw error;
  }
  return;
}

function areNodeIdsInTheSameCollection(
  nodeIds: string[] | undefined,
  nodeIdInCollectionMap: NodeIdInCollections
): boolean {
  if (!nodeIds?.length) {
    return true;
  }

  const firstNodeIdParentCollection = getParentCollectionNodeId(nodeIds[0], nodeIdInCollectionMap);
  return nodeIds.every(
    (nodeId) => !nodeId || firstNodeIdParentCollection === getParentCollectionNodeId(nodeId, nodeIdInCollectionMap)
  );
}

function getParentCollectionNodeId(nodeId: string, nodeIdInCollectionMap: NodeIdInCollections): string | null {
  const firstNodeIdCollectionNodeIds = nodeIdInCollectionMap[nodeId];
  return firstNodeIdCollectionNodeIds?.length
    ? firstNodeIdCollectionNodeIds[firstNodeIdCollectionNodeIds.length - 1]
    : null;
}
