import auth0 from 'auth0-js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteProps } from 'react-router-dom';

import { useCarrierContext, useDispatch, useSelector } from '../../Hooks';
import { authenticationSlice } from '../../Redux/Authentication/AuthenticationSlice';
import { userManagementSlice } from '../../Redux/UserManagement/UserManagementSlice';
import { isSsoUser, loginAgent } from '../../Services/Auth0';
import LoginView from './LoginView';

const { actions: authenticationActions } = authenticationSlice;
const { actions: userManagementActions } = userManagementSlice;

function LoginContainer(props: RouteProps): React.ReactElement | null {
  const dispatch = useDispatch();
  const [isStateReset, setIsStatesReset] = useState(false);
  const user = useSelector((store) => store.leadPlatform.authentication.user);
  const { ssoConnectionName, defaultToSsoConnection, features } = useCarrierContext();

  const { location } = props;

  const isRequestingSsoConnection = useMemo((): boolean => {
    const urlParams = new URLSearchParams(window.location.search);
    const ssoConnectionParam = urlParams.get('sso');

    if (ssoConnectionParam && ssoConnectionParam === '1') {
      const isSso = user && isSsoUser(user);
      return isSso || ssoConnectionParam === '1';
    }

    if (ssoConnectionParam && ssoConnectionParam === '0') {
      return false;
    }

    return !!defaultToSsoConnection;
  }, [user, defaultToSsoConnection]);

  const login = useCallback(() => {
    const options: auth0.AuthorizeOptions = {
      appState: {
        targetRoute: location?.state?.targetRoute,
      },
    };

    if (ssoConnectionName && isRequestingSsoConnection) {
      options.connection = ssoConnectionName;
    }

    return loginAgent(options, features.authImprovementFlow?.enabled);
  }, [isRequestingSsoConnection, location, ssoConnectionName, features.authImprovementFlow?.enabled]);

  useEffect(() => {
    const resetStates = async (): Promise<void> => {
      await dispatch(userManagementActions.reset());
      await dispatch(authenticationActions.reset());
      setIsStatesReset(true);
    };

    void resetStates();
  }, [dispatch]);

  if (!isStateReset) return null;
  return <LoginView login={login} />;
}

export default LoginContainer;
