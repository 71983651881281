import { useMemo } from 'react';

import { QuestionnaireEngine } from '@breathelife/questionnaire-engine';
import { DEFAULT_TIMEZONE_NAME, InsuranceModule, Timezone } from '@breathelife/types';

import { getQuestionnaireEngineConfig } from '../../Helpers/questionnaireEngineConfigs';
import { useCarrierContext } from '../../Hooks';
import { ApplicationHookProps } from './types';

export function useInsuranceApplicationProgress(props: ApplicationHookProps): number | undefined {
  const { features } = useCarrierContext();
  const { application, questionnaire, nodeIdToAnswerPathMap } = props;

  const questionnaireEngineEnabled = features.engineQuestionnaire.enabled;

  const questionnaireEngine = useMemo(() => {
    if (!questionnaireEngineEnabled || !application || !questionnaire || !nodeIdToAnswerPathMap) return;

    const timezoneResult = Timezone.from(application.timezone || DEFAULT_TIMEZONE_NAME);
    if (timezoneResult.isError()) {
      return;
    }

    return new QuestionnaireEngine(
      questionnaire,
      nodeIdToAnswerPathMap,
      {
        scopes: application.insuranceScopes ?? [],
        insuranceModules: [InsuranceModule.insuranceApplication],
      },
      getQuestionnaireEngineConfig(application.mode, features),
      timezoneResult.value
    );
  }, [application, nodeIdToAnswerPathMap, questionnaire]);

  return useMemo(() => {
    if (!application) return;

    // Legacy progress calculation is performed in the backend
    if (!questionnaireEngineEnabled) return application.progress;

    if (!questionnaireEngine) return;
    return questionnaireEngine.calculateProgress(application.answers, application.signed || application.submitted);
  }, [application, questionnaireEngine]);
}
