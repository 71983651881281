import { captureException } from '@sentry/browser';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

// Create a client
type Props = {
  children: React.ReactNode;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 1000 * 60 * 10, // 10 minutes,
      onError: (err) => {
        captureException(err);
      },
    },
  },
});

export function ReactQueryClientProvider(props: Props): React.ReactElement {
  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
      {/* By default, React Query Devtools are only included in bundles when process.env.NODE_ENV === 'development',
         so you don't need to worry about excluding them during a production build. */}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
