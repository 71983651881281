import Box from '@material-ui/core/Box';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ProductsEntity, QuotedInsuranceProduct, ProductsWidgetFeatureType } from '@breathelife/types';

import { Icon } from '../../../../Components/Icons';
import { useCarrierContext } from '../../../../Hooks';
import { QuotedProduct } from '../../../../Models/Product';
import { DownloadPdfButton } from '../DownloadPdfButton/DownloadPdfButton';
import { LegacyProductWidget } from '../LegacyProductWidget/LegacyProductWidget';
import { ProductWidget } from '../ProductWidget/ProductWidget';
import { ApplicationDetailsWidgetViewContainer, StyledActionGhostIconButton } from '../Styles';
import { TotalPremiumsWidget } from '../TotalPremiumsWidget/TotalPremiumsWidget';

type ApplicationDetailsWidgetProps = {
  selectedProduct?: QuotedProduct | QuotedInsuranceProduct;
  coverageAmount?: number | null;
  isLoadingProducts: boolean;
  isLoadingQuotes: boolean;
  showActionButtons: boolean;
  onOpenSubmissionDetailsModal: () => void;
  productsEntity: ProductsEntity<string> | null;
  isLoadingWidgetData: boolean;
};

export function ApplicationDetailsWidgetView(props: ApplicationDetailsWidgetProps): React.ReactElement {
  const {
    selectedProduct,
    coverageAmount,
    isLoadingProducts,
    isLoadingQuotes,
    showActionButtons,
    onOpenSubmissionDetailsModal,
    productsEntity,
    isLoadingWidgetData,
  } = props;
  const { t } = useTranslation();
  const { features } = useCarrierContext();

  const productWidgetType = useMemo(() => {
    if (!features.assistedApplication?.productsWidget?.enabled) return '';
    return features.assistedApplication?.productsWidget?.type;
  }, [features.assistedApplication?.productsWidget]);

  return (
    <ApplicationDetailsWidgetViewContainer display='flex' flexDirection='column'>
      {productWidgetType === ProductsWidgetFeatureType.default && productsEntity && (
        <Box mb={2}>
          <ProductWidget productsEntity={productsEntity} isLoading={isLoadingWidgetData} />
        </Box>
      )}
      {productWidgetType === ProductsWidgetFeatureType.legacy && (
        <Box mb={2}>
          <LegacyProductWidget
            selectedProduct={selectedProduct}
            coverageAmount={coverageAmount}
            isLoadingProducts={isLoadingProducts}
            isLoadingQuotes={isLoadingQuotes}
          />
        </Box>
      )}
      {productWidgetType === ProductsWidgetFeatureType.totalPremiums && (
        <Box mb={2}>
          <TotalPremiumsWidget />
        </Box>
      )}
      {showActionButtons && (
        <React.Fragment>
          <DownloadPdfButton iconName='download' mb={1} fullWidth>
            {t('assistedApplication.downloadApplicationPdf')}
          </DownloadPdfButton>
          <Box>
            <StyledActionGhostIconButton
              onClick={onOpenSubmissionDetailsModal}
              startIcon={<Icon name='loginKey' variant='primary' fill='none' />}
              removeIconBorder
              $fullWidth
              size='x-small'
            >
              {t('assistedApplication.seeSubmissionDetails')}
            </StyledActionGhostIconButton>
          </Box>
        </React.Fragment>
      )}
    </ApplicationDetailsWidgetViewContainer>
  );
}
