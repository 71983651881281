import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Alert from '@material-ui/lab/Alert';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ESignSigner2FAInfo } from '@breathelife/types';

import LoadingView from '../../../../../Components/LoadingView/LoadingView';
import { Tabs, TabsContainer, TabType } from '../../../../../Components/Tabs/Tabs';
import { useDispatch, useSelector } from '../../../../../Hooks';
import { RequiredFile } from '../../../../../Hooks/Application';
import { Application } from '../../../../../Models/Application';
import { useGetPointOfSaleDecisions } from '../../../../../ReactQuery/PointOfSaleDecisions/pointOfSaleDecisions.queries';
import { assistedApplicationSlice } from '../../../../../Redux/AssistedApplication/AssistedApplicationSlice';
import { DocumentToSign } from './DocumentToSign';
import { SignerInfo } from './SignerInfo';
import { SignerInfoForm } from './SignerInfoForm';

type SignerInfoViewProps = {
  isEditMode: boolean;
  isLoading: boolean;
  application: Application;
  onUpdateSigner: (signer: ESignSigner2FAInfo, idx: number) => void;
  requiredFiles: RequiredFile[];
  signers: ESignSigner2FAInfo[];
};

export function SignerInfoView(props: SignerInfoViewProps): React.ReactElement | null {
  const { application, isEditMode, isLoading, signers, onUpdateSigner, requiredFiles } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data: pointOfSaleDecisions } = useGetPointOfSaleDecisions(application.id, {
    enabled: !isLoading, // Refetch when eSignCeremony status changes and/or when submitting
    staleTime: 0, // Always refetch.
  });

  const { isDocumentSignInfoBoxOpen } = useSelector((state) => state.leadPlatform.assistedApplication);

  const [tabValue, setTabValue] = useState(0);

  const tabs: TabType[] = [
    { label: t('assistedApplication.signatureDrawer.signersInformation') },
    { label: t('assistedApplication.signatureDrawer.documentsToSign') },
  ];

  const handleTabChange = useCallback((_, newValue: number) => {
    setTabValue(newValue);
  }, []);

  const closeInfoBox = useCallback(() => {
    void dispatch(assistedApplicationSlice.actions.setIsDocumentSignInfoBoxOpen(false));
  }, [dispatch]);

  if (isLoading) {
    return <LoadingView />;
  }

  if (signers.length == 0) {
    return null;
  }

  return (
    <Box>
      <TabsContainer borderBottom={1} borderTop={1}>
        <Box px={4}>
          <Tabs value={tabValue} onChange={handleTabChange} tabs={tabs} />
        </Box>
      </TabsContainer>

      <Box py={2}>
        {tabValue === 0 && (
          <React.Fragment>
            {signers.map((signer, idx) => {
              let outcome = undefined;
              let overrideOutcome = undefined;
              if (pointOfSaleDecisions && signer.partyId) {
                outcome = pointOfSaleDecisions.decisions[signer.partyId]?.outcome;
                overrideOutcome = pointOfSaleDecisions.decisions[signer.partyId]?.overrideOutcome;
              }

              return (
                <Box key={`signer-${idx}`} mt={4}>
                  {!isEditMode ? (
                    <SignerInfo signer={signer} outcome={outcome} overrideOutcome={overrideOutcome} />
                  ) : (
                    <SignerInfoForm
                      disabled={isLoading}
                      signer={signer}
                      onUpdateSigner={(signer) => onUpdateSigner(signer, idx)}
                    />
                  )}
                </Box>
              );
            })}
          </React.Fragment>
        )}
        {tabValue === 1 && (
          <React.Fragment>
            <Collapse in={isDocumentSignInfoBoxOpen}>
              <Alert onClose={closeInfoBox} severity='info'>
                {t('assistedApplication.signatureDrawer.infoBoxMessage')}
              </Alert>
            </Collapse>

            <Box py={2}>
              {requiredFiles.map((file) => (
                <DocumentToSign
                  key={file.template.id}
                  application={application}
                  template={file.template}
                  storedFile={file.storedFile}
                />
              ))}
            </Box>
          </React.Fragment>
        )}
      </Box>
    </Box>
  );
}
