import React, { useCallback } from 'react';

import { QuotedInsuranceProduct } from '@breathelife/types';

import { LeadItem } from '../../../../../Components/LeadItem/LeadItem';
import { useCarrierContext } from '../../../../../Hooks';
import { Application } from '../../../../../Models/Application';
import { Lead } from '../../../../../Models/Lead';
import { LaunchApplicationType } from '../../../../../Models/PlatformFeatures';
import { QuotedProduct } from '../../../../../Models/Product';

export type ProductProps = {
  application?: Application;
  hasCustomCoverageAmount: boolean;
  hasCoverageAmountError: boolean;
  isLoadingQuotes: boolean;
  lead?: Lead;
  coverageAmount?: number;
  onLaunchAssistedApplication: (application: Application, selectedProductId: string) => void;
  onLaunchCoBrowsing: (applicationId: string) => void;
  onLaunchRedirectToCarrier: (
    applicationId: string,
    productId: string,
    coverageAmount: number,
    premium: number
  ) => void;
  onToggleIsDeclarationSent: (applicationId: string, isDeclarationSent: boolean) => void;
  product: QuotedProduct | QuotedInsuranceProduct;
};

export function Product(props: ProductProps): React.ReactElement {
  const { features } = useCarrierContext();
  const {
    application,
    coverageAmount,
    hasCustomCoverageAmount,
    hasCoverageAmountError,
    isLoadingQuotes,
    lead,
    onLaunchAssistedApplication,
    onLaunchCoBrowsing,
    onLaunchRedirectToCarrier,
    onToggleIsDeclarationSent,
    product,
  } = props;

  const isProductSelected = application?.product === product.productId;

  const onToggleIsDeclarationSentCheck = useCallback(() => {
    if (!application) return;
    onToggleIsDeclarationSent(application.id, !application.isDeclarationSent);
  }, [application, onToggleIsDeclarationSent]);

  const onLaunchApplication = useCallback(() => {
    if (!features.launchApplication.enabled) return;
    switch (features.launchApplication.type) {
      case LaunchApplicationType.assistedApplication:
        if (application && product.productId) onLaunchAssistedApplication(application, product.productId);
        break;
      case LaunchApplicationType.coBrowsing:
        if (application?.id) onLaunchCoBrowsing(application?.id);
        break;
      case LaunchApplicationType.redirectToCarrier:
        if (application?.id && coverageAmount && product.premium && product.productId)
          onLaunchRedirectToCarrier(application?.id, product.productId, coverageAmount, product.premium);
        break;
      default:
        break;
    }
  }, [
    application,
    product,
    coverageAmount,
    features,
    onLaunchAssistedApplication,
    onLaunchCoBrowsing,
    onLaunchRedirectToCarrier,
  ]);

  return (
    <LeadItem
      application={application}
      hasCoverageAmountError={hasCoverageAmountError}
      hasCustomCoverageAmount={hasCustomCoverageAmount}
      isItemSelected={isProductSelected}
      isLoadingQuotes={isLoadingQuotes}
      lead={lead}
      onLaunchApplication={onLaunchApplication}
      onToggleIsDeclarationSent={onToggleIsDeclarationSentCheck}
      product={product}
      title={'shortName' in product ? product.shortName : product.name}
    />
  );
}
