import { Location } from 'history';
import React, { PropsWithChildren, useEffect, useMemo, useState, useRef, useContext } from 'react';
import { useQueryClient } from 'react-query';
import { Prompt } from 'react-router';
import { useHistory } from 'react-router-dom';

import {
  AvailableEntityMappings,
  EntityMappingDefinition,
  GetEntityMappingDefinitionParams,
  isCompleteEntityMappingDefinitionRequestOptions,
} from '@breathelife/types';

import { CarrierContext } from '../../../Context/CarrierContext';
import { useUpdateEntityMappingMutation } from '../../../ReactQuery/Admin/EntityMappings/entityMappings.mutations';
import { useGetEntityMappingQuery } from '../../../ReactQuery/Admin/EntityMappings/entityMappings.queries';
import { QueryId } from '../../../ReactQuery/common/common.types';
import { QuestionnaireVersionsContext } from '../Questionnaire/QuestionnaireVersionsContextProvider';
import { EntityMappingUnsavedChangesWarningModal } from './EntityMappingUnsavedChangesWarningModal';

type SelectedEntityMappingOptions = Partial<GetEntityMappingDefinitionParams>;

type EntityMappingContextValue = {
  availableEntityMappings: AvailableEntityMappings;
  selectedEntityMappingOptions: SelectedEntityMappingOptions;
  mappingCodeHasChanged: boolean;
  setMappingCodeHasChanged: (newState: boolean) => void;
  saveEntityMapping: () => void;
  updateSelectedEntityMappingOptions: (options: SelectedEntityMappingOptions) => void;
  canEditCode: boolean;
  entityMappingIsSelected: boolean;
  savedEntityMapping?: EntityMappingDefinition;
  setRetrieveUpdatedCodeFunction: (getFunction: () => string | undefined) => void;
  availableQuestionnaireVersions: { id: string; label: string }[];
};

export const EntityMappingContext = React.createContext<EntityMappingContextValue>({
  availableEntityMappings: {},
  selectedEntityMappingOptions: {},
  mappingCodeHasChanged: false,
  saveEntityMapping: () => {},
  setMappingCodeHasChanged: () => {},
  updateSelectedEntityMappingOptions: () => {},
  canEditCode: false,
  entityMappingIsSelected: false,
  setRetrieveUpdatedCodeFunction: () => {},
  availableQuestionnaireVersions: [],
});

export function EntityMappingContextProvider(props: PropsWithChildren<Record<string, any>>): React.ReactElement {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { entityMappings } = useContext(CarrierContext);
  const [selectedEntityMappingOptions, setSelectedEntityMappingOptions] = useState<SelectedEntityMappingOptions>({});

  const { questionnaireVersions } = useContext(QuestionnaireVersionsContext);
  const [mappingCodeHasChanged, setMappingCodeHasChanged] = useState<boolean>(false);
  const { data: savedEntityMapping } = useGetEntityMappingQuery(selectedEntityMappingOptions);

  const retrieveUpdatedCodeFunction = useRef<() => string | undefined>();
  const discardChangesAction = useRef<() => void>();

  const [confirmDiscardChangesModalIsOpen, setConfirmDiscardChangesModalIsOpen] = useState<boolean>(false);
  const [shouldPerformDiscardChangesAction, setShouldPerformDiscardChangesAction] = useState<boolean>(false);

  useEffect(() => {
    if (shouldPerformDiscardChangesAction) {
      discardChangesAction.current?.();
      discardChangesAction.current = undefined;
      setShouldPerformDiscardChangesAction(false);
    }
  }, [shouldPerformDiscardChangesAction]);

  useEffect(() => {
    setMappingCodeHasChanged(false);
  }, [selectedEntityMappingOptions]);

  const canEditCode: boolean = useMemo(
    () =>
      Boolean(
        selectedEntityMappingOptions.questionnaireVersionId &&
          questionnaireVersions.find(
            (questionnaireVersion) => questionnaireVersion.id === selectedEntityMappingOptions.questionnaireVersionId
          )?.isDraft
      ),
    [selectedEntityMappingOptions, questionnaireVersions]
  );

  const entityMappingIsSelected: boolean = useMemo(
    () => isCompleteEntityMappingDefinitionRequestOptions(selectedEntityMappingOptions),
    [selectedEntityMappingOptions]
  );

  const updateEntityMappingMutation = useUpdateEntityMappingMutation({
    async onSettled() {
      setMappingCodeHasChanged(false);
    },
  });

  async function updateSelectedEntityMappingOptions(options: SelectedEntityMappingOptions): Promise<void> {
    await queryClient.invalidateQueries({ queryKey: [QueryId.entityMappings] });
    setSelectedEntityMappingOptions((prev) => ({ ...prev, ...options }));
  }

  function tryToUpdateSelectedEntityMappingOptions(options: SelectedEntityMappingOptions): void {
    if (mappingCodeHasChanged) {
      setConfirmDiscardChangesModalIsOpen(true);
      discardChangesAction.current = () => {
        void updateSelectedEntityMappingOptions(options);
        discardChangesAction.current = undefined;
      };
    } else {
      void updateSelectedEntityMappingOptions(options);
    }
  }

  function saveEntityMapping(): void {
    if (entityMappingIsSelected && mappingCodeHasChanged && canEditCode && retrieveUpdatedCodeFunction.current) {
      const newCode = retrieveUpdatedCodeFunction.current();
      if (newCode !== undefined) {
        updateEntityMappingMutation.mutate({
          ...selectedEntityMappingOptions,
          customCode: newCode,
        } as EntityMappingDefinition);
      }
    }
  }

  const availableQuestionnaireVersions = useMemo(() => {
    return questionnaireVersions.map((v) => ({ label: `${v.majorVersion}.${v.minorVersion}`, id: v.id }));
  }, [questionnaireVersions]);

  const availableEntityMappings = useMemo(() => {
    if (!entityMappings || !entityMappings.enabled) return {};

    return entityMappings.mappings;
  }, [entityMappings]);

  return (
    <EntityMappingContext.Provider
      value={{
        availableQuestionnaireVersions,
        availableEntityMappings,
        selectedEntityMappingOptions,
        mappingCodeHasChanged,
        setMappingCodeHasChanged: (hasChanged: boolean) => setMappingCodeHasChanged(hasChanged),
        saveEntityMapping,
        updateSelectedEntityMappingOptions: tryToUpdateSelectedEntityMappingOptions,
        savedEntityMapping,
        canEditCode,
        entityMappingIsSelected,
        setRetrieveUpdatedCodeFunction: (newRetrieveUpdatedCodeFunction: () => string | undefined) => {
          retrieveUpdatedCodeFunction.current = newRetrieveUpdatedCodeFunction;
        },
      }}
    >
      <Prompt
        when={mappingCodeHasChanged}
        message={(location: Location<any>) => {
          setConfirmDiscardChangesModalIsOpen(true);
          discardChangesAction.current = () => {
            history.push(location.pathname);
          };
          return false;
        }}
      />
      <EntityMappingUnsavedChangesWarningModal
        isOpen={confirmDiscardChangesModalIsOpen}
        closeModal={() => {
          setConfirmDiscardChangesModalIsOpen(false);
        }}
        onDiscardChanges={() => {
          setMappingCodeHasChanged(false);
          setConfirmDiscardChangesModalIsOpen(false);
          setShouldPerformDiscardChangesAction(true);
        }}
      />
      {props.children}
    </EntityMappingContext.Provider>
  );
}
