import { captureException } from '@sentry/browser';
import { useStripe } from '@stripe/react-stripe-js';
import React, { useCallback, useEffect, useState } from 'react';

import { PaymentServiceProvider } from '@breathelife/types';

import { PaymentMethodFormModal } from '../../../../../../Components/AssistedApplication/Modals/PaymentMethodForm/PaymentMethodFormModal';
import { useCarrierContext, useDispatch, useModalState, useNavigation, useSelector } from '../../../../../../Hooks';
import { useAssistedApplicationContext } from '../../../../../../Hooks/useAssistedApplicationContext';
import {
  createSetupIntent,
  createSetupIntentForEditing,
  fetchPaymentMethodInfo,
  retrieveSetupIntent,
} from '../../../../../../Redux/Payment/PaymentOperations';
import { isPaymentSetupSucceeded } from '../../../../../../Redux/Payment/PaymentSelectors';
import { paymentSlice } from '../../../../../../Redux/Payment/PaymentSlice';
import { PaymentSubsectionView } from './PaymentSubsectionView';

export function PaymentSubsectionContainer(): React.ReactElement {
  const stripe = useStripe();
  const { features } = useCarrierContext();
  const dispatch = useDispatch();
  const { isLoading, clientSecret, setupIntentStatus } = useSelector((store) => store.leadPlatform.payment);
  const isSetupSucceeded = useSelector(isPaymentSetupSucceeded);
  const { areAllFieldsDisabled } = useAssistedApplicationContext();
  const { applicationId } = useNavigation();

  const isStripeEnabled =
    features.payments.enabled && features.payments.serviceProvider === PaymentServiceProvider.STRIPE;

  if (!isStripeEnabled) {
    const err = new Error('Stripe is not enabled.');
    captureException(err);
    throw err;
  }

  const [isFormModalOpen, onOpenFormModal, onCloseFormModal] = useModalState();

  const [pollingIntervalId, setPollingIntervalId] = useState<NodeJS.Timeout>();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const onOpenForm = useCallback(
    async (isEditing: boolean) => {
      if (!applicationId) return;

      setIsEditing(isEditing);
      if (isEditing) {
        await dispatch(createSetupIntentForEditing(applicationId));
      }
      onOpenFormModal();
    },
    [applicationId, dispatch, onOpenFormModal]
  );

  const onCloseForm = useCallback(() => {
    dispatch(paymentSlice.actions.setEditClientSecret(null));
    setIsEditing((wasEditing) => {
      if (wasEditing) {
        void dispatch(fetchPaymentMethodInfo());
      }
      return false;
    });
    onCloseFormModal();
  }, [dispatch, onCloseFormModal]);

  const onSubmissionSuccess = useCallback(() => {
    if (!stripe) return;
    void dispatch(retrieveSetupIntent(stripe));
  }, [dispatch, stripe]);

  useEffect(() => {
    if (!clientSecret || !stripe) return;

    // We don't expect the setup intent status to change if it's succeeded - no need to refresh
    if (isSetupSucceeded) return;

    void dispatch(retrieveSetupIntent(stripe));
  }, [clientSecret, dispatch, isSetupSucceeded, stripe]);

  useEffect(() => {
    if (!applicationId || !setupIntentStatus) return;

    switch (setupIntentStatus) {
      case 'succeeded':
        // We fetch the payment method information at the `AssistedApplication` root container level so that we
        // don't have to re-fetch every time we render this component
        break;

      case 'processing':
        // For credit card payments, the processing time should be short enough. In case we hit a processing status, we
        // simply try to re-fetch the setup intent after a while and hope that the `processing` status will be gone by then
        break;

      case 'requires_payment_method':
        // Proceed with displaying the view
        break;

      case 'requires_confirmation':
      case 'requires_action':
      // We don't handle the above two statuses since they should already be bypassed when we confirm a credit card
      // using Stripe's `confirmCardSetup` method. In case we hit those statuses, we cancel the previous setup intent
      // and re-create a new one.
      // eslint-disable-next-line no-fallthrough
      case 'canceled':
      default:
        // We can only add a payment method if the setup intent status is `requires_payment_method`
        // We need to create a new setup intent if otherwise (`canceled` in most cases) to proceed
        // See: https://stripe.com/docs/payments/intents#intent-statuses
        void dispatch(createSetupIntent(applicationId));
    }
  }, [dispatch, applicationId, setupIntentStatus]);

  useEffect(() => {
    setPollingIntervalId((intervalId) => {
      if (intervalId) {
        clearInterval(intervalId);
      }

      if (!stripe || !setupIntentStatus || setupIntentStatus !== 'processing') {
        return undefined;
      }
      return setInterval(() => dispatch(retrieveSetupIntent(stripe)), 3000);
    });
  }, [dispatch, setupIntentStatus, stripe]);

  useEffect(() => {
    if (!pollingIntervalId) return;
    return () => clearInterval(pollingIntervalId);
  }, [pollingIntervalId]);

  const isLoadingView =
    isLoading ||
    !setupIntentStatus ||
    (setupIntentStatus !== 'succeeded' && setupIntentStatus !== 'requires_payment_method');

  return (
    <React.Fragment>
      <PaymentSubsectionView isLoading={isLoadingView} onOpenForm={onOpenForm} isEditable={!areAllFieldsDisabled} />
      {clientSecret && (
        <PaymentMethodFormModal
          isOpen={isFormModalOpen}
          isEditing={isEditing}
          onCloseModal={onCloseForm}
          onSubmissionSuccess={onSubmissionSuccess}
        />
      )}
    </React.Fragment>
  );
}
