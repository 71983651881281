import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import styled from 'styled-components';

import { QuestionnaireVersionRowData, SortDirection } from '@breathelife/types';
import { Box } from '@breathelife/ui-components';

import { Pagination } from '../../../../Components/Pagination/Pagination';
import { Table } from '../../../../Components/Table/Table';
import Typography from '../../../../Components/Typography';
import { emptyTableHeight } from '../../../../Models/Layout';
import { QuestionnaireVersionsContext, PER_PAGE_OPTIONS } from '../QuestionnaireVersionsContextProvider';
import { DownloadApplicationSchemaButton } from './DownloadApplicationSchemaButton';

dayjs.extend(LocalizedFormat);

const EmptyQuestionnaireContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: calc(100vh - ${emptyTableHeight}px);
  min-height: 300px;
`;

const TextOverFlowCell = styled.div`
  max-width: 300px;
  overflow-wrap: break-word;
`;

type Props = {
  onTableRowClick: (questionnaireVersionId?: string) => void;
};

export function QuestionnaireVersionsTable(props: Props): React.ReactElement {
  const { t } = useTranslation();

  const { onTableRowClick } = props;

  const { onPageChange, onLimitPerPageChange, onSortingChange, questionnaireVersions, total, currentPage, perPage } =
    useContext(QuestionnaireVersionsContext);

  const tableColumns = useMemo<
    {
      Header: string;
      accessor: keyof QuestionnaireVersionRowData;
      disableSortBy?: boolean;
      Cell?: (props: CellProps<QuestionnaireVersionRowData>) => React.ReactElement;
    }[]
  >(
    () => [
      {
        Header: t('admin.questionnaireManagement.table.version'),
        accessor: 'version',
        disableSortBy: true,
      },
      {
        Header: t('admin.questionnaireManagement.table.description'),
        accessor: 'description',
        Cell: function ({ cell: { value } }: { cell: { value: string } }): React.ReactElement {
          return <TextOverFlowCell>{value}</TextOverFlowCell>;
        },
      },
      {
        Header: t('admin.questionnaireManagement.table.createdAt'),
        accessor: 'createdAt',
        Cell: function ({ cell: { value } }: { cell: { value: Date } }): React.ReactElement {
          return <React.Fragment>{dayjs(value).format('D MMMM YYYY HH:MM A')}</React.Fragment>;
        },
      },
      {
        Header: t('admin.questionnaireManagement.table.updatedAt'),
        accessor: 'updatedAt',
        Cell: function ({ cell: { value } }: { cell: { value: Date } }): React.ReactElement {
          return <React.Fragment>{dayjs(value).format('D MMMM YYYY HH:MM A')}</React.Fragment>;
        },
      },
      {
        Header: t('admin.questionnaireManagement.table.status'),
        accessor: 'isDraft',
        Cell: function ({ cell: { value } }: { cell: { value: boolean } }): React.ReactElement {
          return (
            <React.Fragment>
              {value
                ? t('admin.questionnaireManagement.table.draft')
                : t('admin.questionnaireManagement.table.published')}
            </React.Fragment>
          );
        },
      },
      {
        Header: t('admin.questionnaireManagement.table.applicationsCount'),
        accessor: 'applicationsCount',
        disableSortBy: true,
      },
      {
        Header: t('admin.questionnaireManagement.table.applicationSchema'),
        accessor: 'id',
        disableSortBy: true,
        Cell: function ({
          cell: { row },
        }: {
          cell: { row: { original: QuestionnaireVersionRowData } };
        }): React.ReactElement {
          return <DownloadApplicationSchemaButton id={row.original.id} version={row.original.version} />;
        },
      },
    ],
    [t]
  );

  const handleTableRowClick = useCallback(
    (rowId?: string) => {
      if (!rowId) return;

      onTableRowClick(rowId);
    },
    [onTableRowClick]
  );

  const handleSortingChange = useCallback(
    (columnId?: keyof QuestionnaireVersionRowData, sortDirection?: SortDirection) => {
      if (!columnId || !sortDirection) return;

      onSortingChange({ field: columnId, direction: sortDirection });
    },
    [onSortingChange]
  );

  const data = useMemo<QuestionnaireVersionRowData[] | undefined>(() => {
    if (_.isEmpty(questionnaireVersions)) return;

    return questionnaireVersions.map(
      ({ id, description, majorVersion, minorVersion, createdAt, updatedAt, isDraft, applicationsCount }) => {
        return {
          id,
          description,
          createdAt,
          updatedAt,
          isDraft,
          applicationsCount,
          version: `${majorVersion}.${minorVersion}`,
        };
      }
    );
  }, [questionnaireVersions]);

  return (
    <React.Fragment>
      {!data ? (
        <EmptyQuestionnaireContainer>
          <Box mb={1.5}>
            <Typography variant='h2' grey={70}>
              {t('admin.questionnaireManagement.table.noQuestionnairesFoundTitle')}
            </Typography>
          </Box>
          <Box maxWidth='300px'>
            <Typography variant='body1' grey={60}>
              {t('admin.questionnaireManagement.table.noQuestionnairesFoundDetails')}
            </Typography>
          </Box>
        </EmptyQuestionnaireContainer>
      ) : (
        <React.Fragment>
          <Table<QuestionnaireVersionRowData>
            onRowClick={handleTableRowClick}
            columns={tableColumns}
            data={data}
            onOrderChange={handleSortingChange}
          />
          <Box pl={2.5} pr={2.5}>
            <Pagination
              total={total}
              page={currentPage}
              perPage={perPage}
              perPageOptions={PER_PAGE_OPTIONS}
              onPageChange={onPageChange}
              onPerPageChange={onLimitPerPageChange}
            />
          </Box>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
