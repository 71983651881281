import * as yup from 'yup';

import { FullColorRangesValues, PartialColorRangesValues, NavigationSidebarVariants } from '@breathelife/types';

import { colorSchema } from '../field/color';
import { objectSchemaFieldsAreDefined } from '../typeGuards';

const partialColorRangeSchema = yup
  .object()
  .shape(PartialColorRangesValues.reduce((acc, value) => ({ ...acc, [value]: colorSchema }), {}));

const fullColorRangeSchema = yup
  .object()
  .shape(FullColorRangesValues.reduce((acc, value) => ({ ...acc, [value]: colorSchema }), {}));

const themeFormFieldsSchema = yup.object().shape({
  colorRanges: yup
    .object()
    .shape({
      primary: partialColorRangeSchema,
      secondary: partialColorRangeSchema,
      tertiary: partialColorRangeSchema,
      grey: fullColorRangeSchema,
    })
    .required(),
  logoImageUrl: yup.string().url().required(),
  compactLogoImgUrl: yup.string().url().required(),
  navigationSidebarVariant: yup.string().oneOf(Object.values(NavigationSidebarVariants)).required(),
});

export function validateColorHex(value: unknown): yup.ValidationError | undefined {
  try {
    colorSchema.validateSync(value);
  } catch (error) {
    return error;
  }
  return;
}

export function getFieldValidationError(
  fieldName: 'colorRanges' | 'logoImageUrl' | 'compactLogoImgUrl' | 'navigationSidebarVariant',
  value: unknown
): yup.ValidationError | undefined {
  if (!objectSchemaFieldsAreDefined(themeFormFieldsSchema)) return;

  try {
    themeFormFieldsSchema.fields[fieldName].validateSync(value);
  } catch (error) {
    return error;
  }
  return;
}
