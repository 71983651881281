import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Localizable } from '@breathelife/types';

import RadioGroup, { Radio } from '../../../Components/RadioGroup/RadioGroup';
import { getLanguage } from '../../../Localization/utils';

type Props = {
  name: string;
  options: { optionName: string; optionLabel: Partial<Localizable> }[];
  value: string;
  onChange: (value: string) => void;
};

export function CriteriaValuesRadioGroup(props: Props): React.ReactElement {
  const { name, options, value, onChange } = props;

  const { t } = useTranslation();
  const language = getLanguage();

  return (
    <RadioGroup
      label={t('admin.conditions.labels.selectedValues.singular')}
      name={name}
      onChange={onChange}
      value={value}
    >
      <Grid container spacing={1}>
        {options?.length ? (
          options.map((choice, idx) => {
            return (
              <Grid item key={`${name}-radio-${idx}`}>
                <Radio value={choice.optionName} label={choice.optionLabel[language]} />
              </Grid>
            );
          })
        ) : (
          <Grid item>{t('admin.conditions.labels.selectedValues.noOptions')}</Grid>
        )}
      </Grid>
    </RadioGroup>
  );
}

export function BooleanValuesRadioGroup(props: {
  name: string;
  value?: boolean;
  onChange: (value: boolean) => void;
}): React.ReactElement {
  const { name, value, onChange } = props;

  const { t } = useTranslation();

  return (
    <RadioGroup
      label={t('admin.conditions.labels.selectedValues.singular')}
      name={name}
      onChange={(newValue: string) => onChange(newValue === 'true')}
      value={typeof value !== 'undefined' ? value.toString() : undefined}
    >
      <Grid container spacing={1}>
        <Grid item key={`${name}-radio-true`}>
          <Radio value='true' label={`${t('true')}`} />
        </Grid>
        <Grid item key={`${name}-radio-false`}>
          <Radio value='false' label={`${t('false')}`} />
        </Grid>
      </Grid>
    </RadioGroup>
  );
}
