import _ from 'lodash';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { FileTemplateRecipient } from '@breathelife/types';

import { QueryId } from '../../ReactQuery/common/common.types';
import { findFileTemplateRecipients } from '../../Services/FileTemplateRecipientService';

export function useFindFileTemplateRecipientsQuery(
  fetchOptions: {
    fileTemplateId: string;
  },
  options?: UseQueryOptions<FileTemplateRecipient[], Error>
): UseQueryResult<FileTemplateRecipient[], Error> {
  return useQuery<FileTemplateRecipient[], Error>(
    [QueryId.fileTemplateRecipients, fetchOptions.fileTemplateId],
    async () => await findFileTemplateRecipients(fetchOptions),
    {
      ...options,
      select: (fileTemplateRecipients) => _.orderBy(fileTemplateRecipients, ['createdAt'], ['desc']),
    }
  );
}
