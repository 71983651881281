import { Box } from '@material-ui/core';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentMethodCreateParams, SetupIntent, StripeCardNumberElement } from '@stripe/stripe-js';
import _ from 'lodash';
import React, { FormEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  defaultStripeElementsState,
  StripeCardDetailsForm,
  StripeElementsState,
  validateCreditCardDetailsForm,
} from '@breathelife/ui-components';

import { Alert } from '../../../../Components/Alert/Alert';
import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import { useDispatch, useLocale, useSelector } from '../../../../Hooks';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';
import { notificationSlice } from '../../../../Redux/Notification/NotificationSlice';
import { patchSetupIntent } from '../../../../Redux/Payment/PaymentOperations';

type PaymentMethodFormModalProps = {
  isOpen: boolean;
  isEditing: boolean;
  onCloseModal: () => void;
  onSubmissionSuccess: () => void;
};

export function PaymentMethodFormModal(props: PaymentMethodFormModalProps): React.ReactElement {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const language = useLocale();
  const { t } = useTranslation();
  const {
    applicationId,
    clientSecret: storedClientSecret,
    editClientSecret,
  } = useSelector((store) => store.leadPlatform.payment);

  const { isOpen, isEditing, onCloseModal: onCloseModalProp, onSubmissionSuccess: onSubmissionSuccessProp } = props;

  const [stripeElementsState, setStripeElementsState] = useState<StripeElementsState>(defaultStripeElementsState);
  const [validationErrors, setValidationErrors] = useState<PaymentMethodCreateParams.BillingDetails>({});
  const [billingDetails, setBillingDetails] = useState<PaymentMethodCreateParams.BillingDetails>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // Actual client secret used in the Stripe calls
  const clientSecret = isEditing ? editClientSecret : storedClientSecret;

  const onCloseModal = useCallback(() => {
    if (isSubmitting) return;
    onCloseModalProp();
  }, [isSubmitting, onCloseModalProp]);

  const validateForm = useCallback(() => {
    const validationErrors = validateCreditCardDetailsForm(stripeElementsState, billingDetails);
    setValidationErrors(validationErrors);
    return validationErrors;
  }, [billingDetails, stripeElementsState]);

  const stripeConfirmCardSetup = useCallback(
    async (clientSecret: string): Promise<SetupIntent | null> => {
      if (!stripe || !elements) {
        throw new Error('Stripe Elements not initialized.');
      }

      const cardNumberElement = elements.getElement(CardNumberElement) as StripeCardNumberElement | null;
      if (!cardNumberElement) {
        throw new Error('Card number element cannot be found.');
      }

      const validationErrors = validateForm();
      if (!_.isEmpty(validationErrors)) {
        return null;
      }

      const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardNumberElement,
          billing_details: billingDetails,
        },
      });

      if (result.error || result.setupIntent.status !== 'succeeded') {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.payment.failedToSubmitPaymentMethod'),
          })
        );
        return null;
      }

      return result.setupIntent;
    },
    [billingDetails, dispatch, elements, stripe, t, validateForm]
  );

  const onSubmissionSuccess = useCallback(
    async (setupIntentId: string) => {
      if (!applicationId) return;
      if (isEditing) {
        await dispatch(patchSetupIntent(applicationId, setupIntentId));
      }
      onSubmissionSuccessProp();
    },
    [applicationId, dispatch, onSubmissionSuccessProp, isEditing]
  );

  const onSubmit = useCallback(
    async (event?: FormEvent) => {
      event?.preventDefault();

      if (isSubmitting || !clientSecret) return;

      setIsSubmitting(true);

      const setupIntent = await stripeConfirmCardSetup(clientSecret);

      if (!setupIntent) {
        setIsSubmitting(false);
        return;
      }

      await onSubmissionSuccess(setupIntent.id);
      setIsSubmitting(false);
      onCloseModal();
    },
    [isSubmitting, clientSecret, stripeConfirmCardSetup, onSubmissionSuccess, onCloseModal]
  );

  return (
    <ModalLayout
      maxWidth='sm'
      title={t('modals.assistedApplication.addPaymentMethod.creditCard.modalTitle')}
      isOpen={isOpen}
      closeModal={onCloseModal}
      hideCancel={isSubmitting}
      submitButton={
        <SubmitButton isLoading={isSubmitting} onClick={onSubmit}>
          {t('cta.save')}
        </SubmitButton>
      }
    >
      <Box mb={3}>
        <form onSubmit={onSubmit}>
          <StripeCardDetailsForm
            disabled={isSubmitting}
            language={language}
            onStripeElementsChanged={setStripeElementsState}
            onBillingDetailsChanged={setBillingDetails}
            validationErrors={validationErrors}
          />
        </form>
      </Box>
      <Box>
        <Alert severity='info'>{t('modals.assistedApplication.addPaymentMethod.creditCard.informationText')}</Alert>
      </Box>
    </ModalLayout>
  );
}
