import { captureMessage, Severity } from '@sentry/browser';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { useCarrierContext } from '../../Hooks';
import { LeadStatus } from '../../Models/Lead';
import { LeadPlatformTheme } from '../../Styles/Types';
import { StyledBadge } from './Badge';

export type Props = {
  status: LeadStatus;
};

export function StatusBadge(props: Props): React.ReactElement | null {
  const theme = useTheme() as LeadPlatformTheme;
  const { t } = useTranslation();
  const { leadStatuses } = useCarrierContext();

  const LeadStatusTextColors: {
    [status in LeadStatus | 'default']: { textColor: string; backgroundColor: string };
  } = useMemo(
    () => ({
      new: { textColor: theme.colors.blue[50], backgroundColor: theme.colors.blue[20] },
      invited: { textColor: theme.colors.purple[50], backgroundColor: theme.colors.purple[20] },
      planFinderStarted: { textColor: theme.colors.orange[70], backgroundColor: theme.colors.orange[20] },
      planFinderCompleted: { textColor: theme.colors.grey[80], backgroundColor: theme.colors.grey[30] },
      engaged: { textColor: theme.colors.green[50], backgroundColor: theme.colors.green[20] },
      transactionFlowStarted: { textColor: theme.colors.orange[70], backgroundColor: theme.colors.orange[20] },
      transactionFlowCompleted: { textColor: theme.colors.grey[80], backgroundColor: theme.colors.grey[30] },
      qualified: { textColor: theme.colors.green[50], backgroundColor: theme.colors.green[20] },
      submissionStarted: { textColor: theme.colors.orange[70], backgroundColor: theme.colors.orange[20] },
      submissionCompleted: { textColor: theme.colors.green[50], backgroundColor: theme.colors.green[20] },
      default: { textColor: theme.colors.grey[80], backgroundColor: theme.colors.grey[30] },
    }),
    [theme]
  );

  let statusLabel: string = props.status;
  const statusLabelFromConfig = leadStatuses[props.status]?.label;
  if (statusLabelFromConfig) {
    statusLabel = t(`leadStatuses.${statusLabelFromConfig}`);
  }
  const statusColors = LeadStatusTextColors[props.status] || LeadStatusTextColors.default;

  if (!LeadStatusTextColors[props.status] || !leadStatuses[props.status]) {
    captureMessage(`Unknown lead status: ${props.status}`, Severity.Error);
  }

  return (
    <StyledBadge
      backgroundColor={statusColors.backgroundColor}
      textColor={statusColors.textColor}
      label={statusLabel}
    />
  );
}
