import ReactHtmlParser from 'html-react-parser';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Input as TextInput, Box } from '@breathelife/ui-components';

import { Button } from '../../../../Components/Button/Button';
import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import Typography from '../../../../Components/Typography';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';

const PublishButton = styled(Button)`
  padding: 8px 12px;
`;

type Props = {
  isDraft: boolean;
  isLatestVersion: boolean;
  description: string;
  disabled: boolean;
  onPublishClick: (description: string) => Promise<void>;
  onCreateDraftClick: (description: string) => Promise<void>;
};

export function CreateDraftOrPublish({
  isDraft,
  isLatestVersion,
  disabled,
  onPublishClick,
  onCreateDraftClick,
  description,
}: Props): React.ReactElement | null {
  if (!isDraft && !isLatestVersion) {
    // Drafts can only be created at the latest version, if it is not already a draft.
    return null;
  }

  return isDraft ? (
    <PublishQuestionnaire onPublishClick={onPublishClick} disabled={disabled} existingDescription={description} />
  ) : (
    <CreateDraftQuestionnaire onCreateDraftClick={onCreateDraftClick} disabled={disabled} />
  );
}

function CreateDraftQuestionnaire(props: {
  disabled: boolean;
  onCreateDraftClick: (description: string) => Promise<void>;
}): React.ReactElement {
  const { disabled, onCreateDraftClick } = props;

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [description, setDescription] = useState('');

  const onClose = useCallback(() => {
    setDescription('');
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <React.Fragment>
      <Button color='primary' variant='contained' onClick={() => setIsOpen(true)} disabled={disabled}>
        {t('admin.questionnaireManagement.newDraft')}
      </Button>
      {isOpen && (
        <ModalLayout
          maxWidth='md'
          isOpen={true}
          closeModal={onClose}
          title={t('admin.questionnaireManagement.createDraftQuestionnaire.modalTitle')}
          submitButton={
            <SubmitButton
              disabled={disabled}
              onClick={async () => {
                await onCreateDraftClick(description);
                onClose();
              }}
              data-testid='questionnaire-editor-publish-questionnaire'
            >
              {t('admin.questionnaireManagement.createDraft')}
            </SubmitButton>
          }
        >
          <Box pb={2}>
            <Typography variant='body1'>
              {ReactHtmlParser(t('admin.questionnaireManagement.createDraftQuestionnaire.modalDetails'))}
            </Typography>
          </Box>
          <TextInput
            inputVariant='outlined'
            label={t('admin.questionnaireManagement.description')}
            value={description}
            onChange={(event) => {
              const value = event.target.value;
              setDescription(value);
            }}
          />
        </ModalLayout>
      )}
    </React.Fragment>
  );
}

function PublishQuestionnaire(props: {
  existingDescription: string;
  disabled: boolean;
  onPublishClick: (description: string) => Promise<void>;
}): React.ReactElement {
  const { existingDescription, disabled, onPublishClick } = props;

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [description, setDescription] = useState(existingDescription);

  const onClose = useCallback(() => {
    setDescription('');
    setIsOpen(false);
  }, [setIsOpen, setDescription]);

  return (
    <React.Fragment>
      <PublishButton color='primary' variant='outlined' onClick={() => setIsOpen(true)} disabled={disabled}>
        {t('admin.questionnaireManagement.publish')}
      </PublishButton>
      {isOpen && (
        <ModalLayout
          maxWidth='md'
          isOpen={true}
          closeModal={onClose}
          title={t('admin.questionnaireManagement.publishQuestionnaire.modalTitle')}
          submitButton={
            <SubmitButton
              disabled={disabled || !description}
              onClick={async () => {
                await onPublishClick(description);
                onClose();
              }}
              data-testid='questionnaire-editor-publish-questionnaire'
            >
              {t('admin.questionnaireManagement.publish')}
            </SubmitButton>
          }
        >
          <Box pb={2}>
            <Typography variant='body1'>
              {ReactHtmlParser(t('admin.questionnaireManagement.publishQuestionnaire.modalDetails'))}
            </Typography>
          </Box>
          <TextInput
            required
            inputVariant='outlined'
            label={t('admin.questionnaireManagement.description')}
            value={description}
            onChange={(event) => {
              const value = event.target.value;
              setDescription(value);
            }}
          />
        </ModalLayout>
      )}
    </React.Fragment>
  );
}
