import * as monaco from 'monaco-editor';
import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';

const DEFAULT_LANGUAGE = 'typescript';
const MonacoWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

type MonacoCodeEditorProps = {
  initialCode?: string;
  setRetrieveCurrentEditorValueFunction: (getCurrentEditorValue: () => string | undefined) => void;
  onType: () => void;
  language?: 'typescript';
  readOnly?: boolean;
};

export function MonacoCodeEditor({
  initialCode,
  setRetrieveCurrentEditorValueFunction,
  onType,
  language,
  readOnly = false,
}: MonacoCodeEditorProps): React.ReactElement {
  const containerRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor>();
  const resizeObserver = useRef<ResizeObserver>();
  const subscriptionRef = useRef<any>(null);
  const activeLanguage = language || DEFAULT_LANGUAGE;

  const getCurrentEditorValue = useCallback((): string | undefined => {
    return editorRef.current?.getValue();
  }, []);

  const createEditor = useCallback(
    (value?: string) => {
      setRetrieveCurrentEditorValueFunction?.(getCurrentEditorValue);

      const currentEditorValue = editorRef.current?.getValue();
      if (editorRef.current) {
        editorRef.current?.dispose();
      }

      const newValue = typeof value === 'string' ? value : currentEditorValue || '';

      if (containerRef.current) {
        editorRef.current = monaco.editor.create(containerRef.current, {
          language: activeLanguage,
          value: newValue,
          automaticLayout: true,
          roundedSelection: false,
          scrollBeyondLastLine: false,
          fontFamily: 'monospace',
          readOnly,
          theme: 'vs',
        });

        subscriptionRef.current = editorRef.current?.onDidChangeModelContent(() => {
          onType();
        });
      }
    },
    [onType, setRetrieveCurrentEditorValueFunction, getCurrentEditorValue, activeLanguage, readOnly]
  );

  useEffect(() => {
    const element = containerRef?.current;
    createEditor(initialCode);

    setRetrieveCurrentEditorValueFunction?.(getCurrentEditorValue);
    if (element) {
      resizeObserver.current = new ResizeObserver(() => {
        createEditor();
      });
      resizeObserver.current?.observe(element);
    }
    return () => {
      if (element) {
        resizeObserver.current?.unobserve(element);
      }
      editorRef.current?.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCode, readOnly]);

  return <MonacoWrapper id='monaco-wrapper' ref={containerRef}></MonacoWrapper>;
}
