import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient, UseMutationResult, UseMutationOptions } from 'react-query';

import {
  ESignCeremony,
  ESignCeremonyPatchRequestData,
  ESignCeremonyStatus,
  ESignSigner2FAInfo,
} from '@breathelife/types';

import { useDispatch } from '../../Hooks';
import { QueryId } from '../../ReactQuery/common/common.types';
import { notificationSlice } from '../../Redux/Notification/NotificationSlice';
import { cancelESignCeremony, sendESignCeremony, updateESignCeremony } from '../../Services/ESignCeremonyService';

export function useCancelESignCeremonyMutation(
  options?: UseMutationOptions<ESignCeremony | null, AxiosError, string>
): UseMutationResult<ESignCeremony | null, unknown, string> {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useMutation<ESignCeremony | null, AxiosError<{ data: { status: ESignCeremonyStatus } }>, string>(
    cancelESignCeremony,
    {
      ...options,

      // Update cache
      onSuccess: async (data, applicationId, context) => {
        dispatch(
          notificationSlice.actions.setSuccess({
            message: t('notifications.eSignatureCancelled'),
          })
        );
        await queryClient.invalidateQueries([QueryId.application, applicationId]);
        await queryClient.invalidateQueries([QueryId.eSignCeremony, applicationId]);

        if (options?.onSuccess) {
          await options.onSuccess(data, applicationId, context);
        }
      },

      onError: async (error: AxiosError<{ data: { status: ESignCeremonyStatus } }>, variables, context) => {
        if (error?.response?.data?.data?.status === ESignCeremonyStatus.COMPLETED) {
          dispatch(
            notificationSlice.actions.setError({
              message: t('notifications.cannotCancelCompletedESignCeremony'),
            })
          );
        } else {
          dispatch(
            notificationSlice.actions.setError({
              message: t('notifications.cancelESignCeremonyFail'),
            })
          );
        }

        if (options?.onError) {
          await options.onError(error, variables, context);
        }
      },
    }
  );
}

type SendESignCeremonyParams = {
  applicationId: string;
  signers: ESignSigner2FAInfo[];
};

export type SendESignCeremonyMutation = UseMutationResult<ESignCeremony | null, unknown, SendESignCeremonyParams>;

export function useSendESignCeremonyMutation(
  options?: UseMutationOptions<ESignCeremony | null, unknown, SendESignCeremonyParams>
): SendESignCeremonyMutation {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation<ESignCeremony | null, unknown, SendESignCeremonyParams>(
    ({ applicationId, signers }) => sendESignCeremony(applicationId, signers),
    {
      ...options,
      // Update cache
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([QueryId.eSignCeremony, variables.applicationId]);
        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },

      onError: async (error, variables, context) => {
        if (error instanceof Error) {
          dispatch(
            notificationSlice.actions.setError({
              message: error.message,
            })
          );

          if (options?.onError) {
            await options.onError(error, variables, context);
          }
        }
      },
    }
  );
}

export function useUpdateESignCeremony(
  options?: UseMutationOptions<
    ESignCeremony | null,
    AxiosError,
    { applicationId: string; data: ESignCeremonyPatchRequestData }
  >
): UseMutationResult<ESignCeremony | null, unknown, { applicationId: string; data: ESignCeremonyPatchRequestData }> {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation<ESignCeremony | null, AxiosError, { applicationId: string; data: ESignCeremonyPatchRequestData }>(
    ({ applicationId, data }) => updateESignCeremony(applicationId, data),
    {
      ...options,

      // Update cache
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([QueryId.eSignCeremony, variables.applicationId]);

        // Update the application in case it was locked/unlocked
        if (data?.status === ESignCeremonyStatus.DRAFT) {
          await queryClient.invalidateQueries([QueryId.application, variables.applicationId]);
        }

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },

      onError: async (error, variables, context) => {
        if (error instanceof Error) {
          dispatch(
            notificationSlice.actions.setError({
              message: error.message,
            })
          );

          if (options?.onError) {
            await options.onError(error, variables, context);
          }
        }
      },
    }
  );
}
