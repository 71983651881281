import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InstantIdReport = {
  severity: string;
  riskIndicatorDescription: string;
};
export type IdentityVerificationState = {
  identityVerificationStatus: string;
  isLoading: boolean;
  riskIndicators: InstantIdReport[];
  isIdentityVerificationCheckCompleted: boolean;
};

type ActionsType = {
  reset: () => void;
  setIdentityVerificationStatus: (state: IdentityVerificationState, { payload }: PayloadAction<string>) => void;
  setIsLoading: (state: IdentityVerificationState, { payload }: PayloadAction<boolean>) => void;
  setRiskIndicatorReport: (state: IdentityVerificationState, { payload }: PayloadAction<InstantIdReport[]>) => void;
  setIsIdentityVerificationCheckCompleted: (
    state: IdentityVerificationState,
    { payload }: PayloadAction<boolean>
  ) => void;
};

const defaultState: IdentityVerificationState = {
  identityVerificationStatus: '',
  isLoading: false,
  riskIndicators: [],
  isIdentityVerificationCheckCompleted: false,
};

export const identityVerificationSlice = createSlice<IdentityVerificationState, ActionsType>({
  name: 'leadPlatform/identity-verification',
  initialState: defaultState,
  reducers: {
    reset: () => defaultState,
    setIdentityVerificationStatus: (state: IdentityVerificationState, { payload }: PayloadAction<string>) => {
      state.identityVerificationStatus = payload;
    },
    setIsLoading: (state: IdentityVerificationState, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setRiskIndicatorReport: (state: IdentityVerificationState, { payload }: PayloadAction<InstantIdReport[]>) => {
      state.riskIndicators = payload;
    },
    setIsIdentityVerificationCheckCompleted: (
      state: IdentityVerificationState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isIdentityVerificationCheckCompleted = payload;
    },
  },
});
