import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BlueprintCollectionOperator } from '@breathelife/types';
import { SimpleCheckbox } from '@breathelife/ui-components';

import { DEFAULT_COLLECTION_OPERATOR } from '../../../Helpers/conditions/blueprintHelpers';
import { ConditionPropertiesUpdateCallback } from '../types';
import { CollectionAggregateOperatorRadioGroup } from './CollectionAggregateOperatorRadioGroup';

type Props = {
  collectionContext: string[];
  collectionNodeIds: string[];
  collectionOperators: Record<string, BlueprintCollectionOperator> | undefined;
  onConditionPropertiesChange: ConditionPropertiesUpdateCallback;
  conditionIndex: string;
};

export function CollectionOperators({
  collectionNodeIds,
  collectionContext,
  collectionOperators,
  onConditionPropertiesChange,
  conditionIndex,
}: Props): React.ReactElement {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {collectionNodeIds.map((collectionNodeId) => {
        const isCollectionInContext = collectionContext.includes(collectionNodeId);

        const isUsingCollectionContext =
          !collectionOperators?.[collectionNodeId] ||
          collectionOperators?.[collectionNodeId] === BlueprintCollectionOperator.thisItem;

        return (
          <React.Fragment key={collectionNodeId}>
            <Grid item xs={12}>
              <Typography variant='h3' component='h1'>
                {t('admin.conditions.labels.selectedCollection', { collectionNodeId })}
              </Typography>
            </Grid>
            {isCollectionInContext && (
              <Grid item xs={12}>
                <SimpleCheckbox
                  id={`useLocalContext-${collectionNodeId}-${conditionIndex}`}
                  label={t('admin.conditions.labels.collectionCompareToCurrentItem')}
                  checked={isUsingCollectionContext}
                  onChange={(event) => {
                    const newCollectionOperator = event.target.checked
                      ? BlueprintCollectionOperator.thisItem
                      : DEFAULT_COLLECTION_OPERATOR;

                    const updatedCollectionOperators: Record<string, BlueprintCollectionOperator> = {
                      ...collectionOperators,
                      [collectionNodeId]: newCollectionOperator,
                    };
                    onConditionPropertiesChange({ collectionOperators: updatedCollectionOperators });
                  }}
                />
              </Grid>
            )}
            {(!isUsingCollectionContext || !isCollectionInContext) && (
              <Grid item xs={12} key={collectionNodeId}>
                <CollectionAggregateOperatorRadioGroup
                  value={collectionOperators?.[collectionNodeId] ?? DEFAULT_COLLECTION_OPERATOR}
                  onChange={(value) => {
                    const updatedCollectionOperators: Record<string, BlueprintCollectionOperator> = {
                      ...collectionOperators,
                      [collectionNodeId]: value,
                    };
                    onConditionPropertiesChange({ collectionOperators: updatedCollectionOperators });
                  }}
                />
              </Grid>
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}
