import { ButtonProps } from '@material-ui/core/Button';
import React from 'react';
import styled, { css } from 'styled-components';

import { Button } from '../../Components/Button/Button';
import Typography from '../../Components/Typography';

type StyledGhostButtonProps = {
  buttonSize?: 'small' | 'default';
};

const StyledGhostButton = styled(Button).withConfig({
  shouldForwardProp: (prop) => !['buttonSize'].includes(prop),
})<StyledGhostButtonProps>`
  &&&& {
    padding: 8px 12px;
    border-radius: 3px;
    color: ${(props) => props.theme.colors.primary.default};
    background-color: ${(props) => props.theme.colors.grey[0]};
    :hover {
      background-color: ${(props) => props.theme.colors.grey[20]};
      border-color: ${(props) => props.theme.colors.grey[20]};
    }
    :active {
      background-color: ${(props) => props.theme.colors.grey[30]};
      border-color: ${(props) => props.theme.colors.grey[30]};
    }
    :focus:not(:active) {
      outline: 0;
      border-color: ${(props) => props.theme.colors.tertiary.default};
    }

    ${(props) =>
      props.buttonSize === 'small' &&
      css`
        padding: 4px 13px;
      `}
  }
`;

type GhostButtonProps = { textColor?: string; startIcon?: any; endIcon?: any } & Omit<ButtonProps, 'variant' | 'size'> &
  StyledGhostButtonProps;

export const GhostButton = (props: GhostButtonProps): React.ReactElement => {
  const { textColor, innerRef, ...restProps } = props;

  return (
    <StyledGhostButton {...restProps} innerRef={innerRef}>
      <Typography color={textColor} variant='button'>
        {props.children}
      </Typography>
    </StyledGhostButton>
  );
};
