import axios, { AxiosError } from 'axios';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient, UseMutationResult, UseMutationOptions } from 'react-query';

import { hash } from '@breathelife/hash';
import { TypewriterTracking } from '@breathelife/react-tracking';
import { InsuranceScopes, LeadAccessTokenData } from '@breathelife/types';

import { CarrierContext } from '../../Context/CarrierContext';
import { useDispatch } from '../../Hooks';
import { BaseLead, Lead } from '../../Models/Lead';
import { LeadNote } from '../../Models/LeadsNote';
import { DefaultLeadsListFilterIds } from '../../Pages/Home/Modals/UserListModal/UserListModal';
import { QueryId } from '../../ReactQuery/common/common.types';
import { notificationSlice } from '../../Redux/Notification/NotificationSlice';
import { generatePrivateLinkToken } from '../../Services/AccessTokenService';
import { assignLeads } from '../../Services/AssignService';
import {
  createLead,
  deleteLead,
  updateLead,
  updateLeadNote,
  archiveLead,
  unarchiveLead,
  sendInvitationEmailToLead,
  SendInvitationEmailToLeadResponse,
} from '../../Services/LeadsService';

export function useCreateLeadMutation(
  options?: UseMutationOptions<Lead, unknown, Partial<BaseLead>>
): UseMutationResult<Lead, unknown, Partial<BaseLead>> {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<Lead, unknown, Partial<BaseLead>>(createLead, {
    ...options,
    onSuccess: async (data, variables, context) => {
      TypewriterTracking.createdLead({ leadId: data.id });

      await queryClient.invalidateQueries([QueryId.leads]);

      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
    onError: async (error, variables, context) => {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToCreateLead'),
        })
      );

      if (options?.onError) {
        await options.onError(error, variables, context);
      }
    },
  });
}

export function useUpdateLeadMutation(
  options?: UseMutationOptions<Lead, unknown, Partial<BaseLead>>
): UseMutationResult<Lead, unknown, Partial<BaseLead>> {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<Lead, unknown, Partial<BaseLead>>(updateLead, {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([QueryId.leads]);
      await queryClient.invalidateQueries([QueryId.lead, data.id]);

      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
    onError: async (error, variables, context) => {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToUpdateLead'),
        })
      );

      if (options?.onError) {
        await options.onError(error, variables, context);
      }
    },
  });
}

type UpdateLeadNoteParams = {
  leadId: number;
  note: string;
};

export function useUpdateLeadNoteMutation(
  options?: UseMutationOptions<LeadNote, unknown, UpdateLeadNoteParams>
): UseMutationResult<LeadNote, unknown, UpdateLeadNoteParams> {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<LeadNote, unknown, UpdateLeadNoteParams>(({ leadId, note }) => updateLeadNote(leadId, note), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([QueryId.lead, variables.leadId]);

      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
    onError: async (error, variables, context) => {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToUpdateNotes'),
        })
      );

      if (options?.onError) {
        await options.onError(error, variables, context);
      }
    },
  });
}

type UpdateLeadArchiveParams = {
  leadId: number;
  archive: boolean;
};

export function useUpdateLeadArchiveMutation(
  options?: UseMutationOptions<Lead, unknown, UpdateLeadArchiveParams>
): UseMutationResult<Lead, unknown, UpdateLeadArchiveParams> {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<Lead, unknown, UpdateLeadArchiveParams>(
    ({ leadId, archive }) => (archive ? archiveLead(leadId) : unarchiveLead(leadId)),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([QueryId.leads]);
        await queryClient.invalidateQueries([QueryId.lead, data.id]);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
      onError: async (error, variables, context) => {
        if (error instanceof Error) {
          dispatch(notificationSlice.actions.setError({ message: error.message }));

          if (options?.onError) {
            await options.onError(error, variables, context);
          }
        }
      },
    }
  );
}

export function useDeleteLeadMutation(
  options?: UseMutationOptions<Lead, unknown, number>
): UseMutationResult<Lead, unknown, number> {
  const { t } = useTranslation();
  const { features } = useContext(CarrierContext);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<Lead, unknown, number>(deleteLead, {
    ...options,
    onSuccess: async (data, variables, context) => {
      TypewriterTracking.deletedLead({ leadId: data.id });

      await queryClient.invalidateQueries([QueryId.leads]);
      await queryClient.removeQueries([QueryId.lead, data.id]);

      dispatch(
        notificationSlice.actions.setSuccess({
          message: t(
            features.hiddenLeads?.enabled ? 'notifications.deleteApplicationSuccess' : 'notifications.deleteLeadSuccess'
          ),
          autoHideDuration: 3000,
          dataTestId: 'notification-lead-delete-success',
        })
      );

      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
    onError: async (error, variables, context) => {
      if (error instanceof Error) {
        dispatch(notificationSlice.actions.setError({ message: error.message }));

        if (options?.onError) {
          await options.onError(error, variables, context);
        }
      }
    },
  });
}

type AssignLeadsParams = {
  leadIds: number[];
  userId: string;
};

export function useAssignLeadsMutation(
  options?: UseMutationOptions<BaseLead[], unknown, AssignLeadsParams>
): UseMutationResult<BaseLead[], unknown, AssignLeadsParams> {
  const { t } = useTranslation();
  const { features } = useContext(CarrierContext);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<BaseLead[], unknown, AssignLeadsParams>(
    ({ leadIds, userId }) => {
      const assignedToUserId = userId === DefaultLeadsListFilterIds.Unassigned ? '' : userId;
      return assignLeads(leadIds, assignedToUserId);
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        const { leadIds, userId } = variables;
        const assignedToUserId = userId === DefaultLeadsListFilterIds.Unassigned ? '' : userId;
        //@TODO The applicationId will eventually be deprecated (as leads could have many applications).
        //We need to check with the data team what the hashedId should represent when this happens.
        const applicationId = data[0].applicationId;

        //@TODO We will need to support multiple leads being assigned/unassigned in the future.
        if (assignedToUserId === '') {
          TypewriterTracking.unassignedLead({
            leadId: leadIds[0],
            hashedId: hash(applicationId),
          });
        } else {
          TypewriterTracking.assignedLead({
            assignedToUserId,
            leadId: leadIds[0],
            hashedId: hash(applicationId),
          });
        }

        await queryClient.invalidateQueries(QueryId.leads);
        leadIds.forEach((leadId) => void queryClient.invalidateQueries([QueryId.lead, leadId]));

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
      onError: async (error, variables, context) => {
        dispatch(
          notificationSlice.actions.setError({
            message: t(
              features.hiddenLeads?.enabled
                ? 'notifications.failedToAssignApplications'
                : 'notifications.failedToAssignLeads'
            ),
          })
        );

        if (options?.onError) {
          await options.onError(error, variables, context);
        }
      },
    }
  );
}

type GeneratePrivateLinkTokenParams = {
  leadId: number;
  insuranceScopes: InsuranceScopes[];
};

export function useGeneratePrivateLinkTokenMutation(
  options?: UseMutationOptions<LeadAccessTokenData, unknown, GeneratePrivateLinkTokenParams>
): UseMutationResult<LeadAccessTokenData, unknown, GeneratePrivateLinkTokenParams> {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return useMutation<LeadAccessTokenData, unknown, GeneratePrivateLinkTokenParams>(
    ({ leadId, insuranceScopes }) => generatePrivateLinkToken(leadId, insuranceScopes),
    {
      ...options,
      onError: async (error, variables, context) => {
        dispatch(notificationSlice.actions.setError({ message: t('notifications.failedToCopyPrivateLink') }));

        if (options?.onError) {
          await options.onError(error, variables, context);
        }
      },
    }
  );
}

type SendInvitationEmailToLeadParams = {
  leadId: number;
  insuranceScopes: InsuranceScopes[];
};

export function useSendInvitationEmailToLeadMutation(
  options?: UseMutationOptions<SendInvitationEmailToLeadResponse, unknown, SendInvitationEmailToLeadParams>
): UseMutationResult<SendInvitationEmailToLeadResponse, unknown, SendInvitationEmailToLeadParams> {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<SendInvitationEmailToLeadResponse, unknown, SendInvitationEmailToLeadParams>(
    ({ leadId, insuranceScopes }) => sendInvitationEmailToLead(leadId, insuranceScopes),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([QueryId.leads]);
        await queryClient.invalidateQueries([QueryId.lead, variables.leadId]);

        TypewriterTracking.sentLink({
          leadId: variables.leadId,
        });

        dispatch(
          notificationSlice.actions.setSuccess({
            message: t('notifications.invitationSent'),
            autoHideDuration: 3000,
          })
        );

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
      onError: async (error, variables, context) => {
        if (axios.isAxiosError(error) && error.response?.status === 403) {
          const axiosError = error as AxiosError<any>;
          const message = axiosError.response?.data.message;
          dispatch(
            notificationSlice.actions.setError({
              message: t('notifications.forbiddenInvitationEmail') + (message ? ' ' + message : ''),
            })
          );
        } else {
          dispatch(
            notificationSlice.actions.setError({
              message: t('notifications.failedToSendInvitationEmail'),
            })
          );
        }

        if (options?.onError) {
          await options.onError(error, variables, context);
        }
      },
    }
  );
}
