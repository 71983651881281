import Box from '@material-ui/core/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Input as TextInput } from '@breathelife/ui-components';

import Typography from '../../../../Components/Typography';
import { useCarrierContext } from '../../../../Hooks';

type Props = {
  value: string;
  onChange: (value: string) => void;
  maxLength: number;
  isSso?: boolean;
};

export function AgencyName(props: Props): React.ReactElement {
  const { t } = useTranslation();
  const { ssoProfileFields } = useCarrierContext();
  const { value, onChange, maxLength, isSso } = props;

  return (
    <Box position='relative'>
      <TextInput
        value={value}
        disabled={isSso && ssoProfileFields.includes('subGroupName')}
        onChange={(event) => onChange(event.target.value)}
        label={t('modals.editUser.agencyName')}
        fullWidth
        id='userEditModal-agencyName'
        inputVariant='outlined'
        inputProps={{
          maxLength,
        }}
      />

      <Typography variant='body3' grey={70}>
        {value.length}/{maxLength} {t('characters')}
      </Typography>
    </Box>
  );
}
