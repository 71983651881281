import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Localizable, PublicLink, CarrierNames } from '@breathelife/types';

import { ExportButton } from '../../../Components/ExportData/ExportButton';
import { ExportDataProvider } from '../../../Components/ExportData/ExportDataProvider';
import { ImportButton } from '../../../Components/ExportData/ImportButton';
import { ImportDataProvider } from '../../../Components/ExportData/ImportDataProvider';
import { ImportModal } from '../../../Components/ExportData/ImportModal';
import { Helmet } from '../../../Components/Helmet';
import Typography from '../../../Components/Typography';
import { useCarrierContext, useDispatch, useSelector } from '../../../Hooks';
import * as SettingsOperations from '../../../Redux/Admin/SettingsManagement/SettingsOperations';
import { CarrierNameEditView } from './CarrierNameViewContainer';
import { ConsumerFooterView } from './ConsumerFooterViewContainer';
import { InsuranceScopesEditView, InsuranceScopesSettings } from './InsuranceScopesViewContainer';
import { LanguagesEditView, LanguageSettings } from './LanguagesViewContainer';
import { PublicLinkView } from './PublicLinkViewContainer';

export function SettingsViewContainer(): React.ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    settings,
    isFetching,
    isUpdatingInsuranceScopes,
    isUpdatingLanguages,
    isUpdatingPublicLink,
    isUpdatingCarrierName,
    isUpdatingConsumerFooter,
  } = useSelector((store) => store.leadPlatform.settings);
  const { features } = useCarrierContext();

  useEffect(() => {
    if (features.loadLanguageSettingsFromDb?.enabled) {
      void dispatch(SettingsOperations.fetchAdminSettings());
    }
  }, [dispatch, features.loadLanguageSettingsFromDb?.enabled]);

  return (
    <React.Fragment>
      <Helmet text={t('pageTitles.settings')} />
      <Box m={2} display={'flex'} justifyContent={'space-between'}>
        <div>
          <Typography variant='h1'>{t('admin.settingsManagement.title')}</Typography>
          <Typography variant='body1'>{t('admin.settingsManagement.subtitle')}</Typography>
        </div>
        <Box display={'flex'}>
          <Box mr={1}>
            <ExportDataProvider settings>
              <ExportButton />
            </ExportDataProvider>
          </Box>
          <ImportDataProvider settings>
            <ImportButton />
            <ImportModal title={t('admin.importData.settings')} />
          </ImportDataProvider>
        </Box>
      </Box>
      <Divider />
      <Box m={2}>
        {(isFetching || !settings) && <Skeleton variant='rect' height={50} />}
        {!isFetching && settings && (
          <React.Fragment>
            <LanguagesEditView
              enabledLanguages={settings.data.enabledLanguages}
              defaultLanguage={settings.data.defaultLanguage}
              isUpdating={isUpdatingLanguages}
              onSaveClick={({ enabledLanguages, defaultLanguage }: LanguageSettings) =>
                dispatch(
                  SettingsOperations.updateSettings(settings, {
                    ...settings,
                    data: { ...settings.data, enabledLanguages, defaultLanguage },
                  })
                )
              }
            />
            <InsuranceScopesEditView
              insuranceScopes={settings.data.insuranceScopes}
              defaultInsuranceScope={settings.data.defaultInsuranceScope}
              isUpdating={isUpdatingInsuranceScopes}
              onSaveClick={({ insuranceScopes, defaultInsuranceScope }: InsuranceScopesSettings) =>
                dispatch(
                  SettingsOperations.updateSettings(settings, {
                    ...settings,
                    data: { ...settings.data, insuranceScopes, defaultInsuranceScope },
                  })
                )
              }
            />
            <CarrierNameEditView
              carrierNames={settings.data.carrierNames}
              enabledLanguages={settings.data.enabledLanguages}
              isUpdating={isUpdatingCarrierName}
              onSaveClick={(carrierNameData: CarrierNames) =>
                dispatch(
                  SettingsOperations.updateSettings(settings, {
                    ...settings,
                    data: { ...settings.data, carrierNames: carrierNameData },
                  })
                )
              }
            />
            <PublicLinkView
              enabledLanguages={settings.data.enabledLanguages}
              insuranceScopes={settings.data.insuranceScopes}
              publicLink={settings.data.publicLink}
              isUpdating={isUpdatingPublicLink}
              onSaveClick={(publicLinkData: PublicLink) =>
                dispatch(
                  SettingsOperations.updateSettings(settings, {
                    ...settings,
                    data: { ...settings.data, publicLink: publicLinkData },
                  })
                )
              }
            />
            <ConsumerFooterView
              consumerFooter={settings.data.consumerFooter}
              enabledLanguages={settings.data.enabledLanguages}
              isUpdating={isUpdatingConsumerFooter}
              onSaveClick={(consumerFooterData: Localizable) =>
                dispatch(
                  SettingsOperations.updateSettings(settings, {
                    ...settings,
                    data: { ...settings.data, consumerFooter: consumerFooterData },
                  })
                )
              }
            />
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  );
}
