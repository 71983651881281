import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getRepeatedQuestionIndex,
  isRenderingRepeatedQuestion,
  RenderingQuestion,
} from '@breathelife/questionnaire-engine';
import { CollectionInstanceIdentifiers } from '@breathelife/types';

import { FieldGroupCardModal } from '../../../../../Components/AssistedApplication/Modals/FieldGroupCard/FieldGroupCardModal';
import { Icon } from '../../../../../Components/Icons';
import { useModalState } from '../../../../../Hooks';
import { useAssistedApplicationContext } from '../../../../../Hooks/useAssistedApplicationContext';
import { FieldGroupCardPreview } from './FieldGroupCardPreview';
import { AddButton, DeleteButton } from './Styles';

type FieldGroupCardContainerProps = {
  question: RenderingQuestion;
};

export function FieldGroupCardContainer(props: FieldGroupCardContainerProps): React.ReactElement {
  const { question } = props;
  const { t } = useTranslation();
  const [isModalOpen, onOpenModal, onCloseModal] = useModalState();
  const { onAnswerChange, onBulkAnswerClear } = useAssistedApplicationContext();

  const newRepeatedInstanceIdentifierContext: CollectionInstanceIdentifiers | undefined = useMemo(() => {
    if (isRenderingRepeatedQuestion(question) && question.showAddQuestionButton) {
      const questionNodeId = Object.keys(question.metadata.repeatedInstanceIdentifierContext).find(
        (nodeId) => nodeId === question.nodeId
      );
      if (questionNodeId) {
        return {
          ...question.metadata.repeatedInstanceIdentifierContext,
          [questionNodeId]: getRepeatedQuestionIndex(question) + 1,
        };
      }
    }
    return question.metadata?.repeatedInstanceIdentifierContext;
  }, [question]);

  return (
    <React.Fragment>
      <FieldGroupCardPreview question={question} onEdit={onOpenModal} />

      {isRenderingRepeatedQuestion(question) && question.showAddQuestionButton && (
        <AddButton
          variant='text'
          color='primary'
          startIcon={<Icon name='add' variant='primary' />}
          onClick={() => onAnswerChange(question.nodeId, {}, question.effects, newRepeatedInstanceIdentifierContext)}
        >
          {question.addQuestionButtonText}
        </AddButton>
      )}

      <FieldGroupCardModal
        isOpen={isModalOpen}
        onCloseModal={onCloseModal}
        secondaryAction={
          isRenderingRepeatedQuestion(question) && question.showRemoveQuestionButton ? (
            <DeleteButton
              variant='text'
              startIcon={<Icon name='delete' variant='red' />}
              onClick={() => {
                onCloseModal();
                onAnswerChange(
                  question.nodeId,
                  undefined,
                  question.effects,
                  question.metadata.repeatedInstanceIdentifierContext
                );
              }}
            >
              {question.removeQuestionButtonText}
            </DeleteButton>
          ) : (
            <DeleteButton
              variant='text'
              startIcon={<Icon name='delete' variant='red' />}
              onClick={() => {
                onCloseModal();
                void onBulkAnswerClear(
                  question.fields,
                  undefined,
                  question.metadata?.repeatedInstanceIdentifierContext
                );
              }}
            >
              {t('cta.clear')}
            </DeleteButton>
          )
        }
        question={question}
      />
    </React.Fragment>
  );
}
