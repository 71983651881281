import axios, { AxiosError } from 'axios';

import { AssistedApplicationErrorIds } from '@breathelife/errors';

const errorIdToTranslationKeyMap: Record<AssistedApplicationErrorIds, string> = {
  [AssistedApplicationErrorIds.ADOMissingRequiredInputs]: 'notifications.failedADOMinMaxDueToMissingNodeIds',

  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestFirstNamePattern]:
    'notifications.identityVerificationToast.validationErrors.firstName.pattern',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestFirstNameMax]:
    'notifications.identityVerificationToast.validationErrors.firstName.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestFirstNameEmpty]:
    'notifications.identityVerificationToast.validationErrors.firstName.empty',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestMiddleNamePattern]:
    'notifications.identityVerificationToast.validationErrors.middleName.pattern',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestMiddleNameMax]:
    'notifications.identityVerificationToast.validationErrors.middleName.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestLastNamePattern]:
    'notifications.identityVerificationToast.validationErrors.lastName.pattern',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestLastNameMax]:
    'notifications.identityVerificationToast.validationErrors.lastName.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestLastNameEmpty]:
    'notifications.identityVerificationToast.validationErrors.lastName.empty',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestDOBYearMin]:
    'notifications.identityVerificationToast.validationErrors.DOB.year.min',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestDOBYearEmpty]:
    'notifications.identityVerificationToast.validationErrors.DOB.year.empty',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestDOBMonthMin]:
    'notifications.identityVerificationToast.validationErrors.DOB.month.min',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestDOBMonthMax]:
    'notifications.identityVerificationToast.validationErrors.DOB.month.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestDOBMonthEmpty]:
    'notifications.identityVerificationToast.validationErrors.DOB.month.empty',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestDOBDayMin]:
    'notifications.identityVerificationToast.validationErrors.DOB.day.min',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestDOBDayMax]:
    'notifications.identityVerificationToast.validationErrors.DOB.day.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestDOBDayEmpty]:
    'notifications.identityVerificationToast.validationErrors.DOB.day.empty',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestTelephoneClassification]:
    'notifications.identityVerificationToast.validationErrors.telephone.classification',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestTelephoneAreaCodePattern]:
    'notifications.identityVerificationToast.validationErrors.telephone.areaCode.pattern',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestTelephoneAreaCodeMax]:
    'notifications.identityVerificationToast.validationErrors.telephone.areaCode.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestTelephoneAreaCodeEmpty]:
    'notifications.identityVerificationToast.validationErrors.telephone.areaCode.empty',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestTelephoneExchangePattern]:
    'notifications.identityVerificationToast.validationErrors.telephone.exchange.pattern',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestTelephoneExchangeMax]:
    'notifications.identityVerificationToast.validationErrors.telephone.exchange.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestTelephoneExchangeEmpty]:
    'notifications.identityVerificationToast.validationErrors.telephone.exchange.empty',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestTelephoneNumberPattern]:
    'notifications.identityVerificationToast.validationErrors.telephone.number.pattern',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestTelephoneNumberMax]:
    'notifications.identityVerificationToast.validationErrors.telephone.number.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestTelephoneNumberEmpty]:
    'notifications.identityVerificationToast.validationErrors.telephone.number.empty',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestTelephoneExtensionPattern]:
    'notifications.identityVerificationToast.validationErrors.telephone.extension.pattern',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestTelephoneExtensionMax]:
    'notifications.identityVerificationToast.validationErrors.telephone.extension.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressAddressIdPattern]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.idPattern',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressStreetNumberPattern]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.street.number.pattern',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressStreetNumberMax]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.street.number.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressStreetNamePattern]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.street.name.pattern',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressStreetNameMax]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.street.name.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressStreetAddress1Max]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.street.address1.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressStreetAddress1Empty]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.street.address1.empty',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressStreetAddress2Max]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.street.address2.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressCityMax]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.street.city.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressCityEmpty]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.street.city.empty',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressStateMax]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.street.state.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressStateEmpty]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.street.state.empty',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressZip5Max]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.zip5.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressZip5Empty]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.zip5.empty',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressCountyPattern]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.county.pattern',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressCountyMax]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.county.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressCountryMax]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.country.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressUnitNumberPattern]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.unitNumber.pattern',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressUnitNumberMax]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.unitNumber.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressPostalCodeMax]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.postalCode.max',
  [AssistedApplicationErrorIds.IdentityVerificationServerTimout]:
    'notifications.identityVerificationToast.serverErrors.timeoutError',
  [AssistedApplicationErrorIds.IdentityVerificationAddressError]:
    'notifications.identityVerificationToast.serverErrors.addressError',
  [AssistedApplicationErrorIds.IdentityVerificationProcessingError]:
    'notifications.identityVerificationToast.serverErrors.processError',
  [AssistedApplicationErrorIds.IdentityVerificationApplicationNotFound]:
    'notifications.identityVerificationToast.serverErrors.applicationNotFound',
};

export function getTranslationKeyFromErrorId(error: unknown): string | undefined {
  if (!axios.isAxiosError(error)) {
    return undefined;
  }
  const axiosError = error as AxiosError<any>;
  const errorId: string | undefined = axiosError.response?.data?.errors?.errorId;

  if (!errorId) {
    return undefined;
  }

  return errorIdToTranslationKeyMap[errorId];
}

export function getTranslationKeysFromErrorIds(error: unknown): string[] | undefined {
  if (!axios.isAxiosError(error)) {
    return undefined;
  }
  const axiosError = error as AxiosError<any>;
  const errors: { errorId: string }[] | undefined = axiosError.response?.data?.errors;

  if (!errors) {
    return undefined;
  }

  const translationKeys = errors.map((error) => errorIdToTranslationKeyMap[error.errorId]);

  return translationKeys;
}
