import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SetupIntent } from '@stripe/stripe-js';

import { PaymentMethodInfo } from '@breathelife/types';

export type PaymentState = {
  isLoading: boolean;
  isLoadingEditClientSecret: boolean;
  applicationId: string | null;
  clientSecret: string | null;
  setupIntentStatus: SetupIntent.Status | null;
  paymentMethodInfo: PaymentMethodInfo | null;
  editClientSecret: string | null; // Temporary client secret used for editing
};

type ActionType = {
  reset: () => void;
  setIsLoading: (state: PaymentState, { payload }: PayloadAction<boolean>) => void;
  setIsLoadingEditClientSecret: (state: PaymentState, { payload }: PayloadAction<boolean>) => void;
  setApplicationId: (state: PaymentState, { payload }: PayloadAction<string>) => void;
  setClientSecret: (state: PaymentState, { payload }: PayloadAction<string | null>) => void;
  setSetupIntentStatus: (state: PaymentState, { payload }: PayloadAction<SetupIntent.Status | null>) => void;
  setPaymentMethodInfo: (state: PaymentState, { payload }: PayloadAction<PaymentMethodInfo | null>) => void;
  setEditClientSecret: (state: PaymentState, { payload }: PayloadAction<string | null>) => void;
};

const defaultState: PaymentState = {
  isLoading: false,
  isLoadingEditClientSecret: false,
  applicationId: null,
  clientSecret: null,
  setupIntentStatus: null,
  paymentMethodInfo: null,
  editClientSecret: null,
};

export const paymentSlice = createSlice<PaymentState, ActionType>({
  name: 'leadPlatform/payment',
  initialState: defaultState,
  reducers: {
    reset: () => defaultState,
    setIsLoading(state: PaymentState, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload;
    },
    setIsLoadingEditClientSecret(state: PaymentState, { payload }: PayloadAction<boolean>) {
      state.isLoadingEditClientSecret = payload;
    },
    setApplicationId(state: PaymentState, { payload }: PayloadAction<string>) {
      state.applicationId = payload;
    },
    setClientSecret(state: PaymentState, { payload }: PayloadAction<string | null>) {
      state.clientSecret = payload;
    },
    setSetupIntentStatus(state: PaymentState, { payload }: PayloadAction<SetupIntent.Status | null>) {
      state.setupIntentStatus = payload;
    },
    setPaymentMethodInfo(state: PaymentState, { payload }: PayloadAction<PaymentMethodInfo | null>) {
      state.paymentMethodInfo = payload;
    },
    setEditClientSecret(state: PaymentState, { payload }: PayloadAction<string | null>) {
      state.editClientSecret = payload;
    },
  },
});
