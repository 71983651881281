import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Box } from '@breathelife/ui-components';

import { Helmet } from '../../../Components/Helmet';
import LoadingView from '../../../Components/LoadingView/LoadingView';
import { QuestionnaireEditorContainer } from '../../../Pages/Admin/Questionnaire/QuestionnaireEditor/QuestionnaireEditorContainer';
import { QuestionnaireEditorHeader } from './QuestionnaireEditor/QuestionnaireEditorHeaderContainer';
import { QuestionnaireEditorContextProvider } from './QuestionnaireEditorContextProvider';
import {
  QuestionnaireVersionDataContext,
  QuestionnaireVersionDataContextProvider,
} from './QuestionnaireVersionDataContextProvider';

export function QuestionnaireManagementViewContainer(): React.ReactElement {
  const { t } = useTranslation();

  const { questionnaireVersionId } = useParams<{ questionnaireVersionId: string }>();

  return (
    <QuestionnaireEditorContextProvider>
      <QuestionnaireVersionDataContextProvider questionnaireVersionId={questionnaireVersionId}>
        <Box height='100%'>
          <Helmet text={t('pageTitles.questionnaireManagement')} />
          <Box pt={1} height='100%' display='flex' flexDirection='column'>
            <QuestionnaireVersionDataContext.Consumer>
              {(data) =>
                data.isLoading ? (
                  <LoadingView />
                ) : (
                  <React.Fragment>
                    <QuestionnaireEditorHeader />
                    <QuestionnaireEditorContainer />
                  </React.Fragment>
                )
              }
            </QuestionnaireVersionDataContext.Consumer>
          </Box>
        </Box>
      </QuestionnaireVersionDataContextProvider>
    </QuestionnaireEditorContextProvider>
  );
}
