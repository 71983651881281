import { useCallback, useContext } from 'react';

import {
  isFieldBlueprint,
  isFieldPartIdentifier,
  isQuestionBlueprint,
  isQuestionPartIdentifier,
  isSectionBlueprint,
  isSectionPartIdentifier,
  isSubsectionBlueprint,
  isSubsectionPartIdentifier,
  PartIdentifier,
  QuestionnaireElementBlueprint,
} from '@breathelife/types';

import {
  useAddQuestionnaireElementBlueprint,
  useUpdateQuestionnaireElementBlueprint,
  useRemoveQuestionnaireElementBlueprint,
} from '../../../../../ReactQuery/Admin/Questionnaire/questionnaireVersion.mutations';
import { QuestionnaireEditorContext } from '../../QuestionnaireEditorContextProvider';
import { QuestionnaireVersionDataContext } from '../../QuestionnaireVersionDataContextProvider';
import { SelectedPartIdentifier } from '../types';
import { useQuestionnaireClipboard } from './useQuestionnaireClipboard';

export function useQuestionnaireElementActions({
  isQuestionnaireEditingEnabled,
  areParentsHidden,
}: {
  isQuestionnaireEditingEnabled: boolean;
  areParentsHidden?: boolean;
}): {
  copyItem: (blueprint: QuestionnaireElementBlueprint) => void;
  pasteItem: (identifier: PartIdentifier) => void;
  removeItem: (identifier: PartIdentifier) => void;
  onItemClick: (partIdentifier: SelectedPartIdentifier) => void;
  addItem: (blueprint: QuestionnaireElementBlueprint, identifier?: PartIdentifier) => void;
  toggleItemHiddenState: (identifier: PartIdentifier, newHiddenState: boolean) => void;
} {
  const { onSelectedPartIdentifierChange } = useContext(QuestionnaireEditorContext);
  const { questionnaireVersionId } = useContext(QuestionnaireVersionDataContext);

  const {
    actions: { copySection, copySubsection, copyField, copyFieldGroup, pasteSubsection, pasteField, pasteFieldGroup },
    selectors: { copiedSubsection, copiedField, copiedFieldGroup },
  } = useQuestionnaireClipboard();

  const addQuestionnaireElementBlueprint = useAddQuestionnaireElementBlueprint(questionnaireVersionId);
  const updateQuestionnaireElementBlueprint = useUpdateQuestionnaireElementBlueprint(questionnaireVersionId);
  const removeQuestionnaireElementBlueprint = useRemoveQuestionnaireElementBlueprint(questionnaireVersionId);

  const onItemClick = useCallback(
    (partIdentifier: SelectedPartIdentifier) => {
      if (partIdentifier) {
        void onSelectedPartIdentifierChange(partIdentifier);
      }
    },
    [onSelectedPartIdentifierChange]
  );

  const copyItem = useCallback(
    (blueprint?: QuestionnaireElementBlueprint) => {
      if (!isQuestionnaireEditingEnabled || areParentsHidden) {
        return;
      }
      if (!blueprint) {
        return;
      }
      if (isSectionBlueprint(blueprint)) {
        copySection(blueprint);
      }
      if (isSubsectionBlueprint(blueprint)) {
        copySubsection(blueprint);
      }
      if (isQuestionBlueprint(blueprint)) {
        copyFieldGroup(blueprint);
      }
      if (isFieldBlueprint(blueprint)) {
        copyField(blueprint);
      }
    },
    [copySection, copySubsection, copyFieldGroup, copyField, isQuestionnaireEditingEnabled, areParentsHidden]
  );

  const pasteItem = useCallback(
    (partIdentifier: PartIdentifier) => {
      if (!isQuestionnaireEditingEnabled || areParentsHidden) {
        return;
      }
      if (copiedSubsection && isSectionPartIdentifier(partIdentifier)) {
        void pasteSubsection(partIdentifier, copiedSubsection);
      } else if (copiedFieldGroup && isSubsectionPartIdentifier(partIdentifier)) {
        void pasteFieldGroup(partIdentifier, copiedFieldGroup);
      } else if (copiedField && isQuestionPartIdentifier(partIdentifier)) {
        void pasteField(partIdentifier, copiedField);
      }
    },
    [
      copiedField,
      copiedFieldGroup,
      copiedSubsection,
      isQuestionnaireEditingEnabled,
      pasteField,
      pasteFieldGroup,
      pasteSubsection,
      areParentsHidden,
    ]
  );

  const removeItem = useCallback(
    (partIdentifier: PartIdentifier) => {
      if (!isQuestionnaireEditingEnabled || areParentsHidden) {
        return;
      }
      if (isSectionPartIdentifier(partIdentifier)) {
        void removeQuestionnaireElementBlueprint(partIdentifier);
        return;
      }
      if (isSubsectionPartIdentifier(partIdentifier)) {
        void removeQuestionnaireElementBlueprint(partIdentifier);
        return;
      }
      if (isQuestionPartIdentifier(partIdentifier)) {
        void removeQuestionnaireElementBlueprint(partIdentifier);
        return;
      }
      if (isFieldPartIdentifier(partIdentifier)) {
        void removeQuestionnaireElementBlueprint(partIdentifier);
      }
    },
    [removeQuestionnaireElementBlueprint, isQuestionnaireEditingEnabled, areParentsHidden]
  );

  const addItem = useCallback(
    (itemBlueprint: QuestionnaireElementBlueprint, parentPartIdentifier?: PartIdentifier) => {
      if (!isQuestionnaireEditingEnabled || areParentsHidden) {
        return;
      }
      if (!parentPartIdentifier && isSectionBlueprint(itemBlueprint)) {
        void addQuestionnaireElementBlueprint(itemBlueprint, null);
        return;
      }
      if (!parentPartIdentifier) {
        return;
      }
      if (isSectionPartIdentifier(parentPartIdentifier) && isSubsectionBlueprint(itemBlueprint)) {
        void addQuestionnaireElementBlueprint(itemBlueprint, parentPartIdentifier);

        return;
      }
      if (isSubsectionPartIdentifier(parentPartIdentifier) && isQuestionBlueprint(itemBlueprint)) {
        void addQuestionnaireElementBlueprint(itemBlueprint, parentPartIdentifier);
        return;
      }
      if (isQuestionPartIdentifier(parentPartIdentifier) && isFieldBlueprint(itemBlueprint)) {
        void addQuestionnaireElementBlueprint(itemBlueprint, parentPartIdentifier);
      }
    },
    [addQuestionnaireElementBlueprint, isQuestionnaireEditingEnabled, areParentsHidden]
  );

  const toggleItemHiddenState = useCallback(
    (partIdentifier: PartIdentifier, newHiddenState: boolean) => {
      if (!isQuestionnaireEditingEnabled) {
        return;
      }
      if (isSectionPartIdentifier(partIdentifier)) {
        void updateQuestionnaireElementBlueprint({
          partIdentifier,
          update: { property: 'hidden', value: newHiddenState },
        });
        return;
      }
      if (isSubsectionPartIdentifier(partIdentifier)) {
        void updateQuestionnaireElementBlueprint({
          partIdentifier,
          update: { property: 'hidden', value: newHiddenState },
        });
        return;
      }
      if (isQuestionPartIdentifier(partIdentifier)) {
        void updateQuestionnaireElementBlueprint({
          partIdentifier,
          update: { property: 'hidden', value: newHiddenState },
        });
        return;
      }
      if (isFieldPartIdentifier(partIdentifier)) {
        void updateQuestionnaireElementBlueprint({
          partIdentifier,
          update: { property: 'hidden', value: newHiddenState },
        });
        return;
      }
    },
    [isQuestionnaireEditingEnabled, updateQuestionnaireElementBlueprint]
  );

  return { onItemClick, copyItem, pasteItem, removeItem, toggleItemHiddenState, addItem };
}
