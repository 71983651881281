import Box from '@material-ui/core/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import Typography from '../../../../Components/Typography';
import { useSelector } from '../../../../Hooks';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';

type SendESignatureRequestModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export function SendESignatureRequestModal(props: SendESignatureRequestModalProps): React.ReactElement {
  const isSubmitting = useSelector((state) => state.leadPlatform.submission.isSubmittingApplication);
  const { t } = useTranslation();
  const { isOpen, onClose, onConfirm } = props;

  return (
    <ModalLayout
      maxWidth='sm'
      isOpen={isOpen}
      closeModal={onClose}
      title={t('assistedApplication.sendToSignature')}
      submitButton={
        <SubmitButton isLoading={isSubmitting} onClick={onConfirm}>
          {t('assistedApplication.sendToSignature')}
        </SubmitButton>
      }
    >
      <Box mb={3}>
        <Typography variant='body1'>{t('modals.assistedApplication.sendESignatureRequest.body1')}</Typography>
      </Box>
      <Box>
        <Typography variant='body1'>{t('modals.assistedApplication.sendESignatureRequest.body2')}</Typography>
      </Box>
    </ModalLayout>
  );
}
