import Box from '@material-ui/core/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { QuotedInsuranceProduct } from '@breathelife/types';
import { Loader } from '@breathelife/ui-components';

import Typography from '../../../../Components/Typography';
import getCurrency from '../../../../Helpers/currency';
import { useCarrierContext } from '../../../../Hooks';
import { toCurrency } from '../../../../Localization/utils';
import { QuotedProduct } from '../../../../Models/Product';
import { CoverageAmountContainer, PremiumContainer, ProductInfoContainer, ProductSummaryContainer } from './Styles';

type Props = {
  selectedProduct?: QuotedProduct | QuotedInsuranceProduct;
  coverageAmount?: number | null;
  isLoadingProducts: boolean;
  isLoadingQuotes: boolean;
};

export function LegacyProductWidget(props: Props): React.ReactElement {
  const { t } = useTranslation();
  const { countryCode } = useCarrierContext();
  const { selectedProduct, isLoadingProducts, isLoadingQuotes, coverageAmount } = props;

  return (
    <ProductSummaryContainer display='flex' flexDirection='column' borderRadius='8px' overflow='hidden'>
      <ProductInfoContainer display='flex' flexDirection='column' px={3} py={2}>
        {isLoadingProducts && <Loader />}
        {!isLoadingProducts && (
          <React.Fragment>
            <Typography variant='h3' component='h2'>
              {selectedProduct && ('shortName' in selectedProduct ? selectedProduct.shortName : selectedProduct.name)}
              {!selectedProduct && '--'}
            </Typography>
            <Typography variant='body1' component='h3'>
              {selectedProduct ? selectedProduct.carrierName : '--'}
            </Typography>
          </React.Fragment>
        )}
      </ProductInfoContainer>
      <CoverageAmountContainer px={3} mb={1} component='h4'>
        <Typography variant='body2' component='span'>
          {coverageAmount ? toCurrency(coverageAmount, getCurrency(countryCode), { minimumFractionDigits: 0 }) : '--'}
        </Typography>
      </CoverageAmountContainer>
      <PremiumContainer px={3} pb={3} mt={1}>
        {isLoadingQuotes && (
          <Box width='100%'>
            <Loader />
          </Box>
        )}
        {!isLoadingQuotes && (
          <Box display='flex' flexWrap='wrap' component='h3'>
            <Box>
              <Typography variant='h1' component='span'>
                {selectedProduct && selectedProduct.premium
                  ? toCurrency(selectedProduct.premium, getCurrency(countryCode))
                  : '--'}
              </Typography>
            </Box>
            <Box ml={1}>
              <Typography variant='body4'>{t('perMonth')}</Typography>
            </Box>
          </Box>
        )}
      </PremiumContainer>
    </ProductSummaryContainer>
  );
}
