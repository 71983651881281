import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LogsAdminTools } from '@breathelife/types';

export type LogsAdminToolsState = {
  logs: LogsAdminTools[];
  total: number;
  isLoading: boolean;
};

type LogsAdminToolsPayload = { logs: LogsAdminTools[]; total: number };
type SetIsLoadingPayload = { isLoading: boolean };

type ActionsType = {
  setLogs: (state: LogsAdminToolsState, { payload }: PayloadAction<LogsAdminToolsPayload>) => void;
  setIsLoading: (state: LogsAdminToolsState, { payload }: PayloadAction<SetIsLoadingPayload>) => void;
};

const defaultState: LogsAdminToolsState = {
  logs: [],
  total: 0,
  isLoading: false,
};

export const logsAdminToolsSlice = createSlice<LogsAdminToolsState, ActionsType>({
  name: 'leadPlatform/logsAdminTools',
  initialState: defaultState,
  reducers: {
    setLogs: (state: LogsAdminToolsState, { payload }: PayloadAction<LogsAdminToolsPayload>) => {
      state.logs = payload.logs;
      state.total = payload.total;
    },
    setIsLoading: (state: LogsAdminToolsState, { payload }: PayloadAction<SetIsLoadingPayload>) => {
      state.isLoading = payload.isLoading;
    },
  },
});
