import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { AgentLicenseProperties, Permission, User } from '@breathelife/types';

import { QueryId } from '../../ReactQuery/common/common.types';
import { notificationSlice } from '../../Redux/Notification/NotificationSlice';
import { createAgentLicenses } from '../../Services/AgentLicensesService';

export function useCreateAgentLicenseMutation(
  options?: UseMutationOptions<AgentLicenseProperties[], unknown, User>
): UseMutationResult<AgentLicenseProperties[], unknown, User> {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useMutation<AgentLicenseProperties[], unknown, User>(
    async (user: User) => {
      if (!user) {
        return Promise.reject(new Error('User is missing.'));
      }

      if (user.permissions?.filter((permission) => permission == Permission.AgentLicenseCreate).length === 0) {
        return Promise.reject(new Error('User does not have permission to create agent licenses.'));
      }

      if (!user.customId) {
        return Promise.reject(new Error('User customId is not defined.'));
      }

      return await createAgentLicenses(user.customId);
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        void queryClient.setQueryData([QueryId.agentLicenses, variables.customId], data);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
      onError: async (error, variables, context) => {
        dispatch(
          dispatch(notificationSlice.actions.setError({ message: t('notifications.failedToGetAgentLicenses') }))
        );

        if (options?.onError) {
          await options.onError(error, variables, context);
        }
      },
    }
  );
}
