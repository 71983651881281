import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { ESignCeremony, SignatureType } from '@breathelife/types';

import { QueryId } from '../../ReactQuery/common/common.types';
import { getESignCeremony } from '../../Services/ESignCeremonyService';

export function useGetESignCeremonyQuery(
  applicationId?: string,
  signatureType?: SignatureType,
  options: UseQueryOptions<ESignCeremony | null> = {}
): UseQueryResult<ESignCeremony | null> {
  const { enabled = true } = options;
  const isSignatureTypeValid = signatureType === SignatureType.eSignature;

  return useQuery<ESignCeremony | null>(
    [QueryId.eSignCeremony, applicationId],
    async () => {
      if (!applicationId) {
        return Promise.reject(new Error('Application id is missing.'));
      }
      return await getESignCeremony(applicationId);
    },
    {
      ...options,
      enabled: !!applicationId && isSignatureTypeValid && enabled,
      staleTime: 1000 * 60 * 10, // 10 minutes
    }
  );
}
