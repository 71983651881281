import { useMemo } from 'react';

import { QuestionnaireEngine, RenderingQuestionnaire } from '@breathelife/questionnaire-engine';
import { DEFAULT_TIMEZONE_NAME, InsuranceModule, RenderingType, Timezone } from '@breathelife/types';

import { getQuestionnaireEngineConfig } from '../../Helpers/questionnaireEngineConfigs';
import { useCarrierContext, useLocale } from '../../Hooks';
import { ApplicationHookProps } from './types';

export function useNeedsAnalysisQuestionnaire(props: ApplicationHookProps): RenderingQuestionnaire | undefined {
  const { features } = useCarrierContext();
  const locale = useLocale();

  const { application, questionnaire, nodeIdToAnswerPathMap } = props;

  const questionnaireEngineEnabled = features.engineQuestionnaire.enabled;

  const questionnaireEngine = useMemo(() => {
    if (!questionnaireEngineEnabled || !application || !questionnaire || !nodeIdToAnswerPathMap) return;

    const timezoneResult = Timezone.from(application.timezone || DEFAULT_TIMEZONE_NAME);
    if (timezoneResult.isError()) {
      return;
    }

    return new QuestionnaireEngine(
      questionnaire,
      nodeIdToAnswerPathMap,
      {
        scopes: application.insuranceScopes ?? [],
        insuranceModules: [InsuranceModule.needsAnalysis],
      },
      getQuestionnaireEngineConfig(application.mode, features),
      timezoneResult.value
    );
  }, [application, nodeIdToAnswerPathMap, questionnaire]);

  return useMemo(() => {
    if (!questionnaireEngineEnabled || !application || !questionnaireEngine) return;

    return questionnaireEngine.generateRenderingQuestionnaire(application.answers, locale, () => '', {
      renderingType: RenderingType.proSummary,
      shouldValidateAllAnswers: false,
    });
  }, [application, questionnaireEngine, locale]);
}
