import { IconButtonProps } from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton as StyledIconButton, IconButtonStyleProps } from './Styles';

const SKELETON_DIAMETER = 36;

type Props = {
  icon: React.ReactElement;
  title?: string;
  showSkeleton?: boolean;
} & IconButtonStyleProps &
  Omit<IconButtonProps, 'size'>;

export function IconButton(props: Props): React.ReactElement {
  const { t } = useTranslation();
  const { icon, title, showSkeleton, ...rest } = props;

  if (showSkeleton) return <Skeleton variant='circle' width={SKELETON_DIAMETER} height={SKELETON_DIAMETER} />;

  if (title)
    return (
      <Tooltip placement='top' title={`${props.disabled ? `${t('cta.disabled')}: ` : ''} ${title}`}>
        <div>
          <StyledIconButton {...rest}>{icon}</StyledIconButton>
        </div>
      </Tooltip>
    );

  return <StyledIconButton {...rest}>{icon}</StyledIconButton>;
}
