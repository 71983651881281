import { Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import _ from 'lodash';
import React, { ReactElement } from 'react';

import { RenderingSubsection } from '@breathelife/questionnaire-engine';
import { SubsectionVariant } from '@breathelife/types';

import { PaymentSubsectionContainer } from './SubsectionVariants/Payment/PaymentSubsectionContainer';
import { SubsectionView } from './SubsectionView';

interface SubsectionContainerProps {
  subsection: RenderingSubsection;
}

export function SubsectionContainer(props: SubsectionContainerProps): ReactElement {
  const { subsection } = props;

  let content: ReactElement;

  switch (subsection.variant) {
    case SubsectionVariant.payment:
      content = <PaymentSubsectionContainer />;
      break;
    default:
      content = <SubsectionView subsection={subsection} />;
  }

  return (
    <Box>
      <Divider />
      <Box display='flex' flexDirection='column' pt={4} id={_.kebabCase(subsection.title)}>
        {content}
      </Box>
    </Box>
  );
}
