import { Divider, IconButton } from '@material-ui/core';
import React, { useState, useCallback, useRef, RefObject, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTheme } from 'styled-components';

import { IconName } from '@breathelife/types';
import { Icon, TreeView } from '@breathelife/ui-components';

import { Helmet } from '../../../Components/Helmet';
import { Tabs } from '../../../Components/Tabs/Tabs';
import Typography from '../../../Components/Typography';
import Urls from '../../../Navigation/Urls';
import {
  UnderwritingHeaderContainer,
  UnderwritingReportsArea,
  UnderwritingTreeViewPanelContainer,
  UnderwritingTreeContainer,
  UnderwritingResizeHandle,
} from './UnderwritingReports/Styles';
import { UnderWritingReportsJsonArea } from './UnderwritingReports/UnderwritingReportsJsonArea';

type TreeViewExampleDataItem = {
  label: string;
  identifier: string;
  iconName?: IconName;
  children?: TreeViewExampleDataItem[];
};

function useResize<T extends HTMLElement = HTMLDivElement>({
  minWidth,
}: {
  minWidth: number;
}): {
  ref: RefObject<T>;
  width: number;
  isResizing: boolean;
  enableResize: () => void;
} {
  const [isResizing, setIsResizing] = useState(false);
  const [width, setWidth] = useState(minWidth);
  const ref = useRef<T>(null);

  const enableResize = useCallback(() => {
    setIsResizing(true);
  }, [setIsResizing]);

  const disableResize = useCallback(() => {
    setIsResizing(false);
  }, [setIsResizing]);

  const resize = useCallback(
    (event: MouseEvent) => {
      if (isResizing && ref.current) {
        const offsetLeft = ref.current.getBoundingClientRect().left;
        const newWidth = event.clientX - offsetLeft;
        setWidth(newWidth);
      }
    },
    [isResizing]
  );

  useEffect(() => {
    document.addEventListener('mousemove', resize);
    document.addEventListener('mouseup', disableResize);

    return () => {
      document.removeEventListener('mousemove', resize);
      document.removeEventListener('mouseup', disableResize);
    };
  }, [disableResize, resize]);

  return { ref, width, isResizing, enableResize };
}

const getNewParentPathFromPreviousTreeItemPath = (
  _: string[],
  previousItemPath: string[],
  previousItemIsOpen?: boolean
): string[] => {
  if (previousItemIsOpen) {
    return previousItemPath;
  }

  const parentPath = [...previousItemPath];
  parentPath.length -= 1;
  return parentPath;
};

const TREE_SECTION_WIDTH = 500;

const mockUnderwrittingReportsTreeViewData: TreeViewExampleDataItem[] = [
  {
    label: 'Medical Information',
    identifier: 'medical-information',
    iconName: IconName.list,
    children: [
      {
        label: 'Provider 1',
        identifier: 'p1',
        iconName: IconName.briefcase,
        children: [
          { label: 'Request # XXXXX', identifier: 'general-consent-q1-f1', iconName: IconName.bulletCheckmark },
          { label: 'Request # XXXXX', identifier: 'general-consent-q1-f2', iconName: IconName.bulletEx },
        ],
      },
      {
        label: 'Provider 2',
        identifier: 'p2',
        iconName: IconName.briefcase,
        children: [
          { label: 'Request # XXXXX', identifier: 'general-consent-q1-f1', iconName: IconName.bulletCheckmark },
          { label: 'Request # XXXXX', identifier: 'general-consent-q1-f2', iconName: IconName.bulletEx },
        ],
      },
      {
        label: 'Provider 3',
        identifier: 'p3',
        iconName: IconName.briefcase,
        children: [
          { label: 'Request # XXXXX', identifier: 'general-consent-q1-f1', iconName: IconName.bulletCheckmark },
          { label: 'Request # XXXXX', identifier: 'general-consent-q1-f2', iconName: IconName.bulletEx },
        ],
      },
    ],
  },
  {
    label: 'Motor Vehicule History',
    iconName: IconName.list,
    identifier: 'motor-vehicule-history',
    children: [
      {
        label: 'Provider 1',
        identifier: 'p1',
        iconName: IconName.briefcase,
        children: [
          { label: 'Request # XXXXX', identifier: 'general-consent-q1-f1', iconName: IconName.bulletCheckmark },
          { label: 'Request # XXXXX', identifier: 'general-consent-q1-f2', iconName: IconName.bulletEx },
        ],
      },
      {
        label: 'Provider 2',
        identifier: 'p2',
        iconName: IconName.briefcase,
        children: [
          { label: 'Request # XXXXX', identifier: 'general-consent-q1-f1', iconName: IconName.bulletCheckmark },
          { label: 'Request # XXXXX', identifier: 'general-consent-q1-f2', iconName: IconName.bulletEx },
        ],
      },
      {
        label: 'Provider 3',
        identifier: 'p3',
        iconName: IconName.briefcase,
        children: [
          { label: 'Request # XXXXX', identifier: 'general-consent-q1-f1', iconName: IconName.bulletCheckmark },
          { label: 'Request # XXXXX', identifier: 'general-consent-q1-f2', iconName: IconName.bulletEx },
        ],
      },
    ],
  },
  {
    label: 'Category N',
    iconName: IconName.list,
    identifier: 'category-n',
    children: [
      {
        label: 'Provider 1',
        identifier: 'p1',
        iconName: IconName.briefcase,
        children: [
          { label: 'Request # XXXXX', identifier: 'general-consent-q1-f1', iconName: IconName.bulletCheckmark },
          { label: 'Request # XXXXX', identifier: 'general-consent-q1-f2', iconName: IconName.bulletEx },
        ],
      },
      {
        label: 'Provider 2',
        identifier: 'p2',
        iconName: IconName.briefcase,
        children: [
          { label: 'Request # XXXXX', identifier: 'general-consent-q1-f1', iconName: IconName.bulletCheckmark },
          { label: 'Request # XXXXX', identifier: 'general-consent-q1-f2', iconName: IconName.bulletEx },
        ],
      },
      {
        label: 'Provider 3',
        identifier: 'p3',
        iconName: IconName.briefcase,
        children: [
          { label: 'Request # XXXXX', identifier: 'general-consent-q1-f1', iconName: IconName.bulletCheckmark },
          { label: 'Request # XXXXX', identifier: 'general-consent-q1-f2', iconName: IconName.bulletEx },
        ],
      },
    ],
  },
];

export function UnderwritingReportsViewContainer(): React.ReactElement {
  // TODO DEV-12956: Replace mock data by connecting underwriting reports data
  const [underwrittingTreeViewdata] = useState(mockUnderwrittingReportsTreeViewData);
  const [selectedTab, setSelectedTab] = useState(0);
  const theme = useTheme();
  const history = useHistory();
  const { width, ref, isResizing, enableResize } = useResize({ minWidth: TREE_SECTION_WIDTH });

  const onSwitchTabs = useCallback(
    (newValue: number) => {
      setSelectedTab(newValue);
    },
    [setSelectedTab]
  );

  const pageTitle = 'Application ID - Policy Number';
  const mockTabs = [{ label: 'Insured 1' }, { label: 'Insured 2' }, { label: 'Insured 3' }, { label: 'Insured 4' }];
  return (
    <React.Fragment>
      <Helmet text={pageTitle} />
      <UnderwritingHeaderContainer m={2}>
        <div>
          <Typography variant='h1'>{pageTitle}</Typography>
        </div>
        <IconButton onClick={() => history.push(Urls.backOfficeApplications)} size='medium'>
          <Icon name={IconName.close} size='14px' color={{ primary: theme.colors.grey[60] }} />
        </IconButton>
      </UnderwritingHeaderContainer>
      <Tabs value={selectedTab} onChange={(_event, newValue) => onSwitchTabs(newValue)} tabs={mockTabs} />
      <Divider />
      <UnderwritingReportsArea>
        <UnderwritingTreeViewPanelContainer>
          <UnderwritingTreeContainer ref={ref} width={width}>
            <TreeView
              data={underwrittingTreeViewdata}
              treeName='tree'
              canDropInsideOtherElement
              itemStyle={{ height: 30 }}
              getDataItemFromUnknownItem={(item) => ({
                identifier: item.identifier as string,
                iconName: item.iconName as IconName,
                label: item.label as string,
                iconWidth: 20 as number,
                metadata: {},
              })}
              getUnknownItemChildren={(item) => item.children as TreeViewExampleDataItem[]}
              getNewParentPathFromPreviousTreeItemPath={getNewParentPathFromPreviousTreeItemPath}
            />
          </UnderwritingTreeContainer>
        </UnderwritingTreeViewPanelContainer>
        <UnderwritingResizeHandle type='button' $isResizing={isResizing} onMouseDown={enableResize} />
        <UnderWritingReportsJsonArea />
      </UnderwritingReportsArea>
    </React.Fragment>
  );
}
