import { Box } from '@material-ui/core';
import React, { ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import { Checkbox } from '../../../../Components/Checkbox/Checkbox';
import Typography from '../../../../Components/Typography';
import { useDispatch } from '../../../../Hooks';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';
import { assistedApplicationSlice } from '../../../../Redux/AssistedApplication/AssistedApplicationSlice';

type InfoMessageTrackESignatureModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function InfoMessageTrackESignatureModal(props: InfoMessageTrackESignatureModalProps): React.ReactElement {
  const { isOpen, onClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(assistedApplicationSlice.actions.setDisableTrackESignInfoModal(event.target.checked));
    },
    [dispatch]
  );

  return (
    <ModalLayout
      maxWidth='sm'
      isOpen={isOpen}
      closeModal={onClose}
      hideCancel
      title={t('modals.assistedApplication.trackESignature.title')}
      submitButton={
        <SubmitButton onClick={onClose}>{t('modals.assistedApplication.trackESignature.confirmButton')}</SubmitButton>
      }
    >
      <Box mb={4}>
        <Typography variant='body1'>{t('modals.assistedApplication.trackESignature.body')}</Typography>
      </Box>
      <Box>
        <Checkbox label={t('modals.assistedApplication.trackESignature.checkboxLabel')} onChange={onCheckboxChange} />
      </Box>
    </ModalLayout>
  );
}
