import Box from '@material-ui/core/Box';
import styled from 'styled-components';

import { Button } from '../../../../Components/Button/Button';

export const ContentHeading = styled(Box)`
  background-color: ${(props) => props.theme.colors.grey[20]};
`;

export const CtaButton = styled(Button)`
  &&&& {
    padding: 6px 12px;
  }
`;
