import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItemText';
import React, { useCallback, useMemo } from 'react';

import { FileTemplate, Permission, StoredFile } from '@breathelife/types';
import { Loader } from '@breathelife/ui-components';

import { IconButton } from '../../../../../../Components/Controls';
import { Icon } from '../../../../../../Components/Icons';
import { RestrictedToPermission } from '../../../../../../Components/Restricted/RestrictedToPermission';
import Typography from '../../../../../../Components/Typography';
import { printFileSize } from '../../../../../../Helpers/fileSize';
import { useGetApplicationFile, useLocale } from '../../../../../../Hooks';
import { Application } from '../../../../../../Models/Application';
import { DisplayNameTypography, SubtitleContainer } from '../Styles';

type CompletedRequiredFileProps = {
  application: Application;
  template: FileTemplate;
  storedFile: StoredFile;
  onFileDelete: (fileId: string, isRequired: boolean) => void;
};

export function CompletedRequiredFile(props: CompletedRequiredFileProps): React.ReactElement {
  const { application, template, storedFile, onFileDelete: onFileDeleteProp } = props;

  const platformLanguage = useLocale();
  //  Display file name according to application language
  //  if translation does not exit: default to english name
  const localizedFileName = template.localizedName[platformLanguage] ?? template.localizedName.en;

  const { fetchFile, fileToView } = useGetApplicationFile(
    application.id,
    storedFile.id,
    useCallback(({ signedUrl }) => {
      window.open(signedUrl, '_blank')?.focus();
    }, [])
  );

  const sizeLabel = useMemo(
    () => (storedFile.sizeBytes ? printFileSize(storedFile.sizeBytes, 1) : undefined),
    [storedFile.sizeBytes]
  );

  const onFileDelete = useCallback(async () => {
    await onFileDeleteProp(storedFile.id, true);
  }, [onFileDeleteProp, storedFile.id]);

  const isLoading = fileToView.isLoading;

  return (
    <ListItem>
      <Box display='flex' flexDirection='row' my={3}>
        <Box mr={2}>{isLoading ? <Loader size='24' /> : <Icon name='success' variant='green' inverted />}</Box>
        <Box display='flex' flexDirection='row' width='100%' justifyContent='space-between'>
          <div>
            <div>
              <DisplayNameTypography variant='h4'>{localizedFileName}</DisplayNameTypography>
            </div>
            <div>
              <DisplayNameTypography variant='body3'>{storedFile.displayName}</DisplayNameTypography>
            </div>
            {sizeLabel && (
              <div>
                <SubtitleContainer>
                  <Typography variant='body3'>{sizeLabel}</Typography>
                </SubtitleContainer>
              </div>
            )}
          </div>
          <Box display='flex' flexWrap='no-wrap' alignItems='center'>
            <Box mx={1}>
              <IconButton icon={<Icon name='eye' />} disabled={isLoading} onClick={fetchFile} />
            </Box>
            {!application.submitted && (
              <RestrictedToPermission permission={[Permission.ApplicationFileRemove]}>
                <Box>
                  <IconButton icon={<Icon name='delete' />} disabled={isLoading} onClick={onFileDelete} />
                </Box>
              </RestrictedToPermission>
            )}
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
}
