import { useStripe } from '@stripe/react-stripe-js';
import { useEffect } from 'react';

import { PaymentServiceProvider } from '@breathelife/types';

import { fetchClientSecret, fetchPaymentMethodInfo, retrieveSetupIntent } from '../../Redux/Payment/PaymentOperations';
import { useCarrierContext } from '../useCarrierContext';
import { useDispatch } from '../useDispatch';
import { useSelector } from '../useSelector';

// Encapsulate payment-related hooks
export function usePayment(applicationId: string | undefined): void {
  const { features } = useCarrierContext();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const { clientSecret, setupIntentStatus } = useSelector((store) => store.leadPlatform.payment);

  const isStripeEnabled =
    features.payments.enabled && features.payments.serviceProvider === PaymentServiceProvider.STRIPE;

  useEffect(() => {
    if (!isStripeEnabled || !applicationId || clientSecret) return;
    void dispatch(fetchClientSecret(applicationId));
  }, [
    applicationId,
    clientSecret,
    dispatch,
    features.payments.enabled,
    features.payments.enabled && features.payments.stripe,
  ]);

  useEffect(() => {
    if (!isStripeEnabled || !clientSecret || !stripe) return;
    void dispatch(retrieveSetupIntent(stripe));
  }, [
    clientSecret,
    dispatch,
    features.payments.enabled,
    features.payments.enabled && features.payments.stripe,
    stripe,
  ]);

  useEffect(() => {
    if (setupIntentStatus !== 'succeeded') return;
    void dispatch(fetchPaymentMethodInfo());
  }, [setupIntentStatus]);
}
