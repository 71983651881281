import i18next from 'i18next';
import * as yup from 'yup';

import { EnforceUniqueEmailStrategy, ESignSigner2FAInfo as ESignSigner, ParticipantRoles } from '@breathelife/types';

import { getEmailSchema, getPhoneNumberSchema, getFirstNameSchema, getLastNameSchema } from '../field';
import { getListValidationError, getValidationError } from '../getValidationError';
import { RecursiveNullable } from '../typeGuards';

export enum SignerFormField {
  fistName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  cellphone = 'cellphone',
}

type FillableESignSigner = Omit<ESignSigner, 'roles' | 'documentTypes'>;

const getSchemaMap = (): { [field in SignerFormField]: yup.StringSchema } => ({
  firstName: getFirstNameSchema().required(i18next.t('validation.error.fname')),
  lastName: getLastNameSchema().required(i18next.t('validation.error.lname')),
  email: getEmailSchema().required(i18next.t('validation.error.required')),
  cellphone: getPhoneNumberSchema().required(i18next.t('validation.error.required')),
});

const checkIfAdvisorsHaveUniqueEmails = (signers: RecursiveNullable<ESignSigner[]>): boolean => {
  const agentEmails: string[] = [];
  const otherEmails: string[] = [];

  signers?.forEach((signer) => {
    if (signer?.roles?.find((role) => role?.type === ParticipantRoles.AGENT)) {
      signer?.email && agentEmails.push(signer.email);
    } else {
      signer?.email && otherEmails.push(signer.email);
    }
  });

  const uniqueAgentEmails = new Set(agentEmails);
  const uniqueOtherEmails = new Set(otherEmails);
  return agentEmails.length === uniqueAgentEmails.size && agentEmails.every((email) => !uniqueOtherEmails.has(email));
};

const isFieldValueUnique = (signers: RecursiveNullable<ESignSigner[]>, field: SignerFormField): boolean => {
  if (signers === null || signers === undefined) return true;
  const uniqueSigners = new Set(
    signers.map((el: RecursiveNullable<FillableESignSigner>) => el !== null && el !== undefined && el[field])
  );
  return signers.length === uniqueSigners.size;
};

function getFormSchema(
  enforceUniqueEmailFeature: EnforceUniqueEmailStrategy
): yup.NotRequiredArraySchema<RecursiveNullable<FillableESignSigner>> {
  return yup
    .array()
    .of(yup.object().shape(getSchemaMap()))
    .test(
      'is-unique',
      i18next.t(
        enforceUniqueEmailFeature === EnforceUniqueEmailStrategy.ADVISORS_ONLY
          ? 'validation.error.duplicateAdvisorsEmail'
          : 'validation.error.duplicateSignersEmail'
      ),
      (signers) =>
        enforceUniqueEmailFeature === EnforceUniqueEmailStrategy.ADVISORS_ONLY
          ? checkIfAdvisorsHaveUniqueEmails(signers as RecursiveNullable<ESignSigner>[])
          : isFieldValueUnique(signers as RecursiveNullable<ESignSigner[]>, SignerFormField.email)
    );
}

export function getFieldValidationError(
  fieldName: SignerFormField,
  value: string | undefined
): yup.ValidationError | undefined {
  return getValidationError(getSchemaMap(), fieldName, value);
}

export function getFormValidationError(
  signers: ESignSigner[],
  enforceUniqueEmailFeature: EnforceUniqueEmailStrategy = EnforceUniqueEmailStrategy.ALL
): yup.ValidationError | undefined {
  return getListValidationError<FillableESignSigner>(getFormSchema(enforceUniqueEmailFeature), signers);
}
