import React from 'react';
import * as yup from 'yup';

import { IconName } from '@breathelife/types';
import { AutoComplete } from '@breathelife/ui-components';

type Props = {
  id?: string;
  label: string;
  options?: { label: string; value: string }[];
  onChange: (updatedSelection: string | null) => void;
  defaultOption?: { label: string; value: string };
  selectedOptionValue?: string;
  disabled?: boolean;
  required?: boolean;
  disableClearable?: boolean;
  startIconName?: IconName;
  renderOption?: (
    option: {
      value: string;
      label: string;
    },
    state: {
      inputValue: string;
      selected: boolean;
    }
  ) => React.ReactNode;
  validationError?: yup.ValidationError;
};

export function EditorDropdown(props: Props): React.ReactElement | null {
  const {
    id,
    label,
    options,
    selectedOptionValue,
    defaultOption,
    onChange,
    validationError,
    disabled = false,
    required = false,
    disableClearable,
    renderOption,
    startIconName,
  } = props;

  if (!options) return null;

  const selectedOption = selectedOptionValue
    ? options.find((option) => option.value === selectedOptionValue)
    : defaultOption;

  return (
    <AutoComplete
      id={id}
      options={options}
      option={selectedOption || null}
      onChange={(selection) => onChange(selection?.value || null)}
      label={label}
      disabled={disabled}
      disableClearable={disableClearable}
      renderOption={renderOption}
      startIconName={startIconName}
      validationError={validationError}
      required={required}
    />
  );
}
