import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { ExpandableFileTemplateRule } from '@breathelife/types';

import { QueryId } from '../../../ReactQuery/common/common.types';
import { findFileTemplateRules } from '../../../Services/FileTemplateRulesService';

export function useFindFileTemplateRulesQuery(
  questionnaireVersionId: string | undefined,
  fileTemplateId: string,
  options?: UseQueryOptions<ExpandableFileTemplateRule[], Error>
): UseQueryResult<ExpandableFileTemplateRule[], Error> {
  return useQuery<ExpandableFileTemplateRule[], Error>(
    [QueryId.fileTemplateRules, questionnaireVersionId, fileTemplateId],
    async () => {
      if (!questionnaireVersionId) {
        throw new Error('Invalid questionnaireVersionId');
      }
      return await findFileTemplateRules(questionnaireVersionId, {
        templateId: fileTemplateId,
      });
    },
    {
      ...options,
      // The query will not execute until the questionnaireVersionId exists
      enabled: !!questionnaireVersionId,
    }
  );
}
