import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

import { Button } from '../../Components/Button/Button';
import { getSupportingShade } from '../../Helpers/theme/colors';

type StyledButtonProps = {
  $color: string;
};

export const StyledLoader = styled(CircularProgress)`
  &&& {
    margin: 0;
  }
`;

export const StyledButton = styled(Button)<StyledButtonProps>`
  &&&&& {
    padding: 10px 13px !important;
    svg {
      margin-left: 0;
      background-color: ${(props) => getSupportingShade(props.theme.colors[props.$color], -10)};
      border-radius: 50%;
      height: 20px;
      width: 20px;
    }
    &:hover {
      background-color: ${(props) => getSupportingShade(props.theme.colors[props.$color], -10)} !important;
      svg {
        background-color: ${(props) => getSupportingShade(props.theme.colors[props.$color], -20)};
      }
    }
  }
`;
