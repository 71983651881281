import i18next from 'i18next';
import * as yup from 'yup';

import { Language } from '@breathelife/types';

import { FirmFormFields } from '../../../Pages/Admin/Product/types';
import { getEmailSchema, getPhoneNumberSchema, localizableFieldSchema } from '../field';
import { objectSchemaFieldsAreDefined } from '../typeGuards';

function firmFormFieldsSchema(): yup.ObjectSchema {
  return yup.object().shape({
    name: yup.string().required(i18next.t('validation.error.required')),
    address: yup.string().nullable(),
    primaryPhoneNumber: getPhoneNumberSchema().nullable(),
    tollFreePhoneNumber: getPhoneNumberSchema().nullable(),
    faxNumber: getPhoneNumberSchema().nullable(),
    websiteUrl: yup.string().nullable(),
    email: getEmailSchema().nullable(),
    firmAmfNumber: yup.string().nullable(),
    insurerAmfNumber: yup.string().nullable(),
    privacyPolicyUrl: yup.string().nullable(),
    firmRegistrationUrl: yup.string().nullable(),
    insurerRegistrationUrl: yup.string().nullable(),
  });
}

export function firmFormSchema(enabledLanguages: Language[]): yup.ObjectSchema {
  return yup.object().shape({
    ...firmFormFieldsSchema().fields,
    name: localizableFieldSchema(enabledLanguages, { required: true }),
    address: localizableFieldSchema(enabledLanguages, { required: false }),
  });
}

export function getFieldValidationError(
  fieldName: keyof FirmFormFields,
  value: unknown
): yup.ValidationError | undefined {
  const schema = firmFormFieldsSchema();

  if (!objectSchemaFieldsAreDefined(schema)) return;

  try {
    schema.fields[fieldName].validateSync(value);
  } catch (error) {
    return error;
  }
  return;
}
