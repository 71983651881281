import React from 'react';

import { DragAndDropUpload } from '../../Pages/Admin/Questionnaire/QuestionnaireEditor/Components/DragAndDropUpload';
import { ImportCtx } from './ImportDataProvider';

type Props = {
  title: string;
};

export function ImportModal(props: Props): React.ReactElement {
  const { isImportModalOpen, toggleImportModal, handleFileUpload } = React.useContext(ImportCtx);

  return (
    <DragAndDropUpload
      accept={'application/json'}
      title={props.title}
      isOpen={isImportModalOpen}
      handleFileUpload={handleFileUpload}
      onClose={toggleImportModal}
    />
  );
}
