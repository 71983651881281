import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Language, Localizable, QuestionnaireBlueprintFieldTypes } from '@breathelife/types';
import { AutoComplete, AutocompleteOption } from '@breathelife/ui-components';

import { NodeDetail } from '../../../Helpers/questionnaireEditor/questionnaireNodeIds';

type TargetNodeAutocompleteOption = AutocompleteOption & {
  fieldType?: QuestionnaireBlueprintFieldTypes;
  groupName?: Partial<Localizable>;
};

type Props = {
  value: string;
  nodeIdList: NodeDetail[];
  selectedLanguage: Language;
  onSelect: (value: string | null) => any;
  label?: string;
  fieldId?: string;
  validationError?: yup.ValidationError;
};

export function ConditionTargetNodeAutocomplete(props: Props): React.ReactElement {
  const { nodeIdList, onSelect, label, value, fieldId, validationError, selectedLanguage } = props;

  const { t } = useTranslation();

  const options: TargetNodeAutocompleteOption[] = useMemo(
    () =>
      nodeIdList.map((nodeIdDetail) => ({
        label: nodeIdDetail.answerNodeId,
        value: nodeIdDetail.answerNodeId,
        fieldType: nodeIdDetail.fieldType,
        groupName: nodeIdDetail.groupName,
      })),
    [nodeIdList]
  );

  const handleChange = useCallback(
    (option: { value: string } | null) => {
      onSelect(option?.value || null);
    },
    [onSelect]
  );

  return (
    <AutoComplete
      id={fieldId ?? 'condition-target-node-autocomplete'}
      options={options}
      option={{ label: value, value: value, groupName: {} }}
      label={label ?? t('admin.conditions.labels.targetNodeId')}
      onChange={handleChange}
      validationError={validationError}
      groupBy={(option) => (option.groupName && option.groupName[selectedLanguage]) ?? ''}
    />
  );
}
