import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import { UserNotificationOptions } from '@breathelife/types';

import { getNotificationTypesFromUserPreference } from '../../../../Helpers/notificationPreferences';
import { useDispatch, useSelector } from '../../../../Hooks';
import { updateUserNotificationPreferences } from '../../../../Redux/Authentication/UserOperations';
import { NotificationPreferencesModal } from './NotificationPreferencesModal';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

export function NotificationPreferencesModalContainer(props: Props): React.ReactElement | null {
  const { isOpen, closeModal } = props;
  const dispatch = useDispatch();

  const defaultPreferences = getNotificationTypesFromUserPreference();
  const [initialPreferences, setInitialPreferences] = useState<UserNotificationOptions>(defaultPreferences);
  const [preferences, setPreferences] = useState<UserNotificationOptions>(defaultPreferences);

  const isLoading = useSelector((state) => state.leadPlatform.authentication.isLoading);
  const user = useSelector((store) => store.leadPlatform.authentication.user);
  const userNotificationPreferences = user?.notificationPreferences;

  useEffect(() => {
    const currentPreferences = getNotificationTypesFromUserPreference({ preference: userNotificationPreferences });
    setInitialPreferences(currentPreferences);
    setPreferences(currentPreferences);
  }, [userNotificationPreferences]);

  const onSaveClick = useCallback(async () => {
    if (!user) return;
    const preferencesWithBoolValues = _.mapValues(preferences, (preference) => !!preference);
    await dispatch(updateUserNotificationPreferences(user.auth0Id, preferencesWithBoolValues));
    closeModal();
  }, [dispatch, user, preferences, closeModal]);

  return (
    <NotificationPreferencesModal
      isOpen={isOpen}
      closeModal={closeModal}
      isLoading={isLoading}
      preferences={preferences}
      onExited={() => {
        setPreferences(initialPreferences);
      }}
      onSaveClick={onSaveClick}
      onChange={(notification: string) => {
        setPreferences((oldPreferences) => ({
          ...oldPreferences,
          [notification]: !preferences[notification],
        }));
      }}
    />
  );
}
