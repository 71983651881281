import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _ from 'lodash';
import React from 'react';
import styled, { useTheme } from 'styled-components';

import Typography from '../../../../Components/Typography';
import { useSelector } from '../../../../Hooks';
import { Application } from '../../../../Models/Application';

type IdentityVerificationProps = {
  application: Application;
};

enum InstantIdRiskIndicator {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

enum ColorCodeLevel {
  fontColorLevel = 60,
  backgroundColorLevel = 20,
}

export const TableHeaderTypography = styled(Typography)`
  text-transform: capitalize;
`;

export const SeverityContainer = styled(Box)<{ background: string }>`
  padding: 2px 4px;
  background: ${(props) => props.background};
  display: block;
  text-align: center;
`;

const SetColor = (severity: string, colorLevel: number): any => {
  const theme = useTheme();
  switch (severity) {
    case InstantIdRiskIndicator.HIGH:
      return theme.colors.red[colorLevel];
    case InstantIdRiskIndicator.MEDIUM:
      return theme.colors.orange[colorLevel];
    case InstantIdRiskIndicator.LOW:
      return theme.colors.green[colorLevel];
    default:
      return '';
  }
};

function SplitCamelCaseOfRiskIndicatorHeading(riskIndicatorHeading: string) {
  return riskIndicatorHeading.replace(/([A-Z])/g, ' $1').trim();
}
export function IdentityVerificationDrawerBody(props: IdentityVerificationProps): React.ReactElement | null {
  const { application } = props;
  const { riskIndicators } = useSelector((store) => store.leadPlatform.identityVerification);
  const riskIndicatorReportData = Object.keys(riskIndicators[0]);

  if (!application) {
    return null;
  }

  return (
    <Box mx={3}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {riskIndicatorReportData.map((riskIndicator, i) => (
                <TableCell key={i}>
                  <TableHeaderTypography variant='body5' grey={70}>
                    {SplitCamelCaseOfRiskIndicatorHeading(riskIndicator)}
                  </TableHeaderTypography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {riskIndicators.map((riskIndicator, i) => (
              <TableRow key={i}>
                <TableCell>
                  <SeverityContainer background={SetColor(riskIndicator.severity, ColorCodeLevel.backgroundColorLevel)}>
                    <Typography
                      variant='small2'
                      color={SetColor(riskIndicator.severity, ColorCodeLevel.fontColorLevel)}
                    >
                      {` ${riskIndicator.severity}`}
                    </Typography>
                  </SeverityContainer>
                </TableCell>
                <TableCell>
                  <Typography variant='body3' grey={100}>
                    {riskIndicator.riskIndicatorDescription}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
