import Box from '@material-ui/core/Box';
import { captureException } from '@sentry/browser';
import _ from 'lodash';
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ESignFieldSetting } from '@breathelife/types';

import { SubmitButton } from '../../../../../../Components/Button/SubmitButton';
import { eSignFieldSettingValidation } from '../../../../../../Helpers/inputValidation/form/eSignFieldSetting';
import { useDispatch } from '../../../../../../Hooks';
import {
  useUpdateESignFieldSettingMutation,
  useDeleteESignFieldSettingMutation,
} from '../../../../../../ReactQuery/ESignFieldSettings/eSignFieldSettings.mutations';
import { notificationSlice } from '../../../../../../Redux/Notification/NotificationSlice';
import { ESignFieldSettingForm, ESignFieldSettingFormData } from './ESignFieldSettingForm';
import { ESignFieldSettingUtils } from './utils';

type Props = {
  signerSettingId: string;
  eSignFieldSetting: ESignFieldSetting;
};

const transformToFormData = (data: ESignFieldSetting): ESignFieldSettingFormData => ({
  ...data,
  top: data.top.toString(),
  left: data.left.toString(),
  width: data.width.toString(),
  height: data.height.toString(),
  fieldBinding: data.fieldBinding ?? null,
  defaultValue: data.defaultValue ?? null,
  page: data['page']?.toString(),
  anchorText: data['anchorText'],
  anchorPoint: data['anchorPoint'],
  anchorIndex: data['anchorIndex']?.toString(),
  anchorCharacterIndex: data['anchorCharacterIndex'],
});

export function EditESignFieldSetting(props: Props): React.ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [eSignFieldSetting, setESignFieldSetting] = useState<ESignFieldSettingFormData>(
    transformToFormData(props.eSignFieldSetting)
  );

  // Update Mutation
  const updateDocumentFieldMutation = useUpdateESignFieldSettingMutation();
  const deleteDocumentFieldMutation = useDeleteESignFieldSettingMutation();

  const deleteESignFieldSettingHandler = useCallback(
    async (templateId) => {
      await deleteDocumentFieldMutation.mutateAsync(templateId);
    },
    [deleteDocumentFieldMutation]
  );

  const updateESignFieldSetting = useCallback(
    async (eSignFieldSetting: ESignFieldSettingFormData): Promise<void> => {
      const isESignFieldSettingFormValid = eSignFieldSettingValidation.getFormSchema().isValidSync(eSignFieldSetting);
      if (!isESignFieldSettingFormValid) {
        return;
      }

      const submissionData = ESignFieldSettingUtils.transformFormData(eSignFieldSetting);
      if (submissionData === undefined) {
        captureException(
          {
            message: 'Unable to construct a request payload to the eSignFieldSetting endpoint.',
            data: eSignFieldSetting,
          },
          {
            tags: {
              BLCode: 'UpdateESignFieldSettingError',
            },
          }
        );
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToUpdateSettings'),
          })
        );
        return;
      }

      await updateDocumentFieldMutation.mutateAsync({
        data: submissionData,
        id: props.eSignFieldSetting.id,
      });
    },
    [dispatch, props.eSignFieldSetting.id, t, updateDocumentFieldMutation]
  );

  const debouncedUpdateESignFieldSetting = useMemo(
    () => _.debounce(updateESignFieldSetting, 500),
    [updateESignFieldSetting]
  );

  useEffect(() => {
    if (deleteDocumentFieldMutation.isLoading) {
      debouncedUpdateESignFieldSetting.cancel();
    }
  }, [debouncedUpdateESignFieldSetting, deleteDocumentFieldMutation.isLoading]);

  return (
    <React.Fragment>
      <ESignFieldSettingForm
        data={eSignFieldSetting}
        onChange={(data) => {
          setESignFieldSetting(data);
          void debouncedUpdateESignFieldSetting(data);
        }}
        disabled={deleteDocumentFieldMutation.isLoading}
      />

      <Box display='flex' justifyContent='flex-end' width='100%' p={2}>
        <SubmitButton
          isLoading={deleteDocumentFieldMutation.isLoading}
          onClick={() => deleteESignFieldSettingHandler(props.eSignFieldSetting.id)}
        >
          {t('cta.delete')}
        </SubmitButton>
      </Box>
    </React.Fragment>
  );
}
