import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { deserializeNodeIdToAnswerPathMap } from '@breathelife/questionnaire-engine';
import { ApplicationMode, Permission, QuotedInsuranceProduct } from '@breathelife/types';
import { Loader } from '@breathelife/ui-components';

import { ApplicationModeBadge } from '../../Components/Badge/ApplicationModeBadge';
import { OutcomeBadge } from '../../Components/Badge/OutcomeBadge';
import { Button } from '../../Components/Button/Button';
import { Checkbox } from '../../Components/Checkbox/Checkbox';
import { Progress } from '../../Components/Progress/Progress';
import { RestrictedToPermission } from '../../Components/Restricted/RestrictedToPermission';
import Typography from '../../Components/Typography';
import getCurrency from '../../Helpers/currency';
import { userHasPermission } from '../../Helpers/user';
import {
  useCarrierContext,
  useDispatch,
  useInsuranceApplicationProgress,
  useNeedsAnalysisProgress,
  useSelector,
} from '../../Hooks';
import { toCurrency } from '../../Localization/utils';
import { Application } from '../../Models/Application';
import { Lead } from '../../Models/Lead';
import { LaunchApplicationType } from '../../Models/PlatformFeatures';
import { QuotedProduct } from '../../Models/Product';
import { useGetPointOfSaleDecisionsOutcomeForSingleInsured } from '../../ReactQuery/PointOfSaleDecisions/pointOfSaleDecisions.queries';
import { useFetchQuestionnaireQuery } from '../../ReactQuery/Questionnaire/questionnaire.queries';
import { notificationSlice } from '../../Redux/Notification/NotificationSlice';
import { LeadItemButtonContainer, LeadItemContainer } from './Styles';

export type LeadItemProps = {
  application?: Application;
  hasCoverageAmountError?: boolean;
  hasCustomCoverageAmount?: boolean;
  isItemSelected: boolean;
  isLoadingQuotes?: boolean;
  lead?: Lead;
  onLaunchApplication: (application: Application | undefined) => void;
  onToggleIsDeclarationSent?: (applicationId: string, isDeclarationSent: boolean) => void;
  product?: QuotedProduct | QuotedInsuranceProduct;
  title: string | undefined;
};

export function LeadItem(props: LeadItemProps): React.ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const { countryCode, features } = useCarrierContext();
  const user = useSelector((store) => store.leadPlatform.authentication.user);
  const {
    application,
    hasCoverageAmountError,
    hasCustomCoverageAmount,
    isItemSelected,
    isLoadingQuotes,
    lead,
    onLaunchApplication,
    onToggleIsDeclarationSent,
    product,
    title,
  } = props;
  const { data: questionnaireData, isLoading: isLoadingQuestionnaire } = useFetchQuestionnaireQuery(application?.id, {
    onError: () =>
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToFetchQuestionnaire'),
        })
      ),
  });

  const shouldFetchPointOfSaleDecisionOutcome =
    !features.assistedApplication?.multiInsured?.enabled &&
    !!features.pointOfSaleDecisionOutcome?.enabled &&
    !!features.questionnaireScreen?.enabled &&
    !!application?.id;

  const { data: pointOfSaleDecisionOutcomeForFirstInsured } = useGetPointOfSaleDecisionsOutcomeForSingleInsured(
    application?.id,
    {
      staleTime: 0, //Always refetch.
      enabled: shouldFetchPointOfSaleDecisionOutcome,
    }
  );

  const questionnaire = questionnaireData?.questionnaire;
  const serializedNodeIdToAnswerPathMap = questionnaireData?.nodeIdToAnswerPath;
  const isProduct = typeof product !== 'undefined';
  const leadStatus = lead?.status || 'new';

  const nodeIdToAnswerPathMap = useMemo(
    () => serializedNodeIdToAnswerPathMap && deserializeNodeIdToAnswerPathMap(serializedNodeIdToAnswerPathMap),
    [serializedNodeIdToAnswerPathMap]
  );

  const needsAnalysisProgress = useNeedsAnalysisProgress({
    application,
    questionnaire,
    nodeIdToAnswerPathMap,
  });
  const insuranceApplicationProgress = useInsuranceApplicationProgress({
    application,
    questionnaire,
    nodeIdToAnswerPathMap,
  });

  const applicationProgress = useMemo(() => {
    if (!application) return 0;
    if (
      features.launchApplication.enabled &&
      [LaunchApplicationType.assistedApplication, LaunchApplicationType.coBrowsing].includes(
        features.launchApplication.type
      )
    ) {
      return insuranceApplicationProgress ?? 0;
    }
    return needsAnalysisProgress ?? 0;
  }, [application, features, insuranceApplicationProgress, needsAnalysisProgress]);

  const showProgress = application && !_.isUndefined(applicationProgress) && isItemSelected;

  const isLeadAssignedToUser = user && user.auth0Id && user.auth0Id === lead?.assignedToId;
  const canLaunchAllApplication = userHasPermission(user?.permissions ?? [], [
    Permission.ApplicationLaunchAll,
    // TODO: Remove ApplicationLaunch once every carrier are in prod for at least 1 day with the new
    // ApplicationLaunchMe and ApplicationLaunchAll permissions.
    Permission.ApplicationLaunch,
  ]);
  const canLaunchApplicationAssignedToUser =
    isLeadAssignedToUser && userHasPermission(user?.permissions ?? [], [Permission.ApplicationLaunchMe]);

  const canUserLaunchApplication =
    !hasCoverageAmountError && (canLaunchApplicationAssignedToUser || canLaunchAllApplication);

  const onToggleIsDeclarationSentClick = useCallback(() => {
    if (!application || !onToggleIsDeclarationSent) return;
    onToggleIsDeclarationSent(application.id, !application.isDeclarationSent);
  }, [application, onToggleIsDeclarationSent]);

  const onLaunchApplicationClick = useCallback(() => {
    onLaunchApplication(application);
  }, [application, onLaunchApplication]);

  return (
    <LeadItemContainer>
      <Box>
        <Typography variant='h3' grey={100}>
          {title}
          {application && application.mode === ApplicationMode.paper && (
            <Box display='inline-block' ml='10px'>
              <ApplicationModeBadge mode={application.mode} />
            </Box>
          )}
        </Typography>
        {showProgress && (
          <Box py={1}>
            {isLoadingQuestionnaire ? (
              <Skeleton width={120} height='2em' />
            ) : (
              <Progress progress={applicationProgress || 0} status={leadStatus} />
            )}
          </Box>
        )}
        {shouldFetchPointOfSaleDecisionOutcome && pointOfSaleDecisionOutcomeForFirstInsured && isItemSelected && (
          <Box mt={1}>
            <OutcomeBadge outcome={pointOfSaleDecisionOutcomeForFirstInsured} />
          </Box>
        )}
        {isProduct && isLeadAssignedToUser && application && (
          <RestrictedToPermission permission={Permission.ApplicationSendDeclaration}>
            <Checkbox
              checked={application.isDeclarationSent}
              onChange={onToggleIsDeclarationSentClick}
              label={t('leadDetailDrawer.isDeclarationSent')}
            />
          </RestrictedToPermission>
        )}
      </Box>
      <Box>
        {isProduct && isLoadingQuotes && <Loader size='20px' />}
        {isProduct && !isLoadingQuotes && (
          <Typography
            color={hasCustomCoverageAmount && product?.premium ? theme.colors.orange[50] : undefined}
            variant='h2'
            grey={90}
            component='p'
            align='right'
          >
            {product?.premium ? toCurrency(product?.premium, getCurrency(countryCode)) : '--'}
            {product?.premium && (
              <Typography variant='body5' grey={90}>
                &nbsp;
                {t('perMonth')}
              </Typography>
            )}
          </Typography>
        )}
        <LeadItemButtonContainer>
          <Box my={1}>
            <Button
              color='primary'
              variant={isItemSelected ? 'outlined' : 'contained'}
              disabled={!canUserLaunchApplication || (isProduct && (!product?.premium || isLoadingQuotes))}
              onClick={onLaunchApplicationClick}
            >
              {isItemSelected ? t('cta.continue') : t('cta.launchApplication')}
            </Button>
          </Box>
        </LeadItemButtonContainer>
      </Box>
    </LeadItemContainer>
  );
}
