import Grid from '@material-ui/core/Grid';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BodyMassIndexRangeConditionValue, MeasurementSystem } from '@breathelife/types';
import { SelectMui } from '@breathelife/ui-components';

import { NumberInput } from '../../../Components/NumberInput/NumberInput';
import {
  getConditionValidationError,
  ValidationValues,
} from '../../../Helpers/inputValidation/form/salesDecisionRules';
import { getOptionsFromEnum } from '../../../Helpers/options';
import { CollectionOperators } from '../Helpers/CollectionOperators';
import { ConditionTargetNodeAutocomplete } from '../Helpers/ConditionTargetNodeAutocomplete';
import { ValidationErrorText } from '../ValidationErrorText';
import { ConditionCriteriaProps } from '../types';

function MeasurementSystemSelect(props: {
  value: MeasurementSystem;
  onChange: (value: MeasurementSystem) => void;
}): React.ReactElement {
  const { value, onChange } = props;

  const { t } = useTranslation();

  const options = getOptionsFromEnum<MeasurementSystem>(
    MeasurementSystem,
    'admin.conditions.options.measurementSystem'
  );

  return (
    <SelectMui
      id='measurement-system-select'
      label={t('admin.conditions.labels.measurementSystem')}
      options={options}
      value={value}
      onChange={onChange}
    />
  );
}

export function BmiRangeConditionCriteria({
  condition,
  nodeIdList,
  nodeIdInCollectionMap,
  collectionContext,
  onConditionPropertiesChange,
  language,
  conditionIndex,
}: ConditionCriteriaProps<BodyMassIndexRangeConditionValue>): React.ReactElement {
  const { t } = useTranslation();
  const validationErrors = getConditionValidationError(condition, nodeIdList, nodeIdInCollectionMap);

  const [minBmi, setMinBmi] = useState<number | undefined>(condition.value.minBMI);
  const [maxBmi, setMaxBmi] = useState<number | undefined>(condition.value.maxBMI);

  const onBlur = useCallback(() => {
    if (typeof minBmi === 'undefined' || typeof maxBmi === 'undefined') return;

    onConditionPropertiesChange({ value: { minBMI: minBmi, maxBMI: maxBmi } });
  }, [onConditionPropertiesChange, minBmi, maxBmi]);

  const collectionNodeIds = nodeIdInCollectionMap[condition.heightNodeId ?? condition.weightNodeId] ?? [];

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <ConditionTargetNodeAutocomplete
          fieldId='condition-target-node-autocomplete-height'
          label={t('admin.conditions.labels.height')}
          value={condition.heightNodeId}
          nodeIdList={nodeIdList}
          onSelect={(value) => {
            if (value !== null) {
              onConditionPropertiesChange({ heightNodeId: value });
            }
          }}
          validationError={validationErrors?.[ValidationValues.heightNodeId]}
          selectedLanguage={language}
        />
      </Grid>
      <Grid item xs={12}>
        <ConditionTargetNodeAutocomplete
          fieldId='condition-target-node-autocomplete-weight'
          label={t('admin.conditions.labels.weight')}
          value={condition.weightNodeId}
          nodeIdList={nodeIdList}
          onSelect={(value) => {
            if (value !== null) {
              onConditionPropertiesChange({ weightNodeId: value });
            }
          }}
          validationError={validationErrors?.[ValidationValues.weightNodeId]}
          selectedLanguage={language}
        />
      </Grid>
      <Grid item xs={4}>
        <NumberInput
          inputVariant='outlined'
          value={minBmi}
          onNumberChange={setMinBmi}
          label={t('admin.conditions.labels.minBmi')}
          onBlur={onBlur}
          error={!!validationErrors?.[ValidationValues.minBMIValue]}
        />
        {validationErrors?.[ValidationValues.minBMIValue] && (
          <ValidationErrorText>{validationErrors?.[ValidationValues.minBMIValue]?.message}</ValidationErrorText>
        )}
      </Grid>
      <Grid item xs={4}>
        <NumberInput
          inputVariant='outlined'
          value={maxBmi}
          onNumberChange={setMaxBmi}
          label={t('admin.conditions.labels.maxBmi')}
          onBlur={onBlur}
          error={!!validationErrors?.[ValidationValues.maxBMIValue]}
        />
        {validationErrors?.[ValidationValues.maxBMIValue] && (
          <ValidationErrorText>{validationErrors?.[ValidationValues.maxBMIValue]?.message}</ValidationErrorText>
        )}
      </Grid>
      <Grid item xs={12}>
        <MeasurementSystemSelect
          value={condition.heightUnit === 'inch' ? MeasurementSystem.imperial : MeasurementSystem.metric}
          onChange={(measurementSystem) => {
            if (measurementSystem === MeasurementSystem.imperial) {
              onConditionPropertiesChange({ heightUnit: 'inch', weightUnit: 'lb' });
              return;
            }
            onConditionPropertiesChange({ heightUnit: 'cm', weightUnit: 'kg' });
          }}
        />
        {validationErrors?.[ValidationValues.heightUnit] && (
          <ValidationErrorText>{validationErrors?.[ValidationValues.heightUnit]?.message}</ValidationErrorText>
        )}
        {validationErrors?.[ValidationValues.weightUnit] && (
          <ValidationErrorText>{validationErrors?.[ValidationValues.weightUnit]?.message}</ValidationErrorText>
        )}
      </Grid>
      <CollectionOperators
        collectionContext={collectionContext}
        collectionNodeIds={collectionNodeIds}
        collectionOperators={condition.collectionOperators}
        onConditionPropertiesChange={onConditionPropertiesChange}
        conditionIndex={conditionIndex}
      />
    </React.Fragment>
  );
}
