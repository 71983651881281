import Box from '@material-ui/core/Box';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '../../../../Components/Alert/Alert';
import { UploadButton } from '../../../../Components/Button/UploadButton';
import { convertFileSize, FileSizeUnits, printFileSize } from '../../../../Helpers/fileSize';
import { useSelector } from '../../../../Hooks';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';
import { FileAttachmentItem } from './FileAttachmentItem';
import { ListContainer } from './Styles';

type FileAttachmentModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  isUploadDisabled: boolean;
  onFileUpload: (file: File) => void;
  onFileUploadError: (file: File, errorMessage: string) => void;
  onViewAttachment: (attachmentId: string) => void;
  onDeleteAttachment: (attachmentId: string) => Promise<void>;
};

// Maximum upload size per file is limited to 8MB
const MAX_UPLOAD_SIZE = convertFileSize(8, FileSizeUnits.MB, FileSizeUnits.B);

/**
 * @deprecated Use `DocumentsDrawer`
 */
export function FileAttachmentModal(props: FileAttachmentModalProps): React.ReactElement {
  const {
    isOpen,
    closeModal,
    isUploadDisabled,
    onFileUpload: onFileUploadProps,
    onFileUploadError,
    onViewAttachment,
    onDeleteAttachment,
  } = props;
  const { t } = useTranslation();
  const { isUploadingAttachment, attachments } = useSelector((store) => store.leadPlatform.application);

  const [errorMessage, setErrorMessage] = useState<string>();

  const onCloseAlert = useCallback(() => {
    // Reset error message
    setErrorMessage(undefined);
  }, []);

  const onFileUpload = useCallback(
    (file: File) => {
      if (file.size > MAX_UPLOAD_SIZE) {
        const maxSizeLabel = printFileSize(MAX_UPLOAD_SIZE);
        setErrorMessage(
          t('modals.assistedApplication.fileAttachment.errorUploadLimitExceeded', {
            filename: file.name,
            size: maxSizeLabel,
          })
        );
        onFileUploadError(file, 'File exceeded upload size limit.');
        return;
      }
      onFileUploadProps(file);
    },
    [onFileUploadProps, onFileUploadError, t]
  );

  const uploadButton = useMemo(() => {
    return (
      <UploadButton
        onChange={onFileUpload}
        extensions={['pdf']}
        disabled={isUploadDisabled}
        isLoading={isUploadingAttachment}
        loaderProps={{
          color: 'secondary',
        }}
        variant='contained'
        color='primary'
        component='label'
      >
        {t('modals.assistedApplication.fileAttachment.uploadButton')}
      </UploadButton>
    );
  }, [onFileUpload, isUploadDisabled, isUploadingAttachment, t]);

  return (
    <ModalLayout
      maxWidth='sm'
      title={t('modals.assistedApplication.fileAttachment.modalTitle')}
      isOpen={isOpen}
      closeModal={closeModal}
      submitButton={uploadButton}
      hideCancel
      bodyStyling={[
        {
          padding: 0,
        },
      ]}
    >
      <ListContainer width='100%' height='60vh'>
        {errorMessage && (
          <Box m={2}>
            <Alert severity='error' onClose={onCloseAlert}>
              {errorMessage}
            </Alert>
          </Box>
        )}
        {attachments.map((attachment) => (
          <FileAttachmentItem
            attachment={attachment}
            key={attachment.id}
            onViewAttachment={onViewAttachment}
            onDeleteAttachment={onDeleteAttachment}
          />
        ))}
      </ListContainer>
    </ModalLayout>
  );
}
