import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { EmptinessConditionValue } from '@breathelife/types';

import {
  getConditionValidationError,
  ValidationValues,
} from '../../../Helpers/inputValidation/form/salesDecisionRules';
import { CollectionOperators } from '../Helpers/CollectionOperators';
import { ConditionTargetNodeAutocomplete } from '../Helpers/ConditionTargetNodeAutocomplete';
import { QuantifierRadioGroup } from '../Helpers/QuantifierRadioGroup';
import { ValidationErrorText } from '../ValidationErrorText';
import { ConditionCriteriaProps } from '../types';

function EmptinessQuantifierRadioGroup(props: {
  value: boolean;
  onChange: (value: boolean) => void;
}): React.ReactElement {
  const { value, onChange } = props;

  const { t } = useTranslation();

  const options = [
    { value: 'true', label: t('admin.conditions.options.emptiness.empty') },
    { value: 'false', label: t('admin.conditions.options.emptiness.notEmpty') },
  ];

  return (
    <QuantifierRadioGroup
      label={t('admin.conditions.labels.emptiness')}
      name='condition-emptiness-radio-group'
      options={options}
      value={String(value)}
      onChange={(value: string) => {
        onChange(value === 'true');
      }}
    />
  );
}

export function EmptinessConditionCriteria({
  condition,
  nodeIdList,
  nodeIdInCollectionMap,
  collectionContext,
  onConditionPropertiesChange,
  language,
  conditionIndex,
}: ConditionCriteriaProps<EmptinessConditionValue>): React.ReactElement {
  const validationErrors = getConditionValidationError(condition, nodeIdList, nodeIdInCollectionMap);
  const collectionNodeIds = nodeIdInCollectionMap[condition.targetNodeId] ?? [];

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <ConditionTargetNodeAutocomplete
          value={condition.targetNodeId}
          nodeIdList={nodeIdList}
          onSelect={(targetNodeId) => {
            if (targetNodeId !== null) {
              onConditionPropertiesChange({ targetNodeId });
            }
          }}
          validationError={validationErrors?.[ValidationValues.targetNodeId]}
          selectedLanguage={language}
        />
      </Grid>
      <Grid item xs={12}>
        <EmptinessQuantifierRadioGroup
          value={condition.isEmpty}
          onChange={(isEmpty) => {
            onConditionPropertiesChange({ isEmpty });
          }}
        />
        {validationErrors?.[ValidationValues.isEmpty] && (
          <ValidationErrorText>{validationErrors[ValidationValues.isEmpty].message}</ValidationErrorText>
        )}
      </Grid>
      <CollectionOperators
        collectionContext={collectionContext}
        collectionNodeIds={collectionNodeIds}
        collectionOperators={condition.collectionOperators}
        onConditionPropertiesChange={onConditionPropertiesChange}
        conditionIndex={conditionIndex}
      />
    </React.Fragment>
  );
}
