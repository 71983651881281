import Box from '@material-ui/core/Box';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Helmet } from '../../../Components/Helmet';
import { useDispatch, useSelector } from '../../../Hooks';
import { DetailViewType } from '../../../Models/Layout';
import * as ProductManagementOperations from '../../../Redux/Admin/ProductManagement/ProductManagementOperations';
import { getIsLoadingProducts, getProducts } from '../../../Redux/Admin/ProductManagement/ProductManagementSelectors';
import { layoutSlice } from '../../../Redux/Layout/LayoutSlice';
import { ProductManagementView } from './ProductManagementView';

export function ProductManagementViewContainer(): React.ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const products = useSelector(getProducts);
  const isLoadingProducts = useSelector(getIsLoadingProducts);

  useEffect(() => {
    void dispatch(ProductManagementOperations.fetchProducts());
  }, [dispatch]);

  const onOpenCreateProduct = useCallback(() => {
    void dispatch(
      layoutSlice.actions.setRightPanelState({
        rightPanelState: { isOpen: true, type: DetailViewType.create },
      })
    );
  }, [dispatch]);

  const onTableRowClick = useCallback(
    (productId?: string) => {
      if (!productId) return;
      void dispatch(ProductManagementOperations.selectProduct(productId));
    },
    [dispatch]
  );

  return (
    <Box m={1}>
      <Helmet text={t('pageTitles.productManagement')} />
      <Box mt={1}>
        <ProductManagementView
          products={products}
          isFetching={isLoadingProducts}
          onOpenCreateProduct={onOpenCreateProduct}
          onTableRowClick={onTableRowClick}
        />
      </Box>
    </Box>
  );
}
