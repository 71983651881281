import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';

import { NumericalDataType, Validations, fieldValidation } from '@breathelife/questionnaire-engine';
import { QuestionnaireBlueprintFieldTypes } from '@breathelife/types';
import { NumberInput } from '@breathelife/ui-components';

import { getValidationError } from '../../../../../../Helpers/inputValidation/getValidationError';
import { getCurrentLocale } from '../../../../../../Localization/utils';

type Props = {
  validationType?: Validations;
  onValidationErrorChange?: (validationError?: ValidationError) => void;
  fieldType: QuestionnaireBlueprintFieldTypes.number | QuestionnaireBlueprintFieldTypes.money;
  value?: number;
  onChange: (val?: number) => void;
  numericalDataType?: NumericalDataType;
  disabled: boolean;
};

export function NumberFieldDefaultValueEditor(props: Props): React.ReactElement | null {
  const locale = getCurrentLocale();
  const { fieldType, validationType, onValidationErrorChange, onChange, disabled } = props;

  const [valueToValidate, setValueToValidate] = useState<typeof props.value>(props.value);

  const { t } = useTranslation();

  const fieldValueValidation = useMemo(() => {
    if (!validationType) {
      return;
    }
    return fieldValidation(validationType, false, t);
  }, [validationType, t]);

  const validationError = useMemo(() => {
    if (!valueToValidate || !fieldValueValidation) {
      return;
    }
    return getValidationError({ defaultValue: fieldValueValidation }, 'defaultValue', valueToValidate);
  }, [fieldValueValidation, valueToValidate]);

  const firstUpdate = useRef(true);

  const updateValidationError = useCallback(
    (error?: ValidationError) => {
      if (!onValidationErrorChange) {
        return;
      }
      onValidationErrorChange(error);
    },
    [onValidationErrorChange]
  );

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    onChange();
    updateValidationError();
  }, [validationType]);

  useEffect(() => {
    updateValidationError(validationError);
  }, [validationError, updateValidationError]);

  const handleChange = useCallback(
    (val: any) => {
      if (!validationError) {
        onChange(val);
      }
    },
    [validationError, onChange]
  );

  return (
    <NumberInput
      name=''
      variant='outlined'
      inputVariant='outlined'
      disabled={disabled}
      value={props.value}
      onAnswerChange={(val) => {
        setValueToValidate(parseFloat(val.replace(/,/g, '')));
      }}
      onAnswerComplete={(_, value) => {
        try {
          handleChange(parseFloat(value.replace(/,/g, '')) || undefined);
        } catch (e) {
          // is not a number
        }
      }}
      numericalDataType={props.numericalDataType}
      isCurrency={fieldType === QuestionnaireBlueprintFieldTypes.money}
      locale={locale}
      label={t('admin.questionnaireManagement.input.defaultValue')}
      error={!!validationError}
      validationError={validationError}
    />
  );
}
