import Box from '@material-ui/core/Box';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import styled from 'styled-components';

import { CreateUserData, Permission, User, UserRowData, UserStatus } from '@breathelife/types';
import { SelectMui } from '@breathelife/ui-components';

import { UserStatusBadge } from '../../../../Components/Badge/UserStatusBadge';
import { Image } from '../../../../Components/Images/Image';
import LoadingView from '../../../../Components/LoadingView/LoadingView';
import { Pagination } from '../../../../Components/Pagination/Pagination';
import { Table } from '../../../../Components/Table/Table';
import { getOptionsFromEnum } from '../../../../Helpers/options';
import { userHasPermission } from '../../../../Helpers/user';
import { useCarrierContext, useSelector } from '../../../../Hooks';
import { UserRole } from '../../../../Services/Auth0';
import { SortParams } from '../../../../Services/Users/UsersService';
import { MoreActions } from './MoreActions';

type Props = {
  currentPage: number;
  perPage: number;
  perPageOptions: { label: number; value: number }[];
  users: User[];
  totalUsers: number;
  isLoading: boolean;
  onPageChange: (page: number) => void;
  onPerPageChange: (perPage: number) => void;
  onSortingChange: (options: SortParams) => void;
  onTableRowClick: (user?: User) => void;
  onUpdateUser: (userId: string, data: Partial<CreateUserData>) => void;
  onOpenResendInvitationModal: (user: User) => void;
  onOpenDeleteUserConfirmationModal: (user: User) => void;
};

type TableColumns = {
  Header: string;
  accessor: keyof UserRowData;
  disableSortBy?: boolean;
  Cell?: (props: CellProps<User>) => React.ReactElement | null;
}[];

const StyledSelect = styled(SelectMui)`
  max-width: 180px;
  .MuiInputBase-root {
    height: 24px;
  }
  .MuiSelect-select {
    &:focus {
      background-color: unset !important;
    }
  }
`;

export function UsersTable(props: Props): React.ReactElement {
  const {
    users,
    isLoading,
    onUpdateUser,
    onSortingChange: onSortingChangeProp,
    onTableRowClick: onTableRowClickProp,
    onOpenResendInvitationModal,
    onOpenDeleteUserConfirmationModal,
  } = props;

  const { t } = useTranslation();
  const { ssoProfileFields } = useCarrierContext();
  const currentUserPermissions = useSelector((store) => store.leadPlatform.authentication?.user?.permissions);
  const rolesOptions = getOptionsFromEnum(UserRole, 'user.role');
  if (!userHasPermission(currentUserPermissions, Permission.UserCreateSuperAdmin)) {
    _.remove(rolesOptions, (option) => option.value === UserRole.superAdmin);
  }
  const tableColumns: TableColumns = useMemo(
    () =>
      getTableColumns({
        onUpdateUser,
        onOpenDeleteUserConfirmationModal,
        onOpenResendInvitationModal,
        t,
        ssoProfileFields,
        rolesOptions,
      }),
    [onUpdateUser, onOpenDeleteUserConfirmationModal, onOpenResendInvitationModal, t, ssoProfileFields, rolesOptions]
  );

  // Set the fullName of users
  const data = _.isEmpty(users)
    ? []
    : users.map(({ firstName, lastName, ...restData }) => {
        return {
          fullName: `${firstName} ${lastName}`,
          ...restData,
        };
      });

  const onSortingChanged = useCallback(
    (columnKey: keyof UserRowData, direction: 'asc' | 'desc') => {
      if (!columnKey || !direction) return;
      onSortingChangeProp({ field: columnKey as keyof User, direction });
    },
    [onSortingChangeProp]
  );

  const onTableRowClick = useCallback(
    (userId: string) => {
      const selectedUser = users.find((user) => user.id === userId);
      onTableRowClickProp(selectedUser);
    },
    [users, onTableRowClickProp]
  );

  if (isLoading && _.isEmpty(users)) return <LoadingView />;
  return (
    <React.Fragment>
      <Table<UserRowData>
        onRowClick={onTableRowClick}
        columns={tableColumns}
        data={data}
        onOrderChange={onSortingChanged}
      />
      <Box px={2.5}>
        <Pagination
          total={props.totalUsers}
          page={props.currentPage}
          perPage={props.perPage}
          perPageOptions={props.perPageOptions}
          onPageChange={props.onPageChange}
          onPerPageChange={props.onPerPageChange}
        />
      </Box>
    </React.Fragment>
  );
}

function getTableColumns({
  onOpenResendInvitationModal,
  onOpenDeleteUserConfirmationModal,
  onUpdateUser,
  t,
  ssoProfileFields,
  rolesOptions,
}: {
  onOpenResendInvitationModal: (user: User) => void;
  onOpenDeleteUserConfirmationModal: (user: User) => void;
  onUpdateUser: (userId: string, data: Partial<CreateUserData>) => void;
  t: any;
  ssoProfileFields: any;
  rolesOptions: any;
}): TableColumns {
  return [
    {
      Header: '',
      accessor: 'picture',
      Cell: function ({ cell: { value } }: { cell: { value: string } }): React.ReactElement | null {
        return value ? <Image src={value} height={32} width={32} radius={50} /> : null;
      },
      disableSortBy: true,
    },
    {
      Header: t('usersListTable.fullName'),
      accessor: 'fullName',
    },
    {
      Header: t('usersListTable.email'),
      accessor: 'emailLogin',
    },
    {
      Header: t('usersListTable.role'),
      accessor: 'roles',
      Cell: function ({
        cell: { value, row },
      }: {
        cell: { value: UserRole[]; row: { original: User } };
      }): React.ReactElement {
        return (
          <StyledSelect
            id='user_table_role'
            onChange={(value: any, event: React.ChangeEvent<{ value: unknown }>) => {
              event.stopPropagation();
              onUpdateUser(row.original.auth0Id, { roles: [value as UserRole] });
            }}
            disabled={row.original.isSso && ssoProfileFields.includes('roles')}
            value={value?.[0] ?? ''}
            options={rolesOptions}
          />
        );
      },
    },
    {
      Header: t('usersListTable.leadsAssigned'),
      accessor: 'leadsCount',
      Cell: function ({ cell: { value } }: { cell: { value: string } }): React.ReactElement {
        return <React.Fragment>{value ?? 0}</React.Fragment>;
      },
      // Remove this once we are able to sort by computed fields
      // DEV-4869 https://breathelife.atlassian.net/browse/DEV-4869
      disableSortBy: true,
    },
    {
      Header: t('usersListTable.lastConnection'),
      accessor: 'lastLogin',
      Cell: function ({ cell: { value } }: { cell: { value: Date } }): React.ReactElement {
        return <React.Fragment>{value ? dayjs(value).format('D MMMM YYYY HH:MM A') : ''}</React.Fragment>;
      },
    },
    {
      Header: t('usersListTable.status'),
      accessor: 'status',
      Cell: function ({ cell: { value } }: { cell: { value: UserStatus } }): React.ReactElement {
        return value ? <UserStatusBadge status={value} /> : <></>;
      },
    },
    {
      Header: '',
      accessor: 'id',
      Cell: function ({ cell: { row } }: { cell: { row: { original: User } } }): React.ReactElement {
        return (
          <MoreActions
            onOpenResendInvitationModal={onOpenResendInvitationModal}
            user={row.original}
            onOpenDeleteUserConfirmationModal={onOpenDeleteUserConfirmationModal}
          />
        );
      },
    },
  ];
}
