import { EntitySelectors } from '@breathelife/insurance-entities';
import { AnswerPath, Questionnaire } from '@breathelife/questionnaire-engine';

import ApiService from './ApiService';

export type QuestionnaireResponse = {
  version: string;
  versionId: string;
  questionnaire: Questionnaire;
  nodeIdToAnswerPath?: [string, AnswerPath][];
  entitySelectors: EntitySelectors;
};

export async function fetchQuestionnaire(applicationId?: string): Promise<QuestionnaireResponse> {
  const response = await ApiService.getQuestionnaire(applicationId);
  return response.data;
}
