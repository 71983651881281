import { AxiosError } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { hash } from '@breathelife/hash';
import { getDocumentFormat, TypewriterTracking } from '@breathelife/react-tracking';
import { StoredFile, StoredFileDocType, FileTemplate } from '@breathelife/types';

import { Drawer } from '../../../../Components/Drawer/Drawer';
import { convertFileSize, FileSizeUnits, printFileSize } from '../../../../Helpers/fileSize';
import { isFileNameValid, SPECIAL_CHARS } from '../../../../Helpers/files/validateFileName';
import { useDispatch, useModalState } from '../../../../Hooks';
import { RequiredFile } from '../../../../Hooks/Application';
import { Application } from '../../../../Models/Application';
import {
  CreateApplicationFileMutateOptions,
  useCreateApplicationFileMutation,
  useDeleteApplicationFileMutation,
} from '../../../../ReactQuery/Application/application.mutations';
import { notificationSlice } from '../../../../Redux/Notification/NotificationSlice';
import { CreateApplicationFileData } from '../../../../Services/ApplicationFileService';
import { DocumentDrawerBody } from './DocumentsDrawerBody';
import { DocumentsDrawerHeader } from './DocumentsDrawerHeader';
import { ConfirmDeleteModal } from './Files/ConfirmDeleteModal';
import { UploadError } from './Files/UploadError';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  application: Application;
  onChangeMissingRequiredFiles?: (isMissingRequiredFiles: boolean) => void;
  requiredFiles: RequiredFile[];
  otherFiles: StoredFile[];
};

// Maximum upload size per file is limited to 5MB
const MAX_FILE_SIZE = 5;
const MAX_UPLOAD_SIZE = convertFileSize(MAX_FILE_SIZE, FileSizeUnits.MB, FileSizeUnits.B);

export function DocumentsDrawer(props: Props): React.ReactElement | null {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isOpen, onClose, application, onChangeMissingRequiredFiles, requiredFiles, otherFiles } = props;

  const createApplicationFileMutation = useCreateApplicationFileMutation({
    onSuccess: () => {
      dispatch(
        notificationSlice.actions.setSuccess({
          message: t('notifications.fileUploadSuccess'),
          autoHideDuration: 5000,
        })
      );
    },
    onError: (error: AxiosError<any>) => {
      dispatch(
        notificationSlice.actions.setError({
          message: t([
            `notifications.fileUploadError.${error.response?.data.data?.code}`,
            'notifications.fileUploadError.general',
          ]),
          autoHideDuration: 5000,
        })
      );
    },
  });

  const deleteApplicationFileMutation = useDeleteApplicationFileMutation({
    onSuccess: useCallback(() => {
      dispatch(
        notificationSlice.actions.setSuccess({
          message: t('notifications.fileDeleteSuccess'),
          autoHideDuration: 5000,
        })
      );
    }, [dispatch, t]),
    onError: useCallback(() => {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.fileDeleteError'),
          autoHideDuration: 5000,
        })
      );
    }, [dispatch, t]),
  });

  const [isDeleteModalOpen, onOpenDeleteModalHook, onCloseDeleteModalHook] = useModalState();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [filePendingDelete, setFilePendingDelete] = useState<{ id: string; isRequired: boolean } | undefined>(
    undefined
  );

  const onFileUploadError = useCallback(
    (file: File, errorMessage: string) => {
      // TODO: do we need a new event?
      TypewriterTracking.attachmentErrorOccurred({
        hashedId: hash(application.id),
        documentFormat: getDocumentFormat(file),
        error: errorMessage,
      });
    },
    [application.id]
  );

  const onFileUpload = useCallback(
    (file: File, template?: FileTemplate, options?: CreateApplicationFileMutateOptions) => {
      if (file.size > MAX_UPLOAD_SIZE) {
        const maxSizeLabel = printFileSize(MAX_UPLOAD_SIZE);
        setErrorMessage(
          t('modals.assistedApplication.fileAttachment.errorUploadLimitExceeded', {
            filename: file.name,
            size: maxSizeLabel,
          })
        );
        onFileUploadError(file, 'File exceeded upload size limit.');
        return;
      }

      // File templates will have the template's localized name set as the file name in the signature package
      // As opposed to the uploaded's file name
      if (!template && !isFileNameValid(file.name)) {
        setErrorMessage(
          t('modals.assistedApplication.fileAttachment.errorUploadInvalidName', {
            specialChars: SPECIAL_CHARS,
          })
        );
        onFileUploadError(file, 'Invalid file name.');
        return;
      }

      const newFileInput: CreateApplicationFileData = {
        file,
        applicationId: application.id,
        templateId: template?.id,
        docType: template?.docType ?? StoredFileDocType.Attachment,
      };
      createApplicationFileMutation.mutate(newFileInput, options);
    },
    [application.id, createApplicationFileMutation, onFileUploadError, t]
  );

  const onOpenDeleteModal = useCallback(
    (fileId: string, isRequired: boolean) => {
      setFilePendingDelete({ id: fileId, isRequired });
      onOpenDeleteModalHook();
    },
    [onOpenDeleteModalHook]
  );

  const onCloseDeleteModal = useCallback(() => {
    setFilePendingDelete(undefined);
    onCloseDeleteModalHook();
  }, [onCloseDeleteModalHook]);

  const onConfirmFileDeletion = useCallback(
    async (fileId: string) => {
      await deleteApplicationFileMutation.mutateAsync({ fileId, applicationId: application.id });
    },
    [application.id, deleteApplicationFileMutation]
  );

  const onCloseErrorAlert = useCallback(() => {
    setErrorMessage(null);
  }, []);

  useEffect(() => {
    if (!onChangeMissingRequiredFiles) return;

    const isMissingRequiredFiles = requiredFiles.some((file) => !file.storedFile && file.template.isRequired);
    onChangeMissingRequiredFiles(isMissingRequiredFiles);
  }, [onChangeMissingRequiredFiles, requiredFiles]);

  const errorAlert = <UploadError message={errorMessage} handleClose={onCloseErrorAlert} />;

  return (
    <Drawer open={isOpen} onClose={onClose}>
      <DocumentsDrawerHeader onClose={onClose} />
      <DocumentDrawerBody
        application={application}
        requiredFiles={requiredFiles}
        otherFiles={otherFiles}
        ErrorAlert={errorAlert}
        isUploading={createApplicationFileMutation.isLoading}
        isUploadDisabled={application.submitted}
        onFileUpload={onFileUpload}
        onFileDelete={onOpenDeleteModal}
      />
      <ConfirmDeleteModal
        filePendingDelete={filePendingDelete}
        onClose={onCloseDeleteModal}
        onConfirm={onConfirmFileDeletion}
        isOpen={isDeleteModalOpen}
      />
    </Drawer>
  );
}
