import { ButtonProps } from '@material-ui/core/Button';
import React from 'react';

import { StyledButton } from './Styles';

type Props = ButtonProps & { color: 'primary' | 'secondary'; startIcon?: React.ReactNode };

export function ActionButton(props: Props): JSX.Element {
  return (
    <StyledButton $color={props.color} {...props}>
      {props.children}
    </StyledButton>
  );
}
