import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { hash } from '@breathelife/hash';
import { deserializeNodeIdToAnswerPathMap } from '@breathelife/questionnaire-engine';
import { TypewriterTracking } from '@breathelife/react-tracking';
import { Language, Permission, ProductsWidgetFeatureType, SignatureType } from '@breathelife/types';
import { Loader } from '@breathelife/ui-components';

import { RestrictedToPermission } from '../../Components/Restricted/RestrictedToPermission';
import {
  shouldFetchADO,
  ShouldFetchADOParams,
  adoNodesToRefresh,
  NodesToRefreshParams,
} from '../../Helpers/assistedApplication/ado';
import { userHasPermission } from '../../Helpers/user';
import { useCarrierContext, useDispatch, useModalState, useNavigation, usePayment, useSelector } from '../../Hooks';
import { LaunchApplicationType } from '../../Models/PlatformFeatures';
import {
  useUpdateApplicationAddProposedInsuredMutation,
  useUpdateApplicationRemoveProposedInsuredMutation,
} from '../../ReactQuery/Application/application.mutations';
import { useGetApplicationQuery } from '../../ReactQuery/Application/application.queries';
import {
  useSaveAssistedApplicationAnswersMutation,
  useUpdateApplicationWithADOMinMaxMutation,
} from '../../ReactQuery/AssistedApplication/assistedApplication.mutations';
import { useFetchQuestionnaireQuery } from '../../ReactQuery/Questionnaire/questionnaire.queries';
import { QueryId } from '../../ReactQuery/common/common.types';
import { fetchAttachments } from '../../Redux/Application/ApplicationOperations';
import { applicationSlice } from '../../Redux/Application/ApplicationSlice';
import * as AssistedApplicationOperations from '../../Redux/AssistedApplication/AssistedApplicationOperations';
import { sendIdentityVerificationRequest } from '../../Redux/IdentityVerification/IdentityVerificationOperations';
import { identityVerificationSlice } from '../../Redux/IdentityVerification/IdentityVerificationSlice';
import { navigateToLeadDetail, navigateToLeadsList } from '../../Redux/Navigation/NavigationOperations';
import { notificationSlice } from '../../Redux/Notification/NotificationSlice';
import { paymentSlice } from '../../Redux/Payment/PaymentSlice';
import * as ProductsOperations from '../../Redux/Products/ProductsOperations';
import { AssistedApplicationContainer } from './AssistedApplicationContainer';
import { IdentityVerificationDrawer } from './Drawers/IdentityVerificationDrawer/IdentityVerificationDrawer';
import { FileAttachmentModalContainer } from './Modals/FileAttachment/FileAttachmentModalContainer';
import { SubmissionDetailsModal } from './Modals/SubmissionDetail/SubmissionDetailsModal';
import { SubmitApplicationModalContainer } from './Modals/SubmitApplication/SubmitApplicationModalContainer';
import { JetDecisionWidget } from './Widgets/JetDecision/JetDecisionWidget';

export function AssistedApplication(): React.ReactElement | null {
  const { leadTab, leadId, applicationId } = useNavigation();
  const { features } = useCarrierContext();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();

  const { applicationId: applicationIdFromProductStore } = useSelector((store) => store.leadPlatform.products);
  const { applicationId: applicationIdFromApplicationStore } = useSelector((store) => store.leadPlatform.application);
  const { disableTrackESignInfoModal } = useSelector((store) => store.leadPlatform.assistedApplication);
  const userPermissions = useSelector((store) => store.leadPlatform.authentication.user?.permissions ?? []);

  const { data: application } = useGetApplicationQuery(applicationId, {
    refetchOnMount: 'always',
  });

  const { isLoading: isLoadingQuestionnaire, data: questionnaireData } = useFetchQuestionnaireQuery(applicationId, {
    onError: () =>
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToFetchQuestionnaire'),
        })
      ),
  });

  const isJetDecisionWidgetEnabled = useMemo(
    () =>
      features.assistedApplication?.jetDecisionWidget?.enabled &&
      userHasPermission(userPermissions, Permission.JetDecisionWidgetView),
    [userPermissions, features.assistedApplication?.jetDecisionWidget?.enabled]
  );

  const questionnaire = questionnaireData?.questionnaire;
  const serializedNodeIdToAnswerPathMap = questionnaireData?.nodeIdToAnswerPath;
  const entitySelectors = questionnaireData?.entitySelectors;

  const isProductsWidgetTotalPremiumsEnabled =
    features.assistedApplication?.productsWidget?.enabled &&
    features.assistedApplication?.productsWidget?.type === ProductsWidgetFeatureType.totalPremiums;

  // Encapsulate payment-related hooks
  usePayment(application?.id);
  const [isInstantIdReportDrawerOpen, onOpenInstantIdReportHook, onCloseInstantIdReportDrawer] = useModalState();
  const [isSubmitApplicationModalOpen, onOpenSubmitApplicationModal, onCloseSubmitApplicationModal] = useModalState();
  const [isSubmissionDetailsModalOpen, onOpenSubmissionDetailsModal, onCloseSubmissionDetailsModal] = useModalState();
  const [isESignatureDetailsOpen, onOpenESignatureDetails, onCloseESignatureDetailsHook] = useModalState();
  const [
    isInfoMessageTrackESignatureModalOpen,
    onOpenInfoMessageTrackESignatureModal,
    onCloseInfoMessageTrackESignatureModal,
  ] = useModalState();
  const [isFileAttachmentModalOpen, onOpenFileAttachmentModalHook, onCloseFileAttachmentModal] = useModalState();

  const [proposedInsuredIndexToDelete, setProposedInsuredIndexToDelete] = useState(-1);
  const onOpenDeleteProposedInsuredModal = (index: number): void => setProposedInsuredIndexToDelete(index);
  const onCloseDeleteProposedInsuredModal = (): void => setProposedInsuredIndexToDelete(-1);

  const [hasInitiatedSubmission, setHasInitiatedSubmission] = useState(false);
  const [isMissingRequiredFiles, setIsMissingRequiredFiles] = useState(false);
  const [shouldDisableFields, setShouldDisableFields] = useState(false);
  const [shouldRefreshAnswers, setShouldRefreshAnswers] = useState(false);
  const [shouldShowADONotification, setShouldShowADONotification] = useState(false);
  const [nodesToRefresh, setNodesToRefresh] = useState<Record<string, unknown>[]>([]);
  const [currentProposedInsuredIndex, setCurrentProposedInsuredIndex] = useState(0);

  const nodeIdToAnswerPathMap = useMemo(
    () => serializedNodeIdToAnswerPathMap && deserializeNodeIdToAnswerPathMap(serializedNodeIdToAnswerPathMap),
    [serializedNodeIdToAnswerPathMap]
  );

  const signatureType = useMemo(() => {
    if (
      features.launchApplication.enabled &&
      features.launchApplication.type === LaunchApplicationType.assistedApplication
    ) {
      return features.launchApplication.signatureType;
    }
    return undefined;
  }, [features]);

  const isSubmitButtonDisabled = useMemo(
    () =>
      !application ||
      (application.submitted && application.signed) ||
      isSubmitApplicationModalOpen ||
      isLoadingQuestionnaire,
    [application, isSubmitApplicationModalOpen, isLoadingQuestionnaire]
  );

  const onOpenInstantIdReportDrawer = useCallback(() => {
    TypewriterTracking.clickedButton({
      buttonName: 'See Identity Verification report',
      hashedId: null,
    });
    onOpenInstantIdReportHook();
  }, [onOpenInstantIdReportHook]);

  const onOpenFileAttachmentModal = useCallback(() => {
    TypewriterTracking.clickedButton({
      buttonName: 'files',
      hashedId: application?.id ? hash(application.id) : null,
    });
    onOpenFileAttachmentModalHook();
  }, [application, onOpenFileAttachmentModalHook]);

  const onCloseESignatureDetails = useCallback(() => {
    onCloseESignatureDetailsHook();

    // Display track e-sign information modal if the modal is not disabled and the application has been sent for signature
    if (hasInitiatedSubmission && !disableTrackESignInfoModal) {
      onOpenInfoMessageTrackESignatureModal();
    }
  }, [
    disableTrackESignInfoModal,
    hasInitiatedSubmission,
    onCloseESignatureDetailsHook,
    onOpenInfoMessageTrackESignatureModal,
  ]);

  const triggerIdentityVerification = useCallback(async () => {
    dispatch(identityVerificationSlice.actions.setIsLoading(true));
    return await dispatch(sendIdentityVerificationRequest(application?.id));
  }, [application, dispatch]);

  const updateApplicationWithADOMinMaxMutation = useUpdateApplicationWithADOMinMaxMutation({
    onMutate: () => {
      if (shouldShowADONotification) {
        dispatch(
          notificationSlice.actions.setInfo({
            message: t('notifications.fetchingADOMinMax'),
            autoHideDuration: 3000,
          })
        );
      }
    },
    onSuccess: (data) => {
      const adoNodesToRefreshParams: NodesToRefreshParams = {
        answers: data.answers,
        featureIsEnabled: features.assistedApplication?.adoMinMax?.enabled,
        currentProposedInsuredIndex,
        nodeIdToAnswerPathMap,
      };
      setNodesToRefresh(adoNodesToRefresh(adoNodesToRefreshParams));
      if (shouldShowADONotification) {
        dispatch(
          notificationSlice.actions.setSuccess({
            message: t('notifications.updatedADOMinMax'),
            autoHideDuration: 3000,
          })
        );
      }
    },
  });

  const refreshPricingAuto = useCallback(
    async (updatedNodeIds: string[]) => {
      if (!application) return;

      const shouldRefreshProducts =
        _.isArray(features.assistedApplication?.nodeIdsAffectingProducts) &&
        _.intersection(features.assistedApplication?.nodeIdsAffectingProducts, updatedNodeIds).length > 0;

      if (shouldRefreshProducts) {
        void dispatch(ProductsOperations.fetchProducts(application.id, i18n.language as Language));
      }

      const shouldRefreshQuotes =
        _.isArray(features.assistedApplication?.nodeIdsAffectingPricing) &&
        _.intersection(features.assistedApplication?.nodeIdsAffectingPricing, updatedNodeIds).length > 0;

      if (shouldRefreshQuotes) {
        if (isProductsWidgetTotalPremiumsEnabled) {
          void queryClient.invalidateQueries([QueryId.productsWidgetTotalPremiums, application.id]);
          return;
        }

        const shouldFetchADOParams: ShouldFetchADOParams = {
          answers: application.answers,
          featureIsEnabled: features.assistedApplication?.adoMinMax?.enabled,
          updatedNodeIds,
          currentProposedInsuredIndex,
          nodeIdToAnswerPathMap,
        };
        if (shouldFetchADO(shouldFetchADOParams)) {
          setShouldShowADONotification(true);
          await updateApplicationWithADOMinMaxMutation.mutateAsync(application.id);
          setShouldShowADONotification(false);
        }
        await dispatch(AssistedApplicationOperations.fetchQuotes(application.id, application.coverageAmount));
      }
    },
    [
      application,
      features,
      dispatch,
      i18n.language,
      nodeIdToAnswerPathMap,
      currentProposedInsuredIndex,
      updateApplicationWithADOMinMaxMutation,
      isProductsWidgetTotalPremiumsEnabled,
      queryClient,
    ]
  );

  const refreshPricingManual = useCallback(async () => {
    if (!application) return;

    if (isProductsWidgetTotalPremiumsEnabled) {
      void queryClient.invalidateQueries([QueryId.productsWidgetTotalPremiums, application.id]);
      return;
    }

    if (features.assistedApplication?.adoMinMax?.enabled) {
      await updateApplicationWithADOMinMaxMutation.mutateAsync(application.id);
    }

    await dispatch(AssistedApplicationOperations.fetchQuotes(application.id, application.coverageAmount));
  }, [
    application,
    features,
    dispatch,
    updateApplicationWithADOMinMaxMutation,
    isProductsWidgetTotalPremiumsEnabled,
    queryClient,
  ]);

  const saveAssistedApplicationAnswersMutation = useSaveAssistedApplicationAnswersMutation({
    onMutate: ({ isManuallySavingAnswers }) => {
      if (isManuallySavingAnswers) {
        setShouldDisableFields(true);
      }
    },
    onSuccess: (_application, { isManuallySavingAnswers, isClosing, updatedNodeIds }) => {
      if (isManuallySavingAnswers) {
        void refreshPricingManual();
      } else if (!isClosing) {
        void refreshPricingAuto(updatedNodeIds);
      }

      if (isJetDecisionWidgetEnabled && application) {
        void queryClient.invalidateQueries([QueryId.jetDecisionOutcomes, application.id]);
      }

      void queryClient.invalidateQueries([QueryId.leads]);
    },
    onSettled(_application, _error, { isManuallySavingAnswers }) {
      if (isManuallySavingAnswers) {
        setShouldDisableFields(false);
      }
    },
  });

  const isSavingAnswers =
    saveAssistedApplicationAnswersMutation.isLoading || updateApplicationWithADOMinMaxMutation.isLoading;

  const onAfterRefreshAnswers = (): void => {
    setShouldRefreshAnswers(false);
    setShouldDisableFields(false);
    setNodesToRefresh([]);
  };

  const updateApplicationAddProposedInsuredMutation = useUpdateApplicationAddProposedInsuredMutation({
    onSuccess: (data) => {
      setShouldRefreshAnswers(true);
      setCurrentProposedInsuredIndex(data.answers.insuredPeople.length - 1);

      if (isJetDecisionWidgetEnabled && application) {
        void queryClient.invalidateQueries([QueryId.jetDecisionOutcomes, application.id]);
      }
    },
  });

  const updateApplicationRemoveProposedInsuredMutation = useUpdateApplicationRemoveProposedInsuredMutation({
    onSuccess: (application) => {
      onCloseDeleteProposedInsuredModal();
      setShouldRefreshAnswers(true);
      setCurrentProposedInsuredIndex(0);

      if (isProductsWidgetTotalPremiumsEnabled) {
        void queryClient.invalidateQueries([QueryId.productsWidgetTotalPremiums, application.id]);
      }

      if (isJetDecisionWidgetEnabled) {
        void queryClient.invalidateQueries([QueryId.jetDecisionOutcomes, application.id]);
      }
    },
  });

  const addProposedInsured = useCallback(() => {
    if (!application) return;
    updateApplicationAddProposedInsuredMutation.mutate(application.id);
  }, [application, updateApplicationAddProposedInsuredMutation]);

  const removeProposedInsured = useCallback(
    (surrogateId: string) => {
      if (!application || surrogateId === '') return;
      updateApplicationRemoveProposedInsuredMutation.mutate({ applicationId: application.id, surrogateId });
    },
    [application, updateApplicationRemoveProposedInsuredMutation]
  );

  const closeAssistedApplication = useCallback(() => {
    dispatch(applicationSlice.actions.reset());
    dispatch(paymentSlice.actions.reset());

    void queryClient.invalidateQueries([QueryId.leads]);

    if (leadId) {
      void queryClient.invalidateQueries([QueryId.lead, leadId]);
      dispatch(navigateToLeadDetail(leadId, leadTab));
    } else {
      dispatch(navigateToLeadsList(leadTab));
    }
  }, [dispatch, leadId, leadTab, queryClient]);

  useEffect(() => {
    if (!application || applicationIdFromProductStore === application.id) {
      return;
    }

    dispatch(identityVerificationSlice.actions.reset());

    if (isProductsWidgetTotalPremiumsEnabled) {
      void queryClient.invalidateQueries([QueryId.productsWidgetTotalPremiums, application.id]);
      return;
    }
    dispatch(ProductsOperations.resetProducts());
    void dispatch(ProductsOperations.fetchProducts(application.id, i18n.language as Language));
    void dispatch(ProductsOperations.fetchQuotes(application.id, application.coverageAmount));
  }, [
    application,
    applicationIdFromProductStore,
    i18n.language,
    dispatch,
    isProductsWidgetTotalPremiumsEnabled,
    queryClient,
  ]);

  useEffect(() => {
    if (!application || applicationIdFromApplicationStore === application.id) return;

    if (userHasPermission(userPermissions, Permission.ApplicationFileRead)) {
      void dispatch(fetchAttachments(application.id));
    }
  }, [application, applicationIdFromApplicationStore, dispatch, userPermissions]);

  const isLoadingProposedInsured =
    updateApplicationAddProposedInsuredMutation.isLoading || updateApplicationRemoveProposedInsuredMutation.isLoading;

  if (!application || !questionnaire || !nodeIdToAnswerPathMap) return <Loader />;

  return (
    <React.Fragment>
      <AssistedApplicationContainer
        application={application}
        saveAnswers={saveAssistedApplicationAnswersMutation.mutateAsync}
        isSavingAnswers={isSavingAnswers}
        applicationSignatureType={signatureType}
        closeAssistedApplication={closeAssistedApplication}
        isMissingRequiredFiles={isMissingRequiredFiles}
        isSubmitButtonDisabled={isSubmitButtonDisabled}
        onOpenESignatureDetails={onOpenESignatureDetails}
        onOpenSubmissionDetailsModal={onOpenSubmissionDetailsModal}
        onOpenFileAttachmentModal={onOpenFileAttachmentModal}
        onChangeMissingRequiredFiles={setIsMissingRequiredFiles}
        questionnaire={questionnaire}
        nodeIdToAnswerPathMap={nodeIdToAnswerPathMap}
        entitySelectors={entitySelectors}
        shouldDisableFields={shouldDisableFields}
        shouldRefreshAnswers={shouldRefreshAnswers}
        nodesToRefresh={nodesToRefresh}
        onAfterRefreshAnswers={onAfterRefreshAnswers}
        isESignatureDetailsOpen={isESignatureDetailsOpen}
        onCloseESignatureDetails={onCloseESignatureDetails}
        isInfoMessageTrackESignatureModalOpen={isInfoMessageTrackESignatureModalOpen}
        onCloseInfoMessageTrackESignatureModal={onCloseInfoMessageTrackESignatureModal}
        setHasInitiatedSubmission={setHasInitiatedSubmission}
        onOpenSubmitApplicationModal={onOpenSubmitApplicationModal}
        onAddProposedInsured={addProposedInsured}
        onRemoveProposedInsured={removeProposedInsured}
        currentProposedInsuredIndex={currentProposedInsuredIndex}
        onSelectProposedInsured={setCurrentProposedInsuredIndex}
        isLoadingProposedInsured={isLoadingProposedInsured}
        triggerIdentityVerification={triggerIdentityVerification}
        onOpenInstantIdReportDrawer={onOpenInstantIdReportDrawer}
        proposedInsuredIndexToDelete={proposedInsuredIndexToDelete}
        onOpenDeleteProposedInsuredModal={onOpenDeleteProposedInsuredModal}
        onCloseDeleteProposedInsuredModal={onCloseDeleteProposedInsuredModal}
        refreshPricingManual={refreshPricingManual}
      />
      <IdentityVerificationDrawer
        application={application}
        isOpen={isInstantIdReportDrawerOpen}
        onClose={onCloseInstantIdReportDrawer}
      />
      {application &&
        (signatureType === SignatureType.cryptoSignature || signatureType === SignatureType.externalSignature) && (
          <React.Fragment>
            <SubmitApplicationModalContainer
              signatureType={signatureType}
              isOpen={isSubmitApplicationModalOpen}
              closeModal={onCloseSubmitApplicationModal}
              application={application}
            />
            <SubmissionDetailsModal
              signatureType={signatureType}
              isOpen={isSubmissionDetailsModalOpen}
              closeModal={onCloseSubmissionDetailsModal}
              confirmationNumber={application.confirmationNumber}
              cryptoSignature={application.private?.signedDigest}
            />
          </React.Fragment>
        )}
      <RestrictedToPermission
        permission={[
          Permission.ApplicationFileCreate,
          Permission.ApplicationFileRead,
          Permission.ApplicationFileRemove,
        ]}
      >
        <FileAttachmentModalContainer
          isOpen={isFileAttachmentModalOpen}
          closeModal={onCloseFileAttachmentModal}
          application={application}
        />
      </RestrictedToPermission>
      {isJetDecisionWidgetEnabled && <JetDecisionWidget application={application} />}
    </React.Fragment>
  );
}
