import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';

import { ButtonName, TypewriterTracking } from '@breathelife/react-tracking';
import { Language, Permission } from '@breathelife/types';
import { Input as TextInput, PhoneInput } from '@breathelife/ui-components';

import { RestrictedToPermission } from '../../../../../Components/Restricted/RestrictedToPermission';
import {
  PostalCodeInputMui as PostalCodeInput,
  ZipCodeInputMui as ZipCodeInput,
} from '../../../../../Components/TextInputs';
import { getFieldValidationError, getFormSchema } from '../../../../../Helpers/inputValidation/form/lead';
import { useCarrierContext, useDispatch } from '../../../../../Hooks';
import { DetailViewType } from '../../../../../Models/Layout';
import { Lead } from '../../../../../Models/Lead';
import { useCreateLeadMutation, useUpdateLeadMutation } from '../../../../../ReactQuery/Lead/lead.mutations';
import { layoutSlice } from '../../../../../Redux/Layout/LayoutSlice';
import { navigateToLeadDetail } from '../../../../../Redux/Navigation/NavigationOperations';
import { ContentHeading } from '../Styles';
import { DateText } from './DateText';
import { CaProvinceDropdown, CtaButton, LanguageDropdown, UsStateDropdown } from './Styles';
import { SubscriptionToolLink } from './SubscriptionToolLink';

export type Props = {
  lead?: Lead;
  onClose: () => void;
  detailViewType: DetailViewType;
};

type FormState = {
  value: string;
  error?: ValidationError;
};

const defaultFormState: FormState = {
  value: '',
};

export function PersonalInfoWriteView(props: Props): React.ReactElement {
  const { lead, detailViewType, onClose } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { countryCode, languageSettings, unsubToolUrl, availablePlatformLanguages } = useCarrierContext();

  const [firstName, setFirstName] = useState<FormState>({
    ...defaultFormState,
  });
  const [lastName, setLastName] = useState<FormState>({
    ...defaultFormState,
  });
  const [email, setEmail] = useState<FormState>({
    ...defaultFormState,
  });
  const [phoneNumber, setPhoneNumber] = useState<FormState>({
    ...defaultFormState,
  });
  const [postalCode, setPostalCode] = useState<FormState>({
    ...defaultFormState,
  });
  const [region, setRegion] = useState<string>('');
  const [communicationLanguage, setCommunicationLanguage] = useState<Language>(languageSettings.default);

  useEffect(() => {
    if (!lead) return;
    setFirstName({
      ...defaultFormState,
      value: lead.firstName ?? '',
    });
    setLastName({
      ...defaultFormState,
      value: lead.lastName ?? '',
    });
    setEmail({
      ...defaultFormState,
      value: lead.email ?? '',
    });
    setPhoneNumber({
      ...defaultFormState,
      value: lead.phoneNumber ?? '',
    });
    setPostalCode({
      ...defaultFormState,
      value: lead.postalCode ?? '',
    });
    setRegion(lead.region ?? '');
    setCommunicationLanguage((previousLanguage) => lead.communicationLanguage ?? previousLanguage);
  }, [lead]);

  const createLeadMutation = useCreateLeadMutation({
    onMutate: () => {
      TypewriterTracking.clickedButton({ buttonName: ButtonName.leadNewProfileSave, hashedId: null });
    },
    onSuccess: (createdLead) => {
      dispatch(navigateToLeadDetail(createdLead.id));
      dispatch(layoutSlice.actions.updateRightPanelState({ type: DetailViewType.read }));
    },
  });

  const updateLeadMutation = useUpdateLeadMutation({
    onMutate: () => {
      TypewriterTracking.clickedButton({ buttonName: ButtonName.leadEditProfileSave, hashedId: null });
    },
    onSuccess: () => {
      dispatch(layoutSlice.actions.updateRightPanelState({ type: DetailViewType.read }));
    },
  });

  const formOptions = { countryCode };
  const isFormValid = getFormSchema(formOptions).isValidSync({
    firstName: firstName.value,
    lastName: lastName.value,
    email: email.value,
    phoneNumber: phoneNumber.value,
    postalCode: postalCode.value,
    region,
    communicationLanguage,
  });
  const isSaveBtnDisabled = !isFormValid || createLeadMutation.isLoading || updateLeadMutation.isLoading;

  function onSaveClick(): void {
    if (isSaveBtnDisabled) return;

    const newLeadInfo = {
      id: lead?.id,
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      phoneNumber: !_.isEmpty(phoneNumber.value) ? phoneNumber.value : undefined,
      postalCode: !_.isEmpty(postalCode.value) ? postalCode.value : undefined,
      region: !_.isEmpty(region) ? region : undefined,
      communicationLanguage,
    };

    if (detailViewType === DetailViewType.create) {
      createLeadMutation.mutate(newLeadInfo);
    } else {
      updateLeadMutation.mutate(newLeadInfo);
    }
  }

  function onCancelClick(): void {
    if (detailViewType === DetailViewType.create) {
      onClose();
      TypewriterTracking.clickedButton({ buttonName: ButtonName.leadNewProfileCancel, hashedId: null });
    } else {
      dispatch(layoutSlice.actions.updateRightPanelState({ type: DetailViewType.read }));
      TypewriterTracking.clickedButton({ buttonName: ButtonName.leadEditProfileCancel, hashedId: null });
    }
  }

  return (
    <React.Fragment>
      <ContentHeading py={2} px={2.5}>
        <DateText lead={lead} />

        <RestrictedToPermission permission={Permission.LeadCreate}>
          <div>
            <Box component='span' mr={1}>
              <CtaButton color='primary' variant='outlined' onClick={onCancelClick}>
                {t('cta.cancel')}
              </CtaButton>
            </Box>

            <CtaButton
              data-testid='personalInfoWriteView-save'
              color='primary'
              variant='contained'
              disabled={isSaveBtnDisabled}
              onClick={onSaveClick}
            >
              {t('cta.save')}
            </CtaButton>
          </div>
        </RestrictedToPermission>
      </ContentHeading>

      <Box p={3}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextInput
              required
              fullWidth
              inputVariant='outlined'
              id='personalInfoWriteView-fname'
              label={t('inputs.firstName')}
              error={!!firstName.error}
              validationError={firstName.error}
              value={firstName.value}
              onChange={(event) =>
                setFirstName({
                  value: event.target.value,
                  error: getFieldValidationError('firstName', event.target.value, formOptions),
                })
              }
              onBlur={() => {
                setFirstName((prevState) => ({
                  value: prevState.value.trim(),
                  error: getFieldValidationError('firstName', prevState.value, formOptions),
                }));
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextInput
              required
              fullWidth
              id='personalInfoWriteView-lname'
              inputVariant='outlined'
              label={t('inputs.lastName')}
              error={!!lastName.error}
              validationError={lastName.error}
              value={lastName.value}
              onChange={(event) =>
                setLastName({
                  value: event.target.value,
                  error: getFieldValidationError('lastName', event.target.value, formOptions),
                })
              }
              onBlur={() => {
                setLastName((prevState) => ({
                  value: prevState.value.trim(),
                  error: getFieldValidationError('lastName', prevState.value, formOptions),
                }));
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <LanguageDropdown
                  required
                  data-testid='personalInfoWriteView-communicationLanguage'
                  value={communicationLanguage}
                  id='personalInfoWriteView-communicationLanguage'
                  label={t('inputs.language')}
                  onChange={setCommunicationLanguage}
                  enabledLanguages={availablePlatformLanguages || languageSettings.enabledLanguages}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextInput
                  required
                  id='personalInfoWriteView-email'
                  inputVariant='outlined'
                  error={!!email.error}
                  validationError={email.error}
                  label={t('inputs.email')}
                  value={email.value}
                  onChange={(event) =>
                    setEmail({
                      value: event.target.value,
                      error: getFieldValidationError('email', event.target.value, formOptions),
                    })
                  }
                  onBlur={() => {
                    setEmail((prevState) => ({
                      value: prevState.value.trim(),
                      error: getFieldValidationError('email', prevState.value, formOptions),
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                {unsubToolUrl && <SubscriptionToolLink url={unsubToolUrl} />}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <PhoneInput
              fullWidth
              name='phone'
              inputVariant='outlined'
              label={t('inputs.phoneNumber')}
              id='personalInfoWriteView-phoneNumber'
              data-testid='personalInfoWriteView-phoneNumber'
              validationError={phoneNumber.error}
              value={phoneNumber.value}
              onAnswerChange={(answer: string) =>
                setPhoneNumber({
                  value: answer,
                  error: getFieldValidationError('phoneNumber', answer, formOptions),
                })
              }
            />
          </Grid>
          <Grid item xs={6} />

          {countryCode === 'US' ? (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <ZipCodeInput
                    fullWidth
                    id='personalInfoWriteView-zipCode'
                    label={t('inputs.zipCode')}
                    value={postalCode.value}
                    validationError={postalCode.error}
                    onChange={(value: string) =>
                      setPostalCode({ value, error: getFieldValidationError('postalCode', value, formOptions) })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <UsStateDropdown
                    data-testid='personalInfoWriteView-state'
                    value={region}
                    id='personalInfoWriteView-state'
                    label={t('inputs.state')}
                    onChange={setRegion}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <PostalCodeInput
                    fullWidth
                    id='personalInfoWriteView-postalCode'
                    label={t('inputs.postalCode')}
                    value={postalCode.value}
                    validationError={postalCode.error}
                    onChange={(value: string) =>
                      setPostalCode({ value, error: getFieldValidationError('postalCode', value, formOptions) })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <CaProvinceDropdown
                    data-testid='personalInfoWriteView-province'
                    value={region}
                    id='personalInfoWriteView-province'
                    label={t('inputs.province')}
                    onChange={setRegion}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </React.Fragment>
  );
}
