import { ESignFieldSetting, ESignFieldSettingPlacementType, ESignFieldSettingType } from '@breathelife/types';

import ApiService from './ApiService';

// write

export type CreateESignFieldSettingData = Omit<ESignFieldSetting, 'id'>;

export async function createESignFieldSetting(data: CreateESignFieldSettingData): Promise<ESignFieldSetting> {
  const response = await ApiService.createESignFieldSetting(data);
  return response.data;
}

export async function updateESignFieldSetting(
  id: string,
  data: Partial<Omit<ESignFieldSetting, 'id' | 'signerSettingId'>>
): Promise<ESignFieldSetting> {
  const response = await ApiService.updateESignFieldSetting(id, data);
  return response.data;
}

export async function deleteESignFieldSetting(id: string): Promise<ESignFieldSetting> {
  const response = await ApiService.deleteESignFieldSetting(id);
  return response.data;
}

// read
export async function getESignFieldSetting(id: string): Promise<ESignFieldSetting> {
  // TODO in progress – getters moved to fileTemplateRecipient endpoint
  return {
    id,
    signerSettingId: 'temp',
    type: ESignFieldSettingType.SIGNATURE,
    placementType: ESignFieldSettingPlacementType.ABSOLUTE,
    fieldBinding: 'temp',
    top: 0,
    left: 0,
    width: 150,
    height: 50,
    page: 0,
  };
}

export async function findESignFieldSettings(params: { signerSettingId: string }): Promise<ESignFieldSetting[]> {
  // const response = await ApiService.findESignFieldSettings(params);
  // TODO in progress – getters moved to fileTemplateRecipient endpoint
  const { signerSettingId } = params;

  return [
    {
      id: 'test',
      signerSettingId,
      type: ESignFieldSettingType.SIGNATURE,
      placementType: ESignFieldSettingPlacementType.ABSOLUTE,
      fieldBinding: 'temp',
      top: 0,
      left: 0,
      width: 150,
      height: 50,
      page: 0,
    },
  ];
}
