import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { EntityMappingContext } from './EntityMappingContextProvider';
import { MonacoCodeEditor } from './MonacoCodeEditor';

const NoConfigurationSelectedInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: ${(props) => props.theme.colors.grey[30]};
`;

export function EntityMappingCodeEditor(): React.ReactElement {
  const {
    canEditCode,
    setRetrieveUpdatedCodeFunction,
    savedEntityMapping,
    setMappingCodeHasChanged,
    entityMappingIsSelected,
  } = useContext(EntityMappingContext);
  const { t } = useTranslation();

  if (!entityMappingIsSelected) {
    return (
      <NoConfigurationSelectedInfoWrapper>
        <p>{t('admin.entityMappings.selectEntityToEditInfo')}</p>
      </NoConfigurationSelectedInfoWrapper>
    );
  }
  return (
    <MonacoCodeEditor
      readOnly={!canEditCode}
      onType={() => setMappingCodeHasChanged(true)}
      setRetrieveCurrentEditorValueFunction={setRetrieveUpdatedCodeFunction}
      initialCode={savedEntityMapping?.customCode}
    />
  );
}
