import React from 'react';
import { useTranslation } from 'react-i18next';

import { Permission, ApplicationMode } from '@breathelife/types';

import NewApplicationImage from '../../../../../Assets/NA-empty.svg';
import { ActionButton } from '../../../../../Components/Button/ActionButton';
import { LaunchApplicationMenuButton } from '../../../../../Components/LaunchApplicationMenuButton/LaunchApplicationMenuButton';
import { RestrictedToPermission } from '../../../../../Components/Restricted/RestrictedToPermission';
import { useCarrierContext } from '../../../../../Hooks';
import { Image, LaunchApplicationContainer } from './Styles';

type NewApplicationViewProps = {
  isCreatingApplication: boolean;
  onLaunchAssistedApplication: (mode: ApplicationMode) => void;
};

export function NewApplicationView(props: NewApplicationViewProps): React.ReactElement {
  const { features } = useCarrierContext();
  const { t } = useTranslation();
  const { isCreatingApplication, onLaunchAssistedApplication } = props;
  return (
    <LaunchApplicationContainer>
      <Image src={NewApplicationImage} alt='' />
      {features.leadCreation.enabled &&
        (features.questionnaireScreen?.enabled ? (
          <RestrictedToPermission permission={Permission.ApplicationLaunchMe}>
            <LaunchApplicationMenuButton
              onCreateApplication={onLaunchAssistedApplication}
              isCreatingApplication={isCreatingApplication}
            />
          </RestrictedToPermission>
        ) : (
          <ActionButton
            color='primary'
            variant='contained'
            onClick={() => onLaunchAssistedApplication(ApplicationMode.digital)}
            disabled={isCreatingApplication}
            data-testid='launchApplication'
          >
            {t('cta.launchApplication')}
          </ActionButton>
        ))}
    </LaunchApplicationContainer>
  );
}
