import Box from '@material-ui/core/Box';
import styled from 'styled-components';

export const CoverageAmountContainer = styled(Box)`
  color: ${(props) => props.theme.colors.primary.default};
`;

export const PremiumContainer = styled(Box)`
  color: ${(props) => props.theme.colors.primary.default};
`;

export const ProductSummaryContainer = styled(Box)`
  border: ${(props) => `1px solid ${props.theme.colors.grey[30]}`};

  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.07);
`;

export const ProductInfoContainer = styled(Box)`
  background-color: ${(props) => props.theme.colors.tertiary[30]};
`;
