import Box from '@material-ui/core/Box';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserStatus } from '@breathelife/types';

import { Select } from '../../../../Components/Select/Select';
import { SearchTextField } from '../../../../Components/TextInputs';
import { getOptionsFromEnum } from '../../../../Helpers/options';

export type Filters = {
  status: string;
};

type UsersTableHeaderProps = {
  onSearchChange: (search: string) => void;
  onFiltersChanged: (filters: Filters) => void;
};

export function UsersTableHeader(props: UsersTableHeaderProps): React.ReactElement {
  const { onFiltersChanged, onSearchChange } = props;
  const { t } = useTranslation();
  const [filtersValue, setFiltersValue] = useState<Filters>({ status: '' });

  const onStatusFilterChange = useCallback(
    (value: string) => {
      const newFiltersValue = { ...filtersValue, status: value };
      setFiltersValue(newFiltersValue);
      onFiltersChanged(newFiltersValue);
    },
    [filtersValue, onFiltersChanged]
  );

  const options = useMemo(() => {
    const emptyOption = [{ value: '', label: t('usersListTable.statusFilter') }];
    return emptyOption.concat(getOptionsFromEnum(UserStatus, 'user.status'));
  }, [t]);

  return (
    <Box display='flex'>
      <SearchTextField onSearchChange={onSearchChange} />
      <Box ml={2}>
        <Select
          id='status-select'
          value={filtersValue.status}
          options={options}
          onChange={onStatusFilterChange}
          displayEmpty
        />
      </Box>
    </Box>
  );
}
