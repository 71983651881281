import { AxiosResponse } from 'axios';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { usePolling } from '@breathelife/react-hooks';

import { CarrierContext } from '../../../../../Context/CarrierContext';
import { useDispatch, useNavigation } from '../../../../../Hooks';
import { notificationSlice } from '../../../../../Redux/Notification/NotificationSlice';
import ApiService from '../../../../../Services/ApiService';

const DEFAULT_PDF_FETCH_DURATION_IN_SECONDS = 30;

type EmptyPdfDownloadHandlerProps = {
  onSuccess: (pdfUrl: string) => void;
  onFailure: () => void;
};

export function EmptyPdfDownloadHandler(props: EmptyPdfDownloadHandlerProps): React.ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { onSuccess, onFailure } = props;

  const { applicationId } = useNavigation();

  const { pdfFetchDuration } = useContext(CarrierContext);
  const pdfFetchDurationMs = (pdfFetchDuration || DEFAULT_PDF_FETCH_DURATION_IN_SECONDS) * 1000;
  const pollingIntervalMs = 5000;
  const retryCount = Math.trunc(pdfFetchDurationMs / pollingIntervalMs);

  const onFetch = useCallback((): Promise<AxiosResponse> => {
    if (!applicationId) throw new Error('Application id is missing.');
    return ApiService.fetchApplicationPdf(applicationId, { polling: '1' });
  }, [applicationId]);

  const onSuccessfulPolling = useCallback(
    (response: AxiosResponse<{ url: string }>): boolean => {
      const pdfUrl = response.data.url;
      const hasPdfUrl = !!pdfUrl;
      if (hasPdfUrl) {
        window.open(pdfUrl);
        onSuccess(pdfUrl);
      }
      return hasPdfUrl;
    },
    [onSuccess]
  );

  const onFailedFetching = useCallback((): void => {
    dispatch(
      notificationSlice.actions.setError({
        message: t('notifications.failedToFetchPdfUrl'),
      })
    );
    onFailure();
  }, [dispatch, t, onFailure]);

  usePolling<{ url: string }>({
    onFetch: onFetch,
    interval: pollingIntervalMs,
    retryCount,
    onSuccess: onSuccessfulPolling,
    onFailure: onFailedFetching,
  });

  return <div></div>;
}
