import i18next from 'i18next';

import { AuditLogsRowData, PageQueryOptions } from '@breathelife/types';

import { notificationSlice } from '../../Redux/Notification/NotificationSlice';
import { Dispatch } from '../../Redux/types';
import ApiService from '../../Services/ApiService';
import { logsAdminToolsSlice } from './LogsAdminToolsSlice';

export const fetchLogsAdminTools =
  (
    query: { resourceName?: string; resourceId?: string; actionType?: string },
    queryOptions: PageQueryOptions<AuditLogsRowData>
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(logsAdminToolsSlice.actions.setIsLoading({ isLoading: true }));

    try {
      const response = await ApiService.fetchLogsAdminTools(query, queryOptions);
      const { data: logs, total } = response.data;

      dispatch(logsAdminToolsSlice.actions.setLogs({ logs, total }));
    } catch (error) {
      const entity = i18next.t('admin.auditLogs.logEntity.plural');
      notificationSlice.actions.setError({
        message: i18next.t('notifications.failedToFetchEntity', { entity }),
      });
    } finally {
      dispatch(logsAdminToolsSlice.actions.setIsLoading({ isLoading: false }));
    }
  };
