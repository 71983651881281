import Box from '@material-ui/core/Box';
import MuiPagination from '@material-ui/lab/Pagination';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SelectMui } from '@breathelife/ui-components';

import Typography from '../../Components/Typography';
import { PAGINATION_HEIGHT } from '../../Models/Layout';
import { PerPageContainer } from './Styles';

export const TablePagination = styled(MuiPagination)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${PAGINATION_HEIGHT};
`;

type Props = {
  total: number;
  perPage: number;
  page: number;
  perPageOptions: any[];
  onPageChange: (page: number) => void;
  onPerPageChange: (perPage: number) => void;
};

export function Pagination(props: Props): React.ReactElement {
  const { t } = useTranslation();
  return (
    <Box position='relative'>
      <TablePagination
        count={_.ceil(props.total / props.perPage)}
        page={props.page}
        //eslint-disable-next-line @typescript-eslint/naming-convention
        onChange={(_, page) => props.onPageChange(page)}
      />

      <PerPageContainer>
        <Typography variant='body3' grey={70}>
          {props.total} {t('pagination.results')} | &nbsp;
        </Typography>
        <Typography variant='body3' grey={70}>
          {t('pagination.perPage')}
        </Typography>
        <Box ml={1}>
          <SelectMui
            id='tables_perPage'
            onChange={(value) => props.onPerPageChange(value)}
            value={props.perPage}
            options={props.perPageOptions}
          />
        </Box>
      </PerPageContainer>
    </Box>
  );
}
