import _ from 'lodash';

import { InsuranceProduct, LocalizedInsuranceProduct, QuotedInsuranceProduct } from '@breathelife/types';

import { LegacyProductInfo, QuotedProduct } from '../../Models/Product';
import { LeadPlatformStore } from '../../Redux/types';

export const getUniqueProductListWithEligibility = (
  state: LeadPlatformStore
): Array<LegacyProductInfo | LocalizedInsuranceProduct> => {
  const { products } = state.leadPlatform.products;

  const allProductsById = _.groupBy(products as Array<LegacyProductInfo | LocalizedInsuranceProduct>, 'productId');
  const uniqueProductById = _.mapValues(allProductsById, (productsForId) => {
    const firstEligibleProduct = productsForId.find((product) => product.isEligible);
    return firstEligibleProduct || productsForId[0];
  });
  return _.values(uniqueProductById);
};

export const getQuotedProducts = (state: LeadPlatformStore): Array<QuotedProduct | QuotedInsuranceProduct> => {
  const { quotes } = state.leadPlatform.products;
  const products = getUniqueProductListWithEligibility(state);

  if (!quotes || !quotes.quotePerProduct) return products as QuotedProduct[];

  return products.map<QuotedProduct | QuotedInsuranceProduct>((productInfo) => {
    const premium = quotes.quotePerProduct[productInfo.id];
    return {
      ...productInfo,
      premium,
    };
  });
};

type AvailableProductsAndRiders = {
  availableParents: InsuranceProduct[];
  availableRiders: InsuranceProduct[];
};

export const getLinkableProductsForTheSelectedProduct =
  (language: string) =>
  (store: LeadPlatformStore): AvailableProductsAndRiders => {
    const availableRiders: InsuranceProduct[] = [];
    const availableParents: InsuranceProduct[] = [];

    const products = store.leadPlatform.productManagement.products;
    const selectedProduct = store.leadPlatform.productManagement.selectedProduct;

    for (const product of products) {
      if (selectedProduct && selectedProduct.id === product.id) {
        continue;
      }

      if (product.isRider) {
        availableRiders.push(product);
      } else {
        availableParents.push(product);
      }
    }

    const alphabetically = (a: InsuranceProduct, b: InsuranceProduct): number => {
      return a.name[language].localeCompare(b.name[language], language, { sensitivity: 'base' });
    };

    return {
      availableParents: availableParents.sort(alphabetically),
      availableRiders: availableRiders.sort(alphabetically),
    };
  };
