import i18next from 'i18next';
import * as yup from 'yup';

import { BaseInsuranceProductCoverageAmountRange, CoverageRangeCreateOrUpdateRequest } from '@breathelife/types';

import { objectSchemaFieldsAreDefined, RecursiveNullable } from '../typeGuards';

export const coverageAmountRangesSchema = yup.object().shape({
  minimumAge: yup
    .number()
    .nullable()
    .min(0, i18next.t('validation.birthDate.min'))
    .max(130, i18next.t('validation.birthDate.max')),
  maximumAge: yup
    .number()
    .nullable()
    .min(0, i18next.t('validation.birthDate.min'))
    .max(130, i18next.t('validation.birthDate.max')),
  minimumCoverageAmount: yup.number().nullable(),
  maximumCoverageAmount: yup.number().nullable(),
  minimumSalary: yup.number().nullable(),
});

export function validateCoverageAmountRanges(
  coverageAmountRanges: RecursiveNullable<BaseInsuranceProductCoverageAmountRange[]>
): boolean {
  return (
    coverageAmountRanges === null ||
    coverageAmountRanges === undefined ||
    coverageAmountRanges.length === 0 ||
    coverageAmountRanges.every(
      (range: RecursiveNullable<BaseInsuranceProductCoverageAmountRange>) =>
        range === null ||
        range === undefined ||
        Object.keys(range).some((property) => typeof range[property] === 'number')
    )
  );
}

export function getCoverageAmounRangesFieldsError(
  fieldName: keyof CoverageRangeCreateOrUpdateRequest,
  value: unknown
): yup.ValidationError | undefined {
  if (!objectSchemaFieldsAreDefined(coverageAmountRangesSchema)) return;

  try {
    coverageAmountRangesSchema.fields[fieldName].validateSync(value);
  } catch (error) {
    return error;
  }
  return;
}
