import React from 'react';
import { useTranslation } from 'react-i18next';

import { GhostButton } from '../../../../../Components/Button/GhostButton';
import { useCarrierContext } from '../../../../../Hooks';
import { Lead } from '../../../../../Models/Lead';

type NeedsAnalysisButtonProps = {
  lead?: Lead;
  onViewNeedsAnalysisDetails: () => void;
  onSendNeedsAnalysis: () => void;
};

export function NeedsAnalysisButton(props: NeedsAnalysisButtonProps): React.ReactElement {
  const { t } = useTranslation();
  const { isEmailActionEnabled } = useCarrierContext();
  const { lead, onViewNeedsAnalysisDetails, onSendNeedsAnalysis } = props;
  const leadStatus = lead?.status ?? 'new';
  const isLeadStatusNew = leadStatus === 'new';
  const isLeadStatusInvited = leadStatus === 'invited';
  const isNeedsAnalysisStarted = !isLeadStatusNew && !isLeadStatusInvited;

  if (isNeedsAnalysisStarted) {
    return (
      <GhostButton buttonSize='small' color='primary' onClick={onViewNeedsAnalysisDetails}>
        {t('cta.needAnalysisDetails')}
      </GhostButton>
    );
  }

  const isSendNeedsAnalysisButtonEnabled = !!lead?.assignedToId && isEmailActionEnabled(lead.status);
  return (
    <GhostButton
      buttonSize='small'
      color='primary'
      onClick={onSendNeedsAnalysis}
      disabled={!isSendNeedsAnalysisButtonEnabled}
    >
      {isLeadStatusNew && t('cta.sendNeedsAnalysis')}
      {isLeadStatusInvited && t('cta.resendNeedsAnalysis')}
    </GhostButton>
  );
}
