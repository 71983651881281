import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { HeaderButton } from '../../Pages/Admin/Questionnaire/QuestionnaireEditor/Components/HeaderButton';
import { ExportCtx } from './ExportDataProvider';

export function ExportButton(): React.ReactElement {
  const { isLoading, onExportButtonClick } = React.useContext(ExportCtx);
  const { t } = useTranslation();
  const tooltipTitle = t('admin.exportData.tooltip');

  return (
    <HeaderButton onClick={onExportButtonClick} disabled={isLoading}>
      <Tooltip title={tooltipTitle}>
        <GetAppIcon />
      </Tooltip>
    </HeaderButton>
  );
}
