import Box from '@material-ui/core/Box';
import FileIcon from '@material-ui/icons/Description';
import styled from 'styled-components';

import Typography from '../../../../../Components/Typography';

export const NoFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10rem 0;
`;

export const NoFileIcon = styled(FileIcon)`
  &&& {
    font-size: 140px;
    background: #efefef;
    color: grey;
    padding: 20px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
`;

export const DisplayNameTypography = styled(Typography)`
  text-overflow: ellipsis;
`;

export const SubtitleContainer = styled(Box)`
  color: ${(props) => props.theme.colors.grey[70]};
`;
