import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Alert from '@material-ui/lab/Alert';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ESignSigner2FAInfo } from '@breathelife/types';

import { Tabs, TabsContainer, TabType } from '../../../../../Components/Tabs/Tabs';
import { useDispatch, useSelector, useSigners } from '../../../../../Hooks';
import { RequiredFile } from '../../../../../Hooks/Application';
import { Application } from '../../../../../Models/Application';
import { assistedApplicationSlice } from '../../../../../Redux/AssistedApplication/AssistedApplicationSlice';
import { DocumentToSign } from './DocumentToSign';
import { SignerInfo } from './SignerInfo';
import { SignerInfoForm } from './SignerInfoForm';

type SignerInfoViewProps = {
  isEditMode: boolean;
  isLoading: boolean;
  application: Application;
  onUpdateSigner: (signer: ESignSigner2FAInfo, idx: number) => void;
  requiredFiles: RequiredFile[];
  signers: ESignSigner2FAInfo[];
};

/**
 * Legacy `SignerInfoView` component that doesn't utilize the participants processor
 * service to fetch signers.
 */
export function SignerInfoViewLegacy(props: SignerInfoViewProps): React.ReactElement | null {
  const { application, isEditMode, isLoading, onUpdateSigner, requiredFiles } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [signers] = useSigners();

  const { isDocumentSignInfoBoxOpen } = useSelector((state) => state.leadPlatform.assistedApplication);

  const [tabValue, setTabValue] = useState(0);

  const tabs: TabType[] = [
    { label: t('assistedApplication.signatureDrawer.signersInformation') },
    { label: t('assistedApplication.signatureDrawer.documentsToSign') },
  ];

  const handleTabChange = useCallback((_, newValue: number) => {
    setTabValue(newValue);
  }, []);

  const closeInfoBox = useCallback(() => {
    void dispatch(assistedApplicationSlice.actions.setIsDocumentSignInfoBoxOpen(false));
  }, [dispatch]);

  return (
    <Box>
      <TabsContainer borderBottom={1} borderTop={1}>
        <Box px={4}>
          <Tabs value={tabValue} onChange={handleTabChange} tabs={tabs} />
        </Box>
      </TabsContainer>

      <Box py={2}>
        {tabValue === 0 && (
          <React.Fragment>
            {signers &&
              signers.map((signer, idx) => (
                <Box key={`signer-${idx}`} mt={4}>
                  {!isEditMode ? (
                    <SignerInfo signer={signer} />
                  ) : (
                    <SignerInfoForm
                      disabled={isLoading}
                      signer={signer}
                      onUpdateSigner={(signer) => onUpdateSigner(signer, idx)}
                    />
                  )}
                </Box>
              ))}
          </React.Fragment>
        )}
        {tabValue === 1 && (
          <React.Fragment>
            <Collapse in={isDocumentSignInfoBoxOpen}>
              <Alert onClose={closeInfoBox} severity='info'>
                {t('assistedApplication.signatureDrawer.infoBoxMessage')}
              </Alert>
            </Collapse>

            <Box py={2}>
              {requiredFiles.map((file) => (
                <DocumentToSign
                  key={file.template.id}
                  application={application}
                  template={file.template}
                  storedFile={file.storedFile}
                />
              ))}
            </Box>
          </React.Fragment>
        )}
      </Box>
    </Box>
  );
}
