import React from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';

import { AgentLicenseStatus, LicensesTableRowData } from '@breathelife/types';

import { LicenseStatusBadge } from '../../../Components/Badge/LicenseStatusBadge';
import { Table } from '../../../Components/Table/Table';
import { licensesToTableData } from '../../../Helpers/licensesTableMapping';
import { useSelector } from '../../../Hooks';
import { ModalLayout } from '../../../Layouts/Modal/ModalLayout';
import { useGetAgentLicensesQuery } from '../../../ReactQuery/AgentLicense/agentLicense.queries';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

type LicensesTableColumns = {
  Header: string;
  accessor: keyof LicensesTableRowData;
  disableSortBy?: boolean;
  Cell?: (props: CellProps<any>) => React.ReactElement | null;
}[];

export function AgentLicensesModal(props: Props): React.ReactElement | null {
  const { t } = useTranslation();
  const authentication = useSelector((store) => store.leadPlatform.authentication);

  const { closeModal: onClose, isOpen } = props;

  const { data: licensesData = [] } = useGetAgentLicensesQuery(authentication.user?.customId);

  const licensesTableColumns: LicensesTableColumns = [
    {
      Header: t('modals.agentLicenses.provinceOrState'),
      accessor: 'stateOrProvince',
    },
    {
      Header: t('modals.agentLicenses.status'),
      accessor: 'status',
      Cell: function ({ cell: { value } }: { cell: { value: AgentLicenseStatus } }): React.ReactElement {
        return value ? <LicenseStatusBadge status={value} /> : <React.Fragment />;
      },
    },
    {
      Header: t('modals.agentLicenses.expirationDate'),
      accessor: 'expirationDate',
    },
    {
      Header: t('modals.agentLicenses.type'),
      accessor: 'licenseType',
    },
  ];

  return (
    <ModalLayout
      maxWidth='lg'
      isOpen={isOpen}
      closeModal={onClose}
      title={t('modals.agentLicenses.title')}
      hideCancel
      data-testid='AgentLicensesModal'
    >
      <Table<LicensesTableRowData> data={licensesToTableData(licensesData)} columns={licensesTableColumns} />
    </ModalLayout>
  );
}
