import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/AddRounded';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CreateUserData, Permission, User } from '@breathelife/types';

import { ActionButton } from '../../../Components/Button/ActionButton';
import { ListFilterContainer } from '../../../Components/ListFilterContainer/ListFilterContainer';
import { RestrictedToPermission } from '../../../Components/Restricted/RestrictedToPermission';
import { ModalType } from '../../../Models/Layout';
import { ImportUsersModal } from '../../../Pages/Admin/UserManagement/ImportUsersModal/ImportUsersModal';
import { SortParams } from '../../../Services/Users/UsersService';
import { ConfirmDeleteModal } from './ConfirmDeleteModal/ConfirmDeleteModal';
import { SendInvitationEmailModal } from './SendInvitationEmailModal/SendInvitationEmailModal';
import UserDetailContainer from './UserDetailView/UserDetailContainer';
import { EmptySearchResultView } from './UsersTable/EmptySearchResultView';
import UsersTable from './UsersTable/UsersTableContainer';
import { Filters, UsersTableHeader } from './UsersTable/UsersTableHeader';

type Props = {
  onSearchChange: (search: string) => void;
  onFiltersChanged: (filters?: Filters) => void;
  onOpenCreateUser: () => void;
  onPageChange: (page: number) => void;
  onPerPageChange: (perPage: number) => void;
  onSortingChanged: (sort: SortParams) => void;
  onTableRowClick: (data?: User) => void;
  onUpdateUser: (userId: string, data: Partial<CreateUserData>) => void;
  onOpenResendInvitationModal: (data?: User) => void;
  onOpenDeleteUserConfirmationModal: (data?: User) => void;
  onOpenImportUsers: () => void;
  onCloseUserDetails: () => void;
  onDeleteUser: (userId: string) => Promise<void>;
  onSendInvitationEmail: (user?: User) => void;
  resetModalState: () => void;
  currentPage: number;
  perPage: number;
  perPageOptions: { label: number; value: number }[];
  modalState: any;
  shouldShowEmptySearchResultView: boolean;
};

export function UserManagementView(props: Props): React.ReactElement {
  const {
    onSearchChange,
    onFiltersChanged,
    onOpenCreateUser,
    onPageChange,
    onPerPageChange,
    onSortingChanged,
    onTableRowClick,
    onUpdateUser,
    onOpenResendInvitationModal,
    onOpenDeleteUserConfirmationModal,
    onOpenImportUsers,
    onCloseUserDetails,
    onDeleteUser,
    onSendInvitationEmail,
    resetModalState,
    currentPage,
    perPageOptions,
    perPage,
    modalState,
    shouldShowEmptySearchResultView,
  } = props;

  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mx={2}>
        <ListFilterContainer>
          <UsersTableHeader onSearchChange={onSearchChange} onFiltersChanged={onFiltersChanged} />
          <Box display='flex'>
            <ActionButton
              data-testid='addNewUserButton'
              color='primary'
              variant='contained'
              onClick={onOpenCreateUser}
              startIcon={<AddIcon htmlColor='white' />}
            >
              {t('cta.addNew')}
            </ActionButton>
            <RestrictedToPermission permission={[Permission.UserBulkCreate]}>
              <Box ml={1}>
                <ActionButton
                  data-testid='importNewUsers'
                  color='primary'
                  variant='contained'
                  onClick={onOpenImportUsers}
                  startIcon={<AddIcon htmlColor='white' />}
                >
                  {t('modals.importUsers.importUsers')}
                </ActionButton>
              </Box>
            </RestrictedToPermission>
          </Box>
        </ListFilterContainer>
        {shouldShowEmptySearchResultView ? (
          <EmptySearchResultView />
        ) : (
          <UsersTable
            currentPage={currentPage}
            perPage={perPage}
            onPageChange={onPageChange}
            perPageOptions={perPageOptions}
            onPerPageChange={onPerPageChange}
            onSortingChange={onSortingChanged}
            onTableRowClick={onTableRowClick}
            onUpdateUser={onUpdateUser}
            onOpenResendInvitationModal={onOpenResendInvitationModal}
            onOpenDeleteUserConfirmationModal={onOpenDeleteUserConfirmationModal}
          />
        )}
      </Box>
      <UserDetailContainer
        onClose={onCloseUserDetails}
        onOpenResendInvitationModal={onOpenResendInvitationModal}
        // TODO: Implement the functionality to block an account
        toggleBlockUser={_.noop}
      />
      {modalState.user && (
        <SendInvitationEmailModal
          user={modalState.user}
          isSendingEmail={false}
          isOpen={modalState.isOpen && modalState.type === ModalType.userEmail}
          closeModal={resetModalState}
          onSubmit={() => onSendInvitationEmail(modalState.user)}
        />
      )}

      {modalState.user && (
        <ConfirmDeleteModal
          user={modalState.user}
          isDeletingUser={false}
          isOpen={modalState.isOpen && modalState.type === ModalType.deleteUser}
          closeModal={resetModalState}
          onSubmit={() => onDeleteUser(modalState?.user?.auth0Id!)}
        />
      )}

      <ImportUsersModal
        isOpen={modalState.isOpen && modalState.type === ModalType.importUsers}
        closeModal={resetModalState}
      />
    </React.Fragment>
  );
}
