import Box from '@material-ui/core/Box';
import styled from 'styled-components';

export const PerPageContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    position: absolute;
    top: 4px;
    left: 0;
  }
`;
