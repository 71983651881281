import { Box, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../../Components/Button/Button';
import LoadingView from '../../../../../Components/LoadingView/LoadingView';
import Typography from '../../../../../Components/Typography';
import { FileTemplateRecipientValidation } from '../../../../../Helpers/inputValidation/form/fileTemplateRecipients';
import { useCreateFileTemplateRecipientMutation } from '../../../../../ReactQuery/FileTemplateRecipients/fileTemplateRecipients.mutations';
import { useFindParticipantRolesQuery } from '../../../../../ReactQuery/ParticipantRoles/participantRoles.queries';
import { CreateFileTemplateRecipientData } from '../../../../../Services/FileTemplateRecipientService';
import { StyledSelect } from '../../Style';
import { FileTemplateRecipientsList } from './FileTemplateRecipientList';

type FileTemplateRecipientProps = {
  fileTemplateId: string;
};

export function FileTemplateRecipient(props: FileTemplateRecipientProps): React.ReactElement {
  const { fileTemplateId } = props;
  const { t } = useTranslation();

  const createFileTemplateRecipientMutation = useCreateFileTemplateRecipientMutation();
  const { data: participantRoles } = useFindParticipantRolesQuery();

  const [participantRoleId, setParticipantRoleId] = useState('');

  if (!participantRoles) {
    return <LoadingView />;
  }

  const createNewFileTemplateRecipient = async (): Promise<void> => {
    const newFileTemplateRecipientData: CreateFileTemplateRecipientData = {
      fileTemplateId,
      participantRoleId,
      isSignatureRequired: true,
    };

    if (!FileTemplateRecipientValidation.getFormSchema().isValidSync(newFileTemplateRecipientData)) {
      return;
    }

    await createFileTemplateRecipientMutation.mutateAsync(newFileTemplateRecipientData);
  };

  const participantRoleOptions = participantRoles.map((role) => ({
    value: role.id,
    label: `${role.position} - ${role.type}`,
  }));

  return (
    <React.Fragment>
      <Box p={2}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='h4'>{t('assistedApplication.recipientsDrawer.title')}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container alignItems='flex-end' spacing={3}>
              <Grid item xs={9}>
                <StyledSelect
                  options={participantRoleOptions}
                  onChange={(value) => setParticipantRoleId(value as string)}
                  value={participantRoleId}
                  id='edit-document-doc-type'
                  label={t('inputs.documentType')}
                />
              </Grid>
              <Grid item>
                <Button color='primary' variant='contained' onClick={createNewFileTemplateRecipient}>
                  {t('admin.documentDetailManagement.addRecipient')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box p={2}>
        <FileTemplateRecipientsList fileTemplateId={fileTemplateId} participantRoles={participantRoles} />
      </Box>
    </React.Fragment>
  );
}
