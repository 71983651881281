import Box from '@material-ui/core/Box';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { Loader } from '@breathelife/ui-components';

import { Button } from '../../Components/Button/Button';
import { buttonSize as iconWrapperSize, imageSize, Size } from '../../Components/Controls/IconButton/Styles';
import Typography from '../../Components/Typography';
import { TypographyVariant } from '../../Styles/Types';

type Props = {
  onClick: () => void;
  children?: React.ReactNode;
  isLoading?: boolean;
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
  removeIconBorder?: boolean;
  className?: string;
  typographyVariant?: TypographyVariant;
  size?: Size;
};

const IconWrapper = styled.div<{ $removeIconBorder?: boolean; $size: Size }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.$removeIconBorder ? imageSize[props.$size] : iconWrapperSize[props.$size])};
  height: ${(props) => (props.$removeIconBorder ? imageSize[props.$size] : iconWrapperSize[props.$size])};
  background-color: transparent;
  border-radius: 50%;
  padding: 0;
  border: ${(props) => (props.$removeIconBorder ? undefined : `1px solid ${props.theme.colors.grey[50]}`)};
`;

type OptionalReactElement = React.ReactElement | undefined;

export function GhostIconButton(props: Props): React.ReactElement {
  const {
    startIcon,
    endIcon,
    isLoading,
    removeIconBorder,
    onClick,
    size = 'medium',
    className,
    children,
    typographyVariant,
  } = props;

  const buildIcon = useCallback(
    (icon: OptionalReactElement): OptionalReactElement => {
      if (!icon) return;
      if (isLoading) return <Loader size={iconWrapperSize[size]} />;
      return (
        <Box mx={1}>
          <IconWrapper $removeIconBorder={removeIconBorder} $size={size}>
            {icon}
          </IconWrapper>
        </Box>
      );
    },
    [removeIconBorder, size, isLoading]
  );

  return (
    <Button onClick={onClick} className={className}>
      <Box display='flex' alignItems='center'>
        {buildIcon(startIcon)}
        <Box display='flex' ml={startIcon ? 0 : 1} mr={endIcon ? 0 : 1} alignContent='center'>
          <Typography variant={typographyVariant || 'small2'}>{children}</Typography>
        </Box>
        {buildIcon(endIcon)}
      </Box>
    </Button>
  );
}
