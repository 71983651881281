import React from 'react';

import { Permission } from '@breathelife/types';

import { userHasPermission } from '../../Helpers/user';
import { useSelector } from '../../Hooks';

type Props = {
  permission?: Permission | Permission[];
  children: React.ReactElement;
};

// Hides the component if the user does not have the permission to see it
export const RestrictedToPermission = (props: Props): React.ReactElement | null => {
  const userPermissions =
    useSelector((store) => {
      return store.leadPlatform.authentication.user?.permissions;
    }) ?? [];

  if (!props.permission) return props.children;

  if (!userHasPermission(userPermissions, props.permission)) return null;
  return props.children;
};
