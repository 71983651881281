import History from '@material-ui/icons/History';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import { AdminToolResourceName } from '@breathelife/types';

import { IconButton } from '../../Components/Controls/IconButton/IconButton';
import Urls from '../../Navigation/Urls';

type Props = {
  resourceId: string;
  resourceName: AdminToolResourceName;
};

export function LogButton({ resourceId, resourceName }: Props): React.ReactElement {
  const history = useHistory();

  const redirectToLogsUrl = useCallback(() => {
    history.push(`${Urls.auditLogs}?resourceName=${resourceName}&resourceId=${resourceId}`);
  }, [history, resourceName, resourceId]);

  return <IconButton $customSize='small' icon={<History />} onClick={redirectToLogsUrl} />;
}
