import Box from '@material-ui/core/Box';
import styled from 'styled-components';

export const IconContainer = styled.div`
  margin-bottom: 30px;
  margin-left: -30px;
  &&&&& {
    svg {
      overflow: overlay;
    }
  }
`;

export const PopperBox = styled(Box)`
  display: flex;
  flex-direction: row;
  padding: 16px;
  max-width: 337px;
`;

export const TextContainer = styled(Box)`
  padding-bottom: 8px;
`;
