import * as yup from 'yup';

import { RecursiveNullable } from './typeGuards';

export function getValidationError<T extends Record<string, yup.Schema<any>>>(
  schema: T,
  fieldName: string,
  value: string | number | undefined
): yup.ValidationError | undefined {
  try {
    schema[fieldName].validateSync(value);
  } catch (error) {
    if (yup.ValidationError.isError(error)) {
      return error;
    }

    throw error;
  }
  return;
}

export function getListValidationError<T>(
  schema: yup.NotRequiredArraySchema<RecursiveNullable<T>>,
  list: T[]
): yup.ValidationError | undefined {
  try {
    schema.validateSync(list);
  } catch (error) {
    if (yup.ValidationError.isError(error)) {
      return error;
    }

    throw error;
  }
  return;
}
