import AddIcon from '@material-ui/icons/AddRounded';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ApplicationMode, Permission } from '@breathelife/types';

import { ActionButton } from '../../../Components/Button/ActionButton';
import { ApplicationMarketingDataExtractButton } from '../../../Components/Button/ApplicationMarketingDataExtractButton';
import { Icon } from '../../../Components/Icons';
import { LaunchApplicationMenuButton } from '../../../Components/LaunchApplicationMenuButton/LaunchApplicationMenuButton';
import { ListFilterContainer } from '../../../Components/ListFilterContainer/ListFilterContainer';
import { RestrictedToPermission } from '../../../Components/Restricted/RestrictedToPermission';
import { CarrierContext } from '../../../Context/CarrierContext';
import { ModalPayload } from '../../../Redux/Layout/LayoutSlice';
import { Filters, ListFilter } from './ListFilter';

type Props = {
  onFiltersChanged: (filters?: Filters) => void;
  onSearchChange: (searchString: string) => void;
  onCreateLeadClick: (mode?: ApplicationMode) => void;
  onClickMarketingDataExtractButton: () => void;
  setModalState: (state: ModalPayload) => void;
  selectedUserId: string;
  searchTerm?: string;
  allowLeadCreation: boolean;
  allowApplicationMarketingDataExtract?: boolean;
  isCreatingLeadAndLaunchingApplication: boolean;
};

export function ListHeader({
  onFiltersChanged: propOnFiltersChanged,
  onSearchChange,
  onCreateLeadClick,
  onClickMarketingDataExtractButton,
  setModalState,
  selectedUserId,
  searchTerm,
  allowLeadCreation,
  allowApplicationMarketingDataExtract,
  isCreatingLeadAndLaunchingApplication,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  const { features } = useContext(CarrierContext);

  const isHiddenLeadsEnabled: boolean = !!features.hiddenLeads?.enabled;
  const isPaperAppModeEnabled: boolean = !!features.questionnaireScreen?.enabled;
  const onFiltersChanged = (filters?: Filters): void => {
    propOnFiltersChanged(filters);
  };

  return (
    <ListFilterContainer>
      <ListFilter
        selectedUserId={selectedUserId}
        searchTerm={searchTerm}
        setModalState={setModalState}
        onFiltersChanged={onFiltersChanged}
        onSearchChange={onSearchChange}
      />

      {allowApplicationMarketingDataExtract && (
        <RestrictedToPermission permission={Permission.ApplicationMarketingDataExtract}>
          <ApplicationMarketingDataExtractButton
            data-testid='applicationMarketingDataExtractButton'
            color='primary'
            variant='outlined'
            startIcon={<Icon name='cloudDownload' />}
            onClick={onClickMarketingDataExtractButton}
          >
            {t('cta.extractAllLeadData')}
          </ApplicationMarketingDataExtractButton>
        </RestrictedToPermission>
      )}
      {allowLeadCreation &&
        (isHiddenLeadsEnabled && isPaperAppModeEnabled ? (
          <RestrictedToPermission permission={[Permission.LeadCreate, Permission.ApplicationLaunchMe]}>
            <LaunchApplicationMenuButton
              onCreateApplication={onCreateLeadClick}
              isCreatingApplication={isCreatingLeadAndLaunchingApplication}
            />
          </RestrictedToPermission>
        ) : (
          <RestrictedToPermission permission={Permission.LeadCreate}>
            <ActionButton
              data-testid='addNewLeadBtn'
              color='primary'
              variant='contained'
              onClick={() => onCreateLeadClick()}
              startIcon={<AddIcon htmlColor='white' />}
              disabled={isHiddenLeadsEnabled ? isCreatingLeadAndLaunchingApplication : false}
            >
              {isHiddenLeadsEnabled ? t('cta.launchApplication') : t('leadsListTable.addNew')}
            </ActionButton>
          </RestrictedToPermission>
        ))}
    </ListFilterContainer>
  );
}
