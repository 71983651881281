import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/AddRounded';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  BlueprintConditionValue,
  BooleanOperator,
  ConsiderationBlueprint,
  ConsiderationBlueprintRowData,
  OutcomeCode,
  PageQueryOptions,
} from '@breathelife/types';
import { SelectMui, SelectMuiProps } from '@breathelife/ui-components';

import { ActionButton } from '../../../Components/Button/ActionButton';
import { defaultSingleConditionBlueprintValue } from '../../../Components/Conditions';
import { Helmet } from '../../../Components/Helmet';
import {
  addConditionToBlueprint,
  addNestedConditionToBlueprint,
  removeConditionFromBlueprint,
  updateConditionBlueprint,
  updateConditionBooleanOperator,
} from '../../../Helpers/conditions/blueprintHelpers';
import { validateBlueprint, validateRuleIdentifier } from '../../../Helpers/inputValidation/form/salesDecisionRules';
import { useCarrierContext, useDispatch, useSelector } from '../../../Hooks';
import { DEFAULT_PER_PAGE_OPTIONS, usePagination } from '../../../Hooks/Pagination/usePagination';
import { DetailViewType } from '../../../Models/Layout';
import * as SalesDecisionRuleManagementOperations from '../../../Redux/Admin/SalesDecisionRulesManagement/SalesDecisionRulesManagementOperations';
import {
  getIsReloadingRules,
  getSalesDecisionRules,
  getTotal,
} from '../../../Redux/Admin/SalesDecisionRulesManagement/SalesDecisionRulesManagementSelectors';
import { defaultState, layoutSlice } from '../../../Redux/Layout/LayoutSlice';
import {
  QuestionnaireVersionDataContext,
  QuestionnaireVersionDataContextProvider,
} from '../Questionnaire/QuestionnaireVersionDataContextProvider';
import { QuestionnaireVersionsContext } from '../Questionnaire/QuestionnaireVersionsContextProvider';
import { SalesDecisionRuleDetailContainer } from './SalesDecisionRuleDetail/SalesDecisionRuleDetailContainer';
import { SalesDecisionRulesTable } from './SalesDecisionRulesTable';

export const QuestionnaireVersionSelect = styled(SelectMui)`
  &&& {
    width: 300px;
  }
` as <T extends string | number = string>(props: SelectMuiProps<T>) => React.ReactElement;

export function SalesDecisionRulesManagementViewContainer(): React.ReactElement | null {
  const { questionnaireVersions } = useContext(QuestionnaireVersionsContext);
  const [questionnaireVersionId, setQuestionnaireVersionId] = useState<string | undefined>(
    questionnaireVersions?.[0]?.id
  );

  function onQuestionnaireVersionFilterChanged(versionId: string): void {
    setQuestionnaireVersionId(versionId);
  }
  if (!questionnaireVersionId) {
    return null;
  }
  return (
    <QuestionnaireVersionDataContextProvider questionnaireVersionId={questionnaireVersionId}>
      <SalesDecisionRulesManagementView onQuestionnaireVersionFilterChanged={onQuestionnaireVersionFilterChanged} />
    </QuestionnaireVersionDataContextProvider>
  );
}

export function SalesDecisionRulesManagementView({
  onQuestionnaireVersionFilterChanged,
}: {
  onQuestionnaireVersionFilterChanged: (version: string) => void;
}): React.ReactElement | null {
  const { features } = useCarrierContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { questionnaireVersionData, questionnaireVersionId } = useContext(QuestionnaireVersionDataContext);
  const { questionnaireNodeIds, nodeIdInCollectionMap } = questionnaireVersionData || {};

  const { questionnaireVersions } = useContext(QuestionnaireVersionsContext);

  const questionnaireVersionsSelectOptions = useMemo(() => {
    return questionnaireVersions.map((questionnaireVersion) => {
      const versionDescription = questionnaireVersion.isDraft
        ? t('admin.questionnaireManagement.draftQuestionnaireVersion')
        : questionnaireVersion.description;

      return {
        value: questionnaireVersion.id,
        label: `${questionnaireVersion.majorVersion}.${questionnaireVersion.minorVersion}: ${versionDescription}`,
      };
    });
  }, [questionnaireVersions, t]);

  const salesDecisionRules = useSelector(getSalesDecisionRules);
  const isReloadingRules = useSelector(getIsReloadingRules);
  const total = useSelector(getTotal);

  const { type: viewType } = useSelector((store) => store.leadPlatform.layout.rightPanelState);
  const { isOpen } = useSelector((store) => store.leadPlatform.layout.rightPanelState);
  const [selectedRuleId, setSelectedRuleId] = useState<string | null>(null);
  const [ruleCanBeDeleted, setRuleCanBeDeleted] = useState(false);
  const [identifier, setRuleIdentifier] = useState<string>('');

  const [{ $page, $limit, $sort }, { onPageChange, onLimitPerPageChange, onSortingChange }] =
    usePagination<ConsiderationBlueprintRowData>({ $sort: { field: 'createdAt', direction: 'desc' } }, total);
  const { direction: sortDirection, field: sortField } = $sort ?? {};

  const defaultBlueprintState = useMemo(
    () => ({
      outcomeCode: OutcomeCode.referred,
      reason: '',
      conditions: {
        conditions: [defaultSingleConditionBlueprintValue()],
        booleanOperator: BooleanOperator.and,
      },
    }),
    []
  );

  const [blueprint, setBlueprint] = useState<ConsiderationBlueprint>(defaultBlueprintState);

  useEffect(() => {
    if (!questionnaireVersionId) return;

    const queryOptions: Partial<PageQueryOptions<ConsiderationBlueprintRowData>> = {
      $limit,
      $page,
    };

    if (sortField && sortDirection) {
      queryOptions.$sort = { field: sortField, direction: sortDirection };
    }

    void dispatch(SalesDecisionRuleManagementOperations.fetchRules(questionnaireVersionId, queryOptions));
  }, [dispatch, questionnaireVersionId, $limit, $page, sortField, sortDirection]);

  const onRowClick = useCallback(
    (rowId?: string) => {
      if (!rowId) return;

      const rule = salesDecisionRules.find((rule) => rule.id === rowId);

      if (!rule) return;

      setSelectedRuleId(rule.id);
      setRuleCanBeDeleted(true);
      setBlueprint(rule.blueprint);
      rule.identifier === null ? setRuleIdentifier('') : setRuleIdentifier(rule.identifier);

      dispatch(
        layoutSlice.actions.setRightPanelState({ rightPanelState: { type: DetailViewType.edit, isOpen: true } })
      );
    },
    [salesDecisionRules, dispatch]
  );

  const onBlueprintUpdate = useCallback((data: Partial<ConsiderationBlueprint>) => {
    setBlueprint((prevState) => {
      return {
        ...prevState,
        ...data,
      };
    });
  }, []);

  const onRuleIdentifierUpdate = useCallback((data: string) => {
    setRuleIdentifier(data);
  }, []);

  const onBooleanOperatorChange = useCallback((booleanOperator: BooleanOperator, path: string) => {
    setBlueprint((prevState) => {
      const updatedCondition = updateConditionBooleanOperator(prevState.conditions, path, booleanOperator);
      return { ...prevState, conditions: updatedCondition };
    });
  }, []);

  const onConditionUpdate = useCallback((data: BlueprintConditionValue, path: string) => {
    setBlueprint((prevState) => {
      const updatedCondition = updateConditionBlueprint(prevState.conditions, path, data);
      return { ...prevState, conditions: updatedCondition };
    });
  }, []);

  const onAddCondition = useCallback((path: string) => {
    setBlueprint((prevState) => {
      const updatedCondition = addConditionToBlueprint(prevState.conditions, path);
      return { ...prevState, conditions: updatedCondition };
    });
  }, []);

  const onAddNestedCondition = useCallback((path: string) => {
    setBlueprint((prevState) => {
      const updatedCondition = addNestedConditionToBlueprint(prevState.conditions, path);
      return { ...prevState, conditions: updatedCondition };
    });
  }, []);

  const onRemoveCondition = useCallback((path: string) => {
    setBlueprint((prevState) => {
      const updatedCondition = removeConditionFromBlueprint(prevState.conditions, path);
      return { ...prevState, conditions: updatedCondition };
    });
  }, []);

  const onDrawerOpen = useCallback(() => {
    dispatch(
      layoutSlice.actions.setRightPanelState({
        rightPanelState: { isOpen: true, type: DetailViewType.create },
      })
    );
  }, [dispatch]);

  const onClose = useCallback(() => {
    if (!isOpen) return;
    dispatch(layoutSlice.actions.setRightPanelState({ rightPanelState: defaultState.rightPanelState }));
    setBlueprint(defaultBlueprintState);
    setRuleCanBeDeleted(false);
    setSelectedRuleId(null);
    setRuleIdentifier('');
  }, [defaultBlueprintState, dispatch, isOpen]);

  const isFormValid = useMemo(() => {
    if (!questionnaireNodeIds || !nodeIdInCollectionMap) {
      return false;
    }
    const valid =
      blueprint.outcomeCode &&
      !_.isEmpty(blueprint.reason) &&
      validateBlueprint(blueprint.conditions, questionnaireNodeIds, nodeIdInCollectionMap) &&
      validateRuleIdentifier(identifier, features.requireSalesDecisionRuleIdentifier?.enabled);
    return valid;
  }, [blueprint, questionnaireNodeIds, nodeIdInCollectionMap, identifier, features.requireSalesDecisionRuleIdentifier]);

  const onClickSave = useCallback(() => {
    if (!isFormValid) return;

    if (viewType === DetailViewType.create && !selectedRuleId && questionnaireVersionId) {
      void dispatch(SalesDecisionRuleManagementOperations.createRule(questionnaireVersionId, blueprint, identifier));
      onClose();
      return;
    } else if (selectedRuleId) {
      void dispatch(SalesDecisionRuleManagementOperations.updateRule(selectedRuleId, blueprint, identifier));
    }

    onClose();
  }, [dispatch, isFormValid, blueprint, onClose, selectedRuleId, questionnaireVersionId, viewType, identifier]);

  const onDelete = useCallback(() => {
    if (!selectedRuleId) return;
    void dispatch(SalesDecisionRuleManagementOperations.deleteRule(selectedRuleId));
    setRuleCanBeDeleted(false);
    setSelectedRuleId(null);
    onClose();
  }, [dispatch, selectedRuleId, onClose]);

  if (!questionnaireVersionId) {
    return null;
  }

  return (
    <Box m={2}>
      <Helmet text={t('pageTitles.salesDecisionRulesManagement')} />
      <React.Fragment>
        <Box display='flex' justifyContent='space-between' mb={2}>
          <QuestionnaireVersionSelect
            id='questionnaire-version-select'
            value={questionnaireVersionId}
            options={questionnaireVersionsSelectOptions}
            onChange={onQuestionnaireVersionFilterChanged}
          />
          <ActionButton
            data-testid='add-new-rule-button'
            color='primary'
            variant='contained'
            onClick={onDrawerOpen}
            startIcon={<AddIcon htmlColor='white' />}
            disabled={isReloadingRules}
          >
            {t('cta.addNew')}
          </ActionButton>
        </Box>
        <SalesDecisionRulesTable
          salesDecisionRules={salesDecisionRules}
          onRowClick={onRowClick}
          isReloadingRules={isReloadingRules}
          total={total}
          currentPage={$page}
          perPage={$limit}
          perPageOptions={DEFAULT_PER_PAGE_OPTIONS}
          onPageChange={onPageChange}
          onLimitPerPageChange={onLimitPerPageChange}
          onSortingChange={onSortingChange}
        />
        <SalesDecisionRuleDetailContainer
          blueprint={blueprint}
          onBlueprintUpdate={onBlueprintUpdate}
          onClickSave={onClickSave}
          isFormValid={isFormValid}
          ruleCanBeDeleted={ruleCanBeDeleted}
          onDrawerClose={onClose}
          onDelete={onDelete}
          onConditionUpdate={onConditionUpdate}
          onBooleanOperatorChange={onBooleanOperatorChange}
          onAddCondition={onAddCondition}
          onAddNestedCondition={onAddNestedCondition}
          onRemoveCondition={onRemoveCondition}
          identifier={identifier}
          onRuleIdentifierUpdate={onRuleIdentifierUpdate}
        />
      </React.Fragment>
    </Box>
  );
}
