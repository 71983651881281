import Box from '@material-ui/core/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { NavigationSidebarVariants, Permission } from '@breathelife/types';

import { ReactComponent as AdminIcon } from '../../Assets/admin-icon.svg';
import { ReactComponent as CustomersIcon } from '../../Assets/customers-icon.svg';
import { Icon } from '../../Components/Icons';
import { RestrictedToPermission } from '../../Components/Restricted/RestrictedToPermission';
import { useCarrierContext } from '../../Hooks';
import Urls from '../../Navigation/Urls';
import { FullColorShade, LeadPlatformTheme } from '../../Styles/Types';
import { SidebarNavLink } from './SidebarNavLink';
import { Logo, SidebarFooter, StyledSidebar } from './Styles';

type VariantStyle = {
  background: string;
  border: string;
  grey: keyof FullColorShade;
  hoverColor: string;
  activeColor: string;
};

const handleColorsVariant = (theme: LeadPlatformTheme, variant?: NavigationSidebarVariants): VariantStyle => {
  let backgroundColor1 = theme.colors.primary[60];
  let backgroundColor2 = theme.colors.primary[70];
  let border = `1px solid ${theme.colors.primary[70]};`;
  let hoverColor = theme.colors.primary[70];
  let activeColor = theme.colors.primary[80];
  let grey: keyof FullColorShade = 0;

  switch (variant) {
    case NavigationSidebarVariants.light:
      backgroundColor1 = theme.colors.grey[0];
      backgroundColor2 = theme.colors.grey[10];
      border = `1px solid ${theme.colors.grey[40]}`;
      hoverColor = theme.colors.grey[20];
      activeColor = theme.colors.grey[30];
      grey = 100;
      break;
    case NavigationSidebarVariants.primary:
    case NavigationSidebarVariants.secondary:
      backgroundColor1 = theme.colors[variant][60];
      backgroundColor2 = theme.colors[variant][70];
      border = `1px solid ${theme.colors[variant][70]};`;
      hoverColor = theme.colors[variant][70];
      activeColor = theme.colors[variant][80];
      break;
  }

  const background = `linear-gradient(
    160deg,
    ${backgroundColor1} 0%,
    ${backgroundColor2} 150%
  )`;

  return {
    background,
    border,
    grey,
    hoverColor,
    activeColor,
  };
};

type SideBarLink = {
  id: string;
  url: string;
  text: string;
  icon: React.ReactElement;
  requiredPermissions?: Permission | Permission[];
};

export function Sidebar(): React.ReactElement {
  const { carrierInfo, features, navigationSidebarVariant } = useCarrierContext();
  const theme = useTheme();
  const styles = handleColorsVariant(theme, navigationSidebarVariant);
  const { t } = useTranslation();
  const softwareByTitle = t('softwareByBreatheLife');

  const sideBarLinks: SideBarLink[] = [
    {
      id: 'leads',
      url: Urls.leads,
      text: features.hiddenLeads?.enabled ? t('sidebar.applications') : t('sidebar.customers'),
      icon: <CustomersIcon stroke={theme.colors.grey[styles.grey]} />,
    },
    {
      id: 'admin',
      url: Urls.admin,
      text: t('sidebar.admin'),
      icon: <AdminIcon stroke={theme.colors.grey[styles.grey]} />,
      requiredPermissions: [Permission.AdminToolsRead],
    },
  ];

  return (
    <StyledSidebar
      display='flex'
      flexDirection='column'
      alignItems='center'
      width='72px'
      height='100%'
      background={styles.background}
      border={styles.border}
      role='complementary'
    >
      <Box lineHeight='0' mt={3} mb={4.5}>
        <Logo
          src={carrierInfo.logoCompact}
          radius={0}
          alt={`${carrierInfo.companyName} ${t('sidebar.logo')}`}
          isProfile={false}
        />
      </Box>
      <Box flex='1' display='flex' flexDirection='column' justifyContent='space-between' alignItems='center'>
        <Box flex='1' display='flex' flexDirection='column' alignItems='center'>
          {sideBarLinks.map((navLink) => {
            return (
              <RestrictedToPermission key={navLink.id} permission={navLink.requiredPermissions}>
                <SidebarNavLink
                  to={navLink.url}
                  text={navLink.text}
                  icon={navLink.icon}
                  greyShade={styles.grey}
                  activeColor={styles.activeColor}
                  hoverColor={styles.hoverColor}
                />
              </RestrictedToPermission>
            );
          })}
        </Box>
        {features.softwareByWidget.enabled && (
          <SidebarFooter mb={2} pt={2} width='56px' display='flex' justifyContent='center'>
            <SoftwareByWrapper softwareByUrl={features.softwareByWidget.softwareByUrl} ariaLabel={softwareByTitle}>
              <Icon
                fill={theme.colors.grey[50]}
                name='breatheLifeLogoSmall'
                width='100%'
                height='15'
                id='bl-logo-sidebar'
                title={softwareByTitle}
              />
            </SoftwareByWrapper>
          </SidebarFooter>
        )}
      </Box>
    </StyledSidebar>
  );
}

type SoftwareByWrapperProps = {
  children: React.ReactElement;
  ariaLabel: string;
  softwareByUrl?: string;
};

function SoftwareByWrapper(props: SoftwareByWrapperProps): React.ReactElement {
  const { children, softwareByUrl } = props;

  if (!softwareByUrl) return children;

  return (
    <a href={softwareByUrl} rel='noopener noreferrer' target='_blank'>
      {children}
    </a>
  );
}
