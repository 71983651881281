import React from 'react';
import { useTranslation } from 'react-i18next';

import { SubmitButton } from '../../../Components/Button/SubmitButton';
import { ModalLayout } from '../../../Layouts/Modal/ModalLayout';

type EntityMappingUnsavedChangesWarningModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  onDiscardChanges: () => void;
};

export function EntityMappingUnsavedChangesWarningModal({
  closeModal,
  isOpen,
  onDiscardChanges,
}: EntityMappingUnsavedChangesWarningModalProps): React.ReactElement {
  const { t } = useTranslation();
  return (
    <ModalLayout
      maxWidth='sm'
      isOpen={isOpen}
      closeModal={closeModal}
      title={t('admin.entityMappings.unsavedChangedModalTitle')}
      submitButton={
        <SubmitButton onClick={onDiscardChanges} data-testid='questionnaire-editor-field-create'>
          {t('admin.entityMappings.discardChangesModalButton')}
        </SubmitButton>
      }
    >
      <p>{t('admin.entityMappings.changesUnsavedModalWarning')}</p>
    </ModalLayout>
  );
}
