import Box from '@material-ui/core/Box';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { QuestionnaireVersionInfo } from '@breathelife/types';

import Typography from '../../../../../Components/Typography';
import { useFetchAllQuestionnaireVersionsQuery } from '../../../../../ReactQuery/Admin/Questionnaire/questionnaireVersion.queries';
import { useFindFileTemplateRulesQuery } from '../../../../../ReactQuery/Document/DocumentRule/documentRule.queries';
import { EditDocumentRule } from './EditDocumentRule';
import { NewDocumentRule } from './NewDocumentRule';

function useGetLatestQuestionnaireVersion(): QuestionnaireVersionInfo | undefined {
  const questionnaireVersions = useFetchAllQuestionnaireVersionsQuery();

  return useMemo(
    () =>
      questionnaireVersions.data &&
      questionnaireVersions?.data.find((questionnaireVersion) => !questionnaireVersion.isDraft),
    [questionnaireVersions.data]
  );
}

function useLatestQuestionnaireVersionRulesQuery(fileTemplateId: string) {
  const latestQuestionnaireVersion = useGetLatestQuestionnaireVersion();
  return useFindFileTemplateRulesQuery(latestQuestionnaireVersion?.id, fileTemplateId);
}

type DocumentRulesViewProps = {
  fileTemplateId: string;
};

export function DocumentRulesView(props: DocumentRulesViewProps): React.ReactElement | null {
  const { t } = useTranslation();

  const questionnaireVersion = useGetLatestQuestionnaireVersion();
  const fileTemplateRules = useLatestQuestionnaireVersionRulesQuery(props.fileTemplateId);

  let EditorComponent: React.ReactElement | null;

  if (fileTemplateRules?.data && fileTemplateRules?.data[0]) {
    EditorComponent = <EditDocumentRule fileTemplateRule={fileTemplateRules?.data[0]} />;
  } else if (questionnaireVersion) {
    EditorComponent = (
      <NewDocumentRule fileTemplateId={props.fileTemplateId} questionnaireVersionId={questionnaireVersion.id} />
    );
  } else {
    EditorComponent = null;
  }

  return (
    <React.Fragment>
      {questionnaireVersion && (
        <Box mx={2} mt={2}>
          <Typography
            variant='body2'
            style={{
              textOverflow: 'ellipsis',
            }}
          >
            {t('admin.documentDetailManagement.documentRule.currentlyEditingVersion', {
              version: `v${questionnaireVersion?.majorVersion}.${questionnaireVersion?.minorVersion} - ${questionnaireVersion?.description}`,
            })}
          </Typography>
        </Box>
      )}
      {EditorComponent}
    </React.Fragment>
  );
}
