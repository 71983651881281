import Box from '@material-ui/core/Box';
import dayjs from 'dayjs';
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FileTemplate, FileTemplateOrigin, StoredFileDocType } from '@breathelife/types';

import { EyeIcon } from '../../../Assets/EyeIcon';
import { IconButton } from '../../../Components/Controls';
import LoadingView from '../../../Components/LoadingView/LoadingView';
import { Table } from '../../../Components/Table/Table';
import Typography from '../../../Components/Typography';
import { emptyTableHeight } from '../../../Models/Layout';
import { useFindFileTemplatesQuery } from '../../../ReactQuery/Document/document.queries';
import { EditDocumentDrawer } from './EditDocumentDrawer';

type DocumentTableData = Pick<
  FileTemplate,
  'id' | 'docType' | 'origin' | 'identifier' | 'externalUrl' | 'createdAt' | 'updatedAt' | 'variant' | 'archived'
> & { ['localizedName.en']?: string; ['localizedName.fr']?: string };

const EmptyDocumentsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: ${() => `calc(100vh - ${emptyTableHeight}px)`};
  min-height: 300px;
`;

export const ModalContent = styled.div`
  padding-bottom: 24px;
  color: ${(props) => props.theme.colors.grey[90]};
`;

type DocumentsTableProps = {
  displayArchived?: boolean;
};

export function DocumentsTable(props: DocumentsTableProps): React.ReactElement {
  const { displayArchived } = props;

  const fileTemplates = useFindFileTemplatesQuery({ archived: displayArchived });
  const { t } = useTranslation();

  const columns = useMemo<
    {
      Header: string;
      accessor: keyof DocumentTableData;
    }[]
  >(
    () => [
      {
        Header: 'Document Name',
        accessor: 'localizedName.en',
      },
      {
        Header: 'Document Type',
        accessor: 'docType',
        Cell: function ({ cell: { value } }: { cell: { value: StoredFileDocType } }): React.ReactElement {
          return <React.Fragment>{t(`documentType.${value}`)}</React.Fragment>;
        },
      },
      {
        Header: 'Origin',
        accessor: 'origin',
        Cell: function ({ cell: { value } }: { cell: { value: FileTemplateOrigin } }): React.ReactElement {
          return <React.Fragment>{t(`documentOrigin.${value}`)}</React.Fragment>;
        },
      },
      {
        Header: 'Identifier',
        accessor: 'identifier',
      },

      {
        Header: 'Variant',
        accessor: 'variant',
      },

      {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: function ({ cell: { value } }: { cell: { value: Date } }): React.ReactElement {
          return <React.Fragment>{dayjs(value).format('LLL')}</React.Fragment>;
        },
      },
      {
        Header: 'Last Updated',
        accessor: 'updatedAt',
        Cell: function ({ cell: { value } }: { cell: { value: Date } }): React.ReactElement {
          return <React.Fragment>{dayjs(value).format('LLL')}</React.Fragment>;
        },
      },

      {
        Header: 'Preview',
        accessor: 'externalUrl',
        Cell: function ({ cell: { value } }: { cell: { value: string } }): React.ReactElement | null {
          if (!value) return null;
          return (
            <React.Fragment>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  window.open(value, '_blank', 'noreferrer');
                }}
                icon={<EyeIcon />}
                title={t('cta.view')}
              />
            </React.Fragment>
          );
        },
      },
    ],
    [t]
  );

  const [selectedDocumentId, setDocumentId] = React.useState<string | null>(null);

  const handleSelectedDocument = useCallback((documentId?: string) => {
    if (!documentId) return;

    setDocumentId(documentId);
  }, []);

  const handleCloseDocumentDrawer = useCallback(() => setDocumentId(null), []);

  const onRowClick = useCallback(
    (documentId?: string) => {
      handleSelectedDocument(documentId);
    },
    [handleSelectedDocument]
  );

  if (fileTemplates?.isLoading) {
    return <LoadingView />;
  }

  if (fileTemplates.data && fileTemplates.data.length) {
    return (
      <React.Fragment>
        <EditDocumentDrawer fileTemplateId={selectedDocumentId} onClose={handleCloseDocumentDrawer} />
        <Table<DocumentTableData> columns={columns} data={fileTemplates.data} onRowClick={onRowClick} />
      </React.Fragment>
    );
  }

  return (
    <Box>
      <EmptyDocumentsContainer>
        <Box>
          <Typography variant='h2' grey={70}>
            {t('admin.documentManagement.noDocumentsFoundTitle')}
          </Typography>
        </Box>
        <Box maxWidth={300}>
          <Typography variant='body1' grey={60}>
            {t('admin.documentManagement.noDocumentsFoundText')}
          </Typography>
        </Box>
      </EmptyDocumentsContainer>
    </Box>
  );
}
