import { TFunction } from 'i18next';

import { fieldTypeToValidation } from '@breathelife/insurance-form-builder';
import { DataLabel } from '@breathelife/meta-cruncher';
import { InfoVariants } from '@breathelife/questionnaire-engine';
import {
  IsoCountryCode,
  NumericalDataType,
  QuestionnaireBlueprintFieldTypes,
  SubsectionVariant,
} from '@breathelife/types';
import { AutocompleteOption } from '@breathelife/ui-components';

import { PlatformFeatures } from '../../Models/PlatformFeatures';

export function getFieldTypeOptions(t: TFunction): AutocompleteOption[] {
  return Object.values(QuestionnaireBlueprintFieldTypes)
    .map((fieldType) => ({
      value: fieldType,
      label: t(`admin.questionnaireManagement.fieldTypes.${fieldType}`),
    }))
    .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
}

export const DATA_LABEL_INHERIT_KEY = 'inherit';

export function getDataLabelOptions(t: TFunction): AutocompleteOption[] {
  return [
    DATA_LABEL_INHERIT_KEY,
    DataLabel.Unknown,
    DataLabel.KnownSafe,
    DataLabel.PII,
    DataLabel.PHI,
    DataLabel.IdentifiablePHI,
    DataLabel.ReferenceNumber,
    DataLabel.PostalCode,
    DataLabel.DateOfBirth,
    DataLabel.IdentifiableDate,
    DataLabel.NonIdentifiableDate,
    DataLabel.FreeFormText,
    DataLabel.AgeAtServiceTime,
    DataLabel.Language,
    DataLabel.AdvisorEmail,
    DataLabel.LoginEmail,
    DataLabel.InsuredPersonEmail,
    DataLabel.LeadEmail,
    DataLabel.Email,
  ].map((dataLabel) => ({
    value: dataLabel,
    label: t(`admin.questionnaireManagement.dataLabels.${dataLabel}`),
  }));
}

export function dataLabelSelectionToDataLabel(selection: string): DataLabel | undefined {
  if (selection === DATA_LABEL_INHERIT_KEY) {
    // Don't set a label, empty labels will inherit from their parents.
    return undefined;
  }

  return selection as DataLabel;
}

export type ValidationOptionsByFieldType = { [key in QuestionnaireBlueprintFieldTypes]: AutocompleteOption[] };

export function getValidationOptionsByFieldType(t: TFunction): ValidationOptionsByFieldType {
  return {
    [QuestionnaireBlueprintFieldTypes.input]: Object.values(
      fieldTypeToValidation[QuestionnaireBlueprintFieldTypes.input]
    ).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [QuestionnaireBlueprintFieldTypes.textarea]: Object.values(
      fieldTypeToValidation[QuestionnaireBlueprintFieldTypes.textarea]
    ).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [QuestionnaireBlueprintFieldTypes.radio]: Object.values(
      fieldTypeToValidation[QuestionnaireBlueprintFieldTypes.radio]
    ).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [QuestionnaireBlueprintFieldTypes.dropdown]: Object.values(
      fieldTypeToValidation[QuestionnaireBlueprintFieldTypes.dropdown]
    ).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [QuestionnaireBlueprintFieldTypes.checkbox]: Object.values(
      fieldTypeToValidation[QuestionnaireBlueprintFieldTypes.checkbox]
    ).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [QuestionnaireBlueprintFieldTypes.checkboxGroup]: Object.values(
      fieldTypeToValidation[QuestionnaireBlueprintFieldTypes.checkboxGroup]
    ).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [QuestionnaireBlueprintFieldTypes.date]: Object.values(
      fieldTypeToValidation[QuestionnaireBlueprintFieldTypes.date]
    ).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [QuestionnaireBlueprintFieldTypes.number]: Object.values(
      fieldTypeToValidation[QuestionnaireBlueprintFieldTypes.number]
    ).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [QuestionnaireBlueprintFieldTypes.money]: Object.values(
      fieldTypeToValidation[QuestionnaireBlueprintFieldTypes.money]
    ).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [QuestionnaireBlueprintFieldTypes.phone]: Object.values(
      fieldTypeToValidation[QuestionnaireBlueprintFieldTypes.phone]
    ).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [QuestionnaireBlueprintFieldTypes.information]: Object.values(
      fieldTypeToValidation[QuestionnaireBlueprintFieldTypes.information]
    ).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [QuestionnaireBlueprintFieldTypes.button]: Object.values(
      fieldTypeToValidation[QuestionnaireBlueprintFieldTypes.button]
    ).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [QuestionnaireBlueprintFieldTypes.currencyCard]: Object.values(
      fieldTypeToValidation[QuestionnaireBlueprintFieldTypes.currencyCard]
    ).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [QuestionnaireBlueprintFieldTypes.autocomplete]: Object.values(
      fieldTypeToValidation[QuestionnaireBlueprintFieldTypes.autocomplete]
    ).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [QuestionnaireBlueprintFieldTypes.agree]: Object.values(
      fieldTypeToValidation[QuestionnaireBlueprintFieldTypes.agree]
    ).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [QuestionnaireBlueprintFieldTypes.signature]: Object.values(
      fieldTypeToValidation[QuestionnaireBlueprintFieldTypes.signature]
    ).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
  };
}

export function getSubsectionVariantOptions(t: TFunction, features: PlatformFeatures): AutocompleteOption[] {
  const subsectionVariantOptions = [
    { value: '', label: t('admin.questionnaireManagement.input.noneSet') },
    {
      value: SubsectionVariant.summary,
      label: t(`admin.questionnaireManagement.subsectionVariants.${SubsectionVariant.summary}`),
    },
    {
      value: SubsectionVariant.quoter,
      label: t(`admin.questionnaireManagement.subsectionVariants.${SubsectionVariant.quoter}`),
    },
    {
      value: SubsectionVariant.payment,
      label: t(`admin.questionnaireManagement.subsectionVariants.${SubsectionVariant.payment}`),
    },
    {
      value: SubsectionVariant.submission,
      label: t(`admin.questionnaireManagement.subsectionVariants.${SubsectionVariant.submission}`),
    },
    {
      value: SubsectionVariant.addon,
      label: t(`admin.questionnaireManagement.subsectionVariants.${SubsectionVariant.addon}`),
    },
    {
      value: SubsectionVariant.productSummary,
      label: t(`admin.questionnaireManagement.subsectionVariants.${SubsectionVariant.productSummary}`),
    },
  ];

  if (features.productSelection?.enabled) {
    subsectionVariantOptions.push({
      value: SubsectionVariant.productSelection,
      label: t(`admin.questionnaireManagement.subsectionVariants.${SubsectionVariant.productSelection}`),
    });
  }

  return subsectionVariantOptions;
}

export function getInfoFieldStylingOptions(t: TFunction): AutocompleteOption[] {
  return Object.keys(InfoVariants)
    .map((styleKey) => ({
      label: t(`admin.questionnaireManagement.infoFieldStyles.${styleKey}`),
      value: styleKey,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
}

export function getNumericalDataTypeOptions(t: TFunction): AutocompleteOption[] {
  return Object.keys(NumericalDataType)
    .map((numericDataTypeKey) => ({
      label: t(`admin.questionnaireManagement.numericalDataTypeOptions.${numericDataTypeKey}`),
      value: numericDataTypeKey,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
}

export function getCountryCodeOptions(t: TFunction): AutocompleteOption[] {
  const countryCodes: IsoCountryCode[] = ['CA', 'US'];
  return countryCodes.map((countryCode) => ({
    value: countryCode,
    label: t(`admin.questionnaireManagement.input.autocompleteCountryCodes.${countryCode}`),
  }));
}
