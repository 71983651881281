import _ from 'lodash';
import React, { createContext, PropsWithChildren, useMemo, useState, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { usePrevious } from '@breathelife/react-hooks';

import {
  searchQuestionnaire,
  QuestionnaireSearchResult,
} from '../../../../../Helpers/questionnaireEditor/questionnaireSearch';
import { QuestionnaireEditorContext } from '../../QuestionnaireEditorContextProvider';
import { QuestionnaireVersionDataContext } from '../../QuestionnaireVersionDataContextProvider';

type QuestionnaireTreeViewContextValues = {
  searchTerm: string;
  previousSearchTerm: string;
  handleSearchInputChange: (input: string) => void;
  searchResults: QuestionnaireSearchResult[];
  numberOfSearchResults?: number;
};

export const QuestionnaireTreeViewSearchContext = createContext<QuestionnaireTreeViewContextValues>({
  searchTerm: '',
  previousSearchTerm: '',
  handleSearchInputChange: () => {},
  searchResults: [],
});

export function QuestionnaireTreeViewSearchContextProvider({
  children,
}: PropsWithChildren<Record<string, unknown>>): React.ReactElement {
  const { questionnaireVersionData } = useContext(QuestionnaireVersionDataContext);
  const { selectedLanguage } = useContext(QuestionnaireEditorContext);
  const { blueprint } = questionnaireVersionData || {};
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchResults, setSearchResults] = useState<QuestionnaireSearchResult[]>([]);
  const { t } = useTranslation();

  const search = useMemo(() => {
    if (!blueprint || !selectedLanguage) {
      return;
    }
    return _.debounce((input: string) => {
      const results = searchQuestionnaire(blueprint, input, selectedLanguage, t);
      setSearchResults(results);
    }, 1000);
  }, [blueprint, selectedLanguage, t]);

  const numberOfSearchResults = useMemo(() => {
    if (!searchTerm) {
      return;
    }
    return searchResults.reduce((acc, result) => {
      acc += result.propertiesContainingSearchTerm.length;
      return acc;
    }, 0);
  }, [searchTerm, searchResults]);

  const handleSearchInputChange = useCallback(
    (input: string): void => {
      setSearchTerm(input);
      search?.(input);
    },
    [setSearchTerm, search]
  );

  const previousSearchTerm = usePrevious(searchTerm);

  return (
    <QuestionnaireTreeViewSearchContext.Provider
      value={{
        searchTerm,
        handleSearchInputChange,
        searchResults,
        numberOfSearchResults,
        previousSearchTerm: previousSearchTerm || '',
      }}
    >
      {children}
    </QuestionnaireTreeViewSearchContext.Provider>
  );
}
