import React, { createContext, PropsWithChildren } from 'react';

import { LocalizedThirdPartyIntegration } from '@breathelife/types';

import { useFetchThirdPartyIntegrationsQuery } from '../../../ReactQuery/Admin/ThirdPartyIntegrations/thirdPartyIntegrations.queries';

type ThirdPartyIntegrationsContextValue = {
  thirdPartyIntegrations: LocalizedThirdPartyIntegration[];
};

export const ThirdPartyIntegrationsContext = createContext<ThirdPartyIntegrationsContextValue>({
  thirdPartyIntegrations: [],
});

export function ThirdPartyIntegrationsContextProvider({
  children,
}: PropsWithChildren<Record<string, unknown>>): React.ReactElement {
  const { data: thirdPartyIntegrations } = useFetchThirdPartyIntegrationsQuery();

  return (
    <ThirdPartyIntegrationsContext.Provider value={{ thirdPartyIntegrations: thirdPartyIntegrations || [] }}>
      {children}
    </ThirdPartyIntegrationsContext.Provider>
  );
}
