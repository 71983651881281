import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItemText';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { StoredFile, FileTemplate } from '@breathelife/types';
import { Loader } from '@breathelife/ui-components';

import { IconButton } from '../../../../../Components/Controls';
import { Icon } from '../../../../../Components/Icons';
import { useGetApplicationFile } from '../../../../../Hooks';
import { Application } from '../../../../../Models/Application';
import { DisplayNameTypography } from '../../Documents/Files/Styles';

type Props = {
  application: Application;
  template: FileTemplate;
  storedFile: StoredFile | undefined;
};

export function DocumentToSign(props: Props): React.ReactElement {
  const { application, template, storedFile } = props;
  const { t } = useTranslation();

  const { fetchFile: fetchFileHook, fileToView } = useGetApplicationFile(
    application.id,
    storedFile?.id ?? '',
    useCallback(({ signedUrl }) => {
      window.open(signedUrl, '_blank')?.focus();
    }, [])
  );

  const fetchFile = useCallback(() => {
    if (!storedFile?.id) return;
    fetchFileHook();
  }, [fetchFileHook, storedFile?.id]);

  const signatureRequiredLabel = t('assistedApplication.signatureDrawer.signatureRequired');

  // TODO: DEV-9219: Identify application parties as the Agent answers the questionnaire - https://breathelife.atlassian.net/browse/DEV-9219
  const signatureRequiredValue = `Policy Owner, Insurer`;

  const isLoading: boolean = fileToView.isLoading;

  return (
    <ListItem>
      <Box display='flex' flexDirection='row' my={3}>
        <Box mr={2}>
          {isLoading && <Loader size='24' />}
          {!isLoading && storedFile ? (
            <Icon name='success' variant='green' inverted />
          ) : (
            <Icon name='warning' variant='yellow' fill='none' />
          )}
        </Box>
        <Box display='flex' flexDirection='row' width='100%' justifyContent='space-between'>
          <Box>
            <Box>
              <DisplayNameTypography variant='h4'>{template.name}</DisplayNameTypography>
            </Box>
            {storedFile && (
              <Box>
                <DisplayNameTypography variant='body3'>{storedFile.displayName}</DisplayNameTypography>
              </Box>
            )}
            <Box>
              <DisplayNameTypography variant='body3'>
                {signatureRequiredLabel}: <strong>{signatureRequiredValue}</strong>
              </DisplayNameTypography>
            </Box>
          </Box>
          <Box display='flex' flexWrap='no-wrap' alignItems='center'>
            <Box mx={1}>
              <IconButton icon={<Icon name='eye' />} disabled={isLoading} onClick={fetchFile} />
            </Box>
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
}
