import TabMui from '@material-ui/core/Tab';
import TabsMui from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Permission } from '@breathelife/types';

import { userHasPermission } from '../../Helpers/user';
import { useSelector } from '../../Hooks';

export type Tabs = {
  ['data-testid']: string;
  label: string;
  to: string;
  permissions?: Permission[];
  showWhenNotAllowed?: boolean;
};

type PageTabsProps = {
  tabs: Tabs[];
  defaultTab?: string;
};

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  tab: {
    minWidth: '80px !important',
  },
});

export function PageTabs(props: PageTabsProps): React.ReactElement {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const userPermissions =
    useSelector((store) => {
      return store.leadPlatform.authentication.user?.permissions;
    }) ?? [];

  const { tabs, defaultTab } = props;

  const onSelectedTabChange = useCallback(
    // disable linting error on unused _event
    // eslint-disable-next-line @typescript-eslint/naming-convention
    (_event, value: string) => {
      history.push(value);
    },
    [history]
  );

  const currentTabFromTabs = tabs.find((tab) => location.pathname.includes(tab.to));
  // If current location is not part of any tab, we fallback on default tab if it is set, otherwise we use the first tab
  const currentTab = currentTabFromTabs?.to ?? defaultTab ?? tabs[0].to;

  return (
    <TabsMui
      value={currentTab}
      onChange={onSelectedTabChange}
      variant='scrollable'
      indicatorColor='primary'
      textColor='primary'
      classes={{
        root: classes.root,
      }}
      role='heading'
      aria-level={1}
    >
      {tabs.map((tab) => {
        const allowed = _.isUndefined(tab.permissions) || userHasPermission(userPermissions, tab.permissions);

        if (!allowed && !tab.showWhenNotAllowed) return null;

        return (
          <TabMui
            key={tab.label}
            className={classes.tab}
            label={tab.label}
            value={tab.to}
            disabled={!allowed}
            data-testid={tab['data-testid']}
          />
        );
      })}
    </TabsMui>
  );
}
