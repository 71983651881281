import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useRef } from 'react';

import { hash } from '@breathelife/hash';
import { ScreenName, TypewriterTracking } from '@breathelife/react-tracking';
import { ApplicationMode, ApplicationType, InsuranceScopes, DataExtractType } from '@breathelife/types';

import { useCarrierContext, useDispatch, useNavigation, useSelector, useModalState } from '../../Hooks';
import { Application } from '../../Models/Application';
import { DetailViewType, LeadDetailTab, LeadTab, ModalState, ModalType } from '../../Models/Layout';
import { Lead } from '../../Models/Lead';
import { useCreateDataExtractMutation } from '../../ReactQuery/Admin/ExportData/exportData.mutations';
import { useLaunchNewAssistedApplicationMutation } from '../../ReactQuery/AssistedApplication/assistedApplication.mutations';
import {
  useUpdateLeadArchiveMutation,
  useCreateLeadMutation,
  useUpdateLeadMutation,
} from '../../ReactQuery/Lead/lead.mutations';
import { openRightPanel } from '../../Redux/Layout/LayoutOperations';
import { defaultState as defaultLayoutState, layoutSlice, ModalPayload } from '../../Redux/Layout/LayoutSlice';
import {
  navigateToAssistedApplication,
  navigateToLeadDetail,
  navigateToLeadsList,
} from '../../Redux/Navigation/NavigationOperations';
import { LeadsPageDataContext } from './LeadsPageDataContextProvider';
import { LeadsPageRoot } from './LeadsPageRoot';
import { DataExtractConsentModal } from './Modals/DataExtractConsentModal';

export function LeadsPageContainer(): React.ReactElement {
  const { features, languageSettings } = useCarrierContext();
  const dispatch = useDispatch();
  const { leadTab: navigationLeadsTab } = useNavigation();

  const applicationModeRef = useRef<ApplicationMode>(ApplicationMode.digital);

  const modalState = useSelector((store) => store.leadPlatform.layout.modalState);

  const { leads, tableOptions, setPage, setFilters } = useContext(LeadsPageDataContext);
  const [isOpenDataExtractConsentModal, onOpenDataExtractConsentModal, onCloseDataExtractConsentModal] =
    useModalState();

  const setModalState = useCallback(
    (payload: ModalPayload) => dispatch(layoutSlice.actions.setModalState(payload)),
    [dispatch]
  );
  const updateModalState = useCallback(
    (payload: Partial<ModalState>) => dispatch(layoutSlice.actions.updateModalState(payload)),
    [dispatch]
  );

  // If there are no leads on a page (except for the first page), go to the previous page
  // This usually happens after deleting/archiving a lead, when it was the only lead of the page
  useEffect(() => {
    if (leads?.length === 0 && tableOptions.page > 1) {
      setPage(tableOptions.page - 1);
    }
  }, [leads?.length, tableOptions.page, setPage]);

  const onSearchChange = useCallback((search: string) => setFilters({ $search: search }), [setFilters]);

  const openAssignModal = useCallback(
    (lead: Lead) => {
      dispatch(layoutSlice.actions.setModalState({ modalState: { lead, isOpen: true, type: ModalType.assign } }));
    },
    [dispatch]
  );

  const onSendEmailClick = useCallback(
    (lead: Lead) => {
      setModalState({ modalState: { lead, isOpen: true, type: ModalType.email } });
      TypewriterTracking.viewedScreen({
        screenName: ScreenName.leadNeedsAnalysisEmail,
        // TODO: discuss whether we should keep this. If So, what do we do now with multiple application per lead
        hashedId: lead.applicationId ? hash(lead.applicationId) : null,
      });
    },
    [setModalState]
  );

  const onCopyClick = (application: Application): void => {
    setModalState({ modalState: { application, isOpen: true, type: ModalType.copyApplication } });
  };

  const onDeleteClick = useCallback(
    (lead: Lead) => {
      setModalState({ modalState: { lead, isOpen: true, type: ModalType.deleteLead } });
      TypewriterTracking.viewedScreen({
        screenName: ScreenName.leadDelete,
        // TODO: discuss whether we should keep this. If So, what do we do now with multiple application per lead
        hashedId: lead.applicationId ? hash(lead.applicationId) : null,
      });
    },
    [setModalState]
  );

  const onDownloadFilesClick = useCallback(() => {
    setModalState({ modalState: { isOpen: true, type: ModalType.downloadFiles } });
  }, [setModalState]);

  const updateLeadMutation = useUpdateLeadMutation();

  const createDataExtractMutation = useCreateDataExtractMutation();

  const launchNewAssistedApplicationMutation = useLaunchNewAssistedApplicationMutation({
    onSuccess: (application) => {
      if (application && application.leadId) {
        updateLeadMutation.mutate({
          id: application.leadId,
        });
        dispatch(navigateToAssistedApplication(application.id, application.leadId));
      }
    },
  });

  const createLeadMutation = useCreateLeadMutation({
    onSuccess: (createdLead) => {
      launchNewAssistedApplicationMutation.mutate({
        leadId: createdLead.id,
        type: ApplicationType.transactionFlow,
        insuranceScopes: [InsuranceScopes.life],
        mode: applicationModeRef.current,
      });
    },
  });

  const isCreatingLeadAndLaunchingApplication =
    createLeadMutation.isLoading || launchNewAssistedApplicationMutation.isLoading;

  const updateLeadArchiveMutation = useUpdateLeadArchiveMutation({
    onSuccess: () => {
      dispatch(navigateToLeadsList(navigationLeadsTab));
      setModalState({ modalState: defaultLayoutState.modalState });
    },
  });

  const onToggleArchiveClick = useCallback(
    (lead: Lead, userId: string) => {
      if (lead.assignedToId === userId) {
        updateLeadArchiveMutation.mutate({ leadId: lead.id, archive: !lead.archived });
      } else {
        setModalState({ modalState: { lead, isOpen: true, type: ModalType.archiveConfirmation } });
      }
    },
    [setModalState, updateLeadArchiveMutation]
  );

  const onCreateLeadClick = useCallback(
    (mode: ApplicationMode = ApplicationMode.digital) => {
      applicationModeRef.current = mode;
      if (features.hiddenLeads?.enabled) {
        createLeadMutation.mutate({
          communicationLanguage: languageSettings.default,
        });
      } else {
        dispatch(openRightPanel(DetailViewType.create, LeadDetailTab.personalInfo));
      }
    },
    [dispatch, features.hiddenLeads?.enabled, languageSettings.default, createLeadMutation]
  );

  const onTableRowClick = useCallback(
    (lead?: Lead) => {
      if (!lead || !lead?.id) return;
      dispatch(navigateToLeadDetail(lead.id, navigationLeadsTab));

      TypewriterTracking.viewedScreen({
        screenName: ScreenName.leadProfile,
        hashedId: lead?.applicationId ? hash(lead?.applicationId) : null,
      });
    },
    [dispatch, navigationLeadsTab]
  );

  const onConfirmDataExtractConsentModal = useCallback(() => {
    onCloseDataExtractConsentModal();

    createDataExtractMutation.mutate({
      type: DataExtractType.marketingDataExtract,
    });
  }, [onCloseDataExtractConsentModal, createDataExtractMutation]);

  useEffect(() => {
    if (_.isUndefined(navigationLeadsTab)) return;

    const trackingScreenName = {
      [LeadTab.active]: ScreenName.leadsListActive,
      [LeadTab.archived]: ScreenName.leadsListArchived,
    };

    TypewriterTracking.viewedScreen({
      screenName: trackingScreenName[navigationLeadsTab],
      hashedId: null,
    });

    setFilters({ archived: navigationLeadsTab === LeadTab.archived });
  }, [navigationLeadsTab, setFilters]);

  return (
    <React.Fragment>
      <LeadsPageRoot
        isCreatingLeadAndLaunchingApplication={isCreatingLeadAndLaunchingApplication}
        modalState={modalState}
        setModalState={setModalState}
        updateModalState={updateModalState}
        openAssignModal={openAssignModal}
        onSendEmailClick={onSendEmailClick}
        onCopyClick={onCopyClick}
        onDeleteClick={onDeleteClick}
        onDownloadFilesClick={onDownloadFilesClick}
        onToggleArchiveClick={onToggleArchiveClick}
        onCreateLeadClick={onCreateLeadClick}
        onClickMarketingDataExtractButton={onOpenDataExtractConsentModal}
        onSearchChange={onSearchChange}
        onTableRowClick={onTableRowClick}
        selectedTab={navigationLeadsTab || LeadTab.active}
      />
      <DataExtractConsentModal
        isOpen={isOpenDataExtractConsentModal}
        onClose={onCloseDataExtractConsentModal}
        onConfirm={onConfirmDataExtractConsentModal}
      />
    </React.Fragment>
  );
}
