import Box from '@material-ui/core/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as ChevronRight } from '../../../../../../Assets/chevron-right-icon.svg';
import Typography from '../../../../../../Components/Typography';
import { Application } from '../../../../../../Models/Application';
import { LeadStatus } from '../../../../../../Models/Lead';
import { ContentHeading, CtaButton } from '../../Styles';
import { ApplicationHeaderInfo } from './ApplicationHeaderInfo';
import { ApplicationHeaderProgress } from './ApplicationHeaderProgress';

type ApplicationListProps = {
  applications: Application[];
  leadStatus: LeadStatus;
  toggleDetailView: (index: number) => void;
};

const AppContainer = styled(Box)`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[30]};
`;

export function ApplicationList({
  applications,
  leadStatus,
  toggleDetailView,
}: ApplicationListProps): React.ReactElement {
  const { t } = useTranslation();

  return (
    <div>
      <ContentHeading py={2} px={3}>
        <Typography variant='body3' grey={70}>
          {t('tabs.needsAnalysis.sent')}
        </Typography>
      </ContentHeading>
      {applications.map((application, index) => (
        <AppContainer key={index} py={2} px={3} display='flex' justifyContent='space-between'>
          <div>
            <ApplicationHeaderInfo application={application} />
          </div>

          <Box textAlign='right'>
            <Box mb={2}>
              <ApplicationHeaderProgress application={application} status={leadStatus} />
            </Box>

            <CtaButton endIcon={<ChevronRight />} variant='outlined' onClick={() => toggleDetailView(index)}>
              {t('tabs.needsAnalysis.details')}
            </CtaButton>
          </Box>
        </AppContainer>
      ))}
    </div>
  );
}
