import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/AddRounded';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ApplicationMode, Permission } from '@breathelife/types';

import emptyListImage from '../../../../Assets/empty-application-list.svg';
import emptyFilteredListImage from '../../../../Assets/filtered-list-empty.svg';
import { ActionButton } from '../../../../Components/Button/ActionButton';
import { LaunchApplicationMenuButton } from '../../../../Components/LaunchApplicationMenuButton/LaunchApplicationMenuButton';
import { RestrictedToPermission } from '../../../../Components/Restricted/RestrictedToPermission';
import { CarrierContext } from '../../../../Context/CarrierContext';
import { getUserDisplayName } from '../../../../Helpers/user';
import { useSelector } from '../../../../Hooks';
import { EmptyListContainer, Img, ListText, ListTitle } from './Styles';

export type Props = {
  onCreateLeadClick: (mode?: ApplicationMode) => void;
  hasFilters: boolean;
  isCreatingLeadAndLaunchingApplication: boolean;
  allowLeadCreation: boolean;
};
export function TabActiveEmptyListView(props: Props): React.ReactElement {
  const { onCreateLeadClick, isCreatingLeadAndLaunchingApplication } = props;
  const { t } = useTranslation();
  const { features } = useContext(CarrierContext);
  const authentication = useSelector((store) => store.leadPlatform.authentication);
  const isHiddenLeadsEnabled: boolean = !!features.hiddenLeads?.enabled;
  const isPaperAppModeEnabled: boolean = !!features.questionnaireScreen?.enabled;

  const displayName = authentication.user ? ` ${getUserDisplayName(authentication.user)}` : '';
  return (
    <EmptyListContainer textAlign='center' pt={12} minHeight={400}>
      <Box mb={3}>
        <Img src={props.hasFilters ? emptyFilteredListImage : emptyListImage} alt='' />
      </Box>
      <Box mb={1.5}>
        <ListTitle variant='h1' grey={70}>{`${t('tabs.active.empty.hello', { name: displayName })}!`}</ListTitle>
      </Box>
      <ListText variant='body1' grey={60} component='div'>
        {t('tabs.active.empty.noApplication')}
      </ListText>
      {props.allowLeadCreation && (
        <RestrictedToPermission permission={Permission.LeadCreate}>
          <React.Fragment>
            {!features.hiddenLeads?.enabled && (
              <ListText variant='body1' grey={60} component='div'>
                {t('tabs.active.empty.startBy')}
              </ListText>
            )}
            {features.leadCreation.enabled && (
              <Box mt={3}>
                {isHiddenLeadsEnabled && isPaperAppModeEnabled ? (
                  <RestrictedToPermission permission={Permission.ApplicationLaunchMe}>
                    <LaunchApplicationMenuButton
                      onCreateApplication={onCreateLeadClick}
                      isCreatingApplication={isCreatingLeadAndLaunchingApplication}
                    />
                  </RestrictedToPermission>
                ) : (
                  <ActionButton
                    onClick={() => onCreateLeadClick()}
                    startIcon={<AddIcon htmlColor='white' />}
                    color='primary'
                    variant='contained'
                    disabled={isHiddenLeadsEnabled ? isCreatingLeadAndLaunchingApplication : false}
                  >
                    {isHiddenLeadsEnabled ? t('cta.launchApplication') : t('leadsListTable.addNew')}
                  </ActionButton>
                )}
              </Box>
            )}
          </React.Fragment>
        </RestrictedToPermission>
      )}
    </EmptyListContainer>
  );
}
