import Box from '@material-ui/core/Box';
import styled from 'styled-components';

import Typography from '../../../../Components/Typography';
import { emptyTableHeight } from '../../../../Models/Layout';

export const EmptyListContainer = styled(Box)`
  background-color: ${(props) => props.theme.colors.grey[30]};
  height: ${() => `calc(100vh - ${emptyTableHeight}px)`};
`;

export const ListTitle = styled(Typography)`
  && {
    line-height: 1.2em;
  }
`;

export const ListText = styled(Typography)`
  && {
    line-height: 1.2em;
  }
`;

export const Img = styled.img`
  display: inline-block;
`;
