import { NodeIds } from '@breathelife/insurance-form-builder';
import {
  NodeIdToAnswerPathMap,
  RenderingSectionGroup,
  isRenderingRepeatedSectionGroup,
  NodeIdAnswersResolver,
} from '@breathelife/questionnaire-engine';
import { Answers } from '@breathelife/types';

export function getInsuredFullName(answers: Answers, nodeIdToAnswerPathMap: NodeIdToAnswerPathMap): string {
  const answersResolver = new NodeIdAnswersResolver(nodeIdToAnswerPathMap);
  const firstName = answersResolver.getAnswer(answers, NodeIds.insuredFirstName, {});
  const lastName = answersResolver.getAnswer(answers, NodeIds.insuredLastName, {});

  return [firstName, lastName].filter(Boolean).join(' ');
}

export enum SectionGroupId {
  insuredPeople = 'insuredPeople',
  contract = 'contract',
}

function getSectionGroupId(sectionGroup: RenderingSectionGroup): string {
  if (isRenderingRepeatedSectionGroup(sectionGroup)) {
    return sectionGroup.metadata.parentId;
  } else {
    return sectionGroup.id;
  }
}

export function isInsuredPeopleSectionGroup(sectionGroup: RenderingSectionGroup): boolean {
  const sectionGroupId = getSectionGroupId(sectionGroup);

  return sectionGroupId === SectionGroupId.insuredPeople;
}

export function convertYesNoToBoolean(value: unknown): boolean | undefined {
  switch (value) {
    case 'yes':
      return true;
    case 'no':
      return false;
    default:
      return undefined;
  }
}
