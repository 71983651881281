// NOTE: `ExpansionPanel` is renamed to `Accordion` in later mui versions. Renaming this import will be necessary when we upgrade.
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Divider } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  QuestionnaireBlueprintRenderOn,
  InsuranceScopes,
  PlatformType,
  PdfDocumentType,
  InsuranceModule,
  ApplicationMode,
  QuestionnaireBlueprintCopyableOption,
} from '@breathelife/types';
import { AutoCompleteMultiple, AutocompleteOption, Box } from '@breathelife/ui-components';

import { useCarrierContext } from '../../../../../Hooks';
import { BlueprintContexts } from '../Summaries/BlueprintContexts';
import { EditorDropdown } from './EditorDropdown';
import { ModuleSelection } from './ModuleSelection';

type Props = {
  tag: 'section' | 'subsection' | 'question' | 'field';
  disabled: boolean;
  platform: { selected: PlatformType[]; onChange: (values: PlatformType[]) => void };
  renderOn: {
    selected: QuestionnaireBlueprintRenderOn[];
    onChange: (values: QuestionnaireBlueprintRenderOn[]) => void;
  };
  scope: { selected: InsuranceScopes[]; onChange: (values: InsuranceScopes[]) => void };
  dataLabel: { selected?: string; options: AutocompleteOption[]; onChange: (updatedSelection: string | null) => void };
  pdfDocument?: { selected: PdfDocumentType[]; onChange: (values: PdfDocumentType[]) => void };
  insuranceModule?: { selected: InsuranceModule; onChange: (module: InsuranceModule) => void };
  applicationModes?: {
    selected: ApplicationMode[];
    onChange: (updatedType: ApplicationMode[]) => void;
  };
  copyable: {
    selected: QuestionnaireBlueprintCopyableOption;
    onChange: (updatedSelection: string | null) => void;
    disabled?: boolean;
  };
};

const BlueprintContextContainer = styled.div`
  display: flex;
  align-items: center;

  & > :last-child {
    margin-left: 4px;
  }
`;

export function AdvancedBlueprintOptions({
  disabled,
  tag,
  platform,
  renderOn,
  scope,
  pdfDocument,
  dataLabel,
  insuranceModule,
  applicationModes,
  copyable,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  const { features } = useCarrierContext();

  const dataLabelEntry = useMemo(() => {
    if (!dataLabel?.options.length) {
      return null;
    }

    const { options, selected, onChange } = dataLabel;
    return (
      <Box pt={2}>
        <EditorDropdown
          label={t('admin.questionnaireManagement.input.dataLabel')}
          defaultOption={options[0]}
          disabled={disabled}
          selectedOptionValue={selected}
          options={options}
          onChange={onChange}
        />
      </Box>
    );
  }, [dataLabel, disabled, t]);

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <BlueprintContextContainer>
          {t(`admin.questionnaireManagement.advancedOptions.${tag}`)}

          <BlueprintContexts
            platforms={platform.selected}
            scopes={scope.selected}
            renderOn={renderOn.selected}
            insuranceModules={insuranceModule && insuranceModule.selected ? [insuranceModule.selected] : undefined}
          />
        </BlueprintContextContainer>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails>
        <Box display='flex' flexDirection='column' width='100%'>
          <AutoCompleteMultiple
            id='platform-type-selection'
            label={t('admin.questionnaireManagement.platforms.title')}
            disabled={disabled}
            optionInfo={{
              items: Object.values(PlatformType),
              toOption: (item) => ({ value: item, label: t(`admin.questionnaireManagement.platforms.${item}`) }),
              selectedItems: platform.selected,
              onChange: platform.onChange,
            }}
          />
          <Box pt={2}>
            <AutoCompleteMultiple
              id='render-on-selection'
              label={t('admin.questionnaireManagement.rendering.title')}
              disabled={disabled}
              optionInfo={{
                items: Object.values(QuestionnaireBlueprintRenderOn),
                toOption: (item) => ({ value: item, label: t(`admin.questionnaireManagement.rendering.${item}`) }),
                selectedItems: renderOn.selected,
                onChange: renderOn.onChange,
              }}
            />
          </Box>
          <Box pt={2}>
            <AutoCompleteMultiple
              id='insurance-scopes-selection'
              label={t('admin.questionnaireManagement.scope.title')}
              disabled={disabled}
              optionInfo={{
                items: Object.values(InsuranceScopes),
                toOption: (item) => ({ value: item, label: t(`admin.questionnaireManagement.scope.${item}`) }),
                selectedItems: scope.selected,
                onChange: scope.onChange,
              }}
            />
          </Box>
          {applicationModes && (
            <Box pt={2}>
              <AutoCompleteMultiple
                id='questionnaire-application-mode-selection'
                label={t('admin.questionnaireManagement.applicationMode.title')}
                disabled={disabled}
                optionInfo={{
                  selectedItems: applicationModes.selected,
                  items: Object.values(ApplicationMode),
                  toOption: (text: ApplicationMode) => ({
                    label: t(`admin.questionnaireManagement.applicationMode.${text}`),
                    value: text,
                  }),
                  onChange: applicationModes.onChange,
                }}
              />
            </Box>
          )}
          {pdfDocument && (
            <Box pt={2}>
              <AutoCompleteMultiple
                id='pdf-documents-selection'
                label={t('admin.questionnaireManagement.pdfDocuments.title')}
                disabled={disabled}
                optionInfo={{
                  items: Object.values(PdfDocumentType),
                  toOption: (item) => ({ value: item, label: t(`admin.questionnaireManagement.pdfDocuments.${item}`) }),
                  selectedItems: pdfDocument.selected,
                  onChange: pdfDocument.onChange,
                }}
              />
            </Box>
          )}
          {insuranceModule && (
            <Box pt={2}>
              <ModuleSelection
                selectedModule={insuranceModule.selected}
                onChange={insuranceModule.onChange}
                disabled={disabled}
              />
            </Box>
          )}
          {dataLabelEntry}
          {features.applicationCopy?.enabled && (
            <Box pt={2}>
              <EditorDropdown
                label={t('admin.questionnaireManagement.copyable.title')}
                defaultOption={{
                  label: t(`admin.questionnaireManagement.copyable.${QuestionnaireBlueprintCopyableOption.none}`),
                  value: QuestionnaireBlueprintCopyableOption.none,
                }}
                disabled={disabled || copyable.disabled}
                selectedOptionValue={copyable.selected}
                options={Object.values(QuestionnaireBlueprintCopyableOption).map((option) => ({
                  label: t(`admin.questionnaireManagement.copyable.${option}`),
                  value: option,
                }))}
                onChange={copyable.onChange}
              />
            </Box>
          )}
        </Box>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
