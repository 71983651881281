import _ from 'lodash';
import styled from 'styled-components';

import { Select } from '../../../Components/Select/Select';

export const ModalContent = styled.div`
  padding-bottom: 24px;
  color: ${(props) => props.theme.colors.grey[90]};
`;

export const StyledSelect = styled(Select)`
  &&&& {
    width: 100%;
  }
`;

export const ActionButtonContainer = styled.div`
  display: grid;
  grid-gap: 8px;
  padding: 8px 12px;
  grid-auto-flow: column;
  justify-content: end;
`;
