import React from 'react';

import { Image as SharedImage, ImageProps as SharedImageProps } from '@breathelife/ui-components';

import { useCarrierContext } from '../../Hooks';

type ImageProps = Omit<SharedImageProps, 'cloudinary'>;

export function Image(props: ImageProps): React.ReactElement {
  const { cloudinaryConfig } = useCarrierContext();
  const { className, width, height, src, alt, radius, isProfile } = props;

  const cloudinaryOptions = {
    cloudName: cloudinaryConfig?.cloudName ?? '',
    folder: cloudinaryConfig?.folder ?? '',
  };
  return (
    <SharedImage
      alt={alt}
      className={className}
      cloudinary={cloudinaryOptions}
      height={height}
      src={src}
      width={width}
      radius={radius}
      isProfile={isProfile}
    />
  );
}
