import { Answers } from '@breathelife/types';

import { Application } from '../Models/Application';
import { ImportableLead, Lead } from '../Models/Lead';
import ApiService from './ApiService';

export type UpdateApplicationResponse = { application: Application; answers: Answers };

export async function createLeadWithAnswers(data: ImportableLead): Promise<Lead> {
  const response = await ApiService.createLeadWithDebugToolbar<Lead>(data);
  return response.data;
}

export async function updateApplication(
  applicationId: string,
  data: { subsectionId: string }
): Promise<UpdateApplicationResponse> {
  const response = await ApiService.updateApplicationWithDebugToolbar<UpdateApplicationResponse>(applicationId, data);
  return response.data;
}

export async function createOrUpdateApplication(
  leadId: number,
  applicationId: string | undefined,
  data: { answers: Answers }
): Promise<Application> {
  const response = await ApiService.upsertApplicationWithDebugToolbar<Application>(leadId, applicationId, data);
  return response.data;
}
