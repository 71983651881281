import Divider from '@material-ui/core/Divider';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@breathelife/ui-components';

import { Helmet } from '../../../Components/Helmet';
import Typography from '../../../Components/Typography';
import { ThirdPartyIntegrationsBoard } from './ThirdPartyIntegrationsBoard';
import { ThirdPartyIntegrationsContextProvider } from './ThirdPartyIntegrationsContextProvider';

export function ThirdPartyIntegrationsView(): React.ReactElement {
  const { t } = useTranslation();

  const pageTitle = t('pageTitles.thirdPartyIntegrations');

  return (
    <React.Fragment>
      <Helmet text={pageTitle} />

      <Box m={2} display={'flex'} justifyContent={'space-between'}>
        <div>
          <Typography variant='h1'>{pageTitle}</Typography>
          <Typography variant='body1'>Work in progress..</Typography>
        </div>
      </Box>

      <Divider />

      <Box m={2}>
        <ThirdPartyIntegrationsContextProvider>
          <ThirdPartyIntegrationsBoard />
        </ThirdPartyIntegrationsContextProvider>
      </Box>
    </React.Fragment>
  );
}
