import { Grid, GridSize } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Language, Localizable } from '@breathelife/types';

import { TextInput } from '../../../../../Pages/Admin/Questionnaire/QuestionnaireEditor/Components/TextInput';

type Props = {
  consentModalHeader: Partial<Localizable>;
  consentText: Partial<Localizable>;
  disabled: boolean;
  onConsentModalHeaderChange: (value: string, language: Language) => void;
  onConsentTextChange: (value: string, language: Language) => void;
  onConsentModalHeaderBlur?: () => void;
  onConsentTextBlur?: () => void;
  languages: Language[];
  spacing: 1 | 2;
};

export function AgreeFieldEditor({
  consentModalHeader,
  consentText,
  disabled,
  onConsentModalHeaderChange,
  onConsentTextChange,
  onConsentTextBlur,
  onConsentModalHeaderBlur,
  languages,
  spacing,
}: Props): React.ReactElement | null {
  const { t } = useTranslation();

  if (!languages.length) {
    console.error('languages must be provided.');
    return null;
  } else if (languages.length > 12) {
    console.error('More than 12 languages is not supported.');
    return null;
  }

  // This component is used when creating new Agree field blueprints (where all supported languages must be displayed)
  //   and when editing those blueprints(when only one language is shown at a time).
  const gridItemSize: GridSize = (languages.length === 1 ? 9 : 12 / languages.length) as GridSize;
  const showLanguageLabel = languages.length > 1;

  return (
    <React.Fragment>
      <Grid container spacing={spacing} alignItems='center'>
        {languages.map((language) => (
          <Grid key={`consentModalHeader-${language}`} item xs={gridItemSize}>
            <TextInput
              helperTooltipText={t('admin.questionnaireManagement.input.consentModalHeaderTooltip')}
              label={
                showLanguageLabel
                  ? t('admin.questionnaireManagement.input.consentModalHeaderAndLanguage', {
                      language: t(`language.${language}`),
                    })
                  : t('admin.questionnaireManagement.input.consentModalHeader')
              }
              multiline={true}
              value={consentModalHeader[language]}
              disabled={disabled}
              onChange={(event) => onConsentModalHeaderChange(event.target.value, language)}
              onBlur={() => onConsentModalHeaderBlur && onConsentModalHeaderBlur()}
            />
          </Grid>
        ))}
        {languages.map((language) => (
          <Grid key={`consentTextTooltip-${language}`} item xs={gridItemSize}>
            <TextInput
              helperTooltipText={t('admin.questionnaireManagement.input.consentTextTooltip')}
              label={
                showLanguageLabel
                  ? t('admin.questionnaireManagement.input.consentTextAndLanguage', {
                      language: t(`language.${language}`),
                    })
                  : t('admin.questionnaireManagement.input.consentText')
              }
              multiline={true}
              value={consentText[language]}
              disabled={disabled}
              onChange={(event) => onConsentTextChange(event.target.value, language)}
              onBlur={() => onConsentTextBlur && onConsentTextBlur()}
            />
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
}
