import Box from '@material-ui/core/Box';
import styled from 'styled-components';

import { LinkButton as SharedLinkButton } from '@breathelife/ui-components';

export const AuthCallbackContainer = styled(Box)`
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.colors.grey[20]};
`;

export const AuthCallbackContent = styled(Box)`
  background-color: ${(props) => props.theme.colors.grey[0]};
  border-radius: 3px;
  max-width: 380px;
  width: 100%;
`;

export const LinkButton = styled(SharedLinkButton)`
  width: 100%;
  border-radius: 2px;
`;
