import { ESignCeremonyStatus } from '@breathelife/types';

const translation: { [key in ESignCeremonyStatus]: string } = {
  [ESignCeremonyStatus.DRAFT]: '',
  [ESignCeremonyStatus.IN_PROGRESS]: 'En attente de signature',
  [ESignCeremonyStatus.READY]: 'En attente de signature',
  [ESignCeremonyStatus.SENT]: 'En attente de signature',
  [ESignCeremonyStatus.COMPLETED]: 'Signé',
  [ESignCeremonyStatus.EMAIL_BOUNCED]: 'Courriel non transmis',
  [ESignCeremonyStatus.DECLINED]: 'Signature refusée',
  [ESignCeremonyStatus.OPT_OUT]: 'Le signataire s’est retiré de la signature',
  [ESignCeremonyStatus.CANCELLED]: 'Demande de signature annulée',
  [ESignCeremonyStatus.EXPIRED]: 'Demande de signature expirée',
  [ESignCeremonyStatus.SIGNER_LOCKED_OUT]: 'Signataire bloqué hors de la signature',
  [ESignCeremonyStatus.SEND_PACKAGE_ERROR]: "Erreur lors l'envoie de la demande",
};
export default translation;
