import Box from '@material-ui/core/Box';
import React from 'react';

import { LIST_FILTER_HEIGHT } from '../../Models/Layout';

type ListFilterContainerProps = {
  children: React.ReactNode;
};

export function ListFilterContainer(props: ListFilterContainerProps): React.ReactElement {
  return (
    <Box display='flex' alignItems='center' justifyContent='space-between' width='100%' height={LIST_FILTER_HEIGHT}>
      {props.children}
    </Box>
  );
}
