import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Permission } from '@breathelife/types';
import { TextAnswer } from '@breathelife/ui-components';

import { RestrictedToPermission } from '../../../../../Components/Restricted/RestrictedToPermission';
import { US_STATES } from '../../../../../Helpers/locations';
import { useCarrierContext, useDispatch } from '../../../../../Hooks';
import { DetailViewType } from '../../../../../Models/Layout';
import { Lead } from '../../../../../Models/Lead';
import { layoutSlice } from '../../../../../Redux/Layout/LayoutSlice';
import { ContentHeading } from '../Styles';
import { BestTimeToReach } from './BestTimeToReach';
import { DateText } from './DateText';
import { CtaButton, LongWordBreak } from './Styles';
import { SubscriptionToolLink } from './SubscriptionToolLink';

export type Props = {
  lead: Lead;
};

export function PersonalInfoReadView(props: Props): React.ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { countryCode, unsubToolUrl } = useCarrierContext();
  const isEditable = props.lead?.status === 'new' || props.lead?.status === 'invited';
  const communications = props.lead?.['leads-communication'];

  function onEditClick(): void {
    dispatch(layoutSlice.actions.updateRightPanelState({ type: DetailViewType.edit }));
  }

  return (
    <React.Fragment>
      <ContentHeading py={2} px={2.5}>
        <DateText lead={props.lead} />

        {isEditable && (
          <RestrictedToPermission permission={Permission.LeadCreate}>
            <CtaButton variant='outlined' onClick={onEditClick} size='small'>
              {t('cta.edit')}
            </CtaButton>
          </RestrictedToPermission>
        )}
      </ContentHeading>

      <Box p={3}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <TextAnswer
              data-testid='personalInfoView-fname'
              label={t('inputs.firstName')}
              value={props.lead?.firstName || ''}
            />
          </Grid>

          <Grid item xs={6}>
            <TextAnswer
              data-testid='personalInfoView-lname'
              label={t('inputs.lastName')}
              value={props.lead?.lastName || ''}
            />
          </Grid>

          <Grid item xs={6}>
            <TextAnswer
              label={t('inputs.language')}
              value={
                props.lead && props.lead.communicationLanguage
                  ? t(`language.${props.lead.communicationLanguage}`)
                  : '--'
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <LongWordBreak>
                  <TextAnswer label={t('inputs.email')} value={props.lead?.email || ''} />
                </LongWordBreak>
              </Grid>
              <Grid item xs={6}>
                {unsubToolUrl && <SubscriptionToolLink url={unsubToolUrl} />}
              </Grid>
            </Grid>
          </Grid>

          {props.lead?.phoneNumber && (
            <React.Fragment>
              <Grid item xs={6}>
                <TextAnswer label={t('inputs.phoneNumber')} value={props.lead.phoneNumber || ''} />
              </Grid>
              <Grid item xs={6} />
            </React.Fragment>
          )}

          <Grid item xs={12}>
            <Grid container spacing={2}>
              {props.lead?.postalCode && (
                <Grid item xs={6}>
                  <TextAnswer
                    label={countryCode === 'US' ? t('inputs.zipCode') : t('inputs.postalCode')}
                    value={props.lead.postalCode || ''}
                  />
                </Grid>
              )}
              {props.lead?.region && (
                <Grid item xs={6}>
                  <TextAnswer
                    label={countryCode === 'US' ? t('inputs.state') : t('inputs.province')}
                    value={
                      countryCode === 'US'
                        ? US_STATES[props.lead.region]
                        : t(`canadianProvinces.${props.lead.region}`) || ''
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {communications?.['leads-communication-schedules'] && (
        <Box p={3}>
          <BestTimeToReach
            preferredMethods={communications.preferredMethods}
            communicationSchedules={communications['leads-communication-schedules']}
          />
        </Box>
      )}
    </React.Fragment>
  );
}
