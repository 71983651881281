import MuiButton, { ButtonProps } from '@material-ui/core/Button';
import React from 'react';

import Typography from '../Typography';
import { StyledLoader } from './Styles';

export const SubmitButton = React.forwardRef(
  (
    props: { isLoading?: boolean } & Omit<ButtonProps, 'variant' | 'color'>,
    ref: React.Ref<any>
  ): React.ReactElement => {
    const { children, isLoading, onClick: onClickProp, onSubmit: onSubmitProp, ...buttonProps } = props;

    const onClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
      if (isLoading) return;
      onClickProp?.(event);
    };

    const onSubmit: React.FormEventHandler<HTMLButtonElement> = (event) => {
      if (isLoading) return;
      onSubmitProp?.(event);
    };

    return (
      <MuiButton {...buttonProps} onClick={onClick} onSubmit={onSubmit} variant='contained' color='primary' ref={ref}>
        {isLoading ? (
          <StyledLoader size='20px' color='secondary' />
        ) : (
          <Typography variant='button'>{children}</Typography>
        )}
      </MuiButton>
    );
  }
);
