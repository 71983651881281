import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { DisplayQuestionnaireBuilder } from '@breathelife/types';

import { QueryId } from '../../../ReactQuery/common/common.types';
import { notificationSlice } from '../../../Redux/Notification/NotificationSlice';
import ApiService from '../../../Services/ApiService';
import {
  PIXEL_GAP_BETWEEN_PAGES,
  transformPagesPosition,
  transformPdfEntitiesPosition,
} from './questionnaireBuilderHelpers';

async function uploadPdfQuestionnaire({
  pdfUriData,
  fileName,
}: {
  pdfUriData: string;
  fileName: string;
}): Promise<DisplayQuestionnaireBuilder | void> {
  try {
    const { data } = await ApiService.admin.uploadPdfForQuestionnaireBuilder(pdfUriData, fileName);
    if (data) {
      const displayedPages = transformPagesPosition(data.pdfPages, PIXEL_GAP_BETWEEN_PAGES);
      return {
        ...data,
        pdfPages: displayedPages,
        pdfEntities: transformPdfEntitiesPosition(data.pdfEntities, displayedPages),
      };
    }
  } catch (error: any) {
    throw new Error('There was a problem with the pdf upload');
  }
}

export function useUploadPdfQuestionnaireMutation(
  options?: UseMutationOptions<DisplayQuestionnaireBuilder | void, unknown, { pdfUriData: string; fileName: string }>
): UseMutationResult<DisplayQuestionnaireBuilder | void, unknown, { pdfUriData: string; fileName: string }> {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useMutation<DisplayQuestionnaireBuilder | void, unknown, { pdfUriData: string; fileName: string }, unknown>(
    ({ pdfUriData, fileName }) => uploadPdfQuestionnaire({ pdfUriData, fileName }),
    {
      onSuccess: async (data, variables, context) => {
        dispatch(
          notificationSlice.actions.setSuccess({
            message: t('notifications.questionnaireBuilderPdfUploaded'),
            autoHideDuration: 3000,
            dataTestId: 'notification-questionnaire-builder-upload-pdf-success',
          })
        );

        await queryClient.invalidateQueries(QueryId.questionnaireBuilder);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
    }
  );
}
