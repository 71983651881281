import { InsuranceEntities } from '@breathelife/insurance-entities';
import { NodeIds } from '@breathelife/insurance-form-builder';
import { NodeIdAnswersResolver, NodeIdToAnswerPathMap } from '@breathelife/questionnaire-engine';
import { Answers } from '@breathelife/types';

export type ProposedInsuredTab = {
  name?: string;
  index: number;
  surrogateId?: string;
};

export function getInsuredPeopleTabs(
  insuranceEntities: InsuranceEntities,
  answers?: Answers,
  nodeIdToAnswerPathMap?: NodeIdToAnswerPathMap
): ProposedInsuredTab[] {
  const tabs: ProposedInsuredTab[] = [];
  if (!answers || !nodeIdToAnswerPathMap) return tabs;

  let insuredPeopleCount = 1;

  try {
    const answersResolver = new NodeIdAnswersResolver(nodeIdToAnswerPathMap);
    const repetitionCount = answersResolver.getRepetitionCount(answers, NodeIds.insuredPeople, {});
    if (repetitionCount) {
      insuredPeopleCount = repetitionCount;
    }
  } catch (error) {
    // 'insured-people' node was not an array
    return tabs;
  }

  for (let index = 0; index < insuredPeopleCount; index++) {
    const tabData: ProposedInsuredTab = { index };

    try {
      const proposedInsured = insuranceEntities.getProposedInsured(answers, index);
      if (proposedInsured) {
        const { firstName, lastName, surrogateId } = proposedInsured;
        if (firstName || lastName) {
          tabData.name = [firstName, lastName].filter(Boolean).join(' ');
        }
        tabData.surrogateId = surrogateId;
      }
    } catch (error) {
      // entity generator has failed but let's still add a new index
    } finally {
      tabs.push(tabData);
    }
  }

  return tabs;
}
