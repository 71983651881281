import {
  DisplayQuestionnaireBuilderPdfPage,
  QuestionnaireBuilderPdfEntity,
  QuestionnaireBuilderPdfPage,
} from '@breathelife/types';

export const PIXEL_GAP_BETWEEN_PAGES = 100;

const getPixelHeightFromUnit = (units: number): number => {
  return units * 26;
};

const getPixelWidthFromUnit = (units: number): number => {
  return units * 26;
};

export function transformPdfEntitiesPosition(
  pdfEntities: QuestionnaireBuilderPdfEntity[],
  pages: DisplayQuestionnaireBuilderPdfPage[]
): QuestionnaireBuilderPdfEntity[] {
  return pdfEntities.map((item) => {
    const pageTop = pages[item.position.page].top;
    const newPosition = {
      ...item.position,
      x: getPixelWidthFromUnit(item.position.x),
      y: pageTop + getPixelHeightFromUnit(item.position.y),
    };
    return {
      ...item,
      position: newPosition,
    };
  });
}

export function transformPagesPosition(
  pages: QuestionnaireBuilderPdfPage[],
  pxGapBetweenPages: number
): DisplayQuestionnaireBuilderPdfPage[] {
  let currentPageY = 0;
  return pages.reduce((acc: DisplayQuestionnaireBuilderPdfPage[], page, pageIndex) => {
    const pageHeightInPixels = getPixelHeightFromUnit(page.height);
    acc[pageIndex] = {
      ...page,
      height: pageHeightInPixels,
      width: getPixelWidthFromUnit(page.width),
      top: currentPageY,
    };
    currentPageY += pageHeightInPixels + pxGapBetweenPages;
    return acc;
  }, []);
}
