import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InsuranceScopes, SettingsData } from '@breathelife/types';

import { Button } from '../../../Components/Button/Button';
import { Checkbox, CheckboxGroup } from '../../../Components/Checkbox/Checkbox';
import RadioGroup, { Radio } from '../../../Components/RadioGroup/RadioGroup';
import Typography from '../../../Components/Typography';
import { getOptionsFromEnum } from '../../../Helpers/options';
import { StyledGrid } from './Styles';

export type InsuranceScopesSettings = Pick<SettingsData, 'insuranceScopes' | 'defaultInsuranceScope'>;

type Props = {
  insuranceScopes: InsuranceScopes[];
  defaultInsuranceScope: InsuranceScopes;
  isUpdating: boolean;
  onSaveClick: (newInsuranceScopeSettings: InsuranceScopesSettings) => void;
};

export function InsuranceScopesEditView({
  insuranceScopes: insuranceScopesProp,
  defaultInsuranceScope: defaultInsuranceScopeProp,
  isUpdating,
  onSaveClick,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  const enabledInsuranceScopesMap = useMemo(() => getInsuranceScopeMap(insuranceScopesProp), [insuranceScopesProp]);
  const insuranceScopesOptions = getOptionsFromEnum(
    InsuranceScopes,
    'admin.settingsManagement.insuranceScopes.options'
  );

  const [enabledInsuranceScopes, setEnabledInsuranceScopes] =
    useState<Record<InsuranceScopes, boolean>>(enabledInsuranceScopesMap);
  const [defaultInsuranceScope, setDefaultInsuranceScope] = useState<InsuranceScopes>(defaultInsuranceScopeProp);
  const [validationError, setValidationError] = useState<string>('');

  useEffect(() => {
    setEnabledInsuranceScopes(enabledInsuranceScopesMap);
    setDefaultInsuranceScope(defaultInsuranceScopeProp);
  }, [enabledInsuranceScopesMap, defaultInsuranceScopeProp]);

  useEffect(() => {
    const enabledInsuranceScopesList = Object.keys(enabledInsuranceScopes).filter(
      (scope) => enabledInsuranceScopes[scope]
    );

    if (enabledInsuranceScopesList.length && !enabledInsuranceScopes[defaultInsuranceScope]) {
      setValidationError(t('admin.settingsManagement.insuranceScopes.defaultInsuranceScopesError'));
    } else if (enabledInsuranceScopesList.length === 0) {
      setValidationError(t('admin.settingsManagement.insuranceScopes.enabledInsuranceScopesError'));
    } else {
      setValidationError('');
    }
  }, [enabledInsuranceScopes, defaultInsuranceScope, t]);

  return (
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='enabledInsuranceScopes-content'
        id='enabledInsuranceScopes-header'
      >
        <Box display='flex' flexDirection='row' mr={2}>
          <Typography variant='h2'>{t('admin.settingsManagement.insuranceScopes.title')}</Typography>
        </Box>
      </ExpansionPanelSummary>
      <Divider />
      <MuiExpansionPanelDetails>
        <Box display='flex' flexDirection='row' alignItems='center' width='50%' mx={2} my={2}>
          <CheckboxGroup
            label={t('admin.settingsManagement.insuranceScopes.insuranceScopesLabel')}
            validationError={validationError}
          >
            <Grid container spacing={1}>
              {_.map(insuranceScopesOptions, (choice) => {
                return (
                  <StyledGrid item xs={3} sm={3} key={choice.value}>
                    <Checkbox
                      color='primary'
                      label={choice.label}
                      checked={Boolean(enabledInsuranceScopes[choice.value])}
                      onChange={() =>
                        setEnabledInsuranceScopes({
                          ...enabledInsuranceScopes,
                          [choice.value]: !enabledInsuranceScopes[choice.value],
                        })
                      }
                    />
                  </StyledGrid>
                );
              })}
            </Grid>
          </CheckboxGroup>
        </Box>
        <Box display='flex' flexDirection='row' alignItems='center' width='50%' mx={2}>
          <RadioGroup
            label={t('admin.settingsManagement.insuranceScopes.defaultInsuranceScopeLabel')}
            name='UserForm-insuranceScopes'
            onChange={(value) => setDefaultInsuranceScope(value)}
            value={defaultInsuranceScope}
          >
            <Grid container spacing={1}>
              {_.map(insuranceScopesOptions, (choice) => {
                return (
                  <StyledGrid item xs={3} sm={3} key={choice.value}>
                    <Radio value={choice.value} label={choice.label} />
                  </StyledGrid>
                );
              })}
            </Grid>
          </RadioGroup>
        </Box>
      </MuiExpansionPanelDetails>
      <Divider />
      <ExpansionPanelActions>
        <Button
          data-testid='enabledInsuranceScopesEdit-save'
          color='primary'
          variant='text'
          isLoading={isUpdating}
          disabled={Boolean(validationError)}
          onClick={() =>
            onSaveClick({
              insuranceScopes: Object.keys(enabledInsuranceScopes).filter(
                (scope) => enabledInsuranceScopes[scope]
              ) as InsuranceScopes[],
              defaultInsuranceScope,
            })
          }
        >
          {t('cta.save').toUpperCase()}
        </Button>
      </ExpansionPanelActions>
    </ExpansionPanel>
  );
}

function getInsuranceScopeMap(insuranceScopes: InsuranceScopes[]): Record<InsuranceScopes, boolean> {
  return Object.values(InsuranceScopes).reduce(
    (acc, scope) => ({ ...acc, [scope]: insuranceScopes.includes(scope) }),
    {}
  ) as Record<InsuranceScopes, boolean>;
}
