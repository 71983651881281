export enum LeadTableColumnKeys {
  clientName = 'clientName',
  proposedInsured = 'proposedInsured',
  email = 'email',
  phoneNumber = 'phoneNumber',
  bestTimeToReach = 'bestTimeToReach',
  applicationRefNo = 'applicationRefNo',
  creationDate = 'creationDate',
  updatedAt = 'updatedAt',
  assignedTo = 'assignedTo',
  status = 'status',
  connected = 'connected',
  progress = 'progress',
  contacted = 'contacted',
  referralCode = 'referralCode',
  ownerDetails = 'ownerDetails',
  refNoIncrement = 'refNoIncrement',
  actions = 'actions',
  applicationMode = 'applicationMode',
}

export enum LeadStatusesKeys {
  new = 'new',
  invited = 'invited',
  started = 'started',
  toContact = 'toContact',

  progressing = 'progressing',
  finalized = 'finalized',

  questionnaireStarted = 'questionnaireStarted',

  analysisStarted = 'analysisStarted',
  analysisComplete = 'analysisComplete',

  applicationInProgress = 'applicationInProgress',
  applicationStarted = 'applicationStarted',
  applicationCompleted = 'applicationCompleted',
  applicationSubmitted = 'applicationSubmitted',

  submissionInProgress = 'submissionInProgress',
}
