import { PdfDocumentType, ESignSigner2FAInfo, Signer } from '@breathelife/types';

export function participantProcessor(
  participantSignerData: Partial<Record<PdfDocumentType, Signer[]>>
): ESignSigner2FAInfo[] {
  const signerMap = new Map<string, ESignSigner2FAInfo>();

  let pdfDocumentType: keyof typeof participantSignerData;

  for (pdfDocumentType in participantSignerData) {
    const participantSigners = participantSignerData[pdfDocumentType];

    participantSigners?.forEach((participantSigner) => {
      const { party, roles } = participantSigner;

      // If the party ID does not exist, then create a temporary one using person's first name, last name and dob
      const partyId =
        participantSigner.partyId ||
        `${participantSigner.party.person?.firstName}-${participantSigner.party.person?.lastName}-${participantSigner.party.person?.dob}`;

      let signer = signerMap.get(partyId);

      if (signer) {
        if (!signer.documentTypes) {
          signer.documentTypes = [];
          signer?.documentTypes.push(pdfDocumentType);
        } else {
          signer?.documentTypes.push(pdfDocumentType);
        }
      } else if (party && party.person) {
        const {
          person: { firstName, lastName },
          communication,
        } = party;

        const email = communication?.email;
        const phoneNumber = communication?.phoneNumber;

        signer = {
          partyId,
          firstName: firstName || '',
          lastName: lastName || '',
          email: email || '',
          cellphone: phoneNumber || '',
          roles,
          documentTypes: [pdfDocumentType],
        };
      } else if (party && party.organization) {
        const {
          organization: { legalEntityName, legalEntityOwnerName },
          communication,
        } = party;

        const email = communication?.email;
        const phoneNumber = communication?.phoneNumber;

        signer = {
          partyId,
          firstName: legalEntityName || '',
          lastName: legalEntityOwnerName || '',
          email: email || '',
          cellphone: phoneNumber || '',
          roles,
          documentTypes: [pdfDocumentType],
        };
      }
      if (signer) signerMap.set(partyId, signer);
    });
  }

  return Array.from(signerMap.values());
}
