import { LeadPlatformStore } from '../../types';
import { SalesDecisionRuleManagementState } from './SalesDecisionRulesManagementSlice';

export function getSalesDecisionRules(
  state: LeadPlatformStore
): SalesDecisionRuleManagementState['salesDecisionRules'] {
  const { salesDecisionRulesManagement } = state.leadPlatform;
  return salesDecisionRulesManagement.salesDecisionRules;
}

export function getTotal(state: LeadPlatformStore): SalesDecisionRuleManagementState['total'] {
  const { salesDecisionRulesManagement } = state.leadPlatform;
  return salesDecisionRulesManagement.total;
}

export function getIsLoadingRules(state: LeadPlatformStore): SalesDecisionRuleManagementState['isLoadingRules'] {
  const { salesDecisionRulesManagement } = state.leadPlatform;
  return salesDecisionRulesManagement.isLoadingRules;
}

export function getIsReloadingRules(state: LeadPlatformStore): SalesDecisionRuleManagementState['isReloadingRules'] {
  const { salesDecisionRulesManagement } = state.leadPlatform;
  return salesDecisionRulesManagement.isReloadingRules;
}
