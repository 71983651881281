import { Box } from '@material-ui/core';
import styled from 'styled-components';

import { tableHeight } from '../../Models/Layout';

export const LoadingViewContainer = styled(Box)`
  background-color: ${(props) => props.theme.colors.grey[20]};
`;

export const LoadingTablePlaceholder = styled(Box)`
  background-color: ${(props) => props.theme.colors.grey[0]};
  overflow-y: hidden;
  height: ${() => `calc(100vh - ${tableHeight}px)`};
`;
