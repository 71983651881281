import styled from 'styled-components';

import { Badge } from './Badge';

export const InsuranceScopeBadge = styled(Badge)`
  background-color: ${(props) => props.theme.colors.grey[30]};
  border-color: ${(props) => props.theme.colors.grey[0]};
  color: ${(props) => props.theme.colors.grey[90]};
  padding-top: 6px;
  padding-bottom: 6px;
`;
