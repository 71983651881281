import React from 'react';
import { useTranslation } from 'react-i18next';

import { InsuranceModule } from '@breathelife/types';

import { EditorDropdown } from './EditorDropdown';

type Props = {
  onChange: (updatedSelection: InsuranceModule) => void;
  selectedModule: InsuranceModule;
  disabled?: boolean;
};

export function ModuleSelection({ selectedModule, onChange, disabled }: Props): React.ReactElement {
  const { t } = useTranslation();

  return (
    <EditorDropdown
      id='addFieldFieldTypeDropdown'
      label={t('admin.questionnaireManagement.module.title')}
      selectedOptionValue={selectedModule}
      onChange={(newValue) => onChange(newValue as InsuranceModule)}
      disabled={disabled}
      options={[
        {
          value: InsuranceModule.insuranceApplication,
          label: t('admin.questionnaireManagement.module.insuranceApplication'),
        },
        {
          value: InsuranceModule.needsAnalysis,
          label: t('admin.questionnaireManagement.module.needsAnalysis'),
        },
      ]}
    />
  );
}
