import React from 'react';

import { GenericError } from '../../Components/Error/GenericError';
import { ErrorPageContainer } from './Styles';

export const ErrorPage = () => {
  return (
    <ErrorPageContainer>
      <GenericError />
    </ErrorPageContainer>
  );
};
