import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const Button = styled(IconButton)`
  && {
    border: 1px solid transparent;
    padding: 3px;

    :hover {
      background-color: ${(props) => props.theme.colors.grey[10]};
      border-color: ${(props) => props.theme.colors.grey[10]};
    }
    :active {
      background-color: ${(props) => props.theme.colors.grey[30]};
      border-color: ${(props) => props.theme.colors.grey[30]};
    }
    :focus:not(:active) {
      outline: 0;
      border-color: ${(props) => props.theme.colors.tertiary.default};
    }
  }
`;

export const Icon = styled(Close)`
  &&& {
    color: ${(props) => props.theme.colors.grey[70]};
  }
`;

export const CloseButton = (props: IconButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button {...props} aria-label={t('cta.close')}>
      <Icon />
    </Button>
  );
};
