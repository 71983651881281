import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';

export const HeaderButton = styled(IconButton)`
  &&& {
    background-color: ${(props) => props.theme.colors.grey[20]};

    &:hover {
      background-color: ${(props) => props.theme.colors.grey[40]};
    }
  }
`;
