import Box from '@material-ui/core/Box';
import _ from 'lodash';
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import styled from 'styled-components';

import {
  AdminToolResourceName,
  BlueprintConditionValue,
  OutcomeCode,
  SalesDecisionRule,
  SortDirection,
  SortParams,
  ConsiderationBlueprintRowData,
} from '@breathelife/types';

import { OutcomeBadge } from '../../../Components/Badge/OutcomeBadge';
import { LogButton } from '../../../Components/Button/LogButton';
import { ConditionView } from '../../../Components/Conditions/ConditionView';
import LoadingView from '../../../Components/LoadingView/LoadingView';
import { Pagination } from '../../../Components/Pagination/Pagination';
import { Table } from '../../../Components/Table/Table';
import Typography from '../../../Components/Typography';
import { generateRowData } from '../../../Helpers/conditions/blueprintHelpers';
import { formatDate, guessTimezone } from '../../../Helpers/datetime';
import { useCarrierContext } from '../../../Hooks';
import { emptyTableHeight } from '../../../Models/Layout';

const EmptyContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: ${() => `calc(100vh - ${emptyTableHeight}px)`};
  min-height: 300px;
`;

type SalesDecisionRulesTableColumns = {
  id?: string;
  Header: string;
  accessor: keyof ConsiderationBlueprintRowData;
  Cell: (props: CellProps<ConsiderationBlueprintRowData>) => React.ReactElement;
}[];

type Props = {
  salesDecisionRules: SalesDecisionRule[];
  onRowClick: (rowId?: string) => void;
  isReloadingRules: boolean;
  total: number;
  currentPage: number;
  perPage: number;
  perPageOptions: { label: number; value: number }[];
  onPageChange: (page: number) => void;
  onLimitPerPageChange: (limit: number) => void;
  onSortingChange: (options: SortParams<ConsiderationBlueprintRowData>) => void;
};

export function SalesDecisionRulesTable({
  salesDecisionRules,
  onRowClick,
  isReloadingRules,
  total,
  perPage,
  currentPage,
  perPageOptions,
  onPageChange,
  onLimitPerPageChange,
  onSortingChange,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  const { features } = useCarrierContext();

  const tableColumns = useMemo<SalesDecisionRulesTableColumns>(
    () => [
      {
        Header: t('admin.salesDecisionRulesManagement.table.conditions'),
        accessor: 'conditions',
        disableSortBy: true,
        Cell: function ({ cell: { value } }: { cell: { value: BlueprintConditionValue } }): React.ReactElement {
          return <ConditionView condition={value} />;
        },
      },
      {
        Header: t('admin.salesDecisionRulesManagement.table.outcomeCode'),
        accessor: 'outcomeCode',
        Cell: function ({ cell: { value } }: { cell: { value: OutcomeCode } }): React.ReactElement {
          return <OutcomeBadge outcome={value} />;
        },
      },
      {
        Header: t('admin.salesDecisionRulesManagement.table.createdAt'),
        accessor: 'createdAt',
        Cell: function ({ cell: { value } }: { cell: { value: string } }): React.ReactElement {
          return <Typography variant='body1'>{formatDate(value, 'D MMMM YYYY HH:MM A', guessTimezone())}</Typography>;
        },
      },
      {
        Header: t('admin.salesDecisionRulesManagement.table.reason'),
        accessor: 'reason',
        Cell: function ({ cell: { value } }: { cell: { value: string } }): React.ReactElement {
          return <Typography variant='body1'>{value}</Typography>;
        },
      },
      {
        Header: t('admin.salesDecisionRulesManagement.table.identifier'),
        accessor: 'identifier',
        Cell: function ({ cell: { value } }: { cell: { value: string } }): React.ReactElement {
          return <Typography variant='body1'>{value}</Typography>;
        },
      },
      {
        id: 'logs',
        Header: t('viewLogs'),
        accessor: 'id',
        disableSortBy: true,
        Cell: function ({ cell: { value } }: { cell: { value: string } }): React.ReactElement {
          return <LogButton resourceId={value} resourceName={AdminToolResourceName.salesDecisionRule} />;
        },
      },
    ],
    [t]
  );

  const data = useMemo(() => generateRowData(salesDecisionRules), [salesDecisionRules]);

  const handleSortingChange = useCallback(
    (columnId?: keyof ConsiderationBlueprintRowData, sortDirection?: SortDirection) => {
      if (!columnId || !sortDirection) return;

      onSortingChange({ field: columnId, direction: sortDirection });
    },
    [onSortingChange]
  );

  if (isReloadingRules) {
    return <LoadingView />;
  }

  if (_.isEmpty(salesDecisionRules)) {
    return (
      <EmptyContainer>
        <Box mb={1.5}>
          <Typography variant='h2' grey={70}>
            {t('admin.salesDecisionRulesManagement.table.noRulesFoundTitle')}
          </Typography>
        </Box>
        <Box maxWidth={300}>
          <Typography variant='body1' grey={60}>
            {t('admin.salesDecisionRulesManagement.table.noRulesFoundText')}
          </Typography>
        </Box>
      </EmptyContainer>
    );
  }

  return (
    <React.Fragment>
      <Table<ConsiderationBlueprintRowData>
        data={data}
        onRowClick={onRowClick}
        columns={tableColumns}
        hiddenColumns={features.auditLogs?.enabled ? [] : ['logs']}
        initialState={{ sortBy: [{ id: 'createdAt', desc: true }] }}
        onOrderChange={handleSortingChange}
      />
      <Box pl={2.5} pr={2.5}>
        <Pagination
          total={total}
          page={currentPage}
          perPage={perPage}
          perPageOptions={perPageOptions}
          onPageChange={onPageChange}
          onPerPageChange={onLimitPerPageChange}
        />
      </Box>
    </React.Fragment>
  );
}
