import _ from 'lodash';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateRequest, InsuranceFirm } from '@breathelife/types';

import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import { CarrierContext } from '../../../../Context/CarrierContext';
import { firmFormSchema } from '../../../../Helpers/inputValidation/form/firm';
import { useDispatch } from '../../../../Hooks';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';
import { useCreateFirmMutation } from '../../../../ReactQuery/Admin/Firm/firm.mutations';
import { useFindFirmsQuery } from '../../../../ReactQuery/Admin/Firm/firm.queries';
import { defaultState as defaultLayoutState, layoutSlice } from '../../../../Redux/Layout/LayoutSlice';
import { FirmForm } from '../../Firm/FirmDetail/Components/FirmForm';
import { getDefaultInsuranceFirmFormState } from '../../Firm/FirmDetail/helpers';
import { FirmFormFields, ValueChangeFunctionPayload } from '../../Firm/FirmDetail/types';

export function FirmDetailContainer(): React.ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { languageSettings } = useContext(CarrierContext);

  const { isLoading: isLoadingFirms } = useFindFirmsQuery();

  const [firmFormState, setFirmFormState] = useState<FirmFormFields>(
    getDefaultInsuranceFirmFormState(languageSettings.enabledLanguages)
  );

  const handleFirmInputValueChange = useCallback((payload: ValueChangeFunctionPayload) => {
    setFirmFormState((prevFormState) =>
      _.setWith(_.cloneDeep(prevFormState), payload.path, payload.value, _.cloneDeep)
    );
  }, []);

  const isFirmFormValid = firmFormSchema(languageSettings.enabledLanguages).isValidSync(firmFormState);

  const resetFirmForm = useCallback(() => {
    setFirmFormState(getDefaultInsuranceFirmFormState(languageSettings.enabledLanguages));
  }, [languageSettings.enabledLanguages]);

  const onCreateFirmModalClose = useCallback(() => {
    void dispatch(layoutSlice.actions.setModalState({ modalState: defaultLayoutState.modalState }));
    resetFirmForm();
  }, [dispatch, resetFirmForm]);

  const createFirm = useCreateFirmMutation({
    onSuccess: onCreateFirmModalClose,
  });

  const onCreateFirm = useCallback(async () => {
    const data = firmFormState as CreateRequest<InsuranceFirm>;
    await createFirm.mutateAsync(data);
  }, [firmFormState, createFirm]);

  return (
    <ModalLayout
      maxWidth='md'
      isOpen
      closeModal={onCreateFirmModalClose}
      title={t('admin.firmManagement.createNewFirm')}
      submitButton={
        <SubmitButton
          isLoading={isLoadingFirms}
          disabled={!isFirmFormValid || isLoadingFirms}
          onClick={onCreateFirm}
          data-testid='insurance-firm-create'
        >
          {t('cta.save')}
        </SubmitButton>
      }
    >
      <FirmForm
        insuranceFirmFormState={firmFormState}
        onFirmFieldValueChange={handleFirmInputValueChange}
        enabledLanguages={languageSettings.enabledLanguages}
      />
    </ModalLayout>
  );
}
