import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { QueryId } from '../../ReactQuery/common/common.types';
import { fetchQuestionnaire, QuestionnaireResponse } from '../../Services/QuestionnaireService';

// TODO: Update `applicationId` to be required up to `getQuestionnaire()` in gateway
export function useFetchQuestionnaireQuery(
  applicationId?: string,
  options?: UseQueryOptions<QuestionnaireResponse, Error>
): UseQueryResult<QuestionnaireResponse, Error> {
  return useQuery<QuestionnaireResponse, Error>(
    [QueryId.questionnaire, applicationId],
    async () => {
      return await fetchQuestionnaire(applicationId);
    },
    {
      ...options,
      enabled: !!applicationId,
      staleTime: 1000 * 60 * 30, // 30 minutes
    }
  );
}
