import Box from '@material-ui/core/Box';
import React from 'react';

import config from '@breathelife/config/frontend';
import { ESignCeremonyStatus, ESignSigner2FAInfo, SignatureType } from '@breathelife/types';
import { Loader } from '@breathelife/ui-components';

import { Drawer } from '../../../../Components/Drawer/Drawer';
import { useNavigation, useSelector, useCarrierContext } from '../../../../Hooks';
import { RequiredFile } from '../../../../Hooks/Application';
import { useGetApplicationQuery } from '../../../../ReactQuery/Application/application.queries';
import { useGetESignCeremonyQuery } from '../../../../ReactQuery/ESignCeremony/eSignCeremony.queries';
import { ESignatureDetailsHeader } from './ESignatureDetailsHeader';
import { SignerInfoView } from './SignerInfo/SignerInfoView';
import { SignerInfoViewLegacy } from './SignerInfo/SignerInfoViewLegacy';

type ESignatureDetailsViewProps = {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onClickSend: () => void;
  onClickCancel: () => void;
  onUpdateSigner: (signer: ESignSigner2FAInfo, idx: number) => void;
  requiredFiles: RequiredFile[];
  signers: ESignSigner2FAInfo[];
};

export function ESignatureDetailsView(props: ESignatureDetailsViewProps): React.ReactElement {
  const isSubmitting = useSelector((state) => state.leadPlatform.submission.isSubmittingApplication);
  const {
    isLoading: isLoadingProp,
    isOpen,
    onClose,
    onClickSend,
    onClickCancel,
    onUpdateSigner,
    requiredFiles,
    signers,
  } = props;
  const { features } = useCarrierContext();
  const ENABLE_DECOUPLE_ESIGN_FLOW = !!features.enableDecoupleESignFlow?.enabled;

  const { applicationId } = useNavigation();
  const { data: application } = useGetApplicationQuery(applicationId);
  const { data: eSignCeremony, isLoading: isESignCeremonyLoading } = useGetESignCeremonyQuery(
    applicationId,
    config.get<SignatureType | undefined>('features.signatureType.leadPlatform'),
    {
      refetchInterval: (eSignCeremony) => {
        if (!ENABLE_DECOUPLE_ESIGN_FLOW || eSignCeremony?.status !== ESignCeremonyStatus.IN_PROGRESS) {
          return false;
        }
        return 3000;
      },
    }
  );

  const isLoading = isESignCeremonyLoading || isLoadingProp || isSubmitting;

  if (!application) {
    return <Loader />;
  }

  const isEditMode =
    eSignCeremony?.status === ESignCeremonyStatus.DRAFT ||
    (ENABLE_DECOUPLE_ESIGN_FLOW &&
      (eSignCeremony?.status === ESignCeremonyStatus.IN_PROGRESS ||
        eSignCeremony?.status === ESignCeremonyStatus.READY));

  const isParticipantsProcessorEnabled = features.participantsProcessor?.enabled;

  return (
    <Drawer open={isOpen} onClose={onClose}>
      {isOpen && (
        <Box p={4}>
          <ESignatureDetailsHeader
            isLoading={isLoading}
            status={eSignCeremony?.status}
            onClose={onClose}
            onClickSend={onClickSend}
            onClickCancel={onClickCancel}
            requiredFiles={requiredFiles}
          />
          {isParticipantsProcessorEnabled ? (
            <SignerInfoView
              signers={signers}
              isEditMode={isEditMode}
              isLoading={isLoading}
              onUpdateSigner={onUpdateSigner}
              application={application}
              requiredFiles={requiredFiles}
            />
          ) : (
            <SignerInfoViewLegacy
              signers={signers}
              isEditMode={isEditMode}
              isLoading={isLoading}
              onUpdateSigner={onUpdateSigner}
              application={application}
              requiredFiles={requiredFiles}
            />
          )}
        </Box>
      )}
    </Drawer>
  );
}
