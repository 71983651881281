import { PaymentMethodInfo, PaymentSetupsServiceResponse } from '@breathelife/types';

import ApiService from './ApiService';

export async function createStripeSetupIntent(
  applicationId: string,
  temporary: boolean = false
): Promise<PaymentSetupsServiceResponse> {
  const response = await ApiService.createPaymentSetup(applicationId, temporary);
  return response.data;
}

export async function patchStripeSetupIntent(
  applicationId: string,
  setupIntentId: string
): Promise<PaymentSetupsServiceResponse> {
  const response = await ApiService.patchPaymentSetup(applicationId, setupIntentId);
  return response.data;
}

export async function retrieveStripeSetupIntent(applicationId: string): Promise<PaymentSetupsServiceResponse> {
  const response = await ApiService.getPaymentSetup(applicationId);
  return response.data;
}

export async function fetchPaymentMethodInfo(applicationId: string): Promise<PaymentMethodInfo> {
  const response = await ApiService.getPaymentMethod(applicationId);
  return response.data;
}
