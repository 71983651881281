import Skeleton from '@material-ui/lab/Skeleton';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { deserializeNodeIdToAnswerPathMap } from '@breathelife/questionnaire-engine';

import { Progress } from '../../../../../../Components/Progress/Progress';
import Typography from '../../../../../../Components/Typography';
import getCurrency from '../../../../../../Helpers/currency';
import { useCarrierContext, useDispatch, useNeedsAnalysisProgress } from '../../../../../../Hooks';
import { toCurrency } from '../../../../../../Localization/utils';
import { Application } from '../../../../../../Models/Application';
import { LeadStatus } from '../../../../../../Models/Lead';
import { useFetchQuestionnaireQuery } from '../../../../../../ReactQuery/Questionnaire/questionnaire.queries';
import { notificationSlice } from '../../../../../../Redux/Notification/NotificationSlice';

type ApplicationHeaderProgressProps = {
  application: Application;
  status: LeadStatus;
};

export function ApplicationHeaderProgress({
  application,
  status,
}: ApplicationHeaderProgressProps): React.ReactElement | null {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data: questionnaireData, isLoading: isLoadingQuestionnaire } = useFetchQuestionnaireQuery(application?.id, {
    onError: () =>
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToFetchQuestionnaire'),
        })
      ),
  });
  const questionnaire = questionnaireData?.questionnaire;
  const serializedNodeIdToAnswerPathMap = questionnaireData?.nodeIdToAnswerPath;
  const { countryCode } = useCarrierContext();

  const nodeIdToAnswerPathMap = useMemo(
    () => serializedNodeIdToAnswerPathMap && deserializeNodeIdToAnswerPathMap(serializedNodeIdToAnswerPathMap),
    [serializedNodeIdToAnswerPathMap]
  );

  const needsAnalysisProgress = useNeedsAnalysisProgress({
    application,
    questionnaire,
    nodeIdToAnswerPathMap,
  });

  // When the needs analysis is external, we assume that the application progress is 100. We also expect
  // the coverage amount to be set on the application. This external needs analysis logic
  // was initially added to support Viaction's use case where the needs analysis information come
  // from their website and is saved in the externalData. Some of the external data are also applied to the
  // some of the application properties (see externalNeedsAnalysis.ts from carrier implementations).
  // This means that to determine that a needs analysis is external, we check if `externalData` is defined/not null.
  const isNeedsAnalysisExternal = !!application.externalData;
  const isCompleted = needsAnalysisProgress === 100 || isNeedsAnalysisExternal;
  const coverageAmount = isNeedsAnalysisExternal ? application.coverageAmount : application.recommendedCoverageAmount;
  const hasCoverageAmount = !!coverageAmount && coverageAmount > 0;

  return (
    <React.Fragment>
      {isLoadingQuestionnaire ? (
        <Skeleton width={120} height='2em' />
      ) : (
        <Progress progress={isNeedsAnalysisExternal ? 100 : needsAnalysisProgress || 0} status={status} />
      )}

      {(isCompleted || isLoadingQuestionnaire) &&
        hasCoverageAmount &&
        (isLoadingQuestionnaire ? (
          <Skeleton width={120} height='2em' />
        ) : (
          <Typography variant='body1' grey={90} component='p'>
            {toCurrency(coverageAmount || 0, getCurrency(countryCode))}
          </Typography>
        ))}
    </React.Fragment>
  );
}
