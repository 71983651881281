import styled from 'styled-components';

import { Button } from '../../../../../Components/Button/Button';
import { CanadianProvinceSelect, LanguageSelect, UsStateSelect } from '../../../../../Components/SelectInputs';

export const LongWordBreak = styled.div`
  word-break: break-all;
`;

export const CtaButton = styled(Button)`
  &&&& {
    padding: 6px 12px;
  }
`;

export const UsStateDropdown = styled(UsStateSelect)`
  width: 100%;
`;

export const CaProvinceDropdown = styled(CanadianProvinceSelect)`
  width: 100%;
`;

export const LanguageDropdown = styled(LanguageSelect)`
  width: 100%;
`;
