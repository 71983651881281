import Grid from '@material-ui/core/Grid';
import React from 'react';

import RadioGroup, { Radio } from '../../../Components/RadioGroup/RadioGroup';

type Props<T> = {
  label: string;
  name: string;
  options: Array<{ value: T; label: string }>;
  value?: T;
  onChange: (value: T) => void;
};

export function QuantifierRadioGroup<T extends string>(props: Props<T>): React.ReactElement {
  const { label, name, options, value, onChange } = props;

  return (
    <RadioGroup label={label} name={name} onChange={onChange} value={value}>
      <Grid container spacing={1}>
        {options.map((choice, idx) => {
          return (
            <Grid item xs={3} sm={3} key={`${name}-radio-${idx}`}>
              <Radio value={choice.value} label={choice.label} />
            </Grid>
          );
        })}
      </Grid>
    </RadioGroup>
  );
}
