import * as yup from 'yup';

import { getValidationError } from '../getValidationError';

export type ESignSignerSettingFormFields = 'fileTemplateId' | 'participantRoleId' | 'isSignatureRequired';

const getSchemaMap = (): { [field in ESignSignerSettingFormFields]: yup.StringSchema | yup.BooleanSchema } => ({
  fileTemplateId: yup.string().required(),
  isSignatureRequired: yup.boolean().required(),
  participantRoleId: yup.string().required(),
});

const getFormSchema = (): yup.ObjectSchema => yup.object().shape(getSchemaMap());

function getFieldValidationError(
  fieldName: ESignSignerSettingFormFields,
  value: string | undefined
): yup.ValidationError | undefined {
  return getValidationError(getSchemaMap(), fieldName, value);
}

export const FileTemplateRecipientValidation = {
  getFormSchema,
  getFieldValidationError,
};
