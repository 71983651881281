import { Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '../../../../../../../Components/Typography';

type CreditCardPreviewProps = {
  last4: string;
};

export function CreditCardPreview(props: CreditCardPreviewProps): React.ReactElement {
  const { t } = useTranslation();
  const { last4 } = props;

  return (
    <Box>
      <Box>
        <Typography variant='body5' grey={70}>
          {t('assistedApplication.paymentSubsection.previews.creditCardNumber')}
        </Typography>
      </Box>
      <Box>
        <Typography variant='body1'>**** **** **** {last4}</Typography>
      </Box>
    </Box>
  );
}
