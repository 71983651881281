import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { OutcomeCode, SignatureType } from '@breathelife/types';

import { Icon } from '../../../../Components/Icons';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';
import { SubmitApplicationView } from './SubmitApplicationView';

type SubmitApplicationModalProps = {
  signatureType: SignatureType;
  isOpen: boolean;
  closeModal: () => void;
  confirmationNumber?: string;
  cryptoSignature?: string;
  isLoading: boolean;
  isSubmissionFailure: boolean;
  isSubmissionSuccess: boolean;
  outcome?: OutcomeCode;
};

export function SubmitApplicationModal(props: SubmitApplicationModalProps): React.ReactElement {
  const {
    signatureType,
    isOpen,
    closeModal: closeModalProp,
    confirmationNumber,
    cryptoSignature,
    isLoading,
    isSubmissionSuccess,
    isSubmissionFailure,
    outcome,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const isNotLoadingAndSubmissionFailed = !isLoading && isSubmissionFailure;
  const closeModal = isLoading ? undefined : closeModalProp;

  let title;
  let titleIcon;

  if (outcome && [OutcomeCode.denied, OutcomeCode.blocked, OutcomeCode.blockedAtQuoter].includes(outcome)) {
    title = t('modals.assistedApplication.submitApplicationView.submissionView.responses.denied.title');
    titleIcon = <Icon name='warning' stroke={theme.colors.yellow[60]} />;
  }

  if (isNotLoadingAndSubmissionFailed) {
    title = t('modals.assistedApplication.submitApplicationView.errorView.title');
    titleIcon = <Icon name='error' stroke={theme.colors.red[60]} />;
  }

  return (
    <ModalLayout
      maxWidth='sm'
      isOpen={isOpen}
      confirmationRequired={isLoading}
      closeModal={closeModal}
      hideCancel
      title={title}
      titleIcon={titleIcon}
    >
      <SubmitApplicationView
        signatureType={signatureType}
        confirmationNumber={confirmationNumber}
        cryptoSignature={cryptoSignature}
        isLoading={isLoading}
        isSubmissionFailure={isSubmissionFailure}
        isSubmissionSuccess={isSubmissionSuccess}
        outcome={outcome}
      />
    </ModalLayout>
  );
}
