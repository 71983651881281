import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { ExpandableFileTemplateRule, StoredFile, StoredFileWithSignedUrl } from '@breathelife/types';

import { Application } from '../../Models/Application';
import { QueryId } from '../../ReactQuery/common/common.types';
import { fetchApplicationFiles, getApplicationFile } from '../../Services/ApplicationFileService';
import { getApplication } from '../../Services/ApplicationsService';
import { findFileTemplateRules } from '../../Services/FileTemplateRulesService';

export function useFindApplicationFilesQuery(
  applicationId?: string,
  options?: UseQueryOptions<StoredFile[], Error>
): UseQueryResult<StoredFile[], Error> {
  return useQuery<StoredFile[], Error>(
    [QueryId.storedFiles, applicationId],
    async () => {
      if (!applicationId) {
        return Promise.reject(new Error('Application id is missing.'));
      }
      return await fetchApplicationFiles(applicationId);
    },
    options
  );
}

export function useGetApplicationFileQuery(
  applicationId: string,
  fileId: string,
  options?: UseQueryOptions<StoredFileWithSignedUrl, Error>
): UseQueryResult<StoredFileWithSignedUrl, Error> {
  return useQuery<StoredFileWithSignedUrl, Error>(
    [QueryId.storedFiles, applicationId, fileId],
    async () => await getApplicationFile(applicationId, fileId, { download: false, validFor: 10 }),
    options
  );
}

export function useFileTemplateRulesQuery(
  {
    questionnaireVersionId,
    includeFileTemplate,
    includeFileTemplateRecipient,
  }: {
    questionnaireVersionId: string | undefined;
    includeFileTemplate?: boolean;
    includeFileTemplateRecipient?: boolean;
  },
  options?: UseQueryOptions<ExpandableFileTemplateRule[], Error>
): UseQueryResult<ExpandableFileTemplateRule[], Error> {
  return useQuery<ExpandableFileTemplateRule[], Error>(
    QueryId.fileTemplateRules,
    async () => {
      if (!questionnaireVersionId) {
        throw new Error('Questionnaire version is not defined for this application.');
      }
      return await findFileTemplateRules(questionnaireVersionId, {
        includeFileTemplate: includeFileTemplate || false,
        includeFileTemplateRecipient: includeFileTemplateRecipient || false,
      });
    },
    options
  );
}

export function useGetApplicationQuery(
  applicationId?: string,
  options?: UseQueryOptions<Application, Error>
): UseQueryResult<Application, Error> {
  return useQuery<Application, Error>(
    [QueryId.application, applicationId],
    async () => {
      if (!applicationId) {
        return Promise.reject(new Error('Application id is missing.'));
      }
      return await getApplication(applicationId);
    },
    {
      ...options,
      enabled: !!applicationId,
    }
  );
}
