import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import i18n from 'i18next';
import _ from 'lodash';
import { initReactI18next } from 'react-i18next';

import { InsuranceScopes, Language, UserStatus } from '@breathelife/types';

import { UserCategory, UserRole, UserType } from '../Services/Auth0';
import optionalNeedAnalysisEn from './locales/en/optionalNeedsAnalysis';
import en from './locales/en/translation';
import optionalNeedAnalysisFr from './locales/fr/optionalNeedsAnalysis';
import fr from './locales/fr/translation';
import { setLocale } from './utils';

export type Translation = { [x: string]: any } & {
  insuranceScope?: { [scope in InsuranceScopes]: string };
  user?: {
    category: { [category in UserCategory]: string };
    role: { [role in UserRole]: string };
    type: { [type in UserType]: string };
    status: { [type in UserStatus]: string };
  };
};

type Dictionaries = { [lang in Language]: { translation: Record<string, Translation> } };

export const dictionaries: Dictionaries = {
  [Language.en]: { translation: en },
  [Language.fr]: { translation: fr },
};

export const optionalNeedsAnalysisDictionaries: Dictionaries = {
  [Language.en]: { translation: optionalNeedAnalysisEn },
  [Language.fr]: { translation: optionalNeedAnalysisFr },
};

export default async function (lang: Language, optionalNeedsAnalysis?: boolean): Promise<void> {
  let resources: Dictionaries = dictionaries;

  // Altering the dictionary like so must remain the exception and this will hopefully be removed in the future.
  // This is for now the most low-code, removable quick fix to change many texts all at once.
  if (optionalNeedsAnalysis) {
    resources = _.merge(dictionaries, optionalNeedsAnalysisDictionaries);
  }

  setLocale(lang);
  dayjs.locale(lang);
  await i18n.use(initReactI18next).init({
    resources,
    lng: lang,
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  });
}

export async function changeLanguage(lang: Language): Promise<void> {
  if (lang === i18n.language) return;
  setLocale(lang);
  dayjs.locale(lang);
  await i18n.changeLanguage(lang);
}
