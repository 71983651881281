import { User } from '@breathelife/types';

import { Mga } from '../Models/Mga';

export type GroupData = Pick<User, 'groupId' | 'groupName' | 'groupEmail'>;

// The backend should ideally handle this part.
export function getGroupProperties(groupId: string, groups: Mga[]): GroupData | undefined {
  const selectedGroup = groups.find((group) => group.id === groupId);

  if (!selectedGroup) {
    return {
      groupId: '',
      groupName: '',
      groupEmail: '',
    };
  }

  return {
    groupId: selectedGroup.id,
    groupName: selectedGroup.name,
    groupEmail: selectedGroup.email,
  };
}
