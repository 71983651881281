import i18next from 'i18next';
import * as yup from 'yup';

import { InsuranceProductPricingCreateRequest, PaymentFrequency } from '@breathelife/types';

function productPricingFormFieldsSchema(): yup.ObjectSchema<yup.Shape<object, any>, object> {
  return yup.object().shape({
    rateSheetFile: yup.mixed().test('file type validation', i18next.t('validation.error.fileType'), validateFileType),
    policyFee: yup.number().notRequired(),
    paymentFrequency: yup.string().oneOf(Object.values(PaymentFrequency)),
    modalFactor: yup.number().positive().required(),
    insuranceProductPricingId: yup.string().notRequired(),
  });
}

export function productPricingFormSchema(): yup.ObjectSchema {
  return yup.object().shape({
    ...productPricingFormFieldsSchema().fields,
    rateSheetFile: yup.mixed().when('insuranceProductPricingId', {
      is: undefined,
      then: yup
        .mixed()
        .test('file type validation', i18next.t('validation.error.fileType'), validateFileType)
        .required(i18next.t('validation.error.required')),
    }),
    modalFactor: yup
      .mixed()
      .test('modal factor validation', i18next.t('validation.error.modalFactor'), validateModalFactor),
  });
}

export function getFieldValidationError(
  fieldName: keyof InsuranceProductPricingCreateRequest | 'paymentFrequency',
  value: unknown
): yup.ValidationError | undefined {
  try {
    productPricingFormFieldsSchema().fields[fieldName].validateSync(value);
  } catch (error) {
    return error;
  }
  return;
}

function validateModalFactor(modalFactor: unknown): boolean {
  if (typeof modalFactor !== 'object' || !modalFactor) return false;

  return Object.entries(modalFactor).every(([key, value]) => {
    return key in PaymentFrequency && !Number.isNaN(value);
  });
}

function validateFileType(file: unknown): boolean {
  return Boolean(
    file && file instanceof File && file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  );
}
