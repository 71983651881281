import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Permission } from '@breathelife/types';

import { Button } from '../../../Components/Button/Button';
import { Icon } from '../../../Components/Icons';
import { RestrictedToPermission } from '../../../Components/Restricted/RestrictedToPermission';

type AssistedApplicationHeaderProps = {
  isMissingRequiredFiles: boolean;
  onOpenDocumentsDrawer: () => void;
};

export function DocumentsDrawerButton(props: AssistedApplicationHeaderProps): React.ReactElement | null {
  const { isMissingRequiredFiles, onOpenDocumentsDrawer } = props;
  const { t } = useTranslation();

  return (
    <RestrictedToPermission permission={[Permission.ApplicationFileRead]}>
      <Box>
        <Badge badgeContent='!' color='error' invisible={!isMissingRequiredFiles}>
          <Button
            color='secondary'
            variant='outlined'
            onClick={onOpenDocumentsDrawer}
            startIcon={<Icon name='files' />}
          >
            {t('assistedApplication.files')}
          </Button>
        </Badge>
      </Box>
    </RestrictedToPermission>
  );
}
