import Box from '@material-ui/core/Box';
import styled, { css } from 'styled-components';

import { GhostButton } from '../../../Components/Button/GhostButton';
import { GhostIconButton } from '../../../Components/Button/GhostIconButton';
import Typography from '../../../Components/Typography';
import { NavigationButton } from './NavigationButton';
import { PROPOSED_INSURED_TAB_BAR_HEIGHT_PX } from './ProposedInsuredTabBar/Styles';

const HEADER_HEIGHT_PX = 72;

export const ViewContainer = styled(Box)<{ withTabBar: boolean }>`
  display: flex;
  height: calc(
    100vh -
      ${(props) => (props.withTabBar ? HEADER_HEIGHT_PX + PROPOSED_INSURED_TAB_BAR_HEIGHT_PX : HEADER_HEIGHT_PX)}px
  );
`;

export const NavigationContainer = styled(Box)`
  flex: 1;
  padding: 24px;
  overflow: auto;
  border-right: 1px solid ${(props) => props.theme.colors.grey[30]};
  max-width: 20%;
  min-width: 10%;
`;

export const ScrollContainer = styled(Box)`
  overflow: auto;
  flex: 3;
  scroll-behavior: smooth;
`;

export const FormContainer = styled(Box)`
  min-width: 700px;
  max-width: 700px;
`;

export const NavButton = styled(GhostButton)<{ $isActive?: boolean }>`
  &&&&& {
    min-height: 48px;
    height: auto;
    width: 100%;
    justify-content: start;
    text-align: start;
    border: 1px solid transparent;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    ${(props) =>
      props.$isActive &&
      css`
        background-color: ${props.theme.colors.primary[20]};
        border-color: ${props.theme.colors.primary[30]};
      `};
  }
`;

export const SubNavLink = styled.a<{ $isActive?: boolean }>`
  display: block;
  margin: 4px 0px;
  padding: 4px 0px 4px 16px;
  color: ${(props) => props.theme.colors.grey[60]};
  border-left: 1px solid transparent;
  transition: all 0.1s ease;

  &:hover {
    color: ${(props) => props.theme.colors.grey[90]};
  }

  ${(props) =>
    props.$isActive &&
    css`
      border-color: ${props.theme.colors.primary.default};
      color: ${props.theme.colors.grey[90]};
    `};
`;

export const StyledNextNavigationButton = styled(NavigationButton)`
  && {
    margin-left: auto;
  }
`;

export const ApplicationDetailsWidgetViewContainer = styled(Box)`
  width: 360px;
`;

export const StyledActionGhostIconButton = styled(GhostIconButton)<{ $fullWidth?: boolean }>`
  &&&& {
    ${(props) =>
      props.$fullWidth &&
      css`
        width: 100%;
      `};
    justify-content: flex-start;
    color: ${(props) => props.theme.colors.primary.default};
  }
`;

export const HeaderButtonContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const HeaderTabContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-grow: 1;
`;

export const HeaderTitle = styled(Typography)`
  line-height: 21px;
  color: ${(props) => props.theme.colors.grey[90]};
  margin: 0;
`;

export const HeaderTitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  padding-left: 8px;
`;

export const HeaderWrapper = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[30]};
  display: flex;
  height: ${HEADER_HEIGHT_PX}px;
  justify-content: space-between;
  padding: 0px 16px;
`;
