import Box from '@material-ui/core/Box';
import React from 'react';
import styled from 'styled-components';

import { ErrorBoundary } from '@breathelife/ui-components';

import { GenericError } from '../../Components/Error/GenericError';
import { Helmet } from '../../Components/Helmet';
import { UserMenu } from '../../Components/Menu/UserMenu';
import { HEADER_HEIGHT } from '../../Models/Layout';
import { PageTabs, Tabs } from './PageTabs';

type PageLayoutProps = {
  tabs?: Tabs[];
  defaultTab?: string;
  displayContentFullScreen?: boolean;
  content: React.ReactNode;
  pageTitle: string;
};

const StyledPageHeaderContainer = styled(Box)`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[40]};
`;

export function LeadsPageLayout(props: PageLayoutProps): React.ReactElement {
  const { content, defaultTab, pageTitle, tabs, displayContentFullScreen = false } = props;
  return (
    <React.Fragment>
      <Helmet text={pageTitle} />
      <Box
        py={displayContentFullScreen ? 0 : 2}
        position='relative'
        width='100%'
        height='100vh'
        display='flex'
        flexDirection='column'
      >
        {!displayContentFullScreen && (
          <StyledPageHeaderContainer
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            height={HEADER_HEIGHT}
            role='navigation'
          >
            {tabs && tabs.length !== 0 && <PageTabs tabs={tabs} defaultTab={defaultTab} />}
            <Box>
              <UserMenu variant='default' showCarret showUserName />
            </Box>
          </StyledPageHeaderContainer>
        )}
        <Box flexGrow={1} minHeight={0} role='main'>
          <ErrorBoundary renderErrorComponent={() => <GenericError />}>{content}</ErrorBoundary>
        </Box>
      </Box>
    </React.Fragment>
  );
}
