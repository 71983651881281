import Box from '@material-ui/core/Box';
import styled from 'styled-components';

export const StyledInfoBox = styled(Box)`
  padding: 12px;
  background-color: ${(props) => props.theme.colors.yellow[20]};
  border: ${(props) => `1px solid ${props.theme.colors.yellow[30]}`};
  display: flex;
  border-radius: 3px;
  box-shadow: 0px 0px 1px rgba(117, 117, 117, 0.31), 0px 1px 1px rgba(117, 117, 117, 0.25);
  color: ${(props) => props.theme.colors.grey[90]};
`;
