import { ESignCeremonyStatus } from '@breathelife/types';

const translation: { [key in ESignCeremonyStatus]: string } = {
  [ESignCeremonyStatus.DRAFT]: '',
  [ESignCeremonyStatus.IN_PROGRESS]: 'Waiting for signature',
  // SENT and READY are also "waiting for signature" because we wouldn't be able to update the status in the
  // ui if it was, e.g. "Generating" -> "Sending" -> "Waiting for Signature". This could be looked into if we
  // start using web sockets.
  [ESignCeremonyStatus.READY]: 'Waiting for signature',
  [ESignCeremonyStatus.SENT]: 'Waiting for signature',
  [ESignCeremonyStatus.COMPLETED]: 'Signed',
  [ESignCeremonyStatus.EMAIL_BOUNCED]: 'Email not delivered',
  [ESignCeremonyStatus.DECLINED]: 'Signature declined',
  [ESignCeremonyStatus.OPT_OUT]: 'Signer opted out of signature',
  [ESignCeremonyStatus.CANCELLED]: 'Signature request cancelled',
  [ESignCeremonyStatus.EXPIRED]: 'Signature request expired',
  [ESignCeremonyStatus.SIGNER_LOCKED_OUT]: 'Signer locked out of signature',
  [ESignCeremonyStatus.SEND_PACKAGE_ERROR]: 'Error sending signature package',
};
export default translation;
