import React from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';

import { AssistedApplication } from '../../Components/AssistedApplication/AssistedApplication';
import Urls from '../../Navigation/Urls';
import { LeadsListView, LeadsListViewProps } from '../../Pages/Home/LeadsListView/LeadsListView';
import PrivateRoute from '../../Root/PrivateRoute';

type LeadsPageRootProps = LeadsListViewProps;

export function LeadsPageRoot(props: LeadsPageRootProps): React.ReactElement {
  const location = useLocation();
  return (
    <Switch>
      <PrivateRoute
        exact
        path={[Urls.leads, Urls.leadsList, Urls.leadDetail]}
        render={() => <LeadsListView {...props} />}
      />
      <PrivateRoute exact path={[Urls.application]} render={() => <AssistedApplication />} />
      <Redirect to={{ pathname: Urls.leads, search: location.search }} />
    </Switch>
  );
}
