import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { useAdminTabs } from '../../Hooks/useAdminTabs';
import { PageLayout } from '../../Layouts/PageLayout/PageLayout';
import Urls from '../../Navigation/Urls';
import { UserManagementViewContainer } from '../../Pages/Admin/UserManagement/UserManagementViewContainer';
import PrivateRoute from '../../Root/PrivateRoute';
import { ApplicationsTablePage } from './BackOffice/ApplicationsViewContainer';
import { UnderwritingReportsViewContainer } from './BackOffice/UnderwritingReportsViewContainer';
import { DocumentManagementViewContainer } from './Document/DocumentManagementViewContainer';
import { EntityMappingViewContainer } from './EntityMapping/EntityMappingViewContainer';
import { FirmManagementViewContainer } from './Firm/FirmManagementViewContainer';
import { AuditLogsViewContainer } from './Logs/AuditLogsViewContainer';
import { ProductManagementViewContainer } from './Product/ProductManagementViewContainer';
import { QuestionnaireManagementViewContainer } from './Questionnaire/QuestionnaireManagementViewContainer';
import { QuestionnaireVersionsContextProvider } from './Questionnaire/QuestionnaireVersionsContextProvider';
import { QuestionnaireVersionsTablePage } from './Questionnaire/QuestionnaireVersionsTable/QuestionnaireVersionsTableContainer';
import { QuestionnaireBuilderView } from './QuestionnaireBuilder/QuestionnaireBuilderView';
import { SalesDecisionRulesManagementViewContainer } from './SalesDecisionRules/SalesDecisionRulesManagementViewContainer';
import { SettingsViewContainer } from './Settings/SettingsViewContainer';
import { ThemeManagementViewContainer } from './Theme/ThemeManagementViewContainer';
import { ThirdPartyIntegrationsView } from './ThirdPartyIntegrations/ThirdPartyIntegrationsView';

export function AdminPage(): React.ReactElement {
  const tabs = useAdminTabs();

  const content = (
    <React.Fragment>
      <Switch>
        <Redirect exact path={Urls.admin} to={{ pathname: Urls.questionnaireManagement, search: location.search }} />
        <PrivateRoute exact path={Urls.userManagement} component={UserManagementViewContainer} />
        <PrivateRoute
          exact
          path={`${Urls.questionnaireManagement}/:questionnaireVersionId`}
          component={QuestionnaireManagementViewContainer}
        />
        <PrivateRoute exact path={Urls.productManagement} component={ProductManagementViewContainer} />
        <PrivateRoute exact path={Urls.themeManagement} component={ThemeManagementViewContainer} />
        <PrivateRoute exact path={Urls.settingsManagement} component={SettingsViewContainer} />
        <PrivateRoute exact path={Urls.auditLogs} component={AuditLogsViewContainer} />
        <PrivateRoute exact path={Urls.firmManagement} component={FirmManagementViewContainer} />
        <PrivateRoute exact path={Urls.documentsManagement} component={DocumentManagementViewContainer} />
        <PrivateRoute exact path={Urls.questionnaireBuilder} component={QuestionnaireBuilderView} />
        <PrivateRoute exact path={Urls.thirdPartyIntegrations} component={ThirdPartyIntegrationsView} />
        <PrivateRoute
          exact
          path={Urls.backOfficeApplicationUnderwritingReports}
          component={UnderwritingReportsViewContainer}
        />
        <PrivateRoute exact path={Urls.backOfficeApplications} component={ApplicationsTablePage} />
        <QuestionnaireVersionsContextProvider>
          <PrivateRoute exact path={Urls.entityMappingManagement} component={EntityMappingViewContainer} />
          <PrivateRoute
            exact
            path={Urls.salesDecisionRulesManagement}
            component={SalesDecisionRulesManagementViewContainer}
          />
          <PrivateRoute exact path={Urls.questionnaireManagement} component={QuestionnaireVersionsTablePage} />
        </QuestionnaireVersionsContextProvider>
      </Switch>
    </React.Fragment>
  );

  return <PageLayout content={content} tabs={tabs} />;
}
