import Box from '@material-ui/core/Box';
import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';

import Typography from '../../Components/Typography';
import { FullColorShade } from '../../Styles/Types';
import { NavLinkButton } from './Styles';

type SidebarNavLinkProps = {
  to: string;
  text: string;
  icon: React.ReactElement;
  greyShade?: keyof FullColorShade;
  activeColor?: string;
  hoverColor?: string;
};

export function SidebarNavLink(props: SidebarNavLinkProps): React.ReactElement {
  const { to, text, icon, greyShade, activeColor, hoverColor } = props;
  const match = useRouteMatch({
    path: to,
    exact: false,
  });

  return (
    <Box my={1}>
      <NavLinkButton component={NavLink} to={to} isActive={!!match} activeColor={activeColor} hoverColor={hoverColor}>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Box lineHeight='0'>{icon}</Box>
          <Box>
            <Typography variant='small3' grey={greyShade ?? 100}>
              {text}
            </Typography>
          </Box>
        </Box>
      </NavLinkButton>
    </Box>
  );
}
