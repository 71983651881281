import { Box } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '../../../Components/Typography';
import { ModalLayout } from '../../../Layouts/Modal/ModalLayout';
import { Link } from './Styles';

export type Props = {
  isOpen: boolean;
  files: DownloadFileProps[];
  closeModal: () => void;
};

type DownloadFileProps = {
  title: string;
  subtitle?: string;
  source: string;
  icon?: React.ReactElement<SVGElement>;
};

function DownloadFileItem(props: DownloadFileProps): React.ReactElement | null {
  const { title, source, subtitle, icon } = props;
  const { t } = useTranslation();

  return (
    <Box display='flex' p={2} justifyContent='space-between' alignItems='center'>
      <Box display='flex' alignItems='center'>
        {icon}
        <Box ml={1}>
          <Typography variant='body3' grey={90}>
            {title}
          </Typography>
          {!!subtitle && (
            <Typography variant='body5' grey={70}>
              {subtitle}
            </Typography>
          )}
        </Box>
      </Box>
      <Link href={source} target='_blank' rel='noopener noreferrer'>
        {t('cta.downloadFile.singular')}
      </Link>
    </Box>
  );
}

export const DownloadFilesModal: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { isOpen, closeModal, files } = props;
  return (
    <ModalLayout
      maxWidth='sm'
      hideCancel
      isOpen={isOpen}
      closeModal={closeModal}
      title={t('modals.filesDownload.title')}
    >
      <Box mb={2.5}>
        {files.map((file) => (
          <React.Fragment key={file.title}>
            <DownloadFileItem {...file} />
            <Divider />
          </React.Fragment>
        ))}
      </Box>
    </ModalLayout>
  );
};
