import Box from '@material-ui/core/Box';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import config from '@breathelife/config/frontend';
import { hash } from '@breathelife/hash';
import { EntitySelectors, InsuranceEntities } from '@breathelife/insurance-entities';
import {
  isRenderingQuestionnaireComplete,
  NodeIdToAnswerPathMap,
  Questionnaire,
  QuestionnaireEngine,
} from '@breathelife/questionnaire-engine';
import { TypewriterTracking } from '@breathelife/react-tracking';
import {
  InsuranceModule,
  LocalizedInsuranceProduct,
  Permission,
  PlatformType,
  RenderingType,
  SignatureType,
  ESignSigner2FAInfo,
  ESignCeremonyStatus,
  Timezone,
  DEFAULT_TIMEZONE_NAME,
  ApplicationMode,
} from '@breathelife/types';
import { GoogleMapsHelmet } from '@breathelife/ui-components';

import { MemoizedAssistedApplicationHeader as AssistedApplicationHeader } from '../../Components/AssistedApplication/AssistedApplicationView/AssistedApplicationHeader';
import { MemoizedAssistedApplicationView as AssistedApplicationView } from '../../Components/AssistedApplication/AssistedApplicationView/AssistedApplicationView';
import { RestrictedToPermission } from '../../Components/Restricted/RestrictedToPermission';
import { AssistedApplicationContext } from '../../Context/AssistedApplicationContext';
import { getInsuredPeopleTabs } from '../../Helpers/assistedApplication/tabs';
import { getInsuredFullName, SectionGroupId } from '../../Helpers/questionnaireAnswers';
import { getQuestionnaireEngineConfig } from '../../Helpers/questionnaireEngineConfigs';
import {
  useCarrierContext,
  useLocale,
  useModalState,
  useSelector,
  useDispatch,
  useApplicationStoredFiles,
} from '../../Hooks';
import { Application } from '../../Models/Application';
import { useSubmitInsuranceApplication } from '../../ReactQuery/Application/application.mutations';
import { useGetApplicationQuery } from '../../ReactQuery/Application/application.queries';
import { SaveAssistedApplicationAnswers } from '../../ReactQuery/AssistedApplication/assistedApplication.mutations';
import {
  useSendESignCeremonyMutation,
  useUpdateESignCeremony,
} from '../../ReactQuery/ESignCeremony/eSignCeremony.mutations';
import { useGetESignCeremonyQuery } from '../../ReactQuery/ESignCeremony/eSignCeremony.queries';
import { useProcessParticipantsQuery } from '../../ReactQuery/ParticipantProcessor/participantProcessor.queries';
import { useGetPointOfSaleDecisions } from '../../ReactQuery/PointOfSaleDecisions/pointOfSaleDecisions.queries';
import { QueryId } from '../../ReactQuery/common/common.types';
import {
  processApplicationSubmission,
  processPreviewApplicationSubmission,
} from '../../Redux/Application/ApplicationOperations';
import { notificationSlice } from '../../Redux/Notification/NotificationSlice';
import { getQuotedProducts } from '../../Redux/Products/ProductsSelectors';
import { DocumentsDrawer } from './Drawers/Documents/DocumentsDrawer';
import { ESignatureDetailsContainer } from './Drawers/ESignatureDetails/ESignatureDetailsContainer';
import { InfoMessageTrackESignatureModal } from './Modals/ESignature/InfoMessageTrackESignatureModal';
import { SubmitPaperAppModal } from './Modals/SubmitPaperApp/SubmitPaperAppModal';
import { useAutoSaveAnswers } from './useAutoSaveAnswers';

type Props = {
  application: Application;
  applicationSignatureType?: SignatureType;
  closeAssistedApplication: () => void;
  saveAnswers: SaveAssistedApplicationAnswers;
  isSavingAnswers: boolean;
  isMissingRequiredFiles: boolean;
  isSubmitButtonDisabled: boolean;
  onOpenESignatureDetails: () => void;
  onOpenSubmissionDetailsModal: () => void;
  onOpenFileAttachmentModal: () => void;
  onChangeMissingRequiredFiles: (isMissingRequiredFiles: boolean) => void;
  questionnaire: Questionnaire;
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap;
  entitySelectors: EntitySelectors | undefined;
  shouldDisableFields: boolean;
  shouldRefreshAnswers: boolean;
  nodesToRefresh: Record<string, unknown>[];
  onAfterRefreshAnswers: () => void;
  isESignatureDetailsOpen: boolean;
  onCloseESignatureDetails: () => void;
  isInfoMessageTrackESignatureModalOpen: boolean;
  onCloseInfoMessageTrackESignatureModal: () => void;
  setHasInitiatedSubmission: (value: React.SetStateAction<boolean>) => void;
  onOpenSubmitApplicationModal: () => void;
  onAddProposedInsured: () => void;
  onRemoveProposedInsured: (surrogateId: string) => void;
  currentProposedInsuredIndex: number;
  onSelectProposedInsured: (index: number) => void;
  isLoadingProposedInsured: boolean;
  triggerIdentityVerification: () => void;
  onOpenInstantIdReportDrawer: () => void;
  proposedInsuredIndexToDelete: number;
  onOpenDeleteProposedInsuredModal: (index: number) => void;
  onCloseDeleteProposedInsuredModal: () => void;
  refreshPricingManual: () => Promise<void>;
};

export function AssistedApplicationContainer(props: Props): React.ReactElement | null {
  const isIdentityVerificationCheckCompleted = useSelector(
    (store) => store.leadPlatform.identityVerification.isIdentityVerificationCheckCompleted
  );
  const { t } = useTranslation();
  const { features } = useCarrierContext();
  const locale = useLocale();
  const isIdentityVerificationCheckEnabled = !!features.enableInstantIdRequest?.enabled;
  const isMissingIdentityVerificationCheck =
    isIdentityVerificationCheckEnabled && !isIdentityVerificationCheckCompleted;

  const googleMapsApiKey = config.get<string | undefined>('keys.googleMapsPlaces');

  const {
    application,
    applicationSignatureType,
    closeAssistedApplication,
    saveAnswers,
    isSavingAnswers,
    isMissingRequiredFiles,
    isSubmitButtonDisabled: isSubmitButtonDisabledProps,
    onOpenSubmitApplicationModal,
    onOpenESignatureDetails,
    onOpenSubmissionDetailsModal,
    onOpenFileAttachmentModal,
    onChangeMissingRequiredFiles,
    questionnaire,
    nodeIdToAnswerPathMap,
    entitySelectors,
    shouldDisableFields,
    shouldRefreshAnswers,
    nodesToRefresh,
    onAfterRefreshAnswers,
    isESignatureDetailsOpen,
    onCloseESignatureDetails,
    setHasInitiatedSubmission,
    isInfoMessageTrackESignatureModalOpen,
    onCloseInfoMessageTrackESignatureModal,
    onAddProposedInsured: onAddProposedInsuredProp,
    onRemoveProposedInsured: onRemoveProposedInsuredProp,
    currentProposedInsuredIndex,
    onSelectProposedInsured,
    isLoadingProposedInsured,
    triggerIdentityVerification,
    onOpenInstantIdReportDrawer,
    proposedInsuredIndexToDelete,
    onOpenDeleteProposedInsuredModal,
    onCloseDeleteProposedInsuredModal,
    refreshPricingManual,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const { isSubmittingApplication } = useSelector((state) => state.leadPlatform.submission);
  const { products, quotes, isLoadingQuotes } = useSelector((state) => state.leadPlatform.products);
  const quotedProducts = useSelector(getQuotedProducts);
  const queryClient = useQueryClient();
  const sendESignCeremonyMutation = useSendESignCeremonyMutation();
  const updateESignCeremonyStatusMutation = useUpdateESignCeremony();
  const submitInsuranceApplicationMutation = useSubmitInsuranceApplication();
  const { data: eSignCeremony, isFetching: isGetESignCeremonyFetching } = useGetESignCeremonyQuery(
    application.id,
    applicationSignatureType
  );
  const { isFetching: isGetApplicationFetching } = useGetApplicationQuery(application.id);
  const { isFetching: isPointOfSalesDecisionsFetching } = useGetPointOfSaleDecisions(application.id);

  const [isDocumentDrawerOpen, onOpenDocumentDrawerHook, onCloseDocumentDrawer] = useModalState();
  const [isSubmitPaperAppModalOpen, onOpenSubmitPaperAppModal, onCloseSubmitPaperAppModal] = useModalState();

  const [displayValidationErrors, setDisplayValidationErrors] = useState(false);
  const [currentSectionGroupId, setCurrentSectionGroupId] = useState(SectionGroupId.insuredPeople);
  const [activeSectionIndex, setActiveSectionIndex] = useState(0);
  const [previousSectionIndex, setPreviousSectionIndex] = useState(0);
  const [openESignatureDrawerRequested, setOpenESignatureDrawerRequested] = useState(false);
  const [shouldPointOfSalesTriggerLoadingStatus, setShouldPointOfSalesTriggerLoadingStatus] = useState(true);

  const isESignStatusChanging =
    updateESignCeremonyStatusMutation.isLoading ||
    submitInsuranceApplicationMutation.isLoading ||
    sendESignCeremonyMutation.isLoading ||
    isGetApplicationFetching ||
    (isPointOfSalesDecisionsFetching && shouldPointOfSalesTriggerLoadingStatus) ||
    (isGetESignCeremonyFetching && eSignCeremony?.status !== ESignCeremonyStatus.IN_PROGRESS);

  const areAllFieldsDisabled = useMemo(
    () =>
      application.signed ||
      application.submitted ||
      isSubmittingApplication ||
      shouldDisableFields ||
      isESignStatusChanging,
    [application.signed, application.submitted, isSubmittingApplication, shouldDisableFields, isESignStatusChanging]
  );

  const questionnaireEngine = useMemo(() => {
    const timezoneResult = Timezone.from(application.timezone || DEFAULT_TIMEZONE_NAME);
    if (timezoneResult.isError()) {
      throw new Error('Could not create the timezone for the application or using the default one.');
    }

    // There are performance implications to this object being remade (some internal data structures get initialized when updating answers that are expensive to rebuild)
    // Once the questionnaire and nodeIdToAnswerPathMap are loaded remaking this object should be avoided.
    return new QuestionnaireEngine(
      questionnaire,
      nodeIdToAnswerPathMap,
      {
        platformTypes: [PlatformType.producer],
        insuranceModules: [InsuranceModule.insuranceApplication],
        applicationModes: [application.mode],
      },
      getQuestionnaireEngineConfig(application.mode, features),
      timezoneResult.value
    );
  }, [application.timezone, questionnaire, nodeIdToAnswerPathMap]);

  const { answers, onAnswerChange, onBulkAnswerClear, hasUnsavedChanges, manuallySaveAnswers } = useAutoSaveAnswers({
    application,
    questionnaireEngine,
    saveAnswers,
    isSavingAnswers,
    shouldRefreshAnswers,
    nodesToRefresh,
    onAfterRefreshAnswers,
  });

  async function onOpenESignatureDrawer(): Promise<void> {
    !application.submitted && (await refreshPricingManual());
    setOpenESignatureDrawerRequested(true);
  }

  const onClose = useCallback(async () => {
    hasUnsavedChanges && (await manuallySaveAnswers(true));
    dispatch(
      notificationSlice.actions.setSuccess({
        message: t('notifications.saveApplicationSuccess'),
        autoHideDuration: 3000,
      })
    );
  }, [hasUnsavedChanges, manuallySaveAnswers, dispatch, t]);

  async function onAddProposedInsured(): Promise<void> {
    if (areAllFieldsDisabled) return;
    hasUnsavedChanges && (await manuallySaveAnswers());
    return onAddProposedInsuredProp();
  }

  async function onRemoveProposedInsured(surrogateId: string): Promise<void> {
    if (areAllFieldsDisabled) return;
    hasUnsavedChanges && (await manuallySaveAnswers());
    return onRemoveProposedInsuredProp(surrogateId);
  }

  function onSwitchSectionGroupTab(sectionGroupId: SectionGroupId): void {
    if (sectionGroupId !== currentSectionGroupId) {
      setPreviousSectionIndex(activeSectionIndex);
      setActiveSectionIndex(previousSectionIndex);
    }
    setCurrentSectionGroupId(sectionGroupId);
  }

  // This effect detects if the AA is unmounted due to the browser's back button being pressed
  useEffect(() => {
    const currentLocation = location.href;
    return () => {
      if (history.action === 'POP' && currentLocation !== location.href) {
        void onClose();
      }
    };
  }, [history.action, onClose]);

  const renderingQuestionnaire = useMemo(() => {
    return questionnaireEngine.generateRenderingQuestionnaire(answers, locale, t, {
      renderingType: RenderingType.web,
      shouldValidateAllAnswers: displayValidationErrors,
      allFieldsCompleted: areAllFieldsDisabled || isESignStatusChanging,
      loadingFields: shouldDisableFields || isESignStatusChanging,
    });
  }, [
    questionnaireEngine,
    answers,
    locale,
    t,
    displayValidationErrors,
    areAllFieldsDisabled,
    isESignStatusChanging,
    shouldDisableFields,
  ]);

  const isQuestionnaireCompleted = useMemo(() => {
    return isRenderingQuestionnaireComplete(renderingQuestionnaire);
  }, [renderingQuestionnaire]);

  const applicantName = useMemo(() => {
    return getInsuredFullName(answers, nodeIdToAnswerPathMap);
  }, [answers, nodeIdToAnswerPathMap]);

  const insuranceEntities = useMemo(() => {
    return new InsuranceEntities(nodeIdToAnswerPathMap, entitySelectors);
  }, [nodeIdToAnswerPathMap, entitySelectors]);

  const productsEntity = useMemo(() => {
    if (!features.loadProductInformationFromDb?.enabled) return null;
    if (!features.assistedApplication?.productsWidget?.enabled) return null;

    const hideRidersPremium = features.assistedApplication?.productsWidget?.hideRidersPremium;

    return insuranceEntities.getProducts<string>(
      answers,
      products as LocalizedInsuranceProduct[],
      quotes,
      hideRidersPremium
    );
  }, [features, insuranceEntities, answers, products, quotes]);

  const proposedInsuredTabs = useMemo(
    () => getInsuredPeopleTabs(insuranceEntities, answers, nodeIdToAnswerPathMap),
    [answers, nodeIdToAnswerPathMap, insuranceEntities]
  );

  const hashedApplicationId = useMemo(() => hash(application.id), [application.id]);

  const onAnswerComplete = useCallback(
    (fieldId: string) => {
      TypewriterTracking.completedField({
        fieldId,
        hashedId: hashedApplicationId,
      });
    },
    [hashedApplicationId]
  );

  const onOpenDocumentsDrawer = useCallback(() => {
    TypewriterTracking.clickedButton({
      buttonName: 'documents drawer',
      hashedId: application?.id ? hash(application.id) : null,
    });
    onOpenDocumentDrawerHook();
  }, [application, onOpenDocumentDrawerHook]);

  const processSubmission = useCallback(
    async (signers: ESignSigner2FAInfo[] = []) => {
      if (!application || !applicationSignatureType) return;
      const selectedProduct = quotedProducts.find((product) => product.productId === application.product);
      const premiumForSelectedProduct = selectedProduct?.premium || productsEntity?.totalPremium;

      let updatedApplication: Application | undefined;

      if (application.isPreview) {
        updatedApplication = await dispatch(
          processPreviewApplicationSubmission({ application, productQuote: premiumForSelectedProduct })
        );
      } else if (applicationSignatureType === SignatureType.eSignature) {
        if (signers.length === 0 && application.mode === ApplicationMode.digital) {
          throw new Error('Failed to get signers for application.');
        }

        updatedApplication = await dispatch(
          processApplicationSubmission({
            application,
            productQuote: premiumForSelectedProduct,
            signatureType: applicationSignatureType,
            signers,
            // TODO [DEV-10295] https://breathelife.atlassian.net/browse/DEV-10295 Don't pass this down once we migrate AA submission to react-query
            sendESignCeremonyMutation,
          })
        );
      } else {
        updatedApplication = await dispatch(
          processApplicationSubmission({
            application,
            productQuote: premiumForSelectedProduct,
            signatureType: applicationSignatureType,
          })
        );
      }

      setHasInitiatedSubmission(true);

      if (updatedApplication) {
        queryClient.setQueryData([QueryId.application, application.id], updatedApplication);
        void queryClient.invalidateQueries([QueryId.application, application.id]);
        void queryClient.invalidateQueries([QueryId.pointOfSaleDecision, application.id]);
      }
    },
    [
      application,
      applicationSignatureType,
      quotedProducts,
      productsEntity?.totalPremium,
      setHasInitiatedSubmission,
      queryClient,
      dispatch,
      sendESignCeremonyMutation,
    ]
  );

  const onSubmitApplication = useCallback(async () => {
    if (
      !application ||
      !applicationSignatureType ||
      (!application.isPreview && applicationSignatureType === SignatureType.eSignature)
    )
      return;

    onOpenSubmitApplicationModal();

    return processSubmission();
  }, [application, applicationSignatureType, onOpenSubmitApplicationModal, processSubmission]);

  /** TODO: see DEV-7251
   * We should have a better way of keeping track of an application's signature status regardless of the type of signature used
   * hasSignature is used to know if something is signed in the context of cryptoSignature
   * in the context of eSignature, we only check if the submission was created (application.submitted)
   * */
  const hasNoSignature = applicationSignatureType !== SignatureType.eSignature && !application.signed;
  const DECOUPLE_ESIGN_SUBMISSION_ENABLED = !!features.enableDecoupleESignFlow?.enabled;

  // Application is being edited by the user
  const isApplicationInEditMode =
    DECOUPLE_ESIGN_SUBMISSION_ENABLED && eSignCeremony?.status === ESignCeremonyStatus.DRAFT;

  // Application has been locked for review
  const isApplicationInReview =
    DECOUPLE_ESIGN_SUBMISSION_ENABLED &&
    // application has been submitted but eSignCeremony status is still in draft
    ((application.submitted && eSignCeremony?.status === ESignCeremonyStatus.DRAFT) ||
      eSignCeremony?.status === ESignCeremonyStatus.IN_PROGRESS ||
      eSignCeremony?.status === ESignCeremonyStatus.READY);

  const isSubmitButtonVisible = isApplicationInReview || !application.submitted || hasNoSignature;

  const isSubmitButtonDisabled =
    isSubmitButtonDisabledProps ||
    !isQuestionnaireCompleted ||
    hasUnsavedChanges ||
    isMissingIdentityVerificationCheck ||
    isSavingAnswers ||
    isLoadingQuotes ||
    isApplicationInEditMode;

  const DOCUMENTS_DRAWER_ENABLED = features.assistedApplication?.documentsDrawer?.enabled === true;

  const [requiredFiles, otherFiles] = useApplicationStoredFiles(application, answers, nodeIdToAnswerPathMap);

  useEffect(() => {
    if (!openESignatureDrawerRequested) return;
    if (!isSubmitButtonDisabled) {
      setShouldPointOfSalesTriggerLoadingStatus(false);
      onOpenESignatureDetails();
    } else {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.unableToSendToSignature'),
        })
      );
    }
    setOpenESignatureDrawerRequested(false);
  }, [isSubmitButtonDisabled, openESignatureDrawerRequested, onOpenESignatureDetails, dispatch, t]);

  const handleConfirmSubmitPaperAppModal = useCallback(async () => {
    onCloseSubmitPaperAppModal();
    try {
      await processSubmission();
      dispatch(
        notificationSlice.actions.setSuccess({
          message: t('modals.submitPaperApp.submitSuccessMessage'),
        })
      );
      closeAssistedApplication();
    } catch (error) {
      dispatch(
        notificationSlice.actions.setError({
          message: t('modals.submitPaperApp.submitErrorMessage'),
        })
      );
    }
  }, [onCloseSubmitPaperAppModal, processSubmission]);

  const { data: participants } = useProcessParticipantsQuery(application?.id, { enabled: isQuestionnaireCompleted });

  const onLockApplication: () => void = useCallback(async () => {
    const selectedProduct = quotedProducts.find((product) => product.productId === application.product);
    const premiumForSelectedProduct = selectedProduct?.premium || productsEntity?.totalPremium;
    if (application.id && participants) {
      await submitInsuranceApplicationMutation.mutateAsync({
        applicationId: application.id,
        productQuote: premiumForSelectedProduct,
      });
      updateESignCeremonyStatusMutation.mutate({
        applicationId: application.id,
        data: { status: ESignCeremonyStatus.IN_PROGRESS, signers: participants },
      });
    }
  }, [
    quotedProducts,
    productsEntity?.totalPremium,
    application.id,
    application.product,
    submitInsuranceApplicationMutation,
    updateESignCeremonyStatusMutation,
    participants,
  ]);

  const onUnlockApplication: () => void = useCallback(async () => {
    updateESignCeremonyStatusMutation.mutate({
      applicationId: application.id,
      data: { status: ESignCeremonyStatus.DRAFT },
    });
  }, [application.id, updateESignCeremonyStatusMutation]);

  const onSubmitESignature = (signers: ESignSigner2FAInfo[]): void => {
    if (DECOUPLE_ESIGN_SUBMISSION_ENABLED) {
      updateESignCeremonyStatusMutation.mutate({
        applicationId: application.id,
        data: { status: ESignCeremonyStatus.SENT, signers },
      });
    } else {
      void processSubmission(signers);
    }
  };

  // Display the esign loader when sending the eSignCeremony to sign
  const isESignatureDetailLoading = DECOUPLE_ESIGN_SUBMISSION_ENABLED
    ? updateESignCeremonyStatusMutation.isLoading
    : sendESignCeremonyMutation.isLoading;

  return (
    <AssistedApplicationContext.Provider
      value={{
        // Proposed insured
        currentSectionGroupId,
        onSwitchSectionGroupTab,
        proposedInsuredTabs,
        currentProposedInsuredIndex,
        onSelectProposedInsured,
        isLoadingProposedInsured,
        onAddProposedInsured,
        onRemoveProposedInsured,
        activeSectionIndex,
        setActiveSectionIndex,

        // Form
        areAllFieldsDisabled,
        isSavingAnswers,
        onAnswerChange,
        onAnswerComplete,
        onBulkAnswerClear,
      }}
    >
      <Box display='flex' flexDirection='column' height='100vh'>
        <Box>
          {googleMapsApiKey && <GoogleMapsHelmet googleMapsApiKey={googleMapsApiKey} />}
          <AssistedApplicationHeader
            applicantName={applicantName}
            applicationSignatureType={applicationSignatureType}
            applicationMode={application.mode}
            close={() => {
              void onClose();
              closeAssistedApplication();
            }}
            isMissingRequiredFiles={isMissingRequiredFiles}
            isPreview={application.isPreview}
            isSubmitButtonDisabled={isSubmitButtonDisabled}
            isSubmitButtonVisible={isSubmitButtonVisible}
            onSubmitApplication={onSubmitApplication}
            onOpenESignatureDetails={onOpenESignatureDrawer}
            onOpenFileAttachmentModal={onOpenFileAttachmentModal}
            onOpenDocumentsDrawer={onOpenDocumentsDrawer}
            triggerIdentityVerification={triggerIdentityVerification}
            onOpenInstantIdReportDrawer={onOpenInstantIdReportDrawer}
            onOpenSubmitPaperAppModal={onOpenSubmitPaperAppModal}
          />
        </Box>
        <Box>
          <AssistedApplicationView
            renderingQuestionnaire={renderingQuestionnaire}
            setDisplayValidationErrors={setDisplayValidationErrors}
            selectedProductId={application.product}
            coverageAmount={application.coverageAmount}
            isApplicationSubmitted={application.submitted}
            isApplicationSigned={application.signed}
            isQuestionnaireCompleted={isQuestionnaireCompleted}
            onOpenSubmissionDetailsModal={onOpenSubmissionDetailsModal}
            productsEntity={productsEntity}
            proposedInsuredIndexToDelete={proposedInsuredIndexToDelete}
            onOpenDeleteProposedInsuredModal={onOpenDeleteProposedInsuredModal}
            onCloseDeleteProposedInsuredModal={onCloseDeleteProposedInsuredModal}
            onLockApplication={onLockApplication}
            onUnlockApplication={onUnlockApplication}
            onOpenESignatureDetails={onOpenESignatureDrawer}
            isESignStatusChanging={isESignStatusChanging}
            applicationSignatureType={applicationSignatureType}
          />
        </Box>
      </Box>

      {DOCUMENTS_DRAWER_ENABLED && (
        <RestrictedToPermission permission={[Permission.ApplicationFileRead]}>
          <DocumentsDrawer
            isOpen={isDocumentDrawerOpen}
            onClose={onCloseDocumentDrawer}
            application={application}
            onChangeMissingRequiredFiles={onChangeMissingRequiredFiles}
            requiredFiles={requiredFiles}
            otherFiles={otherFiles}
          />
        </RestrictedToPermission>
      )}

      {applicationSignatureType === SignatureType.eSignature && (
        <React.Fragment>
          <ESignatureDetailsContainer
            isLoading={isESignatureDetailLoading}
            isOpen={isESignatureDetailsOpen}
            onClose={onCloseESignatureDetails}
            onSubmit={onSubmitESignature}
            requiredFiles={requiredFiles}
          />
          <InfoMessageTrackESignatureModal
            isOpen={isInfoMessageTrackESignatureModalOpen}
            onClose={onCloseInfoMessageTrackESignatureModal}
          />
        </React.Fragment>
      )}
      {application.mode === ApplicationMode.paper && (
        <SubmitPaperAppModal
          isOpen={isSubmitPaperAppModalOpen}
          onClose={onCloseSubmitPaperAppModal}
          onConfirm={handleConfirmSubmitPaperAppModal}
        />
      )}
    </AssistedApplicationContext.Provider>
  );
}
