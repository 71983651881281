import React from 'react';
import { useTranslation } from 'react-i18next';

import { SearchTextField } from '../../../../Components/TextInputs';
import { StyledSearchContainer } from './Styles';

type ApplicationsTableHeaderProps = {
  onSearchChange: (search: string) => void;
};

export function ApplicationsTableHeader(props: ApplicationsTableHeaderProps): React.ReactElement {
  const { onSearchChange } = props;
  const { t } = useTranslation();

  return (
    <StyledSearchContainer>
      <SearchTextField onSearchChange={onSearchChange} placeholder={t('applicationTable.search')} fullWidth />
    </StyledSearchContainer>
  );
}
