import React from 'react';

import {
  CloudinaryConfig,
  InsuranceScopes,
  Language,
  NavigationSidebarVariants,
  UserFields,
  EntityMappingsConfig,
} from '@breathelife/types';

import { FileType } from '../Helpers/getFileIcon';
import { EnabledTabs } from '../Models/Layout';
import { LeadStatus, LeadStatusesColumnData } from '../Models/Lead';
import { LeadTableColumnInfo } from '../Models/LeadTableColumns';
import { Mga } from '../Models/Mga';
import { PlatformFeatures } from '../Models/PlatformFeatures';
import { CountryCode, LocalizedHostname } from '../types';

export type ContextProps = {
  carrierInfo: {
    companyName: string;
    logo: string;
    logoCompact: string;
    validFileUploadMimeTypes?: string[];
  };
  cloudinaryConfig?: CloudinaryConfig;
  countryCode: CountryCode;
  enabledTabs: EnabledTabs;
  externalResources?: {
    downloadableFiles?: { title: string; subtitle?: string; source: string; type: FileType }[];
    headerMenuResources?: { title: string; link: string; icon: React.ReactElement<SVGElement> }[];
  };
  headerVariant?: 'primary' | 'secondary' | 'light';
  isEmailActionEnabled: (status: LeadStatus) => boolean;
  leadStatuses: LeadStatusesColumnData;
  leadTableColumns: LeadTableColumnInfo[];
  unsubToolUrl?: string;
  ssoConnectionName?: string;
  defaultToSsoConnection?: boolean;
  mgas: Mga[];
  insuranceScopes: InsuranceScopes[];
  languageSettings: {
    enabledLanguages: Language[];
    default: Language;
  };
  availablePlatformLanguages?: Language[];
  defaultPlatformLanguage?: Language;
  features: PlatformFeatures;
  navigationSidebarVariant?: NavigationSidebarVariants;
  iconMap: Record<string, string>;
  ssoProfileFields: UserFields;
  coverageAmountStepRounding?: number;
  pdfFetchDuration?: number;
  showBlueprintIdInEditor?: boolean;
  entityMappings?: EntityMappingsConfig;
  localizedHostnames?: LocalizedHostname[];
};

export const CarrierContext = React.createContext({} as ContextProps);
