import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';

import { StoredFileWithSignedUrl } from '@breathelife/types';

import { useGetApplicationFileQuery } from '../../ReactQuery/Application/application.queries';
import { notificationSlice } from '../../Redux/Notification/NotificationSlice';
import { useDispatch } from '../useDispatch';

type UseGetApplicationFileAPI = {
  fileToView: UseQueryResult<StoredFileWithSignedUrl, Error>;
  fetchFile: () => void;
};

export function useGetApplicationFile(
  applicationId: string,
  fileId: string,
  onSuccessCallback: (file: StoredFileWithSignedUrl) => void
): UseGetApplicationFileAPI {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const fileToView = useGetApplicationFileQuery(applicationId, fileId, {
    enabled: false,
    onSuccess: onSuccessCallback,
    onError: useCallback(() => {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToFetchUploadedFile'),
          autoHideDuration: 5000,
        })
      );
    }, [dispatch, t]),
  });

  const fetchFile = useCallback(() => {
    if (!fileToView.data || fileToView.isStale) {
      void fileToView.refetch();
    } else {
      onSuccessCallback(fileToView.data);
    }
  }, [fileToView, onSuccessCallback]);

  return {
    fileToView,
    fetchFile,
  };
}
