import { Grid } from '@material-ui/core';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import {
  PdfDocumentType,
  IconName,
  InsuranceModule,
  InsuranceScopes,
  Localizable,
  PlatformType,
  QuestionnaireBlueprintRenderOn,
  SectionBlueprint,
  SectionGroupKey,
  QuestionnaireBlueprintCopyableOption,
} from '@breathelife/types';
import { AutocompleteOption, Input as TextInput } from '@breathelife/ui-components';

import { SubmitButton } from '../../../../../Components/Button/SubmitButton';
import { CarrierContext } from '../../../../../Context/CarrierContext';
import { dataLabelSelectionToDataLabel } from '../../../../../Helpers/questionnaireEditor/selectOptions';
import { ModalLayout } from '../../../../../Layouts/Modal/ModalLayout';
import { QuestionnaireEditorContext } from '../../QuestionnaireEditorContextProvider';
import { useQuestionnaireVersionDetailWithNodeIdInfo } from '../../QuestionnaireVersionDataContextProvider';
import { AdvancedBlueprintOptions } from '../Components/AdvancedBlueprintOptions';
import { EditorDropdown } from '../Components/EditorDropdown';

export function CreateSectionModal({
  sectionGroupKey,
  onCloseModal,
  onCreate,
  dataLabelOptions,
}: {
  sectionGroupKey: SectionGroupKey;
  onCloseModal: () => void;
  onCreate: (blueprint: SectionBlueprint) => void;
  dataLabelOptions: AutocompleteOption[];
}): React.ReactElement | null {
  const { selectedLanguage } = useContext(QuestionnaireEditorContext);
  const { blueprint } = useQuestionnaireVersionDetailWithNodeIdInfo() || {};
  const sectionGroupBlueprints = blueprint?.sectionGroupBlueprints;

  const { languageSettings } = useContext(CarrierContext);
  const { enabledLanguages } = languageSettings;

  const { t } = useTranslation();

  const [title, setTitle] = useState<Partial<Localizable>>({});
  const [iconName, setIconName] = useState<IconName>();
  const [dataLabel, setDataLabel] = useState<string>(dataLabelOptions?.length ? dataLabelOptions[0].value : '');
  const [renderOn, setRenderOn] = useState<QuestionnaireBlueprintRenderOn[]>([]);
  const [scopes, setScopes] = useState<InsuranceScopes[]>([]);
  const [platforms, setPlatforms] = useState<PlatformType[]>([]);
  const [pdfDocuments, setPdfDocuments] = useState<PdfDocumentType[]>([]);
  const [insuranceModule, setInsuranceModule] = useState<InsuranceModule>(InsuranceModule.insuranceApplication);
  const [copyable, setCopyable] = useState<QuestionnaireBlueprintCopyableOption>(
    QuestionnaireBlueprintCopyableOption.none
  );

  const onSubmit = useCallback(() => {
    const blueprintDataLabel = dataLabelSelectionToDataLabel(dataLabel);

    const sectionBlueprint: SectionBlueprint = {
      id: uuid(),
      sectionGroupKey,
      partName: `custom-${uuid()}`,
      title,
      iconName,
      modules: [insuranceModule],
      dataLabel: blueprintDataLabel,
      isCustom: true,
      subsections: [],
      copyable,
    };

    if (renderOn.length) sectionBlueprint.renderOn = renderOn;
    if (scopes.length) sectionBlueprint.scopes = scopes;
    if (platforms.length) sectionBlueprint.platforms = platforms;
    if (pdfDocuments.length) sectionBlueprint.pdfDocuments = pdfDocuments;

    onCreate(sectionBlueprint);
    onCloseModal();
  }, [
    dataLabel,
    onCloseModal,
    onCreate,
    title,
    iconName,
    renderOn,
    scopes,
    platforms,
    insuranceModule,
    pdfDocuments,
    sectionGroupKey,
    copyable,
  ]);

  if (!selectedLanguage) {
    return null;
  }

  const createSectionTitle = t('admin.questionnaireManagement.input.createSection.title', {
    sectionGroupTitle: sectionGroupBlueprints?.[sectionGroupKey]?.title?.[selectedLanguage],
  });

  return (
    <ModalLayout
      maxWidth='md'
      isOpen
      closeModal={onCloseModal}
      title={createSectionTitle}
      submitButton={
        <SubmitButton onClick={onSubmit} data-testid='createQuestionnaireSection'>
          {t('cta.save')}
        </SubmitButton>
      }
    >
      <Grid container spacing={2} alignItems='center'>
        {enabledLanguages.map((language) => (
          <Grid item xs={6} key={`createSection-title-${language}`}>
            <TextInput
              inputVariant='outlined'
              label={t('admin.questionnaireManagement.input.titleAndLanguage', { language: t(`language.${language}`) })}
              value={title[language] ?? ''}
              onChange={(event) => {
                const value = event.target.value;
                setTitle((previousValue) => ({ ...previousValue, [language]: value }));
              }}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <EditorDropdown
            label={t('admin.questionnaireManagement.input.sectionIcon')}
            selectedOptionValue={iconName}
            options={Object.keys(IconName).map((iconName) => {
              return { label: t(`admin.questionnaireManagement.questionnaireIconLabels.${iconName}`), value: iconName };
            })}
            disableClearable={false}
            onChange={(value) => {
              const newIconName = (value || undefined) as IconName | undefined;
              setIconName(newIconName);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <AdvancedBlueprintOptions
            tag='section'
            insuranceModule={{ selected: insuranceModule, onChange: setInsuranceModule }}
            pdfDocument={{ selected: pdfDocuments, onChange: setPdfDocuments }}
            platform={{ selected: platforms, onChange: setPlatforms }}
            renderOn={{ selected: renderOn, onChange: setRenderOn }}
            scope={{ selected: scopes, onChange: setScopes }}
            dataLabel={{
              selected: dataLabel,
              options: dataLabelOptions,
              onChange: (value) => {
                if (value !== null) {
                  setDataLabel(value);
                }
              },
            }}
            copyable={{
              selected: copyable,
              onChange: (value) => {
                if (value !== null) {
                  setCopyable(value as QuestionnaireBlueprintCopyableOption);
                }
              },
            }}
            disabled={false}
          />
        </Grid>
      </Grid>
    </ModalLayout>
  );
}
