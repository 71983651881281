import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import i18next from 'i18next';
import _ from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { deserializeNodeIdToAnswerPathMap } from '@breathelife/questionnaire-engine';
import { AgentLicenseVerificationStatus } from '@breathelife/types';
import { MessageType, Popper } from '@breathelife/ui-components';

import { Icon } from '../../../../Components/Icons';
import Typography from '../../../../Components/Typography';
import {
  refreshLicenseValidation,
  getProvincesOrStates,
  getOwnerIsInsured,
  getOwnerAddressIsSameAsInsured,
} from '../../../../Helpers/assistedApplication/licenseValidation';
import { useCarrierContext, useNavigation, useSelector, useDispatch } from '../../../../Hooks';
import { useGetAgentLicensesQuery } from '../../../../ReactQuery/AgentLicense/agentLicense.queries';
import { useGetApplicationQuery } from '../../../../ReactQuery/Application/application.queries';
import { useFetchQuestionnaireQuery } from '../../../../ReactQuery/Questionnaire/questionnaire.queries';
import { notificationSlice } from '../../../../Redux/Notification/NotificationSlice';
import { IconContainer, PopperBox, TextContainer } from './Styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 1px 4px rgb(0 0 0 / 25%)',
      borderRadius: '8px',
      marginTop: '15px',
    },
  })
);

const PopperWithZIndex = styled(Popper)`
  z-index: 3000;
`;

const verificationStatusMessageLookup = {
  [AgentLicenseVerificationStatus.valid]: {
    messageType: MessageType.success,
    messageKey: 'popper.licenseVerification.status.valid',
    autoHideDuration: 3000,
  },
  [AgentLicenseVerificationStatus.invalidJurisdiction]: {
    messageType: MessageType.warning,
    messageKey: 'popper.licenseVerification.status.invalidJurisdiction',
    autoHideDuration: 3000,
  },
  [AgentLicenseVerificationStatus.expired]: {
    messageType: MessageType.warning,
    messageKey: 'popper.licenseVerification.status.expired',
    autoHideDuration: 3000,
  },
};

export function LicenseVerificationPopper(): React.ReactElement {
  const authentication = useSelector((store) => store.leadPlatform.authentication);

  const dispatch = useDispatch();
  const { applicationId } = useNavigation();
  const { data: application } = useGetApplicationQuery(applicationId);
  const { data: questionnaireData } = useFetchQuestionnaireQuery(applicationId);
  const { data: licensesData } = useGetAgentLicensesQuery(authentication.user?.customId);
  const [prevProvincesOrStates, setPrevProvincesOrStates] = useState<string[] | null>(null);
  const [ownerIsInsuredState, setOwnerIsInsuredState] = useState<string | null>(null);
  const [ownerAddressIsSameAsInsuredState, setOwnerAddressIsSameAsInsuredState] = useState<string[] | null>(null);
  const isFirstRender = useRef(true);
  const { features } = useCarrierContext();

  const [licenceVerificationStatus, setLicenceVerificationStatus] = useState(AgentLicenseVerificationStatus.neutral);

  useEffect(() => {
    if (!questionnaireData?.nodeIdToAnswerPath || !licensesData) {
      return;
    }

    const ownerIsInsured = getOwnerIsInsured(
      features,
      deserializeNodeIdToAnswerPathMap(questionnaireData?.nodeIdToAnswerPath),
      application?.answers
    );

    const ownerAddressIsSameAsInsured = getOwnerAddressIsSameAsInsured(
      features,
      deserializeNodeIdToAnswerPathMap(questionnaireData?.nodeIdToAnswerPath),
      application?.answers
    );

    const provincesOrStates = getProvincesOrStates(
      features,
      deserializeNodeIdToAnswerPathMap(questionnaireData?.nodeIdToAnswerPath),
      application?.answers
    );

    if (
      !_.isEqual(prevProvincesOrStates, provincesOrStates) ||
      !_.isEqual(ownerIsInsuredState, ownerIsInsured) ||
      !_.isEqual(ownerAddressIsSameAsInsuredState, ownerAddressIsSameAsInsured)
    ) {
      const refreshedLicenseValidationStatus = refreshLicenseValidation(
        features,
        deserializeNodeIdToAnswerPathMap(questionnaireData?.nodeIdToAnswerPath),
        licensesData,
        application?.answers
      );

      setLicenceVerificationStatus(refreshedLicenseValidationStatus);
      setPrevProvincesOrStates(provincesOrStates);
      setOwnerIsInsuredState(ownerIsInsured);
      setOwnerAddressIsSameAsInsuredState(ownerAddressIsSameAsInsured);

      if (!isFirstRender.current && refreshedLicenseValidationStatus !== AgentLicenseVerificationStatus.neutral) {
        const notificationInfo = verificationStatusMessageLookup[refreshedLicenseValidationStatus];
        dispatch(
          notificationSlice.actions.setNotification({
            type: notificationInfo.messageType,
            message: i18next.t(notificationInfo.messageKey),
            autoHideDuration: notificationInfo.autoHideDuration,
          })
        );
      }
    }
    if (isFirstRender) isFirstRender.current = false;
  }, [
    application,
    features,
    questionnaireData,
    licensesData,
    prevProvincesOrStates,
    ownerIsInsuredState,
    ownerAddressIsSameAsInsuredState,
  ]);

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const classes = useStyles();

  const onOpenLicenseVerificationPopper = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const isOpen = !!anchorEl;
  const popperId = isOpen ? 'licenseverification-popper' : undefined;

  const handleClickAway = (): void => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box mr={2}>
        <Button
          color='secondary'
          variant='outlined'
          data-testid='license_verification_button'
          onClick={onOpenLicenseVerificationPopper}
          startIcon={<Icon name='licensesIcon' />}
        >
          {t('assistedApplication.licenseVerification')}
        </Button>
      </Box>
      <Box>
        <IconContainer>
          {licenceVerificationStatus === AgentLicenseVerificationStatus.valid ? (
            <Icon name='circledCheckMark' variant='green' inverted stroke='none' />
          ) : (
            <Icon name='circleFilledWarning' fill='#D9A810' variant='orange' inverted stroke='none' />
          )}
        </IconContainer>
      </Box>
      {anchorEl && (
        <PopperWithZIndex id={popperId} isOpen={isOpen} anchorEl={anchorEl} className={classes.paper} placement='auto'>
          <ClickAwayListener onClickAway={handleClickAway}>
            <PopperBox>
              <Box ml={2} alignContent='center' data-testid='license_verification_text'>
                <TextContainer>
                  {licenceVerificationStatus === AgentLicenseVerificationStatus.neutral && (
                    <Typography variant='body3' grey={90}>
                      {t('popper.licenseVerification.status.neutral')}
                    </Typography>
                  )}
                  {licenceVerificationStatus === AgentLicenseVerificationStatus.valid && (
                    <Typography variant='body3' grey={90}>
                      {t('popper.licenseVerification.status.valid')}
                    </Typography>
                  )}
                  {licenceVerificationStatus === AgentLicenseVerificationStatus.invalidJurisdiction && (
                    <Typography variant='body3' grey={90}>
                      {t('popper.licenseVerification.status.invalidJurisdiction')}
                    </Typography>
                  )}
                  {licenceVerificationStatus === AgentLicenseVerificationStatus.expired && (
                    <Typography variant='body3' grey={90}>
                      {t('popper.licenseVerification.status.expired')}
                    </Typography>
                  )}
                </TextContainer>
              </Box>
            </PopperBox>
          </ClickAwayListener>
        </PopperWithZIndex>
      )}
    </React.Fragment>
  );
}
