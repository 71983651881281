import React, { PropsWithChildren, useCallback, useState } from 'react';

import { BackOfficeApplication, PageQueryOptions, SortParams } from '@breathelife/types';

import { useFindBackOfficeApplicationsQuery } from '../../../../ReactQuery/BackOffice/Application/application.queries';

export const PER_PAGE_OPTIONS = [
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

type ApplicationsContextValue = {
  applications: BackOfficeApplication[];
  total: number;
  isLoading: boolean;
  isSuccess: boolean;
  onPageChange: ($page: number) => void;
  onLimitPerPageChange: ($limit: number) => void;
  onSortingChange: ($sort: SortParams<BackOfficeApplication>) => void;
  onSearchChange: ($search?: string) => void;
  currentPage: number;
  perPage: number;
};

export const ApplicationsContext = React.createContext<ApplicationsContextValue>({
  applications: [],
  total: 0,
  isLoading: false,
  isSuccess: true,
  onPageChange: () => {},
  onLimitPerPageChange: () => {},
  onSortingChange: () => {},
  onSearchChange: () => {},
  perPage: 0,
  currentPage: 1,
});

export function ApplicationsContextProvider(props: PropsWithChildren<{}>): React.ReactElement {
  const [backOfficeQueryOptions, setBackOfficeQueryOptions] = useState<PageQueryOptions<BackOfficeApplication>>({
    $page: 1,
    $limit: PER_PAGE_OPTIONS[1].value,
    $sort: undefined,
  });

  const {
    data: applicationsGetResponse,
    isLoading,
    isSuccess,
  } = useFindBackOfficeApplicationsQuery(backOfficeQueryOptions);

  const onPageChange = useCallback(($page: number): void => {
    setBackOfficeQueryOptions((prevValue: PageQueryOptions<BackOfficeApplication>) => ({ ...prevValue, $page }));
  }, []);

  const onLimitPerPageChange = useCallback(($limit: number): void => {
    setBackOfficeQueryOptions((prevValue: PageQueryOptions<BackOfficeApplication>) => ({
      ...prevValue,
      $limit,
    }));
  }, []);

  const onSortingChange = useCallback(($sort: SortParams<BackOfficeApplication>) => {
    setBackOfficeQueryOptions((prevValue: PageQueryOptions<BackOfficeApplication>) => ({ ...prevValue, $sort }));
  }, []);

  const onSearchChange = useCallback(($search?: string) => {
    setBackOfficeQueryOptions((prevValue: PageQueryOptions<BackOfficeApplication>) => ({
      ...prevValue,
      $search,
    }));
  }, []);

  return (
    <ApplicationsContext.Provider
      value={{
        applications: applicationsGetResponse?.data || [],
        total: applicationsGetResponse?.total || 0,
        isLoading,
        isSuccess,
        onPageChange,
        onLimitPerPageChange,
        onSortingChange,
        onSearchChange,
        perPage: applicationsGetResponse?.limit || PER_PAGE_OPTIONS[1].value,
        currentPage: backOfficeQueryOptions.$page || 1,
      }}
    >
      {props.children}
    </ApplicationsContext.Provider>
  );
}
