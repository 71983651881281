/* eslint-disable react/jsx-key */
/* the keys are already returned by the `react-table` prop getters */

import MUITable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import React, { useCallback, useEffect } from 'react';
import { IdType, TableOptions, useRowSelect, useSortBy, useTable } from 'react-table';

import { SortDirection } from '@breathelife/types';

import { ReactComponent as ChevronDown } from '../../Assets/chevron-down-icon.svg';
import { StyledTableContainer, StyledTableRow } from './Styles';

type TableProps<T extends Record<string, unknown>> = TableOptions<T> & {
  onRowClick?: (rowId: IdType<T>) => void;
  onOrderChange?: (columnId: keyof T, sortDirection: SortDirection) => void;
  hiddenColumns?: IdType<T>[];
};

export function Table<T extends Record<string, unknown>>(props: TableProps<T>): React.ReactElement {
  const { columns, data, onRowClick, onOrderChange, hiddenColumns, initialState } = props;
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    getTableBodyProps,
    toggleAllRowsSelected,
    setHiddenColumns,
    state: { sortBy, selectedRowIds },
  } = useTable<T>(
    {
      columns,
      data,
      getRowId: useCallback((row) => row.id as IdType<T>, []),
      manualSortBy: !!onOrderChange,
      initialState,
      disableSortRemove: true,
    },
    useSortBy,
    useRowSelect
  );

  useEffect(() => {
    if (hiddenColumns?.length) {
      setHiddenColumns(hiddenColumns);
    }
  }, [hiddenColumns, setHiddenColumns]);

  useEffect(() => {
    if (!onRowClick || !selectedRowIds) return;
    const selectedId = Object.keys(selectedRowIds).filter((row) => selectedRowIds[row])[0];
    return onRowClick(selectedId);
  }, [selectedRowIds, onRowClick]);

  useEffect(() => {
    if (!onOrderChange || !sortBy || !Array.isArray(sortBy) || !sortBy.length) return;
    const { id, desc } = sortBy[0];
    onOrderChange(id, desc ? 'desc' : 'asc');
  }, [sortBy, onOrderChange]);

  return (
    <StyledTableContainer>
      <MUITable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  sortDirection={column.isSortedDesc ? 'desc' : 'asc'}
                >
                  {column.render('Header')}
                  {column.canSort && (
                    <TableSortLabel
                      active={column.isSorted}
                      direction={column.isSortedDesc ? 'desc' : 'asc'}
                      // the icon components are typed as "SvgrComponent"
                      // @ts-ignore
                      IconComponent={ChevronDown}
                    />
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <StyledTableRow
                {...row.getRowProps()}
                onClick={() => {
                  toggleAllRowsSelected(false);
                  row.toggleRowSelected();
                }}
              >
                {row.cells.map((cell) => {
                  return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>;
                })}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </MUITable>
    </StyledTableContainer>
  );
}
