import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { ImportedData, ImportQueryParams } from '@breathelife/types';

import { QueryId } from '../../../ReactQuery/common/common.types';
import { fetchProducts } from '../../../Redux/Admin/ProductManagement/ProductManagementOperations';
import { salesDecisionRuleManagementSlice } from '../../../Redux/Admin/SalesDecisionRulesManagement/SalesDecisionRulesManagementSlice';
import { settingsSlice } from '../../../Redux/Admin/SettingsManagement/SettingsSlice';
import { themeSlice } from '../../../Redux/Admin/ThemeManagement/ThemeSlice';
import { notificationSlice } from '../../../Redux/Notification/NotificationSlice';
import { uploadDataForImport } from '../../../Services/ImportDataService';
import { QuestionnaireVersionDetailWithNodeIdInfo } from '../../../Services/QuestionnaireVersionService';

type ImportDataMutationVariables = { query: ImportQueryParams; importData: ImportedData };

export function useImportDataMutation(
  options?: UseMutationOptions<ImportedData, unknown, ImportDataMutationVariables>
): UseMutationResult<ImportedData, unknown, ImportDataMutationVariables> {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  async function onSuccess(
    data: ImportedData,
    variables: ImportDataMutationVariables,
    context: unknown
  ): Promise<void> {
    if (data.blueprint) {
      queryClient.setQueryData<QuestionnaireVersionDetailWithNodeIdInfo | undefined>(
        [QueryId.questionnaireVersion, variables.query.questionnaireVersionId],
        (queryData) => {
          if (queryData && data.blueprint) {
            return { ...queryData, blueprint: data.blueprint };
          }
          return queryData;
        }
      );
    }

    if (data.salesDecisionRules) {
      dispatch(salesDecisionRuleManagementSlice.actions.setRules({ rules: data.salesDecisionRules }));
    }

    if (data.theme) {
      dispatch(themeSlice.actions.setTheme({ theme: data.theme }));
    }

    if (data.firms && data.products) {
      void dispatch(fetchProducts());
      void queryClient.invalidateQueries(QueryId.firms);
    }

    if (data.settings) {
      dispatch(settingsSlice.actions.setSettings({ settings: data.settings }));
    }

    dispatch(
      notificationSlice.actions.setSuccess({
        message: t('notifications.importedDataSuccess'),
      })
    );

    if (options?.onSuccess) {
      await options.onSuccess(data, variables, context);
    }
  }

  async function onError(err: unknown, variables: ImportDataMutationVariables, context: unknown): Promise<void> {
    if (axios.isAxiosError(err)) {
      const axiosError = err as AxiosError<any>;
      dispatch(
        notificationSlice.actions.setError({
          message: formatErrMsg(axiosError, t),
        })
      );
    }

    if (options?.onError) {
      await options.onError(err, variables, context);
    }
  }

  return useMutation(({ query, importData }) => uploadDataForImport(query, importData), {
    onSuccess,
    onError,
  });
}

function formatErrMsg(err: AxiosError<any>, t: (key: string) => string): string {
  if (
    !err.response?.data ||
    !err.response.data.data ||
    !Array.isArray(err.response.data.data) ||
    err.response.data.data.length === 0 ||
    !err.response.data.message
  ) {
    const defaultErrMsg = t('notifications.failedToImportData');
    return defaultErrMsg;
  }

  const invalidBlueprintNodeIdsStr = err.response.data.data
    .map((d: { message: string; nodeId: string }) => d.nodeId)
    .join(', ');

  return `${err.response.data.data.message}: ${invalidBlueprintNodeIdsStr}`;
}
