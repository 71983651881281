import React from 'react';

import { LeadTab } from '../../../../Models/Layout';
import { TabActiveEmptyListView, Props as TabActiveEmptyListViewProps } from './TabActiveEmptyListView';
import { TabArchivedEmptyListView } from './TabArchivedEmptyListView';

export type Props = TabActiveEmptyListViewProps;

export const componentMap: { [key in LeadTab]: React.FC<Props> } = {
  [LeadTab.active]: TabActiveEmptyListView,
  [LeadTab.archived]: TabArchivedEmptyListView,
};

export default (selectedTab: LeadTab): React.FC<Props> => componentMap[selectedTab];
