import { useMutation, useQueryClient, UseMutationResult, UseMutationOptions } from 'react-query';

import { ESignFieldSetting } from '@breathelife/types';

import { QueryId } from '../../ReactQuery/common/common.types';
import {
  createESignFieldSetting,
  updateESignFieldSetting,
  deleteESignFieldSetting,
} from '../../Services/ESignFieldSettingService';

export function useCreateESignFieldSettingMutation(
  options?: UseMutationOptions<ESignFieldSetting, unknown, Omit<ESignFieldSetting, 'id'>>
): UseMutationResult<ESignFieldSetting, unknown, Omit<ESignFieldSetting, 'id'>> {
  const queryClient = useQueryClient();
  return useMutation<ESignFieldSetting, unknown, Omit<ESignFieldSetting, 'id'>, unknown>(createESignFieldSetting, {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([QueryId.eSignFieldSettings, data.signerSettingId]);
      await queryClient.invalidateQueries(QueryId.fileTemplateRecipients);

      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
  });
}

type UpdateESignFieldSettingParameters = {
  id: string;
  data: Partial<Omit<ESignFieldSetting, 'id' | 'signerSettingId'>>;
};

export function useUpdateESignFieldSettingMutation(
  options?: UseMutationOptions<ESignFieldSetting, unknown, UpdateESignFieldSettingParameters>
): UseMutationResult<ESignFieldSetting, unknown, UpdateESignFieldSettingParameters> {
  const queryClient = useQueryClient();
  return useMutation<ESignFieldSetting, unknown, UpdateESignFieldSettingParameters, unknown>(
    ({ data, id }) => updateESignFieldSetting(id, data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([QueryId.eSignFieldSettings, data.signerSettingId]);
        await queryClient.invalidateQueries(QueryId.fileTemplateRecipients);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
    }
  );
}

export function useDeleteESignFieldSettingMutation(
  options?: UseMutationOptions<ESignFieldSetting, unknown, string>
): UseMutationResult<ESignFieldSetting, unknown, string> {
  const queryClient = useQueryClient();
  return useMutation<ESignFieldSetting, unknown, string, unknown>(deleteESignFieldSetting, {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([QueryId.eSignFieldSettings, data.signerSettingId]);
      await queryClient.invalidateQueries(QueryId.fileTemplateRecipients);

      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
  });
}
