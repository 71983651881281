import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItemText';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FileTemplate, FileTemplateOrigin, SupportedApplicationFileMimeTypes } from '@breathelife/types';
import { Loader } from '@breathelife/ui-components';

import { Icon } from '../../../../../../Components/Icons';
import Typography from '../../../../../../Components/Typography';
import { useCarrierContext, useDispatch, useLocale } from '../../../../../../Hooks';
import { CreateApplicationFileMutateOptions } from '../../../../../../ReactQuery/Application/application.mutations';
import { notificationSlice } from '../../../../../../Redux/Notification/NotificationSlice';
import { LinkButton, LinkUploadButton } from './Styles';

type MissingRequiredFileProps = {
  template: FileTemplate;
  onFileUpload: (file: File, template: FileTemplate, options?: CreateApplicationFileMutateOptions) => void;
};

export function MissingRequiredFile(props: MissingRequiredFileProps): React.ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { template, onFileUpload } = props;
  const {
    carrierInfo: { validFileUploadMimeTypes },
  } = useCarrierContext();

  const platformLanguage = useLocale();
  //  Display file name according to application language
  //  if translation does not exit: default to english name
  const localizedFileName = template.localizedName[platformLanguage] ?? template.localizedName.en;
  const mimeTypes =
    validFileUploadMimeTypes && validFileUploadMimeTypes.length > 0
      ? validFileUploadMimeTypes
      : SupportedApplicationFileMimeTypes;

  const [isLoading, setIsLoading] = useState(false);

  const onUploadFilledDocument = useCallback(
    async (file: File) => {
      setIsLoading(true);
      onFileUpload(file, template, {
        onSettled: () => setIsLoading(false),
      });
    },
    [onFileUpload, template]
  );

  const onDownloadTemplate = useCallback(async () => {
    const { origin, externalUrl } = template;
    let url: string | null;

    switch (origin) {
      case FileTemplateOrigin.ExternalStatic:
        url = externalUrl;
        break;

      default:
        // TODO: get signed URL from GCP buckets
        url = null;
        break;
    }

    if (!url) {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToFetchEntity', { entity: t('fileUrl') }),
        })
      );
      return;
    }

    window.open(url, '_blank')?.focus();
  }, [dispatch, t, template]);

  return (
    <ListItem>
      <Box display='flex' flexDirection='row' my={3}>
        <Box mr={2}>{isLoading ? <Loader size='24' /> : <Icon name='warning' variant='yellow' fill='none' />}</Box>
        <Box>
          <Box mb={1}>
            <Typography variant='h4'>{localizedFileName}</Typography>
          </Box>
          <Box display='flex' flexDirection='row' alignItems='center'>
            {[FileTemplateOrigin.ExternalStatic, FileTemplateOrigin.GCPBucket].includes(template.origin) && (
              <React.Fragment>
                <Box>
                  <LinkButton color='primary' disabled={isLoading} onClick={onDownloadTemplate}>
                    {t('cta.downloadDocument')}
                  </LinkButton>
                </Box>
                <Box component='span' mx={1}>
                  |
                </Box>
              </React.Fragment>
            )}
            <Box>
              <LinkUploadButton
                color='primary'
                disabled={isLoading}
                mimeTypes={mimeTypes}
                onChange={onUploadFilledDocument}
                component='label'
              >
                {t('cta.uploadFilledDocument')}
              </LinkUploadButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
}
