import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InsuranceScopes, Language, PublicLink, PublicLinkMetadata } from '@breathelife/types';
import { Input } from '@breathelife/ui-components';

import { Button } from '../../../Components/Button/Button';
import { Checkbox } from '../../../Components/Checkbox/Checkbox';
import { Image } from '../../../Components/Images/Image';
import Typography from '../../../Components/Typography';
import { CloudinaryUpload } from '../../../Components/Upload/Cloudinary/CloudinaryUpload';
import { StyledGrid } from './Styles';

type Props = {
  enabledLanguages: Language[];
  insuranceScopes: InsuranceScopes[];
  publicLink: PublicLink;
  isUpdating: boolean;
  onSaveClick: (publicLinkData: PublicLink) => void;
};

export function PublicLinkView({
  enabledLanguages,
  insuranceScopes,
  publicLink: publicLinkProp,
  isUpdating,
  onSaveClick,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  const [isPublicLinkEnabled, setPublicLinkEnabled] = useState<boolean>(Boolean(publicLinkProp.enabled));
  const [publicLinkMetadata, setPublicLinkMetadata] = useState<PublicLinkMetadata>(publicLinkProp?.metadata ?? {});

  useEffect(() => {
    setPublicLinkEnabled(publicLinkProp.enabled);
    setPublicLinkMetadata(publicLinkProp.metadata ?? {});
  }, [publicLinkProp]);

  const handleChange = (target: string, value: string): void => {
    const updatedPublicLinkMetadata = _.setWith(_.cloneDeep(publicLinkMetadata), target, value, _.cloneDeep);
    setPublicLinkMetadata(updatedPublicLinkMetadata);
  };

  const parseImageUrl = (url: string | undefined): string => {
    if (typeof url === 'undefined' || !/cloudinary/.test(url)) return '';
    return url;
  };

  return (
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls='publicLink-content' id='publicLink-header'>
        <Box display='flex' flexDirection='row' mr={2}>
          <Typography variant='h2'>{t('admin.settingsManagement.publicLink.title')}</Typography>
        </Box>
      </ExpansionPanelSummary>
      <Divider />
      <MuiExpansionPanelDetails>
        <Box display='flex' flexDirection='column' width='100%' m={2}>
          <StyledGrid item xs={3} sm={3}>
            <Checkbox
              color='primary'
              label={t('admin.settingsManagement.publicLink.enablePublicLink')}
              checked={isPublicLinkEnabled}
              onChange={() => setPublicLinkEnabled(!isPublicLinkEnabled)}
            />
          </StyledGrid>
          {isPublicLinkEnabled && publicLinkMetadata
            ? insuranceScopes.map((scope) => (
                <Box my={4} key={scope}>
                  <Typography variant='h3'>{t(`insuranceScope.${scope}`)}</Typography>
                  {enabledLanguages.map((language) => (
                    <React.Fragment key={language}>
                      <Box m={2}>
                        <Input
                          fullWidth
                          name={`${scope}.title.${language}`}
                          id={`${scope}-title-${language}`}
                          label={t('admin.settingsManagement.publicLink.titleLabel', {
                            language: t(`language.${language}`),
                          })}
                          inputVariant='outlined'
                          value={publicLinkMetadata[scope]?.title?.[language]}
                          onChange={(event) => handleChange(event.target.name, event.target.value)}
                        />
                      </Box>
                      <Box m={2}>
                        <Input
                          fullWidth
                          name={`${scope}.description.${language}`}
                          id={`${scope}-description-${language}`}
                          label={t('admin.settingsManagement.publicLink.descriptionLabel', {
                            language: t(`language.${language}`),
                          })}
                          inputVariant='outlined'
                          value={publicLinkMetadata[scope]?.description?.[language]}
                          onChange={(event) => handleChange(event.target.name, event.target.value)}
                        />
                      </Box>
                      <Box m={2}>
                        <Typography variant='body5'>
                          {t('admin.settingsManagement.publicLink.imageLabel', {
                            language: t(`language.${language}`),
                          })}
                        </Typography>
                        <Image
                          src={parseImageUrl(publicLinkMetadata[scope]?.imageUrl?.[language])}
                          radius={0}
                          isProfile={false}
                          width={300}
                        />
                        <CloudinaryUpload
                          buttonText={t('admin.settingsManagement.publicLink.imageButtonText', {
                            language: t(`language.${language}`),
                          })}
                          setPicture={(logo) => handleChange(`${scope}.imageUrl.${language}`, logo)}
                          uploadPreset='px_admin_public_link'
                          minWidth={1200}
                          minHeight={630}
                        />
                      </Box>
                    </React.Fragment>
                  ))}
                </Box>
              ))
            : null}
        </Box>
      </MuiExpansionPanelDetails>
      <Divider />
      <ExpansionPanelActions>
        <Button
          data-testid='publicLinkSettings-save'
          color='primary'
          variant='text'
          isLoading={isUpdating}
          onClick={() =>
            onSaveClick({
              enabled: isPublicLinkEnabled,
              metadata: publicLinkMetadata,
            })
          }
        >
          {t('cta.save').toUpperCase()}
        </Button>
      </ExpansionPanelActions>
    </ExpansionPanel>
  );
}
