import React, { SVGProps } from 'react';

export function CircledSendIcon(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg width='68' height='68' viewBox='0 0 68 68' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#filter0_d_18682_199886)'>
        <path
          d='M34 57C47.2548 57 58 46.2548 58 33C58 19.7452 47.2548 9 34 9C20.7452 9 10 19.7452 10 33C10 46.2548 20.7452 57 34 57Z'
          fill='white'
        />
        <path
          d='M57.5 33C57.5 45.9787 46.9787 56.5 34 56.5C21.0213 56.5 10.5 45.9787 10.5 33C10.5 20.0213 21.0213 9.5 34 9.5C46.9787 9.5 57.5 20.0213 57.5 33Z'
          stroke='#CCCCCC'
        />
      </g>
      <path d='M40 28L44 32.5L40 37' stroke='#476CC6' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M27 28L23 32.5L27 37' stroke='#476CC6' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M36 28L31 37' stroke='#476CC6' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <defs>
        <filter
          id='filter0_d_18682_199886'
          x='0'
          y='0'
          width='68'
          height='68'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='5' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_18682_199886' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_18682_199886' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
