import { captureMessage, Severity } from '@sentry/browser';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { ApplicationMode } from '@breathelife/types';

import { LeadPlatformTheme } from '../../Styles/Types';
import { StyledBadge } from './Badge';

export type Props = {
  mode: ApplicationMode;
};

export function ApplicationModeBadge(props: Props): React.ReactElement {
  const theme = useTheme() as LeadPlatformTheme;
  const { t } = useTranslation();

  const ApplicationModeTextColors: {
    [mode in ApplicationMode | 'default']: { textColor: string; backgroundColor: string };
  } = useMemo(
    () => ({
      digital: { textColor: theme.colors.green[50], backgroundColor: theme.colors.green[20] },
      paper: { textColor: theme.colors.purple[50], backgroundColor: theme.colors.purple[20] },
      default: { textColor: theme.colors.grey[50], backgroundColor: theme.colors.grey[20] },
    }),
    [theme]
  );

  const modeLabel = t(`applicationModes.${props.mode || 'unknown'}`);
  const statusColors = ApplicationModeTextColors[props.mode] || ApplicationModeTextColors.default;

  if (!ApplicationModeTextColors[props.mode]) {
    captureMessage(`Unknown application mode: ${props.mode}`, Severity.Error);
  }

  return (
    <StyledBadge backgroundColor={statusColors.backgroundColor} textColor={statusColors.textColor} label={modeLabel} />
  );
}
