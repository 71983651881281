import Button from '@material-ui/core/Button';
import PublishIcon from '@material-ui/icons/Publish';
import React from 'react';

import { useDispatch, useModalState } from '../../../Hooks';
import { DragAndDropUpload } from '../../../Pages/Admin/Questionnaire/QuestionnaireEditor/Components/DragAndDropUpload';
import { useCreateLeadMutation } from '../../../ReactQuery/DebugToolbar/debugToolbar.mutations';
import { navigateToLeadsList } from '../../../Redux/Navigation/NavigationOperations';

export function ImportLeadButton(): React.ReactElement {
  const dispatch = useDispatch();
  const [isImportModalOpen, onOpenImportModal, onCloseImportModal] = useModalState();

  const createLeadMutation = useCreateLeadMutation({
    onMutate: () => {
      onCloseImportModal();
    },
    onSuccess: () => {
      dispatch(navigateToLeadsList());
    },
  });

  function handleFileUpload(files: File[]): void {
    if (!files) return;
    const file = files[0];
    const reader = new FileReader();

    reader.onload = async () => {
      const importedFile = reader.result as string;

      if (!importedFile) return;

      const lead = JSON.parse(importedFile);

      createLeadMutation.mutate(lead);
    };

    reader.readAsText(file);
  }

  return (
    <React.Fragment>
      <Button variant='outlined' color='primary' onClick={onOpenImportModal} startIcon={<PublishIcon />}>
        Import lead
      </Button>
      <DragAndDropUpload
        title={'Import a lead to be created'}
        isOpen={isImportModalOpen}
        onClose={onCloseImportModal}
        handleFileUpload={handleFileUpload}
        accept={'application/json'}
      />
    </React.Fragment>
  );
}
