import React, { useCallback } from 'react';

import { SignatureType } from '@breathelife/types';

import { useDispatch, useSelector } from '../../../../Hooks';
import { Application } from '../../../../Models/Application';
import { useGetPointOfSaleDecisionsOutcomeForSingleInsured } from '../../../../ReactQuery/PointOfSaleDecisions/pointOfSaleDecisions.queries';
import { submissionSlice } from '../../../../Redux/Submission/SubmissionSlice';
import { SubmitApplicationModal } from './SubmitApplicationModal';

type SubmitApplicationModalContainerProps = {
  signatureType: SignatureType;
  isOpen: boolean;
  closeModal: () => void;
  application: Application;
};

export function SubmitApplicationModalContainer(props: SubmitApplicationModalContainerProps): React.ReactElement {
  const { signatureType, isOpen, closeModal: closeModalProp, application } = props;
  const dispatch = useDispatch();
  const isSubmitting = useSelector((state) => state.leadPlatform.submission.isSubmittingApplication);
  const isSubmissionFailure = useSelector((state) => state.leadPlatform.submission.isSubmissionFailure);
  const isSubmissionSuccess = useSelector((state) => state.leadPlatform.submission.isSubmissionSuccess);

  const closeModal = useCallback(() => {
    dispatch(submissionSlice.actions.reset());
    closeModalProp();
  }, [dispatch, closeModalProp]);

  const isLoading = isSubmitting;

  const { private: privateData, confirmationNumber } = application;

  const { data: pointOfSaleDecisionOutcome } = useGetPointOfSaleDecisionsOutcomeForSingleInsured(application.id);

  return (
    <SubmitApplicationModal
      signatureType={signatureType}
      isOpen={isOpen}
      closeModal={closeModal}
      isLoading={isLoading}
      confirmationNumber={confirmationNumber}
      cryptoSignature={privateData?.signedDigest}
      isSubmissionFailure={isSubmissionFailure}
      isSubmissionSuccess={isSubmissionSuccess}
      outcome={pointOfSaleDecisionOutcome}
    />
  );
}
