import Grid from '@material-ui/core/Grid';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from '@breathelife/ui-components';

import { getRuleIdentifierValidationError } from '../../../../Helpers/inputValidation/form/salesDecisionRules';
import { useCarrierContext } from '../../../../Hooks';

type Props = {
  value: string;
  onChange: (data: string) => void;
};

export function RuleIdentifierInput({ value, onChange }: Props): React.ReactElement {
  const { features } = useCarrierContext();
  const { t } = useTranslation();
  const [fieldValue, setFieldValue] = useState(value);

  const onBlur = useCallback(() => {
    onChange(fieldValue);
  }, [onChange, fieldValue]);

  const ruleIdentifierError = getRuleIdentifierValidationError(
    fieldValue,
    features.requireSalesDecisionRuleIdentifier?.enabled
  );

  return (
    <Grid item xs={12}>
      <Input
        value={fieldValue}
        onChange={(event) => {
          setFieldValue(event.target.value);
        }}
        onBlur={onBlur}
        label={t('admin.salesDecisionRulesManagement.labels.identifier')}
        inputVariant='outlined'
        validationError={ruleIdentifierError}
      />
    </Grid>
  );
}
