import React from 'react';
import { useTranslation } from 'react-i18next';

import { BlueprintCollectionOperator } from '@breathelife/types';

import { getOptionsFromEnum } from '../../../Helpers/options';
import { QuantifierRadioGroup } from './QuantifierRadioGroup';

type Props = {
  value?: BlueprintCollectionOperator;
  onChange: (value: BlueprintCollectionOperator) => void;
};

export function CollectionAggregateOperatorRadioGroup({ value, onChange }: Props): React.ReactElement {
  const { t } = useTranslation();

  const options = getOptionsFromEnum<BlueprintCollectionOperator>(
    BlueprintCollectionOperator,
    'admin.conditions.options.collectionOperator'
  ).filter((option) => option.value !== BlueprintCollectionOperator.thisItem);

  return (
    <QuantifierRadioGroup
      label={t('admin.conditions.labels.collectionOperator')}
      name='collection-operator-radio-group'
      options={options}
      value={value}
      onChange={onChange}
    />
  );
}
