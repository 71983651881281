import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Language } from '@breathelife/types';

import { useCarrierContext, useDispatch, useSelector } from '../../../../../Hooks';
import { Application } from '../../../../../Models/Application';
import { Lead } from '../../../../../Models/Lead';
import { DEFAULT_COVERAGE_AMOUNT_STEP } from '../../../../../Models/Product';
import { useLaunchAssistedApplicationMutation } from '../../../../../ReactQuery/AssistedApplication/assistedApplication.mutations';
import * as ProductsOperations from '../../../../../Redux/Products/ProductsOperations';
import {
  getQuotedProducts,
  getUniqueProductListWithEligibility,
} from '../../../../../Redux/Products/ProductsSelectors';
import { productsSlice } from '../../../../../Redux/Products/ProductsSlice';
import { CoverageAmountError, ProductsView } from './ProductsView';

type ProductHubViewContainerProps = {
  lead: Lead;
  needsAnalysisTabIndex?: number;
  onSendNeedsAnalysis: () => void;
  onViewNeedsAnalysisDetails: () => void;
  onLaunchAssistedApplication: (application: Application) => void;
  onLaunchRedirectToCarrier: (
    applicationId: string,
    productId: string,
    coverageAmount: number,
    premium: number
  ) => void;
  onToggleIsDeclarationSent: (applicationId: string, isDeclarationSent: boolean) => void;
};

export function ProductHubViewContainer(props: ProductHubViewContainerProps): React.ReactElement {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { coverageAmountStepRounding: coverageAmountStepRoundingFromCarrierContext } = useCarrierContext();
  const { isLoadingQuotes } = useSelector((store) => store.leadPlatform.products);
  const quotedProducts = useSelector(getQuotedProducts);
  const products = useSelector(getUniqueProductListWithEligibility, _.isEqual);

  const [applicationId, setApplicationId] = useState<string>();
  const [coverageAmountError, setCoverageAmountError] = useState<CoverageAmountError>();
  const [coverageAmount, setCoverageAmount] = useState<number>();

  const {
    lead,
    needsAnalysisTabIndex,
    onSendNeedsAnalysis,
    onViewNeedsAnalysisDetails,
    onLaunchRedirectToCarrier,
    onToggleIsDeclarationSent,
    onLaunchAssistedApplication: onLaunchAssistedApplicationProp,
  } = props;

  const coverageAmountStepRounding = coverageAmountStepRoundingFromCarrierContext ?? DEFAULT_COVERAGE_AMOUNT_STEP;
  // TODO: handle multiple applications
  const application = lead.applications?.[0];
  const recommendedCoverageAmount = application?.recommendedCoverageAmount ?? 0;
  const applicationCoverageAmount = application?.coverageAmount;

  const eligibleProducts = useMemo(() => {
    return products.filter((product) => product.isEligible);
  }, [products]);

  const coverageAmountRange = useMemo(() => {
    const min = _.min(_.map(eligibleProducts, 'coverageRange.min'));
    const max = _.max(_.map(eligibleProducts, 'coverageRange.max'));

    if (_.isUndefined(min) || !max) return undefined;

    return { min, max };
  }, [eligibleProducts]);

  const onCoverageAmountChange = useCallback((selectedCoverageAmount: number | undefined) => {
    setCoverageAmount(selectedCoverageAmount);
  }, []);

  const launchAssistedApplicationMutation = useLaunchAssistedApplicationMutation({
    onSuccess: (data) => {
      onLaunchAssistedApplicationProp(data);
    },
  });

  const onLaunchAssistedApplication = useCallback(
    async (application: Application, selectedProductId: string) => {
      if (
        coverageAmount &&
        !coverageAmountError &&
        (coverageAmount !== application.coverageAmount || selectedProductId !== application.product)
      ) {
        launchAssistedApplicationMutation.mutate({
          applicationId: application.id,
          product: selectedProductId,
          coverageAmount,
        });
      } else {
        onLaunchAssistedApplicationProp(application);
      }
    },
    [coverageAmount, coverageAmountError, onLaunchAssistedApplicationProp, launchAssistedApplicationMutation]
  );

  useEffect(() => {
    if (!application) {
      setApplicationId(undefined);
    } else if (application.id !== applicationId) {
      setApplicationId(application.id);
    }
  }, [application, applicationId]);

  // fetch products for the given application
  useEffect(() => {
    if (!applicationId) return;
    void dispatch(ProductsOperations.fetchProducts(applicationId, i18n.language as Language));
  }, [dispatch, applicationId, i18n.language]);

  // set initial coverage amount if it doesn't yet have a value
  useEffect(() => {
    const initialCoverageAmount = applicationCoverageAmount || recommendedCoverageAmount;
    if (!initialCoverageAmount) return;
    setCoverageAmount(initialCoverageAmount);
  }, [applicationCoverageAmount, recommendedCoverageAmount]);

  // handle coverage range validation and quote fetching
  useEffect(() => {
    if (eligibleProducts.length === 0 || !coverageAmountRange) {
      dispatch(productsSlice.actions.resetQuotes);
      setCoverageAmountError(undefined);
      return;
    }

    if (!coverageAmount) {
      dispatch(productsSlice.actions.resetQuotes);
      setCoverageAmountError({ messageKey: 'validation.error.coverageLimitMin', amount: coverageAmountRange.min });
      return;
    }

    if (
      coverageAmount !== coverageAmountRange.min &&
      coverageAmount !== coverageAmountRange.max &&
      coverageAmount % coverageAmountStepRounding > 0
    ) {
      dispatch(productsSlice.actions.resetQuotes);
      setCoverageAmountError({ messageKey: 'product.roundedAmount', amount: coverageAmountStepRounding });
      return;
    }

    if (coverageAmount > coverageAmountRange.max) {
      dispatch(productsSlice.actions.resetQuotes);
      setCoverageAmountError({ messageKey: 'validation.error.coverageLimitMax', amount: coverageAmountRange.max });
    } else if (coverageAmount < coverageAmountRange.min) {
      dispatch(productsSlice.actions.resetQuotes);
      setCoverageAmountError({ messageKey: 'validation.error.coverageLimitMin', amount: coverageAmountRange.min });
    } else {
      setCoverageAmountError(undefined);
      if (!applicationId) return;
      void dispatch(ProductsOperations.fetchQuotes(applicationId, coverageAmount));
    }
  }, [dispatch, applicationId, eligibleProducts, coverageAmount, coverageAmountRange, coverageAmountStepRounding]);

  const hasCustomCoverageAmount = !!coverageAmount && coverageAmount !== recommendedCoverageAmount;
  // Custom coverage amount is enabled only if the application has been started and has at least one eligible product, regardless of the recommended coverage amount
  const isCoverageAmountInputEnabled = eligibleProducts.length > 0;

  return (
    <ProductsView
      applications={lead.applications}
      coverageAmount={coverageAmount}
      coverageAmountError={coverageAmountError}
      hasCustomCoverageAmount={hasCustomCoverageAmount}
      isCoverageAmountEditable
      isCoverageAmountInputEnabled={isCoverageAmountInputEnabled}
      isLoadingQuotes={isLoadingQuotes}
      isNeedsAnalysisTabEnabled={!!needsAnalysisTabIndex}
      lead={lead}
      onCoverageAmountChange={onCoverageAmountChange}
      onLaunchAssistedApplication={onLaunchAssistedApplication}
      onLaunchCoBrowsing={() => {}}
      onLaunchRedirectToCarrier={onLaunchRedirectToCarrier}
      onSendNeedsAnalysis={onSendNeedsAnalysis}
      onToggleIsDeclarationSent={onToggleIsDeclarationSent}
      onViewNeedsAnalysisDetails={onViewNeedsAnalysisDetails}
      products={quotedProducts}
      recommendedCoverageAmount={recommendedCoverageAmount}
      coverageAmountStep={coverageAmountStepRounding}
    />
  );
}
