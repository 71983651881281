import Box from '@material-ui/core/Box';
import styled, { css } from 'styled-components';

import { emptyTableHeight } from '../../../../Models/Layout';

const TABLE_HEIGHT = css`
  height: ${() => `calc(100vh - 198px)`};
`;

export const MaterialTableContainer = styled.div`
  &&&&& {
    > div:first-child {
      box-shadow: none;
      > div > div > div {
        ${TABLE_HEIGHT}
      }
    }
  }
`;

export const EmptySearchResultContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: ${() => `calc(100vh - ${emptyTableHeight}px)`};
  min-height: 300px;
`;
