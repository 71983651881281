import React, { useCallback } from 'react';

import { CreateRequest, InsuranceFirm } from '@breathelife/types';

import { useCreateFirmMutation } from '../../../../ReactQuery/Admin/Firm/firm.mutations';
import { FirmEditorDrawer } from './FirmEditorDrawer';
import { FirmFormFields } from './types';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export function NewFirmDrawerContainer(props: Props): React.ReactElement | null {
  const { isOpen, onClose } = props;

  const createFile = useCreateFirmMutation({
    onSuccess: onClose,
  });

  const onCreate = useCallback(
    async (formField: FirmFormFields) => {
      const data = formField as CreateRequest<InsuranceFirm>;
      await createFile.mutateAsync(data);
    },
    [createFile]
  );

  return <FirmEditorDrawer isOpen={isOpen} onSave={onCreate} onClose={onClose} />;
}
