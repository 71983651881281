import React from 'react';
import styled from 'styled-components';

import { ProfilePictureUploadAvatar } from '../../Assets/ProfilePictureUploadAvatar';
import { Image } from '../../Components/Images/Image';

const DEFAULT_SIZE = 56;

type ProfilePictureButtonProps = {
  onClick: () => void;
  picture?: string;
  width?: number;
  height?: number;
  disabled?: boolean;
};
const StyledButton = styled.button`
  background-color: ${(props) => props.theme.colors.grey[0]};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  outline: 0;
  line-height: 0;
  padding: 0;
  border: 1px solid ${(props) => props.theme.colors.grey[0]};

  :focus:not(:active) {
    outline: 0;
    border-color: ${(props) => props.theme.colors.tertiary.default};
  }
`;

export function ProfilePictureButton(props: ProfilePictureButtonProps): React.ReactElement {
  const { onClick, picture, width, height, disabled } = props;
  return (
    <StyledButton disabled={disabled} onClick={onClick}>
      {!!picture && <Image width={width ?? DEFAULT_SIZE} height={height ?? DEFAULT_SIZE} src={picture} />}
      {!picture && <ProfilePictureUploadAvatar width={width ?? DEFAULT_SIZE} height={height ?? DEFAULT_SIZE} />}
    </StyledButton>
  );
}
