import { Divider } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import React from 'react';

import Typography from '../../Components/Typography';
import { MenuItem } from './Styles';

export type MenuAction = {
  tag: 'MenuAction';
  onClick: () => void;
  icon: React.ReactElement;
  label: string;
  disabled?: boolean;
  linkProperties?: Record<string, any>;
  ['data-testid']?: string;
};

export type MenuDivider = {
  tag: 'MenuDivider';
};

export type MenuElement = MenuAction | MenuDivider;

type Props = MenuProps & { items: MenuElement[] };

export function PopupMenu(props: Props): React.ReactElement {
  const { items, ...restProps } = props;

  return (
    <Menu {...restProps}>
      {items.map((item: MenuElement, index: number) => {
        switch (item.tag) {
          case 'MenuDivider':
            return <Divider key={`MenuDivider-${index}`} />;
          case 'MenuAction':
            const component = item.linkProperties ? { component: Link } : {};
            return (
              <MenuItem
                key={`MenuItem-${index}`}
                onClick={item.onClick}
                disabled={item.disabled}
                data-testid={item['data-testid']}
                {...component}
                {...item.linkProperties}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <Typography variant='body3' grey={90}>
                  {item.label}
                </Typography>
              </MenuItem>
            );
        }
      })}
    </Menu>
  );
}
