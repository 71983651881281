import Grid from '@material-ui/core/Grid';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DateComparisonConditionValue } from '@breathelife/types';

import { NumberInput } from '../../../Components/NumberInput/NumberInput';
import { DateUnitSelect } from '../../../Components/SelectInputs/DateUnitSelect';
import {
  getConditionValidationError,
  ValidationValues,
} from '../../../Helpers/inputValidation/form/salesDecisionRules';
import { CollectionOperators } from '../Helpers/CollectionOperators';
import { ConditionTargetNodeAutocomplete } from '../Helpers/ConditionTargetNodeAutocomplete';
import { NumberComparisonConditionOperatorSelect } from '../Helpers/NumberComparisonConditionOperatorSelect';
import { ValidationErrorText } from '../ValidationErrorText';
import { ConditionCriteriaProps } from '../types';

export function DateComparisonConditionCriteria({
  condition,
  nodeIdList,
  nodeIdInCollectionMap,
  collectionContext,
  onConditionPropertiesChange,
  language,
  conditionIndex,
}: ConditionCriteriaProps<DateComparisonConditionValue>): React.ReactElement {
  const { t } = useTranslation();
  const validationErrors = getConditionValidationError(condition, nodeIdList, nodeIdInCollectionMap);

  const [comparisonValue, setComparisonValue] = useState<number | undefined>(condition.value);

  const onBlur = useCallback(() => {
    onConditionPropertiesChange({ value: comparisonValue });
  }, [onConditionPropertiesChange, comparisonValue]);

  const collectionNodeIds = nodeIdInCollectionMap[condition.startDateNodeId ?? condition.endDateNodeId] ?? [];

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <ConditionTargetNodeAutocomplete
          label={t('admin.conditions.labels.startDate')}
          value={condition.startDateNodeId}
          nodeIdList={nodeIdList}
          onSelect={(startDateNodeId) => {
            if (startDateNodeId !== null) {
              onConditionPropertiesChange({ startDateNodeId });
            }
          }}
          validationError={validationErrors?.[ValidationValues.startDateNodeId]}
          selectedLanguage={language}
        />
      </Grid>
      <Grid item xs={12}>
        <ConditionTargetNodeAutocomplete
          label={t('admin.conditions.labels.endDate')}
          value={condition.endDateNodeId}
          nodeIdList={nodeIdList}
          onSelect={(endDateNodeId) => {
            if (endDateNodeId !== null) {
              onConditionPropertiesChange({ endDateNodeId });
            }
          }}
          validationError={validationErrors?.[ValidationValues.endDateNodeId]}
          selectedLanguage={language}
        />
      </Grid>
      <Grid item xs={12}>
        <NumberComparisonConditionOperatorSelect
          value={condition.operator}
          onChange={(operator) => {
            onConditionPropertiesChange({ operator });
          }}
        />
        {validationErrors?.[ValidationValues.operator] && (
          <ValidationErrorText>{validationErrors[ValidationValues.operator].message}</ValidationErrorText>
        )}
      </Grid>
      <Grid item xs={12}>
        <NumberInput
          inputVariant='outlined'
          value={comparisonValue}
          onNumberChange={setComparisonValue}
          label={t('admin.conditions.labels.controlValue')}
          onBlur={onBlur}
        />
        {validationErrors?.[ValidationValues.value] && (
          <ValidationErrorText>{validationErrors[ValidationValues.value].message}</ValidationErrorText>
        )}
      </Grid>
      <Grid item xs={12}>
        <DateUnitSelect
          value={condition.unit}
          onChange={(unit) => {
            onConditionPropertiesChange({ unit });
          }}
        />
        {validationErrors?.[ValidationValues.unit] && (
          <ValidationErrorText>{validationErrors[ValidationValues.unit].message}</ValidationErrorText>
        )}
      </Grid>
      <CollectionOperators
        collectionContext={collectionContext}
        collectionNodeIds={collectionNodeIds}
        collectionOperators={condition.collectionOperators}
        onConditionPropertiesChange={onConditionPropertiesChange}
        conditionIndex={conditionIndex}
      />
    </React.Fragment>
  );
}
