import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

export const StyledGrid = styled(Grid)`
  &&& {
    .MuiFormControlLabel-root {
      background-color: ${(props) => props.theme.colors.grey[20]};
      border-radius: 4px;
      margin: unset;
      padding-right: 8px;
      padding-top: 4px;
      padding-bottom: 4px;
      width: 100%;
    }
  }
`;
