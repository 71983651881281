export enum QueryId {
  storedFiles = 'storedFiles',
  fileTemplateRules = 'fileTemplateRules',
  fileTemplates = 'fileTemplates',
  fileTemplate = 'fileTemplate',
  questionnaireVersions = 'questionnaireVersions',
  allQuestionnaireVersions = 'allQuestionnaireVersions',
  questionnaireVersion = 'questionnaireVersion',
  questionnaireNodeIds = 'questionnaireNodeIds',
  questionnaire = 'questionnaire',
  questionnaireBuilder = 'questionnaireBuilder',
  eSignCeremony = 'eSignCeremony',
  eSignFieldSettings = 'eSignFieldSettings',
  eSignFieldSetting = 'eSignFieldSetting',

  fileTemplateRecipients = 'fileTemplateRecipients',
  fileTemplateRecipient = 'fileTemplateRecipient',

  application = 'application',
  agentLicenses = 'agentLicenses',
  firms = 'firms',
  firm = 'firm',
  leadEmailValid = 'leadEmailValid',
  lead = 'lead',
  leads = 'leads',
  entityMappings = 'entityMappings',
  participantProcessor = 'participantProcessor',
  thirdPartyIntegrations = 'thirdPartyIntegrations',
  participantRoles = 'participantRoles',
  pointOfSaleDecision = 'pointOfSaleDecision',
  backOfficeApplications = 'backOfficeApplications',
  productsWidgetTotalPremiums = 'productsWidgetTotalPremiums',
  quotes = 'quotes',
  jetDecisionOutcomes = 'jetDecisionOutcomes',
}
