import Box from '@material-ui/core/Box';
import React from 'react';
import styled from 'styled-components';

type ActionsContainerProps = {
  children: React.ReactNode;
};

const ActionsContent = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  > *:not(:first-child) {
    margin-left: 10px;
  }
`;

export function ActionsContainer(props: ActionsContainerProps): React.ReactElement {
  const { children } = props;
  return <ActionsContent>{children}</ActionsContent>;
}
