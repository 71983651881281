import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  toCustomField,
  toCustomFieldGroup,
  toCustomSection,
  toCustomSubsection,
} from '@breathelife/questionnaire-engine';
import {
  FieldBlueprint,
  QuestionBlueprint,
  QuestionPartIdentifier,
  SectionBlueprint,
  SectionGroupKey,
  SectionPartIdentifier,
  SubsectionBlueprint,
  SubsectionPartIdentifier,
} from '@breathelife/types';

import { useDispatch } from '../../../../../Hooks';
import { useAddQuestionnaireElementBlueprint } from '../../../../../ReactQuery/Admin/Questionnaire/questionnaireVersion.mutations';
import { notificationSlice } from '../../../../../Redux/Notification/NotificationSlice';
import { QuestionnaireVersionDataContext } from '../../QuestionnaireVersionDataContextProvider';

type State = {
  copiedSection: SectionBlueprint | null;
  copiedSubsection: SubsectionBlueprint | null;
  copiedFieldGroup: QuestionBlueprint | null;
  copiedField: FieldBlueprint | null;
};

const defaultState: State = {
  copiedSection: null,
  copiedSubsection: null,
  copiedFieldGroup: null,
  copiedField: null,
};

type ContextProps = {
  state: State;
  setState: (newState: State) => void;
};

const QuestionnaireClipboardContext = React.createContext<ContextProps | null>(null);

interface Props {
  children: React.ReactElement[];
}

export function QuestionnaireClipboardProvider(props: Props): React.ReactElement {
  const [state, setState] = useState<State>(defaultState);
  return (
    <QuestionnaireClipboardContext.Provider
      value={{
        state: state,
        setState: (state: State) => setState(state),
      }}
    >
      {props.children}
    </QuestionnaireClipboardContext.Provider>
  );
}

interface UseQuestionnaireClipboardReturn {
  actions: {
    copySection: (blueprint: SectionBlueprint) => void;
    copySubsection: (blueprint: SubsectionBlueprint) => void;
    copyFieldGroup: (blueprint: QuestionBlueprint) => void;
    copyField: (blueprint: FieldBlueprint) => void;
    pasteSection: (sectionGroupKey: SectionGroupKey, blueprint: SectionBlueprint) => void;
    pasteSubsection: (partIdentifier: SectionPartIdentifier, blueprint: SubsectionBlueprint) => Promise<void>;
    pasteFieldGroup: (partIdentifier: SubsectionPartIdentifier, blueprint: QuestionBlueprint) => Promise<void>;
    pasteField: (partIdentifier: QuestionPartIdentifier, blueprint: FieldBlueprint) => Promise<void>;
  };
  selectors: {
    copiedSection: SectionBlueprint | null;
    copiedSubsection: SubsectionBlueprint | null;
    copiedFieldGroup: QuestionBlueprint | null;
    copiedField: FieldBlueprint | null;
  };
}

export const useQuestionnaireClipboard = (): UseQuestionnaireClipboardReturn => {
  const context = useContext(QuestionnaireClipboardContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { questionnaireVersionId } = useContext(QuestionnaireVersionDataContext);

  const addBlueprint = useAddQuestionnaireElementBlueprint(questionnaireVersionId);

  if (context === null) {
    throw new Error(`useQuestionnaireClipboard is missing a corresponding provider up in the react tree.`);
  }

  const { state, setState } = context;

  return {
    actions: {
      copySection: useCallback(
        (blueprint: SectionBlueprint) => {
          setState({ ...state, copiedSection: blueprint });
          dispatch(notificationSlice.actions.setSuccess({ message: t('notifications.sectionCopiedToClipboard') }));
        },
        [dispatch, setState, state, t]
      ),
      copySubsection: useCallback(
        (blueprint: SubsectionBlueprint) => {
          setState({ ...state, copiedSubsection: blueprint });
          dispatch(notificationSlice.actions.setSuccess({ message: t('notifications.subsectionCopiedToClipboard') }));
        },
        [dispatch, setState, state, t]
      ),
      copyFieldGroup: useCallback(
        (blueprint: QuestionBlueprint) => {
          setState({ ...state, copiedFieldGroup: blueprint });
          dispatch(notificationSlice.actions.setSuccess({ message: t('notifications.fieldGroupCopiedToClipboard') }));
        },
        [dispatch, setState, state, t]
      ),
      copyField: useCallback(
        (blueprint: FieldBlueprint) => {
          setState({ ...state, copiedField: blueprint });
          dispatch(notificationSlice.actions.setSuccess({ message: t('notifications.fieldCopiedToClipboard') }));
        },
        [dispatch, setState, state, t]
      ),
      pasteSection: useCallback(
        async (sectionGroupKey: SectionGroupKey, blueprint: SectionBlueprint) => {
          const blueprintWithUpdatedSectionGroupKey = { ...blueprint, sectionGroupKey };
          void addBlueprint(toCustomSection(blueprintWithUpdatedSectionGroupKey), null);
        },
        [addBlueprint]
      ),
      pasteSubsection: useCallback(
        async (partIdentifier: SectionPartIdentifier, blueprint: SubsectionBlueprint) => {
          void addBlueprint(toCustomSubsection(blueprint), partIdentifier);
        },
        [addBlueprint]
      ),
      pasteFieldGroup: useCallback(
        async (partIdentifier: SubsectionPartIdentifier, blueprint: QuestionBlueprint) => {
          void addBlueprint(toCustomFieldGroup(blueprint), partIdentifier);
        },
        [addBlueprint]
      ),
      pasteField: useCallback(
        async (partIdentifier: QuestionPartIdentifier, blueprint: FieldBlueprint) => {
          void addBlueprint(toCustomField(blueprint), partIdentifier);
        },
        [addBlueprint]
      ),
    },
    selectors: {
      copiedSection: state.copiedSection,
      copiedSubsection: state.copiedSubsection,
      copiedFieldGroup: state.copiedFieldGroup,
      copiedField: state.copiedField,
    },
  };
};
