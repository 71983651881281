import Grid from '@material-ui/core/Grid';
import React from 'react';

import { Language } from '@breathelife/types';

import { FirmFormFields, ValueChangeFunction } from '../types';
import { FirmFields } from './FirmFields';
import { FirmLogo } from './FirmLogo';

type Props = {
  onFirmFieldValueChange: ValueChangeFunction;
  enabledLanguages: Language[];
  insuranceFirmFormState: FirmFormFields;
};

export function FirmForm({
  insuranceFirmFormState,
  onFirmFieldValueChange,
  enabledLanguages,
}: Props): React.ReactElement {
  return (
    <Grid container spacing={2}>
      <FirmFields
        insuranceFirmFormState={insuranceFirmFormState}
        onInputValueChange={onFirmFieldValueChange}
        enabledLanguages={enabledLanguages}
      />
      {enabledLanguages.map((language) => (
        <FirmLogo
          key={`productManagement-logoUrl-${language}`}
          onInputValueChange={onFirmFieldValueChange}
          language={language}
          logoUrl={insuranceFirmFormState?.logoUrl?.[language]}
        />
      ))}
    </Grid>
  );
}
