import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React, { useCallback } from 'react';

import { NodeIds } from '@breathelife/insurance-form-builder';
import { BlueprintConditionValue, BooleanOperator, ConsiderationBlueprint } from '@breathelife/types';

import { ConditionsEditor } from '../../../../Components/Conditions';
import { QuestionnaireNodeIds } from '../../../../Helpers/questionnaireEditor/questionnaireNodeIds';
import { useLocale } from '../../../../Hooks';
import { useQuestionnaireVersionDetailWithNodeIdInfo } from '../../../../Pages/Admin/Questionnaire/QuestionnaireVersionDataContextProvider';
import { OutcomeSelect } from './OutcomeSelect';
import { ReasonInput } from './ReasonInput';
import { RuleIdentifierInput } from './RuleIdentifierInput';

type Props = {
  formState: ConsiderationBlueprint & { identifier: string };
  onFormUpdate: (data: Partial<ConsiderationBlueprint>) => void;
  onConditionUpdate: (data: BlueprintConditionValue, path: string) => void;
  onRuleIdentifierUpdate: (data: string) => void;
  onBooleanOperatorChange: (booleanOperator: BooleanOperator, path: string) => void;
  onAddCondition: (path: string) => void;
  onAddNestedCondition: (path: string) => void;
  onRemoveCondition: (path: string) => void;
  questionnaireNodeIds: QuestionnaireNodeIds;
};

export function SalesDecisionRuleEditor({
  formState,
  onFormUpdate,
  questionnaireNodeIds,
  onConditionUpdate,
  onBooleanOperatorChange,
  onAddCondition,
  onAddNestedCondition,
  onRemoveCondition,
  onRuleIdentifierUpdate,
}: Props): React.ReactElement | null {
  const language = useLocale();
  const { nodeIdInCollectionMap } = useQuestionnaireVersionDetailWithNodeIdInfo() || {};

  const onConditionChange = useCallback(
    (updatedCondition: BlueprintConditionValue, path: string) => {
      onConditionUpdate(updatedCondition, path);
    },
    [onConditionUpdate]
  );

  if (!nodeIdInCollectionMap) {
    return null;
  }

  return (
    <Box p={3} mb='auto'>
      <Grid container spacing={2}>
        <ConditionsEditor
          questionnaireNodeIds={questionnaireNodeIds}
          collectionContext={[NodeIds.insuredPeople]}
          condition={formState.conditions}
          onConditionChange={onConditionChange}
          onBooleanOperatorChange={onBooleanOperatorChange}
          onAddCondition={onAddCondition}
          onAddNestedCondition={onAddNestedCondition}
          onRemoveCondition={onRemoveCondition}
          language={language}
          nodeIdInCollectionMap={nodeIdInCollectionMap}
        />
        <OutcomeSelect value={formState.outcomeCode} onChange={onFormUpdate} />
        <ReasonInput value={formState.reason} onChange={onFormUpdate} />
        <RuleIdentifierInput value={formState.identifier} onChange={onRuleIdentifierUpdate} />
      </Grid>
    </Box>
  );
}
