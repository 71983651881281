import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { PartIdentifierTag } from '@breathelife/types';
import { Box } from '@breathelife/ui-components';

import { Button } from '../../../../Components/Button/Button';
import { IconButton } from '../../../../Components/Controls';
import { ExportButton } from '../../../../Components/ExportData/ExportButton';
import { ExportDataProvider } from '../../../../Components/ExportData/ExportDataProvider';
import { ImportButton } from '../../../../Components/ExportData/ImportButton';
import { ImportDataProvider } from '../../../../Components/ExportData/ImportDataProvider';
import { ImportModal } from '../../../../Components/ExportData/ImportModal';
import { Icon } from '../../../../Components/Icons';
import Typography from '../../../../Components/Typography';
import { useCarrierContext } from '../../../../Hooks';
import Urls from '../../../../Navigation/Urls';
import {
  useNewDraftQuestionnaireVersionMutation,
  usePublishQuestionnaireVersionMutation,
} from '../../../../ReactQuery/Admin/Questionnaire/questionnaireVersion.mutations';
import { QuestionnaireEditorContext } from '../QuestionnaireEditorContextProvider';
import { QuestionnaireVersionDataContext } from '../QuestionnaireVersionDataContextProvider';
import { LanguageToggle } from './Components/LanguageToggle';
import { PreviewConfigurationModal } from './Components/PreviewConfigurationModal';
import { CreateDraftOrPublish } from './CreateDraftOrPublish';

export function QuestionnaireEditorHeader(): React.ReactElement {
  const { t } = useTranslation();

  const history = useHistory();
  const { features, languageSettings } = useCarrierContext();
  const { enabledLanguages } = languageSettings;
  const { selectedLanguage, onSelectedLanguageChange, isMutating, onSelectedPartIdentifierChange } =
    useContext(QuestionnaireEditorContext);
  const { questionnaireVersionData } = useContext(QuestionnaireVersionDataContext);
  const { majorVersion, minorVersion, description, isDraft, isLatestVersion } = questionnaireVersionData || {};

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  const onExitQuestionnaireEditor = useCallback(() => {
    history.push(Urls.questionnaireManagement);
  }, [history]);

  const publishQuestionnaireVersionMutation = usePublishQuestionnaireVersionMutation();

  const publishQuestionnaireVersion = useCallback(
    (description: string) => {
      publishQuestionnaireVersionMutation.mutate({ description });
    },
    [publishQuestionnaireVersionMutation]
  );

  const newDraftQuestionnaireVersionMutation = useNewDraftQuestionnaireVersionMutation({
    onSuccess: ({ questionnaireVersionId }) => {
      history.push(`${Urls.questionnaireManagement}/${questionnaireVersionId}`);
    },
  });

  const newDraftQuestionnaireVersion = useCallback(
    (description: string) => {
      newDraftQuestionnaireVersionMutation.mutate({ description });
    },
    [newDraftQuestionnaireVersionMutation]
  );

  useEffect(() => {
    if (!questionnaireVersionData?.blueprint) {
      return;
    }
    onSelectedPartIdentifierChange({
      tag: PartIdentifierTag.section,
      sectionGroupPartName: 'insuredPeople',
      sectionPartName: questionnaireVersionData.blueprint.sectionBlueprints[0]?.partName ?? '',
    });
    // Disabling dependency array error as this effect should only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Box display='flex' justifyContent='space-between' m={2}>
        <Box flex='1' display='flex'>
          <IconButton
            onClick={onExitQuestionnaireEditor}
            icon={<Icon name='leftArrow' />}
            title={t('admin.questionnaireManagement.return')}
          />
          <Box ml={2}>
            <Typography variant='h1'>{t('admin.questionnaireManagement.title')}</Typography>
            <Typography variant='body1'>
              {t('admin.questionnaireManagement.version', {
                majorVersion,
                minorVersion,
                description: description ? description : t('admin.questionnaireManagement.emptyDescription'),
              })}
            </Typography>
          </Box>
        </Box>
        <Box alignSelf='flex-end'>
          <LanguageToggle
            onLanguageChange={onSelectedLanguageChange}
            enabledLanguages={enabledLanguages}
            selectedLanguage={selectedLanguage ?? languageSettings.default}
          />
        </Box>
        <Box display='flex' alignItems='center' flex='1' justifyContent='flex-end'>
          <Box width='5rem'>
            {isMutating && <Typography variant='small1'>{t('admin.questionnaireManagement.autoSaving')}</Typography>}
          </Box>
          <React.Fragment>
            <Box mr={1}>
              <ExportDataProvider blueprint salesDecisionRules>
                <ExportButton />
              </ExportDataProvider>
            </Box>
            {isDraft && (
              <ImportDataProvider blueprint salesDecisionRules>
                <Box mr={2}>
                  <ImportButton />
                </Box>
                <ImportModal title={t('admin.importData.blueprintsAndSalesDecisionRules')} />
              </ImportDataProvider>
            )}
          </React.Fragment>
          <React.Fragment>
            {features.questionnairePreview.enabled && (
              <Box mr={1}>
                <Button
                  color='inherit'
                  variant='contained'
                  onClick={() => setIsPreviewModalOpen(true)}
                  disabled={false}
                >
                  {t('admin.questionnaireManagement.preview')}
                </Button>
              </Box>
            )}
            {isPreviewModalOpen && <PreviewConfigurationModal isOpen onClose={() => setIsPreviewModalOpen(false)} />}
          </React.Fragment>
          <Box>
            <CreateDraftOrPublish
              onPublishClick={async (newDescription: string) => await publishQuestionnaireVersion(newDescription)}
              onCreateDraftClick={async (newDescription: string) => await newDraftQuestionnaireVersion(newDescription)}
              disabled={isMutating}
              isDraft={Boolean(isDraft)}
              description={description ?? ''}
              isLatestVersion={Boolean(isLatestVersion)}
            />
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
