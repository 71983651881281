import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

export function useResize<T extends HTMLElement = HTMLDivElement>({
  minSize,
  isHeight,
}: {
  minSize: number;
  isHeight?: boolean;
}): {
  ref: RefObject<T>;
  size: number;
  isResizing: boolean;
  enableResize: () => void;
} {
  const [isResizing, setIsResizing] = useState(false);
  const [size, setSize] = useState(minSize);
  const ref = useRef<T>(null);

  const enableResize = useCallback(() => {
    setIsResizing(true);
  }, [setIsResizing]);

  const disableResize = useCallback(() => {
    setIsResizing(false);
  }, [setIsResizing]);

  const resize = useCallback(
    (event: MouseEvent) => {
      if (isResizing && ref.current) {
        if (!isHeight) {
          const offsetLeft = ref.current.getBoundingClientRect().left;
          const newWidth = event.clientX - offsetLeft;
          setSize(newWidth);
        } else {
          const offsetTop = ref.current.getBoundingClientRect().top;
          const newHeight = event.clientY - offsetTop;
          setSize(newHeight);
        }
      }
    },
    [isHeight, isResizing]
  );

  useEffect(() => {
    document.addEventListener('mousemove', resize);
    document.addEventListener('mouseup', disableResize);

    return () => {
      document.removeEventListener('mousemove', resize);
      document.removeEventListener('mouseup', disableResize);
    };
  }, [disableResize, resize]);

  return { ref, size, isResizing, enableResize };
}
