import { Box, CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Copy from 'copy-to-clipboard';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonName, TypewriterTracking } from '@breathelife/react-tracking';
import { InsuranceScopes, Permission } from '@breathelife/types';

import { Alert } from '../../../Components/Alert/Alert';
import { InsuranceScopeBadge } from '../../../Components/Badge/InsuranceScopeBadge';
import { Button } from '../../../Components/Button/Button';
import { SubmitButton } from '../../../Components/Button/SubmitButton';
import { Icon } from '../../../Components/Icons';
import RadioGroup, { Radio } from '../../../Components/RadioGroup/RadioGroup';
import { RestrictedToPermission } from '../../../Components/Restricted/RestrictedToPermission';
import Typography from '../../../Components/Typography';
import { useCarrierContext, useDispatch, useSelector } from '../../../Hooks';
import { ModalLayout } from '../../../Layouts/Modal/ModalLayout';
import { Lead } from '../../../Models/Lead';
import {
  useGeneratePrivateLinkTokenMutation,
  useSendInvitationEmailToLeadMutation,
} from '../../../ReactQuery/Lead/lead.mutations';
import { useValidateLeadEmailQuery } from '../../../ReactQuery/Lead/lead.queries';
import { notificationSlice } from '../../../Redux/Notification/NotificationSlice';
import { LeadEmail, LeadName, SendEmailInsuranceScopesContainer } from './Styles';

// @ts-ignore
const IS_TESTCAFE_RUN = !!window.TESTCAFE_RUN;

export type Props = {
  isOpen: boolean;
  closeModal: () => void;
  lead: Lead;
};

export function SendEmailModal(props: Props): React.ReactElement | null {
  const { isOpen, closeModal, lead } = props;
  const { t } = useTranslation();
  const { features, insuranceScopes: carrierInsuranceScopes } = useCarrierContext();
  const dispatch = useDispatch();

  const user = useSelector((store) => store.leadPlatform.authentication.user);
  const [insuranceScope, setInsuranceScope] = useState<InsuranceScopes>();

  useEffect(() => {
    if (!user || !user.defaultInsuranceScopes) return;
    // TODO: handle potentially having multiple insurance scopes
    setInsuranceScope(user.defaultInsuranceScopes[0]);
  }, [user]);

  const { data: emailValidation, isLoading: isValidatingEmail } = useValidateLeadEmailQuery(lead.id, {
    refetchOnMount: 'always',
  });

  const copyLink = useCallback(
    (link: string) => {
      Copy(`${link}&method=privateLink`);
      dispatch(
        notificationSlice.actions.setSuccess({ message: t('notifications.linkCopied'), autoHideDuration: 3000 })
      );
    },
    [dispatch, t]
  );

  const {
    data: invitationLink,
    isLoading: isGeneratingInvitationLink,
    mutate: generatePrivateLinkToken,
  } = useGeneratePrivateLinkTokenMutation({
    onSuccess: ({ link }) => {
      copyLink(link);
    },
  });

  const onClickCopyToClipboard = useCallback(() => {
    if (isGeneratingInvitationLink) return;

    if (!insuranceScope) {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToCopyPrivateLinkMissingInsuranceScope'),
          autoHideDuration: 3000,
        })
      );
      return;
    }

    TypewriterTracking.clickedButton({
      buttonName: ButtonName.generateLink,
      hashedId: null,
    });

    // TODO: change once a lead can have multiple applications
    const insuranceScopes = [insuranceScope];

    // TODO: check if token exists for app and if so, use that one
    generatePrivateLinkToken({ leadId: lead.id, insuranceScopes });
  }, [dispatch, t, lead, insuranceScope, isGeneratingInvitationLink, generatePrivateLinkToken]);

  const { isLoading: isSendingEmail, mutate: sendInvitationEmail } = useSendInvitationEmailToLeadMutation({
    onSuccess: closeModal,
  });

  const onSendEmail = useCallback(() => {
    if (!insuranceScope) {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToSendInvitaitonMissingInsuranceScope'),
          autoHideDuration: 3000,
        })
      );
      return;
    }

    sendInvitationEmail({ leadId: lead.id, insuranceScopes: [insuranceScope] });
  }, [t, insuranceScope, dispatch, lead, sendInvitationEmail]);

  const insuranceScopesRadioChoices = _.map(carrierInsuranceScopes, (scope) => ({
    value: scope,
    label: t(`insuranceScope.${scope}`),
  }));

  return (
    <ModalLayout
      maxWidth='sm'
      isOpen={isOpen}
      closeModal={closeModal}
      title={t('cta.sendNeedsAnalysis')}
      submitButton={
        <RestrictedToPermission permission={Permission.LeadInviteEmail}>
          <SubmitButton
            isLoading={isSendingEmail || isValidatingEmail}
            disabled={isSendingEmail || isValidatingEmail}
            onClick={onSendEmail}
          >
            {t('cta.send')}
          </SubmitButton>
        </RestrictedToPermission>
      }
    >
      {isValidatingEmail && (
        <Box display='flex' justifyContent='center' py={10}>
          <CircularProgress size={90} />
        </Box>
      )}

      {!isValidatingEmail && (
        <React.Fragment>
          {features.changeInsuranceScopesForInvitation.enabled && (
            <SendEmailInsuranceScopesContainer pb={4} mb={3}>
              <Box mb={1}>
                <Typography variant='h3' grey={90}>
                  {t('modals.sendEmail.topics')}
                </Typography>
              </Box>
              <RadioGroup<InsuranceScopes>
                label={t('modals.sendEmail.selectNeedsAnalysis')}
                name='sendEmailModal-insuranceScopes'
                onChange={setInsuranceScope}
                value={insuranceScope}
              >
                <Grid container spacing={1}>
                  {_.map(insuranceScopesRadioChoices, (choice) => {
                    return (
                      <Grid item xs={12} sm={4} key={choice.value}>
                        <Radio<InsuranceScopes> value={choice.value} label={choice.label} />
                      </Grid>
                    );
                  })}
                </Grid>
              </RadioGroup>
            </SendEmailInsuranceScopesContainer>
          )}

          <RestrictedToPermission permission={Permission.LeadInviteEmail}>
            <Typography variant='body1' grey={70}>
              {t('modals.sendEmail.willBeSentTo')}
            </Typography>
          </RestrictedToPermission>
          <Box mt={1}>
            <LeadName variant='h3' component='span' grey={90}>
              {lead.firstName} {lead.lastName}
            </LeadName>

            <LeadEmail variant='body1' grey={90}>{`<${lead.email}>`}</LeadEmail>
          </Box>
          {emailValidation?.verdict && (
            <Box mt={2}>
              <Alert severity='warning'>
                {emailValidation.verdict === 'Invalid'
                  ? t('modals.sendEmail.invalidEmail')
                  : t('modals.sendEmail.confirmSend')}
              </Alert>
            </Box>
          )}
          {!features.changeInsuranceScopesForInvitation.enabled && (
            <Box my={3}>
              <Typography variant='body1' grey={70}>
                {t('modals.sendEmail.insuranceScopesSendingMessage')}
              </Typography>
              <Box mt={1} display='flex'>
                {_.map(user?.defaultInsuranceScopes, (scope: InsuranceScopes) => (
                  <InsuranceScopeBadge
                    key={`insuranceScopeBadge-sendEmailModal-${scope}`}
                    label={t(`insuranceScope.${scope}`)}
                  />
                ))}
              </Box>
            </Box>
          )}
          <RestrictedToPermission permission={[Permission.LeadInvitePrivate]}>
            <Box mt={3}>
              <Button
                data-testid='sendEmailModal-copyToClipboard'
                variant='outlined'
                startIcon={<Icon name='copy' />}
                onClick={onClickCopyToClipboard}
              >
                <Typography variant='body1'>{t('modals.sendEmail.copyLink')}</Typography>
              </Button>
            </Box>
          </RestrictedToPermission>

          {/* TODO: remove this once the copy button is refactored to generate button */}
          {IS_TESTCAFE_RUN && invitationLink?.link && (
            <React.Fragment>
              <div style={{ backgroundColor: '#fefe' }}>
                <p style={{ color: 'red' }}>DEV ONLY: </p>
                <p data-testid='sendEmailModal-privateLink'>{`${invitationLink.link}&method=privateLink`}</p>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </ModalLayout>
  );
}
