import { Box } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { getDataLabelOptions } from '../../../../Helpers/questionnaireEditor/selectOptions';
import { DetailedEditor } from '../../../../Pages/Admin/Questionnaire/QuestionnaireEditor/Editors/DetailedEditor';
import { QuestionnaireEditorContext } from '../QuestionnaireEditorContextProvider';
import { QuestionnaireVersionDataContext } from '../QuestionnaireVersionDataContextProvider';
import { QuestionnaireClipboardProvider } from './Hooks/useQuestionnaireClipboard';
import { useResize } from './Hooks/useResize';
import { QuestionnaireTreeView } from './TreeView/QuestionnaireTreeView';
import { QuestionnaireTreeViewDragContext } from './TreeView/QuestionnaireTreeViewDragContext';
import { QuestionnaireTreeViewDragContextProvider } from './TreeView/QuestionnaireTreeViewDragContextProvider';
import { QuestionnaireTreeViewSearchContextProvider } from './TreeView/QuestionnaireTreeViewSearchContextProvider';
import { QuestionnaireTreeViewSearchField } from './TreeView/QuestionnaireTreeViewSearchField';

const TREE_SECTION_WIDTH = 500;

const EditorArea = styled.div`
  display: flex;
  flex-grow: 1;
  min-height: 0;
`;

const TreeContainer = styled.div<{ width: number }>`
  display: flex;
  flex-direction: column;
  width: ${(props) => `${props.width}px`};
  transition: visibility 0.5s, opacity 0.5s, width: 0.2s;
  padding: 16px 0;
`;

const ResizeHandle = styled.button<{ $isResizing: boolean }>`
  height: 100%;
  width: 1px;
  padding: 0px;
  border: 0px;
  background-color: transparent;
  cursor: ${(props) => (props.$isResizing ? 'col-resize' : 'ew-resize')};

  &:before {
    content: '';
    width: 2px;
    height: 100%;
    background-color: ${(props) => props.theme.colors.grey[40]};
    display: ${(props) => (props.$isResizing ? 'block' : 'none')};
  }

  &:hover {
    &:before {
      display: block;
    }
  }
`;

const TreeViewPanelContainer = styled.div`
  display: flex;
  border-right: 1px solid ${(props) => props.theme.colors.grey[40]};
  min-width: 56px;
  overflow: hidden;
  transition: all 0.5s linear;
  margin-left: 16px;
`;

const DetailedEditorWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.grey[20]};
  border-right: 1px solid ${(props) => props.theme.colors.grey[40]};
  flex-grow: 1;
  display: flex;
`;

function QuestionnaireEditorContainerComponent(): React.ReactElement | null {
  const { size, ref, isResizing, enableResize } = useResize({ minSize: TREE_SECTION_WIDTH });

  const { questionnaireVersionData } = useContext(QuestionnaireVersionDataContext);
  const { questionnaireId, blueprint, isDraft } = questionnaireVersionData || {};

  const { onSelectedPartIdentifierChange, selectedLanguage } = useContext(QuestionnaireEditorContext);

  const { t } = useTranslation();

  const dataLabelOptions = useMemo(() => getDataLabelOptions(t), [t]);

  if (!questionnaireId || !blueprint || !selectedLanguage) {
    return null;
  }

  return (
    <QuestionnaireClipboardProvider>
      <Divider />
      <EditorArea>
        <QuestionnaireTreeViewSearchContextProvider>
          <TreeViewPanelContainer>
            <TreeContainer ref={ref} width={size}>
              <Box width='100%' pr={2}>
                <QuestionnaireTreeViewSearchField />
              </Box>
              <QuestionnaireTreeViewDragContextProvider context={QuestionnaireTreeViewDragContext}>
                <QuestionnaireTreeView isEditingEnabled={!!isDraft} dataLabelOptions={dataLabelOptions} />
              </QuestionnaireTreeViewDragContextProvider>
            </TreeContainer>
          </TreeViewPanelContainer>
          <ResizeHandle type='button' $isResizing={isResizing} onMouseDown={enableResize} />
          <DetailedEditorWrapper>
            <DetailedEditor
              setSelectedPartIdentifier={onSelectedPartIdentifierChange}
              selectedLanguage={selectedLanguage}
              isEditingEnabled={!!isDraft}
              dataLabelOptions={dataLabelOptions}
            />
          </DetailedEditorWrapper>
        </QuestionnaireTreeViewSearchContextProvider>
      </EditorArea>

      <Divider />
    </QuestionnaireClipboardProvider>
  );
}

export const QuestionnaireEditorContainer = React.memo(QuestionnaireEditorContainerComponent);
