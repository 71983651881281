import i18next from 'i18next';
import _ from 'lodash';

import { CreateUserData, User } from '@breathelife/types';

import { DetailViewType } from '../../Models/Layout';
import { Dispatch } from '../../Redux/types';
import UsersService, { GetUsersOptions } from '../../Services/Users/UsersService';
import { defaultState, layoutSlice } from '../Layout/LayoutSlice';
import { notificationSlice } from '../Notification/NotificationSlice';
import { userManagementSlice } from './UserManagementSlice';

const { actions: userManagementActions } = userManagementSlice;

const { actions: notificationActions } = notificationSlice;
const { actions: layoutActions } = layoutSlice;

export const createUser =
  (data: CreateUserData) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(userManagementActions.setIsLoading({ isLoading: true }));
    try {
      const user = await UsersService.createUser(data);
      dispatch(
        notificationSlice.actions.setSuccess({
          message: i18next.t('notifications.userSuccessfullyCreated', {
            user: user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.emailLogin,
          }),
          autoHideDuration: 3000,
        })
      );
      dispatch(userManagementActions.addUser({ user }));
      dispatch(userManagementActions.setSelectedUserIds({ selectedUserIds: [user.auth0Id] }));
      dispatch(layoutActions.setRightPanelState({ rightPanelState: { type: DetailViewType.edit, isOpen: true } }));
    } catch (error) {
      switch (error?.response?.status) {
        case 400:
          dispatch(notificationActions.setError({ message: i18next.t('notifications.badRequestGeneral') }));
          break;

        case 403:
          dispatch(notificationActions.setError({ message: i18next.t('notifications.forbiddenGeneral') }));
          break;

        case 409:
          dispatch(notificationActions.setError({ message: i18next.t('notifications.createUserConflict') }));
          break;

        default:
          dispatch(notificationActions.setError({ message: error.message }));
          break;
      }
    } finally {
      dispatch(userManagementActions.setIsLoading({ isLoading: false }));
    }
  };

export const selectUser =
  (userId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(userManagementActions.setIsLoading({ isLoading: true }));
    try {
      const response = await UsersService.getUser(userId);
      // TODO: update user in list for "refresh" capabilities
      dispatch(userManagementActions.setSelectedUserIds({ selectedUserIds: [response.auth0Id] }));
    } catch (error) {
      dispatch(userManagementActions.setSelectedUserIds({ selectedUserIds: [] }));
      dispatch(notificationActions.setError({ message: error.message }));
    } finally {
      dispatch(userManagementActions.setIsLoading({ isLoading: false }));
    }
  };

export const getUsers =
  (options?: GetUsersOptions) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(userManagementActions.setIsLoading({ isLoading: true }));
    try {
      const { data: users, total } = await UsersService.getUsers(options);
      dispatch(userManagementActions.setUsers({ users }));
      dispatch(userManagementActions.setTotal({ total }));
    } catch (error) {
      dispatch(notificationActions.setError({ message: error.message }));
    } finally {
      dispatch(userManagementActions.setIsLoading({ isLoading: false }));
    }
  };

export const sendInvitationEmail =
  (user: User) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(userManagementActions.setIsLoading({ isLoading: true }));
    try {
      await UsersService.sendAuth0InvitationEmail(user.auth0Id);
      dispatch(
        notificationSlice.actions.setSuccess({
          title: i18next.t('notifications.userInvitationEmailSuccessfullySent.title'),
          message: i18next.t('notifications.userInvitationEmailSuccessfullySent.message', {
            name: user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.emailLogin,
          }),
          autoHideDuration: 3000,
        })
      );
      dispatch(layoutActions.setModalState(defaultState));
    } catch (error) {
      dispatch(notificationActions.setError({ message: error.message }));
    } finally {
      dispatch(userManagementActions.setIsLoading({ isLoading: false }));
    }
  };

export const deleteUser =
  (userId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(userManagementActions.setIsLoading({ isLoading: true }));
    try {
      const deletedUser = await UsersService.deleteUser(userId);
      dispatch(userManagementActions.removeUser({ user: deletedUser }));
      dispatch(
        notificationSlice.actions.setSuccess({
          message: i18next.t('notifications.userSuccessfullyDeleted'),
          autoHideDuration: 3000,
        })
      );
      dispatch(layoutActions.setModalState(defaultState));
    } catch (error) {
      dispatch(notificationActions.setError({ message: i18next.t('notifications.failedToDeleteUser') }));
    } finally {
      dispatch(userManagementActions.setIsLoading({ isLoading: false }));
    }
  };

export const updateUser =
  (userId: string, data: Partial<CreateUserData>, isSso?: boolean) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(userManagementActions.setIsLoading({ isLoading: true }));
    try {
      const filteredData = isSso ? _.omit(data, ['email', 'firstName', 'lastName']) : data;
      const updatedUserInfo = await UsersService.updateUserInformation(userId, filteredData);
      dispatch(userManagementSlice.actions.updateUser({ user: updatedUserInfo }));
      dispatch(
        notificationSlice.actions.setSuccess({
          message: i18next.t('notifications.updateUser'),
          autoHideDuration: 3000,
        })
      );
    } catch (error) {
      switch (error?.response?.status) {
        case 400:
          dispatch(notificationActions.setError({ message: i18next.t('notifications.badRequestGeneral') }));
          break;

        case 403:
          dispatch(notificationActions.setError({ message: i18next.t('notifications.forbiddenGeneral') }));
          break;

        default:
          dispatch(notificationActions.setError({ message: i18next.t('notifications.failedToUpdateUser') }));
          break;
      }
    } finally {
      dispatch(userManagementActions.setIsLoading({ isLoading: false }));
    }
  };
