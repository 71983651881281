import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { NodeIds } from '@breathelife/insurance-form-builder';
import { evaluateConditions, NodeIdToAnswerPathMap } from '@breathelife/questionnaire-engine';
import {
  FileTemplate,
  FileTemplateOrigin,
  Permission,
  StoredFile,
  StoredFileDocType,
  Answers,
  ParticipantRoles,
  Timezone,
  DEFAULT_TIMEZONE_NAME,
} from '@breathelife/types';

import { userHasPermission } from '../../Helpers/user';
import { useDispatch, useSelector } from '../../Hooks';
import { Application } from '../../Models/Application';
import {
  useFindApplicationFilesQuery,
  useFileTemplateRulesQuery,
} from '../../ReactQuery/Application/application.queries';
import { notificationSlice } from '../../Redux/Notification/NotificationSlice';

export type RequiredFile = {
  template: FileTemplate;
  storedFile: StoredFile | undefined;
};

export function useApplicationStoredFiles(
  application: Application,
  answers: Answers | undefined,
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap | undefined
): [RequiredFile[], StoredFile[]] {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userPermissions =
    useSelector((store) => {
      return store.leadPlatform.authentication.user?.permissions;
    }) ?? [];

  const storedFiles = useFindApplicationFilesQuery(application.id, {
    enabled: userHasPermission(userPermissions, Permission.ApplicationFileRead),
    onError: useCallback(() => {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToFetchUploadedFiles'),
          autoHideDuration: 5000,
        })
      );
    }, [dispatch, t]),
  });

  // TODO: Future Improvements: https://breathelife.atlassian.net/browse/DEV-12858
  // Assign a template to one of the insureds, instead of relying on the signer settings
  const fileTemplateRules = useFileTemplateRulesQuery(
    {
      questionnaireVersionId: application.currentQuestionnaireVersionId,
      includeFileTemplate: true,
      includeFileTemplateRecipient: true,
    },
    {
      enabled:
        !_.isEmpty(application.currentQuestionnaireVersionId) &&
        userHasPermission(userPermissions, Permission.FileTemplateRuleRead),
      onError: useCallback(() => {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToFetchFileTemplates'),
            autoHideDuration: 5000,
          })
        );
      }, [dispatch, t]),
    }
  );

  const [storedFilesIndexedByTemplateId, otherFiles] = useMemo<[Record<string, StoredFile>, StoredFile[]]>(() => {
    if (!storedFiles.data) return [{}, []];

    const clonedStoredFiles: StoredFile[] = _.clone(storedFiles.data);
    const storedFilesIndexedByTemplateId: Record<string, StoredFile> = {};

    for (let i = clonedStoredFiles.length - 1; i >= 0; i--) {
      const file = clonedStoredFiles[i];
      if (file.templateId !== null) {
        storedFilesIndexedByTemplateId[file.templateId] = file;
      }

      // Hide non user-uploaded files from the "Other Files" tab
      if (file.templateId !== null || file.docType !== StoredFileDocType.Attachment) {
        clonedStoredFiles.splice(i, 1);
      }
    }

    return [storedFilesIndexedByTemplateId, clonedStoredFiles];
  }, [storedFiles.data]);

  const requiredTemplates = useMemo<FileTemplate[]>(() => {
    if (!nodeIdToAnswerPathMap || !answers) return [];

    const triggeredTemplates: FileTemplate[] = [];

    (fileTemplateRules.data ?? []).forEach((templateRule) => {
      const { fileTemplate } = templateRule;
      // Do not consider templates with `CarrierGenerated` (API-provided) or `BLGenerated` (e.g. application PDF) origin
      if (
        !fileTemplate ||
        [FileTemplateOrigin.CarrierGenerated, FileTemplateOrigin.BLGenerated].includes(fileTemplate.origin)
      ) {
        return;
      }

      // Dynamically build the repeated instance identifiers (identifies index of an entity in a questionnaire collection)
      // based on the signer settings
      const repeatedInstanceIdentifiers: Partial<Record<NodeIds, number>> = {};

      fileTemplate.fileTemplateRecipients &&
        fileTemplate.fileTemplateRecipients.forEach(({ participantRole }) => {
          if (participantRole?.type === ParticipantRoles.INSURED) {
            repeatedInstanceIdentifiers[NodeIds.insuredPeople] = participantRole.position - 1;
          }
        });

      const timezoneResult = Timezone.from(application.timezone || DEFAULT_TIMEZONE_NAME);
      if (timezoneResult.isError()) {
        throw new Error("Could not create a timezone from either the application's one or the default onemptied.");
      }

      if (
        evaluateConditions(
          templateRule.condition,
          answers,
          nodeIdToAnswerPathMap,
          repeatedInstanceIdentifiers,
          timezoneResult.value
        ) &&
        !fileTemplate.archived
      ) {
        triggeredTemplates.push(fileTemplate);
      }
    });

    return triggeredTemplates;
  }, [answers, nodeIdToAnswerPathMap, fileTemplateRules.data]);

  const requiredFiles = useMemo<RequiredFile[]>(() => {
    return requiredTemplates.map((template) => ({
      template,
      storedFile: _.get(storedFilesIndexedByTemplateId, template.id, undefined),
    }));
  }, [requiredTemplates, storedFilesIndexedByTemplateId]);

  return [requiredFiles, otherFiles];
}
