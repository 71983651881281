import React from 'react';
import { useTranslation } from 'react-i18next';

import { FaviconLinks, TitleHelmet as SharedTitleHelmet } from '@breathelife/ui-components';

import { useCarrierContext } from '../Hooks';

type HelmetProps = {
  text?: string;
};

export const Helmet: React.FC<HelmetProps> = (props) => {
  const { carrierInfo, cloudinaryConfig } = useCarrierContext();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <SharedTitleHelmet text={props.text} title={`${t('pageTitles.prefix')} - ${carrierInfo.companyName}`} />
      {carrierInfo.logoCompact && cloudinaryConfig && (
        <FaviconLinks compactLogoUrl={carrierInfo.logoCompact} cloudinaryConfig={cloudinaryConfig} />
      )}
    </React.Fragment>
  );
};
