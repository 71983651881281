import React, { SVGProps } from 'react';

export function AddAssuredIcon(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke={props.stroke}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12Z' />
      <path d='M7 12L17 12' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 7L12 17' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
}
