import { NodeIds } from '@breathelife/insurance-form-builder';
import {
  PaymentServiceProvider,
  SignatureType,
  EnforceUniqueEmailStrategy,
  ProductsWidgetFeatureType,
  QuestionnaireScreensConfig,
  EntityMappingsConfig,
  NodeId,
} from '@breathelife/types';

type FeatureWithEnableProperty<T = unknown> =
  | ({
      enabled: true;
    } & T)
  | { enabled: false };

export enum LaunchApplicationType {
  coBrowsing = 'co-browsing',
  redirectToCarrier = 'redirect to carrier',
  assistedApplication = 'assisted application',
}

type NeedsAnalysisFeature = FeatureWithEnableProperty<{ optional?: boolean }>;

type LaunchApplicationFeature = FeatureWithEnableProperty<
  | {
      type: LaunchApplicationType.coBrowsing | LaunchApplicationType.redirectToCarrier;
    }
  | {
      type: LaunchApplicationType.assistedApplication;
      signatureType: SignatureType;
    }
>;

type StripeConfig = {
  publicKey: string;
  connectedAccountId: string;
};

type PaymentsFeature = FeatureWithEnableProperty<{
  serviceProvider: PaymentServiceProvider;
  stripe?: StripeConfig;
}>;

type ApplicationCopyFeature = FeatureWithEnableProperty<{
  answerPaths?: {
    insured: [];
    owner: [];
  };
}>;

type SoftwareByWidgetFeature = FeatureWithEnableProperty<{
  softwareByUrl?: string;
}>;

type ProductsWidgetFeature = FeatureWithEnableProperty<{
  type: ProductsWidgetFeatureType;
  hideRidersPremium?: boolean;
}>;

type NodeIdAgentLicenseConfig = {
  ownerSameAsInsuredQuestionNodeId: NodeId;
  ownerResidenceAddressSameAsInsuredNodeIds: NodeId[];
  ownerProvinceOrStateNodeIds: NodeId[];
  insuredProvinceOrStateNodeIds: NodeId[];
};

type AssistedApplicationFeature = {
  adoMinMax?: FeatureWithEnableProperty;
  documentsDrawer?: FeatureWithEnableProperty;
  multiInsured?: FeatureWithEnableProperty;
  productsWidget?: ProductsWidgetFeature;
  totalPremiumsWidget?: FeatureWithEnableProperty;
  jetDecisionWidget?: FeatureWithEnableProperty;
  nodeIdsAffectingPricing?: NodeIds[];
  nodeIdsAffectingProducts?: NodeIds[];
  nodeIdAgentLicenseConfig?: NodeIdAgentLicenseConfig;
};

type ESignatureFeature = {
  enforceUniqueEmail?: EnforceUniqueEmailStrategy;
};

type QuestionnaireScreenFeature = FeatureWithEnableProperty<{
  config: QuestionnaireScreensConfig;
}>;

export type PlatformFeatures = {
  launchApplication: LaunchApplicationFeature;
  productHub: FeatureWithEnableProperty;
  userNotificationEmail: FeatureWithEnableProperty;
  engineQuestionnaire: FeatureWithEnableProperty;
  needsAnalysis: NeedsAnalysisFeature;
  payments: PaymentsFeature;
  enableInstantIdRequest?: FeatureWithEnableProperty;
  loadProductInformationFromDb?: FeatureWithEnableProperty;
  externalNeedsAnalysis?: FeatureWithEnableProperty;
  hiddenLeads?: FeatureWithEnableProperty;
  participantsProcessor?: FeatureWithEnableProperty;
  questionnaireDebugTools: FeatureWithEnableProperty;
  userSecurityDisclosure: FeatureWithEnableProperty;
  documentTemplate?: FeatureWithEnableProperty;
  authImprovementFlow?: FeatureWithEnableProperty;
  loadLanguageSettingsFromDb?: FeatureWithEnableProperty;
  loadCarrierNamesFromDb?: FeatureWithEnableProperty;
  loadPublicLinkMetadataFromDb?: FeatureWithEnableProperty;
  salesDecisionRules?: FeatureWithEnableProperty;
  questionnaireEditor: FeatureWithEnableProperty;
  questionnairePreview: FeatureWithEnableProperty;
  questionnaireBuilder?: FeatureWithEnableProperty;
  importCopyDeck?: FeatureWithEnableProperty;
  auditLogs?: FeatureWithEnableProperty;
  thirdPartyIntegrations?: FeatureWithEnableProperty;
  changeInsuranceScopesForInvitation: FeatureWithEnableProperty;
  softwareByWidget: SoftwareByWidgetFeature;
  assistedApplication?: AssistedApplicationFeature;
  leadCreation: FeatureWithEnableProperty;
  pointOfSaleDecisionOutcome?: FeatureWithEnableProperty;
  productSelection?: FeatureWithEnableProperty;
  applicationCopy?: ApplicationCopyFeature;
  enableDecoupleESignFlow?: FeatureWithEnableProperty;
  agentLicenseValidation?: FeatureWithEnableProperty;
  showBlueprintIdInEditor?: FeatureWithEnableProperty;
  entityMappings?: EntityMappingsConfig;
  applicationMarketingDataExtract?: FeatureWithEnableProperty;
  backOfficeTools?: FeatureWithEnableProperty;
  eSignature?: ESignatureFeature;
  requireSalesDecisionRuleIdentifier?: FeatureWithEnableProperty;
  questionnaireScreen?: QuestionnaireScreenFeature;
};
