import Box from '@material-ui/core/Box';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { ScreenName, TypewriterTracking } from '@breathelife/react-tracking';
import { Permission } from '@breathelife/types';

import { Icon } from '../../Components/Icons';
import { MenuElement, PopupMenu } from '../../Components/PopupMenu/PopupMenu';
import Typography from '../../Components/Typography';
import { getUserDisplayName } from '../../Helpers/user';
import { useCarrierContext, useDispatch, useSelector } from '../../Hooks';
import { ModalType } from '../../Models/Layout';
import { AgentLicensesModal } from '../../Pages/Home/Modals/AgentLicensesModal';
import { NotificationPreferencesModalContainer } from '../../Pages/Home/Modals/NotificationPreferencesModal/NotificationPreferencesModalContainer';
import { PromptCompleteProfileModal } from '../../Pages/Home/Modals/PromptCompleteProfileModal';
import { SharePublicLinkModal } from '../../Pages/Home/Modals/SharePublicLinkModal';
import { UserEditModal } from '../../Pages/Home/Modals/UserEdit/UserEditModal';
import { publicLinkSettingsEnabled } from '../../Redux/Admin/SettingsManagement/SettingsSelectors';
import { logout as reduxLogout } from '../../Redux/Authentication/AuthenticationOperations';
import { layoutSlice } from '../../Redux/Layout/LayoutSlice';
import { StyledButton, UserContainer, UserPicture } from './Styles';

type UserMenuProps = {
  showUserName?: boolean;
  showCarret?: boolean;
  variant: 'light' | 'default';
};

export function UserMenu(props: UserMenuProps): React.ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { externalResources, features } = useCarrierContext();
  const theme = useTheme();
  const user = useSelector((store) => store.leadPlatform.authentication.user);
  const arePublicLinkSettingsEnabled = useSelector(publicLinkSettingsEnabled);
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);

  const [isSharePublicLinkModalOpen, setIsSharePublicLinkModalOpen] = useState(false);
  const [isNotificationPreferencesModalOpen, setIsNotificationPreferencesModalOpen] = useState(false);
  const [isAgentLicensesModalOpen, setIsAgentLicensesModalOpen] = useState(false);
  const [isCompletingProfile, setIsCompletingProfile] = useState(false);

  const modalState = useSelector((store) => store.leadPlatform.layout.modalState);
  const isUserEditModalOpen = modalState.isOpen && modalState.type === ModalType.userEdit;

  const logout = useCallback(() => dispatch(reduxLogout()), [dispatch]);
  const showPopover = Boolean(anchorEl);
  const id = showPopover ? 'popover' : undefined;
  const isUserPermittedToInvite =
    _.intersection(user?.permissions ?? [], [
      Permission.LeadInviteEmail,
      Permission.LeadInvitePublic,
      Permission.LeadInvitePrivate,
    ]).length > 0;
  const isAuthenticatedUserMissingInfo =
    !user?.firstName ||
    !user.lastName ||
    !user.emailLogin ||
    !user.phoneNumber ||
    (isUserPermittedToInvite && !user.defaultInsuranceScopes);

  const { showUserName, showCarret, variant } = props;

  const userGroupName = user?.subGroupName || user?.groupName || '';

  useEffect(() => {
    if (!isAuthenticatedUserMissingInfo && isCompletingProfile) {
      setIsCompletingProfile(false);
    }
  }, [isAuthenticatedUserMissingInfo, isCompletingProfile, setIsCompletingProfile]);

  const openUserEditModal = useCallback(() => {
    TypewriterTracking.viewedScreen({ screenName: ScreenName.userProfile, hashedId: null });
    dispatch(
      layoutSlice.actions.setModalState({
        modalState: { isOpen: true, type: ModalType.userEdit },
      })
    );
    setIsSharePublicLinkModalOpen(false);
  }, [dispatch]);

  const openDownloadFilesModal = useCallback(() => {
    dispatch(
      layoutSlice.actions.setModalState({
        modalState: { isOpen: true, type: ModalType.downloadFiles },
      })
    );
  }, [dispatch]);

  const openAgentLicensesModal = useCallback(() => {
    setIsAgentLicensesModalOpen(true);
  }, []);

  const openPublicLinkModal = useCallback(() => {
    TypewriterTracking.viewedScreen({
      screenName: ScreenName.sharePublicLink,
      hashedId: null,
    });

    setIsSharePublicLinkModalOpen(true);
  }, []);

  const openNotificationsModal = useCallback(() => {
    TypewriterTracking.viewedScreen({
      screenName: ScreenName.notificationPreferences,
      hashedId: null,
    });

    setIsNotificationPreferencesModalOpen(true);
  }, []);

  const onSubmitCompleteProfileModal = useCallback(() => {
    setIsCompletingProfile(true);
  }, [setIsCompletingProfile]);

  const onCloseUserEditModal = useCallback(() => {
    setIsCompletingProfile(false);
    dispatch(
      layoutSlice.actions.setModalState({
        modalState: { isOpen: false, type: ModalType.userEdit },
      })
    );
  }, [dispatch]);

  const onCloseNotificationsModal = useCallback(() => {
    setIsNotificationPreferencesModalOpen(false);
  }, [setIsNotificationPreferencesModalOpen]);

  const onCloseSharePublicLinkModal = useCallback(() => {
    setIsSharePublicLinkModalOpen(false);
  }, [setIsSharePublicLinkModalOpen]);

  const onCloseAgentLicensesModal = useCallback(() => {
    setIsAgentLicensesModalOpen(false);
  }, [setIsAgentLicensesModalOpen]);

  const headerMenuActions = externalResources?.headerMenuResources?.map((externalResource): MenuElement => {
    return {
      tag: 'MenuAction',
      onClick: _.noop,
      icon: externalResource.icon,
      label: externalResource.title,
      linkProperties: {
        href: externalResource.link,
        target: '_blank',
        rel: 'noopener no,referrer',
      },
    };
  });

  const downloadableFiles = externalResources?.downloadableFiles;

  const items: MenuElement[] = useMemo(() => {
    const item: MenuElement[] = [];
    if (_.includes(user?.permissions, Permission.UserEditSelf)) {
      item.push({
        tag: 'MenuAction',
        onClick: () => openUserEditModal(),
        icon: <Icon name='profile' />,
        label: t('userMenu.myProfile'),
      });
    }
    if (
      _.includes(user?.permissions, Permission.LeadInvitePublic) &&
      (!features.loadPublicLinkMetadataFromDb?.enabled || arePublicLinkSettingsEnabled)
    ) {
      item.push({
        tag: 'MenuAction',
        onClick: () => openPublicLinkModal(),
        icon: <Icon name='link' />,
        label: t('userMenu.shareMyPublicLink'),
        'data-testid': 'openPublicLinkModal',
      });
    }
    if (features.userNotificationEmail.enabled) {
      item.push({
        tag: 'MenuAction',
        onClick: () => openNotificationsModal(),
        icon: <Icon name='notification' />,
        label: t('userMenu.notifications'),
      });
    }
    if (downloadableFiles) {
      item.push({
        tag: 'MenuAction',
        onClick: openDownloadFilesModal,
        icon: <Icon name='files' />,
        label: t('userMenu.filesAndDocuments'),
        'data-testid': 'openDownloadFilesModal',
      });
    }
    if (headerMenuActions) {
      item.push(...headerMenuActions);
    }
    if (features.agentLicenseValidation?.enabled) {
      item.push({
        tag: 'MenuAction',
        onClick: openAgentLicensesModal,
        icon: <Icon name='licensesIcon' />,
        label: t('userMenu.agentLicenses'),
        'data-testid': 'openAgentLicensesModal',
      });
    }
    item.push({
      tag: 'MenuAction',
      onClick: () => logout(),
      icon: <Icon name='logout' />,
      label: t('userMenu.logout'),
      'data-testid': 'logout-menu-item',
    });
    return item;
  }, [
    t,
    openUserEditModal,
    user,
    logout,
    openPublicLinkModal,
    openDownloadFilesModal,
    openNotificationsModal,
    headerMenuActions,
    downloadableFiles,
    features.userNotificationEmail.enabled,
    features.loadPublicLinkMetadataFromDb?.enabled,
    arePublicLinkSettingsEnabled,
  ]);

  return (
    <div>
      <PromptCompleteProfileModal
        isOpen={isAuthenticatedUserMissingInfo && !isCompletingProfile}
        onSubmit={onSubmitCompleteProfileModal}
      />
      <UserEditModal
        isOpen={isUserEditModalOpen || isCompletingProfile}
        isCompletingProfile={isCompletingProfile}
        closeModal={onCloseUserEditModal}
      />
      {features.agentLicenseValidation?.enabled && (
        <AgentLicensesModal isOpen={isAgentLicensesModalOpen} closeModal={onCloseAgentLicensesModal} />
      )}
      <SharePublicLinkModal
        onOpenProfilePage={openUserEditModal}
        closeModal={onCloseSharePublicLinkModal}
        isOpen={isSharePublicLinkModalOpen}
      />
      {features.userNotificationEmail.enabled && (
        <NotificationPreferencesModalContainer
          closeModal={onCloseNotificationsModal}
          isOpen={isNotificationPreferencesModalOpen}
        />
      )}
      {user && (
        <StyledButton
          data-testid='header-user-button'
          aria-describedby={id}
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <UserContainer>
            {user?.picture && (
              <Box>
                <UserPicture src={user?.picture} width={32} height={32} />
              </Box>
            )}
            {showUserName && (
              <Box ml={1} textAlign='left'>
                <Box>
                  <Typography variant='body2' grey={variant === 'light' ? 0 : 70}>
                    {getUserDisplayName(user)}
                  </Typography>
                </Box>
                <Typography variant='small1' grey={variant === 'light' ? 0 : 70}>
                  {userGroupName && <Box>{userGroupName}</Box>}
                </Typography>
              </Box>
            )}
            {showCarret && (
              <Box ml={1} lineHeight={0}>
                <Icon
                  name='filledCaret'
                  stroke='none'
                  fill={variant === 'light' ? theme.colors.grey[0] : theme.colors.grey[90]}
                  variant='default'
                  rotation={showPopover ? 180 : 0}
                />
              </Box>
            )}
          </UserContainer>
        </StyledButton>
      )}
      <PopupMenu
        id={id}
        open={showPopover}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        onClose={() => setAnchorEl(null)}
        items={items}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      />
    </div>
  );
}
