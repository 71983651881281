import { NodeIds } from '@breathelife/insurance-form-builder';
import {
  NodeIdAnswersResolver,
  NodeIdToAnswerPathMap,
  CollectionInstanceIdentifiers,
} from '@breathelife/questionnaire-engine';
import { Answers } from '@breathelife/types';

export type ShouldFetchADOParams = {
  answers: Answers;
  featureIsEnabled?: boolean;
  updatedNodeIds: string[];
  currentProposedInsuredIndex: number;
  nodeIdToAnswerPathMap?: NodeIdToAnswerPathMap;
};

export type NodesToRefreshParams = {
  answers: Answers;
  featureIsEnabled?: boolean;
  currentProposedInsuredIndex: number;
  nodeIdToAnswerPathMap?: NodeIdToAnswerPathMap;
};

type NodeToRefresh = {
  nodeId: string;
  value: number;
  collectionInstanceIdentifiers: CollectionInstanceIdentifiers;
};

// Checks whether the conditions are met to fetch ADO values
export function shouldFetchADO({
  answers,
  featureIsEnabled,
  updatedNodeIds,
  currentProposedInsuredIndex,
  nodeIdToAnswerPathMap,
}: ShouldFetchADOParams): boolean {
  if (!featureIsEnabled || !nodeIdToAnswerPathMap) return false;

  const answersResolver = new NodeIdAnswersResolver(nodeIdToAnswerPathMap);
  const adoOptionNodeId = 'ado-option';
  const collectionInstanceIdentifiers: CollectionInstanceIdentifiers = {
    [NodeIds.insuredPeople]: currentProposedInsuredIndex,
  };

  const adoOption = answersResolver.getAnswer(answers, adoOptionNodeId, collectionInstanceIdentifiers);

  // Don't fetch if ADO is not toggled on and that node is not being updated
  if ((!adoOption || adoOption === 'no') && !updatedNodeIds.includes(adoOptionNodeId)) return false;

  // Don't fetch if ADO is toggled on and that node is being updated (meaning it is being toggled off)
  if (adoOption === 'additionalDepositOption' && updatedNodeIds.includes(adoOptionNodeId)) return false;

  return true;
}

// Checks whether the conditions are met to fetch ADO values
export function adoNodesToRefresh({
  answers,
  featureIsEnabled,
  currentProposedInsuredIndex,
  nodeIdToAnswerPathMap,
}: NodesToRefreshParams): NodeToRefresh[] {
  if (!featureIsEnabled || !nodeIdToAnswerPathMap) return [];

  const answersResolver = new NodeIdAnswersResolver(nodeIdToAnswerPathMap);
  const adoMaxNodeId = 'ado-max';
  const adoMinNodeId = 'ado-min';
  const collectionInstanceIdentifiers: CollectionInstanceIdentifiers = {
    [NodeIds.insuredPeople]: currentProposedInsuredIndex,
  };
  const adoMax = answersResolver.getAnswer(answers, adoMaxNodeId, collectionInstanceIdentifiers);
  const adoMin = answersResolver.getAnswer(answers, adoMinNodeId, collectionInstanceIdentifiers);
  return [
    { nodeId: adoMaxNodeId, value: adoMax, collectionInstanceIdentifiers },
    { nodeId: adoMinNodeId, value: adoMin, collectionInstanceIdentifiers },
  ];
}
