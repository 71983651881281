import React, { SVGProps } from 'react';

export function DownloadIcon(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      stroke='#29614B'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0)'>
        <path
          d='M5.00024 10.1205H3.43493C2.61767 10.0062 1.86968 9.59907 1.32993 8.97484C0.790188 8.35061 0.495374 7.55168 0.500262 6.72648C0.490759 6.27983 0.571569 5.83587 0.737846 5.42122C0.904123 5.00656 1.15244 4.62977 1.4679 4.31344C1.78336 3.9971 2.15947 3.74775 2.57366 3.58033C2.98785 3.41291 3.43159 3.33087 3.87826 3.33914C4.23091 2.53731 4.80735 1.85422 5.53847 1.37177C6.26959 0.889321 7.12435 0.628001 8.00026 0.619143C9.18587 0.641466 10.318 1.11667 11.1643 1.94726C12.0106 2.77785 12.507 3.90084 12.5516 5.08581C12.9151 5.01449 13.2901 5.02575 13.6487 5.11876C14.0073 5.21178 14.3404 5.38416 14.6235 5.62319C14.9065 5.86221 15.1323 6.16177 15.284 6.49974C15.4358 6.83771 15.5097 7.20545 15.5003 7.57581C15.5003 10.1611 12.8916 10.1205 12.8916 10.1205H11.0002'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M8 9V15' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M10.5 12.5L8 15L5.5 12.5' strokeLinecap='round' strokeLinejoin='round' />
      </g>
    </svg>
  );
}
