import { Box, Grid } from '@material-ui/core';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { ValidationError } from 'yup';

import {
  isAddressAutocompleteFieldType,
  isAgreeFieldType,
  isOptionFieldType,
} from '@breathelife/insurance-form-builder';
import { Validations } from '@breathelife/questionnaire-engine';
import {
  AgreeFieldBlueprint,
  FieldBlueprint,
  FieldValidation,
  InformationFieldBlueprint,
  InformationFieldBlueprintVariant,
  NumberFieldBlueprint,
  InsuranceScopes,
  Localizable,
  NumericalDataType,
  PlatformType,
  QuestionnaireBlueprintFieldTypes,
  QuestionnaireBlueprintRenderOn,
  SelectOptionFieldBlueprint,
  ButtonFieldBlueprint,
  IconName,
  FieldSizes,
  getFieldLayoutOptions,
  AddressAutocompleteFieldBlueprint,
  IsoCountryCode,
  isTextFieldBlueprint,
  ParticipantRoles,
  isSignatureFieldBlueprint,
  ApplicationMode,
  ApplicationModesDefault,
  QuestionnaireBlueprintCopyableOption,
} from '@breathelife/types';
import { AutocompleteOption, SimpleCheckbox } from '@breathelife/ui-components';

import { SubmitButton } from '../../../../../Components/Button/SubmitButton';
import { NodeIdSelector } from '../../../../../Components/NodeIds/NodeIdSelector';
import { getOptionsFromEnum } from '../../../../../Helpers/options';
import { NodeDetail } from '../../../../../Helpers/questionnaireEditor/questionnaireNodeIds';
import {
  dataLabelSelectionToDataLabel,
  getInfoFieldStylingOptions,
  getNumericalDataTypeOptions,
  getCountryCodeOptions,
  getValidationOptionsByFieldType,
  getFieldTypeOptions,
} from '../../../../../Helpers/questionnaireEditor/selectOptions';
import { useCarrierContext } from '../../../../../Hooks';
import { ModalLayout } from '../../../../../Layouts/Modal/ModalLayout';
import { TextInput } from '../../../../../Pages/Admin/Questionnaire/QuestionnaireEditor/Components/TextInput';
import { QuestionnaireEditorContext } from '../../QuestionnaireEditorContextProvider';
import { QuestionnaireVersionDataContext } from '../../QuestionnaireVersionDataContextProvider';
import { AdvancedBlueprintOptions } from '../Components/AdvancedBlueprintOptions';
import { EditorDropdown } from '../Components/EditorDropdown';
import { IconNameSelector } from '../Components/IconNameSelector';
import NavigatesToNextStepCheckbox from '../Components/NavigatesToNextStepCheckbox';
import { AgreeFieldEditor } from '../Editors/AgreeFieldEditor';
import { BooleanFieldDefaultValueEditor } from '../Editors/defaultValueEditors/BooleanFieldDefaultValueEditor';
import { NumberFieldDefaultValueEditor } from '../Editors/defaultValueEditors/NumberFieldDefaultValueEditor';

export function CreateFieldModal(props: {
  onCloseModal: () => void;
  onCreate: (blueprint: FieldBlueprint) => void;
  dataLabelOptions: AutocompleteOption[];
  repeatableNodeId?: string;
  displayAsCard?: boolean;
}): React.ReactElement | null {
  const { onCloseModal, onCreate, dataLabelOptions, repeatableNodeId, displayAsCard } = props;

  const { t } = useTranslation();
  const fieldTypeOptions = useMemo(() => getFieldTypeOptions(t), [t]);
  const validationOptionsByFieldType = useMemo(() => getValidationOptionsByFieldType(t), [t]);
  const { questionnaireVersionData } = useContext(QuestionnaireVersionDataContext);
  const questionnaireNodeIds = questionnaireVersionData?.questionnaireNodeIds;
  const { selectedLanguage } = useContext(QuestionnaireEditorContext);

  const { languageSettings } = useCarrierContext();
  const enabledLanguages = languageSettings.enabledLanguages;

  const nodeIds: NodeDetail[] = repeatableNodeId
    ? [
        ...(questionnaireNodeIds?.notInQuestionnaire.withRepeatableAncestor[repeatableNodeId] ?? []),
        ...(questionnaireNodeIds?.inQuestionnaire.withRepeatableAncestor[repeatableNodeId] ?? []),
      ]
    : [
        ...(questionnaireNodeIds?.notInQuestionnaire.noRepeatability ?? []),
        ...(questionnaireNodeIds?.inQuestionnaire.noRepeatability ?? []),
      ];

  const [title, setTitle] = useState<Partial<Localizable>>({});
  const [text, setText] = useState<Partial<Localizable>>({});
  const [nodeId, setNodeId] = useState<string>('');
  const [fieldType, setFieldType] = useState<QuestionnaireBlueprintFieldTypes>(QuestionnaireBlueprintFieldTypes.input);
  const [dataLabel, setDataLabel] = useState<string>(dataLabelOptions?.length ? dataLabelOptions[0].value : '');
  const [renderOn, setRenderOn] = useState<QuestionnaireBlueprintRenderOn[]>([]);
  const [scopes, setScopes] = useState<InsuranceScopes[]>([]);
  const [platforms, setPlatforms] = useState<PlatformType[]>([]);
  const [copyable, setCopyable] = useState<QuestionnaireBlueprintCopyableOption>(
    QuestionnaireBlueprintCopyableOption.none
  );
  const [isRequiredField, setIsRequiredField] = useState(true);
  const [triggerStepNavigation, setTriggerStepNavigation] = useState(false);
  const [iconName, setIconName] = useState<IconName>();
  const [isDisabledField, setIsDisabledField] = useState<boolean>();
  const [fieldSize, setFieldSize] = useState<FieldSizes | null>(null);
  const [forceNewLine, setForceNewLine] = useState<boolean | null>(null);
  const [displayInCardPreview, setDisplayInCardPreview] = useState(false);
  const [applicationModes, setApplicationModes] = useState<ApplicationMode[]>(ApplicationModesDefault);

  const [consentModalHeader, setConsentModalHeader] = useState<Partial<Localizable>>({});
  const [consentText, setConsentText] = useState<Partial<Localizable>>({});
  const [buttonText, setButtonText] = useState<Partial<Localizable>>({});
  const [placeholder, setPlaceholder] = useState<Partial<Localizable>>({});
  const [defaultValue, setDefaultValue] = useState<number | string | boolean | string[] | undefined>();
  const [defaultValueValidationError, setDefaultValueValidationError] = useState<ValidationError | undefined>();

  const validationTypeOptions = validationOptionsByFieldType[fieldType];
  const [validationType, setValidationType] = useState<string>(
    validationTypeOptions?.length ? validationTypeOptions[0].value : ''
  );

  const [countryCode, setCountryCode] = useState<IsoCountryCode>('CA');
  const [addressAutocompleteAutocompleteNodeId, setAddressAutocompleteAutocompleteNodeId] = useState<string>('');

  const [infoFieldStylingOption, setInfoFieldStylingOption] = useState<InformationFieldBlueprintVariant>(
    InformationFieldBlueprintVariant.info
  );

  const [participantRole, setParticipantRole] = useState<ParticipantRoles>(ParticipantRoles.INSURED);

  const [fieldNumericalDataType, setFieldNumericalDataType] = useState<keyof typeof NumericalDataType | undefined>(
    undefined
  );

  const participantRoleOptions = getOptionsFromEnum<ParticipantRoles>(ParticipantRoles, 'participantRole');

  const infoFieldStylingOptions = useMemo(() => getInfoFieldStylingOptions(t), [t]);
  const numericalDataTypeOptions = useMemo(() => getNumericalDataTypeOptions(t), [t]);
  const countryCodeOptions = useMemo(() => getCountryCodeOptions(t), [t]);

  const isFormValid =
    !!nodeId &&
    !defaultValueValidationError &&
    (fieldType !== QuestionnaireBlueprintFieldTypes.autocomplete ||
      (fieldType === QuestionnaireBlueprintFieldTypes.autocomplete && addressAutocompleteAutocompleteNodeId));

  if (!selectedLanguage) {
    return null;
  }
  return (
    <ModalLayout
      maxWidth='md'
      isOpen={true}
      closeModal={onCloseModal}
      title={t('admin.questionnaireManagement.input.createField.title')}
      submitButton={
        <SubmitButton
          disabled={!isFormValid}
          onClick={() => {
            const blueprintDataLabel = dataLabelSelectionToDataLabel(dataLabel);

            const partName = `custom-${uuid()}`;
            const blueprintFieldType = fieldType as QuestionnaireBlueprintFieldTypes;
            const fieldBlueprint = {
              id: uuid(),
              partName,
              text,
              title,
              dataLabel: blueprintDataLabel,
              answerNodeId: nodeId,
              fieldType: blueprintFieldType,
              validateAs: validationType as FieldValidation,
              optional: !isRequiredField,
              triggerStepNavigation: triggerStepNavigation,
              isCustom: true,
              iconName,
              defaultValue,
              displayInCardPreview,
              copyable,
            } as FieldBlueprint;

            if (renderOn.length) fieldBlueprint.renderOn = renderOn;
            if (scopes.length) fieldBlueprint.scopes = scopes;
            if (platforms.length) fieldBlueprint.platforms = platforms;

            if (isDisabledField !== undefined) fieldBlueprint.disabled = isDisabledField;

            if (fieldSize || forceNewLine) {
              fieldBlueprint.layout = {};
              if (fieldSize) fieldBlueprint.layout.size = fieldSize;
              if (forceNewLine) fieldBlueprint.layout.forceNewLine = forceNewLine;
            }

            if (isOptionFieldType(blueprintFieldType)) {
              // Add empty options array so more can be added (type checking checks for the existence of this array to determine when something is a `SelectOptionFieldBlueprint`)
              (fieldBlueprint as SelectOptionFieldBlueprint).selectOptions = [];
            }

            if (isAgreeFieldType(blueprintFieldType)) {
              (fieldBlueprint as AgreeFieldBlueprint).modalText = consentText;
              (fieldBlueprint as AgreeFieldBlueprint).modalHeader = consentModalHeader;
            } else if (isAddressAutocompleteFieldType(blueprintFieldType)) {
              (fieldBlueprint as AddressAutocompleteFieldBlueprint).countryCode = 'CA';
              (fieldBlueprint as AddressAutocompleteFieldBlueprint).addressAutocompleteFields = {
                streetAddress: partName,
                city: '',
                stateOrProvince: '',
                postalCodeOrZip: '',
              };
              (fieldBlueprint as AddressAutocompleteFieldBlueprint).addressAutocompleteNodeId =
                addressAutocompleteAutocompleteNodeId;
            } else if (blueprintFieldType === QuestionnaireBlueprintFieldTypes.information) {
              (fieldBlueprint as InformationFieldBlueprint).variant = infoFieldStylingOption;
            } else if (blueprintFieldType === QuestionnaireBlueprintFieldTypes.number) {
              (fieldBlueprint as NumberFieldBlueprint).numericalDataType = fieldNumericalDataType;
            } else if (blueprintFieldType === QuestionnaireBlueprintFieldTypes.button) {
              (fieldBlueprint as ButtonFieldBlueprint).buttonText = buttonText;
            }

            if (isTextFieldBlueprint(blueprintFieldType)) fieldBlueprint.placeholder = placeholder;

            if (isSignatureFieldBlueprint(fieldBlueprint)) {
              fieldBlueprint.participantRole = participantRole;
            }

            fieldBlueprint.applicationModes = applicationModes;

            onCreate(fieldBlueprint);
            onCloseModal();
          }}
          data-testid='questionnaire-editor-field-create'
        >
          {t('cta.save')}
        </SubmitButton>
      }
    >
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={6} key={`createField-fieldType`}>
          <EditorDropdown
            id='addFieldFieldTypeDropdown'
            label={t('admin.questionnaireManagement.input.fieldType')}
            selectedOptionValue={fieldType}
            onChange={(value) => {
              if (value !== null) {
                setFieldType(value as QuestionnaireBlueprintFieldTypes);
              }
            }}
            options={fieldTypeOptions}
          />
        </Grid>
        {fieldType === QuestionnaireBlueprintFieldTypes.number && (
          <Grid item xs={6} key={`createField-numericalDataType`}>
            <EditorDropdown
              label={t('admin.questionnaireManagement.input.numericalDataType')}
              selectedOptionValue={fieldNumericalDataType}
              onChange={(newValue: string | null) => {
                if (newValue !== null) {
                  setFieldNumericalDataType(newValue as NumericalDataType);
                }
              }}
              options={numericalDataTypeOptions}
            />
          </Grid>
        )}
        {fieldType === QuestionnaireBlueprintFieldTypes.button &&
          enabledLanguages.map((language) => (
            <Grid item xs={6} key={`createField-buttonText-${language}`}>
              <TextInput
                label={t('admin.questionnaireManagement.input.buttonTextAndLanguage', {
                  language: t(`language.${language}`),
                })}
                value={buttonText[language] ?? ''}
                onChange={(event) => {
                  const value = event.target.value;
                  setButtonText((previousValue) => ({ ...previousValue, [language]: value }));
                }}
              />
            </Grid>
          ))}
        <Grid item xs={6} key={`createField-validationType`}>
          <EditorDropdown
            id='addFieldValidationTypeDropdown'
            label={t('admin.questionnaireManagement.input.validationType')}
            selectedOptionValue={validationType}
            onChange={(value) => {
              if (value !== null) {
                setValidationType(value);
              }
            }}
            options={validationTypeOptions}
          />
        </Grid>
        {fieldType === QuestionnaireBlueprintFieldTypes.button && (
          <React.Fragment>
            {enabledLanguages.map((language) => (
              <Grid item xs={6} key={`createField-buttonText-${language}`}>
                <TextInput
                  label={t('admin.questionnaireManagement.input.buttonTextAndLanguage', {
                    language: t(`language.${language}`),
                  })}
                  value={buttonText[language] ?? ''}
                  onChange={(event) => {
                    const value = event.target.value;
                    setButtonText((previousValue) => ({ ...previousValue, [language]: value }));
                  }}
                />
              </Grid>
            ))}
            <Grid item xs={6} key={`createField-buttonIconName`}>
              <IconNameSelector
                label={t('admin.questionnaireManagement.input.iconName')}
                value={iconName}
                onChange={setIconName}
                disabled={false}
              />
            </Grid>
          </React.Fragment>
        )}
        <Grid
          item
          xs={fieldType === QuestionnaireBlueprintFieldTypes.button ? 6 : 12}
          key={`createField-isRequiredField`}
        >
          <SimpleCheckbox
            id='createField-isRequiredField'
            label={t('admin.questionnaireManagement.input.requiredField')}
            checked={isRequiredField}
            onChange={(event) => {
              setIsRequiredField(event.target.checked);
            }}
          />
        </Grid>
        <Grid item xs={12} key={`createField-isDisabledField`}>
          <SimpleCheckbox
            id='createField-isDisabledField'
            label={t('admin.questionnaireManagement.input.disabledField')}
            checked={isDisabledField ?? false}
            onChange={(event) => {
              setIsDisabledField(event.target.checked);
            }}
          />
        </Grid>
        {fieldType === QuestionnaireBlueprintFieldTypes.button && (
          <Grid item xs={6} key={`createField-buttonText-triggerStepNavigation`}>
            <NavigatesToNextStepCheckbox
              prefix='create-field'
              checked={triggerStepNavigation}
              onChange={setTriggerStepNavigation}
            />
          </Grid>
        )}

        <Grid item xs={6} key={`createField-answerNodeId`}>
          <NodeIdSelector
            label={t('admin.questionnaireManagement.input.answerStorageNodeId')}
            nodeIds={nodeIds}
            readOnly={false}
            selectedNodeId={nodeId}
            onChange={(nodeIdUpdate) => {
              if (nodeIdUpdate !== null) {
                setNodeId(nodeIdUpdate?.value);
              }
            }}
            selectedLanguage={selectedLanguage}
            required
          />
        </Grid>

        {fieldType === QuestionnaireBlueprintFieldTypes.signature ? (
          <Grid item xs={6} key={`createField-participantRole`}>
            <EditorDropdown
              label={t('admin.questionnaireManagement.input.participantRole')}
              selectedOptionValue={participantRole}
              onChange={(newValue) => {
                const newParticipantRole = newValue as ParticipantRoles;
                setParticipantRole(newParticipantRole);
              }}
              options={participantRoleOptions}
            />
          </Grid>
        ) : (
          <Grid item xs={6} key={`createField-spacing`} />
        )}

        {enabledLanguages.map((language) => (
          <Grid item xs={6} key={`createField-title-${language}`}>
            <TextInput
              label={t('admin.questionnaireManagement.input.titleAndLanguage', { language: t(`language.${language}`) })}
              value={title[language] ?? ''}
              onChange={(event) => {
                const value = event.target.value;
                setTitle((previousValue) => ({ ...previousValue, [language]: value }));
              }}
              helperTooltipText={
                fieldType === QuestionnaireBlueprintFieldTypes.agree
                  ? t(`admin.questionnaireManagement.input.agreeFieldTitleTooltip`)
                  : undefined
              }
            />
          </Grid>
        ))}
        {enabledLanguages.map((language) => (
          <Grid item xs={6} key={`createField-text-${language}`}>
            <TextInput
              label={t('admin.questionnaireManagement.input.textAndLanguage', {
                language: t(`language.${language}`),
              })}
              multiline={true}
              value={text[language] ?? ''}
              onChange={(event) => {
                const value = event.target.value;
                setText((previousValue) => ({ ...previousValue, [language]: value }));
              }}
              helperTooltipText={
                fieldType === QuestionnaireBlueprintFieldTypes.agree
                  ? t(`admin.questionnaireManagement.input.agreeFieldTextTooltip`)
                  : undefined
              }
            />
          </Grid>
        ))}
        {isTextFieldBlueprint(fieldType) &&
          enabledLanguages.map((language) => (
            <Grid item xs={6} key={`createField-placeholder-${language}`}>
              <TextInput
                label={t('admin.questionnaireManagement.input.placeholderAndLanguage', {
                  language: t(`language.${language}`),
                })}
                value={placeholder[language] ?? ''}
                onChange={(event) => {
                  const value = event.target.value;
                  setPlaceholder((previousValue) => ({ ...previousValue, [language]: value }));
                }}
              />
            </Grid>
          ))}
        {fieldType === QuestionnaireBlueprintFieldTypes.information && (
          <Grid item xs={6} key={'createField-informationFieldVariant'}>
            <EditorDropdown
              id='informationFieldVariant'
              label={t('admin.questionnaireManagement.input.infoFieldStyling')}
              selectedOptionValue={infoFieldStylingOption}
              onChange={(newValue) => {
                if (newValue !== null) {
                  setInfoFieldStylingOption(newValue as InformationFieldBlueprintVariant);
                }
              }}
              options={infoFieldStylingOptions}
            />
          </Grid>
        )}
        {fieldType === QuestionnaireBlueprintFieldTypes.autocomplete && (
          <React.Fragment>
            <Grid item xs={6} key={'createField-autocompleteCountryCode'}>
              <EditorDropdown
                label={t('admin.questionnaireManagement.input.autocompleteCountryCode')}
                selectedOptionValue={countryCode}
                onChange={(value) => {
                  if (!value) return;
                  setCountryCode(value as IsoCountryCode);
                }}
                disabled={false}
                options={countryCodeOptions}
              />
            </Grid>
            <Grid item xs={6} key={'createField-autocompleteNodeId'}>
              <NodeIdSelector
                label={t('admin.questionnaireManagement.input.autocompleteNodeId')}
                nodeIds={nodeIds}
                readOnly={false}
                required
                selectedNodeId={addressAutocompleteAutocompleteNodeId}
                onChange={(option) => {
                  if (!option?.value) return;
                  setAddressAutocompleteAutocompleteNodeId(option?.value || '');
                }}
                selectedLanguage={selectedLanguage}
              />
            </Grid>
          </React.Fragment>
        )}
        {(fieldType === QuestionnaireBlueprintFieldTypes.number ||
          fieldType === QuestionnaireBlueprintFieldTypes.money) && (
          <Grid item xs={6} key={'createField-defaultNumberValueEditor'}>
            <NumberFieldDefaultValueEditor
              onValidationErrorChange={setDefaultValueValidationError}
              validationType={validationType as Validations}
              fieldType={fieldType}
              onChange={(value?: number) => {
                setDefaultValue(value);
              }}
              numericalDataType={fieldNumericalDataType}
              disabled={false}
            />
          </Grid>
        )}

        {fieldType === QuestionnaireBlueprintFieldTypes.checkbox && (
          <Grid item xs={6} key={'createField-defaultCheckboxValueEditor'}>
            <BooleanFieldDefaultValueEditor
              onChange={(value) => {
                setDefaultValue(value);
              }}
              value={defaultValue as boolean}
              disabled={false}
            />
          </Grid>
        )}
      </Grid>
      {fieldType === QuestionnaireBlueprintFieldTypes.agree && (
        <AgreeFieldEditor
          languages={enabledLanguages}
          disabled={false}
          consentModalHeader={consentModalHeader}
          consentText={consentText}
          onConsentModalHeaderChange={(value, language) =>
            setConsentModalHeader((previousValue) => ({ ...previousValue, [language]: value }))
          }
          onConsentTextChange={(value, language) =>
            setConsentText((previousValue) => ({ ...previousValue, [language]: value }))
          }
          spacing={2}
        />
      )}

      <Box pt={2}>
        <EditorDropdown
          id='addFieldLayoutDropdown'
          label={'Field size'}
          selectedOptionValue={fieldSize ? fieldSize.toString() : undefined}
          onChange={(value) => {
            if (!value) {
              setFieldSize(null);
              return;
            }

            setFieldSize(parseInt(value));
          }}
          options={getFieldLayoutOptions((key: FieldSizes) => t(`admin.questionnaireManagement.fieldSizes.${key}`)).map(
            (e) => ({ ...e, value: e.value.toString() })
          )}
        />
      </Box>
      <Box pt={2}>
        <SimpleCheckbox
          id='createField-layoutForceNewLine'
          label={'Force new line'}
          checked={forceNewLine ?? false}
          onChange={(event) => {
            setForceNewLine(event.target.checked);
          }}
        />
      </Box>

      {displayAsCard && (
        <Box pt={2}>
          <SimpleCheckbox
            id='createField-displayInCardPreview'
            label={t('admin.questionnaireManagement.input.displayInCardPreview')}
            checked={displayInCardPreview}
            onChange={(event) => {
              setDisplayInCardPreview(event.target.checked);
            }}
          />
        </Box>
      )}

      <Box pt={2}>
        <AdvancedBlueprintOptions
          tag='field'
          platform={{ selected: platforms, onChange: setPlatforms }}
          renderOn={{ selected: renderOn, onChange: setRenderOn }}
          scope={{ selected: scopes, onChange: setScopes }}
          dataLabel={{
            selected: dataLabel,
            options: dataLabelOptions,
            onChange: (value) => {
              if (value !== null) {
                setDataLabel(value);
              }
            },
          }}
          copyable={{
            selected: copyable,
            onChange: (value) => {
              if (value !== null) {
                setCopyable(value as QuestionnaireBlueprintCopyableOption);
              }
            },
          }}
          disabled={false}
          applicationModes={{
            selected: applicationModes,
            onChange: (updatedMode: ApplicationMode[]) => {
              setApplicationModes(updatedMode);
            },
          }}
        />
      </Box>
    </ModalLayout>
  );
}
