import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';

import { EntityMappingDefinition } from '@breathelife/types';

import { QueryId } from '../../../ReactQuery/common/common.types';
import { updateEntityMapping } from '../../../Services/EntityMappingService';

export function useUpdateEntityMappingMutation(
  options?: UseMutationOptions<EntityMappingDefinition, unknown, EntityMappingDefinition>
): UseMutationResult<EntityMappingDefinition, unknown, EntityMappingDefinition> {
  const queryClient = useQueryClient();
  return useMutation<EntityMappingDefinition, unknown, EntityMappingDefinition, unknown>(updateEntityMapping, {
    ...options,
    onSuccess: async (data, variables, context): Promise<void> => {
      queryClient.setQueryData(
        [QueryId.entityMappings, data.mapping, data.namespace, data.questionnaireVersionId],
        data
      );
      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
  });
}
