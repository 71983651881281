import { Box } from '@material-ui/core';
import styled from 'styled-components';

import Typography from '../../../../Components/Typography';

export const JsonAreaHeaderContainer = styled(Box)`
  && {
    padding: 16px;
    border-bottom: 1px solid #e6e6e6;
    margin: 0;
  }
`;

export const JsonAreaHeaderElements = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const UnderwritingReportsArea = styled.div`
  display: flex;
  flex-grow: 1;
  min-height: 0;
  height: calc(100% - 150px);
`;

export const UnderwritingHeaderContainer = styled(Box)`
  && {
    padding: 16px;
    border-bottom: 1px solid #e6e6e6;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const UnderwritingTreeViewPanelContainer = styled.div`
  display: flex;
  border-right: 1px solid ${(props) => props.theme.colors.grey[40]};
  min-width: 56px;
  overflow: hidden;
  transition: all 0.5s linear;
  margin-left: 16px;
  height: 100%;
`;

export const UnderwritingTreeContainer = styled.div<{ width: number }>`
  display: flex;
  flex-direction: column;
  width: ${(props) => `${props.width}px`};
  transition: visibility 0.5s, opacity 0.5s, width: 0.2s;
  padding: 16px 0;
  p {
    margin-left: 5px;
  }
`;

export const UnderwritingResizeHandle = styled.button<{ $isResizing: boolean }>`
  height: 100%;
  width: 1px;
  padding: 0px;
  border: 0px;
  background-color: transparent;
  cursor: ${(props) => (props.$isResizing ? 'col-resize' : 'ew-resize')};

  &:before {
    content: '';
    width: 2px;
    height: 100%;
    background-color: ${(props) => props.theme.colors.grey[40]};
    display: ${(props) => (props.$isResizing ? 'block' : 'none')};
  }

  &:hover {
    &:before {
      display: block;
    }
  }
`;

export const JsonAreaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const JsonViewerContainer = styled.div`
  background-color: #fbfbfb;
  flex-grow: 1;
`;

export const ApplicationInfoContainer = styled.div`
  display: flex;
`;
export const ApplicationTitle = styled(Typography)`
  && {
    margin-right: 6px;
  }
`;
