import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  deserializeNodeIdToAnswerPathMap,
  getAllSubsections,
  NodeIdToAnswerPathMap,
  Questionnaire,
  QuestionnaireEngine,
  RenderingQuestionnaire,
} from '@breathelife/questionnaire-engine';
import { DEFAULT_TIMEZONE_NAME, InsuranceModule, Language, RenderingType, Timezone } from '@breathelife/types';
import { DebugToolbarModal, Loader } from '@breathelife/ui-components';

import { useDispatch } from '../../Hooks';
import { Application } from '../../Models/Application';
import { useUpdateApplicationAnswersUntilMutation } from '../../ReactQuery/DebugToolbar/debugToolbar.mutations';
import { useFetchQuestionnaireQuery } from '../../ReactQuery/Questionnaire/questionnaire.queries';
import { notificationSlice } from '../../Redux/Notification/NotificationSlice';

type Props = {
  selectedApplication: Application;
  onClose: () => void;
};

export function DebugToolbarModalViewContainer(props: Props): React.ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedApplication, onClose } = props;

  const { isLoading: isLoadingQuestionnaire, data: questionnaireData } = useFetchQuestionnaireQuery(
    selectedApplication?.id,
    {
      onError: () =>
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToFetchQuestionnaire'),
          })
        ),
    }
  );
  const questionnaire = questionnaireData?.questionnaire;
  const serializedNodeIdToAnswerPathMap = questionnaireData?.nodeIdToAnswerPath;
  const currentQuestionnaireVersion = questionnaireData?.versionId;

  const nodeIdToAnswerPathMap = useMemo(
    () => serializedNodeIdToAnswerPathMap && deserializeNodeIdToAnswerPathMap(serializedNodeIdToAnswerPathMap),
    [serializedNodeIdToAnswerPathMap]
  );

  const stepIds = useMemo(() => {
    if (!selectedApplication?.id || !questionnaire || !nodeIdToAnswerPathMap) return;
    if (selectedApplication?.currentQuestionnaireVersionId !== currentQuestionnaireVersion) return;
    const timezoneResult = Timezone.from(selectedApplication.timezone || DEFAULT_TIMEZONE_NAME);
    if (timezoneResult.isError()) {
      throw new Error("Could not create a timezone from either the application's one or the default onemptied.");
    }

    const renderingQuestionnaire = buildRenderingQuestionnaire(
      questionnaire,
      nodeIdToAnswerPathMap,
      selectedApplication.lang,
      timezoneResult.value
    );

    return getAllSubsections(renderingQuestionnaire).map((subsection) => subsection.id);
  }, [selectedApplication, currentQuestionnaireVersion, questionnaire, nodeIdToAnswerPathMap]);

  const updateApplicationAnswersUntilMutation = useUpdateApplicationAnswersUntilMutation({
    onSuccess: () => {
      onClose();

      // the timeout is needed because the AA is not setting answers coming from an action outside the AA until the AA is closed
      //TODO: https://breathelife.atlassian.net/browse/DEV-6727
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    },
  });

  const updateApplicationAnswers = useCallback(
    async (stepId: string) => {
      if (!selectedApplication?.id) return;

      updateApplicationAnswersUntilMutation.mutate({
        applicationId: selectedApplication.id,
        subsectionId: stepId,
      });
    },
    [selectedApplication, updateApplicationAnswersUntilMutation]
  );

  if (isLoadingQuestionnaire) return <Loader />;

  return <DebugToolbarModal onClose={onClose} steps={stepIds ?? []} onStepSelect={updateApplicationAnswers} />;
}

function buildRenderingQuestionnaire(
  questionnaire: Questionnaire,
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap,
  language: Language,
  timezone: Timezone
): RenderingQuestionnaire {
  const questionnaireEngine = new QuestionnaireEngine(
    questionnaire,
    nodeIdToAnswerPathMap,
    {
      insuranceModules: [InsuranceModule.insuranceApplication],
    },
    undefined,
    timezone
  );

  const renderingQuestionnaire = questionnaireEngine.generateRenderingQuestionnaire({}, language, () => '', {
    // TODO: Remove filter
    renderingType: RenderingType.consumerSummary,
    shouldValidateAllAnswers: false,
  });

  return renderingQuestionnaire;
}
