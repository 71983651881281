import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

import { RenderingSection } from '@breathelife/questionnaire-engine';
import { Box } from '@breathelife/ui-components';

import { Icon } from '../../../Components/Icons';
import Typography from '../../../Components/Typography';
import { LeadPlatformTheme } from '../../../Styles/Types';
import { NavButton, SubNavLink } from './Styles';

export type NavigationRenderingSection = RenderingSection & { visited: boolean };

type Props = {
  allSections: NavigationRenderingSection[];
  activeSectionIndex: number;
  onSectionClick: (index: number) => void;
};

const getSectionEndIcon = (section: NavigationRenderingSection): React.ReactElement | null => {
  if (section.completed) return <Icon name='circledCheckMark' variant='green' inverted stroke='none' />;

  if (!section.completed && section.visited)
    return <Icon name='circledWarning' variant='orange' inverted stroke='none' />;

  return null;
};

export function AssistedApplicationNavigation(props: Props): React.ReactElement {
  const theme = useTheme() as LeadPlatformTheme;

  const { allSections, activeSectionIndex, onSectionClick } = props;

  return (
    <Box display='flex' flexDirection='column' alignItems='start'>
      {allSections.map((section: NavigationRenderingSection, sectionIndex: number) => {
        const isActive = activeSectionIndex === sectionIndex;
        return (
          <React.Fragment key={`AssistedApplication-${section.id}`}>
            <NavButton
              $isActive={isActive}
              textColor={isActive ? theme.colors.grey[90] : theme.colors.grey[70]}
              onClick={() => onSectionClick(sectionIndex)}
              endIcon={getSectionEndIcon(section)}
              data-testid={`AssistedApplication-${section.id}`}
            >
              {section.title}
            </NavButton>
            {isActive && <AssistedApplicationSubNavigation section={section} />}
          </React.Fragment>
        );
      })}
    </Box>
  );
}

function AssistedApplicationSubNavigation(props: { section: NavigationRenderingSection }): React.ReactElement | null {
  const { section } = props;

  const [activeSubsectionIndex, setActiveSubsectionIndex] = useState(0);

  const subsectionsNavigation = useMemo(
    () => section.subsections.filter((subsection) => subsection.visible && subsection.showInNavigation),
    [section.subsections]
  );

  if (!subsectionsNavigation.length) return null;
  return (
    <Box ml={2}>
      {subsectionsNavigation.map((subsection, subsectionIndex) => (
        <SubNavLink
          key={`AssistedApplication-${subsection.id}`}
          href={`#${_.kebabCase(subsection.title)}`}
          $isActive={activeSubsectionIndex === subsectionIndex}
          onClick={() => setActiveSubsectionIndex(subsectionIndex)}
        >
          <Typography variant='button'>{subsection.title}</Typography>
        </SubNavLink>
      ))}
    </Box>
  );
}
