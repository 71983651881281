import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconName } from '@breathelife/types';
import { HeaderButton, Icon, JsonViewer } from '@breathelife/ui-components';

import { StatusBadge } from '../../../../Components/Badge/StatusBadge';
import { LeadStatusEnum } from '../../../../Models/Lead';
import {
  JsonAreaHeaderContainer,
  JsonAreaHeaderElements,
  JsonAreaContainer,
  JsonViewerContainer,
  ApplicationInfoContainer,
  ApplicationTitle,
} from './Styles';

const mockJsonObject = {
  provider: {
    title: 'Dr.',
    firstName: 'Michael',
    lastName: 'Jordan',
  },
  consumer: {
    id: 111,
    company: 'Evil Corp',
  },
  aRandomNumber: 56,
};

export function UnderWritingReportsJsonArea(): React.ReactElement {
  const { t } = useTranslation();
  // TODO DEV-12481: Implement an onCopyClick functionality to copy json when clicking the copy button
  return (
    <JsonAreaContainer>
      <JsonAreaHeaderContainer m={2}>
        <JsonAreaHeaderElements>
          <ApplicationInfoContainer>
            <ApplicationTitle variant='h3'>
              {/*TODO DEV-12418: Replace this mock data by implementing breadcrumbs */}
              Alexis Azaré / Medical Information / Provider 1 / Request # [yyyy-mm-dd hh:mm:ss]
            </ApplicationTitle>
            <StatusBadge status={LeadStatusEnum.submissionCompleted} />
          </ApplicationInfoContainer>
          <HeaderButton>
            <Icon name={IconName.copy} size='16px' />
            {t('backOffice.underwritingReports.copy')}
          </HeaderButton>
        </JsonAreaHeaderElements>
      </JsonAreaHeaderContainer>
      <JsonViewerContainer>
        {/*TODO DEV-12956: Replace mock data by connecting underwriting reports data */}
        <JsonViewer jsonObject={mockJsonObject} />
      </JsonViewerContainer>
    </JsonAreaContainer>
  );
}
