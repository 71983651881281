import Box from '@material-ui/core/Box';
import styled from 'styled-components';

export const NeedsAnalysisContainer = styled(Box)`
  background-color: ${(props) => props.theme.colors.grey[20]};
`;

export const NeedsAnalysisDetails = styled(Box)`
  display: flex;
  justify-content: space-between;
`;
