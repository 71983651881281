import { Box } from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { QuestionnaireBuilderPdfEntity } from '@breathelife/types';

import { PIXEL_GAP_BETWEEN_PAGES } from '../../../ReactQuery/Admin/QuestionnaireBuilder/questionnaireBuilderHelpers';
import { QuestionnaireBuilderContext } from './QuestionnaireBuilderProvider';
import { UploadButton, UploadModal, UploadPdfProvider } from './QuestionnaireBuilderUpload';

const PREVIEW_PADDING = 15;

const ScaledQuestionnaireBuilderPdfView = styled.div<{ scaleFactor: number }>`
  transform: ${(props) => `scale(${props.scaleFactor * 100}%)`};
  transform-origin: 0 0;
  position: relative;
`;

const QuestionnaireBuilderPdfViewWrapper = styled.div`
  max-width: 70%;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${(props) => props.theme.colors.grey[30]};
  padding: ${PREVIEW_PADDING}px;
`;

const QuestionnaireBuilderPdfItem = styled.span<QuestionnaireBuilderPdfEntity['style']>`
  white-space: nowrap;
  font-size: ${({ fontSize }) => fontSize || 12};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'inherit')};
  color: ${({ color }) => color};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'inherit')};
  font-style: ${({ italic }) => (italic ? 'italic' : 'inherit')};
  text-decoration-line: ${({ strikethrough }) => (strikethrough ? 'line-through' : 'inherit')};
`;

export function QuestionnaireBuilderPdfView(): React.ReactElement {
  const { questionnaireBuilder, questionnaireBuilderLoadingStatus } = useContext(QuestionnaireBuilderContext);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [targetWidth, setTargetWidth] = useState<number>(1);

  const scaleFactor = useMemo(() => {
    if (!questionnaireBuilder) {
      return 1;
    }
    const largestPageWidth = questionnaireBuilder.pdfPages.reduce(
      (acc, page) => (page.width > acc ? page.width : acc),
      0
    );

    return targetWidth / largestPageWidth;
  }, [questionnaireBuilder, targetWidth]);

  useEffect(() => {
    function handleResize(): void {
      const targetWidth = wrapperRef?.current?.offsetWidth;
      if (targetWidth) {
        setTargetWidth(targetWidth - 2 * PREVIEW_PADDING);
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <QuestionnaireBuilderPdfViewWrapper ref={wrapperRef}>
      {!questionnaireBuilder && questionnaireBuilderLoadingStatus !== 'loading' && (
        <UploadPdfProvider>
          <UploadButton />
          <UploadModal />
        </UploadPdfProvider>
      )}
      <ScaledQuestionnaireBuilderPdfView scaleFactor={scaleFactor}>
        {questionnaireBuilder?.pdfPages.map((page, index) => {
          return (
            <Box
              position='relative'
              key={`page-${index}`}
              top={index * PIXEL_GAP_BETWEEN_PAGES}
              height={page.height}
              width={page.width}
              bgcolor={'#ffffff'}
            />
          );
        })}
        {questionnaireBuilder?.pdfEntities.map((item) => {
          return (
            <Box
              position='absolute'
              key={item.id}
              left={item.position.x}
              top={item.position.y}
              fontFamily={item.style.font}
              textAlign={item.style.alignment}
            >
              <QuestionnaireBuilderPdfItem {...item.style}>{item.text}</QuestionnaireBuilderPdfItem>
            </Box>
          );
        })}
      </ScaledQuestionnaireBuilderPdfView>
    </QuestionnaireBuilderPdfViewWrapper>
  );
}
