import { captureException } from '@sentry/browser';
import _ from 'lodash';

import config from '@breathelife/config/frontend';
import { TypewriterTracking } from '@breathelife/react-tracking';
import { User } from '@breathelife/types';

import { acronymize } from '../../Helpers/acronymize';
import { getLanguageRegion } from '../../Localization/utils';
import { notificationSlice } from '../../Redux/Notification/NotificationSlice';
import { Dispatch, LeadPlatformStore } from '../../Redux/types';
import { isSsoUser, logoutAgent } from '../../Services/Auth0';
import { syncUser } from '../../Services/SyncService';
import { fetchUserPermissions } from '../../Services/Users/PermissionsService';
import UsersService from '../../Services/Users/UsersService';
import { authenticationSlice } from './AuthenticationSlice';

const { actions: authenticationActions } = authenticationSlice;

async function trackUserAuthentication(user: User): Promise<void> {
  const locale = getLanguageRegion();

  // Must use English company name
  const companyName = config.get<string>('carrier.companyName.en');
  if (!companyName) {
    console.warn('Tracking properties missing');
    return;
  }

  const groupId = `${_.snakeCase(companyName)}_user_group`;
  const mgaId = user.groupId ?? (user.groupName && acronymize(user.groupName));

  try {
    await new Promise<void>((resolve) => {
      TypewriterTracking.identify(
        {
          userId: user.auth0Id,
          traits: {
            userType: user.roles.join(','),
            locale,
          },
        },
        resolve
      );
    });

    await new Promise<void>((resolve) => {
      TypewriterTracking.group(groupId, undefined, resolve);
    });

    await new Promise<void>((resolve) => {
      TypewriterTracking.loggedIn({ organizationId: mgaId }, undefined, resolve);
    });
  } catch (error) {
    captureException(new Error(`Authentication tracking error: ${error.message}`));
  }
}

export const authenticate = (token: string, user: User) => async (dispatch: Dispatch) => {
  try {
    const permissions = await fetchUserPermissions();
    const userWithPermissions: User = { ...user, permissions };

    dispatch(authenticationActions.authenticate({ token, user: userWithPermissions }));

    await trackUserAuthentication(user);
  } catch (e) {
    dispatch(notificationSlice.actions.setError({ message: e.message }));
  }
};

export const logout = () => async (dispatch: Dispatch, store: () => LeadPlatformStore) => {
  try {
    const user = store().leadPlatform.authentication.user;
    const isSso = user ? isSsoUser(user) : false;
    TypewriterTracking.loggedOut({});
    logoutAgent(isSso);
  } catch (e) {
    dispatch(notificationSlice.actions.setError({ message: e.message }));
  }

  return;
};

export const regularAuthenticate =
  (token: string, auth0User: Pick<User, 'auth0Id'>, lang?: string) => async (dispatch: Dispatch) => {
    try {
      const user = await syncUser(auth0User.auth0Id, lang);
      await authenticate(token, user)(dispatch);
    } catch (e) {
      dispatch(notificationSlice.actions.setError({ message: e.message }));
    }
  };

export const ssoAuthenticate =
  (token: string, auth0User: Pick<User, 'auth0Id'>, lang?: string) => async (dispatch: Dispatch) => {
    try {
      const user = await syncUser(auth0User.auth0Id, lang);

      const { data: existingAuth0AccountsForCurrentUser } = await UsersService.getUsers({
        query: 'currentUserAccounts',
      });
      const nonSsoUserAccount = _.find(existingAuth0AccountsForCurrentUser, (userAccount) => !isSsoUser(userAccount));

      if (nonSsoUserAccount && !nonSsoUserAccount.isBlocked) {
        await UsersService.updateUserInformation(nonSsoUserAccount.auth0Id, { isBlocked: true });
        await UsersService.transferUser(nonSsoUserAccount.auth0Id, user.auth0Id);
      }

      await authenticate(token, user)(dispatch);
    } catch (err) {
      dispatch(notificationSlice.actions.setError({ message: err.message }));
      return;
    }
  };
