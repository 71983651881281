import { SerializedNodeIdToAnswerPathMap } from '@breathelife/questionnaire-engine';
import {
  BlueprintModification,
  PageQueryOptions,
  QuestionnaireBlueprint,
  QuestionnaireVersionDetail,
  QuestionnaireVersionInfo,
  QuestionnaireVersionInfoPage,
  QuestionnaireVersionRowData,
} from '@breathelife/types';

import {
  generateNodeIdInCollectionMap,
  generateQuestionnaireNodeIdsFromBlueprint,
  NodeIdInCollections,
  QuestionnaireNodeIds,
} from '../Helpers/questionnaireEditor/questionnaireNodeIds';
import ApiService from './ApiService';

export type QuestionnaireVersionDetailWithNodeIdInfo = QuestionnaireVersionDetail & {
  nodeIdToAnswerPath?: SerializedNodeIdToAnswerPathMap;
  nodeIdInCollectionMap?: NodeIdInCollections;
  questionnaireNodeIds?: QuestionnaireNodeIds;
};

export async function fetchQuestionnaireVersionAndNodeIdData(
  questionnaireId: string
): Promise<QuestionnaireVersionDetailWithNodeIdInfo> {
  const { data: questionnaire } = await ApiService.admin.fetchQuestionnaireVersion(questionnaireId);

  const nodeIdToAnswerPath = questionnaire.dataStructure as SerializedNodeIdToAnswerPathMap;

  const questionnaireNodeIds = generateQuestionnaireNodeIdsFromBlueprint(questionnaire.blueprint, nodeIdToAnswerPath);

  const nodeIdInCollectionMap = generateNodeIdInCollectionMap(questionnaireNodeIds);

  return {
    ...questionnaire,
    questionnaireNodeIds,
    nodeIdInCollectionMap,
    nodeIdToAnswerPath,
  };
}

export async function patchQuestionnaireVersionBlueprint(
  questionnaireVersionId: string,
  modification: BlueprintModification
): Promise<QuestionnaireBlueprint> {
  const { data } = await ApiService.admin.patchQuestionnaireVersion(questionnaireVersionId, modification);
  return data.blueprint;
}

export async function fetchQuestionnaireVersions(
  fetchOptions: PageQueryOptions<QuestionnaireVersionRowData>
): Promise<QuestionnaireVersionInfoPage> {
  const axiosResponse = await ApiService.admin.fetchQuestionnaireVersions(fetchOptions);
  return axiosResponse.data;
}

export async function fetchAllQuestionnaireVersions(): Promise<QuestionnaireVersionInfo[]> {
  return ApiService.admin.fetchAllQuestionnaireVersions();
}

export async function publishQuestionnaire(questionnaireVersionId: string, description: string): Promise<void> {
  return ApiService.admin.publishQuestionnaireVersion(questionnaireVersionId, description);
}

export async function createNewDraftQuestionnaireVersion(
  questionnaireId: string,
  description: string,
  questionnaireBlueprint: QuestionnaireBlueprint
): Promise<{
  id: string;
  questionnaireBlueprint: QuestionnaireBlueprint;
  questionnaireId: string;
  majorVersion: number;
  minorVersion: number;
}> {
  const response = await ApiService.admin.newDraftQuestionnaireVersion({
    questionnaireId,
    description,
    questionnaireBlueprint,
  });
  return response.data;
}
