import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Language,
  Localizable,
  QuestionBlueprint,
  QuestionPartIdentifier,
  QuestionnaireBlueprintCopyableOption,
} from '@breathelife/types';
import { AutocompleteOption, Box, SimpleCheckbox, StyledFormControlLabel } from '@breathelife/ui-components';

import { MonospaceTypography } from '../../../../../Components/Typography';
import { QuestionnaireNodeIds } from '../../../../../Helpers/questionnaireEditor/questionnaireNodeIds';
import { ValidationOptionsByFieldType } from '../../../../../Helpers/questionnaireEditor/selectOptions';
import { useCarrierContext } from '../../../../../Hooks/useCarrierContext';
import { TextInput } from '../../../../../Pages/Admin/Questionnaire/QuestionnaireEditor/Components/TextInput';
import { useUpdateQuestionnaireElementBlueprint } from '../../../../../ReactQuery/Admin/Questionnaire/questionnaireVersion.mutations';
import { QuestionnaireVersionDataContext } from '../../QuestionnaireVersionDataContextProvider';
import { AdvancedBlueprintOptions } from '../Components/AdvancedBlueprintOptions';
import { useAdvancedBlueprintOptions } from '../Hooks/useAdvancedBlueprintOptions';
import { useHighlightedTextInBlueprintElement } from '../Hooks/useHighlightedTextInBlueprintElement';
import { HighlightedContent } from '../TreeView/HighlightedContent';
import { ConditionsEditor } from './ConditionsEditor';

type Props = {
  blueprint: QuestionBlueprint;
  partIdentifier: QuestionPartIdentifier;
  sectionGroupCollectionNodeId: string | undefined;
  selectedLanguage: Language;
  parentHidden: boolean;
  dataLabelOptions: AutocompleteOption[];
  validationOptionsByFieldType: ValidationOptionsByFieldType;
  questionnaireNodeIds: QuestionnaireNodeIds;
  isEditingEnabled: boolean;
  disableCopyable: boolean;
};

export function QuestionBlueprintEditor(props: Props): React.ReactElement {
  const {
    partIdentifier,
    blueprint,
    selectedLanguage,
    parentHidden,
    dataLabelOptions,
    questionnaireNodeIds,
    isEditingEnabled,
    sectionGroupCollectionNodeId,
    disableCopyable,
  } = props;

  const { t } = useTranslation();
  const { showBlueprintIdInEditor } = useCarrierContext();

  const [title, setTitle] = useState<Partial<Localizable>>({});
  const [text, setText] = useState<Partial<Localizable>>({});
  const [displayAsCard, setDisplayAsCard] = useState(false);

  const collectionContext = useMemo(() => {
    const collectionNodeIds: string[] = [];
    if (sectionGroupCollectionNodeId) {
      collectionNodeIds.push(sectionGroupCollectionNodeId);
    }
    if (blueprint.repeatable?.repeatableAnswerNodeId) {
      collectionNodeIds.push(blueprint.repeatable?.repeatableAnswerNodeId);
    }

    return collectionNodeIds;
  }, [blueprint.repeatable, sectionGroupCollectionNodeId]);

  const { questionnaireVersionId } = useContext(QuestionnaireVersionDataContext);

  const blueprintUpdate = useUpdateQuestionnaireElementBlueprint(questionnaireVersionId);

  const {
    actions: { setPlatforms, setScopes, setRenderOn, setDataLabel, setCopyable },
    selectors: { platforms, scopes, renderOn, dataLabel, copyable },
  } = useAdvancedBlueprintOptions<QuestionPartIdentifier>({
    partIdentifier,
    blueprintUpdate,
  });

  const [isInitialized, setIsInitialized] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isInitialized) return;

    const initialTitle = blueprint.title;
    if (initialTitle) {
      setTitle(initialTitle);
    }

    const initialText = blueprint.text;
    if (initialText) {
      setText(initialText);
    }

    const initialDisplayAsCard = blueprint.displayAsCard;
    if (initialDisplayAsCard) {
      setDisplayAsCard(initialDisplayAsCard);
    }

    const initialDataLabel = blueprint.dataLabel;
    if (initialDataLabel) {
      setDataLabel(initialDataLabel, false);
    } else if (dataLabelOptions?.length) {
      setDataLabel(dataLabelOptions[0].value, false);
    }

    setPlatforms(blueprint.platforms ?? [], false);
    setRenderOn(blueprint.renderOn ?? [], false);
    setScopes(blueprint.scopes ?? [], false);
    setCopyable(blueprint.copyable ?? QuestionnaireBlueprintCopyableOption.none, false);

    setIsInitialized(true);
  }, [
    blueprint.title,
    blueprint.text,
    blueprint.displayAsCard,
    blueprint.dataLabel,
    blueprint.platforms,
    blueprint.renderOn,
    blueprint.scopes,
    blueprint.copyable,
    dataLabelOptions,
    setPlatforms,
    setScopes,
    setRenderOn,
    setDataLabel,
    setCopyable,
    isInitialized,
  ]);

  const disabled = parentHidden || !!blueprint.hidden || !isEditingEnabled;

  const onTitleChange = useCallback(
    (event) => {
      const value = event.target.value;
      setTitle((previousValue) => ({ ...previousValue, [selectedLanguage]: value }));
    },
    [selectedLanguage]
  );

  const titleValue = useMemo(() => title[selectedLanguage] ?? '', [title, selectedLanguage]);

  const onTitleBlur = useCallback(() => {
    void blueprintUpdate({
      partIdentifier,
      update: { property: 'title', value: title },
    });
  }, [blueprintUpdate, partIdentifier, title]);

  const onTextChange = useCallback(
    (event) => {
      const value = event.target.value;
      setText((previousValue) => ({ ...previousValue, [selectedLanguage]: value }));
    },
    [selectedLanguage]
  );

  const textValue = useMemo(() => text[selectedLanguage] ?? '', [text, selectedLanguage]);

  const highlightedTexts = useHighlightedTextInBlueprintElement({ partIdentifier, blueprint });

  const onTextBlur = useCallback(() => {
    void blueprintUpdate({
      partIdentifier,
      update: { property: 'text', value: text },
    });
  }, [blueprintUpdate, partIdentifier, text]);

  return (
    /* @ts-ignore  ref is missing in Material UI Box typing definitions https://github.com/mui-org/material-ui/issues/17010 */
    <Box ref={containerRef}>
      {showBlueprintIdInEditor && <TextInput label={'Id'} value={blueprint?.id} disabled />}
      <TextInput
        highlighted={!!highlightedTexts.title}
        label={t('admin.questionnaireManagement.input.questionTitle')}
        value={titleValue}
        disabled={disabled}
        onChange={onTitleChange}
        onBlur={onTitleBlur}
      />
      <Box pt={2}>
        <TextInput
          label={t('admin.questionnaireManagement.input.questionText')}
          highlighted={!!highlightedTexts.text}
          multiline={true}
          value={textValue}
          disabled={disabled}
          onChange={onTextChange}
          onBlur={onTextBlur}
        />
      </Box>
      <Box pt={2}>
        <SimpleCheckbox
          id={`displayAsCard-${blueprint.partName}`}
          label={t('admin.questionnaireManagement.input.displayAsCard')}
          checked={displayAsCard}
          disabled={disabled}
          onChange={(event) => {
            setDisplayAsCard(event.target.checked);
            void blueprintUpdate({
              partIdentifier,
              update: { property: 'displayAsCard', value: event.target.checked },
            });
          }}
        />
      </Box>
      {blueprint.repeatable?.repeatableAnswerNodeId && (
        <Box pt={2}>
          <StyledFormControlLabel
            label={t('admin.questionnaireManagement.input.repeatableQuestionNodeId')}
            showError={false}
            labelPlacement='top'
            control={
              <MonospaceTypography variant='body1'>
                {highlightedTexts.repeatableAnswerNodeId ? (
                  <HighlightedContent textFragments={highlightedTexts.repeatableAnswerNodeId} />
                ) : (
                  blueprint.repeatable?.repeatableAnswerNodeId
                )}
              </MonospaceTypography>
            }
          />
        </Box>
      )}

      <Box pb={2} pt={2} mr={-1}>
        <ConditionsEditor
          condition={blueprint.visible}
          collectionContext={collectionContext}
          questionnaireNodeIds={questionnaireNodeIds}
          isReadOnly={parentHidden || !!blueprint.hidden || disabled}
          label={t('admin.questionnaireManagement.rules.visibility.conditions')}
          editHeadingText={t('admin.questionnaireManagement.rules.visibility.edit')}
          saveCondition={(condition) => {
            void blueprintUpdate({
              partIdentifier,
              update: { property: 'visible', value: condition },
            });
          }}
          selectedLanguage={selectedLanguage}
        />
      </Box>

      <AdvancedBlueprintOptions
        tag='question'
        platform={{ selected: platforms, onChange: setPlatforms }}
        renderOn={{ selected: renderOn, onChange: setRenderOn }}
        scope={{ selected: scopes, onChange: setScopes }}
        dataLabel={{
          selected: dataLabel,
          options: dataLabelOptions,
          onChange: (value) => {
            if (value !== null) {
              setDataLabel(value);
            }
          },
        }}
        copyable={{
          selected: copyable,
          disabled: disableCopyable,
          onChange: (value) => {
            if (value !== null) {
              setCopyable(value as QuestionnaireBlueprintCopyableOption);
            }
          },
        }}
        disabled={disabled}
      />
    </Box>
  );
}
