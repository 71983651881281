import { Box, Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import React, { useMemo } from 'react';

import { FileTemplateRecipient } from '@breathelife/types';

import { Button } from '../../../../../../Components/Button/Button';
import { useModalState } from '../../../../../../Hooks';
import { EditESignFieldSetting } from './EditESignFieldSettings';
import { NewESignFieldSetting } from './NewESignFieldSettings';

export function ESignFieldSettingContainer(props: {
  fileTemplateRecipient: FileTemplateRecipient;
}): React.ReactElement {
  const [isCreating, openCreateForm, closeCreateForm] = useModalState();

  const sortedFieldSettings = useMemo(
    () => _.orderBy(props?.fileTemplateRecipient?.fieldSettings, ['createdAt'], ['desc']),
    [props?.fileTemplateRecipient?.fieldSettings]
  );

  return (
    <Grid container>
      <Grid item>
        <Box my={2}>
          {isCreating ? (
            <NewESignFieldSetting signerSettingId={props.fileTemplateRecipient.id} onClose={closeCreateForm} />
          ) : (
            <Box display='flex' justifyContent='flex-end' width='100%'>
              <Button color='primary' variant='outlined' onClick={openCreateForm}>
                + Add Signature Field
              </Button>
            </Box>
          )}

          <Grid item>
            {sortedFieldSettings.map((eSignFieldSetting) => {
              return (
                <React.Fragment key={eSignFieldSetting.id}>
                  <Box my={4}>
                    <EditESignFieldSetting
                      signerSettingId={eSignFieldSetting.signerSettingId}
                      eSignFieldSetting={eSignFieldSetting}
                    />
                  </Box>
                  <Divider />
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
