import Box from '@material-ui/core/Box';
import React, { useEffect, useCallback } from 'react';
import { Redirect, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { Permission, UserRole } from '@breathelife/types';
import { Loader } from '@breathelife/ui-components';

import { Sidebar } from '../Components/Sidebar/Sidebar';
import { useCarrierContext, useDispatch, useSelector } from '../Hooks';
import Urls from '../Navigation/Urls';
import { AdminPage } from '../Pages/Admin/AdminPage';
import { LeadsPage } from '../Pages/Leads/LeadsPage';
import { useCreateAgentLicenseMutation } from '../ReactQuery/AgentLicense/agentLicense.mutations';
import { fetchPublicSettings } from '../Redux/Admin/SettingsManagement/SettingsOperations';
import PrivateRoute from '../Root/PrivateRoute';

export function PrivateRootApplication(): React.ReactElement {
  const dispatch = useDispatch();
  const location = useLocation();
  const { features } = useCarrierContext();
  const theme = useSelector((state) => state.leadPlatform.theme);
  const authentication = useSelector((store) => store.leadPlatform.authentication);
  const matchesApplicationRoute = useRouteMatch(Urls.application);
  const matchesAdminRoute = useRouteMatch(Urls.admin);
  const { mutate } = useCreateAgentLicenseMutation();

  const fetchAgentLicenses = useCallback(async (): Promise<void> => {
    if (authentication.user && authentication.user.roles.includes(UserRole.user)) {
      mutate(authentication.user);
    }
  }, [authentication, mutate]);

  useEffect(() => {
    void dispatch(fetchPublicSettings());
    if (features.agentLicenseValidation?.enabled) {
      void fetchAgentLicenses();
    }
  }, [dispatch, fetchAgentLicenses, features.agentLicenseValidation]);

  if (!theme) return <Loader color='#000000' />;

  const displayContentFullScreen = !!matchesApplicationRoute?.isExact || !!matchesAdminRoute;

  return (
    // TODO: Move to BaseLayout and use BaseLayout here. Pass the Switch routes to the BaseLayout.
    <Box width='100%'>
      {!displayContentFullScreen && (
        /* Using a position fixed for the sidebar with a margin left on the page layout because having
        a table inside a `display: flex;` is not working well. The table is rendered wider than the parent even if
        the parent of the table is a div with a `flex: 1;` */
        <Box height='100vh' position='fixed' top='0' left='0' p={2}>
          <Sidebar />
        </Box>
      )}

      <Box ml={displayContentFullScreen ? 0 : 13} mr={displayContentFullScreen ? 0 : 2}>
        <Switch>
          <PrivateRoute
            path={Urls.leads}
            render={() => <LeadsPage displayContentFullScreen={displayContentFullScreen} />}
          />
          <PrivateRoute path={Urls.admin} component={AdminPage} permissions={Permission.AdminToolsRead} />
          <Redirect to={{ pathname: Urls.leads, search: location.search }} />
        </Switch>
      </Box>
    </Box>
  );
}
