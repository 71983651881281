import { captureException } from '@sentry/browser';
import { Stripe } from '@stripe/stripe-js';
import i18next from 'i18next';

import { notificationSlice } from '../../Redux/Notification/NotificationSlice';
import { Dispatch, LeadPlatformStore } from '../../Redux/types';
import * as PaymentService from '../../Services/PaymentService';
import { paymentSlice } from './PaymentSlice';

const { actions } = paymentSlice;

export const createSetupIntent =
  (applicationId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(actions.setIsLoading(true));

    const { clientSecret } = await PaymentService.createStripeSetupIntent(applicationId);

    dispatch(actions.setApplicationId(applicationId));
    dispatch(actions.setClientSecret(clientSecret));
    dispatch(actions.setSetupIntentStatus(null));
    dispatch(actions.setPaymentMethodInfo(null));

    dispatch(actions.setIsLoading(false));
  };

export const patchSetupIntent =
  (applicationId: string, setupIntentId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const { clientSecret } = await PaymentService.patchStripeSetupIntent(applicationId, setupIntentId);

      dispatch(actions.setClientSecret(clientSecret));
    } catch (err) {
      dispatch(
        notificationSlice.actions.setError({
          message: i18next.t('notifications.payment.failedToUpdatePaymentInformation'),
        })
      );
    }
  };

export const createSetupIntentForEditing =
  (applicationId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(actions.setIsLoadingEditClientSecret(true));

    const { clientSecret } = await PaymentService.createStripeSetupIntent(applicationId, true);
    dispatch(actions.setApplicationId(applicationId));
    dispatch(actions.setEditClientSecret(clientSecret));

    dispatch(actions.setIsLoadingEditClientSecret(false));
  };

export const fetchClientSecret =
  (applicationId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(actions.setIsLoading(true));

    try {
      const { clientSecret } = await PaymentService.retrieveStripeSetupIntent(applicationId);
      dispatch(actions.setApplicationId(applicationId));
      dispatch(actions.setClientSecret(clientSecret));
      dispatch(actions.setSetupIntentStatus(null));
      dispatch(actions.setPaymentMethodInfo(null));
    } catch (err) {
      if (err.response?.status === 404) {
        // If we couldn't retrieve a setup intent due to 404 (Not Found), we attempt to re-create a new one
        return await dispatch(createSetupIntent(applicationId));
      }
      throw err;
    } finally {
      dispatch(actions.setIsLoading(false));
    }
  };

export const retrieveSetupIntent =
  (stripe: Stripe) =>
  async (dispatch: Dispatch, store: () => LeadPlatformStore): Promise<void> => {
    const clientSecret = store().leadPlatform.payment.clientSecret;
    if (!clientSecret) {
      dispatch(actions.setSetupIntentStatus(null));
      return;
    }

    dispatch(actions.setIsLoading(true));

    try {
      const stripeResponse = await stripe.retrieveSetupIntent(clientSecret);

      if (stripeResponse.setupIntent) {
        dispatch(actions.setSetupIntentStatus(stripeResponse.setupIntent.status));
        return;
      }

      const applicationId = store().leadPlatform.payment.applicationId;
      if (stripeResponse.error.code === 'resource_missing' && applicationId) {
        await dispatch(createSetupIntent(applicationId));
      } else {
        dispatch(
          notificationSlice.actions.setError({
            message: i18next.t('notifications.payment.failedToLoadPaymentInformation'),
          })
        );
      }
    } catch (err) {
      dispatch(
        notificationSlice.actions.setError({
          message: i18next.t('notifications.payment.failedToLoadPaymentInformation'),
        })
      );
    } finally {
      dispatch(actions.setIsLoading(false));
    }
  };

export const fetchPaymentMethodInfo =
  () =>
  async (dispatch: Dispatch, store: () => LeadPlatformStore): Promise<void> => {
    dispatch(actions.setIsLoading(true));
    dispatch(actions.setPaymentMethodInfo(null));

    const applicationId = store().leadPlatform.payment.applicationId;
    if (!applicationId) {
      const err = new Error('Application ID is not set in the payment store.');
      captureException(err);
      throw err;
    }

    try {
      const paymentMethodInfo = await PaymentService.fetchPaymentMethodInfo(applicationId);
      dispatch(actions.setPaymentMethodInfo(paymentMethodInfo));
    } catch (err) {
      dispatch(
        notificationSlice.actions.setError({
          message: i18next.t('notifications.payment.failedToLoadPaymentInformation'),
        })
      );
    } finally {
      dispatch(actions.setIsLoading(false));
    }
  };
