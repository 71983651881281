import React, { useEffect } from 'react';

import { ScreenName, TypewriterTracking } from '@breathelife/react-tracking';

export type StateProps = {
  login: () => void;
};

type LoginProps = StateProps;

function LoginView({ login }: LoginProps): React.ReactElement {
  useEffect(() => {
    TypewriterTracking.viewedScreen({ screenName: ScreenName.login, hashedId: null });
    login();
  }, [login]);

  return <div />;
}

export default LoginView;
