import _ from 'lodash';
import queryString from 'query-string';

export type FetchSortingParams = {
  field: string;
  direction: 'asc' | 'desc';
};

export type CustomFieldMap = { [field: string]: string | string[] };

export function convertObjectToQueryString(
  options: { [key: string]: any } & { $sort?: FetchSortingParams },
  customFieldSortMapping?: CustomFieldMap
): string {
  const { $sort, ...restOptions } = options;
  let sortQuery = undefined;
  const query = queryString.stringify(restOptions, { arrayFormat: 'comma' });
  if ($sort) {
    sortQuery = buildSortQuery($sort, customFieldSortMapping);
  }
  const queries = _.compact([query, sortQuery]);
  return `?${queries.join('&')}`;
}

export function buildSortQuery(sortOptions: FetchSortingParams, customFieldSortMapping?: CustomFieldMap): string {
  const { field, direction } = sortOptions;
  const customFieldMapping = customFieldSortMapping?.[field];
  if (!customFieldMapping) return `$sort[${field}]=${direction}`;

  if (!_.isArray(customFieldMapping)) return `$sort[${customFieldMapping}]=${direction}`;

  const customFieldSortQuery = _.map(customFieldMapping, (field: string) => `$sort[${field}]=${direction}`);

  return customFieldSortQuery.join('&');
}
