import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TextAnswer } from '@breathelife/ui-components';

import { Badge } from '../../../../../Components/Badge/Badge';
import Typography from '../../../../../Components/Typography';
import { convertToReadableTime, getDayInText, getMethodDisplayText } from '../../../../../Helpers/communication';
import { BaseLeadCommunicationSchedule } from '../../../../../Models/Lead';

export const BestTimeToReachTextAnswerWrapper = styled(Box)`
  border-top: 1px solid ${(props) => props.theme.colors.grey[40]};
`;

type Props = {
  preferredMethods: string[];
  communicationSchedules: BaseLeadCommunicationSchedule[];
};

export const BestTimeToReach: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <BestTimeToReachTextAnswerWrapper pt={3}>
        <TextAnswer label={t('bestTimeToReach')} value={getMethodDisplayText(props.preferredMethods)} />
      </BestTimeToReachTextAnswerWrapper>
      {props.communicationSchedules.map((schedule) => (
        <Box my={2} key={`communication-${schedule.day}`}>
          <Grid container>
            <Box minWidth={40}>
              <Typography variant='body1'>{getDayInText(schedule.day, 'short')}: </Typography>
            </Box>
            {schedule.time.map((time) => {
              const readableTime = convertToReadableTime(time, '12');
              return (
                <Box key={readableTime} mx={'2px'}>
                  <Badge label={readableTime} />
                </Box>
              );
            })}
          </Grid>
        </Box>
      ))}
    </>
  );
};
