import i18next from 'i18next';
import * as yup from 'yup';

import { UserCategory, UserRole, UserType } from '@breathelife/types';

import { getEmailSchema, getFirstNameSchema, getLastNameSchema, getPhoneNumberSchema } from '../field';
import { getValidationError } from '../getValidationError';

export type FormFields = {
  firstName: string;
  lastName: string;
  emailLogin: string;
  phoneNumber: string;
  role: UserRole;
  groupId: string;
  category: UserCategory;
  type: UserType;
  userId: string;
  picture: string;
};

const getSchemaMap = (): { [field in keyof FormFields]: yup.StringSchema } => ({
  firstName: getFirstNameSchema().required(i18next.t('validation.error.required')),
  lastName: getLastNameSchema().required(i18next.t('validation.error.required')),
  emailLogin: getEmailSchema().required().required(i18next.t('validation.error.required')),
  phoneNumber: getPhoneNumberSchema(),
  role: yup.string().required(),
  groupId: yup.string(),
  category: yup.string().required(),
  type: yup.string().required(),
  userId: yup.string(),
  picture: yup.string(),
});

export const getFormSchema = (): yup.ObjectSchema => yup.object().shape(getSchemaMap());

export function getFieldValidationError(
  fieldName: keyof FormFields,
  value: string | undefined
): yup.ValidationError | undefined {
  return getValidationError(getSchemaMap(), fieldName, value);
}
