import Box from '@material-ui/core/Box';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StoredFile, FileTemplate } from '@breathelife/types';

import { Tabs, TabsContainer, TabType } from '../../../../Components/Tabs/Tabs';
import { RequiredFile } from '../../../../Hooks/Application';
import { Application } from '../../../../Models/Application';
import { CreateApplicationFileMutateOptions } from '../../../../ReactQuery/Application/application.mutations';
import { OtherFiles } from './Files/OtherFiles';
import { RequiredFiles } from './Files/RequiredFiles';

type Props = {
  application: Application;
  requiredFiles: RequiredFile[];
  otherFiles: StoredFile[];
  ErrorAlert?: React.ReactElement;
  isUploading: boolean;
  isUploadDisabled: boolean;
  onFileUpload: (file: File, template?: FileTemplate, options?: CreateApplicationFileMutateOptions) => void;
  onFileDelete: (fileId: string, isRequired: boolean) => void;
};

export function DocumentDrawerBody(props: Props): React.ReactElement | null {
  const { t } = useTranslation();
  const {
    application,
    requiredFiles,
    otherFiles,
    ErrorAlert,
    isUploading,
    isUploadDisabled,
    onFileUpload,
    onFileDelete,
  } = props;

  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = useCallback((_, newValue: number) => {
    setTabValue(newValue);
  }, []);

  const tabs: TabType[] = [
    { label: t('assistedApplication.documentsDrawer.requiredFiles') },
    { label: t('assistedApplication.documentsDrawer.otherFiles') },
  ];

  return (
    <Box>
      <TabsContainer borderBottom={1} borderTop={1}>
        <Box px={4}>
          <Tabs value={tabValue} onChange={handleTabChange} tabs={tabs} />
        </Box>
      </TabsContainer>

      <Box py={2}>
        {ErrorAlert}
        {tabValue === 0 && (
          <Box px={4}>
            <RequiredFiles
              application={application}
              files={requiredFiles}
              onFileUpload={onFileUpload}
              onFileDelete={onFileDelete}
            />
          </Box>
        )}
        {tabValue === 1 && (
          <OtherFiles
            application={application}
            files={otherFiles}
            isUploading={isUploading}
            isUploadDisabled={isUploadDisabled}
            onFileUpload={onFileUpload}
            onFileDelete={onFileDelete}
          />
        )}
      </Box>
    </Box>
  );
}
