import _ from 'lodash';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { FileTemplate, StoredFileDocType, FileTemplateOrigin } from '@breathelife/types';

import { QueryId } from '../../ReactQuery/common/common.types';
import { findFileTemplates, getFileTemplate } from '../../Services/FileTemplateService';

export function useFindFileTemplatesQuery(
  fetchOptions?: {
    archived?: boolean;
    docType?: StoredFileDocType;
    origin?: FileTemplateOrigin;
  },
  options?: UseQueryOptions<FileTemplate[], Error>
): UseQueryResult<FileTemplate[], Error> {
  return useQuery<FileTemplate[], Error>(
    [QueryId.fileTemplates, fetchOptions?.archived ? 'archived' : 'unarchived'],
    async () => await findFileTemplates(fetchOptions),
    {
      ...options,
      select: (fileTemplates) => _.orderBy(fileTemplates, ['createdAt'], ['desc']),
    }
  );
}

export function useGetFileTemplateQuery(
  templateId: string | null,
  options?: UseQueryOptions<FileTemplate, Error>
): UseQueryResult<FileTemplate, Error> {
  return useQuery<FileTemplate, Error>(
    [QueryId.fileTemplate, templateId],
    async () => {
      if (!templateId) {
        throw new Error('Invalid templateId');
      }
      return await getFileTemplate(templateId);
    },
    {
      enabled: !!templateId,
      ...options,
    }
  );
}
