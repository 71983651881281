import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { Permission, User } from '@breathelife/types';

import { Icon } from '../../../../Components/Icons';
import { Image } from '../../../../Components/Images/Image';
import { CarrierContext } from '../../../../Context/CarrierContext';
import { getUserDisplayName, userHasPermission } from '../../../../Helpers/user';
import { useDispatch, useSelector } from '../../../../Hooks';
import { DefaultLeadsListFilterIds } from '../../../../Pages/Home/Modals/UserListModal/UserListModal';
import { getUsers } from '../../../../Redux/UserManagement/UserManagementOperation';
import { GetUsersOptions } from '../../../../Services/Users/UsersService';
import { LeadPlatformTheme } from '../../../../Styles/Types';
import { UserListModal, UserListModalItem } from './UserListModal';

type LeadsListFilterModalContainerProps = {
  isOpen: boolean;
  closeModal: () => void;
  onModalSubmit: (selectedUserId: string) => void;
  submitLabel: string;
  defaultSelectedUserId: string;
};

export function LeadsListFilterModalContainer(props: LeadsListFilterModalContainerProps): React.ReactElement {
  const { t } = useTranslation();
  const { features } = useContext(CarrierContext);
  const theme = useTheme() as LeadPlatformTheme;
  const dispatch = useDispatch();
  const users = useSelector((store) => store.leadPlatform.userManagement.users);
  const currentUser = useSelector((store) => store.leadPlatform.authentication.user) as User;
  const isLoading = useSelector((store) => store.leadPlatform.userManagement.isLoading);

  const isSearchEnabled = useMemo(
    () => userHasPermission(currentUser.permissions, [Permission.LeadReadAll, Permission.LeadReadGroup]),
    [currentUser.permissions]
  );

  const title = t(
    features.hiddenLeads?.enabled
      ? 'modals.leadsListFilters.filterApplicationsTitle'
      : 'modals.leadsListFilters.filterLeadsTitle'
  );

  const staticItems: UserListModalItem[] = [
    {
      title: t('modals.leadsListFilters.all'),
      subtitle: t(
        features.hiddenLeads?.enabled
          ? 'modals.leadsListFilters.showAllApplications'
          : 'modals.leadsListFilters.showAllLeads'
      ),
      id: DefaultLeadsListFilterIds.All,
      icon: (
        <Icon
          name='allUsersProfilePicture'
          width='30'
          height='30'
          fill={theme.colors.grey[0]}
          stroke={theme.colors.grey[50]}
        />
      ),
    },
    {
      title: t('modals.leadsListFilters.unassigned'),
      subtitle: t(
        features.hiddenLeads?.enabled
          ? 'modals.leadsListFilters.showUnassignedApplications'
          : 'modals.leadsListFilters.showUnassignedLeads'
      ),
      id: DefaultLeadsListFilterIds.Unassigned,
      icon: (
        <Icon
          name='unassignedProfilePicture'
          width='30'
          height='30'
          fill={theme.colors.grey[0]}
          stroke={theme.colors.grey[50]}
        />
      ),
    },
    {
      title: getUserDisplayName(currentUser),
      subtitle: t(
        features.hiddenLeads?.enabled
          ? 'modals.leadsListFilters.showMyApplications'
          : 'modals.leadsListFilters.showMyLeads'
      ),
      id: currentUser.auth0Id,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      email: currentUser.emailLogin,
      icon: currentUser.picture ? <Image src={currentUser.picture} width={30} height={30} /> : null,
    },
  ];

  const fetchUsers = useCallback(
    (fetchUsersOptions: GetUsersOptions) => dispatch(getUsers(fetchUsersOptions)),
    [dispatch]
  );
  const filteredUsers = useMemo(
    () => users.filter((user) => user.auth0Id !== currentUser?.auth0Id),
    [users, currentUser]
  );

  const leadsAssignModelProps = {
    ...props,
    staticItems,
    users: filteredUsers,
    isSearchEnabled,
    title,
    fetchUsers,
    isLoading,
  };

  return <UserListModal {...leadsAssignModelProps} />;
}
