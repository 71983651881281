import React from 'react';

import { useCarrierContext } from '../../../../../Hooks';
import { Application } from '../../../../../Models/Application';
import { Lead } from '../../../../../Models/Lead';
import { DEFAULT_COVERAGE_AMOUNT_STEP, QuotedProduct } from '../../../../../Models/Product';
import { ProductsView } from './ProductsView';

type StaticProductsViewContainerProps = {
  lead: Lead;
  needsAnalysisTabIndex?: number;
  onSendNeedsAnalysis: () => void;
  onViewNeedsAnalysisDetails: () => void;
  onLaunchCoBrowsing: (applicationId: string) => void;
  onToggleIsDeclarationSent: (applicationId: string, isDeclarationSent: boolean) => void;
};

function buildProductFromLeadApplication(application: Application): QuotedProduct {
  return {
    // displayed in the Product.tsx
    shortName: application.product,
    premium: application.premium ?? 0,

    // Arbitrary default values to comply with QuotedProduct type
    id: application.product,
    productId: application.product,
    name: application.product,
    category: '',
    type: '',
    includedFeatures: [],
    excludedFeatures: [],
    featured: true,
    carrierId: '',
    carrierName: '',
    isEligible: true,
  };
}

export function StaticProductsViewContainer(props: StaticProductsViewContainerProps): React.ReactElement {
  const {
    lead,
    needsAnalysisTabIndex,
    onSendNeedsAnalysis,
    onViewNeedsAnalysisDetails,
    onLaunchCoBrowsing,
    onToggleIsDeclarationSent,
  } = props;
  const { coverageAmountStepRounding } = useCarrierContext();
  const coverageAmountStep = coverageAmountStepRounding ?? DEFAULT_COVERAGE_AMOUNT_STEP;

  // TODO: handle multiple applications
  const application = lead.applications?.[0];
  const coverageAmount = application?.coverageAmount ?? 0;

  const quotedProducts = [];
  if (application?.product) {
    quotedProducts.push(buildProductFromLeadApplication(application));
  }

  return (
    <ProductsView
      lead={lead}
      products={quotedProducts}
      applications={lead.applications}
      isLoadingQuotes={false}
      isNeedsAnalysisTabEnabled={!!needsAnalysisTabIndex}
      onViewNeedsAnalysisDetails={onViewNeedsAnalysisDetails}
      onLaunchAssistedApplication={() => {}}
      onLaunchCoBrowsing={onLaunchCoBrowsing}
      onLaunchRedirectToCarrier={() => {}}
      onSendNeedsAnalysis={onSendNeedsAnalysis}
      recommendedCoverageAmount={coverageAmount}
      onToggleIsDeclarationSent={onToggleIsDeclarationSent}
      isCoverageAmountInputEnabled={false}
      hasCustomCoverageAmount={false}
      coverageAmountStep={coverageAmountStep}
    />
  );
}
