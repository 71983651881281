import i18next from 'i18next';
import * as yup from 'yup';

import {
  getEmailSchema,
  getFirstNameSchema,
  getLastNameSchema,
  getPreferredNameSchema,
  getPhoneNumberSchema,
} from '../field';
import { getValidationError } from '../getValidationError';

type FormFields = 'firstName' | 'lastName' | 'preferredName' | 'emailLogin' | 'phoneNumber';

const getSchemaMap = (): { [field in FormFields]: yup.StringSchema } => ({
  firstName: getFirstNameSchema().required(i18next.t('validation.error.required')),
  lastName: getLastNameSchema().required(i18next.t('validation.error.required')),
  preferredName: getPreferredNameSchema(),
  emailLogin: getEmailSchema().required(i18next.t('validation.error.required')),
  phoneNumber: getPhoneNumberSchema().required(i18next.t('validation.error.required')),
});

export const getFormSchema = (): yup.ObjectSchema => yup.object().shape(getSchemaMap());

export function getFieldValidationError(
  fieldName: FormFields,
  value: string | undefined
): yup.ValidationError | undefined {
  return getValidationError(getSchemaMap(), fieldName, value);
}
