import { Box } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { isMappingOf, isNamespace } from '@breathelife/types';

import { EditorDropdown } from '../Questionnaire/QuestionnaireEditor/Components/EditorDropdown';
import { EntityMappingContext } from './EntityMappingContextProvider';

const SidebarMenuWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 15px;
`;

export function EntityMappingSidebar(): React.ReactElement {
  const { t } = useTranslation();

  const {
    availableEntityMappings,
    selectedEntityMappingOptions,
    updateSelectedEntityMappingOptions,
    saveEntityMapping,
    mappingCodeHasChanged,
    availableQuestionnaireVersions,
  } = useContext(EntityMappingContext);

  const mappingsDropdownOptions: string[] | null = useMemo(() => {
    if (selectedEntityMappingOptions.namespace) {
      return (availableEntityMappings[selectedEntityMappingOptions.namespace] as string[]) || [];
    }
    return null;
  }, [selectedEntityMappingOptions.namespace, availableEntityMappings]);

  return (
    <SidebarMenuWrapper>
      <EditorDropdown
        label={t('admin.entityMappings.namespaceDropdownLabel')}
        selectedOptionValue={selectedEntityMappingOptions.namespace}
        options={Object.keys(availableEntityMappings).map((namespace) => ({
          label: namespace,
          value: namespace,
        }))}
        disableClearable={false}
        onChange={(value) => {
          if (value && isNamespace(value)) {
            updateSelectedEntityMappingOptions({ namespace: value });
          }
        }}
      />

      {mappingsDropdownOptions && (
        <EditorDropdown
          label={t('admin.entityMappings.mappingDropdownLabel')}
          selectedOptionValue={selectedEntityMappingOptions.mapping}
          options={mappingsDropdownOptions.map((mapping) => ({
            label: mapping,
            value: mapping,
          }))}
          disableClearable={false}
          onChange={(value) => {
            if (
              value &&
              selectedEntityMappingOptions.namespace &&
              isMappingOf(selectedEntityMappingOptions.namespace, value)
            ) {
              updateSelectedEntityMappingOptions({ mapping: value });
            }
          }}
        />
      )}

      {availableQuestionnaireVersions && (
        <EditorDropdown
          label={t('admin.entityMappings.questionnaireVersionDropdownLabel')}
          selectedOptionValue={selectedEntityMappingOptions.questionnaireVersionId}
          options={availableQuestionnaireVersions.map((questionnaireVersion) => ({
            label: questionnaireVersion.label,
            value: questionnaireVersion.id,
          }))}
          disableClearable={false}
          onChange={(value) => {
            if (value) {
              updateSelectedEntityMappingOptions({ questionnaireVersionId: value });
            }
          }}
        />
      )}

      <Box display='flex' justifyContent='flex-end'>
        <MuiButton onClick={saveEntityMapping} disabled={!mappingCodeHasChanged} variant='contained' color='primary'>
          {t('admin.entityMappings.saveButtonLabel')}
        </MuiButton>
      </Box>
    </SidebarMenuWrapper>
  );
}
