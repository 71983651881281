import React from 'react';

import { BlueprintConditionValue, BooleanOperator, ConsiderationBlueprint } from '@breathelife/types';

import { Drawer } from '../../../../Components/Drawer/Drawer';
import { useSelector } from '../../../../Hooks';
import { useQuestionnaireVersionDetailWithNodeIdInfo } from '../../../../Pages/Admin/Questionnaire/QuestionnaireVersionDataContextProvider';
import { SalesDecisionRuleDetailView } from './SalesDecisionRuleDetailView';

type Props = {
  blueprint: ConsiderationBlueprint;
  identifier: string;
  onBlueprintUpdate: (partialBlueprint: Partial<ConsiderationBlueprint>) => void;
  onConditionUpdate: (data: BlueprintConditionValue, path: string) => void;
  onRuleIdentifierUpdate: (data: string) => void;
  onBooleanOperatorChange: (booleanOperator: BooleanOperator, path: string) => void;
  onAddCondition: (path: string) => void;
  onAddNestedCondition: (path: string) => void;
  onRemoveCondition: (path: string) => void;
  onClickSave: () => void;
  isFormValid: boolean;
  onDrawerClose: () => void;
  onDelete: () => void;
  ruleCanBeDeleted: boolean;
};

export function SalesDecisionRuleDetailContainer({
  blueprint,
  onBlueprintUpdate,
  onClickSave,
  isFormValid,
  onDrawerClose,
  onDelete,
  ruleCanBeDeleted,
  onConditionUpdate,
  onBooleanOperatorChange,
  onAddCondition,
  onAddNestedCondition,
  onRemoveCondition,
  identifier,
  onRuleIdentifierUpdate,
}: Props): React.ReactElement | null {
  const { questionnaireNodeIds } = useQuestionnaireVersionDetailWithNodeIdInfo() || {};

  const { isOpen, type: viewType } = useSelector((store) => store.leadPlatform.layout.rightPanelState);

  if (!isOpen || !questionnaireNodeIds) return null;

  const formState = { ...blueprint, identifier };

  return (
    <Drawer open={isOpen} onClose={onDrawerClose} width='50%'>
      <SalesDecisionRuleDetailView
        onDrawerClose={onDrawerClose}
        formState={formState}
        ruleCanBeDeleted={ruleCanBeDeleted}
        onFormUpdate={onBlueprintUpdate}
        onClickSave={onClickSave}
        isFormValid={isFormValid}
        onDelete={onDelete}
        questionnaireNodeIds={questionnaireNodeIds}
        onConditionUpdate={onConditionUpdate}
        onBooleanOperatorChange={onBooleanOperatorChange}
        onAddCondition={onAddCondition}
        onAddNestedCondition={onAddNestedCondition}
        onRemoveCondition={onRemoveCondition}
        viewType={viewType}
        onRuleIdentifierUpdate={onRuleIdentifierUpdate}
      />
    </Drawer>
  );
}
