import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ReactJsonEditor from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

import { SubmitButton } from '../../../Components/Button/SubmitButton';
import { ModalLayout } from '../../../Layouts/Modal/ModalLayout';

type Props = {
  actionDetail: Record<string, any> | null;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

export function AuditLogDetailsModal(props: Props): React.ReactElement {
  const { actionDetail, isOpen, setIsOpen } = props;
  const { t } = useTranslation();

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <ModalLayout
      maxWidth='md'
      isOpen={isOpen}
      closeModal={onClose}
      title={t('admin.auditLogs.modalTitle')}
      submitButton={
        <SubmitButton
          onClick={() => {
            onClose();
          }}
        >
          Close
        </SubmitButton>
      }
    >
      <Box mb={2}>
        <p>{actionDetail?.message}</p>
      </Box>
      {actionDetail?.updatedProperties && (
        <React.Fragment>
          <Divider />
          <p>{t('admin.auditLogs.modalLabel')}</p>
          <ReactJsonEditor
            placeholder={actionDetail.updatedProperties}
            locale={locale}
            style={{
              body: {
                fontSize: '16px',
                fontFamily: 'monospace !important',
              },
            }}
            readOnly
          />
        </React.Fragment>
      )}
    </ModalLayout>
  );
}
