import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { deserializeNodeIdToAnswerPathMap } from '@breathelife/questionnaire-engine';

import { useDispatch, useNeedsAnalysisProgress, useNeedsAnalysisQuestionnaire } from '../../../../../../../Hooks';
import { Application } from '../../../../../../../Models/Application';
import { LeadStatus } from '../../../../../../../Models/Lead';
import { useFetchQuestionnaireQuery } from '../../../../../../../ReactQuery/Questionnaire/questionnaire.queries';
import { notificationSlice } from '../../../../../../../Redux/Notification/NotificationSlice';
import { PendingInformation } from '../PendingInformation';
import { SectionGroups } from './SectionGroups';

type ApplicationProps = {
  customView?: (props: any) => React.ReactElement | null;
  application: Application;
  leadStatus: LeadStatus;
};

export function ApplicationView(props: ApplicationProps): React.ReactElement | null {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { customView, application, leadStatus } = props;
  const { data: questionnaireData } = useFetchQuestionnaireQuery(application?.id, {
    onError: () =>
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToFetchQuestionnaire'),
        })
      ),
  });
  const questionnaire = questionnaireData?.questionnaire;
  const serializedNodeIdToAnswerPathMap = questionnaireData?.nodeIdToAnswerPath;

  const nodeIdToAnswerPathMap = useMemo(
    () => serializedNodeIdToAnswerPathMap && deserializeNodeIdToAnswerPathMap(serializedNodeIdToAnswerPathMap),
    [serializedNodeIdToAnswerPathMap]
  );

  const needsAnalysisProgress = useNeedsAnalysisProgress({
    application,
    questionnaire,
    nodeIdToAnswerPathMap,
  });
  const renderingQuestionnaire = useNeedsAnalysisQuestionnaire({
    application,
    questionnaire,
    nodeIdToAnswerPathMap,
  });

  const isLeadStatusInvited = leadStatus === 'invited';

  if (isLeadStatusInvited) return <PendingInformation />;

  if (customView) {
    return React.createElement(customView || 'div', {
      steps: application.steps ?? [],
      leadStatus,
      progress: needsAnalysisProgress ?? 0,
      recommendedCoverageAmount: application.recommendedCoverageAmount ?? 0,
      externalData: application.externalData,
    });
  }

  if (!renderingQuestionnaire) return null;

  // TODO: render a loader when the questionnaire is being fetched?
  return <SectionGroups sectionGroups={renderingQuestionnaire} />;
}
