import { Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import RepeatIcon from '@material-ui/icons/Repeat';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

import { Language, SectionGroupBlueprint } from '@breathelife/types';

import { MoreActionButton } from '../../../../../Components/MoreActionButton/MoreActionButton';
import { MenuElement } from '../../../../../Components/PopupMenu/PopupMenu';
import Typography from '../../../../../Components/Typography';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
`;

export function SectionGroupHeader({
  sectionGroupBlueprint,
  selectedLanguage,
  menuActions,
}: {
  sectionGroupBlueprint?: SectionGroupBlueprint;
  selectedLanguage: Language;
  repeatableIcon?: React.ReactElement;
  menuActions: MenuElement[];
}): React.ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();

  const title = sectionGroupBlueprint?.title?.[selectedLanguage]?.toUpperCase();
  const titleColor = theme.colors.grey[60];

  const isRepeatIconVisible = Number(sectionGroupBlueprint?.repeatable?.maxRepeatable) >= 2;
  const repeatIconCount = sectionGroupBlueprint?.repeatable?.maxRepeatable;
  const repeatIconTooltipText = t('admin.questionnaireManagement.input.numberOfInsured');

  return (
    <Container>
      <Box display={'flex'}>
        <Typography variant='h2' color={titleColor}>
          {title}
        </Typography>

        {isRepeatIconVisible && (
          <Tooltip title={repeatIconTooltipText} aria-label='Repeatable field group' placement='top'>
            <Box display='flex' ml={1}>
              <RepeatIcon fontSize='small' />
              {repeatIconCount}
            </Box>
          </Tooltip>
        )}
      </Box>

      <div>
        <MoreActionButton actions={menuActions} />
      </div>
    </Container>
  );
}
