import Box from '@material-ui/core/Box';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Permission, StoredFile } from '@breathelife/types';
import { Loader } from '@breathelife/ui-components';

import { IconButton } from '../../../../../../Components/Controls';
import { AttachmentIcon, Icon } from '../../../../../../Components/Icons';
import { RestrictedToPermission } from '../../../../../../Components/Restricted/RestrictedToPermission';
import Typography from '../../../../../../Components/Typography';
import { formatDate, guessTimezone } from '../../../../../../Helpers/datetime';
import { printFileSize } from '../../../../../../Helpers/fileSize';
import { useGetApplicationFile } from '../../../../../../Hooks';
import { Application } from '../../../../../../Models/Application';
import { DisplayNameTypography, SubtitleContainer } from '../Styles';
import { ListItem } from './Styles';

type Props = {
  application: Application;
  storedFile: StoredFile;
  onFileDelete: (fileId: string, isRequired: boolean) => void;
};

export function OtherFileItem(props: Props): React.ReactElement {
  const { application, storedFile, onFileDelete } = props;
  const { t } = useTranslation();

  const { fetchFile, fileToView } = useGetApplicationFile(
    application.id,
    storedFile.id,
    useCallback(({ signedUrl }) => {
      window.open(signedUrl, '_blank')?.focus();
    }, [])
  );

  const iconName = useMemo(() => {
    switch (storedFile.mimeType) {
      case 'application/pdf':
        return 'pdf';

      default:
        return null;
    }
  }, [storedFile.mimeType]);

  const sizeLabel = useMemo(() => {
    if (storedFile.sizeBytes) {
      return printFileSize(storedFile.sizeBytes, 1);
    }

    return '';
  }, [storedFile.sizeBytes]);

  const dateLabel = useMemo(() => {
    const formattedDate = formatDate(storedFile.createdAt, 'D/M/YYYY', guessTimezone());
    return t('modals.assistedApplication.fileAttachment.uploadedOn', { date: formattedDate });
  }, [storedFile.createdAt, t]);

  const onDeleteStoredFile = useCallback(async () => {
    onFileDelete(storedFile.id, false);
  }, [storedFile.id, onFileDelete]);

  const isLoading: boolean = fileToView.isLoading;

  return (
    <ListItem display='flex' width='100%' px={2} py={1}>
      <Box display='flex' alignItems='center' flexGrow={1}>
        {isLoading && (
          <Box mr={2}>
            <Loader size='24' />
          </Box>
        )}
        {!isLoading && iconName && (
          <Box mr={2}>
            <AttachmentIcon name={iconName} />
          </Box>
        )}
        <Box display='flex' flexDirection='column' flexGrow={1}>
          <Box>
            <DisplayNameTypography variant='body3'>{storedFile.displayName}</DisplayNameTypography>
          </Box>
          <SubtitleContainer display='flex'>
            <Box mr={1}>
              <Typography variant='body5'>{sizeLabel}</Typography>
            </Box>
            <Box>
              <Typography variant='body5'>{dateLabel}</Typography>
            </Box>
          </SubtitleContainer>
        </Box>
      </Box>
      <Box display='flex' flexWrap='no-wrap' alignItems='center'>
        <Box mx={1}>
          <IconButton icon={<Icon name='eye' />} disabled={isLoading} onClick={fetchFile} />
        </Box>
        {!application.submitted && (
          <RestrictedToPermission permission={[Permission.ApplicationFileRemove]}>
            <Box>
              <IconButton icon={<Icon name='delete' />} disabled={isLoading} onClick={onDeleteStoredFile} />
            </Box>
          </RestrictedToPermission>
        )}
      </Box>
    </ListItem>
  );
}
