import {
  BlueprintSingleConditionValue,
  ComparisonOperator,
  ConditionBlueprintType,
  DateUnit,
  MatchesConditionPropertyQuantifier,
  MathConditionOperator,
  NumberComparisonConditionOperator,
  YesNoValue,
} from '@breathelife/types';

const DEFAULT_NODE_ID = '';

export function getEmptyStateForConditionType(type: ConditionBlueprintType): BlueprintSingleConditionValue {
  switch (type) {
    case ConditionBlueprintType.reflexive: {
      return {
        type: ConditionBlueprintType.reflexive,
        value: YesNoValue.yes,
        targetNodeId: DEFAULT_NODE_ID,
      };
    }
    case ConditionBlueprintType.ageRange: {
      return {
        type: ConditionBlueprintType.ageRange,
        value: {
          minAge: 0,
          maxAge: 99,
          unit: DateUnit.year,
        },
        targetBirthdateNodeId: DEFAULT_NODE_ID,
      };
    }
    case ConditionBlueprintType.characterCountInBetween: {
      return {
        type: ConditionBlueprintType.characterCountInBetween,
        value: {
          minLength: 1,
        },
        targetNodeId: DEFAULT_NODE_ID,
      };
    }
    case ConditionBlueprintType.bmiRange: {
      return {
        type: ConditionBlueprintType.bmiRange,
        value: {
          minBMI: 18,
          maxBMI: 35,
        },
        heightNodeId: DEFAULT_NODE_ID,
        weightNodeId: DEFAULT_NODE_ID,
        heightUnit: 'inch',
        weightUnit: 'lb',
      };
    }
    case ConditionBlueprintType.equality: {
      return {
        type: ConditionBlueprintType.equality,
        value: '',
        isEqual: true,
        targetNodeId: DEFAULT_NODE_ID,
      };
    }
    case ConditionBlueprintType.emptiness: {
      return {
        type: ConditionBlueprintType.emptiness,
        isEmpty: true,
        targetNodeId: DEFAULT_NODE_ID,
      };
    }
    case ConditionBlueprintType.numberComparison: {
      return {
        type: ConditionBlueprintType.numberComparison,
        value: 0,
        targetNodeId: DEFAULT_NODE_ID,
        operator: NumberComparisonConditionOperator.greaterThan,
      };
    }
    case ConditionBlueprintType.matches: {
      return {
        type: ConditionBlueprintType.matches,
        value: [],
        targetNodeId: DEFAULT_NODE_ID,
        quantifier: MatchesConditionPropertyQuantifier.any,
      };
    }
    case ConditionBlueprintType.mathOperator: {
      return {
        type: ConditionBlueprintType.mathOperator,
        value: 0,
        nodeIds: [],
        operator: NumberComparisonConditionOperator.greaterThan,
        mathOperator: MathConditionOperator.sum,
      };
    }
    case ConditionBlueprintType.lastIncidentDate: {
      return {
        type: ConditionBlueprintType.lastIncidentDate,
        value: 0,
        targetNodeId: DEFAULT_NODE_ID,
        unit: DateUnit.year,
        operator: NumberComparisonConditionOperator.lessThan,
      };
    }
    case ConditionBlueprintType.dateComparison: {
      return {
        type: ConditionBlueprintType.dateComparison,
        startDateNodeId: DEFAULT_NODE_ID,
        endDateNodeId: DEFAULT_NODE_ID,
        operator: NumberComparisonConditionOperator.lessThan,
        value: 0,
        unit: DateUnit.year,
      };
    }
    case ConditionBlueprintType.countEqual: {
      return {
        type: ConditionBlueprintType.countEqual,
        targetNodeId: DEFAULT_NODE_ID,
        controlValue: 'no',
        operator: NumberComparisonConditionOperator.greaterThan,
        value: 0,
      };
    }
    case ConditionBlueprintType.instancesCount: {
      return {
        type: ConditionBlueprintType.instancesCount,
        nodeIdsToAdd: [],
        nodeIdsToSubtract: [],
      };
    }
    case ConditionBlueprintType.jointProductAgeRange: {
      return {
        type: ConditionBlueprintType.jointProductAgeRange,

        jointProductNodeIds: [],
        insuredBirthdayNodeId: DEFAULT_NODE_ID,
        repeatableInsuredCollectionId: DEFAULT_NODE_ID,
        value: {
          maxAge: 0,
          unit: DateUnit.year,
        },
      };
    }
    case ConditionBlueprintType.percentOf: {
      return {
        type: ConditionBlueprintType.percentOf,
        targetNodeId: DEFAULT_NODE_ID,
        percent: 100,
        operator: NumberComparisonConditionOperator.lessThanOrEqual,
        value: 0,
      };
    }
    case ConditionBlueprintType.engineCondition: {
      return {
        type: ConditionBlueprintType.engineCondition,
        condition: {
          operator: ComparisonOperator.equal,
        },
      };
    }
  }
}

export const defaultSingleConditionBlueprintValue = (): BlueprintSingleConditionValue =>
  getEmptyStateForConditionType(ConditionBlueprintType.reflexive);
