import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  isFieldBlueprint,
  isQuestionBlueprint,
  isSelectOptionBlueprint,
  isSubsectionBlueprint,
  PartIdentifier,
  QuestionnaireElementBlueprint,
  SelectOptionBlueprint,
} from '@breathelife/types';

import {
  makeTextHighlightFragmentsFromIndices,
  HighlightedTextFragmentsByPropertyName,
  getStartIndicesOfTermInString,
  SearchablePropertyNames,
  QuestionnaireSearchResult,
} from '../../../../../Helpers/questionnaireEditor/questionnaireSearch';
import { QuestionnaireEditorContext } from '../../QuestionnaireEditorContextProvider';
import { arePartIdentifiersTheSame } from '../Helpers/arePartIdentifiersTheSame';
import { getBlueprintVariantName } from '../Helpers/getBlueprintvariantName';
import { QuestionnaireTreeViewSearchContext } from '../TreeView/QuestionnaireTreeViewSearchContextProvider';

export function useHighlightedTextInBlueprintElement({
  partIdentifier,
  blueprint,
}: {
  blueprint: QuestionnaireElementBlueprint | SelectOptionBlueprint;
  partIdentifier: PartIdentifier;
}): HighlightedTextFragmentsByPropertyName {
  const { searchResults, searchTerm } = useContext(QuestionnaireTreeViewSearchContext);
  const { selectedLanguage } = useContext(QuestionnaireEditorContext);
  const { t } = useTranslation();

  const textFragments: HighlightedTextFragmentsByPropertyName = {};

  if (!selectedLanguage) {
    return textFragments;
  }

  const addPropertyToTextFragments = (
    fragmentsRecord: HighlightedTextFragmentsByPropertyName,
    text: string,
    propertyName: SearchablePropertyNames
  ): void => {
    fragmentsRecord[propertyName] = makeTextHighlightFragmentsFromIndices(
      text,
      searchTerm.length,
      getStartIndicesOfTermInString(text.toLowerCase(), searchTerm.toLowerCase())
    );
  };

  const propertyIsInSearchResult = (
    result: QuestionnaireSearchResult,
    propertyName: SearchablePropertyNames
  ): boolean => !!result.propertiesContainingSearchTerm.includes(propertyName);

  searchResults.some((result) => {
    if (arePartIdentifiersTheSame(result.identifier, partIdentifier)) {
      if (propertyIsInSearchResult(result, SearchablePropertyNames.text) && blueprint.text?.[selectedLanguage]) {
        addPropertyToTextFragments(
          textFragments,
          blueprint.text?.[selectedLanguage] || '',
          SearchablePropertyNames.text
        );
      }

      if (isSelectOptionBlueprint(blueprint)) {
        return;
      }

      if (propertyIsInSearchResult(result, SearchablePropertyNames.title) && blueprint.title?.[selectedLanguage]) {
        addPropertyToTextFragments(
          textFragments,
          blueprint.title?.[selectedLanguage] || '',
          SearchablePropertyNames.title
        );
      }

      if (
        propertyIsInSearchResult(result, SearchablePropertyNames.variant) &&
        isSubsectionBlueprint(blueprint) &&
        blueprint.variant
      ) {
        const variantName = getBlueprintVariantName(blueprint.variant, t);
        addPropertyToTextFragments(textFragments, variantName || '', SearchablePropertyNames.variant);
      }
      if (
        propertyIsInSearchResult(result, SearchablePropertyNames.repeatableAnswerNodeId) &&
        isQuestionBlueprint(blueprint) &&
        blueprint.repeatable?.repeatableAnswerNodeId
      ) {
        addPropertyToTextFragments(
          textFragments,
          blueprint.repeatable.repeatableAnswerNodeId,
          SearchablePropertyNames.repeatableAnswerNodeId
        );
      }
      if (
        propertyIsInSearchResult(result, SearchablePropertyNames.answerNodeId) &&
        isFieldBlueprint(blueprint) &&
        blueprint.answerNodeId
      ) {
        addPropertyToTextFragments(textFragments, blueprint.answerNodeId, SearchablePropertyNames.answerNodeId);
      }
      return true;
    }
    return false;
  });

  return textFragments;
}
