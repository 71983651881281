import Box from '@material-ui/core/Box';
import { captureException } from '@sentry/browser';
import { Auth0Error } from 'auth0-js';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { User } from '@breathelife/types';
import { Loader } from '@breathelife/ui-components';

import Typography from '../../../Components/Typography';
import { clearSearchParameters } from '../../../Helpers/login';
import { usePlatformLanguage, useSelector } from '../../../Hooks';
import { setAuthorizationInterceptor } from '../../../Services/ApiService';
import { AUTH_USER_BLOCKED, Authentication, isSsoUser, parseUrlHash } from '../../../Services/Auth0';
import { LogoFooter } from '../Styles';
import { AuthCallbackContainer, AuthCallbackContent } from './Styles';

type Props = {
  handleSsoCallback: (token: string, user: Pick<User, 'auth0Id'>, lang?: string) => void;
  handleRegularCallback: (token: string, user: Pick<User, 'auth0Id'>, lang?: string) => void;
  redirectToPlatform: (route?: string) => void;
};

function AuthCallbackView(props: Props): React.ReactElement | null {
  const { handleRegularCallback, redirectToPlatform, handleSsoCallback } = props;
  const { t } = useTranslation();
  const [error, setError] = useState<Auth0Error>();
  const [token, setToken] = useState<Authentication>();
  const errorState = useSelector((store) => store.leadPlatform.notification);
  const authentication = useSelector((store) => store.leadPlatform.authentication);
  const lang = usePlatformLanguage();

  useEffect(() => {
    let isCancelled = false;

    const handleCallback = async (): Promise<void> => {
      const token = await parseUrlHash();
      setToken(token);
      setAuthorizationInterceptor(token.jwtToken);
      if (isSsoUser(token.user)) {
        handleSsoCallback(token.jwtToken, token.user, lang);
      } else {
        handleRegularCallback(token.jwtToken, token.user, lang);
      }
    };
    handleCallback().catch((error: Auth0Error) => {
      if (!isCancelled) {
        const errorDescription = error?.errorDescription || error?.error_description || error?.description || '';
        captureException(Error(`${error?.error}: ${errorDescription}`));
        setError(error);
      }
    });

    return () => {
      isCancelled = true;
    };
  }, [t, handleRegularCallback, handleSsoCallback, lang]);

  useEffect(() => {
    if (authentication.isAuthenticated && authentication.user && token) {
      const targetRoute = token.targetRoute ? clearSearchParameters(token.targetRoute) : undefined;
      redirectToPlatform(targetRoute);
    }
  }, [authentication, redirectToPlatform, token]);

  return (
    <AuthCallbackContainer display='flex'>
      <AuthCallbackContent margin='auto' p={4}>
        <Box mb={4}>
          <Typography variant='h1'>
            {!error && t('authentication.loggingIn')}
            {error &&
              (error.error === AUTH_USER_BLOCKED
                ? t('authentication.userBlockedTitle')
                : t('authentication.generalErrorTitle'))}
          </Typography>
        </Box>

        {!error && _.isEmpty(errorState) && <Loader size='50px' />}

        {(error || !_.isEmpty(errorState)) && (
          <Box mb={2}>
            <Typography variant='body1' grey={80}>
              {error?.error === AUTH_USER_BLOCKED
                ? t('authentication.userBlockedText')
                : t('authentication.generalErrorText')}
            </Typography>
          </Box>
        )}

        <Box textAlign='center' mt={5}>
          <Typography variant='small2' grey={50}>
            {t('authentication.softwareBy')}&nbsp;
            <LogoFooter role='img' aria-label='Breathe Life' />
          </Typography>
        </Box>
      </AuthCallbackContent>
    </AuthCallbackContainer>
  );
}

export default AuthCallbackView;
