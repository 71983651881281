import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { submitUrlForm } from '../../../../../Helpers/submitForm';
import { useCarrierContext, useDispatch, useNavigation } from '../../../../../Hooks';
import { Application } from '../../../../../Models/Application';
import { Lead } from '../../../../../Models/Lead';
import { LaunchApplicationType } from '../../../../../Models/PlatformFeatures';
import {
  useLaunchApplicationCoBrowsingMutation,
  useSubmitApplicationNeedsAnalysisMutation,
  useUpdateApplicationIsDeclarationSentMutation,
} from '../../../../../ReactQuery/Application/application.mutations';
import { navigateToAssistedApplication } from '../../../../../Redux/Navigation/NavigationOperations';
import { notificationSlice } from '../../../../../Redux/Notification/NotificationSlice';
import { ProductHubViewContainer } from './ProductHubViewContainer';
import { StaticProductsViewContainer } from './StaticProductsViewContainer';

type Props = {
  lead?: Lead;
  needsAnalysisTabIndex?: number;
  setSelectedTabIndex: (index: number) => void;
  onSendNeedsAnalysis: (lead: Lead) => void;
};

export function ProductsViewContainer(props: Props): React.ReactElement | null {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { features } = useCarrierContext();
  const { leadTab } = useNavigation();
  const isProductHubEnabled = features.productHub.enabled;

  const { lead, needsAnalysisTabIndex, setSelectedTabIndex, onSendNeedsAnalysis: onSendNeedsAnalysisProp } = props;

  const onViewNeedsAnalysisDetails = useCallback(() => {
    if (needsAnalysisTabIndex) {
      setSelectedTabIndex(needsAnalysisTabIndex);
    }
  }, [setSelectedTabIndex, needsAnalysisTabIndex]);

  const onLaunchAssistedApplication = useCallback(
    (application: Application) => {
      if (!features.launchApplication.enabled || !application || !application.leadId) return;
      if (features.launchApplication.type !== LaunchApplicationType.assistedApplication) return;
      dispatch(navigateToAssistedApplication(application.id, application.leadId, leadTab));
    },
    [dispatch, features, leadTab]
  );

  const launchApplicationCoBrowsingMutation = useLaunchApplicationCoBrowsingMutation({
    onSuccess: (linkToken, applicationId) => {
      window.open(`${linkToken}&method=co-browsing`, `childPage-${applicationId}`);
    },
  });

  const onLaunchCoBrowsing = useCallback(
    (applicationId: string) => {
      if (!features.launchApplication.enabled || !applicationId) return;
      if (features.launchApplication.type !== LaunchApplicationType.coBrowsing) return;
      launchApplicationCoBrowsingMutation.mutate(applicationId);
      return;
    },
    [features, launchApplicationCoBrowsingMutation]
  );

  const submitApplicationNeedsAnalysisMutation = useSubmitApplicationNeedsAnalysisMutation({
    onSuccess: (data) => {
      if (data.redirectUrl) {
        submitUrlForm(data.redirectUrl);
      } else {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToSubmitApplicationAndRedirect'),
          })
        );
      }
    },
  });

  const onLaunchRedirectToCarrier = useCallback(
    (applicationId: string, productId: string, coverageAmount: number, premium: number) => {
      if (!features.launchApplication.enabled || !applicationId) return;
      if (
        features.launchApplication.type !== LaunchApplicationType.redirectToCarrier ||
        !productId ||
        !coverageAmount ||
        !premium
      ) {
        return;
      }
      submitApplicationNeedsAnalysisMutation.mutate({ applicationId, productId, coverageAmount, premium });
    },
    [features, submitApplicationNeedsAnalysisMutation]
  );

  const updateApplicationIsDeclarationSentMutation = useUpdateApplicationIsDeclarationSentMutation();

  function onToggleIsDeclarationSent(applicationId: string, isDeclarationSent: boolean): void {
    updateApplicationIsDeclarationSentMutation.mutate({ applicationId, isDeclarationSent });
  }

  const onSendNeedsAnalysis = useCallback(() => {
    if (!lead) return;
    onSendNeedsAnalysisProp(lead);
  }, [lead, onSendNeedsAnalysisProp]);

  if (!lead) return null;
  if (!isProductHubEnabled) {
    return (
      <StaticProductsViewContainer
        lead={lead}
        needsAnalysisTabIndex={needsAnalysisTabIndex}
        onSendNeedsAnalysis={onSendNeedsAnalysis}
        onViewNeedsAnalysisDetails={onViewNeedsAnalysisDetails}
        onLaunchCoBrowsing={onLaunchCoBrowsing}
        onToggleIsDeclarationSent={onToggleIsDeclarationSent}
      />
    );
  }
  return (
    <ProductHubViewContainer
      lead={lead}
      needsAnalysisTabIndex={needsAnalysisTabIndex}
      onSendNeedsAnalysis={onSendNeedsAnalysis}
      onViewNeedsAnalysisDetails={onViewNeedsAnalysisDetails}
      onLaunchRedirectToCarrier={onLaunchRedirectToCarrier}
      onLaunchAssistedApplication={onLaunchAssistedApplication}
      onToggleIsDeclarationSent={onToggleIsDeclarationSent}
    />
  );
}
