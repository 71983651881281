import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from '@breathelife/types';

type LoadingPayload = {
  isLoading: boolean;
};

type UsersPayload = {
  users: User[];
};

type TotalPayload = {
  total: number;
};

type UserPayload = {
  user: User;
};

type SelectedUserIdsPayload = {
  selectedUserIds: string[];
};

export type UserManagementState = LoadingPayload & UsersPayload & TotalPayload & SelectedUserIdsPayload;

type ActionsType = {
  reset: () => void;
  setIsLoading: (state: UserManagementState, { payload }: PayloadAction<LoadingPayload>) => void;
  setUsers: (state: UserManagementState, { payload }: PayloadAction<UsersPayload>) => void;
  setTotal: (state: UserManagementState, { payload }: PayloadAction<TotalPayload>) => void;
  addUser: (state: UserManagementState, { payload }: PayloadAction<UserPayload>) => void;
  removeUser: (state: UserManagementState, { payload }: PayloadAction<UserPayload>) => void;
  setSelectedUserIds: (state: UserManagementState, { payload }: PayloadAction<SelectedUserIdsPayload>) => void;
  updateUser: (state: UserManagementState, { payload }: PayloadAction<UserPayload>) => void;
};

const defaultState = {
  isLoading: false,
  users: [],
  selectedUserIds: [],
  total: 0,
};

export const userManagementSlice = createSlice<UserManagementState, ActionsType>({
  name: 'leadPlatform/userManagement',
  initialState: defaultState,
  reducers: {
    reset: (): UserManagementState => defaultState,
    setIsLoading: (state: UserManagementState, { payload }: PayloadAction<LoadingPayload>) => {
      state.isLoading = payload.isLoading;
    },
    setUsers: (state: UserManagementState, { payload }: PayloadAction<UsersPayload>) => {
      state.users = payload.users;
    },
    setTotal: (state: UserManagementState, { payload }: PayloadAction<TotalPayload>) => {
      state.total = payload.total;
    },
    addUser: (state: UserManagementState, { payload }: PayloadAction<UserPayload>) => {
      state.users = [payload.user].concat(state.users);
      state.total = state.total + 1;
    },
    removeUser: (state: UserManagementState, { payload }: PayloadAction<UserPayload>) => {
      state.users = state.users.filter((user) => user.auth0Id != payload.user.auth0Id);
      state.total = state.total - 1;
    },
    setSelectedUserIds: (state: UserManagementState, { payload }: PayloadAction<SelectedUserIdsPayload>) => {
      state.selectedUserIds = payload.selectedUserIds;
    },
    updateUser: (state: UserManagementState, { payload }: PayloadAction<UserPayload>) => {
      state.users = state.users.map((user) =>
        user.auth0Id === payload.user.auth0Id ? { ...user, ...payload.user } : user
      );
    },
  },
});
