import { NodeIds } from '@breathelife/insurance-form-builder';
import { QuoterSubsectionBlueprintVariant, SubsectionVariant, SubsectionVariantBlueprint } from '@breathelife/types';

export function buildSubsectionVariantBlueprint(
  type: string,
  options: { isSimpleQuoter: boolean; submissionVariantHideHeader: boolean; submissionVariantDisplayInline: boolean }
): SubsectionVariantBlueprint | undefined {
  switch (type) {
    case SubsectionVariant.summary:
    case SubsectionVariant.addon:
    case SubsectionVariant.payment:
    case SubsectionVariant.productSummary:
    case SubsectionVariant.productSelection:
      return { type };

    case SubsectionVariant.quoter:
      return buildQuoterSubsectionBlueprint(options.isSimpleQuoter);

    case SubsectionVariant.submission:
      return {
        type: SubsectionVariant.submission,
        displayInline: options.submissionVariantDisplayInline,
        hideHeader: options.submissionVariantHideHeader,
      };

    default:
      return undefined;
  }
}

function buildQuoterSubsectionBlueprint(isSimpleQuoter: boolean): QuoterSubsectionBlueprintVariant {
  const quoterSubsection: QuoterSubsectionBlueprintVariant = {
    type: SubsectionVariant.quoter,
    productNodeIds: {
      product: NodeIds.productId,
      coverageAmount: NodeIds.coverageAmount,
    },
  };

  if (!isSimpleQuoter) return quoterSubsection;

  const simpleQuoterSubsection = {
    ...quoterSubsection,
    simpleQuoter: {
      useNeedsAnalysisNodeId: NodeIds.useNeedsAnalysis,
    },
  };

  return simpleQuoterSubsection;
}
