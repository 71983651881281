import React from 'react';

import Typography from '../../../../../Components/Typography';
import { NoFileContainer, NoFileIcon } from './Styles';

type Props = {
  message: string;
};

export function NoFiles(props: Props): React.ReactElement {
  return (
    <NoFileContainer>
      <NoFileIcon />
      <Typography color={'primary'} variant={'body1'}>
        {props.message}
      </Typography>
    </NoFileContainer>
  );
}
