import axios from 'axios';

import config from '@breathelife/config/frontend';
import {
  addErrorRedirectInterceptor,
  addJwtIntoHeaderInterceptor,
  addProductIntoHeaderInterceptor,
  addTraceIntoHeaderInterceptor,
  Gateway,
  sentryInterceptor,
} from '@breathelife/gateway';

import { getTraceId } from '../Helpers/monitoring';
import Urls from '../Navigation/Urls';

const ApiService = axios.create({
  timeout: 30000,
});

const errorRedirects = { 401: Urls.login };
const traceId = getTraceId();

// TODO: DEV-3296 use ProductName.LeadPlatform from @direct/frontend-common/dist/Monitoring
ApiService.interceptors.request.use(addProductIntoHeaderInterceptor('lead-platform'));
ApiService.interceptors.request.use(addTraceIntoHeaderInterceptor(traceId));
ApiService.interceptors.response.use((response) => response, addErrorRedirectInterceptor(errorRedirects));
ApiService.interceptors.response.use((response) => response, sentryInterceptor);

// Add header authorization token
let authorizationInterceptorIndex: number;
export function setAuthorizationInterceptor(token?: string): void {
  if (typeof authorizationInterceptorIndex !== 'undefined') {
    ApiService.interceptors.request.eject(authorizationInterceptorIndex);
  }

  authorizationInterceptorIndex = ApiService.interceptors.request.use(addJwtIntoHeaderInterceptor(token));
}

const releaseVersion = config.get<string>('git.commitSha');

export default new Gateway(releaseVersion, ApiService);
