import _ from 'lodash';
import React from 'react';
import styled, { useTheme } from 'styled-components';

import { AddAssuredIcon } from '../../Assets/AddAssuredIcon';
import { AddIcon } from '../../Assets/AddIcon';
import { DownloadIcon } from '../../Assets/DownloadIcon';
import { EyeIcon } from '../../Assets/EyeIcon';
import { LoginKeyIcon } from '../../Assets/LoginKeyIcon';
import { LogoBreatheLifeSmall } from '../../Assets/LogoBreatheLifeSmall';
import { ReactComponent as ArchiveIcon } from '../../Assets/archive-icon.svg';
import { ReactComponent as ArrowDown } from '../../Assets/arrow-down-icon.svg';
import { ReactComponent as AssignToIcon } from '../../Assets/assign-icon.svg';
import { ReactComponent as BlockUserIcon } from '../../Assets/block-user-icon.svg';
import { ReactComponent as ChevronDownIcon } from '../../Assets/chevron-down-icon.svg';
import { ReactComponent as CircledCheckMark } from '../../Assets/circled-checkmark.svg';
import { ReactComponent as CircledWarning } from '../../Assets/circled-warning.svg';
import { ReactComponent as CircleFilledWarning } from '../../Assets/circlefilled-warning-icon.svg';
import { ReactComponent as CloudDownloadIcon } from '../../Assets/cloud-download-icon.svg';
import { ReactComponent as CopyIcon } from '../../Assets/copy-icon.svg';
import { ReactComponent as DeleteIcon } from '../../Assets/delete-icon.svg';
import { ReactComponent as EmailIcon } from '../../Assets/email-icon.svg';
import { ReactComponent as EmailSubscriptionStatusIcon } from '../../Assets/email-subscription-status.svg';
import { ReactComponent as ErrorIcon } from '../../Assets/error-icon.svg';
import { ReactComponent as FilesIcon } from '../../Assets/files-icon.svg';
import { ReactComponent as IdCheck } from '../../Assets/id-check.svg';
import { ReactComponent as InfoIcon } from '../../Assets/info-icon.svg';
import { ReactComponent as LeftArrow } from '../../Assets/left-arrow.svg';
import { ReactComponent as LicensesIcon } from '../../Assets/licenses-icon.svg';
import { ReactComponent as LinkIcon } from '../../Assets/link-icon.svg';
import { ReactComponent as LogoutIcon } from '../../Assets/logout-icon.svg';
import { ReactComponent as MuiVisibilityOff } from '../../Assets/mui-visibility-off.svg';
import { ReactComponent as MuiVisibility } from '../../Assets/mui-visibility.svg';
import { ReactComponent as NotificationIcon } from '../../Assets/notification-icon.svg';
import { ReactComponent as PdfIcon } from '../../Assets/pdf-icon.svg';
import { ReactComponent as ProfileIcon } from '../../Assets/profile-icon.svg';
import { ReactComponent as PushLeftIcon } from '../../Assets/push-left-icon.svg';
import { ReactComponent as PushRightIcon } from '../../Assets/push-right-icon.svg';
import { ReactComponent as RefreshIcon } from '../../Assets/refresh.svg';
import { ReactComponent as RightArrow } from '../../Assets/right-arrow.svg';
import { ReactComponent as SuccessIcon } from '../../Assets/success-icon.svg';
import { ReactComponent as UnarchiveIcon } from '../../Assets/unarchive-icon.svg';
import { ReactComponent as UnassignedProfilePicture } from '../../Assets/unassigned-profile-picture.svg';
import { ReactComponent as UnblockUserIcon } from '../../Assets/unblock-user-icon.svg';
import { ReactComponent as UploadIcon } from '../../Assets/upload-icon.svg';
import { ReactComponent as WarningIcon } from '../../Assets/warning-icon.svg';
import { LeadPlatformTheme, PlatformColorList } from '../../Styles/Types';
import { Variant } from './types';

export const IconMap = {
  visibility: MuiVisibility,
  visibilityOff: MuiVisibilityOff,
  add: AddIcon,
  addAssured: AddAssuredIcon,
  allUsersProfilePicture: UnassignedProfilePicture,
  archive: ArchiveIcon,
  assignTo: AssignToIcon,
  breatheLifeLogoSmall: LogoBreatheLifeSmall,
  loginKey: LoginKeyIcon,
  copy: CopyIcon,
  download: DownloadIcon,
  delete: DeleteIcon,
  email: EmailIcon,
  emailSubscriptionStatus: EmailSubscriptionStatusIcon,
  error: ErrorIcon,
  eye: EyeIcon,
  files: FilesIcon,
  filledCaret: ArrowDown,
  info: InfoIcon,
  link: LinkIcon,
  logout: LogoutIcon,
  pdf: PdfIcon,
  profile: ProfileIcon,
  success: SuccessIcon,
  unarchive: UnarchiveIcon,
  unassignedProfilePicture: UnassignedProfilePicture,
  upload: UploadIcon,
  warning: WarningIcon,
  blockUser: BlockUserIcon,
  unblockUser: UnblockUserIcon,
  notification: NotificationIcon,
  leftArrow: LeftArrow,
  rightArrow: RightArrow,
  idCheck: IdCheck,
  circleFilledWarning: CircleFilledWarning,
  circledCheckMark: CircledCheckMark,
  circledWarning: CircledWarning,
  pushLeft: PushLeftIcon,
  pushRight: PushRightIcon,
  chevronDown: ChevronDownIcon,
  refresh: RefreshIcon,
  licensesIcon: LicensesIcon,
  cloudDownload: CloudDownloadIcon,
};

type IconProps = {
  name: keyof typeof IconMap;
  variant?: Variant | PlatformColorList | 'primary-inverted' | 'secondary-inverted' | 'dark';
  stroke?: string;
  fill?: string;
  alt?: string;
  rotation?: number;
  width?: string;
  height?: string;
  className?: string;
  id?: string;
  title?: string;
  inverted?: boolean;
};

const handleVariant = (
  theme: LeadPlatformTheme,
  variant?: Variant | PlatformColorList | 'primary-inverted' | 'secondary-inverted' | 'dark',
  inverted?: boolean
): { stroke: string; fill: string } => {
  let stroke = theme.colors.grey[inverted ? 20 : 70];
  let fill = theme.colors.grey[inverted ? 70 : 20];

  switch (variant) {
    case 'light':
      stroke = theme.colors.grey[inverted ? 0 : 50];
      fill = theme.colors.grey[inverted ? 50 : 0];
      break;
    case 'dark':
      stroke = theme.colors.grey[100];
      fill = theme.colors.grey[100];
      break;
    case 'primary':
    case 'secondary':
    case 'green':
    case 'red':
    case 'yellow':
    case 'blue':
    case 'purple':
    case 'grey':
    case 'orange':
      stroke = theme.colors[variant][inverted ? 20 : 50];
      fill = theme.colors[variant][inverted ? 50 : 20];
      break;
    case 'primary-inverted':
      stroke = theme.colors.grey[inverted ? 40 : 0];
      fill = theme.colors.primary[inverted ? 0 : 40];
      break;
    case 'secondary-inverted':
      stroke = theme.colors.grey[inverted ? 40 : 0];
      fill = theme.colors.secondary[inverted ? 0 : 40];
      break;
  }

  return { fill, stroke };
};

const IconContainer = styled.div<{ rotation?: number; width?: string; height?: string }>`
  transform: ${(props) => `rotateX(${props.rotation ?? 0}deg)`};
  transition: transform ${(props) => `${props.theme.animation.speed.fast} ${props.theme.animation.transition}`};
  display: inline-flex;
  svg {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
  }
`;

export function Icon(props: IconProps): React.ReactElement {
  const theme = useTheme() as LeadPlatformTheme;
  let { stroke, fill } = handleVariant(theme, props.variant, props.inverted);

  if (props.stroke) stroke = props.stroke;
  if (props.fill) fill = props.fill;

  const IconToRender = IconMap[props.name];

  const size = _.pickBy(
    {
      height: props.height,
      width: props.width,
    },
    _.identity
  );

  return (
    <IconContainer rotation={props.rotation} height={props.height} width={props.width}>
      <IconToRender
        role='img'
        aria-label={props.name}
        stroke={stroke}
        fill={fill}
        {...size}
        title={props.title || ''}
        id={props.id}
      />
    </IconContainer>
  );
}
