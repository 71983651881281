import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddressAutocompleteFieldBlueprint, FieldPartIdentifier, IsoCountryCode, Language } from '@breathelife/types';
import { AutocompleteOption, Box, SimpleCheckbox } from '@breathelife/ui-components';

import { NodeIdSelector } from '../../../../../Components/NodeIds/NodeIdSelector';
import { validate } from '../../../../../Helpers/inputValidation/form/addressAutocompleteFields';
import { NodeDetail } from '../../../../../Helpers/questionnaireEditor/questionnaireNodeIds';
import { getCountryCodeOptions } from '../../../../../Helpers/questionnaireEditor/selectOptions';
import { useUpdateQuestionnaireElementBlueprint } from '../../../../../ReactQuery/Admin/Questionnaire/questionnaireVersion.mutations';
import { QuestionnaireVersionDataContext } from '../../QuestionnaireVersionDataContextProvider';
import { EditorDropdown } from '../Components/EditorDropdown';

type Props = {
  partIdentifier: FieldPartIdentifier;
  blueprint: AddressAutocompleteFieldBlueprint;
  addressAutocompleteFieldTargetOptions?: AutocompleteOption[]; // `AutocompleteOption` in the mui sense.
  nodeIds: NodeDetail[];
  selectedLanguage: Language;
  disabled: boolean;
};

export function AddressAutocompleteFieldEditor({
  blueprint,
  partIdentifier,
  addressAutocompleteFieldTargetOptions,
  disabled,
  nodeIds,
  selectedLanguage,
}: Props): React.ReactElement | null {
  const { t } = useTranslation();

  const [countryCode, setCountryCode] = useState<IsoCountryCode>('CA');

  // addressAutocomplete search inputs are also often the 'address street/number' input.
  const [addressAutocompleteIsAddressStreet, setAddressAutocompleteIsAddressStreet] = useState<boolean>(true);
  const [addressAutocompleteAutocompleteNodeId, setAddressAutocompleteAutocompleteNodeId] = useState<string>('');

  const [addressAutocompleteStreetAddress, setAddressAutocompleteStreetAddress] = useState<string>('');
  const [addressAutocompleteCity, setAddressAutocompleteCity] = useState<string>('');
  const [addressAutocompleteStateOrProvince, setAddressAutocompleteStateOrProvince] = useState<string>('');
  const [addressAutocompletePostalCodeOrZip, setAddressAutocompletePostalCodeOrZip] = useState<string>('');
  const { questionnaireVersionId } = useContext(QuestionnaireVersionDataContext);

  const updateBlueprint = useUpdateQuestionnaireElementBlueprint(questionnaireVersionId);

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (isInitialized) return;

    const initialCountryCode = blueprint.countryCode;
    if (initialCountryCode) {
      setCountryCode(initialCountryCode);
    }

    if (blueprint.addressAutocompleteFields?.streetAddress === blueprint.partName) {
      setAddressAutocompleteIsAddressStreet(true);
      setAddressAutocompleteAutocompleteNodeId(blueprint.addressAutocompleteNodeId ?? '');
    } else if (blueprint.addressAutocompleteFields?.streetAddress) {
      setAddressAutocompleteStreetAddress(blueprint.addressAutocompleteFields.streetAddress);
    }

    if (blueprint.addressAutocompleteFields?.city) {
      setAddressAutocompleteCity(blueprint.addressAutocompleteFields.city);
    }

    if (blueprint.addressAutocompleteFields?.stateOrProvince) {
      setAddressAutocompleteStateOrProvince(blueprint.addressAutocompleteFields.stateOrProvince);
    }

    if (blueprint.addressAutocompleteFields?.postalCodeOrZip) {
      setAddressAutocompletePostalCodeOrZip(blueprint.addressAutocompleteFields.postalCodeOrZip);
    }

    setIsInitialized(true);
  }, [isInitialized, blueprint]);

  const countryCodeOptions = useMemo(() => getCountryCodeOptions(t), [t]);

  const validationErrors = useMemo(() => {
    if (!isInitialized) return {};

    return validate(t, {
      streetAddress: addressAutocompleteStreetAddress,
      city: addressAutocompleteCity,
      stateOrProvince: addressAutocompleteStateOrProvince,
      postalCodeOrZip: addressAutocompletePostalCodeOrZip,
      autocompleteIsAddressStreet: addressAutocompleteIsAddressStreet,
      autocompleteNodeId: addressAutocompleteAutocompleteNodeId,
    });
  }, [
    t,
    addressAutocompleteStreetAddress,
    addressAutocompleteCity,
    addressAutocompleteStateOrProvince,
    addressAutocompletePostalCodeOrZip,
    addressAutocompleteIsAddressStreet,
    addressAutocompleteAutocompleteNodeId,
    isInitialized,
  ]);

  if (!isInitialized) return null;

  return (
    <React.Fragment>
      <Box pb={1}>
        <EditorDropdown
          label={t('admin.questionnaireManagement.input.autocompleteCountryCode')}
          selectedOptionValue={countryCode}
          onChange={(value) => {
            if (!value) return;

            const countryCodeValue = value as IsoCountryCode;
            setCountryCode(countryCodeValue);

            void updateBlueprint({
              partIdentifier,
              update: { property: 'countryCode', value: countryCodeValue },
            });
          }}
          disabled={disabled}
          options={countryCodeOptions}
        />
      </Box>
      <Box pb={1} pt={1}>
        <SimpleCheckbox
          id={`autocompleteIsAddressStreet-${blueprint.partName}`}
          label={t('admin.questionnaireManagement.input.autocompleteFieldIsTheStreetAddressField')}
          checked={addressAutocompleteIsAddressStreet}
          disabled={disabled}
          onChange={(event) => {
            const checked = event.target.checked;
            setAddressAutocompleteIsAddressStreet(checked);

            if (checked) {
              void updateBlueprint({
                partIdentifier,
                update: { property: 'addressAutocompleteFields.streetAddress', value: blueprint.partName },
              });
            } else {
              setAddressAutocompleteAutocompleteNodeId('');

              void updateBlueprint({
                partIdentifier,
                update: { property: 'addressAutocompleteFields.streetAddress', value: undefined },
              });

              void updateBlueprint({
                partIdentifier,
                update: { property: 'addressAutocompleteNodeId', value: undefined },
              });
            }
          }}
        />
      </Box>
      {addressAutocompleteIsAddressStreet && (
        <Box pb={1} pt={1}>
          <NodeIdSelector
            label={t('admin.questionnaireManagement.input.autocompleteNodeId')}
            nodeIds={nodeIds}
            readOnly={disabled}
            required
            selectedNodeId={addressAutocompleteAutocompleteNodeId}
            onChange={(option) => {
              if (!option?.value) return;

              setAddressAutocompleteAutocompleteNodeId(option?.value || '');
              void updateBlueprint({
                partIdentifier,
                update: { property: 'addressAutocompleteNodeId', value: option?.value },
              });
            }}
            selectedLanguage={selectedLanguage}
            validationError={validationErrors.streetAddressOrAutocompleteNodeId}
          />
        </Box>
      )}
      {!addressAutocompleteIsAddressStreet && (
        <Box pb={1} pt={1}>
          <EditorDropdown
            label={t('admin.questionnaireManagement.input.autocompleteStreetAddressField')}
            selectedOptionValue={addressAutocompleteStreetAddress}
            disabled={disabled}
            disableClearable={false}
            required
            onChange={(value) => {
              setAddressAutocompleteStreetAddress(value ?? '');
              void updateBlueprint({
                partIdentifier,
                update: { property: 'addressAutocompleteFields.streetAddress', value: value ?? '' },
              });
            }}
            validationError={validationErrors.streetAddressOrAutocompleteNodeId}
            options={addressAutocompleteFieldTargetOptions}
          />
        </Box>
      )}
      <Box pt={1} pb={1}>
        <EditorDropdown
          label={t('admin.questionnaireManagement.input.autocompleteCityField')}
          selectedOptionValue={addressAutocompleteCity}
          disabled={disabled}
          disableClearable={false}
          required
          onChange={(value) => {
            setAddressAutocompleteCity(value ?? '');
            void updateBlueprint({
              partIdentifier,
              update: { property: 'addressAutocompleteFields.city', value: value ?? '' },
            });
          }}
          validationError={validationErrors.city}
          options={addressAutocompleteFieldTargetOptions}
        />
      </Box>
      <Box pt={1} pb={1}>
        <EditorDropdown
          label={t('admin.questionnaireManagement.input.autocompleteStateOrProvinceField')}
          selectedOptionValue={addressAutocompleteStateOrProvince}
          disabled={disabled}
          disableClearable={false}
          required
          onChange={(value) => {
            setAddressAutocompleteStateOrProvince(value ?? '');
            void updateBlueprint({
              partIdentifier,
              update: { property: 'addressAutocompleteFields.stateOrProvince', value: value ?? '' },
            });
          }}
          validationError={validationErrors.stateOrProvince}
          options={addressAutocompleteFieldTargetOptions}
        />
      </Box>
      <Box pt={1}>
        <EditorDropdown
          label={t('admin.questionnaireManagement.input.autocompletePostalCodeOrZipField')}
          selectedOptionValue={addressAutocompletePostalCodeOrZip}
          disabled={disabled}
          disableClearable={false}
          required
          onChange={(value) => {
            setAddressAutocompletePostalCodeOrZip(value ?? '');
            void updateBlueprint({
              partIdentifier,
              update: { property: 'addressAutocompleteFields.postalCodeOrZip', value: value ?? '' },
            });
          }}
          validationError={validationErrors.postalCodeOrZip}
          options={addressAutocompleteFieldTargetOptions}
        />
      </Box>
    </React.Fragment>
  );
}
