import dayjs from 'dayjs';

import { NodeIdAnswersResolver, NodeIdToAnswerPathMap } from '@breathelife/questionnaire-engine';
import { AgentLicenseProperties, AgentLicenseVerificationStatus, Answers, YesNoValue } from '@breathelife/types';

import { PlatformFeatures } from '../../Models/PlatformFeatures';

function validateAgentLicense(
  provinceOrState: string,
  licensesData: AgentLicenseProperties[]
): AgentLicenseVerificationStatus {
  if (!provinceOrState) {
    return AgentLicenseVerificationStatus.neutral;
  }
  const agentLicense = licensesData.find((licenses) => licenses.jurisdiction === provinceOrState);
  if (!agentLicense) {
    return AgentLicenseVerificationStatus.invalidJurisdiction;
  }

  if (agentLicense) {
    const { expirationDate } = agentLicense;

    if (dayjs(expirationDate).isBefore(dayjs())) {
      return AgentLicenseVerificationStatus.expired;
    }

    return AgentLicenseVerificationStatus.valid;
  }

  return AgentLicenseVerificationStatus.invalidJurisdiction;
}

export function refreshLicenseValidation(
  features: PlatformFeatures,
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap,
  licensesData: AgentLicenseProperties[],
  answers?: Answers
): AgentLicenseVerificationStatus {
  if (!features.assistedApplication?.nodeIdAgentLicenseConfig || !answers) {
    return AgentLicenseVerificationStatus.invalidJurisdiction;
  }

  const answersResolver = new NodeIdAnswersResolver(nodeIdToAnswerPathMap);

  const {
    ownerSameAsInsuredQuestionNodeId,
    insuredProvinceOrStateNodeIds,
    ownerResidenceAddressSameAsInsuredNodeIds,
    ownerProvinceOrStateNodeIds,
  } = features.assistedApplication.nodeIdAgentLicenseConfig;

  const insuredProvinceValidity = validateInsuredLicense(
    insuredProvinceOrStateNodeIds,
    licensesData,
    answersResolver,
    answers
  );

  const ownerAddressProvinceValidity = validateOwnerLicense(
    ownerSameAsInsuredQuestionNodeId,
    insuredProvinceOrStateNodeIds,
    ownerResidenceAddressSameAsInsuredNodeIds,
    ownerProvinceOrStateNodeIds,
    licensesData,
    answersResolver,
    answers
  );

  if (!ownerAddressProvinceValidity) {
    return insuredProvinceValidity;
  }

  return ownerAddressProvinceValidity;
}

function validateInsuredLicense(
  insuredProvinceOrStateNodeIds: string[],
  licensesData: AgentLicenseProperties[],
  answersResolver: NodeIdAnswersResolver,
  answers: Answers
): AgentLicenseVerificationStatus {
  let insuredProvinceValidity = AgentLicenseVerificationStatus.invalidJurisdiction;

  for (const insuredProvinceNodeId of insuredProvinceOrStateNodeIds) {
    insuredProvinceValidity = validateAgentLicense(
      answersResolver.getAnswer(answers, insuredProvinceNodeId),
      licensesData
    );
  }

  return insuredProvinceValidity;
}

function validateOwnerLicense(
  ownerSameAsInsuredQuestionNodeId: string,
  insuredProvinceOrStateNodeIds: string[],
  ownerResidenceAddressSameAsInsuredNodeIds: string[],
  ownerProvinceOrStateNodeIds: string[],
  licensesData: AgentLicenseProperties[],
  answersResolver: NodeIdAnswersResolver,
  answers: Answers
): AgentLicenseVerificationStatus | null {
  let ownerSameAsInsuredAnswer: string = '';
  const ownerSameAddressAsInsuredAnswers: string[] = [];
  const ownerLicensesValidities: AgentLicenseVerificationStatus[] = [];

  // If the owner is the same as the proposed insured
  ownerSameAsInsuredAnswer = answersResolver.getAnswer(answers, ownerSameAsInsuredQuestionNodeId);

  if (ownerSameAsInsuredAnswer === YesNoValue.no) {
    for (const ownerProvinceOrStateAddressNodeId of ownerProvinceOrStateNodeIds) {
      const ownerProvinceOrStateAddressAnswer = answersResolver.getAnswer(answers, ownerProvinceOrStateAddressNodeId);

      if (ownerProvinceOrStateAddressAnswer) {
        ownerLicensesValidities.push(validateAgentLicense(ownerProvinceOrStateAddressAnswer, licensesData));
      }
    }
  }

  if (ownerSameAsInsuredAnswer === YesNoValue.yes) {
    ownerLicensesValidities.push(
      validateInsuredLicense(insuredProvinceOrStateNodeIds, licensesData, answersResolver, answers)
    );
  }

  // If the address of the owner is the same as the proposed insured
  for (const ownerResidenceAddressSameAsInsuredNodeId of ownerResidenceAddressSameAsInsuredNodeIds) {
    ownerSameAddressAsInsuredAnswers.push(answersResolver.getAnswer(answers, ownerResidenceAddressSameAsInsuredNodeId));
  }

  if (ownerSameAddressAsInsuredAnswers.filter((answer) => answer === YesNoValue.no).length > 0) {
    for (const ownerProvinceOrStateAddressNodeId of ownerProvinceOrStateNodeIds) {
      const ownerProvinceOrStateAddressAnswer = answersResolver.getAnswer(answers, ownerProvinceOrStateAddressNodeId);

      if (ownerProvinceOrStateAddressAnswer) {
        ownerLicensesValidities.push(validateAgentLicense(ownerProvinceOrStateAddressAnswer, licensesData));
      }
    }
  }

  if (ownerSameAddressAsInsuredAnswers.filter((answer) => answer === YesNoValue.yes).length > 0) {
    ownerLicensesValidities.push(
      validateInsuredLicense(insuredProvinceOrStateNodeIds, licensesData, answersResolver, answers)
    );
  }
  if (ownerLicensesValidities.length) {
    if (ownerLicensesValidities.every((licenseValidity) => licenseValidity === AgentLicenseVerificationStatus.valid)) {
      return AgentLicenseVerificationStatus.valid;
    }

    if (ownerLicensesValidities.some((licenseValidity) => licenseValidity === AgentLicenseVerificationStatus.expired)) {
      return AgentLicenseVerificationStatus.expired;
    }

    if (
      ownerLicensesValidities.some(
        (licenseValidity) => licenseValidity === AgentLicenseVerificationStatus.invalidJurisdiction
      )
    ) {
      return AgentLicenseVerificationStatus.invalidJurisdiction;
    }
  }

  return null;
}

export function getProvincesOrStates(
  features: PlatformFeatures,
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap,
  answers?: Answers
): string[] | null {
  if (!features.assistedApplication?.nodeIdAgentLicenseConfig || !answers) {
    return null;
  }
  const answersResolver = new NodeIdAnswersResolver(nodeIdToAnswerPathMap);

  const { ownerProvinceOrStateNodeIds, ownerResidenceAddressSameAsInsuredNodeIds, insuredProvinceOrStateNodeIds } =
    features.assistedApplication.nodeIdAgentLicenseConfig;

  const insuredProvinceOrStateAnswers = [];
  const ownerProvinceOrStateAnswers = [];

  for (const insuredProvinceNodeId of insuredProvinceOrStateNodeIds) {
    const insuredProvinceOrStateAddressAnswer = answersResolver.getAnswer(answers, insuredProvinceNodeId);
    if (insuredProvinceOrStateAddressAnswer) {
      insuredProvinceOrStateAnswers.push(insuredProvinceOrStateAddressAnswer);
    }
  }

  const ownerSameAddressAsInsuredAnswers: string[] = [];

  for (const ownerResidenceAddressSameAsInsuredNodeId of ownerResidenceAddressSameAsInsuredNodeIds) {
    ownerSameAddressAsInsuredAnswers.push(answersResolver.getAnswer(answers, ownerResidenceAddressSameAsInsuredNodeId));
  }

  if (ownerSameAddressAsInsuredAnswers.filter((answer) => answer === YesNoValue.no).length > 0) {
    for (const ownerProvinceOrStateAddressNodeId of ownerProvinceOrStateNodeIds) {
      const ownerProvinceOrStateAnswer = answersResolver.getAnswer(answers, ownerProvinceOrStateAddressNodeId);
      if (ownerProvinceOrStateAnswer) {
        ownerProvinceOrStateAnswers.push(ownerProvinceOrStateAnswer);
      }
    }
  }

  if (!ownerProvinceOrStateAnswers.length) {
    return insuredProvinceOrStateAnswers;
  }

  return ownerProvinceOrStateAnswers;
}

export function getOwnerIsInsured(
  features: PlatformFeatures,
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap,
  answers?: Answers
): string | null {
  if (!features.assistedApplication?.nodeIdAgentLicenseConfig || !answers) {
    return null;
  }

  const answersResolver = new NodeIdAnswersResolver(nodeIdToAnswerPathMap);
  const { ownerSameAsInsuredQuestionNodeId } = features.assistedApplication.nodeIdAgentLicenseConfig;
  const ownerSameAsInsuredAnswer = answersResolver.getAnswer(answers, ownerSameAsInsuredQuestionNodeId);
  return ownerSameAsInsuredAnswer;
}

export function getOwnerAddressIsSameAsInsured(
  features: PlatformFeatures,
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap,
  answers?: Answers
): string[] | null {
  if (!features.assistedApplication?.nodeIdAgentLicenseConfig || !answers) {
    return null;
  }

  const ownerSameAddressAsInsuredAnswers: string[] = [];
  const insuredProvinceOrStateAnswers: string[] = [];

  const answersResolver = new NodeIdAnswersResolver(nodeIdToAnswerPathMap);
  const { ownerResidenceAddressSameAsInsuredNodeIds, insuredProvinceOrStateNodeIds } =
    features.assistedApplication.nodeIdAgentLicenseConfig;

  for (const ownerResidenceAddressSameAsInsuredNodeId of ownerResidenceAddressSameAsInsuredNodeIds) {
    ownerSameAddressAsInsuredAnswers.push(answersResolver.getAnswer(answers, ownerResidenceAddressSameAsInsuredNodeId));
  }

  if (ownerSameAddressAsInsuredAnswers.filter((answer) => answer === YesNoValue.yes).length > 0) {
    for (const insuredProvinceOrStateNodeId of insuredProvinceOrStateNodeIds) {
      const insuredProvinceOrStateAnswer = answersResolver.getAnswer(answers, insuredProvinceOrStateNodeId);
      if (insuredProvinceOrStateAnswer) {
        insuredProvinceOrStateAnswers.push(insuredProvinceOrStateAnswer);
      }
    }
  }

  return insuredProvinceOrStateAnswers;
}
